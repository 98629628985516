import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPutTempclin {

  constructor(
    public uid: string,
    public name: string = null,
    public state: string = null,
    public durationDays: number = null,
    public offsetDays: number = null,
    public description: string = null,
    public start: Date = null,
    public end: Date = null,
    public distribution: SOGetDistribution = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTempclin(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'state' in params ? params.state : null,
      'durationDays' in params ? params.durationDays : null,
      'offsetDays' in params ? params.offsetDays : null,
      'description' in params ? params.description : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
