import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutCarrierauthFormFields {

    public account: FormField
    public carrier: FormField

    constructor() {

        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.carrier = new FormField(
          'carrier',
          'Carrier',
          'carrier',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutCarrierauthFormFields()

export const putCarrierauthFormFieldOptions: {[key: string]: FormField} = {
  account: fields.account,
  carrier: fields.carrier,
};