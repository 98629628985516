import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostDeltaweightFormFields {

    public initial: FormField
    public delta: FormField
    public weight: FormField

    constructor() {

        this.initial = new FormField(
          'initial',
          'Initial',
          null,
          FormFieldType.boolean,
          true,
        )
        this.delta = new FormField(
          'delta',
          'Delta',
          'delta',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.weight = new FormField(
          'weight',
          'Weight',
          'weight',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostDeltaweightFormFields()

export const postDeltaweightFormFieldOptions: {[key: string]: FormField} = {
  initial: fields.initial,
  delta: fields.delta,
  weight: fields.weight,
};