import {SOPostRateableagreeyearallocation} from '../../core/rateableagreeyearallocation/so-post-rateableagreeyearallocation';
import {RateableagreeyearallocationCoreBase} from './rateableagreeyearallocation.core.base';
import {SOGetRateableagreeyearallocation} from '../../core/rateableagreeyearallocation/so-get-rateableagreeyearallocation';
import {FormField, SelectionOption} from 'lionheartland';
import {postRateableagreeyearallocationFormFieldOptions} from './post-rateableagreeyearallocation-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AgreeyearCore} from '../agreeyear/agreeyear.core';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';

export class RAPostRateableagreeyearallocation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRateableagreeyearallocationFormFieldOptions);

  RequestClass = SOPostRateableagreeyearallocation

  constructor(
    public entityCore: RateableagreeyearallocationCoreBase
  ) {
    super();
    if (postRateableagreeyearallocationFormFieldOptions.agreeyear) {
      postRateableagreeyearallocationFormFieldOptions.agreeyear.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'agreeyear',
        SOGetAgreeyear,
      ),
      this.getFormValueFromIdentifier('jan'),
      this.getFormValueFromIdentifier('feb'),
      this.getFormValueFromIdentifier('mar'),
      this.getFormValueFromIdentifier('apr'),
      this.getFormValueFromIdentifier('may'),
      this.getFormValueFromIdentifier('jun'),
      this.getFormValueFromIdentifier('jul'),
      this.getFormValueFromIdentifier('aug'),
      this.getFormValueFromIdentifier('sep'),
      this.getFormValueFromIdentifier('oct'),
      this.getFormValueFromIdentifier('nov'),
      this.getFormValueFromIdentifier('dec'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}