import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTaskreportFormFields {

    public status: FormField
    public dateRequested: FormField
    public dateStart: FormField
    public dateEnd: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
        )
        this.dateRequested = new FormField(
          'dateRequested',
          'Date requested',
          null,
          FormFieldType.datetime,
          false,
        )
        this.dateStart = new FormField(
          'dateStart',
          'Date start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.dateEnd = new FormField(
          'dateEnd',
          'Date end',
          null,
          FormFieldType.datetime,
          false,
        )

    }
}

const fields = new PutTaskreportFormFields()

export const putTaskreportFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
  dateRequested: fields.dateRequested,
  dateStart: fields.dateStart,
  dateEnd: fields.dateEnd,
};