import {SORespTempagreement} from '../../core/tempagreement/so-resp-tempagreement';
import {TempagreementCore} from './tempagreement.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTempagreementFormFields} from './put-tempagreement-form-fields';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {SOGetTempagreetract} from '../../core/tempagreetract/so-get-tempagreetract';
import {SOGetDisttempagreement} from '../../core/disttempagreement/so-get-disttempagreement';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CETempagreementAttributesBase {

    agreements: DetailEntry
    name: DetailEntry
    description: DetailEntry
    autoRenew: DetailEntry
    docusignLink: DetailEntry
    units: DetailEntry
    durationDays: DetailEntry
    feeschedule: DetailEntry
    acq: DetailEntry
    top: DetailEntry
    tempagreetracts: DetailEntry
    disttempagreements: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTempagreement,
        app: GasDirectApp,
        formFields: PutTempagreementFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.autoRenew = new DetailEntry(
            'Auto renew',
            entity.autoRenew,
            'autoRenew',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.autoRenew
        )

        this.docusignLink = new DetailEntry(
            'Docusign link',
            entity.docusignLink,
            'docusignLink',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.docusignLink
        )

        this.units = new DetailEntry(
            'Units',
            entity.units,
            'units',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.units
        )

        this.durationDays = new DetailEntry(
            'Duration days',
            entity.durationDays,
            'durationDays',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.durationDays
        )

        this.acq = new DetailEntry(
            'Acq',
            entity.acq,
            'acq',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.acq
        )

        this.top = new DetailEntry(
            'Top',
            entity.top,
            'top',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.top
        )


        const agreementsCore = app.makeCore( 'agreement');
        agreementsCore.soGet = SOGetAgreement.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementsCore
        )
        agreementsCore.title = heading ? heading : agreementsCore.title
        agreementsCore.listTitle = 'Agreements'

        agreementsCore.configureForDisplay(parentEntityViewSpec)

        this.agreements = new DetailEntry(
          heading ? heading : agreementsCore.listTitle,
          null,
          'agreements',
          BaseDetailType.embeddedList,
          agreementsCore,
          EditableType.never
        )

        const feescheduleCore = app.makeCore( 'feeschedule');
        feescheduleCore.soGet = SOGetFeeschedule.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let feescheduleCeProtocol;
        if (entity.feeschedule) {
            feescheduleCeProtocol = createCEProtocol(
                feescheduleCore.ceProtocol,
                parentEntityViewSpec,
                feescheduleCore,
                entity.feeschedule,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feescheduleCore
        )
        feescheduleCore.title = heading ? heading : feescheduleCore.title
        feescheduleCore.listTitle = 'Feeschedule'

        if (entity.feeschedule) {
            displayStringFactory.reconfigure(
                entity.feeschedule,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        feescheduleCore.configureForDisplay(parentEntityViewSpec)

        this.feeschedule = new DetailEntry(
          heading ? heading : feescheduleCore.listTitle,
          feescheduleCeProtocol ? feescheduleCeProtocol.entityListView.fullHeading : null,
          'feeschedule',
          BaseDetailType.pushedDetail,
          feescheduleCore,
          EditableType.always,
          formFields.feeschedule
        )
        if (entity.feeschedule) {
          this.feeschedule.ceProtocol = feescheduleCeProtocol;
          this.feeschedule.cachedListEntry = feescheduleCore.makeListEntry(parentEntityViewSpec, entity.feeschedule, 0)
          feescheduleCore.selectedEntity = entity.feeschedule;
        }
        this.feeschedule.singleFieldSelect = true

        const tempagreetractsCore = app.makeCore( 'tempagreetract');
        tempagreetractsCore.soGet = SOGetTempagreetract.construct({
          tempagreement: new SOGetTempagreement(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempagreetractsCore
        )
        tempagreetractsCore.title = heading ? heading : tempagreetractsCore.title
        tempagreetractsCore.listTitle = 'Tempagreetracts'

        tempagreetractsCore.configureForDisplay(parentEntityViewSpec)

        this.tempagreetracts = new DetailEntry(
          heading ? heading : tempagreetractsCore.listTitle,
          null,
          'tempagreetracts',
          BaseDetailType.embeddedList,
          tempagreetractsCore,
          EditableType.never
        )

        const disttempagreementsCore = app.makeCore( 'disttempagreement');
        disttempagreementsCore.soGet = SOGetDisttempagreement.construct({
          tempagreement: new SOGetTempagreement(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            disttempagreementsCore
        )
        disttempagreementsCore.title = heading ? heading : disttempagreementsCore.title
        disttempagreementsCore.listTitle = 'Disttempagreements'

        disttempagreementsCore.configureForDisplay(parentEntityViewSpec)

        this.disttempagreements = new DetailEntry(
          heading ? heading : disttempagreementsCore.listTitle,
          null,
          'disttempagreements',
          BaseDetailType.embeddedList,
          disttempagreementsCore,
          EditableType.never
        )
    }
}