import {SOPutHemon} from '../../core/hemon/so-put-hemon';
import {HemonCoreBase} from './hemon.core.base';
import {SOGetHemon} from '../../core/hemon/so-get-hemon';
import {FormField, SelectionOption} from 'lionheartland';
import {putHemonFormFieldOptions} from './put-hemon-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';
import {TaskCore} from '../task/task.core';
import {SOGetTask} from '../../core/task/so-get-task';
import {ContainersessionCore} from '../containersession/containersession.core';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';

export class RAPutHemon extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putHemonFormFieldOptions);

  RequestClass = SOPutHemon

  constructor(
    public entityCore: HemonCoreBase
  ) {
    super();
    if (putHemonFormFieldOptions.load) {
      putHemonFormFieldOptions.load.makeCore = true;
    }
    if (putHemonFormFieldOptions.task) {
      putHemonFormFieldOptions.task.makeCore = true;
    }
    if (putHemonFormFieldOptions.containersession) {
      putHemonFormFieldOptions.containersession.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('dateCreated'),
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('n2Level'),
      this.getFormValueFromIdentifier('n2Pressure'),
      this.getFormValueFromIdentifier('heTemp'),
      this.getFormValueFromIdentifier('heLevel'),
      this.getFormValueFromIdentifier('hePressure'),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
      this.getFormValueFromIdentifier(
        'task',
        SOGetTask,
      ),
      this.getFormValueFromIdentifier(
        'containersession',
        SOGetContainersession,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}