import {SORespSlotoption} from '../../core/slotoption/so-resp-slotoption';
import {SlotoptionCore} from './slotoption.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSlotoptionFormFields} from './put-slotoption-form-fields';
import { CESlotoptionAttributes } from './ce-slotoption-attributes'


export class CESlotoptionBase extends CEBase {

    public details: CESlotoptionAttributes;
    public fromFields: PutSlotoptionFormFields = new PutSlotoptionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: SlotoptionCore,
        public entity: SORespSlotoption,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CESlotoptionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.slot)
        this.attributes.push(this.details.agreemonth)

    }
}