import {SORespInquiry} from '../../core/inquiry/so-resp-inquiry';
import {InquiryCore} from './inquiry.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutInquiryFormFields} from './put-inquiry-form-fields';
import {SOGetInquiry} from '../../core/inquiry/so-get-inquiry';
import {SOGetCustomer} from '../../core/customer/so-get-customer';


export class CEInquiryAttributesBase {

    customers: DetailEntry
    companyName: DetailEntry
    givenName: DetailEntry
    surname: DetailEntry
    productInquiryStr: DetailEntry
    estimatedPurchaseQuantity: DetailEntry
    preferredEmailAddress: DetailEntry
    phoneNumber: DetailEntry
    dateCreated: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespInquiry,
        app: GasDirectApp,
        formFields: PutInquiryFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.companyName = new DetailEntry(
            'Company name',
            entity.companyName,
            'companyName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.companyName
        )

        this.givenName = new DetailEntry(
            'Given name',
            entity.givenName,
            'givenName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.givenName
        )

        this.surname = new DetailEntry(
            'Surname',
            entity.surname,
            'surname',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.surname
        )

        this.productInquiryStr = new DetailEntry(
            'Product inquiry str',
            entity.productInquiryStr,
            'productInquiryStr',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.productInquiryStr
        )

        this.estimatedPurchaseQuantity = new DetailEntry(
            'Estimated purchase quantity',
            entity.estimatedPurchaseQuantity,
            'estimatedPurchaseQuantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.estimatedPurchaseQuantity
        )

        this.preferredEmailAddress = new DetailEntry(
            'Preferred email address',
            entity.preferredEmailAddress,
            'preferredEmailAddress',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.preferredEmailAddress
        )

        this.phoneNumber = new DetailEntry(
            'Phone number',
            entity.phoneNumber,
            'phoneNumber',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.phoneNumber
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateCreated
        )


        const customersCore = app.makeCore( 'customer');
        customersCore.soGet = SOGetCustomer.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            customersCore
        )
        customersCore.title = heading ? heading : customersCore.title
        customersCore.listTitle = 'Customers'

        customersCore.configureForDisplay(parentEntityViewSpec)

        this.customers = new DetailEntry(
          heading ? heading : customersCore.listTitle,
          null,
          'customers',
          BaseDetailType.embeddedList,
          customersCore,
          EditableType.never
        )
    }
}