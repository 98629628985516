import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENServiceType} from '../../enums';

export class PostServiceRequestFormFields {

    public dateCreated: FormField
    public dateRequested: FormField
    public serviceType: FormField
    public distributionday: FormField
    public containersession: FormField

    constructor() {

        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          true,
        )
        this.dateRequested = new FormField(
          'dateRequested',
          'Date requested',
          null,
          FormFieldType.datetime,
          true,
        )
        this.serviceType = new FormField(
          'serviceType',
          'Service type',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.serviceType.enumeration = ENServiceType
        this.distributionday = new FormField(
          'distributionday',
          'Distributionday',
          'distributionday',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostServiceRequestFormFields()

export const postServiceRequestFormFieldOptions: {[key: string]: FormField} = {
  dateCreated: fields.dateCreated,
  dateRequested: fields.dateRequested,
  serviceType: fields.serviceType,
  distributionday: fields.distributionday,
  containersession: fields.containersession,
};