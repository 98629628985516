import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespImpurity implements ObjectiveProtocol {

  entityType: string = 'impurity';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  state: string;
  h20: number;
  neon: number;
  h2: number;
  o2: number;
  n2: number;
  carbonMonoxide: number;
  hydrocarbons: number;
  carbonDioxide: number;
}