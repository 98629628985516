import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutNavi } from './so-put-navi';
import { SOGetNavi } from './so-get-navi';
import { SOPostNavi } from './so-post-navi';
import { SODeleteNavi } from './so-delete-navi';
import {SORespNavi} from './so-resp-navi';
import {SOPluralNavi} from './so-plural-navi';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EONaviService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'navi',
      entityCore
    );
  }

  /** GET Navi by id. Will 404 if id not found */
  get(request: SOGetNavi): Observable<SOPluralNavi> {
    return this.httpClient.get<SOPluralNavi>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralNavi, resp) }),
      tap(_ => this.log(`fetched Navi`)),
      catchError(this.handleError<SOPluralNavi>(`get Navi`))
    );
  }

  /** POST: add a new Navi to the server */
  post(request: SOPostNavi): Observable<SORespNavi> {
    return this.httpClient.post<SORespNavi>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespNavi, resp) }),
      tap((response: SORespNavi) => this.log(`added Navi w/ id=${response.uid}`)),
      catchError(this.handleError<SORespNavi>('Navi post'))
    );
  }

  /** PUT: update Navi  */
  put(request: SOPutNavi): Observable<SORespNavi> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespNavi>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespNavi, resp) }),
      tap((response: SORespNavi) => this.log(`edited Navi w/ uid=${uid}`)),
      catchError(this.handleError<SORespNavi>('Navi put'))
    );
  }

  /** DELETE: delete the Navi from the server */
  delete(request: SODeleteNavi): Observable<SORespNavi> {
    return this.httpClient.delete<SORespNavi>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Navi uid=${request.uid}`)),
      catchError(this.handleError<SORespNavi>('delete Navi'))
    );
  }
}
