import { Type } from 'class-transformer';
import { SORespSubscription } from '../subscription/so-resp-subscription';
import { SORespPublication } from '../publication/so-resp-publication';
import { SORespActivity } from '../activity/so-resp-activity';
import { SORespUser } from '../user/so-resp-user';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespMessage implements ObjectiveProtocol {

  entityType: string = 'message';
  uid: string;
  @Type(() => Date)
  timeSent: Date;
  body: string;
  read: boolean = false;
  type: string;
  ref: string = null;
  @Type(() => SORespSubscription)
  subscription: SORespSubscription = null;
  @Type(() => SORespPublication)
  publication: SORespPublication = null;
  @Type(() => SORespActivity)
  activity: SORespActivity = null;
  @Type(() => SORespUser)
  user: SORespUser = null;
}