import {SORespAccountreport} from '../../core/accountreport/so-resp-accountreport';
import {AccountreportCore} from './accountreport.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAccountreportFormFields} from './put-accountreport-form-fields';
import {SOGetAccountreport} from '../../core/accountreport/so-get-accountreport';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistributionreport} from '../../core/distributionreport/so-get-distributionreport';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';
import {SOGetInreport} from '../../core/inreport/so-get-inreport';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEAccountreportAttributesBase {

    ident: DetailEntry
    account: DetailEntry
    timeWindow: DetailEntry
    start: DetailEntry
    end: DetailEntry
    loadsProjected: DetailEntry
    loadsScheduled: DetailEntry
    loadsContracted: DetailEntry
    loadsActual: DetailEntry
    volumeActual: DetailEntry
    volumeProjected: DetailEntry
    volumeContracted: DetailEntry
    volumeScheduled: DetailEntry
    averageLoads: DetailEntry
    averageVolume: DetailEntry
    averageUnits: DetailEntry
    revenueProjected: DetailEntry
    revenueActual: DetailEntry
    revenueScheduled: DetailEntry
    revenueContracted: DetailEntry
    waspProjected: DetailEntry
    waspActual: DetailEntry
    waspScheduled: DetailEntry
    waspContracted: DetailEntry
    tankersActual: DetailEntry
    tankersScheduled: DetailEntry
    supertankersActual: DetailEntry
    supertankersScheduled: DetailEntry
    distributionreport: DetailEntry
    volumeDelta: DetailEntry
    loadsDelta: DetailEntry
    waspDelta: DetailEntry
    revenueDelta: DetailEntry
    distributionday: DetailEntry
    distributionmonth: DetailEntry
    distributionyear: DetailEntry
    inreports: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAccountreport,
        app: GasDirectApp,
        formFields: PutAccountreportFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.ident = new DetailEntry(
            'Ident',
            entity.ident,
            'ident',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.ident
        )

        this.timeWindow = new DetailEntry(
            'Time window',
            entity.timeWindow,
            'timeWindow',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.timeWindow
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )

        this.loadsProjected = new DetailEntry(
            'Loads projected',
            entity.loadsProjected,
            'loadsProjected',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadsProjected
        )

        this.loadsScheduled = new DetailEntry(
            'Loads scheduled',
            entity.loadsScheduled,
            'loadsScheduled',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadsScheduled
        )

        this.loadsContracted = new DetailEntry(
            'Loads contracted',
            entity.loadsContracted,
            'loadsContracted',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadsContracted
        )

        this.loadsActual = new DetailEntry(
            'Loads actual',
            entity.loadsActual,
            'loadsActual',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadsActual
        )

        this.volumeActual = new DetailEntry(
            'Volume actual',
            entity.volumeActual,
            'volumeActual',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.volumeActual
        )

        this.volumeProjected = new DetailEntry(
            'Volume projected',
            entity.volumeProjected,
            'volumeProjected',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.volumeProjected
        )

        this.volumeContracted = new DetailEntry(
            'Volume contracted',
            entity.volumeContracted,
            'volumeContracted',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.volumeContracted
        )

        this.volumeScheduled = new DetailEntry(
            'Volume scheduled',
            entity.volumeScheduled,
            'volumeScheduled',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.volumeScheduled
        )

        this.averageLoads = new DetailEntry(
            'Average loads',
            entity.averageLoads,
            'averageLoads',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.averageLoads
        )

        this.averageVolume = new DetailEntry(
            'Average volume',
            entity.averageVolume,
            'averageVolume',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.averageVolume
        )

        this.averageUnits = new DetailEntry(
            'Average units',
            entity.averageUnits,
            'averageUnits',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.averageUnits
        )

        this.revenueProjected = new DetailEntry(
            'Revenue projected',
            entity.revenueProjected,
            'revenueProjected',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.revenueProjected
        )

        this.revenueActual = new DetailEntry(
            'Revenue actual',
            entity.revenueActual,
            'revenueActual',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.revenueActual
        )

        this.revenueScheduled = new DetailEntry(
            'Revenue scheduled',
            entity.revenueScheduled,
            'revenueScheduled',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.revenueScheduled
        )

        this.revenueContracted = new DetailEntry(
            'Revenue contracted',
            entity.revenueContracted,
            'revenueContracted',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.revenueContracted
        )

        this.waspProjected = new DetailEntry(
            'Wasp projected',
            entity.waspProjected,
            'waspProjected',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.waspProjected
        )

        this.waspActual = new DetailEntry(
            'Wasp actual',
            entity.waspActual,
            'waspActual',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.waspActual
        )

        this.waspScheduled = new DetailEntry(
            'Wasp scheduled',
            entity.waspScheduled,
            'waspScheduled',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.waspScheduled
        )

        this.waspContracted = new DetailEntry(
            'Wasp contracted',
            entity.waspContracted,
            'waspContracted',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.waspContracted
        )

        this.tankersActual = new DetailEntry(
            'Tankers actual',
            entity.tankersActual,
            'tankersActual',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.tankersActual
        )

        this.tankersScheduled = new DetailEntry(
            'Tankers scheduled',
            entity.tankersScheduled,
            'tankersScheduled',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.tankersScheduled
        )

        this.supertankersActual = new DetailEntry(
            'Supertankers actual',
            entity.supertankersActual,
            'supertankersActual',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.supertankersActual
        )

        this.supertankersScheduled = new DetailEntry(
            'Supertankers scheduled',
            entity.supertankersScheduled,
            'supertankersScheduled',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.supertankersScheduled
        )

        this.volumeDelta = new DetailEntry(
            'Volume delta',
            entity.volumeDelta,
            'volumeDelta',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.volumeDelta
        )

        this.loadsDelta = new DetailEntry(
            'Loads delta',
            entity.loadsDelta,
            'loadsDelta',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadsDelta
        )

        this.waspDelta = new DetailEntry(
            'Wasp delta',
            entity.waspDelta,
            'waspDelta',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.waspDelta
        )

        this.revenueDelta = new DetailEntry(
            'Revenue delta',
            entity.revenueDelta,
            'revenueDelta',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.revenueDelta
        )


        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.always,
          formFields.account
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const distributionreportCore = app.makeCore( 'distributionreport');
        distributionreportCore.soGet = SOGetDistributionreport.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionreportCore
        )
        distributionreportCore.title = heading ? heading : distributionreportCore.title
        distributionreportCore.listTitle = 'Distributionreport'

        distributionreportCore.configureForDisplay(parentEntityViewSpec)

        this.distributionreport = new DetailEntry(
          heading ? heading : distributionreportCore.listTitle,
          null,
          'distributionreport',
          BaseDetailType.pushedDetail,
          distributionreportCore,
          EditableType.always,
          formFields.distributionreport
        )
        this.distributionreport.singleFieldSelect = true

        const distributiondayCore = app.makeCore( 'distributionday');
        distributiondayCore.soGet = SOGetDistributionday.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributiondayCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributiondayCore
        )
        distributiondayCore.title = heading ? heading : distributiondayCore.title
        distributiondayCore.listTitle = 'Distributionday'

        distributiondayCore.configureForDisplay(parentEntityViewSpec)

        this.distributionday = new DetailEntry(
          heading ? heading : distributiondayCore.listTitle,
          null,
          'distributionday',
          BaseDetailType.pushedDetail,
          distributiondayCore,
          EditableType.never
        )
        this.distributionday.singleFieldSelect = true

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true

        const distributionyearCore = app.makeCore( 'distributionyear');
        distributionyearCore.soGet = SOGetDistributionyear.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionyearCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionyearCore
        )
        distributionyearCore.title = heading ? heading : distributionyearCore.title
        distributionyearCore.listTitle = 'Distributionyear'

        distributionyearCore.configureForDisplay(parentEntityViewSpec)

        this.distributionyear = new DetailEntry(
          heading ? heading : distributionyearCore.listTitle,
          null,
          'distributionyear',
          BaseDetailType.pushedDetail,
          distributionyearCore,
          EditableType.never
        )
        this.distributionyear.singleFieldSelect = true

        const inreportsCore = app.makeCore( 'inreport');
        inreportsCore.soGet = SOGetInreport.construct({
          accounreport: new SOGetAccountreport(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            inreportsCore
        )
        inreportsCore.title = heading ? heading : inreportsCore.title
        inreportsCore.listTitle = 'Inreports'

        inreportsCore.configureForDisplay(parentEntityViewSpec)

        this.inreports = new DetailEntry(
          heading ? heading : inreportsCore.listTitle,
          null,
          'inreports',
          BaseDetailType.embeddedList,
          inreportsCore,
          EditableType.always,
          formFields.inreports
        )
    }
}