import { SOGetRole } from '../role/so-get-role';
import { SOGetUser } from '../user/so-get-user';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetGrant implements QueryProtocol {

  constructor(
    public uid: string = null,
    public role: SOGetRole = null,
    public user: SOGetUser = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetGrant(
      'uid' in params ? params.uid : null,
      'role' in params ? params.role : null,
      'user' in params ? params.user : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
