import {SOPutIndexprice} from '../../core/indexprice/so-put-indexprice';
import {IndexpriceCoreBase} from './indexprice.core.base';
import {SOGetIndexprice} from '../../core/indexprice/so-get-indexprice';
import {FormField, SelectionOption} from 'lionheartland';
import {putIndexpriceFormFieldOptions} from './put-indexprice-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {ExternalindexpriceCore} from '../externalindexprice/externalindexprice.core';
import {SOGetExternalindexprice} from '../../core/externalindexprice/so-get-externalindexprice';

export class RAPutIndexprice extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putIndexpriceFormFieldOptions);

  RequestClass = SOPutIndexprice

  constructor(
    public entityCore: IndexpriceCoreBase
  ) {
    super();
    if (putIndexpriceFormFieldOptions.externalindexprice) {
      putIndexpriceFormFieldOptions.externalindexprice.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('rate'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
        true,
      ),
      this.getFormValueFromIdentifier(
        'externalindexprice',
        SOGetExternalindexprice,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}