import { SOGetClin } from '../clin/so-get-clin';
import { SOGetAgreeyear } from '../agreeyear/so-get-agreeyear';


export class SOPostPrice {

  constructor(
    public startDate: Date,
    public endDate: Date,
    public rate: number,
    public rank: number,
    public quantity: number = 0,
    public loadQuantity: number = 0,
    public clin: SOGetClin = null,
    public agreeyear: SOGetAgreeyear = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostPrice(
      'startDate' in params ? params.startDate : null,
      'endDate' in params ? params.endDate : null,
      'rate' in params ? params.rate : null,
      'quantity' in params ? params.quantity : null,
      'loadQuantity' in params ? params.loadQuantity : null,
      'rank' in params ? params.rank : null,
      'clin' in params ? params.clin : null,
      'agreeyear' in params ? params.agreeyear : null,
    );
  }
}
