import { SOGetContract } from '../contract/so-get-contract';
import { SOGetSlot } from '../slot/so-get-slot';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetClin implements QueryProtocol {

  constructor(
    public uid: string = null,
    public state: string = null,
    public type: string = null,
    public contract: SOGetContract = null,
    public slot: SOGetSlot = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetClin(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'type' in params ? params.type : null,
      'contract' in params ? params.contract : null,
      'slot' in params ? params.slot : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
