import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutMessageFormFields {

    public body: FormField
    public read: FormField
    public type: FormField

    constructor() {

        this.body = new FormField(
          'body',
          'Body',
          null,
          FormFieldType.text,
          false,
        )
        this.read = new FormField(
          'read',
          'Read',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutMessageFormFields()

export const putMessageFormFieldOptions: {[key: string]: FormField} = {
  body: fields.body,
  read: fields.read,
  type: fields.type,
};