import {SOPutLinag} from '../../core/linag/so-put-linag';
import {LinagCoreBase} from './linag.core.base';
import {SOGetLinag} from '../../core/linag/so-get-linag';
import {FormField, SelectionOption} from 'lionheartland';
import {putLinagFormFieldOptions} from './put-linag-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {TagCore} from '../tag/tag.core';
import {SOGetTag} from '../../core/tag/so-get-tag';

export class RAPutLinag extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putLinagFormFieldOptions);

  RequestClass = SOPutLinag

  constructor(
    public entityCore: LinagCoreBase
  ) {
    super();
    if (putLinagFormFieldOptions.tag) {
      putLinagFormFieldOptions.tag.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier(
        'tag',
        SOGetTag,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}