import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutOrderFormFields {

    public status: FormField
    public account: FormField
    public agreement: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.agreement = new FormField(
          'agreement',
          'Agreement',
          'agreement',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutOrderFormFields()

export const putOrderFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
};