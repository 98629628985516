import {SOPostOrgrola} from '../../core/orgrola/so-post-orgrola';
import {OrgrolaCoreBase} from './orgrola.core.base';
import {SOGetOrgrola} from '../../core/orgrola/so-get-orgrola';
import {FormField, SelectionOption} from 'lionheartland';
import {postOrgrolaFormFieldOptions} from './post-orgrola-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {RoleCore} from '../role/role.core';
import {SOGetRole} from '../../core/role/so-get-role';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostOrgrola extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postOrgrolaFormFieldOptions);

  RequestClass = SOPostOrgrola

  constructor(
    public entityCore: OrgrolaCoreBase
  ) {
    super();
    if (postOrgrolaFormFieldOptions.role) {
      postOrgrolaFormFieldOptions.role.makeCore = true;
    }
    if (postOrgrolaFormFieldOptions.organization) {
      postOrgrolaFormFieldOptions.organization.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'role',
        SOGetRole,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
      ),
      this.getFormValueFromIdentifier('isOwner'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}