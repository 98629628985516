import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOOrderService} from '../../core/order/eo-order';
import {SOGetOrder} from '../../core/order/so-get-order';
import {RAPutOrder} from './ra-put-order';
import {RAPostOrder} from './ra-post-order';
import {RADeleteOrder} from './ra-delete-order';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import {SOGetAgreement} from '../../core/agreement/so-get-agreement'
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth'
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth'
import { CEOrder } from "./ce-order"


export class OrderCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'order';
  title = 'Order';
  ceProtocol: CEConstructor = CEOrder

  entityService: EOOrderService;
  soGetClass = SOGetOrder;
  soGet: SOGetOrder;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOOrderService
    );
    this.adapters = [
      new RAPostOrder(this),
      new RAPutOrder(this),
      new RADeleteOrder(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetOrder();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    if (this.activeUserService.getScope('agreement')) {
        this.soGet.agreement = new SOGetAgreement(
          this.activeUserService.getScope('agreement').uid
        );
    }
    if (this.activeUserService.getScope('agreemonth')) {
        this.soGet.agreemonth = new SOGetAgreemonth(
          this.activeUserService.getScope('agreemonth').uid
        );
    }
    if (this.activeUserService.getScope('distributionmonth')) {
        this.soGet.distributionmonth = new SOGetDistributionmonth(
          this.activeUserService.getScope('distributionmonth').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}