import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutLngloadFormFields {


    constructor() {


    }
}

const fields = new PutLngloadFormFields()

export const putLngloadFormFieldOptions: {[key: string]: FormField} = {
};