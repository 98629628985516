import {
  DisplayVector,
  DisplayVectorBundle,
  DisplayVectorComponent,
  DisplayVectorEntity,
  DisplayVectorKey,
  DisplaySeparator,
  DISPLAY_OPTIONS
} from 'lionheartland';


export const GENERATED_AND_BASE_DISPLAY_OPTIONS = []

for (const option of DISPLAY_OPTIONS) {
  GENERATED_AND_BASE_DISPLAY_OPTIONS.push(option)
}

GENERATED_AND_BASE_DISPLAY_OPTIONS.push(
    new DisplayVectorBundle(
        'account',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('customer'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'carrier',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorKey('companyName'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'customer',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorKey('companyName'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'featureasc',
        [
        new DisplayVector(
            'user',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('feature'),
                    ]
                ),
            ),
        new DisplayVector(
            'role',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('feature'),
                    ]
                ),
            ),
        new DisplayVector(
            'account',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('feature'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'grant',
        [
        new DisplayVector(
            'user',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('role'),
                    ]
                ),
            ),
        new DisplayVector(
            'role',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('user'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'inquiry',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorKey('companyName'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'navi',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorKey('identifier'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'navroa',
        [
        new DisplayVector(
            'role',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('navi'),
                    ]
                ),
            ),
        new DisplayVector(
            'navi',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('role'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'navtemrola',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('navi'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'organization',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorKey('handle'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'orgrola',
        [
        new DisplayVector(
            'role',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('organization'),
                    ]
                ),
            ),
        new DisplayVector(
            'organization',
            new DisplayVectorComponent(
                [
                    new DisplayVectorEntity('role'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'role',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorKey('name'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'trailer',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorKey('identifier'),
                    ]
                ),
            ),
        ]
    ),
    new DisplayVectorBundle(
        'user',
        [
        new DisplayVector(
            null,
            new DisplayVectorComponent(
                [
                    new DisplayVectorKey('surname'),
                    new DisplayVectorKey('givenName'),
                    ]
                ),
            ),
        ]
    ),
)