import {SOPostDistributionday} from '../../core/distributionday/so-post-distributionday';
import {DistributiondayCoreBase} from './distributionday.core.base';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {FormField, SelectionOption} from 'lionheartland';
import {postDistributiondayFormFieldOptions} from './post-distributionday-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionmonthCore} from '../distributionmonth/distributionmonth.core';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPostDistributionday extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postDistributiondayFormFieldOptions);

  RequestClass = SOPostDistributionday

  constructor(
    public entityCore: DistributiondayCoreBase
  ) {
    super();
    if (postDistributiondayFormFieldOptions.distributionmonth) {
      postDistributiondayFormFieldOptions.distributionmonth.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('day'),
      this.getFormValueFromIdentifier(
        'distributionmonth',
        SOGetDistributionmonth,
      ),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}