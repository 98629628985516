import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOPriceService} from '../../core/price/eo-price';
import {SOGetPrice} from '../../core/price/so-get-price';
import {RAPutPrice} from './ra-put-price';
import {RAPostPrice} from './ra-post-price';
import {RADeletePrice} from './ra-delete-price';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEPrice } from "./ce-price"


export class PriceCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'price';
  title = 'Price';
  ceProtocol: CEConstructor = CEPrice

  entityService: EOPriceService;
  soGetClass = SOGetPrice;
  soGet: SOGetPrice;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOPriceService
    );
    this.adapters = [
      new RAPostPrice(this),
      new RAPutPrice(this),
      new RADeletePrice(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPrice();
    this.soGet.queryOptions.limit = this.limit;
  }


}