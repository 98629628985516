import { SOGetLine } from '../line/so-get-line';
import { SOGetTag } from '../tag/so-get-tag';


export class SOPutDcs {

  constructor(
    public uid: string,
    public name: string = null,
    public endpoint: string = null,
    public line: SOGetLine = null,
    public tags: SOGetTag[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDcs(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'endpoint' in params ? params.endpoint : null,
      'line' in params ? params.line : null,
      'tags' in params ? params.tags : null,
    );
  }
}
