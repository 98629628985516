import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostSlotFormFields {

    public status: FormField
    public slotNumber: FormField
    public start: FormField
    public end: FormField
    public account: FormField
    public tempclin: FormField
    public loadType: FormField
    public distribution: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          true,
        )
        this.slotNumber = new FormField(
          'slotNumber',
          'Slot number',
          null,
          FormFieldType.text,
          false,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          true,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.tempclin = new FormField(
          'tempclin',
          'Tempclin',
          'tempclin',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.loadType = new FormField(
          'loadType',
          'Load type',
          null,
          FormFieldType.text,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostSlotFormFields()

export const postSlotFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
  slotNumber: fields.slotNumber,
  start: fields.start,
  end: fields.end,
  tempclin: fields.tempclin,
  loadType: fields.loadType,
};