import {SORespPlant} from "../core/plant/so-resp-plant";
import {
    isDstObserved
} from "lionheartland";

export function getDateInPlantTimeFromServerDate(serverDate, plant: SORespPlant) {
    const date = new Date(serverDate);
    if (plant.daylightSavingsObserved && isDstObserved(date)) {
        date.setUTCHours(date.getUTCHours() + (plant.timeZoneOffset + 1));
    } else {
        date.setUTCHours(date.getHours() + (plant.timeZoneOffset));
    }
    return date;
}
