import {SORespChecklistitem} from '../../core/checklistitem/so-resp-checklistitem';
import {ChecklistitemCore} from './checklistitem.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutChecklistitemFormFields} from './put-checklistitem-form-fields';
import { CEChecklistitemAttributes } from './ce-checklistitem-attributes'


export class CEChecklistitemBase extends CEBase {

    public details: CEChecklistitemAttributes;
    public fromFields: PutChecklistitemFormFields = new PutChecklistitemFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ChecklistitemCore,
        public entity: SORespChecklistitem,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEChecklistitemAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.body)
        this.attributes.push(this.details.index)
        this.attributes.push(this.details.required)
        this.attributes.push(this.details.checked)
        this.attributes.push(this.details.checkedDatetime)
        this.attributes.push(this.details.checklist)

    }
}