import {SORespRelease} from '../../core/release/so-resp-release';
import {ReleaseCore} from './release.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutReleaseFormFields} from './put-release-form-fields';
import {SOGetRelease} from '../../core/release/so-get-release';
import {SOGetBacklogitem} from '../../core/backlogitem/so-get-backlogitem';


export class CEReleaseAttributesBase {

    dateCreated: DetailEntry
    name: DetailEntry
    summary: DetailEntry
    dateScheduled: DetailEntry
    dateDeployed: DetailEntry
    state: DetailEntry
    description: DetailEntry
    message: DetailEntry
    backlogitems: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRelease,
        app: GasDirectApp,
        formFields: PutReleaseFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.summary = new DetailEntry(
            'Summary',
            entity.summary,
            'summary',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateScheduled = new DetailEntry(
            'Date scheduled',
            app.activeUserService.time.dateAsString(entity.dateScheduled),
            'dateScheduled',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateDeployed = new DetailEntry(
            'Date deployed',
            app.activeUserService.time.dateAsString(entity.dateDeployed),
            'dateDeployed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.message = new DetailEntry(
            'Message',
            entity.message,
            'message',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const backlogitemsCore = app.makeCore( 'backlogitem');
        backlogitemsCore.soGet = SOGetBacklogitem.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            backlogitemsCore
        )
        backlogitemsCore.title = heading ? heading : backlogitemsCore.title
        backlogitemsCore.listTitle = 'Backlogitems'

        backlogitemsCore.configureForDisplay(parentEntityViewSpec)

        this.backlogitems = new DetailEntry(
          heading ? heading : backlogitemsCore.listTitle,
          null,
          'backlogitems',
          BaseDetailType.embeddedList,
          backlogitemsCore,
          EditableType.never
        )
    }
}