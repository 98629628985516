

export class SOPostExternalindexprice {

  constructor(
    public data: any,
    public EventProcessedUtcTime: string,
    public PartitionId: string,
    public EventEnqueuedUtcTime: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostExternalindexprice(
      'data' in params ? params.data : null,
      'EventProcessedUtcTime' in params ? params.EventProcessedUtcTime : null,
      'PartitionId' in params ? params.PartitionId : null,
      'EventEnqueuedUtcTime' in params ? params.EventEnqueuedUtcTime : null,
    );
  }
}
