import {SOPostRelease} from '../../core/release/so-post-release';
import {ReleaseCoreBase} from './release.core.base';
import {SOGetRelease} from '../../core/release/so-get-release';
import {FormField, SelectionOption} from 'lionheartland';
import {postReleaseFormFieldOptions} from './post-release-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostRelease extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postReleaseFormFieldOptions);

  RequestClass = SOPostRelease

  constructor(
    public entityCore: ReleaseCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}