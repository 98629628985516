import { SOGetAccount } from '../account/so-get-account';
import { SOGetOrder } from '../order/so-get-order';
import { SOGetTrailer } from '../trailer/so-get-trailer';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetPrice } from '../price/so-get-price';
import { SOGetAgreemonth } from '../agreemonth/so-get-agreemonth';
import { SOGetAgreement } from '../agreement/so-get-agreement';
import { SOGetHmonth } from '../hmonth/so-get-hmonth';
import { SOGetDistributionday } from '../distributionday/so-get-distributionday';
import {ENLoadStatus} from '../../enums';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetLoad implements QueryProtocol {

  constructor(
    public uid: string = null,
    public status: ENLoadStatus[] = null,
    public loadCode: string = null,
    public location: string = null,
    public ref: string = null,
    public account: SOGetAccount = null,
    public order: SOGetOrder = null,
    public trailer: SOGetTrailer = null,
    public carrier: SOGetOrganization = null,
    public price: SOGetPrice = null,
    public agreemonth: SOGetAgreemonth = null,
    public agreement: SOGetAgreement = null,
    public hmonth: SOGetHmonth = null,
    public distributionday: SOGetDistributionday = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetLoad(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
      'loadCode' in params ? params.loadCode : null,
      'location' in params ? params.location : null,
      'ref' in params ? params.ref : null,
      'account' in params ? params.account : null,
      'order' in params ? params.order : null,
      'trailer' in params ? params.trailer : null,
      'carrier' in params ? params.carrier : null,
      'price' in params ? params.price : null,
      'agreemonth' in params ? params.agreemonth : null,
      'agreement' in params ? params.agreement : null,
      'hmonth' in params ? params.hmonth : null,
      'distributionday' in params ? params.distributionday : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
