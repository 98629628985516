import {SORespDistributionreport} from '../../core/distributionreport/so-resp-distributionreport';
import {DistributionreportCore} from './distributionreport.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributionreportFormFields} from './put-distributionreport-form-fields';
import {SOGetDistributionreport} from '../../core/distributionreport/so-get-distributionreport';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';
import {SOGetAccountreport} from '../../core/accountreport/so-get-accountreport';


export class CEDistributionreportAttributesBase {

    ident: DetailEntry
    distribution: DetailEntry
    timeWindow: DetailEntry
    start: DetailEntry
    end: DetailEntry
    tankersScheduled: DetailEntry
    tankersActual: DetailEntry
    supertankersScheduled: DetailEntry
    supertankersActual: DetailEntry
    revenueProjected: DetailEntry
    revenueActual: DetailEntry
    revenueContracted: DetailEntry
    revenueScheduled: DetailEntry
    loadsActual: DetailEntry
    loadsProjected: DetailEntry
    loadsContracted: DetailEntry
    loadsScheduled: DetailEntry
    volumeActual: DetailEntry
    volumeProjected: DetailEntry
    volumeContracted: DetailEntry
    volumeScheduled: DetailEntry
    waspProjected: DetailEntry
    waspScheduled: DetailEntry
    waspContracted: DetailEntry
    waspActual: DetailEntry
    averageLoads: DetailEntry
    averageVolume: DetailEntry
    averageUnits: DetailEntry
    volumeDelta: DetailEntry
    loadsDelta: DetailEntry
    waspDelta: DetailEntry
    revenueDelta: DetailEntry
    distributionday: DetailEntry
    distributionmonth: DetailEntry
    distributionyear: DetailEntry
    accountreports: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDistributionreport,
        app: GasDirectApp,
        formFields: PutDistributionreportFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.ident = new DetailEntry(
            'Ident',
            entity.ident,
            'ident',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.ident
        )

        this.timeWindow = new DetailEntry(
            'Time window',
            entity.timeWindow,
            'timeWindow',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.tankersScheduled = new DetailEntry(
            'Tankers scheduled',
            entity.tankersScheduled,
            'tankersScheduled',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.tankersActual = new DetailEntry(
            'Tankers actual',
            entity.tankersActual,
            'tankersActual',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.supertankersScheduled = new DetailEntry(
            'Supertankers scheduled',
            entity.supertankersScheduled,
            'supertankersScheduled',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.supertankersActual = new DetailEntry(
            'Supertankers actual',
            entity.supertankersActual,
            'supertankersActual',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.revenueProjected = new DetailEntry(
            'Revenue projected',
            entity.revenueProjected,
            'revenueProjected',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.revenueActual = new DetailEntry(
            'Revenue actual',
            entity.revenueActual,
            'revenueActual',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.revenueContracted = new DetailEntry(
            'Revenue contracted',
            entity.revenueContracted,
            'revenueContracted',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.revenueScheduled = new DetailEntry(
            'Revenue scheduled',
            entity.revenueScheduled,
            'revenueScheduled',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadsActual = new DetailEntry(
            'Loads actual',
            entity.loadsActual,
            'loadsActual',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadsProjected = new DetailEntry(
            'Loads projected',
            entity.loadsProjected,
            'loadsProjected',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadsContracted = new DetailEntry(
            'Loads contracted',
            entity.loadsContracted,
            'loadsContracted',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadsScheduled = new DetailEntry(
            'Loads scheduled',
            entity.loadsScheduled,
            'loadsScheduled',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.volumeActual = new DetailEntry(
            'Volume actual',
            entity.volumeActual,
            'volumeActual',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.volumeProjected = new DetailEntry(
            'Volume projected',
            entity.volumeProjected,
            'volumeProjected',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.volumeContracted = new DetailEntry(
            'Volume contracted',
            entity.volumeContracted,
            'volumeContracted',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.volumeScheduled = new DetailEntry(
            'Volume scheduled',
            entity.volumeScheduled,
            'volumeScheduled',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.waspProjected = new DetailEntry(
            'Wasp projected',
            entity.waspProjected,
            'waspProjected',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.waspScheduled = new DetailEntry(
            'Wasp scheduled',
            entity.waspScheduled,
            'waspScheduled',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.waspContracted = new DetailEntry(
            'Wasp contracted',
            entity.waspContracted,
            'waspContracted',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.waspActual = new DetailEntry(
            'Wasp actual',
            entity.waspActual,
            'waspActual',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.averageLoads = new DetailEntry(
            'Average loads',
            entity.averageLoads,
            'averageLoads',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.averageVolume = new DetailEntry(
            'Average volume',
            entity.averageVolume,
            'averageVolume',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.averageUnits = new DetailEntry(
            'Average units',
            entity.averageUnits,
            'averageUnits',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.volumeDelta = new DetailEntry(
            'Volume delta',
            entity.volumeDelta,
            'volumeDelta',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadsDelta = new DetailEntry(
            'Loads delta',
            entity.loadsDelta,
            'loadsDelta',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.waspDelta = new DetailEntry(
            'Wasp delta',
            entity.waspDelta,
            'waspDelta',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.revenueDelta = new DetailEntry(
            'Revenue delta',
            entity.revenueDelta,
            'revenueDelta',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        this.distribution.singleFieldSelect = true

        const distributiondayCore = app.makeCore( 'distributionday');
        distributiondayCore.soGet = SOGetDistributionday.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributiondayCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributiondayCore
        )
        distributiondayCore.title = heading ? heading : distributiondayCore.title
        distributiondayCore.listTitle = 'Distributionday'

        distributiondayCore.configureForDisplay(parentEntityViewSpec)

        this.distributionday = new DetailEntry(
          heading ? heading : distributiondayCore.listTitle,
          null,
          'distributionday',
          BaseDetailType.pushedDetail,
          distributiondayCore,
          EditableType.never
        )
        this.distributionday.singleFieldSelect = true

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true

        const distributionyearCore = app.makeCore( 'distributionyear');
        distributionyearCore.soGet = SOGetDistributionyear.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionyearCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionyearCore
        )
        distributionyearCore.title = heading ? heading : distributionyearCore.title
        distributionyearCore.listTitle = 'Distributionyear'

        distributionyearCore.configureForDisplay(parentEntityViewSpec)

        this.distributionyear = new DetailEntry(
          heading ? heading : distributionyearCore.listTitle,
          null,
          'distributionyear',
          BaseDetailType.pushedDetail,
          distributionyearCore,
          EditableType.never
        )
        this.distributionyear.singleFieldSelect = true

        const accountreportsCore = app.makeCore( 'accountreport');
        accountreportsCore.soGet = SOGetAccountreport.construct({
          distributionreport: new SOGetDistributionreport(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountreportsCore
        )
        accountreportsCore.title = heading ? heading : accountreportsCore.title
        accountreportsCore.listTitle = 'Accountreports'

        accountreportsCore.configureForDisplay(parentEntityViewSpec)

        this.accountreports = new DetailEntry(
          heading ? heading : accountreportsCore.listTitle,
          null,
          'accountreports',
          BaseDetailType.embeddedList,
          accountreportsCore,
          EditableType.never
        )
    }
}