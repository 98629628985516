import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostLngloadFormFields {

    public load: FormField

    constructor() {

        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          true,
        )

    }
}

const fields = new PostLngloadFormFields()

export const postLngloadFormFieldOptions: {[key: string]: FormField} = {
  load: fields.load,
};