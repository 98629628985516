import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutChecklistitem } from './so-put-checklistitem';
import { SOGetChecklistitem } from './so-get-checklistitem';
import { SOPostChecklistitem } from './so-post-checklistitem';
import { SODeleteChecklistitem } from './so-delete-checklistitem';
import {SORespChecklistitem} from './so-resp-checklistitem';
import {SOPluralChecklistitem} from './so-plural-checklistitem';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOChecklistitemService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'checklistitem',
      entityCore
    );
  }

  /** GET Checklistitem by id. Will 404 if id not found */
  get(request: SOGetChecklistitem): Observable<SOPluralChecklistitem> {
    return this.httpClient.get<SOPluralChecklistitem>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralChecklistitem, resp) }),
      tap(_ => this.log(`fetched Checklistitem`)),
      catchError(this.handleError<SOPluralChecklistitem>(`get Checklistitem`))
    );
  }

  /** POST: add a new Checklistitem to the server */
  post(request: SOPostChecklistitem): Observable<SORespChecklistitem> {
    return this.httpClient.post<SORespChecklistitem>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespChecklistitem, resp) }),
      tap((response: SORespChecklistitem) => this.log(`added Checklistitem w/ id=${response.uid}`)),
      catchError(this.handleError<SORespChecklistitem>('Checklistitem post'))
    );
  }

  /** PUT: update Checklistitem  */
  put(request: SOPutChecklistitem): Observable<SORespChecklistitem> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespChecklistitem>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespChecklistitem, resp) }),
      tap((response: SORespChecklistitem) => this.log(`edited Checklistitem w/ uid=${uid}`)),
      catchError(this.handleError<SORespChecklistitem>('Checklistitem put'))
    );
  }

  /** DELETE: delete the Checklistitem from the server */
  delete(request: SODeleteChecklistitem): Observable<SORespChecklistitem> {
    return this.httpClient.delete<SORespChecklistitem>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Checklistitem uid=${request.uid}`)),
      catchError(this.handleError<SORespChecklistitem>('delete Checklistitem'))
    );
  }
}
