import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSlotoptionService} from '../../core/slotoption/eo-slotoption';
import {SOGetSlotoption} from '../../core/slotoption/so-get-slotoption';
import {RAPutSlotoption} from './ra-put-slotoption';
import {RAPostSlotoption} from './ra-post-slotoption';
import {RADeleteSlotoption} from './ra-delete-slotoption';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CESlotoption } from "./ce-slotoption"


export class SlotoptionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'slotoption';
  title = 'Slotoption';
  ceProtocol: CEConstructor = CESlotoption

  entityService: EOSlotoptionService;
  soGetClass = SOGetSlotoption;
  soGet: SOGetSlotoption;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSlotoptionService
    );
    this.adapters = [
      new RAPostSlotoption(this),
      new RAPutSlotoption(this),
      new RADeleteSlotoption(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSlotoption();
    this.soGet.queryOptions.limit = this.limit;
  }


}