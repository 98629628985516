import {SOPostTaskreport} from '../../core/taskreport/so-post-taskreport';
import {TaskreportCoreBase} from './taskreport.core.base';
import {SOGetTaskreport} from '../../core/taskreport/so-get-taskreport';
import {FormField, SelectionOption} from 'lionheartland';
import {postTaskreportFormFieldOptions} from './post-taskreport-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPostTaskreport extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTaskreportFormFieldOptions);

  RequestClass = SOPostTaskreport

  constructor(
    public entityCore: TaskreportCoreBase
  ) {
    super();
    if (postTaskreportFormFieldOptions.distribution) {
      postTaskreportFormFieldOptions.distribution.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
      this.getFormValueFromIdentifier('dateRequested'),
      this.getFormValueFromIdentifier('dateStart'),
      this.getFormValueFromIdentifier('dateEnd'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}