import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOLineService} from '../../core/line/eo-line';
import {SOGetLine} from '../../core/line/so-get-line';
import {RAPutLine} from './ra-put-line';
import {RAPostLine} from './ra-post-line';
import {RADeleteLine} from './ra-delete-line';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CELine } from "./ce-line"


export class LineCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'line';
  title = 'Line';
  ceProtocol: CEConstructor = CELine

  entityService: EOLineService;
  soGetClass = SOGetLine;
  soGet: SOGetLine;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOLineService
    );
    this.adapters = [
      new RAPostLine(this),
      new RAPutLine(this),
      new RADeleteLine(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetLine();
    this.soGet.queryOptions.limit = this.limit;
  }


}