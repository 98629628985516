import {SOPostInvoiceitem} from '../../core/invoiceitem/so-post-invoiceitem';
import {InvoiceitemCoreBase} from './invoiceitem.core.base';
import {SOGetInvoiceitem} from '../../core/invoiceitem/so-get-invoiceitem';
import {FormField, SelectionOption} from 'lionheartland';
import {postInvoiceitemFormFieldOptions} from './post-invoiceitem-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {InvoiceCore} from '../invoice/invoice.core';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';

export class RAPostInvoiceitem extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postInvoiceitemFormFieldOptions);

  RequestClass = SOPostInvoiceitem

  constructor(
    public entityCore: InvoiceitemCoreBase
  ) {
    super();
    if (postInvoiceitemFormFieldOptions.invoice) {
      postInvoiceitemFormFieldOptions.invoice.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier('unitCost'),
      this.getFormValueFromIdentifier(
        'invoice',
        SOGetInvoice,
      ),
      this.getFormValueFromIdentifier('units'),
      this.getFormValueFromIdentifier('code'),
      this.getFormValueFromIdentifier('codeDefinition'),
      this.getFormValueFromIdentifier('baseUnits'),
      this.getFormValueFromIdentifier('baseQuantity'),
      this.getFormValueFromIdentifier('conversion'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}