import {SORespProvider} from '../../core/provider/so-resp-provider';
import {ProviderCore} from './provider.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutProviderFormFields} from './put-provider-form-fields';
import { CEProviderAttributes } from './ce-provider-attributes'


export class CEProviderBase extends CEBase {

    public details: CEProviderAttributes;
    public fromFields: PutProviderFormFields = new PutProviderFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ProviderCore,
        public entity: SORespProvider,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEProviderAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.organization)

    }
}