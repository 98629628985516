import { SOGetHestatus } from '../hestatus/so-get-hestatus';
import { SOGetContainersession } from '../containersession/so-get-containersession';


export class SOPostHestatusitem {

  constructor(
    public hestatus: SOGetHestatus,
    public containersession: SOGetContainersession,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostHestatusitem(
      'hestatus' in params ? params.hestatus : null,
      'containersession' in params ? params.containersession : null,
    );
  }
}
