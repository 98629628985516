import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOFeatureascService} from '../../core/featureasc/eo-featureasc';
import {SOGetFeatureasc} from '../../core/featureasc/so-get-featureasc';
import {RAPutFeatureasc} from './ra-put-featureasc';
import {RAPostFeatureasc} from './ra-post-featureasc';
import {RADeleteFeatureasc} from './ra-delete-featureasc';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEFeatureasc } from "./ce-featureasc"


export class FeatureascCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'featureasc';
  title = 'Featureasc';
  ceProtocol: CEConstructor = CEFeatureasc

  entityService: EOFeatureascService;
  soGetClass = SOGetFeatureasc;
  soGet: SOGetFeatureasc;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOFeatureascService
    );
    this.adapters = [
      new RAPostFeatureasc(this),
      new RAPutFeatureasc(this),
      new RADeleteFeatureasc(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetFeatureasc();
    this.soGet.queryOptions.limit = this.limit;
  }


}