import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOLoadfeeService} from '../../core/loadfee/eo-loadfee';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {RAPutLoadfee} from './ra-put-loadfee';
import {RAPostLoadfee} from './ra-post-loadfee';
import {RADeleteLoadfee} from './ra-delete-loadfee';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CELoadfee } from "./ce-loadfee"


export class LoadfeeCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'loadfee';
  title = 'Loadfee';
  ceProtocol: CEConstructor = CELoadfee

  entityService: EOLoadfeeService;
  soGetClass = SOGetLoadfee;
  soGet: SOGetLoadfee;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOLoadfeeService
    );
    this.adapters = [
      new RAPostLoadfee(this),
      new RAPutLoadfee(this),
      new RADeleteLoadfee(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetLoadfee();
    this.soGet.queryOptions.limit = this.limit;
  }


}