import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTankreportFormFields {

    public dateCreated: FormField
    public value: FormField
    public tank: FormField

    constructor() {

        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          false,
        )
        this.value = new FormField(
          'value',
          'Value',
          null,
          FormFieldType.text,
          false,
        )
        this.tank = new FormField(
          'tank',
          'Tank',
          'tank',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutTankreportFormFields()

export const putTankreportFormFieldOptions: {[key: string]: FormField} = {
  dateCreated: fields.dateCreated,
  value: fields.value,
  tank: fields.tank,
};