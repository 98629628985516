import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutNavroa } from './so-put-navroa';
import { SOGetNavroa } from './so-get-navroa';
import { SOPostNavroa } from './so-post-navroa';
import { SODeleteNavroa } from './so-delete-navroa';
import {SORespNavroa} from './so-resp-navroa';
import {SOPluralNavroa} from './so-plural-navroa';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EONavroaService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'navroa',
      entityCore
    );
  }

  /** GET Navroa by id. Will 404 if id not found */
  get(request: SOGetNavroa): Observable<SOPluralNavroa> {
    return this.httpClient.get<SOPluralNavroa>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralNavroa, resp) }),
      tap(_ => this.log(`fetched Navroa`)),
      catchError(this.handleError<SOPluralNavroa>(`get Navroa`))
    );
  }

  /** POST: add a new Navroa to the server */
  post(request: SOPostNavroa): Observable<SORespNavroa> {
    return this.httpClient.post<SORespNavroa>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespNavroa, resp) }),
      tap((response: SORespNavroa) => this.log(`added Navroa w/ id=${response.uid}`)),
      catchError(this.handleError<SORespNavroa>('Navroa post'))
    );
  }

  /** PUT: update Navroa  */
  put(request: SOPutNavroa): Observable<SORespNavroa> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespNavroa>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespNavroa, resp) }),
      tap((response: SORespNavroa) => this.log(`edited Navroa w/ uid=${uid}`)),
      catchError(this.handleError<SORespNavroa>('Navroa put'))
    );
  }

  /** DELETE: delete the Navroa from the server */
  delete(request: SODeleteNavroa): Observable<SORespNavroa> {
    return this.httpClient.delete<SORespNavroa>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Navroa uid=${request.uid}`)),
      catchError(this.handleError<SORespNavroa>('delete Navroa'))
    );
  }
}
