import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutAgreement } from './so-put-agreement';
import { SOGetAgreement } from './so-get-agreement';
import { SOPostAgreement } from './so-post-agreement';
import { SODeleteAgreement } from './so-delete-agreement';
import {SORespAgreement} from './so-resp-agreement';
import {SOPluralAgreement} from './so-plural-agreement';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOAgreementService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'agreement',
      entityCore
    );
  }

  /** GET Agreement by id. Will 404 if id not found */
  get(request: SOGetAgreement): Observable<SOPluralAgreement> {
    return this.httpClient.get<SOPluralAgreement>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAgreement, resp) }),
      tap(_ => this.log(`fetched Agreement`)),
      catchError(this.handleError<SOPluralAgreement>(`get Agreement`))
    );
  }

  /** POST: add a new Agreement to the server */
  post(request: SOPostAgreement): Observable<SORespAgreement> {
    return this.httpClient.post<SORespAgreement>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAgreement, resp) }),
      tap((response: SORespAgreement) => this.log(`added Agreement w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAgreement>('Agreement post'))
    );
  }

  /** PUT: update Agreement  */
  put(request: SOPutAgreement): Observable<SORespAgreement> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAgreement>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAgreement, resp) }),
      tap((response: SORespAgreement) => this.log(`edited Agreement w/ uid=${uid}`)),
      catchError(this.handleError<SORespAgreement>('Agreement put'))
    );
  }

  /** DELETE: delete the Agreement from the server */
  delete(request: SODeleteAgreement): Observable<SORespAgreement> {
    return this.httpClient.delete<SORespAgreement>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Agreement uid=${request.uid}`)),
      catchError(this.handleError<SORespAgreement>('delete Agreement'))
    );
  }
}
