import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSaleaccountascService} from '../../core/saleaccountasc/eo-saleaccountasc';
import {SOGetSaleaccountasc} from '../../core/saleaccountasc/so-get-saleaccountasc';
import {RAPutSaleaccountasc} from './ra-put-saleaccountasc';
import {RAPostSaleaccountasc} from './ra-post-saleaccountasc';
import {RADeleteSaleaccountasc} from './ra-delete-saleaccountasc';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import { CESaleaccountasc } from "./ce-saleaccountasc"


export class SaleaccountascCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'saleaccountasc';
  title = 'Saleaccountasc';
  ceProtocol: CEConstructor = CESaleaccountasc

  entityService: EOSaleaccountascService;
  soGetClass = SOGetSaleaccountasc;
  soGet: SOGetSaleaccountasc;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSaleaccountascService
    );
    this.adapters = [
      new RAPostSaleaccountasc(this),
      new RAPutSaleaccountasc(this),
      new RADeleteSaleaccountasc(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSaleaccountasc();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}