import {SOPostActivity} from '../../core/activity/so-post-activity';
import {ActivityCoreBase} from './activity.core.base';
import {SOGetActivity} from '../../core/activity/so-get-activity';
import {FormField, SelectionOption} from 'lionheartland';
import {postActivityFormFieldOptions} from './post-activity-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {OrderCore} from '../order/order.core';
import {SOGetOrder} from '../../core/order/so-get-order';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';
import {UserCore} from '../user/user.core';
import {SOGetUser} from '../../core/user/so-get-user';
import {ContainersessionCore} from '../containersession/containersession.core';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SlotCore} from '../slot/slot.core';
import {SOGetSlot} from '../../core/slot/so-get-slot';

export class RAPostActivity extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postActivityFormFieldOptions);

  RequestClass = SOPostActivity

  constructor(
    public entityCore: ActivityCoreBase
  ) {
    super();
    if (postActivityFormFieldOptions.order) {
      postActivityFormFieldOptions.order.makeCore = true;
    }
    if (postActivityFormFieldOptions.load) {
      postActivityFormFieldOptions.load.makeCore = true;
    }
    if (postActivityFormFieldOptions.user) {
      postActivityFormFieldOptions.user.makeCore = true;
    }
    if (postActivityFormFieldOptions.containersession) {
      postActivityFormFieldOptions.containersession.makeCore = true;
    }
    if (postActivityFormFieldOptions.slot) {
      postActivityFormFieldOptions.slot.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('summary'),
      this.getFormValueFromIdentifier('body'),
      this.getFormValueFromIdentifier(
        'order',
        SOGetOrder,
      ),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
      this.getFormValueFromIdentifier(
        'user',
        SOGetUser,
      ),
      this.getFormValueFromIdentifier(
        'containersession',
        SOGetContainersession,
      ),
      this.getFormValueFromIdentifier(
        'slot',
        SOGetSlot,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}