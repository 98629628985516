import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOBaysessionService} from '../../core/baysession/eo-baysession';
import {SOGetBaysession} from '../../core/baysession/so-get-baysession';
import {RAPutBaysession} from './ra-put-baysession';
import {RAPostBaysession} from './ra-post-baysession';
import {RADeleteBaysession} from './ra-delete-baysession';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEBaysession } from "./ce-baysession"


export class BaysessionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'baysession';
  title = 'Baysession';
  ceProtocol: CEConstructor = CEBaysession

  entityService: EOBaysessionService;
  soGetClass = SOGetBaysession;
  soGet: SOGetBaysession;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOBaysessionService
    );
    this.adapters = [
      new RAPostBaysession(this),
      new RAPutBaysession(this),
      new RADeleteBaysession(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBaysession();
    this.soGet.queryOptions.limit = this.limit;
  }


}