import {GasDirectAppBase} from "./land-app-base";
import {authConfig} from "./auth-config";
import {
  createCEProtocol,
  DetailEntry,
  EntityViewSpecProtocol,
  NavItemLocation,
  SbNavItem,
  SvSidenavComponent
} from "lionheartland";
import {SOGetRole} from "./core/role/so-get-role";
import {NavroaCore} from "./entities/navroa/navroa.core";
import {SOGetNavroa} from "./core/navroa/so-get-navroa";
import {SORespGrant} from "./core/grant/so-resp-grant";
import {environment} from "../environments/environment";
import {SORespToken} from "./core/token/so-resp-token";
import {SORespAccount} from "./core/account/so-resp-account";
import {SORespDistribution} from "./core/distribution/so-resp-distribution";
import {SORespContainersession} from "./core/containersession/so-resp-containersession";
import {BoardCore} from "./entities/load/board.core";
import {SlotCalendarCore} from "./entities/slot/slot-calendar.core";
import {StatusCore} from "./entities/load/status.core";

export class GasDirectApp extends GasDirectAppBase {

  unknowns: SORespContainersession[];
  hasSchedulerRole = false;
  noCancelWithin48 = false;

  passwordLoginEnabled = false;
  showRegistration = false;

  get timeZone(): string {
    return [
      'Shute Creek LNG',
      'Shute Creek Helium',
    ].includes(this.distribution.name) ? 'America/Denver' : 'America/Chicago'
  };

  get activeGrant(): SORespGrant {
    return this.activeUserService.activeGrant as unknown as SORespGrant
  }

  get isHelium(): boolean {
    return !!this.activeUserService.getScope('helium')
  }

  get isCustomer(): boolean {
    return !!this.activeUserService.getScope('customer')
  }

  get account(): SORespAccount {
    return this.activeUserService.getScope('account')
  }

  get distribution(): SORespDistribution {
    return this.activeUserService.getScope('distribution')
  }

  constructor() {
    super([]);
    this.authConfig = authConfig;
    this.emailVerificationRequired = false;
    this.navShowAppName = false;
    this.navLogoMain = 'assets/images/xom_logo.svg';
    this.navLogoSecondary = 'assets/images/gas-direct-small.png';
    this.navLogoSecondaryClass = 'logoGasDirect';
    this.showStageBanner = !environment.production;
    this.title = environment.pageTitle;
    this.formatAsString.infinityValues = [
      9223372036854775807,
      2147483647
    ];
    this.customEntityProcessors['board'] = BoardCore;
    this.customEntityProcessors['status'] = StatusCore;
    this.customEntityProcessors['slot-calendar'] = SlotCalendarCore;
  }

  startSetNavItems(sideNav: SvSidenavComponent) {
    console.log('starting to set nav items')

    if (this.activeGrant.role.account) {
      this.getNavItemsFromEntityIdentifier('account', sideNav);
    } else if (this.activeGrant.role.distribution) {
      this.getNavItemsFromEntityIdentifier('distribution', sideNav);
    } else {
      this.getNavItemsFromViewGrants(sideNav);
    }
  }

  private getNavItemsFromViewGrants(sideNav: SvSidenavComponent) {
    const viewGrantCore = new NavroaCore(this.activeUserService)
    viewGrantCore.entityService.get(
      new SOGetNavroa(
        null,
        new SOGetRole(this.activeUserService.activeGrant.role.uid)
      )
    ).subscribe(resp => {
      if (resp) {
        this.activeUserService.mainNavItems = [];
        this.activeUserService.moreNavItems = [];
        for (const navItem of this.navItems) {
          const viewGrant = resp.objects.find(obj => obj.navi.identifier === navItem.id.toLowerCase())
          if (viewGrant) {
            if (navItem.location == NavItemLocation.main) {
              this.activeUserService.mainNavItems.push(navItem)
            } else if (navItem.location == NavItemLocation.more) {
              this.activeUserService.moreNavItems.push(navItem)
            }
          }
        }
        sideNav.finishSetMainNavItems(this.activeUserService.mainNavItems);
      }
    })
  }

  private getNavItemsFromEntityIdentifier(key: string, sideNav: SvSidenavComponent) {
    this.activeUserService.mainNavItems = [];
    this.activeUserService.moreNavItems = [];

    if (['scheduler', 'operator'].includes(this.activeGrant.role.identifier)) {
      this.activeUserService.mainNavItems.push(
        new SbNavItem(
          'slotCalendar',
          'slot-calendar',
          '/slot-calendar',
          'View the slot calendar',
          NavItemLocation.main,
        ),
        new SbNavItem(
          'board',
          'board',
          '/board',
          'View the board',
          NavItemLocation.main,
        ),
        new SbNavItem(
          'status',
          'status',
          '/status',
          'View the status',
          NavItemLocation.main,
        )
      )
    }

    if (['customerManager'].includes(this.activeGrant.role.identifier)) {
      this.activeUserService.mainNavItems.push(
        new SbNavItem(
          'slotCalendar',
          'slot-calendar',
          '/slot-calendar',
          'View the slot calendar',
          NavItemLocation.main,
        )
      )
    }

    const core = this.makeCore(key);
    const ceProtocol = createCEProtocol(
      core.ceProtocol,
      null,
      core,
      this.activeGrant.role[key],
      this
    );
    const navigables = ceProtocol.navigationAttributes;
    for (const navItem of this.navItems) {
      const viewGrant = navigables.find(
        obj => obj.entityCore.type.toLowerCase() === navItem.id.toLowerCase()
      )
      if (viewGrant) {
        if (navItem.location == NavItemLocation.main) {
          this.activeUserService.mainNavItems.push(navItem)
        } else if (navItem.location == NavItemLocation.more) {
          this.activeUserService.moreNavItems.push(navItem)
        }
      }
    }
    const navItem = this.navItems.find(obj => obj.id === key);
    if (navItem) {
      this.activeUserService.mainNavItems.push(
        new SbNavItem(
          navItem.id,
          navItem.entityType,
          `${navItem.routerLink}/${this.activeGrant.role[key].uid}`,
          navItem.description,
          navItem.location,
          navItem.edit,
          navItem.displayName,
          navItem.routerState,
        )
      )
    }

    sideNav.finishSetMainNavItems(
      this.activeUserService.mainNavItems,
      this.activeGrant.role.distribution ? this.activeGrant.role.distribution.name : 'Menu'
    );
  }

  getGrantSubText(grant: SORespGrant) {
    return grant.role && grant.role.account ? grant.role.account.customer.companyName : '';
  }

  haveNewToken(value: SORespToken) {
    this.unknowns = [];
    if (value.roleGrant.role.distribution) {
      this.activeUserService.setScope('distribution', value.roleGrant.role.distribution)
      if (value.roleGrant.role.distribution.name == 'Shute Creek Helium') {
        this.activeUserService.setScope('helium', true);
        this.activeUserService.removeScope('lng');
      } else if (['Shute Creek LNG', 'Hawkins LNG'].includes(value.roleGrant.role.distribution.name)) {
        this.activeUserService.setScope('lng', true);
        this.activeUserService.removeScope('helium');
      }
    } else {
      this.activeUserService.removeScope('distribution');
      this.activeUserService.removeScope('helium');
      this.activeUserService.removeScope('lng');
    }

    if (value.roleGrant.role.account) {
      this.activeUserService.setScope('account', value.roleGrant.role.account)
      this.activeUserService.setScope('customer', value.roleGrant.role.account.customer)
    } else {
      this.activeUserService.removeScope('account');
      this.activeUserService.removeScope('customer');
    }
  }

  canDelete(type: string) {

    const providerDelete = [
      'grant',
      'agreement',
      'contract',
      'clin',
      'price',
      'subscription',
      'trailerauth',
      'message'
    ];
    if (['scheduler', 'productionManager', 'superUser'].includes(this.activeGrant.role.identifier)) {
      providerDelete.push(
        'checklist',
        'checklistitem',
        'disttempagreement',
        'tempagreetract',
        'temptraclin',
        'tempagreement',
        'temptract',
        'tempclin',
        'temprice',
        'order',
        'loadfee',
        'slot',
        'weight',
        'item',
        'trailer',
        'tempclin',
        'fee',
        'task',
        'taskreport',
        'hemon',
        'containersession',
        'sale',
        'bid',
        'saleaccountasc',
        'bol',
        'account',
        'delta',
        'deltaweight',
        'hestatus'
      );

      // if (environment.extendedTroubleshooting) {
      //   providerDelete.push(
      //     'taskreport'
      //   );
      // }
    }

    if (['superUser'].includes(this.activeGrant.role.identifier)) {
      providerDelete.push(
        'featureasc'
      );
    }

    if (['operator'].includes(this.activeGrant.role.identifier)) {
      providerDelete.push(
        'weight',
        'hemon',
        'impurity',
        'containersession',
        'order', // unknown
        'bol'
      );
    }

    if (['accountant'].includes(this.activeGrant.role.identifier)) {
      providerDelete.push(
        'transaction',
        'invoiceitem'
      );
    }

    const customerDelete = [
      'subscription',
      'trailerauth',
      'trailer',
      'message'
    ];

    if (!this.isCustomer) {
      return providerDelete.includes(type);
    } else {
      return customerDelete.includes(type);
    }
  }

  canEdit(entityViewSpec: EntityViewSpecProtocol, detail: DetailEntry = null) {
    const accessIdentifier = this.makeAccessIdentifier(entityViewSpec, detail);
    const providerEditables = [
      'status',
      'message.read',
      'agreement.state',
      'agreement.signedDocumentUrl',
      'user.organization',
      'order.status',
      'load.trailer',
      'load.carrier',
      'load.status',
      'load.destination',
      'load.weight0Manual',
      'load.weight1Manual',
      'load.weight2Manual',
      'load.weight0',
      'load.weight1',
      'load.weight2',
      'load.lngNetTargetSetpoint',
      'load.lngNetTargetSetpointManual',
      'load.nitrogenDetected',
      'load.pressure0',
      'load.pressure1',
      'load.carrierSignature',
      'load.providerSignature',
      'load.driverName',
      // 'distribution',
      'distribution.name',
      'distribution.defaultSlotsPerDay',
      'distribution.showSlotDuration',
      'distribution.providerSlotDurationHours',
      'distribution.customerSlotDurationHours',
      'distribution.conversionRate',
      'distribution.galToWeight',
      'distribution.pickupInstructions',
      'distribution.paymentInstructions',
      'distribution.departureChecklist',
      'distribution.loadingChecklist',
      'distribution.checkoutText',
      'distribution.purchaseCompleteText',
      'distribution.bolHeader',
      'distribution.bolWarning',
      'distribution.marketIndexId',
      'distribution.loadNumberStart',
      'distribution.loadNumberPrefix',
      'order.receiptUrl',
      'order.account',
      'indexprice.start',
      'indexprice.end',
      'indexprice.state',
      'indexprice.rate',
      'load.arrivalButton',
      'load.checkInButton',
      'load.finalButton',
      // 'load.coolDownDays',
      'load.rearAxelWeight',
      'load.droppedLin',
      'load.droppedLinQuestion',
      // 'load.finalAnalysis',
      // 'load.waterAnalysis',
      'load.prepVol',
      'load.prepTrailer',
      'load.location',
      'load.confirmButton',
      'load.preLoadingButton',
      'load.readyToLoadButton',
      'load.loadingButton',
      'load.shipEmpty',
      'load.loadingCompleteButton',
      'load.readyForCheckoutButton',
      'load.departedButton',
      'load.arrived',
      'load.loadingBegin',
      'load.loadingEnd',
      'load.departed',
      'load.departedButton',
      'load.order',
      // 'load.coolDownDays',
      'load.rearAxelWeight',
      // 'load.finalAnalysis',
      // 'load.waterAnalysis',
      'load.weightRequested',
      'load.weightRequestedNetGross',
      'load.postTransactionButton',
      'load.prepVol',
      'load.prepTrailer',
      'load.location',
      'load.requestedFillDate',
      'load.eta',
      'load.offering',
      'load.trailer',
      'load.shield',
      'impurity.state',
      'hemon.state',
      'weight.tare',
      'checklist.item',
      'checklist.name',
      'taskreport.status',
      'taskreport.getLastWeightsButton',
      'load.process',
      'load.chassisId',
      'load.dotInspectionDate',
      'load.dotSp',
      'load.dotE',
      'load.coolDownDays',
      'load.shield',
      'load.finalAnalysis',
      'load.waterAnalysis',
      'load.specialRequests',
      'load.eta',
      'load.requestedFillDate',
      'load.attachment',
      'load.reservation',
      'dcs.name',
      'dcs.endpoint',
      'inquiry.addCustomer',
      'slot.start',
      'slot.end',
      'account.start',
      'account.end',
      'account.exportButton',
      'disttempagreement.isDefault',
      'load.toDepartedOverride',
      'activity.body',
      'task.completed',
      'featureasc.enabled',
      'subscription.feed',
      'subscription.webAlerts',
      'subscription.mobileAlerts',
      'subscription.emailAlerts',
    ];

    if (this.isHelium) {
      providerEditables.push(
        'load.order',
        'slot.start',
        'slot.end',
      );
    } else {
      providerEditables.push(
        'load.account'
      );
    }

    if (['scheduler', 'productionManager'].includes(this.activeGrant.role.identifier)) {
      // providerEditables.push('checklistitem.body');
      // providerEditables.push('checklistitem.index');
      // providerEditables.push('checklistitem.required');
      providerEditables.push('checklist.fileLink');
      providerEditables.push('monthclose.status');
      providerEditables.push('load.reservation');
      providerEditables.push('load.offering');
      providerEditables.push('fee.displayName');
      providerEditables.push('fee.amount');
      providerEditables.push(
        'load.shipEmpty',
        'load.releaseToUnknown',
        'load.deleteButton',
        'temprice.rate',
        'order.submitButton',
        'load.agreement',
        'load.agreemonth',
        'load.prepTrailer',
        'bid.status',
        'slot.cancelReservation',
        'distributionreport.recalculate',
        'trailer.organization',
        'agreement.name',
        'agreement.description',
        'agreement.start',
        'agreement.end',
        'agreement.top',
        'agreement.acq',
        'agreement.autoRenew',
        'activity.body',
        'agreement.docusignLink',
        'load.confirmButton',
        'load.moveToScheduledButton',
        'item.quantity',
        'contract.minLoadSizeToQualify',
        'feeschedule.displayName',
        'feeschedule.start',
        'feeschedule.end',
        'price.quantity',
        'price.loadQuantity',
        'distributionday.state',
        'distributionmonth.state',
        'distributionyear.state',
        'adjustment.quantity',
        'load.agreemonth',
        'load.holdRelease',
        'account.interruptibleExpiration',
        'hemon.containersession',
        'load.agreemonth',
        'plant.closed',
        'plant.message',
        'account.salesTaxState',
        // 'price.rate',
      );
    }

    if (['scheduler', 'productionManager', 'operator'].includes(this.activeGrant.role.identifier)) {
      providerEditables.push('task.trailerGone');
      providerEditables.push('task.location');
      providerEditables.push('trailer.attachment');
      providerEditables.push('load.attachment');
      providerEditables.push(
        'load.bol',
        'load.exportStart',
        'load.exportEnd',
        'load.exportButton',
        'load.mawp',
        'slot.exportStart',
        'slot.exportEnd',
        'slot.exportButton',
        'order.exportStart',
        'order.exportEnd',
        'order.exportButton',
        'trailer.mawp',
        'trailer.mawt',
        'trailer.fillVolume',
        'trailer.chassisId',
        'trailer.dotInspectionDate',
        'trailer.dotSp',
        'trailer.dotE',
        'trailer.dotId',
        'load.shipEmpty',
        'taskreport.recalculate',
        'task.loadStatus',
        'taskreport.dateStart',
        'taskreport.dateEnd',
        'containersession.load',
        'containersession.trailer',
        'hyear.feeschedule',
        'distributionmonth.started',
        'distributionmonth.paymentsReceived',
        'distributionmonth.invoicesCreated',
        'distributionmonth.invoicesSent',
        'distributionmonth.midMonthCheckpointComplete',
        'distributionmonth.loadsAllocated',
        'distributionmonth.ordersSubmitted',
        'distributionmonth.ordersCreated',
        'distributionmonth.scheduleMade',
        'distributionmonth.closed',
        'distributionmonth.productionVolume',
        'distributionmonth.productionVolumeEstimated',
        'containersession.notes',
        'containersession.providerSignature',
        'containersession.carrierSignature',
        'containersession.departLoad',
        'containersession.arrived',
        'bol.driverName',
        'bol.rearAxelWeight',
        'bol.destination',
        'bol.providerSignature',
        'bol.carrierSignature',
        'bol.carrier',
        'trailer.linFirst',
        'siteSession.arrived',
        'siteSession.departed',
        'containersession.loadSheetId',
        'load.loadSheetId',
        'hestatus.state',
      );
    }

    if (['operator'].includes(this.activeGrant.role.identifier)) {
      providerEditables.push(
        'impurity.h20',
        'impurity.neon',
        'impurity.h2',
        'impurity.o2',
        'impurity.n2',
        'impurity.carbonMonoxide',
        'impurity.hydrocarbons',
        'impurity.carbonDioxide',
        'hemon.n2Level',
        'hemon.n2Pressure',
        'hemon.heLevel',
        'hemon.hePressure',
        'hemon.heTemp',
        'weight.amount',
        'load.weight0',
        'load.weight1',
        'load.weight2',
        'load.arrived',
        'load.departed',
        'hestatusitem.state',
        'hestatusitem.gone',
        'hestatusitem.location',
        'hestatusitem.loadStatus',
        'hestatusitem.n2Level',
        'hestatusitem.n2Pressure',
        'hestatusitem.heTemp',
        'hestatusitem.heLevel',
        'hestatusitem.hePressure',
        'hestatusitem.save',
      );
    }

    if (['scheduler'].includes(this.activeGrant.role.identifier)) {
      providerEditables.push(
        'trailer.chassisId',
        'trailer.identifier',
        'trailer.type',
        'trailer.size',
        'trailer.dotId',
        'trailer.dotInspectionDate',
        'trailer.dotE',
        'trailer.dotSp',
        'trailer.mawp',
        'trailer.mawt',
        'trailer.fillVolume',
        'slot.status',
        'slot.account',
        'agreement.feeschedule',
        'agreement.top',
        'contract.name',
        'contract.description',
        'contract.start',
        'contract.end',
        'clin.name',
        'clin.description',
        'clin.start',
        'clin.end',
        'tempclin.state',
        'loadingBegin',
        'loadingEnd',
        'weight.dateCreated',
        'weight.amount',
        'task.weight',
        'containersession.shipEmpty',
        'containersession.account',
        'agreement.variableAcq',
        'agreement.rateable',
        'agreement.prePaymentRequired',
        'account.averageLoadSize',
        'hemon.dateCreated',
        'distributionday.updateNets',
        'distributionmonth.makeDeltas',
        'distributionmonth.removeDeltas',
        'distributionmonth.makeDays',
        'distributionmonth.updateProduction',
        'distributionmonth.migrateTruckStatus',
        'distributionmonth.deleteInvoices',
        'distributionmonth.assignLngLoadsToDays',
        'distributionmonth.associateTankreports',
        'distributionmonth.updateLoadCount',
        'distributionyear.makeDays',
        'distributionmonth.recalculateLoads',
        'distributionmonth.linkTransactions',
        // 'price.rate'
      );
    }

    if (['accountant'].includes(this.activeGrant.role.identifier)) {
      providerEditables.push(
        'order.exportStart',
        'order.exportEnd',
        'order.exportButton',
        'customer.creditLimit',
        'agreement.prePaymentRequired',
        'load.holdRelease',
        'transaction.dateCreated',
        'transaction.amount',
        'transaction.description',
        'transaction.invoice',
      );
    }


    if (['accountant', 'scheduler'].includes(this.activeGrant.role.identifier)) {
      providerEditables.push(
        'customer.companyName',
        'customer.fullCompanyName',
        'customer.address',
        'customer.remittorNumber',
        'customer.additionalInvoiceText',
        'customer.billingPoc',
        'customer.paymentTerms',
        'customer.stripeRef',
        'customer.stripeDirectDebit',
        'customer.stripeVerify1',
        'customer.stripeVerify2',
        'agreement.sapContractNumber',
        'invoice.state',
        'invoice.sent',
        'distributionmonth.deleteInvoices',
        'distributionmonth.invoicesCreated'
      );
    }

    if (['superUser'].includes(this.activeGrant.role.identifier)) {
      providerEditables.push(
        'linag.name',
        'linag.identifier',
        'linag.type',
        'tank.name',
        'tank.capacity',
        'tempagreement.name',
        'tempagreement.description',
        'tempagreement.durationDays',
        'tempagreement.autoRenew',
        'temptract.name',
        'temptract.description',
        'temptract.durationDays',
        'temptract.type',
        'temptract.minLoadSizeToQualify',
        'tempclin.name',
        'tempclin.description',
        'tempclin.durationDays',
        'tempclin.type',
        'temprice.rate',
        'temprice.quantity',
        'user.active',
        'plant.closed',
        'plant.message',
      );
    }

    const customerEditables = [
      'load.trailer',
      'load.carrier',
      'load.weightRequested',
      'load.weightRequestedNetGross',
      'load.destination',
      'order.receiptUrl',
      'load.coolDownDays',
      'load.finalAnalysis',
      'load.waterAnalysis',
      'load.prepTrailer',
      'load.specialRequests',
      'load.eta',
      'load.requestedFillDate',
      'load.reservation',
      'load.offering',
      'load.shield',
      'trailer.chassisId',
      'trailer.identifier',
      'trailer.type',
      'trailer.size',
      'trailer.dotId',
      'trailer.dotInspectionDate',
      'trailer.dotE',
      'trailer.dotSp',
      'trailer.mawp',
      'trailer.mawt',
      'trailer.fillVolume',
      'account.exportStart',
      'account.exportEnd',
      'account.exportButton',
      'slot.cancelReservation',
      'load.shipEmpty',
      'order.reservation', // this is for unknown reservation, I made it an order so I could delete
      'load.agreemonth',
      'trailer.linFirst',
      'containersession.load',
      'featureasc.enabled',
      'subscription.feed',
      'subscription.webAlerts',
      'subscription.mobileAlerts',
      'subscription.emailAlerts',
      'message.read',
      // 'load.prepTrailer',
      // 'load.prepVol',
    ];

    let result: boolean;
    if (!this.isCustomer) {
      result = providerEditables.includes(accessIdentifier);
    } else {
      result = customerEditables.includes(accessIdentifier);
    }
    console.log(`${this.activeGrant.role.identifier}: ${accessIdentifier} = ${result}`)
    return result
  }

  makeAccessIdentifier(entityViewSpec: EntityViewSpecProtocol, detail: DetailEntry = null) {

    const identifierParts = detail.identifier.split('||');
    const identifier = identifierParts.length === 2 ? identifierParts[1] : detail.identifier;

    if (detail) {
      return `${entityViewSpec.entityCore.type}.${identifier}`;
    } else {
      return `${entityViewSpec.entityCore.type}`;
    }
  }
}

