import {SORespSlotlet} from '../../core/slotlet/so-resp-slotlet';
import {SlotletCore} from './slotlet.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSlotletFormFields} from './put-slotlet-form-fields';
import { CESlotletAttributes } from './ce-slotlet-attributes'


export class CESlotletBase extends CEBase {

    public details: CESlotletAttributes;
    public fromFields: PutSlotletFormFields = new PutSlotletFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: SlotletCore,
        public entity: SORespSlotlet,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CESlotletAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.dayOffset)
        this.attributes.push(this.details.slotNumber)
        this.attributes.push(this.details.event)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.tempclin)

    }
}