import { SOGetPlant } from '../plant/so-get-plant';
import { SOGetProduct } from '../product/so-get-product';


export class SOPostLine {

  constructor(
    public name: string,
    public plant: SOGetPlant,
    public product: SOGetProduct,
    public units: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostLine(
      'name' in params ? params.name : null,
      'plant' in params ? params.plant : null,
      'product' in params ? params.product : null,
      'units' in params ? params.units : null,
    );
  }
}
