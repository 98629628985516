import { Type } from 'class-transformer';
import { SORespFee } from '../fee/so-resp-fee';
import { SORespContainersession } from '../containersession/so-resp-containersession';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespLoadfee implements ObjectiveProtocol {

  entityType: string = 'loadfee';
  uid: string;
  quantity: number;
  amount: number;
  notes: string = "";
  @Type(() => SORespFee)
  fee: SORespFee = null;
  unitCost: number;
  @Type(() => SORespContainersession)
  containersession: SORespContainersession;
  @Type(() => Date)
  dateCreated: Date = null;
  @Type(() => Date)
  feeDate: Date = null;
}