import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTempagreement } from './so-put-tempagreement';
import { SOGetTempagreement } from './so-get-tempagreement';
import { SOPostTempagreement } from './so-post-tempagreement';
import { SODeleteTempagreement } from './so-delete-tempagreement';
import {SORespTempagreement} from './so-resp-tempagreement';
import {SOPluralTempagreement} from './so-plural-tempagreement';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTempagreementService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'tempagreement',
      entityCore
    );
  }

  /** GET Tempagreement by id. Will 404 if id not found */
  get(request: SOGetTempagreement): Observable<SOPluralTempagreement> {
    return this.httpClient.get<SOPluralTempagreement>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTempagreement, resp) }),
      tap(_ => this.log(`fetched Tempagreement`)),
      catchError(this.handleError<SOPluralTempagreement>(`get Tempagreement`))
    );
  }

  /** POST: add a new Tempagreement to the server */
  post(request: SOPostTempagreement): Observable<SORespTempagreement> {
    return this.httpClient.post<SORespTempagreement>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTempagreement, resp) }),
      tap((response: SORespTempagreement) => this.log(`added Tempagreement w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTempagreement>('Tempagreement post'))
    );
  }

  /** PUT: update Tempagreement  */
  put(request: SOPutTempagreement): Observable<SORespTempagreement> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTempagreement>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTempagreement, resp) }),
      tap((response: SORespTempagreement) => this.log(`edited Tempagreement w/ uid=${uid}`)),
      catchError(this.handleError<SORespTempagreement>('Tempagreement put'))
    );
  }

  /** DELETE: delete the Tempagreement from the server */
  delete(request: SODeleteTempagreement): Observable<SORespTempagreement> {
    return this.httpClient.delete<SORespTempagreement>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Tempagreement uid=${request.uid}`)),
      catchError(this.handleError<SORespTempagreement>('delete Tempagreement'))
    );
  }
}
