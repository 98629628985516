import { SOGetTempclin } from '../tempclin/so-get-tempclin';
import { SOGetTemptract } from '../temptract/so-get-temptract';


export class SOPostTemptraclin {

  constructor(
    public tempclin: SOGetTempclin,
    public temptract: SOGetTemptract,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTemptraclin(
      'tempclin' in params ? params.tempclin : null,
      'temptract' in params ? params.temptract : null,
    );
  }
}
