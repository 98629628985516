import {SOPutEvent} from '../../core/event/so-put-event';
import {EventCoreBase} from './event.core.base';
import {SOGetEvent} from '../../core/event/so-get-event';
import {FormField, SelectionOption} from 'lionheartland';
import {putEventFormFieldOptions} from './put-event-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {SlotletCore} from '../slotlet/slotlet.core';
import {SOGetSlotlet} from '../../core/slotlet/so-get-slotlet';

export class RAPutEvent extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putEventFormFieldOptions);

  RequestClass = SOPutEvent

  constructor(
    public entityCore: EventCoreBase
  ) {
    super();
    if (putEventFormFieldOptions.slotlet) {
      putEventFormFieldOptions.slotlet.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('title'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('eventType'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier(
        'slotlet',
        SOGetSlotlet,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}