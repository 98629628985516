import {SORespDistributionmonth} from '../../core/distributionmonth/so-resp-distributionmonth';
import {DistributionmonthCore} from './distributionmonth.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributionmonthFormFields} from './put-distributionmonth-form-fields';
import { CEDistributionmonthAttributes } from './ce-distributionmonth-attributes'


export class CEDistributionmonthBase extends CEBase {

    public details: CEDistributionmonthAttributes;
    public fromFields: PutDistributionmonthFormFields = new PutDistributionmonthFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DistributionmonthCore,
        public entity: SORespDistributionmonth,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDistributionmonthAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.month)
        this.attributes.push(this.details.started)
        this.attributes.push(this.details.ordersCreated)
        this.attributes.push(this.details.ordersSubmitted)
        this.attributes.push(this.details.scheduleMade)
        this.attributes.push(this.details.loadsAllocated)
        this.attributes.push(this.details.midMonthCheckpointComplete)
        this.attributes.push(this.details.invoicesSent)
        this.attributes.push(this.details.paymentsReceived)
        this.attributes.push(this.details.closed)
        this.attributes.push(this.details.volumeContracted)
        this.attributes.push(this.details.volumeSold)
        this.attributes.push(this.details.volumeSoldEstimated)
        this.attributes.push(this.details.productionVolume)
        this.attributes.push(this.details.productionVolumeEstimated)
        this.attributes.push(this.details.spotVolumeContracted)
        this.attributes.push(this.details.spotVolumeSold)
        this.attributes.push(this.details.excessVolumeEstimated)
        this.attributes.push(this.details.numberOfLoads)
        this.attributes.push(this.details.loadsOrdered)
        this.attributes.push(this.details.loadsScheduled)
        this.attributes.push(this.details.loadsFilled)
        this.attributes.push(this.details.distributiondays)
        this.attributes.push(this.details.distributionyear)
        this.attributes.push(this.details.inreports)
        this.attributes.push(this.details.transactions)
        this.attributes.push(this.details.orders)
        this.attributes.push(this.details.hmonths)
        this.attributes.push(this.details.agreemonths)
        this.attributes.push(this.details.indexprices)
        this.attributes.push(this.details.loadfees)
        this.attributes.push(this.details.downtimes)
        this.attributes.push(this.details.sales)
        this.attributes.push(this.details.invoices)
        this.attributes.push(this.details.distribution)

    }
}