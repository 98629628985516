import { Type } from 'class-transformer';
import { SORespNavtemrola } from '../navtemrola/so-resp-navtemrola';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTemprole implements ObjectiveProtocol {

  entityType: string = 'temprole';
  uid: string;
  name: string;
  identifier: string;
  @Type(() => SORespNavtemrola)
  navtemrolas: SORespNavtemrola[] = [];
}