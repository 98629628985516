import {SORespTag} from '../../core/tag/so-resp-tag';
import {TagCore} from './tag.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTagFormFields} from './put-tag-form-fields';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {SOGetDcs} from '../../core/dcs/so-get-dcs';
import {SOGetLinag} from '../../core/linag/so-get-linag';
import {SOGetTagreport} from '../../core/tagreport/so-get-tagreport';


export class CETagAttributesBase {

    name: DetailEntry
    identifier: DetailEntry
    dcs: DetailEntry
    linags: DetailEntry
    tagreports: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTag,
        app: GasDirectApp,
        formFields: PutTagFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )


        const dcsCore = app.makeCore( 'dcs');
        dcsCore.soGet = SOGetDcs.construct({
        });
        let dcsCeProtocol;
        if (entity.dcs) {
            dcsCeProtocol = createCEProtocol(
                dcsCore.ceProtocol,
                parentEntityViewSpec,
                dcsCore,
                entity.dcs,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            dcsCore
        )
        dcsCore.title = heading ? heading : dcsCore.title
        dcsCore.listTitle = 'Dcs'

        if (entity.dcs) {
            displayStringFactory.reconfigure(
                entity.dcs,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        dcsCore.configureForDisplay(parentEntityViewSpec)

        this.dcs = new DetailEntry(
          heading ? heading : dcsCore.listTitle,
          dcsCeProtocol ? dcsCeProtocol.entityListView.fullHeading : null,
          'dcs',
          BaseDetailType.pushedDetail,
          dcsCore,
          EditableType.always,
          formFields.dcs
        )
        if (entity.dcs) {
          this.dcs.ceProtocol = dcsCeProtocol;
          this.dcs.cachedListEntry = dcsCore.makeListEntry(parentEntityViewSpec, entity.dcs, 0)
          dcsCore.selectedEntity = entity.dcs;
        }
        this.dcs.singleFieldSelect = true

        const linagsCore = app.makeCore( 'linag');
        linagsCore.soGet = SOGetLinag.construct({
          tag: new SOGetTag(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            linagsCore
        )
        linagsCore.title = heading ? heading : linagsCore.title
        linagsCore.listTitle = 'Linags'

        linagsCore.configureForDisplay(parentEntityViewSpec)

        this.linags = new DetailEntry(
          heading ? heading : linagsCore.listTitle,
          null,
          'linags',
          BaseDetailType.embeddedList,
          linagsCore,
          EditableType.always,
          formFields.linags
        )

        const tagreportsCore = app.makeCore( 'tagreport');
        tagreportsCore.soGet = SOGetTagreport.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tagreportsCore
        )
        tagreportsCore.title = heading ? heading : tagreportsCore.title
        tagreportsCore.listTitle = 'Tagreports'

        tagreportsCore.configureForDisplay(parentEntityViewSpec)

        this.tagreports = new DetailEntry(
          heading ? heading : tagreportsCore.listTitle,
          null,
          'tagreports',
          BaseDetailType.embeddedList,
          tagreportsCore,
          EditableType.never
        )
    }
}