import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOFaqService} from '../../core/faq/eo-faq';
import {SOGetFaq} from '../../core/faq/so-get-faq';
import {RAPutFaq} from './ra-put-faq';
import {RAPostFaq} from './ra-post-faq';
import {RADeleteFaq} from './ra-delete-faq';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEFaq } from "./ce-faq"


export class FaqCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'faq';
  title = 'Faq';
  ceProtocol: CEConstructor = CEFaq

  entityService: EOFaqService;
  soGetClass = SOGetFaq;
  soGet: SOGetFaq;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOFaqService
    );
    this.adapters = [
      new RAPostFaq(this),
      new RAPutFaq(this),
      new RADeleteFaq(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetFaq();
    this.soGet.queryOptions.limit = this.limit;
  }


}