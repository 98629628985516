import {SOPutTempclin} from '../../core/tempclin/so-put-tempclin';
import {TempclinCoreBase} from './tempclin.core.base';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {FormField, SelectionOption} from 'lionheartland';
import {putTempclinFormFieldOptions} from './put-tempclin-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPutTempclin extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTempclinFormFieldOptions);

  RequestClass = SOPutTempclin

  constructor(
    public entityCore: TempclinCoreBase
  ) {
    super();
    if (putTempclinFormFieldOptions.distribution) {
      putTempclinFormFieldOptions.distribution.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('durationDays'),
      this.getFormValueFromIdentifier('offsetDays'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}