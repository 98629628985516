import {SOPutClin} from '../../core/clin/so-put-clin';
import {ClinCoreBase} from './clin.core.base';
import {SOGetClin} from '../../core/clin/so-get-clin';
import {FormField, SelectionOption} from 'lionheartland';
import {putClinFormFieldOptions} from './put-clin-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutClin extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putClinFormFieldOptions);

  RequestClass = SOPutClin

  constructor(
    public entityCore: ClinCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}