import { SOGetPlant } from '../plant/so-get-plant';
import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetCarrier } from '../carrier/so-get-carrier';


export class SOPostRole {

  constructor(
    public name: string,
    public identifier: string,
    public plant: SOGetPlant,
    public distribution: SOGetDistribution = null,
    public account: SOGetAccount = null,
    public carrier: SOGetCarrier = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRole(
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'plant' in params ? params.plant : null,
      'distribution' in params ? params.distribution : null,
      'account' in params ? params.account : null,
      'carrier' in params ? params.carrier : null,
    );
  }
}
