import {SORespTemprole} from '../../core/temprole/so-resp-temprole';
import {TemproleCore} from './temprole.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTemproleFormFields} from './put-temprole-form-fields';
import { CETemproleAttributes } from './ce-temprole-attributes'


export class CETemproleBase extends CEBase {

    public details: CETemproleAttributes;
    public fromFields: PutTemproleFormFields = new PutTemproleFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TemproleCore,
        public entity: SORespTemprole,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETemproleAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.navtemrolas)

    }
}