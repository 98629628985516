import {SORespBulk} from '../../core/bulk/so-resp-bulk';
import {BulkCore} from './bulk.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutBulkFormFields} from './put-bulk-form-fields';
import {SOGetBulk} from '../../core/bulk/so-get-bulk';


export class CEBulkAttributesBase {


    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespBulk,
        app: GasDirectApp,
        formFields: PutBulkFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


    }
}