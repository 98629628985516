

export class SOPutMonthclose {

  constructor(
    public uid: string,
    public status: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutMonthclose(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
    );
  }
}
