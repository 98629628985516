import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTaskreport } from './so-put-taskreport';
import { SOGetTaskreport } from './so-get-taskreport';
import { SOPostTaskreport } from './so-post-taskreport';
import { SODeleteTaskreport } from './so-delete-taskreport';
import {SORespTaskreport} from './so-resp-taskreport';
import {SOPluralTaskreport} from './so-plural-taskreport';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTaskreportService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'taskreport',
      entityCore
    );
  }

  /** GET Taskreport by id. Will 404 if id not found */
  get(request: SOGetTaskreport): Observable<SOPluralTaskreport> {
    return this.httpClient.get<SOPluralTaskreport>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTaskreport, resp) }),
      tap(_ => this.log(`fetched Taskreport`)),
      catchError(this.handleError<SOPluralTaskreport>(`get Taskreport`))
    );
  }

  /** POST: add a new Taskreport to the server */
  post(request: SOPostTaskreport): Observable<SORespTaskreport> {
    return this.httpClient.post<SORespTaskreport>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTaskreport, resp) }),
      tap((response: SORespTaskreport) => this.log(`added Taskreport w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTaskreport>('Taskreport post'))
    );
  }

  /** PUT: update Taskreport  */
  put(request: SOPutTaskreport): Observable<SORespTaskreport> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTaskreport>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTaskreport, resp) }),
      tap((response: SORespTaskreport) => this.log(`edited Taskreport w/ uid=${uid}`)),
      catchError(this.handleError<SORespTaskreport>('Taskreport put'))
    );
  }

  /** DELETE: delete the Taskreport from the server */
  delete(request: SODeleteTaskreport): Observable<SORespTaskreport> {
    return this.httpClient.delete<SORespTaskreport>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Taskreport uid=${request.uid}`)),
      catchError(this.handleError<SORespTaskreport>('delete Taskreport'))
    );
  }
}
