import {SOPutDisttempagreement} from '../../core/disttempagreement/so-put-disttempagreement';
import {DisttempagreementCoreBase} from './disttempagreement.core.base';
import {SOGetDisttempagreement} from '../../core/disttempagreement/so-get-disttempagreement';
import {FormField, SelectionOption} from 'lionheartland';
import {putDisttempagreementFormFieldOptions} from './put-disttempagreement-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {TempagreementCore} from '../tempagreement/tempagreement.core';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';

export class RAPutDisttempagreement extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putDisttempagreementFormFieldOptions);

  RequestClass = SOPutDisttempagreement

  constructor(
    public entityCore: DisttempagreementCoreBase
  ) {
    super();
    if (putDisttempagreementFormFieldOptions.distribution) {
      putDisttempagreementFormFieldOptions.distribution.makeCore = true;
    }
    if (putDisttempagreementFormFieldOptions.tempagreement) {
      putDisttempagreementFormFieldOptions.tempagreement.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('isDefault'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
      this.getFormValueFromIdentifier(
        'tempagreement',
        SOGetTempagreement,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}