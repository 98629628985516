import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostDowntimeFormFields {

    public description: FormField
    public start: FormField
    public end: FormField
    public distributionmonth: FormField
    public cutPercent: FormField

    constructor() {

        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          false,
        )
        this.distributionmonth = new FormField(
          'distributionmonth',
          'Distributionmonth',
          'distributionmonth',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.cutPercent = new FormField(
          'cutPercent',
          'Cut percent',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostDowntimeFormFields()

export const postDowntimeFormFieldOptions: {[key: string]: FormField} = {
  description: fields.description,
  start: fields.start,
  end: fields.end,
  distributionmonth: fields.distributionmonth,
  cutPercent: fields.cutPercent,
};