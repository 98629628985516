import {SORespDelta} from '../../core/delta/so-resp-delta';
import {DeltaCore} from './delta.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDeltaFormFields} from './put-delta-form-fields';
import { CEDeltaAttributes } from './ce-delta-attributes'


export class CEDeltaBase extends CEBase {

    public details: CEDeltaAttributes;
    public fromFields: PutDeltaFormFields = new PutDeltaFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DeltaCore,
        public entity: SORespDelta,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDeltaAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.billable)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.task)
        this.attributes.push(this.details.distributionday)
        this.attributes.push(this.details.invoice)
        this.attributes.push(this.details.invoiceitem)
        this.attributes.push(this.details.loadfee)
        this.attributes.push(this.details.deltaweights)

    }
}