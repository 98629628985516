import { SOGetFee } from '../fee/so-get-fee';
import { SOGetLoad } from '../load/so-get-load';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import { SOGetPrice } from '../price/so-get-price';


export class SOPostLoadfee {

  constructor(
    public quantity: number,
    public load: SOGetLoad,
    public unitCost: number,
    public feeDate: Date,
    public distributionmonth: SOGetDistributionmonth,
    public notes: string = "",
    public fee: SOGetFee = null,
    public price: SOGetPrice = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostLoadfee(
      'quantity' in params ? params.quantity : null,
      'notes' in params ? params.notes : null,
      'fee' in params ? params.fee : null,
      'load' in params ? params.load : null,
      'unitCost' in params ? params.unitCost : null,
      'feeDate' in params ? params.feeDate : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'price' in params ? params.price : null,
    );
  }
}
