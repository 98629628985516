import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOFeescheduleagreementascService} from '../../core/feescheduleagreementasc/eo-feescheduleagreementasc';
import {SOGetFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-get-feescheduleagreementasc';
import {RAPutFeescheduleagreementasc} from './ra-put-feescheduleagreementasc';
import {RAPostFeescheduleagreementasc} from './ra-post-feescheduleagreementasc';
import {RADeleteFeescheduleagreementasc} from './ra-delete-feescheduleagreementasc';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEFeescheduleagreementasc } from "./ce-feescheduleagreementasc"


export class FeescheduleagreementascCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'feescheduleagreementasc';
  title = 'Feescheduleagreementasc';
  ceProtocol: CEConstructor = CEFeescheduleagreementasc

  entityService: EOFeescheduleagreementascService;
  soGetClass = SOGetFeescheduleagreementasc;
  soGet: SOGetFeescheduleagreementasc;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOFeescheduleagreementascService
    );
    this.adapters = [
      new RAPostFeescheduleagreementasc(this),
      new RAPutFeescheduleagreementasc(this),
      new RADeleteFeescheduleagreementasc(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetFeescheduleagreementasc();
    this.soGet.queryOptions.limit = this.limit;
  }


}