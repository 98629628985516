import { SOGetDistoffer } from '../distoffer/so-get-distoffer';
import { SOGetItem } from '../item/so-get-item';
import { SOGetLoad } from '../load/so-get-load';


export class SOPutOffering {

  constructor(
    public uid: string,
    public distoffers: SOGetDistoffer[] = null,
    public name: string = null,
    public description: string = null,
    public units: string = null,
    public size: number = null,
    public items: SOGetItem[] = null,
    public loads: SOGetLoad[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutOffering(
      'uid' in params ? params.uid : null,
      'distoffers' in params ? params.distoffers : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'units' in params ? params.units : null,
      'size' in params ? params.size : null,
      'items' in params ? params.items : null,
      'loads' in params ? params.loads : null,
    );
  }
}
