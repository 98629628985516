import {SORespBacklogitem} from '../../core/backlogitem/so-resp-backlogitem';
import {BacklogitemCore} from './backlogitem.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutBacklogitemFormFields} from './put-backlogitem-form-fields';
import {SOGetBacklogitem} from '../../core/backlogitem/so-get-backlogitem';
import {SOGetRelease} from '../../core/release/so-get-release';


export class CEBacklogitemAttributesBase {

    dateCreated: DetailEntry
    type: DetailEntry
    state: DetailEntry
    summary: DetailEntry
    details: DetailEntry
    release: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespBacklogitem,
        app: GasDirectApp,
        formFields: PutBacklogitemFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.summary = new DetailEntry(
            'Summary',
            entity.summary,
            'summary',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.details = new DetailEntry(
            'Details',
            entity.details,
            'details',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const releaseCore = app.makeCore( 'release');
        releaseCore.soGet = SOGetRelease.construct({
        });
        let releaseCeProtocol;
        if (entity.release) {
            releaseCeProtocol = createCEProtocol(
                releaseCore.ceProtocol,
                parentEntityViewSpec,
                releaseCore,
                entity.release,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            releaseCore
        )
        releaseCore.title = heading ? heading : releaseCore.title
        releaseCore.listTitle = 'Release'

        if (entity.release) {
            displayStringFactory.reconfigure(
                entity.release,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        releaseCore.configureForDisplay(parentEntityViewSpec)

        this.release = new DetailEntry(
          heading ? heading : releaseCore.listTitle,
          releaseCeProtocol ? releaseCeProtocol.entityListView.fullHeading : null,
          'release',
          BaseDetailType.pushedDetail,
          releaseCore,
          EditableType.never
        )
        if (entity.release) {
          this.release.ceProtocol = releaseCeProtocol;
          this.release.cachedListEntry = releaseCore.makeListEntry(parentEntityViewSpec, entity.release, 0)
          releaseCore.selectedEntity = entity.release;
        }
        this.release.singleFieldSelect = true
    }
}