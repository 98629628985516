import {LoadCoreBase} from "./load.core.base"
import {ENLoadStatus} from "../../enums";


export class LoadCore extends LoadCoreBase {

    board = false;
    status = false;


    resetSoGet() {
        super.resetSoGet();
        this.soGet.queryOptions.datetimeStart = new Date();
        // this.soGet.status = [
        //   ENLoadStatus.arrived,
        //   ENLoadStatus.loading,
        //   ENLoadStatus.soaking,
        //   ENLoadStatus.loadingComplete,
        //   ENLoadStatus.departed,
        // ]

    }
}