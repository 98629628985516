import {SORespOrgrola} from '../../core/orgrola/so-resp-orgrola';
import {OrgrolaCore} from './orgrola.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutOrgrolaFormFields} from './put-orgrola-form-fields';
import {SOGetOrgrola} from '../../core/orgrola/so-get-orgrola';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEOrgrolaAttributesBase {

    role: DetailEntry
    isOwner: DetailEntry
    organization: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespOrgrola,
        app: GasDirectApp,
        formFields: PutOrgrolaFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.isOwner = new DetailEntry(
            'Is owner',
            entity.isOwner,
            'isOwner',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.isOwner
        )


        const roleCore = app.makeCore( 'role');
        roleCore.soGet = SOGetRole.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleCore
        )
        roleCore.title = heading ? heading : roleCore.title
        roleCore.listTitle = 'Role'

        roleCore.configureForDisplay(parentEntityViewSpec)

        this.role = new DetailEntry(
          heading ? heading : roleCore.listTitle,
          null,
          'role',
          BaseDetailType.pushedDetail,
          roleCore,
          EditableType.always,
          formFields.role
        )
        this.role.singleFieldSelect = true

        const organizationCore = app.makeCore( 'organization');
        organizationCore.soGet = SOGetOrganization.construct({
        });
        let organizationCeProtocol;
        if (entity.organization) {
            organizationCeProtocol = createCEProtocol(
                organizationCore.ceProtocol,
                parentEntityViewSpec,
                organizationCore,
                entity.organization,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            organizationCore
        )
        organizationCore.title = heading ? heading : organizationCore.title
        organizationCore.listTitle = 'Organization'

        if (entity.organization) {
            displayStringFactory.reconfigure(
                entity.organization,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        organizationCore.configureForDisplay(parentEntityViewSpec)

        this.organization = new DetailEntry(
          heading ? heading : organizationCore.listTitle,
          organizationCeProtocol ? organizationCeProtocol.entityListView.fullHeading : null,
          'organization',
          BaseDetailType.pushedDetail,
          organizationCore,
          EditableType.always,
          formFields.organization
        )
        if (entity.organization) {
          this.organization.ceProtocol = organizationCeProtocol;
          this.organization.cachedListEntry = organizationCore.makeListEntry(parentEntityViewSpec, entity.organization, 0)
          organizationCore.selectedEntity = entity.organization;
        }
        this.organization.singleFieldSelect = true
    }
}