import {SORespDistributionmonth} from '../../core/distributionmonth/so-resp-distributionmonth';
import {DistributionmonthCore} from './distributionmonth.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributionmonthFormFields} from './put-distributionmonth-form-fields';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';
import {SOGetInreport} from '../../core/inreport/so-get-inreport';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetIndexprice} from '../../core/indexprice/so-get-indexprice';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {SOGetDowntime} from '../../core/downtime/so-get-downtime';
import {SOGetSale} from '../../core/sale/so-get-sale';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';


export class CEDistributionmonthAttributesBase {

    distributiondays: DetailEntry
    state: DetailEntry
    month: DetailEntry
    started: DetailEntry
    ordersCreated: DetailEntry
    ordersSubmitted: DetailEntry
    scheduleMade: DetailEntry
    loadsAllocated: DetailEntry
    midMonthCheckpointComplete: DetailEntry
    invoicesSent: DetailEntry
    paymentsReceived: DetailEntry
    closed: DetailEntry
    volumeContracted: DetailEntry
    volumeSold: DetailEntry
    volumeSoldEstimated: DetailEntry
    productionVolume: DetailEntry
    productionVolumeEstimated: DetailEntry
    spotVolumeContracted: DetailEntry
    spotVolumeSold: DetailEntry
    excessVolumeEstimated: DetailEntry
    distributionyear: DetailEntry
    numberOfLoads: DetailEntry
    loadsOrdered: DetailEntry
    loadsScheduled: DetailEntry
    loadsFilled: DetailEntry
    inreports: DetailEntry
    transactions: DetailEntry
    orders: DetailEntry
    hmonths: DetailEntry
    agreemonths: DetailEntry
    indexprices: DetailEntry
    loadfees: DetailEntry
    downtimes: DetailEntry
    sales: DetailEntry
    invoices: DetailEntry
    distribution: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDistributionmonth,
        app: GasDirectApp,
        formFields: PutDistributionmonthFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )
        this.state.tableIndex = 2;

        this.month = new DetailEntry(
            'Month',
            entity.month,
            'month',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.month.listHeadingIndex = 1;
        this.month.tableIndex = 1;

        this.started = new DetailEntry(
            'Started',
            entity.started,
            'started',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.started
        )

        this.ordersCreated = new DetailEntry(
            'Orders created',
            entity.ordersCreated,
            'ordersCreated',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.ordersCreated
        )

        this.ordersSubmitted = new DetailEntry(
            'Orders submitted',
            entity.ordersSubmitted,
            'ordersSubmitted',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.ordersSubmitted
        )

        this.scheduleMade = new DetailEntry(
            'Schedule made',
            entity.scheduleMade,
            'scheduleMade',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.scheduleMade
        )

        this.loadsAllocated = new DetailEntry(
            'Loads allocated',
            entity.loadsAllocated,
            'loadsAllocated',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.loadsAllocated
        )

        this.midMonthCheckpointComplete = new DetailEntry(
            'Mid month checkpoint complete',
            entity.midMonthCheckpointComplete,
            'midMonthCheckpointComplete',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.midMonthCheckpointComplete
        )

        this.invoicesSent = new DetailEntry(
            'Invoices sent',
            entity.invoicesSent,
            'invoicesSent',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.invoicesSent
        )

        this.paymentsReceived = new DetailEntry(
            'Payments received',
            entity.paymentsReceived,
            'paymentsReceived',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.paymentsReceived
        )

        this.closed = new DetailEntry(
            'Closed',
            entity.closed,
            'closed',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.closed
        )

        this.volumeContracted = new DetailEntry(
            'Volume contracted',
            entity.volumeContracted,
            'volumeContracted',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.volumeContracted.tableIndex = 3;

        this.volumeSold = new DetailEntry(
            'Volume sold',
            entity.volumeSold,
            'volumeSold',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.volumeSold.tableIndex = 5;

        this.volumeSoldEstimated = new DetailEntry(
            'Volume sold estimated',
            entity.volumeSoldEstimated,
            'volumeSoldEstimated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.volumeSoldEstimated.tableIndex = 4;

        this.productionVolume = new DetailEntry(
            'Production volume',
            entity.productionVolume,
            'productionVolume',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.productionVolume
        )
        this.productionVolume.tableIndex = 6;

        this.productionVolumeEstimated = new DetailEntry(
            'Production volume estimated',
            entity.productionVolumeEstimated,
            'productionVolumeEstimated',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.productionVolumeEstimated
        )
        this.productionVolumeEstimated.tableIndex = 7;

        this.spotVolumeContracted = new DetailEntry(
            'Spot volume contracted',
            entity.spotVolumeContracted,
            'spotVolumeContracted',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.spotVolumeContracted.tableIndex = 8;

        this.spotVolumeSold = new DetailEntry(
            'Spot volume sold',
            entity.spotVolumeSold,
            'spotVolumeSold',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.spotVolumeSold.tableIndex = 9;

        this.excessVolumeEstimated = new DetailEntry(
            'Excess volume estimated',
            entity.excessVolumeEstimated,
            'excessVolumeEstimated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.excessVolumeEstimated.tableIndex = 11;

        this.numberOfLoads = new DetailEntry(
            'Number of loads',
            entity.numberOfLoads,
            'numberOfLoads',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadsOrdered = new DetailEntry(
            'Loads ordered',
            entity.loadsOrdered,
            'loadsOrdered',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadsScheduled = new DetailEntry(
            'Loads scheduled',
            entity.loadsScheduled,
            'loadsScheduled',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.loadsScheduled.tableIndex = 3;

        this.loadsFilled = new DetailEntry(
            'Loads filled',
            entity.loadsFilled,
            'loadsFilled',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.loadsFilled.tableIndex = 4;


        const distributiondaysCore = app.makeCore( 'distributionday');
        distributiondaysCore.soGet = SOGetDistributionday.construct({
          distributionmonth: new SOGetDistributionmonth(entity.uid),
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributiondaysCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributiondaysCore
        )
        distributiondaysCore.title = heading ? heading : distributiondaysCore.title
        distributiondaysCore.listTitle = 'Distributiondays'

        distributiondaysCore.configureForDisplay(parentEntityViewSpec)

        this.distributiondays = new DetailEntry(
          heading ? heading : distributiondaysCore.listTitle,
          null,
          'distributiondays',
          BaseDetailType.embeddedList,
          distributiondaysCore,
          EditableType.never
        )

        const distributionyearCore = app.makeCore( 'distributionyear');
        distributionyearCore.soGet = SOGetDistributionyear.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionyearCore.soGet.queryOptions.sortKey = 'start';
        let distributionyearCeProtocol;
        if (entity.distributionyear) {
            distributionyearCeProtocol = createCEProtocol(
                distributionyearCore.ceProtocol,
                parentEntityViewSpec,
                distributionyearCore,
                entity.distributionyear,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionyearCore
        )
        distributionyearCore.title = heading ? heading : distributionyearCore.title
        distributionyearCore.listTitle = 'Distributionyear'

        if (entity.distributionyear) {
            displayStringFactory.reconfigure(
                entity.distributionyear,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionyearCore.configureForDisplay(parentEntityViewSpec)

        this.distributionyear = new DetailEntry(
          heading ? heading : distributionyearCore.listTitle,
          distributionyearCeProtocol ? distributionyearCeProtocol.entityListView.fullHeading : null,
          'distributionyear',
          BaseDetailType.pushedDetail,
          distributionyearCore,
          EditableType.never
        )
        if (entity.distributionyear) {
          this.distributionyear.ceProtocol = distributionyearCeProtocol;
          this.distributionyear.cachedListEntry = distributionyearCore.makeListEntry(parentEntityViewSpec, entity.distributionyear, 0)
          distributionyearCore.selectedEntity = entity.distributionyear;
        }
        this.distributionyear.singleFieldSelect = true
        this.distributionyear.listHeadingIndex = 0;
        this.distributionyear.tableIndex = 0;

        const inreportsCore = app.makeCore( 'inreport');
        inreportsCore.soGet = SOGetInreport.construct({
          distributionmonth: new SOGetDistributionmonth(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            inreportsCore
        )
        inreportsCore.title = heading ? heading : inreportsCore.title
        inreportsCore.listTitle = 'Inreports'

        inreportsCore.configureForDisplay(parentEntityViewSpec)

        this.inreports = new DetailEntry(
          heading ? heading : inreportsCore.listTitle,
          null,
          'inreports',
          BaseDetailType.pushedList,
          inreportsCore,
          EditableType.never
        )

        const transactionsCore = app.makeCore( 'transaction');
        transactionsCore.soGet = SOGetTransaction.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distributionmonth: new SOGetDistributionmonth(entity.uid),
        });
        transactionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        transactionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            transactionsCore
        )
        transactionsCore.title = heading ? heading : transactionsCore.title
        transactionsCore.listTitle = 'Transactions'

        transactionsCore.configureForDisplay(parentEntityViewSpec)

        this.transactions = new DetailEntry(
          heading ? heading : transactionsCore.listTitle,
          null,
          'transactions',
          BaseDetailType.pushedList,
          transactionsCore,
          EditableType.never
        )

        const ordersCore = app.makeCore( 'order');
        ordersCore.soGet = SOGetOrder.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreement: app.getScope('agreement') ? new SOGetAgreement(app.getScope('agreement').uid) : null,
          agreemonth: app.getScope('agreemonth') ? new SOGetAgreemonth(app.getScope('agreemonth').uid) : null,
          distributionmonth: new SOGetDistributionmonth(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            ordersCore
        )
        ordersCore.title = heading ? heading : ordersCore.title
        ordersCore.listTitle = 'Orders'

        ordersCore.configureForDisplay(parentEntityViewSpec)

        this.orders = new DetailEntry(
          heading ? heading : ordersCore.listTitle,
          null,
          'orders',
          BaseDetailType.pushedList,
          ordersCore,
          EditableType.never
        )

        const hmonthsCore = app.makeCore( 'hmonth');
        hmonthsCore.soGet = SOGetHmonth.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distributionmonth: new SOGetDistributionmonth(entity.uid),
        });
        hmonthsCore.soGet.queryOptions.sortKey = 'month';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hmonthsCore
        )
        hmonthsCore.title = heading ? heading : hmonthsCore.title
        hmonthsCore.listTitle = 'Hmonths'

        hmonthsCore.configureForDisplay(parentEntityViewSpec)

        this.hmonths = new DetailEntry(
          heading ? heading : hmonthsCore.listTitle,
          null,
          'hmonths',
          BaseDetailType.pushedList,
          hmonthsCore,
          EditableType.never
        )

        const agreemonthsCore = app.makeCore( 'agreemonth');
        agreemonthsCore.soGet = SOGetAgreemonth.construct({
          distributionmonth: new SOGetDistributionmonth(entity.uid),
        });
        agreemonthsCore.soGet.queryOptions.sortKey = 'month';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreemonthsCore
        )
        agreemonthsCore.title = heading ? heading : agreemonthsCore.title
        agreemonthsCore.listTitle = 'Agreemonths'

        agreemonthsCore.configureForDisplay(parentEntityViewSpec)

        this.agreemonths = new DetailEntry(
          heading ? heading : agreemonthsCore.listTitle,
          null,
          'agreemonths',
          BaseDetailType.pushedList,
          agreemonthsCore,
          EditableType.never
        )

        const indexpricesCore = app.makeCore( 'indexprice');
        indexpricesCore.soGet = SOGetIndexprice.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            indexpricesCore
        )
        indexpricesCore.title = heading ? heading : indexpricesCore.title
        indexpricesCore.listTitle = 'Indexprices'

        indexpricesCore.configureForDisplay(parentEntityViewSpec)

        this.indexprices = new DetailEntry(
          heading ? heading : indexpricesCore.listTitle,
          null,
          'indexprices',
          BaseDetailType.pushedList,
          indexpricesCore,
          EditableType.never
        )

        const loadfeesCore = app.makeCore( 'loadfee');
        loadfeesCore.soGet = SOGetLoadfee.construct({
          distributionmonth: new SOGetDistributionmonth(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadfeesCore
        )
        loadfeesCore.title = heading ? heading : loadfeesCore.title
        loadfeesCore.listTitle = 'Loadfees'

        loadfeesCore.configureForDisplay(parentEntityViewSpec)

        this.loadfees = new DetailEntry(
          heading ? heading : loadfeesCore.listTitle,
          null,
          'loadfees',
          BaseDetailType.pushedList,
          loadfeesCore,
          EditableType.never
        )

        const downtimesCore = app.makeCore( 'downtime');
        downtimesCore.soGet = SOGetDowntime.construct({
          distributionmonth: new SOGetDistributionmonth(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            downtimesCore
        )
        downtimesCore.title = heading ? heading : downtimesCore.title
        downtimesCore.listTitle = 'Downtimes'

        downtimesCore.configureForDisplay(parentEntityViewSpec)

        this.downtimes = new DetailEntry(
          heading ? heading : downtimesCore.listTitle,
          null,
          'downtimes',
          BaseDetailType.pushedList,
          downtimesCore,
          EditableType.never
        )

        const salesCore = app.makeCore( 'sale');
        salesCore.soGet = SOGetSale.construct({
          distributionmonth: new SOGetDistributionmonth(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            salesCore
        )
        salesCore.title = heading ? heading : salesCore.title
        salesCore.listTitle = 'Sales'

        salesCore.configureForDisplay(parentEntityViewSpec)

        this.sales = new DetailEntry(
          heading ? heading : salesCore.listTitle,
          null,
          'sales',
          BaseDetailType.pushedList,
          salesCore,
          EditableType.never
        )

        const invoicesCore = app.makeCore( 'invoice');
        invoicesCore.soGet = SOGetInvoice.construct({
          distributionmonth: new SOGetDistributionmonth(entity.uid),
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoicesCore
        )
        invoicesCore.title = heading ? heading : invoicesCore.title
        invoicesCore.listTitle = 'Invoices'

        invoicesCore.configureForDisplay(parentEntityViewSpec)

        this.invoices = new DetailEntry(
          heading ? heading : invoicesCore.listTitle,
          null,
          'invoices',
          BaseDetailType.pushedList,
          invoicesCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true
    }
}