import {SORespTrailermerge} from '../../core/trailermerge/so-resp-trailermerge';
import {TrailermergeCore} from './trailermerge.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTrailermergeFormFields} from './put-trailermerge-form-fields';
import { CETrailermergeAttributes } from './ce-trailermerge-attributes'


export class CETrailermergeBase extends CEBase {

    public details: CETrailermergeAttributes;
    public fromFields: PutTrailermergeFormFields = new PutTrailermergeFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TrailermergeCore,
        public entity: SORespTrailermerge,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETrailermergeAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.newTrailer)

    }
}