import {SOPutDeltaweight} from '../../core/deltaweight/so-put-deltaweight';
import {DeltaweightCoreBase} from './deltaweight.core.base';
import {SOGetDeltaweight} from '../../core/deltaweight/so-get-deltaweight';
import {FormField, SelectionOption} from 'lionheartland';
import {putDeltaweightFormFieldOptions} from './put-deltaweight-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutDeltaweight extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putDeltaweightFormFieldOptions);

  RequestClass = SOPutDeltaweight

  constructor(
    public entityCore: DeltaweightCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}