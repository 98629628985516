import {SORespCustomer} from '../../core/customer/so-resp-customer';
import {CustomerCore} from './customer.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutCustomerFormFields} from './put-customer-form-fields';
import { CECustomerAttributes } from './ce-customer-attributes'


export class CECustomerBase extends CEBase {

    public details: CECustomerAttributes;
    public fromFields: PutCustomerFormFields = new PutCustomerFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: CustomerCore,
        public entity: SORespCustomer,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CECustomerAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.companyName)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.emailDomain)
        this.attributes.push(this.details.signedAgreement)
        this.attributes.push(this.details.handle)
        this.attributes.push(this.details.fullCompanyName)
        this.attributes.push(this.details.address)
        this.attributes.push(this.details.remittorNumber)
        this.attributes.push(this.details.additionalInvoiceText)
        this.attributes.push(this.details.billingPoc)
        this.attributes.push(this.details.paymentTerms)
        this.attributes.push(this.details.stripeRef)
        this.attributes.push(this.details.stripeDirectDebit)
        this.attributes.push(this.details.stripeVerify1)
        this.attributes.push(this.details.stripeVerify2)
        this.attributes.push(this.details.creditLimit)
        this.attributes.push(this.details.organizations)
        this.attributes.push(this.details.inquiry)
        this.attributes.push(this.details.accounts)

    }
}