import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutFeatureasc } from './so-put-featureasc';
import { SOGetFeatureasc } from './so-get-featureasc';
import { SOPostFeatureasc } from './so-post-featureasc';
import { SODeleteFeatureasc } from './so-delete-featureasc';
import {SORespFeatureasc} from './so-resp-featureasc';
import {SOPluralFeatureasc} from './so-plural-featureasc';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOFeatureascService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'featureasc',
      entityCore
    );
  }

  /** GET Featureasc by id. Will 404 if id not found */
  get(request: SOGetFeatureasc): Observable<SOPluralFeatureasc> {
    return this.httpClient.get<SOPluralFeatureasc>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralFeatureasc, resp) }),
      tap(_ => this.log(`fetched Featureasc`)),
      catchError(this.handleError<SOPluralFeatureasc>(`get Featureasc`))
    );
  }

  /** POST: add a new Featureasc to the server */
  post(request: SOPostFeatureasc): Observable<SORespFeatureasc> {
    return this.httpClient.post<SORespFeatureasc>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFeatureasc, resp) }),
      tap((response: SORespFeatureasc) => this.log(`added Featureasc w/ id=${response.uid}`)),
      catchError(this.handleError<SORespFeatureasc>('Featureasc post'))
    );
  }

  /** PUT: update Featureasc  */
  put(request: SOPutFeatureasc): Observable<SORespFeatureasc> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespFeatureasc>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFeatureasc, resp) }),
      tap((response: SORespFeatureasc) => this.log(`edited Featureasc w/ uid=${uid}`)),
      catchError(this.handleError<SORespFeatureasc>('Featureasc put'))
    );
  }

  /** DELETE: delete the Featureasc from the server */
  delete(request: SODeleteFeatureasc): Observable<SORespFeatureasc> {
    return this.httpClient.delete<SORespFeatureasc>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Featureasc uid=${request.uid}`)),
      catchError(this.handleError<SORespFeatureasc>('delete Featureasc'))
    );
  }
}
