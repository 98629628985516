import {SOPutTag} from '../../core/tag/so-put-tag';
import {TagCoreBase} from './tag.core.base';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {FormField, SelectionOption} from 'lionheartland';
import {putTagFormFieldOptions} from './put-tag-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {DcsCore} from '../dcs/dcs.core';
import {SOGetDcs} from '../../core/dcs/so-get-dcs';
import {LinagCore} from '../linag/linag.core';
import {SOGetLinag} from '../../core/linag/so-get-linag';

export class RAPutTag extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTagFormFieldOptions);

  RequestClass = SOPutTag

  constructor(
    public entityCore: TagCoreBase
  ) {
    super();
    if (putTagFormFieldOptions.dcs) {
      putTagFormFieldOptions.dcs.makeCore = true;
    }
    if (putTagFormFieldOptions.linags) {
      putTagFormFieldOptions.linags.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier(
        'dcs',
        SOGetDcs,
      ),
      [this.getFormValueFromIdentifier('linags', SOGetLinag)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}