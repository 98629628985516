import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOCalendarService} from '../../core/calendar/eo-calendar';
import {SOGetCalendar} from '../../core/calendar/so-get-calendar';
import {RAPutCalendar} from './ra-put-calendar';
import {RAPostCalendar} from './ra-post-calendar';
import {RADeleteCalendar} from './ra-delete-calendar';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution'
import { CECalendar } from "./ce-calendar"


export class CalendarCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'calendar';
  title = 'Calendar';
  ceProtocol: CEConstructor = CECalendar

  entityService: EOCalendarService;
  soGetClass = SOGetCalendar;
  soGet: SOGetCalendar;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOCalendarService
    );
    this.adapters = [
      new RAPostCalendar(this),
      new RAPutCalendar(this),
      new RADeleteCalendar(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCalendar();
    if (this.activeUserService.getScope('distribution')) {
        this.soGet.distribution = new SOGetDistribution(
          this.activeUserService.getScope('distribution').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}