import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTempagreetract } from './so-put-tempagreetract';
import { SOGetTempagreetract } from './so-get-tempagreetract';
import { SOPostTempagreetract } from './so-post-tempagreetract';
import { SODeleteTempagreetract } from './so-delete-tempagreetract';
import {SORespTempagreetract} from './so-resp-tempagreetract';
import {SOPluralTempagreetract} from './so-plural-tempagreetract';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTempagreetractService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'tempagreetract',
      entityCore
    );
  }

  /** GET Tempagreetract by id. Will 404 if id not found */
  get(request: SOGetTempagreetract): Observable<SOPluralTempagreetract> {
    return this.httpClient.get<SOPluralTempagreetract>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTempagreetract, resp) }),
      tap(_ => this.log(`fetched Tempagreetract`)),
      catchError(this.handleError<SOPluralTempagreetract>(`get Tempagreetract`))
    );
  }

  /** POST: add a new Tempagreetract to the server */
  post(request: SOPostTempagreetract): Observable<SORespTempagreetract> {
    return this.httpClient.post<SORespTempagreetract>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTempagreetract, resp) }),
      tap((response: SORespTempagreetract) => this.log(`added Tempagreetract w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTempagreetract>('Tempagreetract post'))
    );
  }

  /** PUT: update Tempagreetract  */
  put(request: SOPutTempagreetract): Observable<SORespTempagreetract> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTempagreetract>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTempagreetract, resp) }),
      tap((response: SORespTempagreetract) => this.log(`edited Tempagreetract w/ uid=${uid}`)),
      catchError(this.handleError<SORespTempagreetract>('Tempagreetract put'))
    );
  }

  /** DELETE: delete the Tempagreetract from the server */
  delete(request: SODeleteTempagreetract): Observable<SORespTempagreetract> {
    return this.httpClient.delete<SORespTempagreetract>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Tempagreetract uid=${request.uid}`)),
      catchError(this.handleError<SORespTempagreetract>('delete Tempagreetract'))
    );
  }
}
