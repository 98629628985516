import {SOPutNavtemrola} from '../../core/navtemrola/so-put-navtemrola';
import {NavtemrolaCoreBase} from './navtemrola.core.base';
import {SOGetNavtemrola} from '../../core/navtemrola/so-get-navtemrola';
import {FormField, SelectionOption} from 'lionheartland';
import {putNavtemrolaFormFieldOptions} from './put-navtemrola-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutNavtemrola extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putNavtemrolaFormFieldOptions);

  RequestClass = SOPutNavtemrola

  constructor(
    public entityCore: NavtemrolaCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('index'),
      this.getFormValueFromIdentifier('mainNav'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}