import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutExternalindexpriceFormFields {

    public data: FormField
    public eventProcessedUtcTime: FormField
    public partitionId: FormField
    public eventEnqueuedUtcTime: FormField

    constructor() {

        this.data = new FormField(
          'data',
          'Data',
          null,
          FormFieldType.text,
          false,
        )
        this.eventProcessedUtcTime = new FormField(
          'eventProcessedUtcTime',
          'Event processed utc time',
          null,
          FormFieldType.text,
          false,
        )
        this.partitionId = new FormField(
          'partitionId',
          'Partition id',
          null,
          FormFieldType.text,
          false,
        )
        this.eventEnqueuedUtcTime = new FormField(
          'eventEnqueuedUtcTime',
          'Event enqueued utc time',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutExternalindexpriceFormFields()

export const putExternalindexpriceFormFieldOptions: {[key: string]: FormField} = {
  data: fields.data,
  eventProcessedUtcTime: fields.eventProcessedUtcTime,
  partitionId: fields.partitionId,
  eventEnqueuedUtcTime: fields.eventEnqueuedUtcTime,
};