import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostDistributionyear {

  constructor(
    public year: number,
    public distribution: SOGetDistribution,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDistributionyear(
      'year' in params ? params.year : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
