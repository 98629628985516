import {SOPutTrailerauth} from '../../core/trailerauth/so-put-trailerauth';
import {TrailerauthCoreBase} from './trailerauth.core.base';
import {SOGetTrailerauth} from '../../core/trailerauth/so-get-trailerauth';
import {FormField, SelectionOption} from 'lionheartland';
import {putTrailerauthFormFieldOptions} from './put-trailerauth-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {TrailerCore} from '../trailer/trailer.core';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';

export class RAPutTrailerauth extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTrailerauthFormFieldOptions);

  RequestClass = SOPutTrailerauth

  constructor(
    public entityCore: TrailerauthCoreBase
  ) {
    super();
    if (putTrailerauthFormFieldOptions.trailer) {
      putTrailerauthFormFieldOptions.trailer.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier(
        'trailer',
        SOGetTrailer,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}