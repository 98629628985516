import { SOGetTempclin } from '../tempclin/so-get-tempclin';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetSale } from '../sale/so-get-sale';


export class SOPostBid {

  constructor(
    public displayName: string,
    public amount: number,
    public account: SOGetAccount,
    public description: string = "",
    public tempclin: SOGetTempclin = null,
    public amountTwo: number = null,
    public amountThree: number = null,
    public sale: SOGetSale = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostBid(
      'displayName' in params ? params.displayName : null,
      'description' in params ? params.description : null,
      'amount' in params ? params.amount : null,
      'tempclin' in params ? params.tempclin : null,
      'account' in params ? params.account : null,
      'amountTwo' in params ? params.amountTwo : null,
      'amountThree' in params ? params.amountThree : null,
      'sale' in params ? params.sale : null,
    );
  }
}
