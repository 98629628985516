import {SORespAccount} from '../../core/account/so-resp-account';
import {AccountCore} from './account.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAccountFormFields} from './put-account-form-fields';
import { CEAccountAttributes } from './ce-account-attributes'


export class CEAccountBase extends CEBase {

    public details: CEAccountAttributes;
    public fromFields: PutAccountFormFields = new PutAccountFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AccountCore,
        public entity: SORespAccount,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAccountAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.activeAgreement)
        this.attributes.push(this.details.balance)
        this.attributes.push(this.details.availableBalance)
        this.attributes.push(this.details.scheduledLoads)
        this.attributes.push(this.details.unscheduledLoads)
        this.attributes.push(this.details.confirmedLoads)
        this.attributes.push(this.details.creditLimit)
        this.attributes.push(this.details.salesTaxState)
        this.attributes.push(this.details.interruptibleExpiration)
        this.attributes.push(this.details.averageLoadSize)
        this.attributes.push(this.details.balanceMatchThreshold)
        this.attributes.push(this.details.customer)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.transactions)
        this.attributes.push(this.details.orders)
        this.attributes.push(this.details.slots)
        this.attributes.push(this.details.reservations)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.agreements)
        this.attributes.push(this.details.hyears)
        this.attributes.push(this.details.hmonths)
        this.attributes.push(this.details.roles)
        this.attributes.push(this.details.trailerauths)
        this.attributes.push(this.details.carrierauths)
        this.attributes.push(this.details.bids)
        this.attributes.push(this.details.containersessions)
        this.attributes.push(this.details.saleaccountascs)
        this.attributes.push(this.details.featureascs)
        this.attributes.push(this.details.invoices)
        this.attributes.push(this.details.publications)

    }
}