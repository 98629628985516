import {SORespHestatusitem} from '../../core/hestatusitem/so-resp-hestatusitem';
import {HestatusitemCore} from './hestatusitem.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHestatusitemFormFields} from './put-hestatusitem-form-fields';
import { CEHestatusitemAttributes } from './ce-hestatusitem-attributes'


export class CEHestatusitemBase extends CEBase {

    public details: CEHestatusitemAttributes;
    public fromFields: PutHestatusitemFormFields = new PutHestatusitemFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: HestatusitemCore,
        public entity: SORespHestatusitem,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEHestatusitemAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.location)
        this.attributes.push(this.details.loadStatus)
        this.attributes.push(this.details.n2Level)
        this.attributes.push(this.details.n2Pressure)
        this.attributes.push(this.details.heTemp)
        this.attributes.push(this.details.heLevel)
        this.attributes.push(this.details.hePressure)
        this.attributes.push(this.details.n2LevelDcs)
        this.attributes.push(this.details.n2PressureDcs)
        this.attributes.push(this.details.heTempDcs)
        this.attributes.push(this.details.heLevelDcs)
        this.attributes.push(this.details.hePressureDcs)
        this.attributes.push(this.details.hemon)
        this.attributes.push(this.details.hestatus)
        this.attributes.push(this.details.containersession)

    }
}