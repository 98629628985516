import {SORespTemprice} from '../../core/temprice/so-resp-temprice';
import {TempriceCore} from './temprice.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTempriceFormFields} from './put-temprice-form-fields';
import { CETempriceAttributes } from './ce-temprice-attributes'


export class CETempriceBase extends CEBase {

    public details: CETempriceAttributes;
    public fromFields: PutTempriceFormFields = new PutTempriceFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TempriceCore,
        public entity: SORespTemprice,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETempriceAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.rate)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.loadQuantity)
        this.attributes.push(this.details.rank)
        this.attributes.push(this.details.tempclin)

    }
}