import { SOGetTemprole } from '../temprole/so-get-temprole';
import { SOGetNavi } from '../navi/so-get-navi';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetNavtemrola implements QueryProtocol {

  constructor(
    public uid: string = null,
    public temprole: SOGetTemprole = null,
    public navi: SOGetNavi = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetNavtemrola(
      'uid' in params ? params.uid : null,
      'temprole' in params ? params.temprole : null,
      'navi' in params ? params.navi : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
