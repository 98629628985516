import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetTempagreement } from '../tempagreement/so-get-tempagreement';


export class SOPostDisttempagreement {

  constructor(
    public isDefault: boolean,
    public distribution: SOGetDistribution,
    public tempagreement: SOGetTempagreement,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDisttempagreement(
      'isDefault' in params ? params.isDefault : null,
      'distribution' in params ? params.distribution : null,
      'tempagreement' in params ? params.tempagreement : null,
    );
  }
}
