import {SORespIndexprice} from '../../core/indexprice/so-resp-indexprice';
import {IndexpriceCore} from './indexprice.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutIndexpriceFormFields} from './put-indexprice-form-fields';
import { CEIndexpriceAttributes } from './ce-indexprice-attributes'


export class CEIndexpriceBase extends CEBase {

    public details: CEIndexpriceAttributes;
    public fromFields: PutIndexpriceFormFields = new PutIndexpriceFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: IndexpriceCore,
        public entity: SORespIndexprice,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEIndexpriceAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.rate)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.externalindexprice)
        this.attributes.push(this.details.distributionmonth)

    }
}