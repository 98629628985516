import { SOGetTrailer } from '../trailer/so-get-trailer';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetLoad } from '../load/so-get-load';


export class SOPutContainersession {

  constructor(
    public uid: string,
    public arrived: Date = null,
    public soakStart: Date = null,
    public shipEmpty: boolean = null,
    public carrierSignature: string = null,
    public providerSignature: string = null,
    public notes: string = null,
    public departed: Date = null,
    public trailer: SOGetTrailer = null,
    public account: SOGetAccount = null,
    public load: SOGetLoad = null,
    public loadSheetId: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutContainersession(
      'uid' in params ? params.uid : null,
      'arrived' in params ? params.arrived : null,
      'soakStart' in params ? params.soakStart : null,
      'shipEmpty' in params ? params.shipEmpty : null,
      'carrierSignature' in params ? params.carrierSignature : null,
      'providerSignature' in params ? params.providerSignature : null,
      'notes' in params ? params.notes : null,
      'departed' in params ? params.departed : null,
      'trailer' in params ? params.trailer : null,
      'account' in params ? params.account : null,
      'load' in params ? params.load : null,
      'loadSheetId' in params ? params.loadSheetId : null,
    );
  }
}
