import {SOPostTransaction} from '../../core/transaction/so-post-transaction';
import {TransactionCoreBase} from './transaction.core.base';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {FormField, SelectionOption} from 'lionheartland';
import {postTransactionFormFieldOptions} from './post-transaction-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {OrderCore} from '../order/order.core';
import {SOGetOrder} from '../../core/order/so-get-order';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';
import {InvoiceCore} from '../invoice/invoice.core';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';

export class RAPostTransaction extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTransactionFormFieldOptions);

  RequestClass = SOPostTransaction

  constructor(
    public entityCore: TransactionCoreBase
  ) {
    super();
    if (postTransactionFormFieldOptions.order) {
      postTransactionFormFieldOptions.order.makeCore = true;
    }
    if (postTransactionFormFieldOptions.load) {
      postTransactionFormFieldOptions.load.makeCore = true;
    }
    if (postTransactionFormFieldOptions.invoice) {
      postTransactionFormFieldOptions.invoice.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier('dateCreated'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'order',
        SOGetOrder,
      ),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
      this.getFormValueFromIdentifier('finalLoadCost'),
      this.getFormValueFromIdentifier('reconciliationAdjustment'),
      this.getFormValueFromIdentifier('transactionType'),
      this.getFormValueFromIdentifier(
        'invoice',
        SOGetInvoice,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}