import {SORespIndexprice} from '../../core/indexprice/so-resp-indexprice';
import {IndexpriceCore} from './indexprice.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutIndexpriceFormFields} from './put-indexprice-form-fields';
import {SOGetIndexprice} from '../../core/indexprice/so-get-indexprice';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetExternalindexprice} from '../../core/externalindexprice/so-get-externalindexprice';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';


export class CEIndexpriceAttributesBase {

    start: DetailEntry
    end: DetailEntry
    state: DetailEntry
    rate: DetailEntry
    distribution: DetailEntry
    externalindexprice: DetailEntry
    distributionmonth: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespIndexprice,
        app: GasDirectApp,
        formFields: PutIndexpriceFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.rate = new DetailEntry(
            'Rate',
            entity.rate,
            'rate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.rate
        )


        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const externalindexpriceCore = app.makeCore( 'externalindexprice');
        externalindexpriceCore.soGet = SOGetExternalindexprice.construct({
        });
        let externalindexpriceCeProtocol;
        if (entity.externalindexprice) {
            externalindexpriceCeProtocol = createCEProtocol(
                externalindexpriceCore.ceProtocol,
                parentEntityViewSpec,
                externalindexpriceCore,
                entity.externalindexprice,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            externalindexpriceCore
        )
        externalindexpriceCore.title = heading ? heading : externalindexpriceCore.title
        externalindexpriceCore.listTitle = 'Externalindexprice'

        if (entity.externalindexprice) {
            displayStringFactory.reconfigure(
                entity.externalindexprice,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        externalindexpriceCore.configureForDisplay(parentEntityViewSpec)

        this.externalindexprice = new DetailEntry(
          heading ? heading : externalindexpriceCore.listTitle,
          externalindexpriceCeProtocol ? externalindexpriceCeProtocol.entityListView.fullHeading : null,
          'externalindexprice',
          BaseDetailType.pushedDetail,
          externalindexpriceCore,
          EditableType.always,
          formFields.externalindexprice
        )
        if (entity.externalindexprice) {
          this.externalindexprice.ceProtocol = externalindexpriceCeProtocol;
          this.externalindexprice.cachedListEntry = externalindexpriceCore.makeListEntry(parentEntityViewSpec, entity.externalindexprice, 0)
          externalindexpriceCore.selectedEntity = entity.externalindexprice;
        }
        this.externalindexprice.singleFieldSelect = true

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true
    }
}