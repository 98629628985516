import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODcsService} from '../../core/dcs/eo-dcs';
import {SOGetDcs} from '../../core/dcs/so-get-dcs';
import {RAPutDcs} from './ra-put-dcs';
import {RAPostDcs} from './ra-post-dcs';
import {RADeleteDcs} from './ra-delete-dcs';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEDcs } from "./ce-dcs"


export class DcsCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'dcs';
  title = 'Dcs';
  ceProtocol: CEConstructor = CEDcs

  entityService: EODcsService;
  soGetClass = SOGetDcs;
  soGet: SOGetDcs;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODcsService
    );
    this.adapters = [
      new RAPostDcs(this),
      new RAPutDcs(this),
      new RADeleteDcs(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDcs();
    this.soGet.queryOptions.limit = this.limit;
  }


}