import {SOPostLoad} from '../../core/load/so-post-load';
import {LoadCoreBase} from './load.core.base';
import {SOGetLoad} from '../../core/load/so-get-load';
import {FormField, SelectionOption} from 'lionheartland';
import {postLoadFormFieldOptions} from './post-load-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {OrderCore} from '../order/order.core';
import {SOGetOrder} from '../../core/order/so-get-order';

export class RAPostLoad extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postLoadFormFieldOptions);

  RequestClass = SOPostLoad

  constructor(
    public entityCore: LoadCoreBase
  ) {
    super();
    if (postLoadFormFieldOptions.order) {
      postLoadFormFieldOptions.order.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'order',
        SOGetOrder,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}