import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutRateableagreeyearallocationFormFields {


    constructor() {


    }
}

const fields = new PutRateableagreeyearallocationFormFields()

export const putRateableagreeyearallocationFormFieldOptions: {[key: string]: FormField} = {
};