import {SOPostChronreport} from '../../core/chronreport/so-post-chronreport';
import {ChronreportCoreBase} from './chronreport.core.base';
import {SOGetChronreport} from '../../core/chronreport/so-get-chronreport';
import {FormField, SelectionOption} from 'lionheartland';
import {postChronreportFormFieldOptions} from './post-chronreport-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostChronreport extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postChronreportFormFieldOptions);

  RequestClass = SOPostChronreport

  constructor(
    public entityCore: ChronreportCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('data'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}