import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetUser implements QueryProtocol {

  constructor(
    public uid: string = null,
    public username: string = null,
    public password: string = null,
    public givenName: string = null,
    public surname: string = null,
    public organization: SOGetOrganization = null,
    public active: boolean = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetUser(
      'uid' in params ? params.uid : null,
      'username' in params ? params.username : null,
      'password' in params ? params.password : null,
      'givenName' in params ? params.givenName : null,
      'surname' in params ? params.surname : null,
      'organization' in params ? params.organization : null,
      'active' in params ? params.active : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
