import { SOGetLine } from '../line/so-get-line';


export class SOPostDcs {

  constructor(
    public name: string,
    public endpoint: string,
    public line: SOGetLine,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDcs(
      'name' in params ? params.name : null,
      'endpoint' in params ? params.endpoint : null,
      'line' in params ? params.line : null,
    );
  }
}
