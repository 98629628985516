import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutSlotletFormFields {

    public start: FormField
    public end: FormField
    public status: FormField
    public dayOffset: FormField
    public slotNumber: FormField
    public event: FormField
    public account: FormField
    public tempclin: FormField

    constructor() {

        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          false,
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
        )
        this.dayOffset = new FormField(
          'dayOffset',
          'Day offset',
          null,
          FormFieldType.text,
          false,
        )
        this.slotNumber = new FormField(
          'slotNumber',
          'Slot number',
          null,
          FormFieldType.text,
          false,
        )
        this.event = new FormField(
          'event',
          'Event',
          'event',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.tempclin = new FormField(
          'tempclin',
          'Tempclin',
          'tempclin',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutSlotletFormFields()

export const putSlotletFormFieldOptions: {[key: string]: FormField} = {
  start: fields.start,
  end: fields.end,
  status: fields.status,
  dayOffset: fields.dayOffset,
  slotNumber: fields.slotNumber,
  event: fields.event,
  account: fields.account,
  tempclin: fields.tempclin,
};