import { Type } from 'class-transformer';
import { SORespAgreement } from '../agreement/so-resp-agreement';
import { SORespFeeschedule } from '../feeschedule/so-resp-feeschedule';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespFeescheduleagreementasc implements ObjectiveProtocol {

  entityType: string = 'feescheduleagreementasc';
  uid: string;
  @Type(() => SORespAgreement)
  agreement: SORespAgreement;
  @Type(() => SORespFeeschedule)
  feeschedule: SORespFeeschedule;
}