

export class SOPutPrice {

  constructor(
    public uid: string,
    public startDate: Date = null,
    public endDate: Date = null,
    public rate: number = null,
    public quantity: number = null,
    public loadQuantity: number = null,
    public rank: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutPrice(
      'uid' in params ? params.uid : null,
      'startDate' in params ? params.startDate : null,
      'endDate' in params ? params.endDate : null,
      'rate' in params ? params.rate : null,
      'quantity' in params ? params.quantity : null,
      'loadQuantity' in params ? params.loadQuantity : null,
      'rank' in params ? params.rank : null,
    );
  }
}
