import {SORespHyear} from '../../core/hyear/so-resp-hyear';
import {HyearCore} from './hyear.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHyearFormFields} from './put-hyear-form-fields';
import { CEHyearAttributes } from './ce-hyear-attributes'


export class CEHyearBase extends CEBase {

    public details: CEHyearAttributes;
    public fromFields: PutHyearFormFields = new PutHyearFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: HyearCore,
        public entity: SORespHyear,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEHyearAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.acq)
        this.attributes.push(this.details.acqUsed)
        this.attributes.push(this.details.year)
        this.attributes.push(this.details.spotQuantity)
        this.attributes.push(this.details.spotQuantityUsed)
        this.attributes.push(this.details.delta)
        this.attributes.push(this.details.acqInitial)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.distributionyear)
        this.attributes.push(this.details.feeschedule)
        this.attributes.push(this.details.hmonths)
        this.attributes.push(this.details.agreeyears)

    }
}