import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTrailerService} from '../../core/trailer/eo-trailer';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {RAPutTrailer} from './ra-put-trailer';
import {RAPostTrailer} from './ra-post-trailer';
import {RADeleteTrailer} from './ra-delete-trailer';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {FormControl, FormGroup} from "@angular/forms";
import { CETrailer } from "./ce-trailer"


export class TrailerCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'trailer';
  title = 'Trailer';
  ceProtocol: CEConstructor = CETrailer

  entityService: EOTrailerService;
  soGetClass = SOGetTrailer;
  soGet: SOGetTrailer;

  searchEnabled = true;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTrailerService
    );
    this.adapters = [
      new RAPostTrailer(this),
      new RAPutTrailer(this),
      new RADeleteTrailer(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTrailer();
    this.soGet.queryOptions.limit = this.limit;
  }


}