import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutSubscriptionFormFields {

    public feed: FormField
    public webAlerts: FormField
    public mobileAlerts: FormField
    public emailAlerts: FormField

    constructor() {

        this.feed = new FormField(
          'feed',
          'Feed',
          null,
          FormFieldType.boolean,
          false,
          true,
          true
        )
        this.webAlerts = new FormField(
          'webAlerts',
          'Web alerts',
          null,
          FormFieldType.boolean,
          false,
          true,
          true
        )
        this.mobileAlerts = new FormField(
          'mobileAlerts',
          'Mobile alerts',
          null,
          FormFieldType.boolean,
          false,
          true,
          true
        )
        this.emailAlerts = new FormField(
          'emailAlerts',
          'Email alerts',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )

    }
}

const fields = new PutSubscriptionFormFields()

export const putSubscriptionFormFieldOptions: {[key: string]: FormField} = {
  feed: fields.feed,
  webAlerts: fields.webAlerts,
  mobileAlerts: fields.mobileAlerts,
  emailAlerts: fields.emailAlerts,
};