

export class SOPutSubscription {

  constructor(
    public uid: string,
    public feed: boolean = null,
    public webAlerts: boolean = null,
    public mobileAlerts: boolean = null,
    public emailAlerts: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSubscription(
      'uid' in params ? params.uid : null,
      'feed' in params ? params.feed : null,
      'webAlerts' in params ? params.webAlerts : null,
      'mobileAlerts' in params ? params.mobileAlerts : null,
      'emailAlerts' in params ? params.emailAlerts : null,
    );
  }
}
