import {SOPostToken} from '../../core/token/so-post-token';
import {TokenCoreBase} from './token.core.base';
import {SOGetToken} from '../../core/token/so-get-token';
import {FormField, SelectionOption} from 'lionheartland';
import {postTokenFormFieldOptions} from './post-token-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {GrantCore} from '../grant/grant.core';
import {SOGetGrant} from '../../core/grant/so-get-grant';
import {UserCore} from '../user/user.core';
import {SOGetUser} from '../../core/user/so-get-user';

export class RAPostToken extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTokenFormFieldOptions);

  RequestClass = SOPostToken

  constructor(
    public entityCore: TokenCoreBase
  ) {
    super();
    if (postTokenFormFieldOptions.roleGrant) {
      postTokenFormFieldOptions.roleGrant.makeCore = true;
    }
    if (postTokenFormFieldOptions.user) {
      postTokenFormFieldOptions.user.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'roleGrant',
        SOGetGrant,
      ),
      this.getFormValueFromIdentifier(
        'user',
        SOGetUser,
      ),
      this.getFormValueFromIdentifier('accessToken'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}