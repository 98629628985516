import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutAgreemonth } from './so-put-agreemonth';
import { SOGetAgreemonth } from './so-get-agreemonth';
import { SOPostAgreemonth } from './so-post-agreemonth';
import { SODeleteAgreemonth } from './so-delete-agreemonth';
import {SORespAgreemonth} from './so-resp-agreemonth';
import {SOPluralAgreemonth} from './so-plural-agreemonth';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOAgreemonthService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'agreemonth',
      entityCore
    );
  }

  /** GET Agreemonth by id. Will 404 if id not found */
  get(request: SOGetAgreemonth): Observable<SOPluralAgreemonth> {
    return this.httpClient.get<SOPluralAgreemonth>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAgreemonth, resp) }),
      tap(_ => this.log(`fetched Agreemonth`)),
      catchError(this.handleError<SOPluralAgreemonth>(`get Agreemonth`))
    );
  }

  /** POST: add a new Agreemonth to the server */
  post(request: SOPostAgreemonth): Observable<SORespAgreemonth> {
    return this.httpClient.post<SORespAgreemonth>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAgreemonth, resp) }),
      tap((response: SORespAgreemonth) => this.log(`added Agreemonth w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAgreemonth>('Agreemonth post'))
    );
  }

  /** PUT: update Agreemonth  */
  put(request: SOPutAgreemonth): Observable<SORespAgreemonth> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAgreemonth>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAgreemonth, resp) }),
      tap((response: SORespAgreemonth) => this.log(`edited Agreemonth w/ uid=${uid}`)),
      catchError(this.handleError<SORespAgreemonth>('Agreemonth put'))
    );
  }

  /** DELETE: delete the Agreemonth from the server */
  delete(request: SODeleteAgreemonth): Observable<SORespAgreemonth> {
    return this.httpClient.delete<SORespAgreemonth>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Agreemonth uid=${request.uid}`)),
      catchError(this.handleError<SORespAgreemonth>('delete Agreemonth'))
    );
  }
}
