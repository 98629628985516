import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutFeeFormFields {

    public displayName: FormField
    public identifier: FormField
    public amount: FormField
    public feeschedule: FormField

    constructor() {

        this.displayName = new FormField(
          'displayName',
          'Display name',
          null,
          FormFieldType.text,
          false,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          false,
        )
        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          false,
        )
        this.feeschedule = new FormField(
          'feeschedule',
          'Feeschedule',
          'feeschedule',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutFeeFormFields()

export const putFeeFormFieldOptions: {[key: string]: FormField} = {
  displayName: fields.displayName,
  identifier: fields.identifier,
  amount: fields.amount,
  feeschedule: fields.feeschedule,
};