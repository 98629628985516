import {SORespToken} from '../../core/token/so-resp-token';
import {TokenCore} from './token.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTokenFormFields} from './put-token-form-fields';
import { CETokenAttributes } from './ce-token-attributes'


export class CETokenBase extends CEBase {

    public details: CETokenAttributes;
    public fromFields: PutTokenFormFields = new PutTokenFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TokenCore,
        public entity: SORespToken,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETokenAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.provider)
        this.attributes.push(this.details.roleGrant)
        this.attributes.push(this.details.user)

    }
}