import {SORespTaskreport} from '../../core/taskreport/so-resp-taskreport';
import {TaskreportCore} from './taskreport.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTaskreportFormFields} from './put-taskreport-form-fields';
import { CETaskreportAttributes } from './ce-taskreport-attributes'


export class CETaskreportBase extends CEBase {

    public details: CETaskreportAttributes;
    public fromFields: PutTaskreportFormFields = new PutTaskreportFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TaskreportCore,
        public entity: SORespTaskreport,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETaskreportAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.dateRequested)
        this.attributes.push(this.details.dateStart)
        this.attributes.push(this.details.dateEnd)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.tasks)

    }
}