import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostCalendar {

  constructor(
    public name: string,
    public label: string,
    public distribution: SOGetDistribution,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCalendar(
      'name' in params ? params.name : null,
      'label' in params ? params.label : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
