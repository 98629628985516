import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutShutelngFormFields {

    public data: FormField
    public dateCreated: FormField

    constructor() {

        this.data = new FormField(
          'data',
          'Data',
          null,
          FormFieldType.text,
          false,
        )
        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          false,
        )

    }
}

const fields = new PutShutelngFormFields()

export const putShutelngFormFieldOptions: {[key: string]: FormField} = {
  data: fields.data,
  dateCreated: fields.dateCreated,
};