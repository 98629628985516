import {SORespLoadfee} from '../../core/loadfee/so-resp-loadfee';
import {LoadfeeCore} from './loadfee.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutLoadfeeFormFields} from './put-loadfee-form-fields';
import { CELoadfeeAttributes } from './ce-loadfee-attributes'


export class CELoadfeeBase extends CEBase {

    public details: CELoadfeeAttributes;
    public fromFields: PutLoadfeeFormFields = new PutLoadfeeFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: LoadfeeCore,
        public entity: SORespLoadfee,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CELoadfeeAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.amount)
        this.attributes.push(this.details.notes)
        this.attributes.push(this.details.unitCost)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.feeDate)
        this.attributes.push(this.details.fee)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.invoice)
        this.attributes.push(this.details.distributionmonth)
        this.attributes.push(this.details.price)
        this.attributes.push(this.details.invoiceitem)
        this.attributes.push(this.details.deltas)

    }
}