import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOMonthcloseService} from '../../core/monthclose/eo-monthclose';
import {SOGetMonthclose} from '../../core/monthclose/so-get-monthclose';
import {RAPutMonthclose} from './ra-put-monthclose';
import {RAPostMonthclose} from './ra-post-monthclose';
import {RADeleteMonthclose} from './ra-delete-monthclose';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEMonthclose } from "./ce-monthclose"


export class MonthcloseCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'monthclose';
  title = 'Monthclose';
  ceProtocol: CEConstructor = CEMonthclose

  entityService: EOMonthcloseService;
  soGetClass = SOGetMonthclose;
  soGet: SOGetMonthclose;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOMonthcloseService
    );
    this.adapters = [
      new RAPostMonthclose(this),
      new RAPutMonthclose(this),
      new RADeleteMonthclose(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetMonthclose();
    this.soGet.queryOptions.limit = this.limit;
  }


}