import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespOrder } from '../order/so-resp-order';
import { SORespTrailer } from '../trailer/so-resp-trailer';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespPrice } from '../price/so-resp-price';
import { SORespOffering } from '../offering/so-resp-offering';
import { SORespAgreemonth } from '../agreemonth/so-resp-agreemonth';
import { SORespAgreement } from '../agreement/so-resp-agreement';
import { SORespHmonth } from '../hmonth/so-resp-hmonth';
import { SORespLngload } from '../lngload/so-resp-lngload';
import { SORespWeight } from '../weight/so-resp-weight';
import { SORespHemon } from '../hemon/so-resp-hemon';
import { SORespImpurity } from '../impurity/so-resp-impurity';
import { SORespChecklist } from '../checklist/so-resp-checklist';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import {ENLoadStatus} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespLoad implements ObjectiveProtocol {

  entityType: string = 'load';
  uid: string;
  status: ENLoadStatus;
  action: string = null;
  type: string;
  weightRequested: number = null;
  destination: string = null;
  carrierSignature: string = null;
  providerSignature: string = null;
  quantity: number = null;
  net: number = null;
  netDcs: number = null;
  lngNetTargetSetpoint: number = null;
  lngNetTargetSetpointManual: number = null;
  cost: number = null;
  @Type(() => Date)
  arrived: Date = null;
  @Type(() => Date)
  soakStart: Date = null;
  @Type(() => Date)
  dueDate: Date = null;
  @Type(() => Date)
  departed: Date = null;
  @Type(() => Date)
  loadingBegin: Date = null;
  @Type(() => Date)
  loadingEnd: Date = null;
  loadCode: string = null;
  loadCodeWonderware: string = null;
  location: string = null;
  ref: string;
  specialRequests: string = "";
  @Type(() => SORespAccount)
  account: SORespAccount;
  @Type(() => SORespOrder)
  order: SORespOrder;
  @Type(() => SORespTrailer)
  trailer: SORespTrailer = null;
  @Type(() => SORespOrganization)
  carrier: SORespOrganization = null;
  @Type(() => SORespPrice)
  price: SORespPrice = null;
  @Type(() => SORespOffering)
  offering: SORespOffering;
  driverName: string = null;
  operatorName: string = null;
  @Type(() => Date)
  eta: Date = null;
  @Type(() => Date)
  requestedFillDate: Date = null;
  coolDownDays: number = null;
  rearAxelWeight: number = null;
  droppedLin: number = null;
  finalAnalysis: string = null;
  waterAnalysis: string = null;
  prepVol: string = null;
  prepTrailer: string = null;
  nitrogenDetected: boolean = null;
  weight0: number = null;
  weight0Manual: number = null;
  weight1: number = null;
  weight1Manual: number = null;
  weight2: number = null;
  weight2Manual: number = null;
  pressure0: number = null;
  pressure1: number = null;
  process: string = null;
  chassisId: string = null;
  dotInspectionDate: string = null;
  dotSp: string = null;
  dotE: string = null;
  attachment: string = null;
  @Type(() => SORespAgreemonth)
  agreemonth: SORespAgreemonth = null;
  @Type(() => SORespAgreement)
  agreement: SORespAgreement = null;
  bol: string = null;
  @Type(() => SORespHmonth)
  hmonth: SORespHmonth = null;
  quantityProjected: number = null;
  costProjected: number = null;
  shield: boolean = null;
  loadSheetId: string = null;
  holdRelease: boolean = null;
  netRework: number = null;
  netNormal: number = null;
  quantityRework: number = null;
  quantityNormal: number = null;
  @Type(() => SORespLngload)
  lngload: SORespLngload = null;
  @Type(() => SORespWeight)
  weights: SORespWeight[] = null;
  @Type(() => SORespHemon)
  hemons: SORespHemon[] = null;
  @Type(() => SORespImpurity)
  impuritys: SORespImpurity[] = null;
  @Type(() => SORespChecklist)
  checklists: SORespChecklist[] = null;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
}