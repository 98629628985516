import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespQuotelet implements ObjectiveProtocol {

  entityType: string = 'quotelet';
  uid: string;
  type: string;
  units: string;
  quantityVolume: number;
  quantityLoads: number;
  indexRate: number;
  variableRate: number;
  totalRate: number;
  totalAmount: number;
}