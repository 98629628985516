import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENPublicationOption} from '../../enums';

export class PostPublicationFormFields {

    public identifier: FormField
    public displayName: FormField
    public distribution: FormField
    public account: FormField
    public carrier: FormField

    constructor() {

        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.identifier.enumeration = ENPublicationOption
        this.displayName = new FormField(
          'displayName',
          'Display name',
          null,
          FormFieldType.text,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.carrier = new FormField(
          'carrier',
          'Carrier',
          'carrier',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostPublicationFormFields()

export const postPublicationFormFieldOptions: {[key: string]: FormField} = {
  identifier: fields.identifier,
  displayName: fields.displayName,
  distribution: fields.distribution,
  account: fields.account,
  carrier: fields.carrier,
};