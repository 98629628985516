import {SOPostItem} from '../../core/item/so-post-item';
import {ItemCoreBase} from './item.core.base';
import {SOGetItem} from '../../core/item/so-get-item';
import {FormField, SelectionOption} from 'lionheartland';
import {postItemFormFieldOptions} from './post-item-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {OrderCore} from '../order/order.core';
import {SOGetOrder} from '../../core/order/so-get-order';
import {TempclinCore} from '../tempclin/tempclin.core';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {OfferingCore} from '../offering/offering.core';
import {SOGetOffering} from '../../core/offering/so-get-offering';

export class RAPostItem extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postItemFormFieldOptions);

  RequestClass = SOPostItem

  constructor(
    public entityCore: ItemCoreBase
  ) {
    super();
    if (postItemFormFieldOptions.order) {
      postItemFormFieldOptions.order.makeCore = true;
    }
    if (postItemFormFieldOptions.tempclin) {
      postItemFormFieldOptions.tempclin.makeCore = true;
    }
    if (postItemFormFieldOptions.offering) {
      postItemFormFieldOptions.offering.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier(
        'order',
        SOGetOrder,
      ),
      this.getFormValueFromIdentifier(
        'offering',
        SOGetOffering,
      ),
      this.getFormValueFromIdentifier(
        'tempclin',
        SOGetTempclin,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}