import {SORespBid} from '../../core/bid/so-resp-bid';
import {BidCore} from './bid.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutBidFormFields} from './put-bid-form-fields';
import { CEBidAttributes } from './ce-bid-attributes'


export class CEBidBase extends CEBase {

    public details: CEBidAttributes;
    public fromFields: PutBidFormFields = new PutBidFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: BidCore,
        public entity: SORespBid,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEBidAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.displayName)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.amount)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.amountTwo)
        this.attributes.push(this.details.amountThree)
        this.attributes.push(this.details.prices)
        this.attributes.push(this.details.tempclin)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.sale)
        this.attributes.push(this.details.awards)

    }
}