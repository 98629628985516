import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutHmonthFormFields {

    public state: FormField
    public mcq: FormField
    public month: FormField
    public year: FormField
    public account: FormField
    public delta: FormField
    public hyear: FormField
    public ordersSubmitted: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )
        this.mcq = new FormField(
          'mcq',
          'Mcq',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.month = new FormField(
          'month',
          'Month',
          null,
          FormFieldType.text,
          false,
        )
        this.year = new FormField(
          'year',
          'Year',
          null,
          FormFieldType.text,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.delta = new FormField(
          'delta',
          'Delta',
          null,
          FormFieldType.text,
          false,
        )
        this.hyear = new FormField(
          'hyear',
          'Hyear',
          'hyear',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.ordersSubmitted = new FormField(
          'ordersSubmitted',
          'Orders submitted',
          null,
          FormFieldType.boolean,
          false,
        )

    }
}

const fields = new PutHmonthFormFields()

export const putHmonthFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  mcq: fields.mcq,
  month: fields.month,
  year: fields.year,
  delta: fields.delta,
  hyear: fields.hyear,
  ordersSubmitted: fields.ordersSubmitted,
};