import {SORespWonderware} from '../../core/wonderware/so-resp-wonderware';
import {WonderwareCore} from './wonderware.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutWonderwareFormFields} from './put-wonderware-form-fields';
import {SOGetWonderware} from '../../core/wonderware/so-get-wonderware';


export class CEWonderwareAttributesBase {


    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespWonderware,
        app: GasDirectApp,
        formFields: PutWonderwareFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


    }
}