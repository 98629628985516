

export class SOPostWonderware {

  constructor(
    public data: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostWonderware(
      'data' in params ? params.data : null,
    );
  }
}
