import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutBolFormFields {

    public carrierSignature: FormField
    public providerSignature: FormField
    public driverName: FormField
    public destination: FormField
    public carrier: FormField
    public rearAxelWeight: FormField

    constructor() {

        this.carrierSignature = new FormField(
          'carrierSignature',
          'Carrier signature',
          null,
          FormFieldType.text,
          false,
        )
        this.providerSignature = new FormField(
          'providerSignature',
          'Provider signature',
          null,
          FormFieldType.text,
          false,
        )
        this.driverName = new FormField(
          'driverName',
          'Driver name',
          null,
          FormFieldType.text,
          false,
        )
        this.destination = new FormField(
          'destination',
          'Destination',
          null,
          FormFieldType.text,
          false,
        )
        this.carrier = new FormField(
          'carrier',
          'Carrier',
          'organization',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.rearAxelWeight = new FormField(
          'rearAxelWeight',
          'Rear axel weight',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutBolFormFields()

export const putBolFormFieldOptions: {[key: string]: FormField} = {
  carrierSignature: fields.carrierSignature,
  providerSignature: fields.providerSignature,
  driverName: fields.driverName,
  destination: fields.destination,
  carrier: fields.carrier,
  rearAxelWeight: fields.rearAxelWeight,
};