import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTemprice implements ObjectiveProtocol {

  entityType: string = 'temprice';
  uid: string;
  rate: number;
  quantity: number;
  loadQuantity: number;
  rank: number;
}