import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostDistributionyearFormFields {

    public year: FormField
    public distribution: FormField

    constructor() {

        this.year = new FormField(
          'year',
          'Year',
          null,
          FormFieldType.text,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostDistributionyearFormFields()

export const postDistributionyearFormFieldOptions: {[key: string]: FormField} = {
  year: fields.year,
};