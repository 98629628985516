import {SORespExternalindexprice} from '../../core/externalindexprice/so-resp-externalindexprice';
import {ExternalindexpriceCore} from './externalindexprice.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutExternalindexpriceFormFields} from './put-externalindexprice-form-fields';
import { CEExternalindexpriceAttributes } from './ce-externalindexprice-attributes'


export class CEExternalindexpriceBase extends CEBase {

    public details: CEExternalindexpriceAttributes;
    public fromFields: PutExternalindexpriceFormFields = new PutExternalindexpriceFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ExternalindexpriceCore,
        public entity: SORespExternalindexprice,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEExternalindexpriceAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.data)
        this.attributes.push(this.details.timestamp)
        this.attributes.push(this.details.eventProcessedUtcTime)
        this.attributes.push(this.details.partitionId)
        this.attributes.push(this.details.eventEnqueuedUtcTime)
        this.attributes.push(this.details.marketIndexId)
        this.attributes.push(this.details.indexprices)

    }
}