import { Type } from 'class-transformer';
import { SORespSlotlet } from '../slotlet/so-resp-slotlet';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespEvent implements ObjectiveProtocol {

  entityType: string = 'event';
  uid: string;
  title: string = null;
  description: string = null;
  eventType: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  @Type(() => SORespSlotlet)
  slotlet: SORespSlotlet = null;
}