

export class SOPutLoadfee {

  constructor(
    public uid: string,
    public quantity: number = null,
    public notes: string = null,
    public unitCost: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutLoadfee(
      'uid' in params ? params.uid : null,
      'quantity' in params ? params.quantity : null,
      'notes' in params ? params.notes : null,
      'unitCost' in params ? params.unitCost : null,
    );
  }
}
