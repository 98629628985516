import { SOGetFeeschedule } from '../feeschedule/so-get-feeschedule';


export class SOPostFee {

  constructor(
    public displayName: string,
    public identifier: string,
    public amount: number,
    public feeschedule: SOGetFeeschedule,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostFee(
      'displayName' in params ? params.displayName : null,
      'identifier' in params ? params.identifier : null,
      'amount' in params ? params.amount : null,
      'feeschedule' in params ? params.feeschedule : null,
    );
  }
}
