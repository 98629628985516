import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostInvoiceitemFormFields {

    public description: FormField
    public quantity: FormField
    public unitCost: FormField
    public invoice: FormField
    public code: FormField
    public codeDefinition: FormField
    public units: FormField
    public baseUnits: FormField
    public baseQuantity: FormField
    public conversion: FormField

    constructor() {

        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
        )
        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          true,
        )
        this.unitCost = new FormField(
          'unitCost',
          'Unit cost',
          null,
          FormFieldType.text,
          true,
        )
        this.invoice = new FormField(
          'invoice',
          'Invoice',
          'invoice',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.code = new FormField(
          'code',
          'Code',
          null,
          FormFieldType.text,
          false,
        )
        this.codeDefinition = new FormField(
          'codeDefinition',
          'Code definition',
          null,
          FormFieldType.text,
          false,
        )
        this.units = new FormField(
          'units',
          'Units',
          null,
          FormFieldType.text,
          true,
        )
        this.baseUnits = new FormField(
          'baseUnits',
          'Base units',
          null,
          FormFieldType.text,
          false,
        )
        this.baseQuantity = new FormField(
          'baseQuantity',
          'Base quantity',
          null,
          FormFieldType.text,
          false,
        )
        this.conversion = new FormField(
          'conversion',
          'Conversion',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PostInvoiceitemFormFields()

export const postInvoiceitemFormFieldOptions: {[key: string]: FormField} = {
  description: fields.description,
  quantity: fields.quantity,
  unitCost: fields.unitCost,
  invoice: fields.invoice,
  code: fields.code,
  codeDefinition: fields.codeDefinition,
  units: fields.units,
  baseUnits: fields.baseUnits,
  baseQuantity: fields.baseQuantity,
  conversion: fields.conversion,
};