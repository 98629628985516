import {SORespPrice} from '../../core/price/so-resp-price';
import {PriceCore} from './price.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutPriceFormFields} from './put-price-form-fields';
import { CEPriceAttributes } from './ce-price-attributes'


export class CEPriceBase extends CEBase {

    public details: CEPriceAttributes;
    public fromFields: PutPriceFormFields = new PutPriceFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: PriceCore,
        public entity: SORespPrice,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEPriceAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.startDate)
        this.attributes.push(this.details.endDate)
        this.attributes.push(this.details.rate)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.quantityUsed)
        this.attributes.push(this.details.loadQuantity)
        this.attributes.push(this.details.loadQuantityUsed)
        this.attributes.push(this.details.rank)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.clin)
        this.attributes.push(this.details.agreeyear)
        this.attributes.push(this.details.bid)
        this.attributes.push(this.details.loadfees)

    }
}