import { SOGetRole } from '../role/so-get-role';
import { SOGetUser } from '../user/so-get-user';


export class SOPostGrant {

  constructor(
    public role: SOGetRole,
    public user: SOGetUser,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostGrant(
      'role' in params ? params.role : null,
      'user' in params ? params.user : null,
    );
  }
}
