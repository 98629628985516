import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostDistofferFormFields {

    public offering: FormField
    public distribution: FormField

    constructor() {

        this.offering = new FormField(
          'offering',
          'Offering',
          'offering',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostDistofferFormFields()

export const postDistofferFormFieldOptions: {[key: string]: FormField} = {
  offering: fields.offering,
};