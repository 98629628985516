import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOInreportService} from '../../core/inreport/eo-inreport';
import {SOGetInreport} from '../../core/inreport/so-get-inreport';
import {RAPutInreport} from './ra-put-inreport';
import {RAPostInreport} from './ra-post-inreport';
import {RADeleteInreport} from './ra-delete-inreport';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEInreport } from "./ce-inreport"


export class InreportCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'inreport';
  title = 'Inreport';
  ceProtocol: CEConstructor = CEInreport

  entityService: EOInreportService;
  soGetClass = SOGetInreport;
  soGet: SOGetInreport;

  listSpecType = ListSpecType.tableEntityRows;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOInreportService
    );
    this.adapters = [
      new RAPostInreport(this),
      new RAPutInreport(this),
      new RADeleteInreport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetInreport();
    this.soGet.queryOptions.limit = this.limit;
  }


}