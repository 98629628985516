import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostSaleaccountascFormFields {

    public account: FormField
    public sale: FormField

    constructor() {

        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.sale = new FormField(
          'sale',
          'Sale',
          'sale',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostSaleaccountascFormFields()

export const postSaleaccountascFormFieldOptions: {[key: string]: FormField} = {
  sale: fields.sale,
};