import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostEventFormFields {

    public title: FormField
    public description: FormField
    public eventType: FormField
    public start: FormField
    public end: FormField

    constructor() {

        this.title = new FormField(
          'title',
          'Title',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
        )
        this.eventType = new FormField(
          'eventType',
          'Event type',
          null,
          FormFieldType.text,
          true,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          true,
        )

    }
}

const fields = new PostEventFormFields()

export const postEventFormFieldOptions: {[key: string]: FormField} = {
  title: fields.title,
  description: fields.description,
  eventType: fields.eventType,
  start: fields.start,
  end: fields.end,
};