import { SOGetDowntime } from '../downtime/so-get-downtime';
import { SOGetAgreemonth } from '../agreemonth/so-get-agreemonth';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetAdjustment implements QueryProtocol {

  constructor(
    public uid: string = null,
    public downtime: SOGetDowntime = null,
    public agreemonth: SOGetAgreemonth = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetAdjustment(
      'uid' in params ? params.uid : null,
      'downtime' in params ? params.downtime : null,
      'agreemonth' in params ? params.agreemonth : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
