import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutMonthclose } from './so-put-monthclose';
import { SOGetMonthclose } from './so-get-monthclose';
import { SOPostMonthclose } from './so-post-monthclose';
import { SODeleteMonthclose } from './so-delete-monthclose';
import {SORespMonthclose} from './so-resp-monthclose';
import {SOPluralMonthclose} from './so-plural-monthclose';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOMonthcloseService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'monthclose',
      entityCore
    );
  }

  /** GET Monthclose by id. Will 404 if id not found */
  get(request: SOGetMonthclose): Observable<SOPluralMonthclose> {
    return this.httpClient.get<SOPluralMonthclose>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralMonthclose, resp) }),
      tap(_ => this.log(`fetched Monthclose`)),
      catchError(this.handleError<SOPluralMonthclose>(`get Monthclose`))
    );
  }

  /** POST: add a new Monthclose to the server */
  post(request: SOPostMonthclose): Observable<SORespMonthclose> {
    return this.httpClient.post<SORespMonthclose>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespMonthclose, resp) }),
      tap((response: SORespMonthclose) => this.log(`added Monthclose w/ id=${response.uid}`)),
      catchError(this.handleError<SORespMonthclose>('Monthclose post'))
    );
  }

  /** PUT: update Monthclose  */
  put(request: SOPutMonthclose): Observable<SORespMonthclose> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespMonthclose>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespMonthclose, resp) }),
      tap((response: SORespMonthclose) => this.log(`edited Monthclose w/ uid=${uid}`)),
      catchError(this.handleError<SORespMonthclose>('Monthclose put'))
    );
  }

  /** DELETE: delete the Monthclose from the server */
  delete(request: SODeleteMonthclose): Observable<SORespMonthclose> {
    return this.httpClient.delete<SORespMonthclose>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Monthclose uid=${request.uid}`)),
      catchError(this.handleError<SORespMonthclose>('delete Monthclose'))
    );
  }
}
