import {SORespFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-resp-feescheduleagreementasc';
import {FeescheduleagreementascCore} from './feescheduleagreementasc.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeescheduleagreementascFormFields} from './put-feescheduleagreementasc-form-fields';
import {SOGetFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-get-feescheduleagreementasc';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEFeescheduleagreementascAttributesBase {

    agreement: DetailEntry
    feeschedule: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespFeescheduleagreementasc,
        app: GasDirectApp,
        formFields: PutFeescheduleagreementascFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const agreementCore = app.makeCore( 'agreement');
        agreementCore.soGet = SOGetAgreement.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        let agreementCeProtocol;
        if (entity.agreement) {
            agreementCeProtocol = createCEProtocol(
                agreementCore.ceProtocol,
                parentEntityViewSpec,
                agreementCore,
                entity.agreement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementCore
        )
        agreementCore.title = heading ? heading : agreementCore.title
        agreementCore.listTitle = 'Agreement'

        if (entity.agreement) {
            displayStringFactory.reconfigure(
                entity.agreement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreementCore.configureForDisplay(parentEntityViewSpec)

        this.agreement = new DetailEntry(
          heading ? heading : agreementCore.listTitle,
          agreementCeProtocol ? agreementCeProtocol.entityListView.fullHeading : null,
          'agreement',
          BaseDetailType.pushedDetail,
          agreementCore,
          EditableType.never
        )
        if (entity.agreement) {
          this.agreement.ceProtocol = agreementCeProtocol;
          this.agreement.cachedListEntry = agreementCore.makeListEntry(parentEntityViewSpec, entity.agreement, 0)
          agreementCore.selectedEntity = entity.agreement;
        }
        this.agreement.singleFieldSelect = true

        const feescheduleCore = app.makeCore( 'feeschedule');
        feescheduleCore.soGet = SOGetFeeschedule.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let feescheduleCeProtocol;
        if (entity.feeschedule) {
            feescheduleCeProtocol = createCEProtocol(
                feescheduleCore.ceProtocol,
                parentEntityViewSpec,
                feescheduleCore,
                entity.feeschedule,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feescheduleCore
        )
        feescheduleCore.title = heading ? heading : feescheduleCore.title
        feescheduleCore.listTitle = 'Feeschedule'

        if (entity.feeschedule) {
            displayStringFactory.reconfigure(
                entity.feeschedule,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        feescheduleCore.configureForDisplay(parentEntityViewSpec)

        this.feeschedule = new DetailEntry(
          heading ? heading : feescheduleCore.listTitle,
          feescheduleCeProtocol ? feescheduleCeProtocol.entityListView.fullHeading : null,
          'feeschedule',
          BaseDetailType.pushedDetail,
          feescheduleCore,
          EditableType.never
        )
        if (entity.feeschedule) {
          this.feeschedule.ceProtocol = feescheduleCeProtocol;
          this.feeschedule.cachedListEntry = feescheduleCore.makeListEntry(parentEntityViewSpec, entity.feeschedule, 0)
          feescheduleCore.selectedEntity = entity.feeschedule;
        }
        this.feeschedule.singleFieldSelect = true
    }
}