

export class SOPutCarrier {

  constructor(
    public uid: string,
    public companyName: string = null,
    public emailDomain: string = null,
    public handle: string = null,
    public dateCreated: Date = null,
    public signedAgreement: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutCarrier(
      'uid' in params ? params.uid : null,
      'companyName' in params ? params.companyName : null,
      'emailDomain' in params ? params.emailDomain : null,
      'handle' in params ? params.handle : null,
      'dateCreated' in params ? params.dateCreated : null,
      'signedAgreement' in params ? params.signedAgreement : null,
    );
  }
}
