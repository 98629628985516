import {SORespMonthclose} from '../../core/monthclose/so-resp-monthclose';
import {MonthcloseCore} from './monthclose.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutMonthcloseFormFields} from './put-monthclose-form-fields';
import {SOGetMonthclose} from '../../core/monthclose/so-get-monthclose';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEMonthcloseAttributesBase {

    month: DetailEntry
    status: DetailEntry
    dateCreated: DetailEntry
    distribution: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespMonthclose,
        app: GasDirectApp,
        formFields: PutMonthcloseFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.month = new DetailEntry(
            'Month',
            app.activeUserService.time.dateAsString(entity.month),
            'month',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.status
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true
    }
}