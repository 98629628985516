import {SORespFeatureasc} from '../../core/featureasc/so-resp-featureasc';
import {FeatureascCore} from './featureasc.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeatureascFormFields} from './put-featureasc-form-fields';
import { CEFeatureascAttributes } from './ce-featureasc-attributes'


export class CEFeatureascBase extends CEBase {

    public details: CEFeatureascAttributes;
    public fromFields: PutFeatureascFormFields = new PutFeatureascFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: FeatureascCore,
        public entity: SORespFeatureasc,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEFeatureascAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.feature)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.user)
        this.attributes.push(this.details.role)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.organization)

    }
}