import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAccountService} from '../../core/account/eo-account';
import {SOGetAccount} from '../../core/account/so-get-account';
import {RAPutAccount} from './ra-put-account';
import {RAPostAccount} from './ra-post-account';
import {RADeleteAccount} from './ra-delete-account';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution'
import { CEAccount } from "./ce-account"


export class AccountCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'account';
  title = 'Account';
  ceProtocol: CEConstructor = CEAccount

  entityService: EOAccountService;
  soGetClass = SOGetAccount;
  soGet: SOGetAccount;

  isScope = true;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAccountService
    );
    this.adapters = [
      new RAPostAccount(this),
      new RAPutAccount(this),
      new RADeleteAccount(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAccount();
    if (this.activeUserService.getScope('distribution')) {
        this.soGet.distribution = new SOGetDistribution(
          this.activeUserService.getScope('distribution').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}