import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutReleaseFormFields {


    constructor() {


    }
}

const fields = new PutReleaseFormFields()

export const putReleaseFormFieldOptions: {[key: string]: FormField} = {
};