import {SOPostAdjustment} from '../../core/adjustment/so-post-adjustment';
import {AdjustmentCoreBase} from './adjustment.core.base';
import {SOGetAdjustment} from '../../core/adjustment/so-get-adjustment';
import {FormField, SelectionOption} from 'lionheartland';
import {postAdjustmentFormFieldOptions} from './post-adjustment-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DowntimeCore} from '../downtime/downtime.core';
import {SOGetDowntime} from '../../core/downtime/so-get-downtime';
import {AgreemonthCore} from '../agreemonth/agreemonth.core';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';

export class RAPostAdjustment extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAdjustmentFormFieldOptions);

  RequestClass = SOPostAdjustment

  constructor(
    public entityCore: AdjustmentCoreBase
  ) {
    super();
    if (postAdjustmentFormFieldOptions.downtime) {
      postAdjustmentFormFieldOptions.downtime.makeCore = true;
    }
    if (postAdjustmentFormFieldOptions.agreemonth) {
      postAdjustmentFormFieldOptions.agreemonth.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier(
        'agreemonth',
        SOGetAgreemonth,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('acqAdjustment'),
      this.getFormValueFromIdentifier(
        'downtime',
        SOGetDowntime,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}