import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutDistributionyearFormFields {

    public state: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutDistributionyearFormFields()

export const putDistributionyearFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
};