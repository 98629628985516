import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOShutelngService} from '../../core/shutelng/eo-shutelng';
import {SOGetShutelng} from '../../core/shutelng/so-get-shutelng';
import {RAPutShutelng} from './ra-put-shutelng';
import {RAPostShutelng} from './ra-post-shutelng';
import {RADeleteShutelng} from './ra-delete-shutelng';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEShutelng } from "./ce-shutelng"


export class ShutelngCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'shutelng';
  title = 'Shutelng';
  ceProtocol: CEConstructor = CEShutelng

  entityService: EOShutelngService;
  soGetClass = SOGetShutelng;
  soGet: SOGetShutelng;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOShutelngService
    );
    this.adapters = [
      new RAPostShutelng(this),
      new RAPutShutelng(this),
      new RADeleteShutelng(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetShutelng();
    this.soGet.queryOptions.limit = this.limit;
  }


}