import {SORespProvider} from '../../core/provider/so-resp-provider';
import {ProviderCore} from './provider.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutProviderFormFields} from './put-provider-form-fields';
import {SOGetProvider} from '../../core/provider/so-get-provider';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEProviderAttributesBase {

    organization: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespProvider,
        app: GasDirectApp,
        formFields: PutProviderFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const organizationCore = app.makeCore( 'organization');
        organizationCore.soGet = SOGetOrganization.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            organizationCore
        )
        organizationCore.title = heading ? heading : organizationCore.title
        organizationCore.listTitle = 'Organization'

        organizationCore.configureForDisplay(parentEntityViewSpec)

        this.organization = new DetailEntry(
          heading ? heading : organizationCore.listTitle,
          null,
          'organization',
          BaseDetailType.pushedDetail,
          organizationCore,
          EditableType.always,
          formFields.organization
        )
        this.organization.singleFieldSelect = true
    }
}