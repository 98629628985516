import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOGrantService} from '../../core/grant/eo-grant';
import {SOGetGrant} from '../../core/grant/so-get-grant';
import {RAPutGrant} from './ra-put-grant';
import {RAPostGrant} from './ra-post-grant';
import {RADeleteGrant} from './ra-delete-grant';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEGrant } from "./ce-grant"


export class GrantCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'grant';
  title = 'Grant';
  ceProtocol: CEConstructor = CEGrant

  entityService: EOGrantService;
  soGetClass = SOGetGrant;
  soGet: SOGetGrant;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOGrantService
    );
    this.adapters = [
      new RAPostGrant(this),
      new RAPutGrant(this),
      new RADeleteGrant(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetGrant();
    this.soGet.queryOptions.limit = this.limit;
  }


}