import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutLinagFormFields {

    public name: FormField
    public identifier: FormField
    public type: FormField
    public tag: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          false,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          false,
        )
        this.tag = new FormField(
          'tag',
          'Tag',
          'tag',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutLinagFormFields()

export const putLinagFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  identifier: fields.identifier,
  type: fields.type,
  tag: fields.tag,
};