import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutMessage } from './so-put-message';
import { SOGetMessage } from './so-get-message';
import { SOPostMessage } from './so-post-message';
import { SODeleteMessage } from './so-delete-message';
import {SORespMessage} from './so-resp-message';
import {SOPluralMessage} from './so-plural-message';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOMessageService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'message',
      entityCore
    );
  }

  /** GET Message by id. Will 404 if id not found */
  get(request: SOGetMessage): Observable<SOPluralMessage> {
    return this.httpClient.get<SOPluralMessage>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralMessage, resp) }),
      tap(_ => this.log(`fetched Message`)),
      catchError(this.handleError<SOPluralMessage>(`get Message`))
    );
  }

  /** POST: add a new Message to the server */
  post(request: SOPostMessage): Observable<SORespMessage> {
    return this.httpClient.post<SORespMessage>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespMessage, resp) }),
      tap((response: SORespMessage) => this.log(`added Message w/ id=${response.uid}`)),
      catchError(this.handleError<SORespMessage>('Message post'))
    );
  }

  /** PUT: update Message  */
  put(request: SOPutMessage): Observable<SORespMessage> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespMessage>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespMessage, resp) }),
      tap((response: SORespMessage) => this.log(`edited Message w/ uid=${uid}`)),
      catchError(this.handleError<SORespMessage>('Message put'))
    );
  }

  /** DELETE: delete the Message from the server */
  delete(request: SODeleteMessage): Observable<SORespMessage> {
    return this.httpClient.delete<SORespMessage>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Message uid=${request.uid}`)),
      catchError(this.handleError<SORespMessage>('delete Message'))
    );
  }
}
