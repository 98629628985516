import { SOGetContainersession } from '../containersession/so-get-containersession';
import { SOGetUser } from '../user/so-get-user';
import { SOGetPublication } from '../publication/so-get-publication';


export class SOPostSubscription {

  constructor(
    public user: SOGetUser,
    public publication: SOGetPublication,
    public containersession: SOGetContainersession = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostSubscription(
      'containersession' in params ? params.containersession : null,
      'user' in params ? params.user : null,
      'publication' in params ? params.publication : null,
    );
  }
}
