import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENTare} from '../../enums';

export class PostWeightFormFields {

    public tare: FormField
    public amount: FormField
    public load: FormField
    public amountDcs: FormField
    public dcsTimestamp: FormField

    constructor() {

        this.tare = new FormField(
          'tare',
          'Tare',
          null,
          FormFieldType.enumeration,
          true,
        )
        this.tare.enumeration = ENTare
        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          true,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          true,
        )
        this.amountDcs = new FormField(
          'amountDcs',
          'Amount dcs',
          null,
          FormFieldType.text,
          false,
        )
        this.dcsTimestamp = new FormField(
          'dcsTimestamp',
          'Dcs timestamp',
          null,
          FormFieldType.datetime,
          false,
        )

    }
}

const fields = new PostWeightFormFields()

export const postWeightFormFieldOptions: {[key: string]: FormField} = {
  tare: fields.tare,
  amount: fields.amount,
  load: fields.load,
  amountDcs: fields.amountDcs,
  dcsTimestamp: fields.dcsTimestamp,
};