import {SORespLoad} from '../../core/load/so-resp-load';
import {LoadCore} from './load.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutLoadFormFields} from './put-load-form-fields';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetInreport} from '../../core/inreport/so-get-inreport';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {SOGetReservation} from '../../core/reservation/so-get-reservation';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetOffering} from '../../core/offering/so-get-offering';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetActivity} from '../../core/activity/so-get-activity';
import {SOGetWeight} from '../../core/weight/so-get-weight';
import {SOGetHemon} from '../../core/hemon/so-get-hemon';
import {SOGetImpurity} from '../../core/impurity/so-get-impurity';
import {SOGetChecklist} from '../../core/checklist/so-get-checklist';
import {SOGetTask} from '../../core/task/so-get-task';
import {SOGetBol} from '../../core/bol/so-get-bol';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetSitesession} from '../../core/sitesession/so-get-sitesession';
import {SOGetBaysession} from '../../core/baysession/so-get-baysession';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';


export class CELoadAttributesBase {

    inreports: DetailEntry
    transactions: DetailEntry
    reservation: DetailEntry
    status: DetailEntry
    action: DetailEntry
    weightRequested: DetailEntry
    destination: DetailEntry
    carrierSignature: DetailEntry
    providerSignature: DetailEntry
    quantity: DetailEntry
    net: DetailEntry
    cost: DetailEntry
    arrived: DetailEntry
    soakStart: DetailEntry
    dueDate: DetailEntry
    departed: DetailEntry
    loadingBegin: DetailEntry
    loadingEnd: DetailEntry
    loadCode: DetailEntry
    location: DetailEntry
    ref: DetailEntry
    specialRequests: DetailEntry
    account: DetailEntry
    order: DetailEntry
    trailer: DetailEntry
    carrier: DetailEntry
    price: DetailEntry
    offering: DetailEntry
    driverName: DetailEntry
    operatorName: DetailEntry
    eta: DetailEntry
    requestedFillDate: DetailEntry
    coolDownDays: DetailEntry
    rearAxelWeight: DetailEntry
    droppedLin: DetailEntry
    finalAnalysis: DetailEntry
    waterAnalysis: DetailEntry
    prepVol: DetailEntry
    prepTrailer: DetailEntry
    nitrogenDetected: DetailEntry
    weight0: DetailEntry
    weight0Manual: DetailEntry
    weight1: DetailEntry
    weight1Manual: DetailEntry
    weight2: DetailEntry
    weight2Manual: DetailEntry
    pressure0: DetailEntry
    pressure1: DetailEntry
    process: DetailEntry
    chassisId: DetailEntry
    dotInspectionDate: DetailEntry
    dotSp: DetailEntry
    dotE: DetailEntry
    attachment: DetailEntry
    agreemonth: DetailEntry
    agreement: DetailEntry
    bol: DetailEntry
    hmonth: DetailEntry
    quantityProjected: DetailEntry
    costProjected: DetailEntry
    shield: DetailEntry
    loadSheetId: DetailEntry
    holdRelease: DetailEntry
    netRework: DetailEntry
    netNormal: DetailEntry
    quantityRework: DetailEntry
    quantityNormal: DetailEntry
    distributionday: DetailEntry
    activitys: DetailEntry
    weights: DetailEntry
    hemons: DetailEntry
    impuritys: DetailEntry
    checklists: DetailEntry
    tasks: DetailEntry
    bols: DetailEntry
    loadfees: DetailEntry
    containersession: DetailEntry
    sitesessions: DetailEntry
    baysessions: DetailEntry
    deltas: DetailEntry
    distribution: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespLoad,
        app: GasDirectApp,
        formFields: PutLoadFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.status
        )
        this.status.tableIndex = 4;

        this.action = new DetailEntry(
            'Action',
            entity.action,
            'action',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.action
        )

        this.weightRequested = new DetailEntry(
            'Weight requested',
            entity.weightRequested,
            'weightRequested',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.weightRequested
        )

        this.destination = new DetailEntry(
            'Destination',
            entity.destination,
            'destination',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.destination
        )

        this.carrierSignature = new DetailEntry(
            'Carrier signature',
            entity.carrierSignature,
            'carrierSignature',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.carrierSignature
        )

        this.providerSignature = new DetailEntry(
            'Provider signature',
            entity.providerSignature,
            'providerSignature',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.providerSignature
        )

        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.quantity
        )

        this.net = new DetailEntry(
            'Net',
            entity.net,
            'net',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.net
        )

        this.cost = new DetailEntry(
            'Cost',
            entity.cost,
            'cost',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.arrived = new DetailEntry(
            'Arrived',
            app.activeUserService.time.dateAsString(entity.arrived),
            'arrived',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.arrived
        )

        this.soakStart = new DetailEntry(
            'Soak start',
            app.activeUserService.time.dateAsString(entity.soakStart),
            'soakStart',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dueDate = new DetailEntry(
            'Due date',
            app.activeUserService.time.dateAsString(entity.dueDate),
            'dueDate',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.departed = new DetailEntry(
            'Departed',
            app.activeUserService.time.dateAsString(entity.departed),
            'departed',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.departed
        )

        this.loadingBegin = new DetailEntry(
            'Loading begin',
            app.activeUserService.time.dateAsString(entity.loadingBegin),
            'loadingBegin',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadingBegin
        )

        this.loadingEnd = new DetailEntry(
            'Loading end',
            app.activeUserService.time.dateAsString(entity.loadingEnd),
            'loadingEnd',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadingEnd
        )

        this.loadCode = new DetailEntry(
            'Load code',
            entity.loadCode,
            'loadCode',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadCode
        )

        this.location = new DetailEntry(
            'Location',
            entity.location,
            'location',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.location
        )
        this.location.tableIndex = 6;

        this.ref = new DetailEntry(
            'Ref',
            entity.ref,
            'ref',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.ref.tableIndex = 3;

        this.specialRequests = new DetailEntry(
            'Special requests',
            entity.specialRequests,
            'specialRequests',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.specialRequests
        )

        this.driverName = new DetailEntry(
            'Driver name',
            entity.driverName,
            'driverName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.driverName
        )

        this.operatorName = new DetailEntry(
            'Operator name',
            entity.operatorName,
            'operatorName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.operatorName
        )

        this.eta = new DetailEntry(
            'Eta',
            app.activeUserService.time.dateAsString(entity.eta),
            'eta',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.eta
        )

        this.requestedFillDate = new DetailEntry(
            'Requested fill date',
            app.activeUserService.time.dateAsString(entity.requestedFillDate),
            'requestedFillDate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.requestedFillDate
        )
        this.requestedFillDate.tableIndex = 2;

        this.coolDownDays = new DetailEntry(
            'Cool down days',
            entity.coolDownDays,
            'coolDownDays',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.coolDownDays
        )

        this.rearAxelWeight = new DetailEntry(
            'Rear axel weight',
            entity.rearAxelWeight,
            'rearAxelWeight',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.rearAxelWeight
        )

        this.droppedLin = new DetailEntry(
            'Dropped lin',
            entity.droppedLin,
            'droppedLin',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.droppedLin
        )

        this.finalAnalysis = new DetailEntry(
            'Final analysis',
            entity.finalAnalysis,
            'finalAnalysis',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.finalAnalysis
        )

        this.waterAnalysis = new DetailEntry(
            'Water analysis',
            entity.waterAnalysis,
            'waterAnalysis',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.waterAnalysis
        )

        this.prepVol = new DetailEntry(
            'Prep vol',
            entity.prepVol,
            'prepVol',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.prepVol
        )

        this.prepTrailer = new DetailEntry(
            'Prep trailer',
            entity.prepTrailer,
            'prepTrailer',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.prepTrailer
        )

        this.nitrogenDetected = new DetailEntry(
            'Nitrogen detected',
            entity.nitrogenDetected,
            'nitrogenDetected',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.nitrogenDetected
        )
        this.nitrogenDetected.limitViewToScopeMatch = [
            new ScopeMatch(
            'lng',
            false
            ),
        ];

        this.weight0 = new DetailEntry(
            'Weight0',
            entity.weight0,
            'weight0',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.weight0
        )
        this.weight0.limitViewToScopeMatch = [
            new ScopeMatch(
            'lng',
            false
            ),
        ];

        this.weight0Manual = new DetailEntry(
            'Weight0 manual',
            entity.weight0Manual,
            'weight0Manual',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.weight0Manual
        )
        this.weight0Manual.limitViewToScopeMatch = [
            new ScopeMatch(
            'lng',
            false
            ),
        ];

        this.weight1 = new DetailEntry(
            'Weight1',
            entity.weight1,
            'weight1',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.weight1
        )
        this.weight1.limitViewToScopeMatch = [
            new ScopeMatch(
            'lng',
            false
            ),
        ];

        this.weight1Manual = new DetailEntry(
            'Weight1 manual',
            entity.weight1Manual,
            'weight1Manual',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.weight1Manual
        )
        this.weight1Manual.limitViewToScopeMatch = [
            new ScopeMatch(
            'lng',
            false
            ),
        ];

        this.weight2 = new DetailEntry(
            'Weight2',
            entity.weight2,
            'weight2',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.weight2
        )
        this.weight2.limitViewToScopeMatch = [
            new ScopeMatch(
            'lng',
            false
            ),
        ];

        this.weight2Manual = new DetailEntry(
            'Weight2 manual',
            entity.weight2Manual,
            'weight2Manual',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.weight2Manual
        )
        this.weight2Manual.limitViewToScopeMatch = [
            new ScopeMatch(
            'lng',
            false
            ),
        ];

        this.pressure0 = new DetailEntry(
            'Pressure0',
            entity.pressure0,
            'pressure0',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.pressure0
        )
        this.pressure0.limitViewToScopeMatch = [
            new ScopeMatch(
            'lng',
            false
            ),
        ];

        this.pressure1 = new DetailEntry(
            'Pressure1',
            entity.pressure1,
            'pressure1',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.pressure1
        )
        this.pressure1.limitViewToScopeMatch = [
            new ScopeMatch(
            'lng',
            false
            ),
        ];

        this.process = new DetailEntry(
            'Process',
            entity.process,
            'process',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.process
        )

        this.chassisId = new DetailEntry(
            'Chassis id',
            entity.chassisId,
            'chassisId',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.chassisId
        )

        this.dotInspectionDate = new DetailEntry(
            'Dot inspection date',
            entity.dotInspectionDate,
            'dotInspectionDate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dotInspectionDate
        )

        this.dotSp = new DetailEntry(
            'Dot sp',
            entity.dotSp,
            'dotSp',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dotSp
        )

        this.dotE = new DetailEntry(
            'Dot e',
            entity.dotE,
            'dotE',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dotE
        )

        this.attachment = new DetailEntry(
            'Attachment',
            entity.attachment,
            'attachment',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.attachment
        )

        this.bol = new DetailEntry(
            'Bol',
            entity.bol,
            'bol',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.quantityProjected = new DetailEntry(
            'Quantity projected',
            entity.quantityProjected,
            'quantityProjected',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.costProjected = new DetailEntry(
            'Cost projected',
            entity.costProjected,
            'costProjected',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.shield = new DetailEntry(
            'Shield',
            entity.shield,
            'shield',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.shield
        )

        this.loadSheetId = new DetailEntry(
            'Load sheet id',
            entity.loadSheetId,
            'loadSheetId',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadSheetId
        )

        this.holdRelease = new DetailEntry(
            'Hold release',
            entity.holdRelease,
            'holdRelease',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.holdRelease
        )

        this.netRework = new DetailEntry(
            'Net rework',
            entity.netRework,
            'netRework',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.netNormal = new DetailEntry(
            'Net normal',
            entity.netNormal,
            'netNormal',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.quantityRework = new DetailEntry(
            'Quantity rework',
            entity.quantityRework,
            'quantityRework',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.quantityNormal = new DetailEntry(
            'Quantity normal',
            entity.quantityNormal,
            'quantityNormal',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const inreportsCore = app.makeCore( 'inreport');
        inreportsCore.soGet = SOGetInreport.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            inreportsCore
        )
        inreportsCore.title = heading ? heading : inreportsCore.title
        inreportsCore.listTitle = 'Inreports'

        inreportsCore.configureForDisplay(parentEntityViewSpec)

        this.inreports = new DetailEntry(
          heading ? heading : inreportsCore.listTitle,
          null,
          'inreports',
          BaseDetailType.embeddedList,
          inreportsCore,
          EditableType.never
        )
        this.inreports.limitViewToRoles = [
            'superUser',
        ];

        const transactionsCore = app.makeCore( 'transaction');
        transactionsCore.soGet = SOGetTransaction.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          load: new SOGetLoad(entity.uid),
        });
        transactionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        transactionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            transactionsCore
        )
        transactionsCore.title = heading ? heading : transactionsCore.title
        transactionsCore.listTitle = 'Transactions'

        transactionsCore.configureForDisplay(parentEntityViewSpec)

        this.transactions = new DetailEntry(
          heading ? heading : transactionsCore.listTitle,
          null,
          'transactions',
          BaseDetailType.pushedList,
          transactionsCore,
          EditableType.never
        )

        const reservationCore = app.makeCore( 'reservation');
        reservationCore.soGet = SOGetReservation.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            reservationCore
        )
        reservationCore.title = heading ? heading : reservationCore.title
        reservationCore.listTitle = 'Reservation'

        reservationCore.configureForDisplay(parentEntityViewSpec)

        this.reservation = new DetailEntry(
          heading ? heading : reservationCore.listTitle,
          null,
          'reservation',
          BaseDetailType.pushedDetail,
          reservationCore,
          EditableType.never
        )
        this.reservation.singleFieldSelect = true

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.never
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true
        this.account.tableIndex = 1;

        const orderCore = app.makeCore( 'order');
        orderCore.soGet = SOGetOrder.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreement: app.getScope('agreement') ? new SOGetAgreement(app.getScope('agreement').uid) : null,
          agreemonth: app.getScope('agreemonth') ? new SOGetAgreemonth(app.getScope('agreemonth').uid) : null,
          distributionmonth: app.getScope('distributionmonth') ? new SOGetDistributionmonth(app.getScope('distributionmonth').uid) : null,
        });
        let orderCeProtocol;
        if (entity.order) {
            orderCeProtocol = createCEProtocol(
                orderCore.ceProtocol,
                parentEntityViewSpec,
                orderCore,
                entity.order,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            orderCore
        )
        orderCore.title = heading ? heading : orderCore.title
        orderCore.listTitle = 'Order'

        if (entity.order) {
            displayStringFactory.reconfigure(
                entity.order,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        orderCore.configureForDisplay(parentEntityViewSpec)

        this.order = new DetailEntry(
          heading ? heading : orderCore.listTitle,
          orderCeProtocol ? orderCeProtocol.entityListView.fullHeading : null,
          'order',
          BaseDetailType.pushedDetail,
          orderCore,
          EditableType.always,
          formFields.order
        )
        if (entity.order) {
          this.order.ceProtocol = orderCeProtocol;
          this.order.cachedListEntry = orderCore.makeListEntry(parentEntityViewSpec, entity.order, 0)
          orderCore.selectedEntity = entity.order;
        }
        this.order.singleFieldSelect = true

        const trailerCore = app.makeCore( 'trailer');
        trailerCore.soGet = SOGetTrailer.construct({
        });
        let trailerCeProtocol;
        if (entity.trailer) {
            trailerCeProtocol = createCEProtocol(
                trailerCore.ceProtocol,
                parentEntityViewSpec,
                trailerCore,
                entity.trailer,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            trailerCore
        )
        trailerCore.title = heading ? heading : trailerCore.title
        trailerCore.listTitle = 'Trailer'

        if (entity.trailer) {
            displayStringFactory.reconfigure(
                entity.trailer,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        trailerCore.configureForDisplay(parentEntityViewSpec)

        this.trailer = new DetailEntry(
          heading ? heading : trailerCore.listTitle,
          trailerCeProtocol ? trailerCeProtocol.entityListView.fullHeading : null,
          'trailer',
          BaseDetailType.pushedDetail,
          trailerCore,
          EditableType.always,
          formFields.trailer
        )
        if (entity.trailer) {
          this.trailer.ceProtocol = trailerCeProtocol;
          this.trailer.cachedListEntry = trailerCore.makeListEntry(parentEntityViewSpec, entity.trailer, 0)
          trailerCore.selectedEntity = entity.trailer;
        }
        this.trailer.singleFieldSelect = true
        this.trailer.tableIndex = 0;

        const carrierCore = app.makeCore( 'organization');
        carrierCore.soGet = SOGetOrganization.construct({
        });
        let carrierCeProtocol;
        if (entity.carrier) {
            carrierCeProtocol = createCEProtocol(
                carrierCore.ceProtocol,
                parentEntityViewSpec,
                carrierCore,
                entity.carrier,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            carrierCore
        )
        carrierCore.title = heading ? heading : carrierCore.title
        carrierCore.listTitle = 'Carrier'

        if (entity.carrier) {
            displayStringFactory.reconfigure(
                entity.carrier,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        carrierCore.configureForDisplay(parentEntityViewSpec)

        this.carrier = new DetailEntry(
          heading ? heading : carrierCore.listTitle,
          carrierCeProtocol ? carrierCeProtocol.entityListView.fullHeading : null,
          'carrier',
          BaseDetailType.pushedDetail,
          carrierCore,
          EditableType.always,
          formFields.carrier
        )
        if (entity.carrier) {
          this.carrier.ceProtocol = carrierCeProtocol;
          this.carrier.cachedListEntry = carrierCore.makeListEntry(parentEntityViewSpec, entity.carrier, 0)
          carrierCore.selectedEntity = entity.carrier;
        }
        this.carrier.singleFieldSelect = true

        const priceCore = app.makeCore( 'price');
        priceCore.soGet = SOGetPrice.construct({
        });
        let priceCeProtocol;
        if (entity.price) {
            priceCeProtocol = createCEProtocol(
                priceCore.ceProtocol,
                parentEntityViewSpec,
                priceCore,
                entity.price,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            priceCore
        )
        priceCore.title = heading ? heading : priceCore.title
        priceCore.listTitle = 'Price'

        if (entity.price) {
            displayStringFactory.reconfigure(
                entity.price,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        priceCore.configureForDisplay(parentEntityViewSpec)

        this.price = new DetailEntry(
          heading ? heading : priceCore.listTitle,
          priceCeProtocol ? priceCeProtocol.entityListView.fullHeading : null,
          'price',
          BaseDetailType.pushedDetail,
          priceCore,
          EditableType.never
        )
        if (entity.price) {
          this.price.ceProtocol = priceCeProtocol;
          this.price.cachedListEntry = priceCore.makeListEntry(parentEntityViewSpec, entity.price, 0)
          priceCore.selectedEntity = entity.price;
        }
        this.price.singleFieldSelect = true

        const offeringCore = app.makeCore( 'offering');
        offeringCore.soGet = SOGetOffering.construct({
        });
        let offeringCeProtocol;
        if (entity.offering) {
            offeringCeProtocol = createCEProtocol(
                offeringCore.ceProtocol,
                parentEntityViewSpec,
                offeringCore,
                entity.offering,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            offeringCore
        )
        offeringCore.title = heading ? heading : offeringCore.title
        offeringCore.listTitle = 'Offering'

        if (entity.offering) {
            displayStringFactory.reconfigure(
                entity.offering,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        offeringCore.configureForDisplay(parentEntityViewSpec)

        this.offering = new DetailEntry(
          heading ? heading : offeringCore.listTitle,
          offeringCeProtocol ? offeringCeProtocol.entityListView.fullHeading : null,
          'offering',
          BaseDetailType.pushedDetail,
          offeringCore,
          EditableType.always,
          formFields.offering
        )
        if (entity.offering) {
          this.offering.ceProtocol = offeringCeProtocol;
          this.offering.cachedListEntry = offeringCore.makeListEntry(parentEntityViewSpec, entity.offering, 0)
          offeringCore.selectedEntity = entity.offering;
        }
        this.offering.singleFieldSelect = true
        this.offering.tableIndex = 5;

        const agreemonthCore = app.makeCore( 'agreemonth');
        agreemonthCore.soGet = SOGetAgreemonth.construct({
        });
        agreemonthCore.soGet.queryOptions.sortKey = 'month';
        let agreemonthCeProtocol;
        if (entity.agreemonth) {
            agreemonthCeProtocol = createCEProtocol(
                agreemonthCore.ceProtocol,
                parentEntityViewSpec,
                agreemonthCore,
                entity.agreemonth,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreemonthCore
        )
        agreemonthCore.title = heading ? heading : agreemonthCore.title
        agreemonthCore.listTitle = 'Agreemonth'

        if (entity.agreemonth) {
            displayStringFactory.reconfigure(
                entity.agreemonth,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreemonthCore.configureForDisplay(parentEntityViewSpec)

        this.agreemonth = new DetailEntry(
          heading ? heading : agreemonthCore.listTitle,
          agreemonthCeProtocol ? agreemonthCeProtocol.entityListView.fullHeading : null,
          'agreemonth',
          BaseDetailType.pushedDetail,
          agreemonthCore,
          EditableType.always,
          formFields.agreemonth
        )
        if (entity.agreemonth) {
          this.agreemonth.ceProtocol = agreemonthCeProtocol;
          this.agreemonth.cachedListEntry = agreemonthCore.makeListEntry(parentEntityViewSpec, entity.agreemonth, 0)
          agreemonthCore.selectedEntity = entity.agreemonth;
        }
        this.agreemonth.singleFieldSelect = true

        const agreementCore = app.makeCore( 'agreement');
        agreementCore.soGet = SOGetAgreement.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        let agreementCeProtocol;
        if (entity.agreement) {
            agreementCeProtocol = createCEProtocol(
                agreementCore.ceProtocol,
                parentEntityViewSpec,
                agreementCore,
                entity.agreement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementCore
        )
        agreementCore.title = heading ? heading : agreementCore.title
        agreementCore.listTitle = 'Agreement'

        if (entity.agreement) {
            displayStringFactory.reconfigure(
                entity.agreement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreementCore.configureForDisplay(parentEntityViewSpec)

        this.agreement = new DetailEntry(
          heading ? heading : agreementCore.listTitle,
          agreementCeProtocol ? agreementCeProtocol.entityListView.fullHeading : null,
          'agreement',
          BaseDetailType.pushedDetail,
          agreementCore,
          EditableType.always,
          formFields.agreement
        )
        if (entity.agreement) {
          this.agreement.ceProtocol = agreementCeProtocol;
          this.agreement.cachedListEntry = agreementCore.makeListEntry(parentEntityViewSpec, entity.agreement, 0)
          agreementCore.selectedEntity = entity.agreement;
        }
        this.agreement.singleFieldSelect = true

        const hmonthCore = app.makeCore( 'hmonth');
        hmonthCore.soGet = SOGetHmonth.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        hmonthCore.soGet.queryOptions.sortKey = 'month';
        let hmonthCeProtocol;
        if (entity.hmonth) {
            hmonthCeProtocol = createCEProtocol(
                hmonthCore.ceProtocol,
                parentEntityViewSpec,
                hmonthCore,
                entity.hmonth,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hmonthCore
        )
        hmonthCore.title = heading ? heading : hmonthCore.title
        hmonthCore.listTitle = 'Hmonth'

        if (entity.hmonth) {
            displayStringFactory.reconfigure(
                entity.hmonth,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        hmonthCore.configureForDisplay(parentEntityViewSpec)

        this.hmonth = new DetailEntry(
          heading ? heading : hmonthCore.listTitle,
          hmonthCeProtocol ? hmonthCeProtocol.entityListView.fullHeading : null,
          'hmonth',
          BaseDetailType.pushedDetail,
          hmonthCore,
          EditableType.never
        )
        if (entity.hmonth) {
          this.hmonth.ceProtocol = hmonthCeProtocol;
          this.hmonth.cachedListEntry = hmonthCore.makeListEntry(parentEntityViewSpec, entity.hmonth, 0)
          hmonthCore.selectedEntity = entity.hmonth;
        }
        this.hmonth.singleFieldSelect = true

        const distributiondayCore = app.makeCore( 'distributionday');
        distributiondayCore.soGet = SOGetDistributionday.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributiondayCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributiondayCore
        )
        distributiondayCore.title = heading ? heading : distributiondayCore.title
        distributiondayCore.listTitle = 'Distributionday'

        distributiondayCore.configureForDisplay(parentEntityViewSpec)

        this.distributionday = new DetailEntry(
          heading ? heading : distributiondayCore.listTitle,
          null,
          'distributionday',
          BaseDetailType.pushedDetail,
          distributiondayCore,
          EditableType.never
        )
        this.distributionday.singleFieldSelect = true

        const activitysCore = app.makeCore( 'activity');
        activitysCore.soGet = SOGetActivity.construct({
          load: new SOGetLoad(entity.uid),
        });
        activitysCore.soGet.queryOptions.sortKey = 'dateCreated';
        activitysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            activitysCore
        )
        activitysCore.title = heading ? heading : activitysCore.title
        activitysCore.listTitle = 'Activitys'

        activitysCore.configureForDisplay(parentEntityViewSpec)

        this.activitys = new DetailEntry(
          heading ? heading : activitysCore.listTitle,
          null,
          'activitys',
          BaseDetailType.pushedList,
          activitysCore,
          EditableType.never
        )

        const weightsCore = app.makeCore( 'weight');
        weightsCore.soGet = SOGetWeight.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            weightsCore
        )
        weightsCore.title = heading ? heading : weightsCore.title
        weightsCore.listTitle = 'Weights'

        weightsCore.configureForDisplay(parentEntityViewSpec)

        this.weights = new DetailEntry(
          heading ? heading : weightsCore.listTitle,
          null,
          'weights',
          BaseDetailType.embeddedList,
          weightsCore,
          EditableType.never
        )

        const hemonsCore = app.makeCore( 'hemon');
        hemonsCore.soGet = SOGetHemon.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hemonsCore
        )
        hemonsCore.title = heading ? heading : hemonsCore.title
        hemonsCore.listTitle = 'Hemons'

        hemonsCore.configureForDisplay(parentEntityViewSpec)

        this.hemons = new DetailEntry(
          heading ? heading : hemonsCore.listTitle,
          null,
          'hemons',
          BaseDetailType.embeddedList,
          hemonsCore,
          EditableType.never
        )

        const impuritysCore = app.makeCore( 'impurity');
        impuritysCore.soGet = SOGetImpurity.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            impuritysCore
        )
        impuritysCore.title = heading ? heading : impuritysCore.title
        impuritysCore.listTitle = 'Impuritys'

        impuritysCore.configureForDisplay(parentEntityViewSpec)

        this.impuritys = new DetailEntry(
          heading ? heading : impuritysCore.listTitle,
          null,
          'impuritys',
          BaseDetailType.embeddedList,
          impuritysCore,
          EditableType.never
        )

        const checklistsCore = app.makeCore( 'checklist');
        checklistsCore.soGet = SOGetChecklist.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            checklistsCore
        )
        checklistsCore.title = heading ? heading : checklistsCore.title
        checklistsCore.listTitle = 'Checklists'

        checklistsCore.configureForDisplay(parentEntityViewSpec)

        this.checklists = new DetailEntry(
          heading ? heading : checklistsCore.listTitle,
          null,
          'checklists',
          BaseDetailType.pushedList,
          checklistsCore,
          EditableType.never
        )

        const tasksCore = app.makeCore( 'task');
        tasksCore.soGet = SOGetTask.construct({
          load: new SOGetLoad(entity.uid),
        });
        tasksCore.soGet.queryOptions.sortKey = 'dateCreated';
        tasksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tasksCore
        )
        tasksCore.title = heading ? heading : tasksCore.title
        tasksCore.listTitle = 'Tasks'

        tasksCore.configureForDisplay(parentEntityViewSpec)

        this.tasks = new DetailEntry(
          heading ? heading : tasksCore.listTitle,
          null,
          'tasks',
          BaseDetailType.pushedList,
          tasksCore,
          EditableType.never
        )

        const bolsCore = app.makeCore( 'bol');
        bolsCore.soGet = SOGetBol.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            bolsCore
        )
        bolsCore.title = heading ? heading : bolsCore.title
        bolsCore.listTitle = 'Bols'

        bolsCore.configureForDisplay(parentEntityViewSpec)

        this.bols = new DetailEntry(
          heading ? heading : bolsCore.listTitle,
          null,
          'bols',
          BaseDetailType.embeddedList,
          bolsCore,
          EditableType.never
        )

        const loadfeesCore = app.makeCore( 'loadfee');
        loadfeesCore.soGet = SOGetLoadfee.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadfeesCore
        )
        loadfeesCore.title = heading ? heading : loadfeesCore.title
        loadfeesCore.listTitle = 'Loadfees'

        loadfeesCore.configureForDisplay(parentEntityViewSpec)

        this.loadfees = new DetailEntry(
          heading ? heading : loadfeesCore.listTitle,
          null,
          'loadfees',
          BaseDetailType.embeddedList,
          loadfeesCore,
          EditableType.never
        )

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
          load: new SOGetLoad(entity.uid),
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        this.containersession.singleFieldSelect = true

        const sitesessionsCore = app.makeCore( 'sitesession');
        sitesessionsCore.soGet = SOGetSitesession.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            sitesessionsCore
        )
        sitesessionsCore.title = heading ? heading : sitesessionsCore.title
        sitesessionsCore.listTitle = 'Sitesessions'

        sitesessionsCore.configureForDisplay(parentEntityViewSpec)

        this.sitesessions = new DetailEntry(
          heading ? heading : sitesessionsCore.listTitle,
          null,
          'sitesessions',
          BaseDetailType.pushedList,
          sitesessionsCore,
          EditableType.never
        )

        const baysessionsCore = app.makeCore( 'baysession');
        baysessionsCore.soGet = SOGetBaysession.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            baysessionsCore
        )
        baysessionsCore.title = heading ? heading : baysessionsCore.title
        baysessionsCore.listTitle = 'Baysessions'

        baysessionsCore.configureForDisplay(parentEntityViewSpec)

        this.baysessions = new DetailEntry(
          heading ? heading : baysessionsCore.listTitle,
          null,
          'baysessions',
          BaseDetailType.pushedList,
          baysessionsCore,
          EditableType.never
        )

        const deltasCore = app.makeCore( 'delta');
        deltasCore.soGet = SOGetDelta.construct({
          load: new SOGetLoad(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deltasCore
        )
        deltasCore.title = heading ? heading : deltasCore.title
        deltasCore.listTitle = 'Deltas'

        deltasCore.configureForDisplay(parentEntityViewSpec)

        this.deltas = new DetailEntry(
          heading ? heading : deltasCore.listTitle,
          null,
          'deltas',
          BaseDetailType.pushedList,
          deltasCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true
    }
}