import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutBol {

  constructor(
    public uid: string,
    public carrierSignature: string = null,
    public providerSignature: string = null,
    public driverName: string = null,
    public destination: string = null,
    public carrier: SOGetOrganization = null,
    public rearAxelWeight: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutBol(
      'uid' in params ? params.uid : null,
      'carrierSignature' in params ? params.carrierSignature : null,
      'providerSignature' in params ? params.providerSignature : null,
      'driverName' in params ? params.driverName : null,
      'destination' in params ? params.destination : null,
      'carrier' in params ? params.carrier : null,
      'rearAxelWeight' in params ? params.rearAxelWeight : null,
    );
  }
}
