import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutSaleaccountascFormFields {


    constructor() {


    }
}

const fields = new PutSaleaccountascFormFields()

export const putSaleaccountascFormFieldOptions: {[key: string]: FormField} = {
};