import {SOPutOrganization} from '../../core/organization/so-put-organization';
import {OrganizationCoreBase} from './organization.core.base';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {FormField, SelectionOption} from 'lionheartland';
import {putOrganizationFormFieldOptions} from './put-organization-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {UserCore} from '../user/user.core';
import {SOGetUser} from '../../core/user/so-get-user';
import {CustomerCore} from '../customer/customer.core';
import {SOGetCustomer} from '../../core/customer/so-get-customer';
import {CarrierCore} from '../carrier/carrier.core';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';
import {OrgrolaCore} from '../orgrola/orgrola.core';
import {SOGetOrgrola} from '../../core/orgrola/so-get-orgrola';
import {TrailerCore} from '../trailer/trailer.core';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';

export class RAPutOrganization extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putOrganizationFormFieldOptions);

  RequestClass = SOPutOrganization

  constructor(
    public entityCore: OrganizationCoreBase
  ) {
    super();
    if (putOrganizationFormFieldOptions.users) {
      putOrganizationFormFieldOptions.users.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.customer) {
      putOrganizationFormFieldOptions.customer.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.carrier) {
      putOrganizationFormFieldOptions.carrier.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.orgrolas) {
      putOrganizationFormFieldOptions.orgrolas.makeCore = true;
    }
    if (putOrganizationFormFieldOptions.trailers) {
      putOrganizationFormFieldOptions.trailers.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      [this.getFormValueFromIdentifier('users', SOGetUser)],
      this.getFormValueFromIdentifier('handle'),
      this.getFormValueFromIdentifier('ownerId'),
      this.getFormValueFromIdentifier(
        'customer',
        SOGetCustomer,
      ),
      this.getFormValueFromIdentifier(
        'carrier',
        SOGetCarrier,
      ),
      [this.getFormValueFromIdentifier('orgrolas', SOGetOrgrola)],
      [this.getFormValueFromIdentifier('trailers', SOGetTrailer)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}