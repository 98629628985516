import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutInvoiceFormFields {

    public state: FormField
    public invoiceDate: FormField
    public sentDate: FormField
    public sent: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )
        this.invoiceDate = new FormField(
          'invoiceDate',
          'Invoice date',
          null,
          FormFieldType.datetime,
          false,
        )
        this.sentDate = new FormField(
          'sentDate',
          'Sent date',
          null,
          FormFieldType.datetime,
          false,
        )
        this.sent = new FormField(
          'sent',
          'Sent',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutInvoiceFormFields()

export const putInvoiceFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  invoiceDate: fields.invoiceDate,
  sentDate: fields.sentDate,
  sent: fields.sent,
};