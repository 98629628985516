<div class="list-container" fxLayout="column">
  <!--  <app-av-header-->
  <!--    [header]="header"-->
  <!--    [parent]="this"-->
  <!--  ></app-av-header>-->
  <div class="body-container" fxLayout="column">
    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
    <div *ngIf="!loading" class="hidden-progress-spacer"></div>
    <app-dv-load-row *ngFor="let loadRow of loadRows" [loadRow]="loadRow" [parent]="this"
                     [color]="loadRow.color"></app-dv-load-row>

  </div>
</div>
