import {SORespSale} from '../../core/sale/so-resp-sale';
import {SaleCore} from './sale.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSaleFormFields} from './put-sale-form-fields';
import { CESaleAttributes } from './ce-sale-attributes'


export class CESaleBase extends CEBase {

    public details: CESaleAttributes;
    public fromFields: PutSaleFormFields = new PutSaleFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: SaleCore,
        public entity: SORespSale,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CESaleAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.bids)
        this.attributes.push(this.details.saleaccountascs)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.distributionmonth)

    }
}