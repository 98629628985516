import { SOGetLoad } from '../load/so-get-load';


export class SOPostLngload {

  constructor(
    public load: SOGetLoad,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostLngload(
      'load' in params ? params.load : null,
    );
  }
}
