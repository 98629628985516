import {SORespHmonth} from '../../core/hmonth/so-resp-hmonth';
import {HmonthCore} from './hmonth.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHmonthFormFields} from './put-hmonth-form-fields';
import { CEHmonthAttributes } from './ce-hmonth-attributes'


export class CEHmonthBase extends CEBase {

    public details: CEHmonthAttributes;
    public fromFields: PutHmonthFormFields = new PutHmonthFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: HmonthCore,
        public entity: SORespHmonth,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEHmonthAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.mcq)
        this.attributes.push(this.details.mcqUsed)
        this.attributes.push(this.details.month)
        this.attributes.push(this.details.year)
        this.attributes.push(this.details.delta)
        this.attributes.push(this.details.mcqEstimated)
        this.attributes.push(this.details.spotQuantity)
        this.attributes.push(this.details.spotQuantityUsed)
        this.attributes.push(this.details.allocatedLoads)
        this.attributes.push(this.details.ordersSubmitted)
        this.attributes.push(this.details.numberOfLoads)
        this.attributes.push(this.details.mcqInitial)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.hyear)
        this.attributes.push(this.details.distributionmonth)
        this.attributes.push(this.details.agreemonths)

    }
}