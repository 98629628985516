import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetCarrier } from '../carrier/so-get-carrier';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetRole implements QueryProtocol {

  constructor(
    public uid: string = null,
    public identifier: string = null,
    public distribution: SOGetDistribution = null,
    public account: SOGetAccount = null,
    public carrier: SOGetCarrier = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetRole(
      'uid' in params ? params.uid : null,
      'identifier' in params ? params.identifier : null,
      'distribution' in params ? params.distribution : null,
      'account' in params ? params.account : null,
      'carrier' in params ? params.carrier : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
