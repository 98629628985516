import {SORespDisttempagreement} from '../../core/disttempagreement/so-resp-disttempagreement';
import {DisttempagreementCore} from './disttempagreement.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDisttempagreementFormFields} from './put-disttempagreement-form-fields';
import { CEDisttempagreementAttributes } from './ce-disttempagreement-attributes'


export class CEDisttempagreementBase extends CEBase {

    public details: CEDisttempagreementAttributes;
    public fromFields: PutDisttempagreementFormFields = new PutDisttempagreementFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DisttempagreementCore,
        public entity: SORespDisttempagreement,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDisttempagreementAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.isDefault)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.tempagreement)

    }
}