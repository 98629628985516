import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespCustomer implements ObjectiveProtocol {

  entityType: string = 'customer';
  uid: string;
  companyName: string;
  @Type(() => Date)
  dateCreated: Date;
  emailDomain: string = null;
  signedAgreement: string = "";
  handle: string;
  fullCompanyName: string = null;
  address: string = null;
  remittorNumber: string = null;
  additionalInvoiceText: string = null;
  billingPoc: string = null;
  paymentTerms: number = null;
  stripeRef: string = null;
  stripeDirectDebit: boolean = null;
  stripeVerify1: number = null;
  stripeVerify2: number = null;
  creditLimit: number = 0;
}