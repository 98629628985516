import { SOGetRole } from '../role/so-get-role';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutOrgrola {

  constructor(
    public uid: string,
    public role: SOGetRole = null,
    public isOwner: boolean = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutOrgrola(
      'uid' in params ? params.uid : null,
      'role' in params ? params.role : null,
      'isOwner' in params ? params.isOwner : null,
      'organization' in params ? params.organization : null,
    );
  }
}
