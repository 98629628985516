import {SORespClin} from '../../core/clin/so-resp-clin';
import {ClinCore} from './clin.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutClinFormFields} from './put-clin-form-fields';
import { CEClinAttributes } from './ce-clin-attributes'


export class CEClinBase extends CEBase {

    public details: CEClinAttributes;
    public fromFields: PutClinFormFields = new PutClinFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ClinCore,
        public entity: SORespClin,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEClinAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.quantityUsed)
        this.attributes.push(this.details.loadQuantity)
        this.attributes.push(this.details.loadQuantityUsed)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.contract)
        this.attributes.push(this.details.tempclin)
        this.attributes.push(this.details.slot)
        this.attributes.push(this.details.clinyearlng)
        this.attributes.push(this.details.prices)

    }
}