import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutDisttempagreementFormFields {

    public isDefault: FormField
    public distribution: FormField
    public tempagreement: FormField

    constructor() {

        this.isDefault = new FormField(
          'isDefault',
          'Is default',
          null,
          FormFieldType.boolean,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.tempagreement = new FormField(
          'tempagreement',
          'Tempagreement',
          'tempagreement',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutDisttempagreementFormFields()

export const putDisttempagreementFormFieldOptions: {[key: string]: FormField} = {
  isDefault: fields.isDefault,
  distribution: fields.distribution,
  tempagreement: fields.tempagreement,
};