import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetTrailer implements QueryProtocol {

  constructor(
    public uid: string = null,
    public chassisId: string = null,
    public identifier: string = null,
    public type: string = null,
    public organization: SOGetOrganization = null,
    public requestingAccount: string = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetTrailer(
      'uid' in params ? params.uid : null,
      'chassisId' in params ? params.chassisId : null,
      'identifier' in params ? params.identifier : null,
      'type' in params ? params.type : null,
      'organization' in params ? params.organization : null,
      'requestingAccount' in params ? params.requestingAccount : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
