import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODistributionService} from '../../core/distribution/eo-distribution';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {RAPutDistribution} from './ra-put-distribution';
import {RAPostDistribution} from './ra-post-distribution';
import {RADeleteDistribution} from './ra-delete-distribution';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEDistribution } from "./ce-distribution"


export class DistributionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'distribution';
  title = 'Distribution';
  ceProtocol: CEConstructor = CEDistribution

  entityService: EODistributionService;
  soGetClass = SOGetDistribution;
  soGet: SOGetDistribution;

  isScope = true;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODistributionService
    );
    this.adapters = [
      new RAPostDistribution(this),
      new RAPutDistribution(this),
      new RADeleteDistribution(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDistribution();
    this.soGet.queryOptions.limit = this.limit;
  }


}