import {SORespTankreport} from '../../core/tankreport/so-resp-tankreport';
import {TankreportCore} from './tankreport.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTankreportFormFields} from './put-tankreport-form-fields';
import { CETankreportAttributes } from './ce-tankreport-attributes'


export class CETankreportBase extends CEBase {

    public details: CETankreportAttributes;
    public fromFields: PutTankreportFormFields = new PutTankreportFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TankreportCore,
        public entity: SORespTankreport,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETankreportAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.value)
        this.attributes.push(this.details.tank)
        this.attributes.push(this.details.distributionday)

    }
}