<div class="load-summary" style="border-color: {{color}}; background-color: {{listEntry.backgroundColor}}" fxLayout="column" [ngClass]="{'soak': listEntry.entity.status == 'soaking'}">
  <div class="line-heading" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column">
      <p>
        {{lastEight}}
      </p>
      <p *ngIf="arrived">
        {{arrived}}
      </p>
    </div>
    <mat-icon *ngIf="listEntry.icon">
      {{listEntry.icon}}
    </mat-icon>
    <mat-icon *ngIf="showHold">
      report
    </mat-icon>
  </div>
  <div fxLayout="row" *ngIf="!soaker">
    <div class="col-left" fxFlex>
      <div *ngFor="let line of listEntry.lineHeading" class="line">
        {{getLine(line)}}
      </div>
    </div>
    <div class="col-right" *ngIf="!activeUserService.isHandset">
      <div *ngFor="let line of listEntry.subLines" class="line">
        {{line}}
      </div>
    </div>
  </div>
  <div fxLayout="column" *ngIf="soaker && listEntry.entity.status == 'soaking'">
    <div>{{customerName}}</div>
    <div>{{soakDaysRemaining}}</div>
    <div>{{soakStartDate}}</div>
<!--    <div>{{projectedSoakEndDate}}</div>-->
    <div>{{dueDate}}</div>
  </div>
</div>
