import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutAgreeyear } from './so-put-agreeyear';
import { SOGetAgreeyear } from './so-get-agreeyear';
import { SOPostAgreeyear } from './so-post-agreeyear';
import { SODeleteAgreeyear } from './so-delete-agreeyear';
import {SORespAgreeyear} from './so-resp-agreeyear';
import {SOPluralAgreeyear} from './so-plural-agreeyear';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOAgreeyearService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'agreeyear',
      entityCore
    );
  }

  /** GET Agreeyear by id. Will 404 if id not found */
  get(request: SOGetAgreeyear): Observable<SOPluralAgreeyear> {
    return this.httpClient.get<SOPluralAgreeyear>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAgreeyear, resp) }),
      tap(_ => this.log(`fetched Agreeyear`)),
      catchError(this.handleError<SOPluralAgreeyear>(`get Agreeyear`))
    );
  }

  /** POST: add a new Agreeyear to the server */
  post(request: SOPostAgreeyear): Observable<SORespAgreeyear> {
    return this.httpClient.post<SORespAgreeyear>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAgreeyear, resp) }),
      tap((response: SORespAgreeyear) => this.log(`added Agreeyear w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAgreeyear>('Agreeyear post'))
    );
  }

  /** PUT: update Agreeyear  */
  put(request: SOPutAgreeyear): Observable<SORespAgreeyear> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAgreeyear>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAgreeyear, resp) }),
      tap((response: SORespAgreeyear) => this.log(`edited Agreeyear w/ uid=${uid}`)),
      catchError(this.handleError<SORespAgreeyear>('Agreeyear put'))
    );
  }

  /** DELETE: delete the Agreeyear from the server */
  delete(request: SODeleteAgreeyear): Observable<SORespAgreeyear> {
    return this.httpClient.delete<SORespAgreeyear>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Agreeyear uid=${request.uid}`)),
      catchError(this.handleError<SORespAgreeyear>('delete Agreeyear'))
    );
  }
}
