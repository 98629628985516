import {SOPutDistribution} from '../../core/distribution/so-put-distribution';
import {DistributionCoreBase} from './distribution.core.base';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {FormField, SelectionOption} from 'lionheartland';
import {putDistributionFormFieldOptions} from './put-distribution-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutDistribution extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putDistributionFormFieldOptions);

  RequestClass = SOPutDistribution

  constructor(
    public entityCore: DistributionCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('marketIndexId'),
      this.getFormValueFromIdentifier('conversionRate'),
      this.getFormValueFromIdentifier('galToWeight'),
      this.getFormValueFromIdentifier('defaultSlotsPerDay'),
      this.getFormValueFromIdentifier('providerSlotDurationHours'),
      this.getFormValueFromIdentifier('customerSlotDurationHours'),
      this.getFormValueFromIdentifier('showSlotDuration'),
      this.getFormValueFromIdentifier('pickupInstructions'),
      this.getFormValueFromIdentifier('paymentInstructions'),
      this.getFormValueFromIdentifier('checkoutText'),
      this.getFormValueFromIdentifier('purchaseCompleteText'),
      this.getFormValueFromIdentifier('bolHeader'),
      this.getFormValueFromIdentifier('bolWarning'),
      this.getFormValueFromIdentifier('loadingChecklist'),
      this.getFormValueFromIdentifier('departureChecklist'),
      this.getFormValueFromIdentifier('loadNumberPrefix'),
      this.getFormValueFromIdentifier('loadNumberStart'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}