import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostOfferingFormFields {

    public name: FormField
    public description: FormField
    public units: FormField
    public size: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.units = new FormField(
          'units',
          'Units',
          null,
          FormFieldType.text,
          true,
        )
        this.size = new FormField(
          'size',
          'Size',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostOfferingFormFields()

export const postOfferingFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  units: fields.units,
  size: fields.size,
};