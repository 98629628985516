import { SOGetFeeschedule } from '../feeschedule/so-get-feeschedule';


export class SOPutAgreement {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public docusignLink: string = null,
    public units: string = null,
    public state: string = null,
    public start: Date = null,
    public end: Date = null,
    public autoRenew: boolean = null,
    public acq: number = null,
    public top: number = null,
    public feeschedule: SOGetFeeschedule = null,
    public variableAcq: boolean = null,
    public rateable: boolean = null,
    public prePaymentRequired: boolean = null,
    public sapContractNumber: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAgreement(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'docusignLink' in params ? params.docusignLink : null,
      'units' in params ? params.units : null,
      'state' in params ? params.state : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'autoRenew' in params ? params.autoRenew : null,
      'acq' in params ? params.acq : null,
      'top' in params ? params.top : null,
      'feeschedule' in params ? params.feeschedule : null,
      'variableAcq' in params ? params.variableAcq : null,
      'rateable' in params ? params.rateable : null,
      'prePaymentRequired' in params ? params.prePaymentRequired : null,
      'sapContractNumber' in params ? params.sapContractNumber : null,
    );
  }
}
