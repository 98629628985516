

export class SOPutDistributionreport {

  constructor(
    public uid: string,
    public ident: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDistributionreport(
      'uid' in params ? params.uid : null,
      'ident' in params ? params.ident : null,
    );
  }
}
