import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespFeeschedule } from '../feeschedule/so-resp-feeschedule';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespHyear implements ObjectiveProtocol {

  entityType: string = 'hyear';
  uid: string;
  state: string;
  acq: number = 0;
  acqUsed: number = 0;
  year: number;
  @Type(() => SORespAccount)
  account: SORespAccount;
  spotQuantity: number = 0;
  spotQuantityUsed: number = 0;
  delta: number = null;
  @Type(() => SORespFeeschedule)
  feeschedule: SORespFeeschedule = null;
  acqInitial: number = null;
}