import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutPlantFormFields {

    public name: FormField
    public closed: FormField
    public message: FormField
    public timeZone: FormField
    public timeZoneOffset: FormField
    public daylightSavingsObserved: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.closed = new FormField(
          'closed',
          'Closed',
          null,
          FormFieldType.boolean,
          false,
        )
        this.message = new FormField(
          'message',
          'Message',
          null,
          FormFieldType.text,
          false,
        )
        this.timeZone = new FormField(
          'timeZone',
          'Time zone',
          null,
          FormFieldType.text,
          false,
        )
        this.timeZoneOffset = new FormField(
          'timeZoneOffset',
          'Time zone offset',
          null,
          FormFieldType.text,
          false,
        )
        this.daylightSavingsObserved = new FormField(
          'daylightSavingsObserved',
          'Daylight savings observed',
          null,
          FormFieldType.boolean,
          false,
          true,
          true
        )

    }
}

const fields = new PutPlantFormFields()

export const putPlantFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  closed: fields.closed,
  message: fields.message,
  timeZone: fields.timeZone,
  timeZoneOffset: fields.timeZoneOffset,
  daylightSavingsObserved: fields.daylightSavingsObserved,
};