import { SOGetTag } from '../tag/so-get-tag';
import { SOGetLine } from '../line/so-get-line';
import { SOGetTank } from '../tank/so-get-tank';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetLinag implements QueryProtocol {

  constructor(
    public uid: string = null,
    public tag: SOGetTag = null,
    public line: SOGetLine = null,
    public tank: SOGetTank = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetLinag(
      'uid' in params ? params.uid : null,
      'tag' in params ? params.tag : null,
      'line' in params ? params.line : null,
      'tank' in params ? params.tank : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
