import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutCarrier } from './so-put-carrier';
import { SOGetCarrier } from './so-get-carrier';
import { SOPostCarrier } from './so-post-carrier';
import { SODeleteCarrier } from './so-delete-carrier';
import {SORespCarrier} from './so-resp-carrier';
import {SOPluralCarrier} from './so-plural-carrier';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOCarrierService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'carrier',
      entityCore
    );
  }

  /** GET Carrier by id. Will 404 if id not found */
  get(request: SOGetCarrier): Observable<SOPluralCarrier> {
    return this.httpClient.get<SOPluralCarrier>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCarrier, resp) }),
      tap(_ => this.log(`fetched Carrier`)),
      catchError(this.handleError<SOPluralCarrier>(`get Carrier`))
    );
  }

  /** POST: add a new Carrier to the server */
  post(request: SOPostCarrier): Observable<SORespCarrier> {
    return this.httpClient.post<SORespCarrier>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCarrier, resp) }),
      tap((response: SORespCarrier) => this.log(`added Carrier w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCarrier>('Carrier post'))
    );
  }

  /** PUT: update Carrier  */
  put(request: SOPutCarrier): Observable<SORespCarrier> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCarrier>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCarrier, resp) }),
      tap((response: SORespCarrier) => this.log(`edited Carrier w/ uid=${uid}`)),
      catchError(this.handleError<SORespCarrier>('Carrier put'))
    );
  }

  /** DELETE: delete the Carrier from the server */
  delete(request: SODeleteCarrier): Observable<SORespCarrier> {
    return this.httpClient.delete<SORespCarrier>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Carrier uid=${request.uid}`)),
      catchError(this.handleError<SORespCarrier>('delete Carrier'))
    );
  }
}
