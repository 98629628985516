import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostSubscriptionFormFields {

    public containersession: FormField
    public user: FormField
    public publication: FormField

    constructor() {

        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.autoCompleteList,
          true,
        )
        this.publication = new FormField(
          'publication',
          'Publication',
          'publication',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostSubscriptionFormFields()

export const postSubscriptionFormFieldOptions: {[key: string]: FormField} = {
  containersession: fields.containersession,
  user: fields.user,
  publication: fields.publication,
};