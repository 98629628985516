import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOOfferingService} from '../../core/offering/eo-offering';
import {SOGetOffering} from '../../core/offering/so-get-offering';
import {RAPutOffering} from './ra-put-offering';
import {RAPostOffering} from './ra-post-offering';
import {RADeleteOffering} from './ra-delete-offering';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEOffering } from "./ce-offering"


export class OfferingCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'offering';
  title = 'Offering';
  ceProtocol: CEConstructor = CEOffering

  entityService: EOOfferingService;
  soGetClass = SOGetOffering;
  soGet: SOGetOffering;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOOfferingService
    );
    this.adapters = [
      new RAPostOffering(this),
      new RAPutOffering(this),
      new RADeleteOffering(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetOffering();
    this.soGet.queryOptions.limit = this.limit;
  }


}