import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutChronreportFormFields {

    public data: FormField

    constructor() {

        this.data = new FormField(
          'data',
          'Data',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutChronreportFormFields()

export const putChronreportFormFieldOptions: {[key: string]: FormField} = {
  data: fields.data,
};