import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostSlotoptionFormFields {

    public slot: FormField
    public agreemonth: FormField

    constructor() {

        this.slot = new FormField(
          'slot',
          'Slot',
          'slot',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.agreemonth = new FormField(
          'agreemonth',
          'Agreemonth',
          'agreemonth',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostSlotoptionFormFields()

export const postSlotoptionFormFieldOptions: {[key: string]: FormField} = {
  slot: fields.slot,
  agreemonth: fields.agreemonth,
};