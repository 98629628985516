import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutSlot } from './so-put-slot';
import { SOGetSlot } from './so-get-slot';
import { SOPostSlot } from './so-post-slot';
import { SODeleteSlot } from './so-delete-slot';
import {SORespSlot} from './so-resp-slot';
import {SOPluralSlot} from './so-plural-slot';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOSlotService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'slot',
      entityCore
    );
  }

  /** GET Slot by id. Will 404 if id not found */
  get(request: SOGetSlot): Observable<SOPluralSlot> {
    return this.httpClient.get<SOPluralSlot>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralSlot, resp) }),
      tap(_ => this.log(`fetched Slot`)),
      catchError(this.handleError<SOPluralSlot>(`get Slot`))
    );
  }

  /** POST: add a new Slot to the server */
  post(request: SOPostSlot): Observable<SORespSlot> {
    return this.httpClient.post<SORespSlot>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSlot, resp) }),
      tap((response: SORespSlot) => this.log(`added Slot w/ id=${response.uid}`)),
      catchError(this.handleError<SORespSlot>('Slot post'))
    );
  }

  /** PUT: update Slot  */
  put(request: SOPutSlot): Observable<SORespSlot> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSlot>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSlot, resp) }),
      tap((response: SORespSlot) => this.log(`edited Slot w/ uid=${uid}`)),
      catchError(this.handleError<SORespSlot>('Slot put'))
    );
  }

  /** DELETE: delete the Slot from the server */
  delete(request: SODeleteSlot): Observable<SORespSlot> {
    return this.httpClient.delete<SORespSlot>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Slot uid=${request.uid}`)),
      catchError(this.handleError<SORespSlot>('delete Slot'))
    );
  }
}
