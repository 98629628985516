import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostHemonFormFields {

    public state: FormField
    public n2Level: FormField
    public n2Pressure: FormField
    public heTemp: FormField
    public heLevel: FormField
    public hePressure: FormField
    public n2LevelDcs: FormField
    public n2PressureDcs: FormField
    public heTempDcs: FormField
    public heLevelDcs: FormField
    public hePressureDcs: FormField
    public load: FormField
    public task: FormField
    public containersession: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          true,
        )
        this.n2Level = new FormField(
          'n2Level',
          'N2 level',
          null,
          FormFieldType.text,
          true,
        )
        this.n2Pressure = new FormField(
          'n2Pressure',
          'N2 pressure',
          null,
          FormFieldType.text,
          true,
        )
        this.heTemp = new FormField(
          'heTemp',
          'He temp',
          null,
          FormFieldType.text,
          false,
        )
        this.heLevel = new FormField(
          'heLevel',
          'He level',
          null,
          FormFieldType.text,
          true,
        )
        this.hePressure = new FormField(
          'hePressure',
          'He pressure',
          null,
          FormFieldType.text,
          true,
        )
        this.n2LevelDcs = new FormField(
          'n2LevelDcs',
          'N2 level dcs',
          null,
          FormFieldType.text,
          false,
        )
        this.n2PressureDcs = new FormField(
          'n2PressureDcs',
          'N2 pressure dcs',
          null,
          FormFieldType.text,
          false,
        )
        this.heTempDcs = new FormField(
          'heTempDcs',
          'He temp dcs',
          null,
          FormFieldType.text,
          false,
        )
        this.heLevelDcs = new FormField(
          'heLevelDcs',
          'He level dcs',
          null,
          FormFieldType.text,
          false,
        )
        this.hePressureDcs = new FormField(
          'hePressureDcs',
          'He pressure dcs',
          null,
          FormFieldType.text,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.task = new FormField(
          'task',
          'Task',
          'task',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostHemonFormFields()

export const postHemonFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  n2Level: fields.n2Level,
  n2Pressure: fields.n2Pressure,
  heTemp: fields.heTemp,
  heLevel: fields.heLevel,
  hePressure: fields.hePressure,
  n2LevelDcs: fields.n2LevelDcs,
  n2PressureDcs: fields.n2PressureDcs,
  heTempDcs: fields.heTempDcs,
  heLevelDcs: fields.heLevelDcs,
  hePressureDcs: fields.hePressureDcs,
  load: fields.load,
  task: fields.task,
  containersession: fields.containersession,
};