import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTemptraclinFormFields {

    public tempclin: FormField
    public temptract: FormField

    constructor() {

        this.tempclin = new FormField(
          'tempclin',
          'Tempclin',
          'tempclin',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.temptract = new FormField(
          'temptract',
          'Temptract',
          'temptract',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostTemptraclinFormFields()

export const postTemptraclinFormFieldOptions: {[key: string]: FormField} = {
  tempclin: fields.tempclin,
  temptract: fields.temptract,
};