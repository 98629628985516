import {SOPostSale} from '../../core/sale/so-post-sale';
import {SaleCoreBase} from './sale.core.base';
import {SOGetSale} from '../../core/sale/so-get-sale';
import {FormField, SelectionOption} from 'lionheartland';
import {postSaleFormFieldOptions} from './post-sale-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {DistributionmonthCore} from '../distributionmonth/distributionmonth.core';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';

export class RAPostSale extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSaleFormFieldOptions);

  RequestClass = SOPostSale

  constructor(
    public entityCore: SaleCoreBase
  ) {
    super();
    if (postSaleFormFieldOptions.distribution) {
      postSaleFormFieldOptions.distribution.makeCore = true;
    }
    if (postSaleFormFieldOptions.distributionmonth) {
      postSaleFormFieldOptions.distributionmonth.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
      this.getFormValueFromIdentifier(
        'distributionmonth',
        SOGetDistributionmonth,
      ),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}