import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutEventFormFields {

    public title: FormField
    public description: FormField
    public eventType: FormField
    public start: FormField
    public end: FormField
    public slotlet: FormField

    constructor() {

        this.title = new FormField(
          'title',
          'Title',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
        )
        this.eventType = new FormField(
          'eventType',
          'Event type',
          null,
          FormFieldType.text,
          false,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          false,
        )
        this.slotlet = new FormField(
          'slotlet',
          'Slotlet',
          'slotlet',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutEventFormFields()

export const putEventFormFieldOptions: {[key: string]: FormField} = {
  title: fields.title,
  description: fields.description,
  eventType: fields.eventType,
  start: fields.start,
  end: fields.end,
  slotlet: fields.slotlet,
};