import {SOPutQuotelet} from '../../core/quotelet/so-put-quotelet';
import {QuoteletCoreBase} from './quotelet.core.base';
import {SOGetQuotelet} from '../../core/quotelet/so-get-quotelet';
import {FormField, SelectionOption} from 'lionheartland';
import {putQuoteletFormFieldOptions} from './put-quotelet-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {OrderCore} from '../order/order.core';
import {SOGetOrder} from '../../core/order/so-get-order';

export class RAPutQuotelet extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putQuoteletFormFieldOptions);

  RequestClass = SOPutQuotelet

  constructor(
    public entityCore: QuoteletCoreBase
  ) {
    super();
    if (putQuoteletFormFieldOptions.order) {
      putQuoteletFormFieldOptions.order.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('units'),
      this.getFormValueFromIdentifier('quantityVolume'),
      this.getFormValueFromIdentifier('quantityLoads'),
      this.getFormValueFromIdentifier('indexRate'),
      this.getFormValueFromIdentifier('variableRate'),
      this.getFormValueFromIdentifier('totalRate'),
      this.getFormValueFromIdentifier('totalAmount'),
      this.getFormValueFromIdentifier(
        'order',
        SOGetOrder,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}