import {SOPutPublication} from '../../core/publication/so-put-publication';
import {PublicationCoreBase} from './publication.core.base';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {FormField, SelectionOption} from 'lionheartland';
import {putPublicationFormFieldOptions} from './put-publication-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutPublication extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putPublicationFormFieldOptions);

  RequestClass = SOPutPublication

  constructor(
    public entityCore: PublicationCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('displayName'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}