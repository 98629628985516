import {SOPostTrailerauth} from '../../core/trailerauth/so-post-trailerauth';
import {TrailerauthCoreBase} from './trailerauth.core.base';
import {SOGetTrailerauth} from '../../core/trailerauth/so-get-trailerauth';
import {FormField, SelectionOption} from 'lionheartland';
import {postTrailerauthFormFieldOptions} from './post-trailerauth-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {TrailerCore} from '../trailer/trailer.core';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';

export class RAPostTrailerauth extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTrailerauthFormFieldOptions);

  RequestClass = SOPostTrailerauth

  constructor(
    public entityCore: TrailerauthCoreBase
  ) {
    super();
    if (postTrailerauthFormFieldOptions.trailer) {
      postTrailerauthFormFieldOptions.trailer.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier(
        'trailer',
        SOGetTrailer,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}