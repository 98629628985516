import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutRelease } from './so-put-release';
import { SOGetRelease } from './so-get-release';
import { SOPostRelease } from './so-post-release';
import { SODeleteRelease } from './so-delete-release';
import {SORespRelease} from './so-resp-release';
import {SOPluralRelease} from './so-plural-release';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOReleaseService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'release',
      entityCore
    );
  }

  /** GET Release by id. Will 404 if id not found */
  get(request: SOGetRelease): Observable<SOPluralRelease> {
    return this.httpClient.get<SOPluralRelease>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRelease, resp) }),
      tap(_ => this.log(`fetched Release`)),
      catchError(this.handleError<SOPluralRelease>(`get Release`))
    );
  }

  /** POST: add a new Release to the server */
  post(request: SOPostRelease): Observable<SORespRelease> {
    return this.httpClient.post<SORespRelease>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelease, resp) }),
      tap((response: SORespRelease) => this.log(`added Release w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRelease>('Release post'))
    );
  }

  /** PUT: update Release  */
  put(request: SOPutRelease): Observable<SORespRelease> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRelease>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelease, resp) }),
      tap((response: SORespRelease) => this.log(`edited Release w/ uid=${uid}`)),
      catchError(this.handleError<SORespRelease>('Release put'))
    );
  }

  /** DELETE: delete the Release from the server */
  delete(request: SODeleteRelease): Observable<SORespRelease> {
    return this.httpClient.delete<SORespRelease>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Release uid=${request.uid}`)),
      catchError(this.handleError<SORespRelease>('delete Release'))
    );
  }
}
