import { SOGetDistributionyear } from '../distributionyear/so-get-distributionyear';
import { SOGetDistribution } from '../distribution/so-get-distribution';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetDistributionmonth implements QueryProtocol {

  constructor(
    public uid: string = null,
    public state: string = null,
    public month: number = null,
    public distributionyear: SOGetDistributionyear = null,
    public distribution: SOGetDistribution = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetDistributionmonth(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'month' in params ? params.month : null,
      'distributionyear' in params ? params.distributionyear : null,
      'distribution' in params ? params.distribution : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
