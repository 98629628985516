import { SOGetEvent } from '../event/so-get-event';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetTempclin } from '../tempclin/so-get-tempclin';


export class SOPutSlotlet {

  constructor(
    public uid: string,
    public start: Date = null,
    public end: Date = null,
    public status: string = null,
    public dayOffset: number = null,
    public slotNumber: number = null,
    public event: SOGetEvent = null,
    public account: SOGetAccount = null,
    public tempclin: SOGetTempclin = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSlotlet(
      'uid' in params ? params.uid : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'status' in params ? params.status : null,
      'dayOffset' in params ? params.dayOffset : null,
      'slotNumber' in params ? params.slotNumber : null,
      'event' in params ? params.event : null,
      'account' in params ? params.account : null,
      'tempclin' in params ? params.tempclin : null,
    );
  }
}
