import {SORespCarrierauth} from '../../core/carrierauth/so-resp-carrierauth';
import {CarrierauthCore} from './carrierauth.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutCarrierauthFormFields} from './put-carrierauth-form-fields';
import { CECarrierauthAttributes } from './ce-carrierauth-attributes'


export class CECarrierauthBase extends CEBase {

    public details: CECarrierauthAttributes;
    public fromFields: PutCarrierauthFormFields = new PutCarrierauthFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: CarrierauthCore,
        public entity: SORespCarrierauth,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CECarrierauthAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.carrier)

    }
}