import { SOGetBid } from '../bid/so-get-bid';
import { SOGetAgreement } from '../agreement/so-get-agreement';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetAward implements QueryProtocol {

  constructor(
    public uid: string = null,
    public bid: SOGetBid = null,
    public agreement: SOGetAgreement = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetAward(
      'uid' in params ? params.uid : null,
      'bid' in params ? params.bid : null,
      'agreement' in params ? params.agreement : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
