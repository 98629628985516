import { SOGetAccount } from '../account/so-get-account';
import { SOGetHyear } from '../hyear/so-get-hyear';


export class SOPutHmonth {

  constructor(
    public uid: string,
    public state: string = null,
    public mcq: number = null,
    public month: number = null,
    public year: number = null,
    public account: SOGetAccount = null,
    public delta: number = null,
    public hyear: SOGetHyear = null,
    public ordersSubmitted: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutHmonth(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'mcq' in params ? params.mcq : null,
      'month' in params ? params.month : null,
      'year' in params ? params.year : null,
      'account' in params ? params.account : null,
      'delta' in params ? params.delta : null,
      'hyear' in params ? params.hyear : null,
      'ordersSubmitted' in params ? params.ordersSubmitted : null,
    );
  }
}
