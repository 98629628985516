import {SOPutChecklistitem} from '../../core/checklistitem/so-put-checklistitem';
import {ChecklistitemCoreBase} from './checklistitem.core.base';
import {SOGetChecklistitem} from '../../core/checklistitem/so-get-checklistitem';
import {FormField, SelectionOption} from 'lionheartland';
import {putChecklistitemFormFieldOptions} from './put-checklistitem-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {ChecklistCore} from '../checklist/checklist.core';
import {SOGetChecklist} from '../../core/checklist/so-get-checklist';

export class RAPutChecklistitem extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putChecklistitemFormFieldOptions);

  RequestClass = SOPutChecklistitem

  constructor(
    public entityCore: ChecklistitemCoreBase
  ) {
    super();
    if (putChecklistitemFormFieldOptions.checklist) {
      putChecklistitemFormFieldOptions.checklist.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('body'),
      this.getFormValueFromIdentifier('index'),
      this.getFormValueFromIdentifier('required'),
      this.getFormValueFromIdentifier('checked'),
      this.getFormValueFromIdentifier(
        'checklist',
        SOGetChecklist,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}