import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOProductionService} from '../../core/production/eo-production';
import {SOGetProduction} from '../../core/production/so-get-production';
import {RAPutProduction} from './ra-put-production';
import {RAPostProduction} from './ra-post-production';
import {RADeleteProduction} from './ra-delete-production';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEProduction } from "./ce-production"


export class ProductionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'production';
  title = 'Production';
  ceProtocol: CEConstructor = CEProduction

  entityService: EOProductionService;
  soGetClass = SOGetProduction;
  soGet: SOGetProduction;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOProductionService
    );
    this.adapters = [
      new RAPostProduction(this),
      new RAPutProduction(this),
      new RADeleteProduction(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetProduction();
    this.soGet.queryOptions.limit = this.limit;
  }


}