import { SOGetSlot } from '../slot/so-get-slot';
import { SOGetAccount } from '../account/so-get-account';


export class SOPostReservation {

  constructor(
    public slot: SOGetSlot,
    public account: SOGetAccount,
    public description: string = "",
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostReservation(
      'description' in params ? params.description : null,
      'slot' in params ? params.slot : null,
      'account' in params ? params.account : null,
    );
  }
}
