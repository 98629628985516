import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOLoadService} from '../../core/load/eo-load';
import {SOGetLoad} from '../../core/load/so-get-load';
import {RAPutLoad} from './ra-put-load';
import {RAPostLoad} from './ra-post-load';
import {RADeleteLoad} from './ra-delete-load';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {FormControl, FormGroup} from "@angular/forms";
import {SOGetAccount} from '../../core/account/so-get-account'
import { CELoad } from "./ce-load"


export class LoadCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'load';
  title = 'Load';
  ceProtocol: CEConstructor = CELoad

  entityService: EOLoadService;
  soGetClass = SOGetLoad;
  soGet: SOGetLoad;

  searchEnabled = true;
  listSpecType = ListSpecType.tableEntityRows;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOLoadService
    );
    this.adapters = [
      new RAPostLoad(this),
      new RAPutLoad(this),
      new RADeleteLoad(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetLoad();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'requestedFillDate';
    this.soGet.queryOptions.descending = true;
  }


}