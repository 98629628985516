import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespShutelng implements ObjectiveProtocol {

  entityType: string = 'shutelng';
  uid: string;
  data: any;
  @Type(() => Date)
  dateCreated: Date;
}