import { SOGetBid } from '../bid/so-get-bid';
import { SOGetFeeschedule } from '../feeschedule/so-get-feeschedule';


export class SOPostAward {

  constructor(
    public amount: number,
    public quantity: number,
    public loadQuantity: number,
    public bid: SOGetBid,
    public notes: string = null,
    public feeschedule: SOGetFeeschedule = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAward(
      'amount' in params ? params.amount : null,
      'quantity' in params ? params.quantity : null,
      'loadQuantity' in params ? params.loadQuantity : null,
      'notes' in params ? params.notes : null,
      'bid' in params ? params.bid : null,
      'feeschedule' in params ? params.feeschedule : null,
    );
  }
}
