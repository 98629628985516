import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostFaq {

  constructor(
    public index: number,
    public title: string,
    public body: string,
    public distribution: SOGetDistribution,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostFaq(
      'index' in params ? params.index : null,
      'title' in params ? params.title : null,
      'body' in params ? params.body : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
