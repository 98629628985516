import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutDistributionreportFormFields {

    public ident: FormField

    constructor() {

        this.ident = new FormField(
          'ident',
          'Ident',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutDistributionreportFormFields()

export const putDistributionreportFormFieldOptions: {[key: string]: FormField} = {
  ident: fields.ident,
};