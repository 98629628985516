import {SORespCarrier} from '../../core/carrier/so-resp-carrier';
import {CarrierCore} from './carrier.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutCarrierFormFields} from './put-carrier-form-fields';
import { CECarrierAttributes } from './ce-carrier-attributes'


export class CECarrierBase extends CEBase {

    public details: CECarrierAttributes;
    public fromFields: PutCarrierFormFields = new PutCarrierFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: CarrierCore,
        public entity: SORespCarrier,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CECarrierAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.companyName)
        this.attributes.push(this.details.emailDomain)
        this.attributes.push(this.details.handle)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.signedAgreement)
        this.attributes.push(this.details.organization)
        this.attributes.push(this.details.carrierauths)
        this.attributes.push(this.details.roles)
        this.attributes.push(this.details.publications)

    }
}