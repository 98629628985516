import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostMonthclose {

  constructor(
    public distribution: SOGetDistribution,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostMonthclose(
      'distribution' in params ? params.distribution : null,
    );
  }
}
