import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutEvent } from './so-put-event';
import { SOGetEvent } from './so-get-event';
import { SOPostEvent } from './so-post-event';
import { SODeleteEvent } from './so-delete-event';
import {SORespEvent} from './so-resp-event';
import {SOPluralEvent} from './so-plural-event';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOEventService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'event',
      entityCore
    );
  }

  /** GET Event by id. Will 404 if id not found */
  get(request: SOGetEvent): Observable<SOPluralEvent> {
    return this.httpClient.get<SOPluralEvent>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEvent, resp) }),
      tap(_ => this.log(`fetched Event`)),
      catchError(this.handleError<SOPluralEvent>(`get Event`))
    );
  }

  /** POST: add a new Event to the server */
  post(request: SOPostEvent): Observable<SORespEvent> {
    return this.httpClient.post<SORespEvent>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEvent, resp) }),
      tap((response: SORespEvent) => this.log(`added Event w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEvent>('Event post'))
    );
  }

  /** PUT: update Event  */
  put(request: SOPutEvent): Observable<SORespEvent> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEvent>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEvent, resp) }),
      tap((response: SORespEvent) => this.log(`edited Event w/ uid=${uid}`)),
      catchError(this.handleError<SORespEvent>('Event put'))
    );
  }

  /** DELETE: delete the Event from the server */
  delete(request: SODeleteEvent): Observable<SORespEvent> {
    return this.httpClient.delete<SORespEvent>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Event uid=${request.uid}`)),
      catchError(this.handleError<SORespEvent>('delete Event'))
    );
  }
}
