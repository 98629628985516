import { Type } from 'class-transformer';
import { SORespLine } from '../line/so-resp-line';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDcs implements ObjectiveProtocol {

  entityType: string = 'dcs';
  uid: string;
  name: string;
  endpoint: string;
  @Type(() => SORespLine)
  line: SORespLine;
}