import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import { SOGetAccount } from '../account/so-get-account';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetInvoice implements QueryProtocol {

  constructor(
    public uid: string = null,
    public distributionmonth: SOGetDistributionmonth = null,
    public account: SOGetAccount = null,
    public paymentStatus: string = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetInvoice(
      'uid' in params ? params.uid : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'account' in params ? params.account : null,
      'paymentStatus' in params ? params.paymentStatus : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
