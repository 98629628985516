import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostInvoiceFormFields {

    public distributionmonth: FormField
    public account: FormField

    constructor() {

        this.distributionmonth = new FormField(
          'distributionmonth',
          'Distributionmonth',
          'distributionmonth',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostInvoiceFormFields()

export const postInvoiceFormFieldOptions: {[key: string]: FormField} = {
  distributionmonth: fields.distributionmonth,
};