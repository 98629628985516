import {SOPostSaleaccountasc} from '../../core/saleaccountasc/so-post-saleaccountasc';
import {SaleaccountascCoreBase} from './saleaccountasc.core.base';
import {SOGetSaleaccountasc} from '../../core/saleaccountasc/so-get-saleaccountasc';
import {FormField, SelectionOption} from 'lionheartland';
import {postSaleaccountascFormFieldOptions} from './post-saleaccountasc-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SaleCore} from '../sale/sale.core';
import {SOGetSale} from '../../core/sale/so-get-sale';

export class RAPostSaleaccountasc extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSaleaccountascFormFieldOptions);

  RequestClass = SOPostSaleaccountasc

  constructor(
    public entityCore: SaleaccountascCoreBase
  ) {
    super();
    if (postSaleaccountascFormFieldOptions.sale) {
      postSaleaccountascFormFieldOptions.sale.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier(
        'sale',
        SOGetSale,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}