import {SORespFaq} from '../../core/faq/so-resp-faq';
import {FaqCore} from './faq.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFaqFormFields} from './put-faq-form-fields';
import {SOGetFaq} from '../../core/faq/so-get-faq';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEFaqAttributesBase {

    index: DetailEntry
    title: DetailEntry
    body: DetailEntry
    distribution: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespFaq,
        app: GasDirectApp,
        formFields: PutFaqFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.index = new DetailEntry(
            'Index',
            entity.index,
            'index',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.index
        )

        this.title = new DetailEntry(
            'Title',
            entity.title,
            'title',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.title
        )

        this.body = new DetailEntry(
            'Body',
            entity.body,
            'body',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.body
        )


        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        this.distribution.singleFieldSelect = true
    }
}