import {SORespHemon} from '../../core/hemon/so-resp-hemon';
import {HemonCore} from './hemon.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHemonFormFields} from './put-hemon-form-fields';
import { CEHemonAttributes } from './ce-hemon-attributes'


export class CEHemonBase extends CEBase {

    public details: CEHemonAttributes;
    public fromFields: PutHemonFormFields = new PutHemonFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: HemonCore,
        public entity: SORespHemon,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEHemonAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.n2Level)
        this.attributes.push(this.details.n2Pressure)
        this.attributes.push(this.details.heTemp)
        this.attributes.push(this.details.heLevel)
        this.attributes.push(this.details.hePressure)
        this.attributes.push(this.details.n2LevelDcs)
        this.attributes.push(this.details.n2PressureDcs)
        this.attributes.push(this.details.heTempDcs)
        this.attributes.push(this.details.heLevelDcs)
        this.attributes.push(this.details.hePressureDcs)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.task)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.tagreports)
        this.attributes.push(this.details.hestatusitems)
        this.attributes.push(this.details.containersessionsTwo)

    }
}