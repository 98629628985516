import { SOGetAccount } from '../account/so-get-account';
import { SOGetAgreement } from '../agreement/so-get-agreement';
import { SOGetAgreemonth } from '../agreemonth/so-get-agreemonth';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';


export class SOPostOrder {

  constructor(
    public account: SOGetAccount,
    public status: string = null,
    public agreement: SOGetAgreement = null,
    public agreemonth: SOGetAgreemonth = null,
    public distributionmonth: SOGetDistributionmonth = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostOrder(
      'status' in params ? params.status : null,
      'account' in params ? params.account : null,
      'agreement' in params ? params.agreement : null,
      'agreemonth' in params ? params.agreemonth : null,
      'distributionmonth' in params ? params.distributionmonth : null,
    );
  }
}
