import {SOPutToken} from '../../core/token/so-put-token';
import {TokenCoreBase} from './token.core.base';
import {SOGetToken} from '../../core/token/so-get-token';
import {FormField, SelectionOption} from 'lionheartland';
import {putTokenFormFieldOptions} from './put-token-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutToken extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTokenFormFieldOptions);

  RequestClass = SOPutToken

  constructor(
    public entityCore: TokenCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}