import { SOGetLoad } from '../load/so-get-load';
import {ENTare} from '../../enums';


export class SOPostWeight {

  constructor(
    public tare: ENTare,
    public amount: number,
    public load: SOGetLoad,
    public amountDcs: number = null,
    public dcsTimestamp: Date = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostWeight(
      'tare' in params ? params.tare : null,
      'amount' in params ? params.amount : null,
      'load' in params ? params.load : null,
      'amountDcs' in params ? params.amountDcs : null,
      'dcsTimestamp' in params ? params.dcsTimestamp : null,
    );
  }
}
