import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostDistributionFormFields {

    public name: FormField
    public type: FormField
    public marketIndexId: FormField
    public conversionRate: FormField
    public galToWeight: FormField
    public defaultSlotsPerDay: FormField
    public providerSlotDurationHours: FormField
    public customerSlotDurationHours: FormField
    public showSlotDuration: FormField
    public pickupInstructions: FormField
    public paymentInstructions: FormField
    public checkoutText: FormField
    public purchaseCompleteText: FormField
    public line: FormField
    public bolHeader: FormField
    public bolWarning: FormField
    public loadingChecklist: FormField
    public departureChecklist: FormField
    public nextLoadNumber: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          false,
          true,
          "normal"
        )
        this.marketIndexId = new FormField(
          'marketIndexId',
          'Market index id',
          null,
          FormFieldType.text,
          false,
        )
        this.conversionRate = new FormField(
          'conversionRate',
          'Conversion rate',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.galToWeight = new FormField(
          'galToWeight',
          'Gal to weight',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.defaultSlotsPerDay = new FormField(
          'defaultSlotsPerDay',
          'Default slots per day',
          null,
          FormFieldType.text,
          false,
          true,
          6
        )
        this.providerSlotDurationHours = new FormField(
          'providerSlotDurationHours',
          'Provider slot duration hours',
          null,
          FormFieldType.text,
          false,
          true,
          4
        )
        this.customerSlotDurationHours = new FormField(
          'customerSlotDurationHours',
          'Customer slot duration hours',
          null,
          FormFieldType.text,
          false,
          true,
          2
        )
        this.showSlotDuration = new FormField(
          'showSlotDuration',
          'Show slot duration',
          null,
          FormFieldType.boolean,
          false,
          true,
          true
        )
        this.pickupInstructions = new FormField(
          'pickupInstructions',
          'Pickup instructions',
          null,
          FormFieldType.text,
          false,
        )
        this.paymentInstructions = new FormField(
          'paymentInstructions',
          'Payment instructions',
          null,
          FormFieldType.text,
          false,
        )
        this.checkoutText = new FormField(
          'checkoutText',
          'Checkout text',
          null,
          FormFieldType.text,
          false,
        )
        this.purchaseCompleteText = new FormField(
          'purchaseCompleteText',
          'Purchase complete text',
          null,
          FormFieldType.text,
          false,
        )
        this.line = new FormField(
          'line',
          'Line',
          'line',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.bolHeader = new FormField(
          'bolHeader',
          'Bol header',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.bolWarning = new FormField(
          'bolWarning',
          'Bol warning',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.loadingChecklist = new FormField(
          'loadingChecklist',
          'Loading checklist',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.departureChecklist = new FormField(
          'departureChecklist',
          'Departure checklist',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.nextLoadNumber = new FormField(
          'nextLoadNumber',
          'Next load number',
          null,
          FormFieldType.text,
          false,
          true,
          1
        )

    }
}

const fields = new PostDistributionFormFields()

export const postDistributionFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  type: fields.type,
  marketIndexId: fields.marketIndexId,
  conversionRate: fields.conversionRate,
  galToWeight: fields.galToWeight,
  defaultSlotsPerDay: fields.defaultSlotsPerDay,
  providerSlotDurationHours: fields.providerSlotDurationHours,
  customerSlotDurationHours: fields.customerSlotDurationHours,
  showSlotDuration: fields.showSlotDuration,
  pickupInstructions: fields.pickupInstructions,
  paymentInstructions: fields.paymentInstructions,
  checkoutText: fields.checkoutText,
  purchaseCompleteText: fields.purchaseCompleteText,
  line: fields.line,
  bolHeader: fields.bolHeader,
  bolWarning: fields.bolWarning,
  loadingChecklist: fields.loadingChecklist,
  departureChecklist: fields.departureChecklist,
  nextLoadNumber: fields.nextLoadNumber,
};