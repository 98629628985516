import {SORespToken} from '../../core/token/so-resp-token';
import {TokenCore} from './token.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTokenFormFields} from './put-token-form-fields';
import {SOGetToken} from '../../core/token/so-get-token';
import {SOGetGrant} from '../../core/grant/so-get-grant';
import {SOGetUser} from '../../core/user/so-get-user';


export class CETokenAttributesBase {

    roleGrant: DetailEntry
    user: DetailEntry
    dateCreated: DetailEntry
    provider: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespToken,
        app: GasDirectApp,
        formFields: PutTokenFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.provider = new DetailEntry(
            'Provider',
            entity.provider,
            'provider',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const roleGrantCore = app.makeCore( 'grant');
        roleGrantCore.soGet = SOGetGrant.construct({
        });
        let roleGrantCeProtocol;
        if (entity.roleGrant) {
            roleGrantCeProtocol = createCEProtocol(
                roleGrantCore.ceProtocol,
                parentEntityViewSpec,
                roleGrantCore,
                entity.roleGrant,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleGrantCore
        )
        roleGrantCore.title = heading ? heading : roleGrantCore.title
        roleGrantCore.listTitle = 'Role grant'

        if (entity.roleGrant) {
            displayStringFactory.reconfigure(
                entity.roleGrant,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        roleGrantCore.configureForDisplay(parentEntityViewSpec)

        this.roleGrant = new DetailEntry(
          heading ? heading : roleGrantCore.listTitle,
          roleGrantCeProtocol ? roleGrantCeProtocol.entityListView.fullHeading : null,
          'roleGrant',
          BaseDetailType.pushedDetail,
          roleGrantCore,
          EditableType.never
        )
        if (entity.roleGrant) {
          this.roleGrant.ceProtocol = roleGrantCeProtocol;
          this.roleGrant.cachedListEntry = roleGrantCore.makeListEntry(parentEntityViewSpec, entity.roleGrant, 0)
          roleGrantCore.selectedEntity = entity.roleGrant;
        }
        this.roleGrant.singleFieldSelect = true

        const userCore = app.makeCore( 'user');
        userCore.soGet = SOGetUser.construct({
        });
        userCore.soGet.queryOptions.sortKey = 'surname';
        let userCeProtocol;
        if (entity.user) {
            userCeProtocol = createCEProtocol(
                userCore.ceProtocol,
                parentEntityViewSpec,
                userCore,
                entity.user,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            userCore
        )
        userCore.title = heading ? heading : userCore.title
        userCore.listTitle = 'User'

        if (entity.user) {
            displayStringFactory.reconfigure(
                entity.user,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        userCore.configureForDisplay(parentEntityViewSpec)

        this.user = new DetailEntry(
          heading ? heading : userCore.listTitle,
          userCeProtocol ? userCeProtocol.entityListView.fullHeading : null,
          'user',
          BaseDetailType.pushedDetail,
          userCore,
          EditableType.never
        )
        if (entity.user) {
          this.user.ceProtocol = userCeProtocol;
          this.user.cachedListEntry = userCore.makeListEntry(parentEntityViewSpec, entity.user, 0)
          userCore.selectedEntity = entity.user;
        }
        this.user.singleFieldSelect = true
    }
}