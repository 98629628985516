import {SORespDistributionyear} from '../../core/distributionyear/so-resp-distributionyear';
import {DistributionyearCore} from './distributionyear.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributionyearFormFields} from './put-distributionyear-form-fields';
import { CEDistributionyearAttributes } from './ce-distributionyear-attributes'


export class CEDistributionyearBase extends CEBase {

    public details: CEDistributionyearAttributes;
    public fromFields: PutDistributionyearFormFields = new PutDistributionyearFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DistributionyearCore,
        public entity: SORespDistributionyear,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDistributionyearAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.year)
        this.attributes.push(this.details.volumeContracted)
        this.attributes.push(this.details.volumeSold)
        this.attributes.push(this.details.volumeSoldEstimated)
        this.attributes.push(this.details.productionVolume)
        this.attributes.push(this.details.productionVolumeEstimated)
        this.attributes.push(this.details.spotVolumeContracted)
        this.attributes.push(this.details.spotVolumeSold)
        this.attributes.push(this.details.distributionmonths)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.hyears)
        this.attributes.push(this.details.agreeyears)

    }
}