import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTagService} from '../../core/tag/eo-tag';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {RAPutTag} from './ra-put-tag';
import {RAPostTag} from './ra-post-tag';
import {RADeleteTag} from './ra-delete-tag';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETag } from "./ce-tag"


export class TagCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'tag';
  title = 'Tag';
  ceProtocol: CEConstructor = CETag

  entityService: EOTagService;
  soGetClass = SOGetTag;
  soGet: SOGetTag;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTagService
    );
    this.adapters = [
      new RAPostTag(this),
      new RAPutTag(this),
      new RADeleteTag(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTag();
    this.soGet.queryOptions.limit = this.limit;
  }


}