import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespHyear } from '../hyear/so-resp-hyear';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespHmonth implements ObjectiveProtocol {

  entityType: string = 'hmonth';
  uid: string;
  state: string;
  mcq: number = 0;
  mcqUsed: number = 0;
  month: number;
  year: number;
  @Type(() => SORespAccount)
  account: SORespAccount;
  delta: number = null;
  @Type(() => SORespHyear)
  hyear: SORespHyear;
  mcqEstimated: number = null;
  spotQuantity: number = 0;
  spotQuantityUsed: number = 0;
  allocatedLoads: number = null;
  ordersSubmitted: boolean = null;
  numberOfLoads: number = null;
  mcqInitial: number = null;
}