import {SORespGrant} from '../../core/grant/so-resp-grant';
import {GrantCore} from './grant.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutGrantFormFields} from './put-grant-form-fields';
import {SOGetGrant} from '../../core/grant/so-get-grant';
import {SOGetToken} from '../../core/token/so-get-token';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEGrantAttributesBase {

    isDefault: DetailEntry
    tokens: DetailEntry
    role: DetailEntry
    user: DetailEntry
    userDisplayName: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespGrant,
        app: GasDirectApp,
        formFields: PutGrantFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.isDefault = new DetailEntry(
            'Is default',
            entity.isDefault,
            'isDefault',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.isDefault
        )
        this.isDefault.listHeadingIndex = 1;
        this.isDefault.listSubheadingIndex = 0;
        this.isDefault.tableIndex = 1;

        this.userDisplayName = new DetailEntry(
            'User display name',
            entity.userDisplayName,
            'userDisplayName',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const tokensCore = app.makeCore( 'token');
        tokensCore.soGet = SOGetToken.construct({
          roleGrant: new SOGetGrant(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tokensCore
        )
        tokensCore.title = heading ? heading : tokensCore.title
        tokensCore.listTitle = 'Tokens'

        tokensCore.configureForDisplay(parentEntityViewSpec)

        this.tokens = new DetailEntry(
          heading ? heading : tokensCore.listTitle,
          null,
          'tokens',
          BaseDetailType.embeddedList,
          tokensCore,
          EditableType.never
        )

        const roleCore = app.makeCore( 'role');
        roleCore.soGet = SOGetRole.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let roleCeProtocol;
        if (entity.role) {
            roleCeProtocol = createCEProtocol(
                roleCore.ceProtocol,
                parentEntityViewSpec,
                roleCore,
                entity.role,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleCore
        )
        roleCore.title = heading ? heading : roleCore.title
        roleCore.listTitle = 'Role'

        if (entity.role) {
            displayStringFactory.reconfigure(
                entity.role,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        roleCore.configureForDisplay(parentEntityViewSpec)

        this.role = new DetailEntry(
          heading ? heading : roleCore.listTitle,
          roleCeProtocol ? roleCeProtocol.entityListView.fullHeading : null,
          'role',
          BaseDetailType.pushedDetail,
          roleCore,
          EditableType.never
        )
        if (entity.role) {
          this.role.ceProtocol = roleCeProtocol;
          this.role.cachedListEntry = roleCore.makeListEntry(parentEntityViewSpec, entity.role, 0)
          roleCore.selectedEntity = entity.role;
        }
        this.role.singleFieldSelect = true
        this.role.listHeadingIndex = 1;
        this.role.listSubheadingIndex = 0;
        this.role.tableIndex = 0;

        const userCore = app.makeCore( 'user');
        userCore.soGet = SOGetUser.construct({
        });
        userCore.soGet.queryOptions.sortKey = 'surname';
        let userCeProtocol;
        if (entity.user) {
            userCeProtocol = createCEProtocol(
                userCore.ceProtocol,
                parentEntityViewSpec,
                userCore,
                entity.user,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            userCore
        )
        userCore.title = heading ? heading : userCore.title
        userCore.listTitle = 'User'

        if (entity.user) {
            displayStringFactory.reconfigure(
                entity.user,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        userCore.configureForDisplay(parentEntityViewSpec)

        this.user = new DetailEntry(
          heading ? heading : userCore.listTitle,
          userCeProtocol ? userCeProtocol.entityListView.fullHeading : null,
          'user',
          BaseDetailType.pushedDetail,
          userCore,
          EditableType.never
        )
        if (entity.user) {
          this.user.ceProtocol = userCeProtocol;
          this.user.cachedListEntry = userCore.makeListEntry(parentEntityViewSpec, entity.user, 0)
          userCore.selectedEntity = entity.user;
        }
        this.user.singleFieldSelect = true
    }
}