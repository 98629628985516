import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutGrant } from './so-put-grant';
import { SOGetGrant } from './so-get-grant';
import { SOPostGrant } from './so-post-grant';
import { SODeleteGrant } from './so-delete-grant';
import {SORespGrant} from './so-resp-grant';
import {SOPluralGrant} from './so-plural-grant';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOGrantService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'grant',
      entityCore
    );
  }

  /** GET Grant by id. Will 404 if id not found */
  get(request: SOGetGrant): Observable<SOPluralGrant> {
    return this.httpClient.get<SOPluralGrant>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralGrant, resp) }),
      tap(_ => this.log(`fetched Grant`)),
      catchError(this.handleError<SOPluralGrant>(`get Grant`))
    );
  }

  /** POST: add a new Grant to the server */
  post(request: SOPostGrant): Observable<SORespGrant> {
    return this.httpClient.post<SORespGrant>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespGrant, resp) }),
      tap((response: SORespGrant) => this.log(`added Grant w/ id=${response.uid}`)),
      catchError(this.handleError<SORespGrant>('Grant post'))
    );
  }

  /** PUT: update Grant  */
  put(request: SOPutGrant): Observable<SORespGrant> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespGrant>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespGrant, resp) }),
      tap((response: SORespGrant) => this.log(`edited Grant w/ uid=${uid}`)),
      catchError(this.handleError<SORespGrant>('Grant put'))
    );
  }

  /** DELETE: delete the Grant from the server */
  delete(request: SODeleteGrant): Observable<SORespGrant> {
    return this.httpClient.delete<SORespGrant>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Grant uid=${request.uid}`)),
      catchError(this.handleError<SORespGrant>('delete Grant'))
    );
  }
}
