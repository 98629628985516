import {SOPostDelta} from '../../core/delta/so-post-delta';
import {DeltaCoreBase} from './delta.core.base';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {FormField, SelectionOption} from 'lionheartland';
import {postDeltaFormFieldOptions} from './post-delta-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {ContainersessionCore} from '../containersession/containersession.core';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';

export class RAPostDelta extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postDeltaFormFieldOptions);

  RequestClass = SOPostDelta

  constructor(
    public entityCore: DeltaCoreBase
  ) {
    super();
    if (postDeltaFormFieldOptions.containersession) {
      postDeltaFormFieldOptions.containersession.makeCore = true;
    }
    if (postDeltaFormFieldOptions.load) {
      postDeltaFormFieldOptions.load.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('billable'),
      this.getFormValueFromIdentifier(
        'containersession',
        SOGetContainersession,
      ),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}