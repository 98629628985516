import {SORespInvoice} from '../../core/invoice/so-resp-invoice';
import {InvoiceCore} from './invoice.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutInvoiceFormFields} from './put-invoice-form-fields';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetInvoiceitem} from '../../core/invoiceitem/so-get-invoiceitem';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEInvoiceAttributesBase {

    transactions: DetailEntry
    loadfees: DetailEntry
    deltas: DetailEntry
    dateCreated: DetailEntry
    ref: DetailEntry
    total: DetailEntry
    distributionmonth: DetailEntry
    account: DetailEntry
    state: DetailEntry
    invoiceDate: DetailEntry
    sentDate: DetailEntry
    paymentTerms: DetailEntry
    productionDate: DetailEntry
    contractNumbers: DetailEntry
    remittorNumber: DetailEntry
    additionalText: DetailEntry
    sent: DetailEntry
    paymentStatus: DetailEntry
    stripeRef: DetailEntry
    stripeStatus: DetailEntry
    customerPocText: DetailEntry
    xomPaymentInstructions: DetailEntry
    xomPocText: DetailEntry
    balanceInitial: DetailEntry
    balanceFinal: DetailEntry
    paymentsReceivedSinceLastStatement: DetailEntry
    invoiceitems: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespInvoice,
        app: GasDirectApp,
        formFields: PutInvoiceFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.ref = new DetailEntry(
            'Ref',
            entity.ref,
            'ref',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.total = new DetailEntry(
            'Total',
            entity.total,
            'total',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.invoiceDate = new DetailEntry(
            'Invoice date',
            app.activeUserService.time.dateAsString(entity.invoiceDate),
            'invoiceDate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.invoiceDate
        )

        this.sentDate = new DetailEntry(
            'Sent date',
            app.activeUserService.time.dateAsString(entity.sentDate),
            'sentDate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.sentDate
        )

        this.paymentTerms = new DetailEntry(
            'Payment terms',
            entity.paymentTerms,
            'paymentTerms',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.productionDate = new DetailEntry(
            'Production date',
            entity.productionDate,
            'productionDate',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.contractNumbers = new DetailEntry(
            'Contract numbers',
            entity.contractNumbers,
            'contractNumbers',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.remittorNumber = new DetailEntry(
            'Remittor number',
            entity.remittorNumber,
            'remittorNumber',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.additionalText = new DetailEntry(
            'Additional text',
            entity.additionalText,
            'additionalText',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.sent = new DetailEntry(
            'Sent',
            entity.sent,
            'sent',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.sent
        )

        this.paymentStatus = new DetailEntry(
            'Payment status',
            entity.paymentStatus,
            'paymentStatus',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.stripeRef = new DetailEntry(
            'Stripe ref',
            entity.stripeRef,
            'stripeRef',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.stripeStatus = new DetailEntry(
            'Stripe status',
            entity.stripeStatus,
            'stripeStatus',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.customerPocText = new DetailEntry(
            'Customer poc text',
            entity.customerPocText,
            'customerPocText',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.xomPaymentInstructions = new DetailEntry(
            'Xom payment instructions',
            entity.xomPaymentInstructions,
            'xomPaymentInstructions',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.xomPocText = new DetailEntry(
            'Xom poc text',
            entity.xomPocText,
            'xomPocText',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.balanceInitial = new DetailEntry(
            'Balance initial',
            entity.balanceInitial,
            'balanceInitial',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.balanceFinal = new DetailEntry(
            'Balance final',
            entity.balanceFinal,
            'balanceFinal',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.paymentsReceivedSinceLastStatement = new DetailEntry(
            'Payments received since last statement',
            entity.paymentsReceivedSinceLastStatement,
            'paymentsReceivedSinceLastStatement',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const transactionsCore = app.makeCore( 'transaction');
        transactionsCore.soGet = SOGetTransaction.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          invoice: new SOGetInvoice(entity.uid),
        });
        transactionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        transactionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            transactionsCore
        )
        transactionsCore.title = heading ? heading : transactionsCore.title
        transactionsCore.listTitle = 'Transactions'

        transactionsCore.configureForDisplay(parentEntityViewSpec)

        this.transactions = new DetailEntry(
          heading ? heading : transactionsCore.listTitle,
          null,
          'transactions',
          BaseDetailType.embeddedList,
          transactionsCore,
          EditableType.never
        )

        const loadfeesCore = app.makeCore( 'loadfee');
        loadfeesCore.soGet = SOGetLoadfee.construct({
          invoice: new SOGetInvoice(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadfeesCore
        )
        loadfeesCore.title = heading ? heading : loadfeesCore.title
        loadfeesCore.listTitle = 'Loadfees'

        loadfeesCore.configureForDisplay(parentEntityViewSpec)

        this.loadfees = new DetailEntry(
          heading ? heading : loadfeesCore.listTitle,
          null,
          'loadfees',
          BaseDetailType.embeddedList,
          loadfeesCore,
          EditableType.never
        )

        const deltasCore = app.makeCore( 'delta');
        deltasCore.soGet = SOGetDelta.construct({
          invoice: new SOGetInvoice(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deltasCore
        )
        deltasCore.title = heading ? heading : deltasCore.title
        deltasCore.listTitle = 'Deltas'

        deltasCore.configureForDisplay(parentEntityViewSpec)

        this.deltas = new DetailEntry(
          heading ? heading : deltasCore.listTitle,
          null,
          'deltas',
          BaseDetailType.embeddedList,
          deltasCore,
          EditableType.never
        )

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.never
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const invoiceitemsCore = app.makeCore( 'invoiceitem');
        invoiceitemsCore.soGet = SOGetInvoiceitem.construct({
          invoice: new SOGetInvoice(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoiceitemsCore
        )
        invoiceitemsCore.title = heading ? heading : invoiceitemsCore.title
        invoiceitemsCore.listTitle = 'Invoiceitems'

        invoiceitemsCore.configureForDisplay(parentEntityViewSpec)

        this.invoiceitems = new DetailEntry(
          heading ? heading : invoiceitemsCore.listTitle,
          null,
          'invoiceitems',
          BaseDetailType.embeddedList,
          invoiceitemsCore,
          EditableType.never
        )
    }
}