import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutProviderFormFields {

    public organization: FormField

    constructor() {

        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutProviderFormFields()

export const putProviderFormFieldOptions: {[key: string]: FormField} = {
  organization: fields.organization,
};