import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostTrailer {

  constructor(
    public identifier: string,
    public type: string,
    public size: number,
    public chassisId: string = null,
    public dotId: string = null,
    public dotInspectionDate: Date = null,
    public dotE: string = null,
    public dotSp: string = null,
    public mawp: string = null,
    public organization: SOGetOrganization = null,
    public requestingAccount: string = null,
    public fillVolume: number = null,
    public mawt: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTrailer(
      'chassisId' in params ? params.chassisId : null,
      'identifier' in params ? params.identifier : null,
      'type' in params ? params.type : null,
      'size' in params ? params.size : null,
      'dotId' in params ? params.dotId : null,
      'dotInspectionDate' in params ? params.dotInspectionDate : null,
      'dotE' in params ? params.dotE : null,
      'dotSp' in params ? params.dotSp : null,
      'mawp' in params ? params.mawp : null,
      'organization' in params ? params.organization : null,
      'requestingAccount' in params ? params.requestingAccount : null,
      'fillVolume' in params ? params.fillVolume : null,
      'mawt' in params ? params.mawt : null,
    );
  }
}
