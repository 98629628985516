import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTransactionFormFields {

    public dateCreated: FormField
    public description: FormField
    public amount: FormField
    public invoice: FormField

    constructor() {

        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          false,
        )
        this.invoice = new FormField(
          'invoice',
          'Invoice',
          'invoice',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutTransactionFormFields()

export const putTransactionFormFieldOptions: {[key: string]: FormField} = {
  dateCreated: fields.dateCreated,
  description: fields.description,
  amount: fields.amount,
  invoice: fields.invoice,
};