import {DEFAULT_ROUTES} from "./app-default-routes";
import {
    AuthGuard,
} from "lionheartland";
import {SlotCore} from "./entities/slot/slot.core";
import {GasDirectNavigatorComponent} from "./custom-structure/gas-direct-navigator/gas-direct-navigator.component";
import {ContainersessionCore} from "./entities/containersession/containersession.core";
import {SlotCalendarCore} from "./entities/slot/slot-calendar.core";
import {BoardCore} from "./entities/load/board.core";
import {StatusCore} from "./entities/load/status.core";

export const ALL_ROUTES = [];

for (const route of DEFAULT_ROUTES) {

  ALL_ROUTES.push(
    route
  )

    // if (route.path && [
    //     'slot',
    //     'slot/:entityUid'
    // ].includes(route.path)) {
    //     ALL_ROUTES.push(
    //         {
    //             path: route.path,
    //             component: GasDirectNavigatorComponent,
    //             canActivate: [AuthGuard],
    //             data: {CoreClass: SlotCore, identifier: 'slot'}
    //         }
    //     )
    //     console.log(route.path)
    // } else if (route.path && [
    //     'containersession',
    //     'containersession/:entityUid',
    // ].includes(route.path)) {
    //     ALL_ROUTES.push(
    //         {
    //             path: route.path,
    //             component: GasDirectNavigatorComponent,
    //             canActivate: [AuthGuard],
    //             data: {CoreClass: ContainersessionCore, identifier: 'containersession'}
    //         }
    //     )
    //     console.log(route.path)
    // } else {
    //     ALL_ROUTES.push(
    //         route
    //     )
    // }
}

ALL_ROUTES.push(
  {path: 'board', component: GasDirectNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BoardCore, identifier: 'board'}},
  {path: 'status', component: GasDirectNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StatusCore, identifier: 'status'}},
  {path: 'slot-calendar', component: GasDirectNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SlotCalendarCore, identifier: 'slot-calendar'}},
    // {path: 'shop', component: AvRouteComponent, canActivate: [AuthGuard], data: {CoreClass: ShopCore, }},
    // {path: 'shopping-cart', component: CvShoppingCartComponent, canActivate: [AuthGuard]},
    // {path: 'load-history', component: AvRouteComponent, canActivate: [AuthGuard], data: {CoreClass: LoadHistoryCore}},
    // {path: 'load-operator', component: AvRouteComponent, canActivate: [AuthGuard], data: {CoreClass: OperatorCore}},
    // {path: 'shopmgmt', component: AvRouteComponent, canActivate: [AuthGuard], data: {CoreClass: ShopmgmtCore}},
    // {path: 'current-nightly', component: AvRouteComponent, canActivate: [AuthGuard], data: {CoreClass: NightlyweightshortcutCore}},
    // {path: 'current-daily', component: AvRouteComponent, canActivate: [AuthGuard], data: {CoreClass: DailycheckshortcutCore}},
    // {path: 'lobby', component: CvWelcomeComponent, canActivate: [AuthGuard]},
    // {path: 'more', component: CvMoreComponent, canActivate: [AuthGuard]},
)
