import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDistributionday } from './so-put-distributionday';
import { SOGetDistributionday } from './so-get-distributionday';
import { SOPostDistributionday } from './so-post-distributionday';
import { SODeleteDistributionday } from './so-delete-distributionday';
import {SORespDistributionday} from './so-resp-distributionday';
import {SOPluralDistributionday} from './so-plural-distributionday';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODistributiondayService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'distributionday',
      entityCore
    );
  }

  /** GET Distributionday by id. Will 404 if id not found */
  get(request: SOGetDistributionday): Observable<SOPluralDistributionday> {
    return this.httpClient.get<SOPluralDistributionday>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDistributionday, resp) }),
      tap(_ => this.log(`fetched Distributionday`)),
      catchError(this.handleError<SOPluralDistributionday>(`get Distributionday`))
    );
  }

  /** POST: add a new Distributionday to the server */
  post(request: SOPostDistributionday): Observable<SORespDistributionday> {
    return this.httpClient.post<SORespDistributionday>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistributionday, resp) }),
      tap((response: SORespDistributionday) => this.log(`added Distributionday w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDistributionday>('Distributionday post'))
    );
  }

  /** PUT: update Distributionday  */
  put(request: SOPutDistributionday): Observable<SORespDistributionday> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDistributionday>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistributionday, resp) }),
      tap((response: SORespDistributionday) => this.log(`edited Distributionday w/ uid=${uid}`)),
      catchError(this.handleError<SORespDistributionday>('Distributionday put'))
    );
  }

  /** DELETE: delete the Distributionday from the server */
  delete(request: SODeleteDistributionday): Observable<SORespDistributionday> {
    return this.httpClient.delete<SORespDistributionday>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Distributionday uid=${request.uid}`)),
      catchError(this.handleError<SORespDistributionday>('delete Distributionday'))
    );
  }
}
