import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTagFormFields {

    public name: FormField
    public identifier: FormField
    public dcs: FormField
    public linags: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          false,
        )
        this.dcs = new FormField(
          'dcs',
          'Dcs',
          'dcs',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.linags = new FormField(
          'linags',
          'Linags',
          'linag',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutTagFormFields()

export const putTagFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  identifier: fields.identifier,
  dcs: fields.dcs,
  linags: fields.linags,
};