import {SOPutLoad} from '../../core/load/so-put-load';
import {LoadCoreBase} from './load.core.base';
import {SOGetLoad} from '../../core/load/so-get-load';
import {FormField, SelectionOption} from 'lionheartland';
import {putLoadFormFieldOptions} from './put-load-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {OrderCore} from '../order/order.core';
import {SOGetOrder} from '../../core/order/so-get-order';
import {TrailerCore} from '../trailer/trailer.core';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {OfferingCore} from '../offering/offering.core';
import {SOGetOffering} from '../../core/offering/so-get-offering';
import {AgreemonthCore} from '../agreemonth/agreemonth.core';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {AgreementCore} from '../agreement/agreement.core';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';

export class RAPutLoad extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putLoadFormFieldOptions);

  RequestClass = SOPutLoad

  constructor(
    public entityCore: LoadCoreBase
  ) {
    super();
    if (putLoadFormFieldOptions.order) {
      putLoadFormFieldOptions.order.makeCore = true;
    }
    if (putLoadFormFieldOptions.trailer) {
      putLoadFormFieldOptions.trailer.makeCore = true;
    }
    if (putLoadFormFieldOptions.carrier) {
      putLoadFormFieldOptions.carrier.makeCore = true;
    }
    if (putLoadFormFieldOptions.offering) {
      putLoadFormFieldOptions.offering.makeCore = true;
    }
    if (putLoadFormFieldOptions.agreemonth) {
      putLoadFormFieldOptions.agreemonth.makeCore = true;
    }
    if (putLoadFormFieldOptions.agreement) {
      putLoadFormFieldOptions.agreement.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('action'),
      this.getFormValueFromIdentifier('weightRequested'),
      this.getFormValueFromIdentifier('destination'),
      this.getFormValueFromIdentifier('carrierSignature'),
      this.getFormValueFromIdentifier('providerSignature'),
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier('net'),
      this.getFormValueFromIdentifier('netDcs'),
      this.getFormValueFromIdentifier('lngNetTargetSetpoint'),
      this.getFormValueFromIdentifier('lngNetTargetSetpointManual'),
      this.getFormValueFromIdentifier('arrived'),
      this.getFormValueFromIdentifier('departed'),
      this.getFormValueFromIdentifier('loadingBegin'),
      this.getFormValueFromIdentifier('loadingEnd'),
      this.getFormValueFromIdentifier('loadCode'),
      this.getFormValueFromIdentifier('location'),
      this.getFormValueFromIdentifier('specialRequests'),
      this.getFormValueFromIdentifier(
        'order',
        SOGetOrder,
      ),
      this.getFormValueFromIdentifier(
        'trailer',
        SOGetTrailer,
      ),
      this.getFormValueFromIdentifier(
        'carrier',
        SOGetOrganization,
      ),
      this.getFormValueFromIdentifier(
        'offering',
        SOGetOffering,
      ),
      this.getFormValueFromIdentifier('driverName'),
      this.getFormValueFromIdentifier('operatorName'),
      this.getFormValueFromIdentifier('eta'),
      this.getFormValueFromIdentifier('requestedFillDate'),
      this.getFormValueFromIdentifier('coolDownDays'),
      this.getFormValueFromIdentifier('rearAxelWeight'),
      this.getFormValueFromIdentifier('droppedLin'),
      this.getFormValueFromIdentifier('finalAnalysis'),
      this.getFormValueFromIdentifier('waterAnalysis'),
      this.getFormValueFromIdentifier('prepVol'),
      this.getFormValueFromIdentifier('prepTrailer'),
      this.getFormValueFromIdentifier('nitrogenDetected'),
      this.getFormValueFromIdentifier('weight0'),
      this.getFormValueFromIdentifier('weight0Manual'),
      this.getFormValueFromIdentifier('weight1'),
      this.getFormValueFromIdentifier('weight1Manual'),
      this.getFormValueFromIdentifier('weight2'),
      this.getFormValueFromIdentifier('weight2Manual'),
      this.getFormValueFromIdentifier('pressure0'),
      this.getFormValueFromIdentifier('pressure1'),
      this.getFormValueFromIdentifier('process'),
      this.getFormValueFromIdentifier('chassisId'),
      this.getFormValueFromIdentifier('dotInspectionDate'),
      this.getFormValueFromIdentifier('dotSp'),
      this.getFormValueFromIdentifier('dotE'),
      this.getFormValueFromIdentifier('setOrderAccountUid'),
      this.getFormValueFromIdentifier('attachment'),
      this.getFormValueFromIdentifier(
        'agreemonth',
        SOGetAgreemonth,
      ),
      this.getFormValueFromIdentifier(
        'agreement',
        SOGetAgreement,
      ),
      this.getFormValueFromIdentifier('shield'),
      this.getFormValueFromIdentifier('loadSheetId'),
      this.getFormValueFromIdentifier('holdRelease'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}