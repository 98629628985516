import {SORespShutelng} from '../../core/shutelng/so-resp-shutelng';
import {ShutelngCore} from './shutelng.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutShutelngFormFields} from './put-shutelng-form-fields';
import {SOGetShutelng} from '../../core/shutelng/so-get-shutelng';


export class CEShutelngAttributesBase {

    data: DetailEntry
    dateCreated: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespShutelng,
        app: GasDirectApp,
        formFields: PutShutelngFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.data = new DetailEntry(
            'Data',
            entity.data,
            'data',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.data
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateCreated
        )

    }
}