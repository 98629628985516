import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostHmonthFormFields {

    public state: FormField
    public mcq: FormField
    public mcqUsed: FormField
    public month: FormField
    public year: FormField
    public account: FormField
    public hyear: FormField
    public spotQuantity: FormField
    public distributionmonth: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          true,
        )
        this.mcq = new FormField(
          'mcq',
          'Mcq',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.mcqUsed = new FormField(
          'mcqUsed',
          'Mcq used',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.month = new FormField(
          'month',
          'Month',
          null,
          FormFieldType.text,
          true,
        )
        this.year = new FormField(
          'year',
          'Year',
          null,
          FormFieldType.text,
          true,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.hyear = new FormField(
          'hyear',
          'Hyear',
          'hyear',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.spotQuantity = new FormField(
          'spotQuantity',
          'Spot quantity',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.distributionmonth = new FormField(
          'distributionmonth',
          'Distributionmonth',
          'distributionmonth',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostHmonthFormFields()

export const postHmonthFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  mcq: fields.mcq,
  mcqUsed: fields.mcqUsed,
  month: fields.month,
  year: fields.year,
  hyear: fields.hyear,
  spotQuantity: fields.spotQuantity,
  distributionmonth: fields.distributionmonth,
};