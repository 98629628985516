import { SOGetContract } from '../contract/so-get-contract';
import { SOGetTempclin } from '../tempclin/so-get-tempclin';
import { SOGetSlot } from '../slot/so-get-slot';


export class SOPostClin {

  constructor(
    public name: string,
    public state: string,
    public type: string,
    public start: Date,
    public end: Date,
    public contract: SOGetContract,
    public description: string = "",
    public tempclin: SOGetTempclin = null,
    public slot: SOGetSlot = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostClin(
      'name' in params ? params.name : null,
      'state' in params ? params.state : null,
      'type' in params ? params.type : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'description' in params ? params.description : null,
      'contract' in params ? params.contract : null,
      'tempclin' in params ? params.tempclin : null,
      'slot' in params ? params.slot : null,
    );
  }
}
