import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTempclin } from './so-put-tempclin';
import { SOGetTempclin } from './so-get-tempclin';
import { SOPostTempclin } from './so-post-tempclin';
import { SODeleteTempclin } from './so-delete-tempclin';
import {SORespTempclin} from './so-resp-tempclin';
import {SOPluralTempclin} from './so-plural-tempclin';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTempclinService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'tempclin',
      entityCore
    );
  }

  /** GET Tempclin by id. Will 404 if id not found */
  get(request: SOGetTempclin): Observable<SOPluralTempclin> {
    return this.httpClient.get<SOPluralTempclin>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTempclin, resp) }),
      tap(_ => this.log(`fetched Tempclin`)),
      catchError(this.handleError<SOPluralTempclin>(`get Tempclin`))
    );
  }

  /** POST: add a new Tempclin to the server */
  post(request: SOPostTempclin): Observable<SORespTempclin> {
    return this.httpClient.post<SORespTempclin>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTempclin, resp) }),
      tap((response: SORespTempclin) => this.log(`added Tempclin w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTempclin>('Tempclin post'))
    );
  }

  /** PUT: update Tempclin  */
  put(request: SOPutTempclin): Observable<SORespTempclin> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTempclin>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTempclin, resp) }),
      tap((response: SORespTempclin) => this.log(`edited Tempclin w/ uid=${uid}`)),
      catchError(this.handleError<SORespTempclin>('Tempclin put'))
    );
  }

  /** DELETE: delete the Tempclin from the server */
  delete(request: SODeleteTempclin): Observable<SORespTempclin> {
    return this.httpClient.delete<SORespTempclin>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Tempclin uid=${request.uid}`)),
      catchError(this.handleError<SORespTempclin>('delete Tempclin'))
    );
  }
}
