import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutUserFormFields {

    public givenName: FormField
    public surname: FormField
    public organization: FormField
    public unread: FormField
    public active: FormField

    constructor() {

        this.givenName = new FormField(
          'givenName',
          'Given name',
          null,
          FormFieldType.text,
          false,
        )
        this.surname = new FormField(
          'surname',
          'Surname',
          null,
          FormFieldType.text,
          false,
        )
        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.unread = new FormField(
          'unread',
          'Unread',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.active = new FormField(
          'active',
          'Active',
          null,
          FormFieldType.boolean,
          false,
        )

    }
}

const fields = new PutUserFormFields()

export const putUserFormFieldOptions: {[key: string]: FormField} = {
  givenName: fields.givenName,
  surname: fields.surname,
  organization: fields.organization,
  unread: fields.unread,
  active: fields.active,
};