import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutInreportFormFields {

    public accounreport: FormField
    public load: FormField

    constructor() {

        this.accounreport = new FormField(
          'accounreport',
          'Accounreport',
          'accountreport',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )

    }
}

const fields = new PutInreportFormFields()

export const putInreportFormFieldOptions: {[key: string]: FormField} = {
  accounreport: fields.accounreport,
  load: fields.load,
};