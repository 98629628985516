import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOIndexpriceService} from '../../core/indexprice/eo-indexprice';
import {SOGetIndexprice} from '../../core/indexprice/so-get-indexprice';
import {RAPutIndexprice} from './ra-put-indexprice';
import {RAPostIndexprice} from './ra-post-indexprice';
import {RADeleteIndexprice} from './ra-delete-indexprice';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution'
import { CEIndexprice } from "./ce-indexprice"


export class IndexpriceCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'indexprice';
  title = 'Indexprice';
  ceProtocol: CEConstructor = CEIndexprice

  entityService: EOIndexpriceService;
  soGetClass = SOGetIndexprice;
  soGet: SOGetIndexprice;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOIndexpriceService
    );
    this.adapters = [
      new RAPostIndexprice(this),
      new RAPutIndexprice(this),
      new RADeleteIndexprice(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetIndexprice();
    if (this.activeUserService.getScope('distribution')) {
        this.soGet.distribution = new SOGetDistribution(
          this.activeUserService.getScope('distribution').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}