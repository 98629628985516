import {SORespUser} from '../../core/user/so-resp-user';
import {UserCore} from './user.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutUserFormFields} from './put-user-form-fields';
import { CEUserAttributes } from './ce-user-attributes'


export class CEUserBase extends CEBase {

    public details: CEUserAttributes;
    public fromFields: PutUserFormFields = new PutUserFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: UserCore,
        public entity: SORespUser,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEUserAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.username)
        this.attributes.push(this.details.givenName)
        this.attributes.push(this.details.surname)
        this.attributes.push(this.details.companyName)
        this.attributes.push(this.details.isExxon)
        this.attributes.push(this.details.unread)
        this.attributes.push(this.details.active)
        this.attributes.push(this.details.organization)
        this.attributes.push(this.details.grants)
        this.attributes.push(this.details.tokens)
        this.attributes.push(this.details.subscriptions)
        this.attributes.push(this.details.activitys)
        this.attributes.push(this.details.featureascs)
        this.attributes.push(this.details.messages)

    }
}