import {AccountCore} from './entities/account/account.core';
import {AccountreportCore} from './entities/accountreport/accountreport.core';
import {ActivityCore} from './entities/activity/activity.core';
import {AdjustmentCore} from './entities/adjustment/adjustment.core';
import {AgreementCore} from './entities/agreement/agreement.core';
import {AgreemonthCore} from './entities/agreemonth/agreemonth.core';
import {AgreeyearCore} from './entities/agreeyear/agreeyear.core';
import {AwardCore} from './entities/award/award.core';
import {BacklogitemCore} from './entities/backlogitem/backlogitem.core';
import {BaysessionCore} from './entities/baysession/baysession.core';
import {BidCore} from './entities/bid/bid.core';
import {BolCore} from './entities/bol/bol.core';
import {BulkCore} from './entities/bulk/bulk.core';
import {CalendarCore} from './entities/calendar/calendar.core';
import {CarrierCore} from './entities/carrier/carrier.core';
import {CarrierauthCore} from './entities/carrierauth/carrierauth.core';
import {ChecklistCore} from './entities/checklist/checklist.core';
import {ChecklistitemCore} from './entities/checklistitem/checklistitem.core';
import {ChronreportCore} from './entities/chronreport/chronreport.core';
import {ClinCore} from './entities/clin/clin.core';
import {ClinyearlngCore} from './entities/clinyearlng/clinyearlng.core';
import {ContainersessionCore} from './entities/containersession/containersession.core';
import {ContractCore} from './entities/contract/contract.core';
import {CustomerCore} from './entities/customer/customer.core';
import {DcsCore} from './entities/dcs/dcs.core';
import {DeltaCore} from './entities/delta/delta.core';
import {DeltaweightCore} from './entities/deltaweight/deltaweight.core';
import {DistofferCore} from './entities/distoffer/distoffer.core';
import {DistributionCore} from './entities/distribution/distribution.core';
import {DistributiondayCore} from './entities/distributionday/distributionday.core';
import {DistributionmonthCore} from './entities/distributionmonth/distributionmonth.core';
import {DistributionreportCore} from './entities/distributionreport/distributionreport.core';
import {DistributionyearCore} from './entities/distributionyear/distributionyear.core';
import {DisttempagreementCore} from './entities/disttempagreement/disttempagreement.core';
import {DowntimeCore} from './entities/downtime/downtime.core';
import {EventCore} from './entities/event/event.core';
import {ExternalindexpriceCore} from './entities/externalindexprice/externalindexprice.core';
import {FaqCore} from './entities/faq/faq.core';
import {FeatureCore} from './entities/feature/feature.core';
import {FeatureascCore} from './entities/featureasc/featureasc.core';
import {FeeCore} from './entities/fee/fee.core';
import {FeescheduleCore} from './entities/feeschedule/feeschedule.core';
import {FeescheduleagreementascCore} from './entities/feescheduleagreementasc/feescheduleagreementasc.core';
import {GrantCore} from './entities/grant/grant.core';
import {HelloCore} from './entities/hello/hello.core';
import {HemonCore} from './entities/hemon/hemon.core';
import {HestatusCore} from './entities/hestatus/hestatus.core';
import {HestatusitemCore} from './entities/hestatusitem/hestatusitem.core';
import {HmonthCore} from './entities/hmonth/hmonth.core';
import {HyearCore} from './entities/hyear/hyear.core';
import {ImpurityCore} from './entities/impurity/impurity.core';
import {IndexpriceCore} from './entities/indexprice/indexprice.core';
import {InquiryCore} from './entities/inquiry/inquiry.core';
import {InreportCore} from './entities/inreport/inreport.core';
import {InvoiceCore} from './entities/invoice/invoice.core';
import {InvoiceitemCore} from './entities/invoiceitem/invoiceitem.core';
import {ItemCore} from './entities/item/item.core';
import {LinagCore} from './entities/linag/linag.core';
import {LineCore} from './entities/line/line.core';
import {LngloadCore} from './entities/lngload/lngload.core';
import {LoadCore} from './entities/load/load.core';
import {LoadfeeCore} from './entities/loadfee/loadfee.core';
import {MessageCore} from './entities/message/message.core';
import {MonthcloseCore} from './entities/monthclose/monthclose.core';
import {NaviCore} from './entities/navi/navi.core';
import {NavroaCore} from './entities/navroa/navroa.core';
import {NavtemrolaCore} from './entities/navtemrola/navtemrola.core';
import {OfferingCore} from './entities/offering/offering.core';
import {OrderCore} from './entities/order/order.core';
import {OrganizationCore} from './entities/organization/organization.core';
import {OrgrolaCore} from './entities/orgrola/orgrola.core';
import {PlantCore} from './entities/plant/plant.core';
import {PriceCore} from './entities/price/price.core';
import {ProductCore} from './entities/product/product.core';
import {ProductionCore} from './entities/production/production.core';
import {ProductionreportCore} from './entities/productionreport/productionreport.core';
import {ProviderCore} from './entities/provider/provider.core';
import {PublicationCore} from './entities/publication/publication.core';
import {QuoteletCore} from './entities/quotelet/quotelet.core';
import {RateableagreeyearallocationCore} from './entities/rateableagreeyearallocation/rateableagreeyearallocation.core';
import {ReleaseCore} from './entities/release/release.core';
import {ReservationCore} from './entities/reservation/reservation.core';
import {RoleCore} from './entities/role/role.core';
import {SaleCore} from './entities/sale/sale.core';
import {SaleaccountascCore} from './entities/saleaccountasc/saleaccountasc.core';
import {ServiceRequestCore} from './entities/service-request/service-request.core';
import {ShutelngCore} from './entities/shutelng/shutelng.core';
import {SitesessionCore} from './entities/sitesession/sitesession.core';
import {SlotCore} from './entities/slot/slot.core';
import {SlotletCore} from './entities/slotlet/slotlet.core';
import {SlotoptionCore} from './entities/slotoption/slotoption.core';
import {SubscriptionCore} from './entities/subscription/subscription.core';
import {TagCore} from './entities/tag/tag.core';
import {TagreportCore} from './entities/tagreport/tagreport.core';
import {TankCore} from './entities/tank/tank.core';
import {TankreportCore} from './entities/tankreport/tankreport.core';
import {TaskCore} from './entities/task/task.core';
import {TaskreportCore} from './entities/taskreport/taskreport.core';
import {TempagreementCore} from './entities/tempagreement/tempagreement.core';
import {TempagreetractCore} from './entities/tempagreetract/tempagreetract.core';
import {TempclinCore} from './entities/tempclin/tempclin.core';
import {TempriceCore} from './entities/temprice/temprice.core';
import {TemproleCore} from './entities/temprole/temprole.core';
import {TemptraclinCore} from './entities/temptraclin/temptraclin.core';
import {TemptractCore} from './entities/temptract/temptract.core';
import {TokenCore} from './entities/token/token.core';
import {TrailerCore} from './entities/trailer/trailer.core';
import {TrailerauthCore} from './entities/trailerauth/trailerauth.core';
import {TrailermergeCore} from './entities/trailermerge/trailermerge.core';
import {TransactionCore} from './entities/transaction/transaction.core';
import {UserCore} from './entities/user/user.core';
import {WeightCore} from './entities/weight/weight.core';
import {WonderwareCore} from './entities/wonderware/wonderware.core';


export const entityCoreMap = {
  account: AccountCore,
  accountreport: AccountreportCore,
  activity: ActivityCore,
  adjustment: AdjustmentCore,
  agreement: AgreementCore,
  agreemonth: AgreemonthCore,
  agreeyear: AgreeyearCore,
  award: AwardCore,
  backlogitem: BacklogitemCore,
  baysession: BaysessionCore,
  bid: BidCore,
  bol: BolCore,
  bulk: BulkCore,
  calendar: CalendarCore,
  carrier: CarrierCore,
  carrierauth: CarrierauthCore,
  checklist: ChecklistCore,
  checklistitem: ChecklistitemCore,
  chronreport: ChronreportCore,
  clin: ClinCore,
  clinyearlng: ClinyearlngCore,
  containersession: ContainersessionCore,
  contract: ContractCore,
  customer: CustomerCore,
  dcs: DcsCore,
  delta: DeltaCore,
  deltaweight: DeltaweightCore,
  distoffer: DistofferCore,
  distribution: DistributionCore,
  distributionday: DistributiondayCore,
  distributionmonth: DistributionmonthCore,
  distributionreport: DistributionreportCore,
  distributionyear: DistributionyearCore,
  disttempagreement: DisttempagreementCore,
  downtime: DowntimeCore,
  event: EventCore,
  externalindexprice: ExternalindexpriceCore,
  faq: FaqCore,
  feature: FeatureCore,
  featureasc: FeatureascCore,
  fee: FeeCore,
  feeschedule: FeescheduleCore,
  feescheduleagreementasc: FeescheduleagreementascCore,
  grant: GrantCore,
  hello: HelloCore,
  hemon: HemonCore,
  hestatus: HestatusCore,
  hestatusitem: HestatusitemCore,
  hmonth: HmonthCore,
  hyear: HyearCore,
  impurity: ImpurityCore,
  indexprice: IndexpriceCore,
  inquiry: InquiryCore,
  inreport: InreportCore,
  invoice: InvoiceCore,
  invoiceitem: InvoiceitemCore,
  item: ItemCore,
  linag: LinagCore,
  line: LineCore,
  lngload: LngloadCore,
  load: LoadCore,
  loadfee: LoadfeeCore,
  message: MessageCore,
  monthclose: MonthcloseCore,
  navi: NaviCore,
  navroa: NavroaCore,
  navtemrola: NavtemrolaCore,
  offering: OfferingCore,
  order: OrderCore,
  organization: OrganizationCore,
  orgrola: OrgrolaCore,
  plant: PlantCore,
  price: PriceCore,
  product: ProductCore,
  production: ProductionCore,
  productionreport: ProductionreportCore,
  provider: ProviderCore,
  publication: PublicationCore,
  quotelet: QuoteletCore,
  rateableagreeyearallocation: RateableagreeyearallocationCore,
  release: ReleaseCore,
  reservation: ReservationCore,
  role: RoleCore,
  sale: SaleCore,
  saleaccountasc: SaleaccountascCore,
  serviceRequest: ServiceRequestCore,
  shutelng: ShutelngCore,
  sitesession: SitesessionCore,
  slot: SlotCore,
  slotlet: SlotletCore,
  slotoption: SlotoptionCore,
  subscription: SubscriptionCore,
  tag: TagCore,
  tagreport: TagreportCore,
  tank: TankCore,
  tankreport: TankreportCore,
  task: TaskCore,
  taskreport: TaskreportCore,
  tempagreement: TempagreementCore,
  tempagreetract: TempagreetractCore,
  tempclin: TempclinCore,
  temprice: TempriceCore,
  temprole: TemproleCore,
  temptraclin: TemptraclinCore,
  temptract: TemptractCore,
  token: TokenCore,
  trailer: TrailerCore,
  trailerauth: TrailerauthCore,
  trailermerge: TrailermergeCore,
  transaction: TransactionCore,
  user: UserCore,
  weight: WeightCore,
  wonderware: WonderwareCore,
};

export const entityCoreNameMap = {
  AccountCore: AccountCore,
  AccountreportCore: AccountreportCore,
  ActivityCore: ActivityCore,
  AdjustmentCore: AdjustmentCore,
  AgreementCore: AgreementCore,
  AgreemonthCore: AgreemonthCore,
  AgreeyearCore: AgreeyearCore,
  AwardCore: AwardCore,
  BacklogitemCore: BacklogitemCore,
  BaysessionCore: BaysessionCore,
  BidCore: BidCore,
  BolCore: BolCore,
  BulkCore: BulkCore,
  CalendarCore: CalendarCore,
  CarrierCore: CarrierCore,
  CarrierauthCore: CarrierauthCore,
  ChecklistCore: ChecklistCore,
  ChecklistitemCore: ChecklistitemCore,
  ChronreportCore: ChronreportCore,
  ClinCore: ClinCore,
  ClinyearlngCore: ClinyearlngCore,
  ContainersessionCore: ContainersessionCore,
  ContractCore: ContractCore,
  CustomerCore: CustomerCore,
  DcsCore: DcsCore,
  DeltaCore: DeltaCore,
  DeltaweightCore: DeltaweightCore,
  DistofferCore: DistofferCore,
  DistributionCore: DistributionCore,
  DistributiondayCore: DistributiondayCore,
  DistributionmonthCore: DistributionmonthCore,
  DistributionreportCore: DistributionreportCore,
  DistributionyearCore: DistributionyearCore,
  DisttempagreementCore: DisttempagreementCore,
  DowntimeCore: DowntimeCore,
  EventCore: EventCore,
  ExternalindexpriceCore: ExternalindexpriceCore,
  FaqCore: FaqCore,
  FeatureCore: FeatureCore,
  FeatureascCore: FeatureascCore,
  FeeCore: FeeCore,
  FeescheduleCore: FeescheduleCore,
  FeescheduleagreementascCore: FeescheduleagreementascCore,
  GrantCore: GrantCore,
  HelloCore: HelloCore,
  HemonCore: HemonCore,
  HestatusCore: HestatusCore,
  HestatusitemCore: HestatusitemCore,
  HmonthCore: HmonthCore,
  HyearCore: HyearCore,
  ImpurityCore: ImpurityCore,
  IndexpriceCore: IndexpriceCore,
  InquiryCore: InquiryCore,
  InreportCore: InreportCore,
  InvoiceCore: InvoiceCore,
  InvoiceitemCore: InvoiceitemCore,
  ItemCore: ItemCore,
  LinagCore: LinagCore,
  LineCore: LineCore,
  LngloadCore: LngloadCore,
  LoadCore: LoadCore,
  LoadfeeCore: LoadfeeCore,
  MessageCore: MessageCore,
  MonthcloseCore: MonthcloseCore,
  NaviCore: NaviCore,
  NavroaCore: NavroaCore,
  NavtemrolaCore: NavtemrolaCore,
  OfferingCore: OfferingCore,
  OrderCore: OrderCore,
  OrganizationCore: OrganizationCore,
  OrgrolaCore: OrgrolaCore,
  PlantCore: PlantCore,
  PriceCore: PriceCore,
  ProductCore: ProductCore,
  ProductionCore: ProductionCore,
  ProductionreportCore: ProductionreportCore,
  ProviderCore: ProviderCore,
  PublicationCore: PublicationCore,
  QuoteletCore: QuoteletCore,
  RateableagreeyearallocationCore: RateableagreeyearallocationCore,
  ReleaseCore: ReleaseCore,
  ReservationCore: ReservationCore,
  RoleCore: RoleCore,
  SaleCore: SaleCore,
  SaleaccountascCore: SaleaccountascCore,
  ServiceRequestCore: ServiceRequestCore,
  ShutelngCore: ShutelngCore,
  SitesessionCore: SitesessionCore,
  SlotCore: SlotCore,
  SlotletCore: SlotletCore,
  SlotoptionCore: SlotoptionCore,
  SubscriptionCore: SubscriptionCore,
  TagCore: TagCore,
  TagreportCore: TagreportCore,
  TankCore: TankCore,
  TankreportCore: TankreportCore,
  TaskCore: TaskCore,
  TaskreportCore: TaskreportCore,
  TempagreementCore: TempagreementCore,
  TempagreetractCore: TempagreetractCore,
  TempclinCore: TempclinCore,
  TempriceCore: TempriceCore,
  TemproleCore: TemproleCore,
  TemptraclinCore: TemptraclinCore,
  TemptractCore: TemptractCore,
  TokenCore: TokenCore,
  TrailerCore: TrailerCore,
  TrailerauthCore: TrailerauthCore,
  TrailermergeCore: TrailermergeCore,
  TransactionCore: TransactionCore,
  UserCore: UserCore,
  WeightCore: WeightCore,
  WonderwareCore: WonderwareCore,
};