import {SOPostDeltaweight} from '../../core/deltaweight/so-post-deltaweight';
import {DeltaweightCoreBase} from './deltaweight.core.base';
import {SOGetDeltaweight} from '../../core/deltaweight/so-get-deltaweight';
import {FormField, SelectionOption} from 'lionheartland';
import {postDeltaweightFormFieldOptions} from './post-deltaweight-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DeltaCore} from '../delta/delta.core';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {WeightCore} from '../weight/weight.core';
import {SOGetWeight} from '../../core/weight/so-get-weight';

export class RAPostDeltaweight extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postDeltaweightFormFieldOptions);

  RequestClass = SOPostDeltaweight

  constructor(
    public entityCore: DeltaweightCoreBase
  ) {
    super();
    if (postDeltaweightFormFieldOptions.delta) {
      postDeltaweightFormFieldOptions.delta.makeCore = true;
    }
    if (postDeltaweightFormFieldOptions.weight) {
      postDeltaweightFormFieldOptions.weight.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('initial'),
      this.getFormValueFromIdentifier(
        'delta',
        SOGetDelta,
      ),
      this.getFormValueFromIdentifier(
        'weight',
        SOGetWeight,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}