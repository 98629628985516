import {SOPostSubscription} from '../../core/subscription/so-post-subscription';
import {SubscriptionCoreBase} from './subscription.core.base';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {FormField, SelectionOption} from 'lionheartland';
import {postSubscriptionFormFieldOptions} from './post-subscription-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {ContainersessionCore} from '../containersession/containersession.core';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {UserCore} from '../user/user.core';
import {SOGetUser} from '../../core/user/so-get-user';
import {PublicationCore} from '../publication/publication.core';
import {SOGetPublication} from '../../core/publication/so-get-publication';

export class RAPostSubscription extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSubscriptionFormFieldOptions);

  RequestClass = SOPostSubscription

  constructor(
    public entityCore: SubscriptionCoreBase
  ) {
    super();
    if (postSubscriptionFormFieldOptions.containersession) {
      postSubscriptionFormFieldOptions.containersession.makeCore = true;
    }
    if (postSubscriptionFormFieldOptions.user) {
      postSubscriptionFormFieldOptions.user.makeCore = true;
    }
    if (postSubscriptionFormFieldOptions.publication) {
      postSubscriptionFormFieldOptions.publication.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'user',
        SOGetUser,
      ),
      this.getFormValueFromIdentifier(
        'publication',
        SOGetPublication,
      ),
      this.getFormValueFromIdentifier(
        'containersession',
        SOGetContainersession,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}