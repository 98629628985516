import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPutDistributionday {

  constructor(
    public uid: string,
    public state: string = null,
    public distribution: SOGetDistribution = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDistributionday(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
