import {SORespTempclin} from '../../core/tempclin/so-resp-tempclin';
import {TempclinCore} from './tempclin.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTempclinFormFields} from './put-tempclin-form-fields';
import { CETempclinAttributes } from './ce-tempclin-attributes'


export class CETempclinBase extends CEBase {

    public details: CETempclinAttributes;
    public fromFields: PutTempclinFormFields = new PutTempclinFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TempclinCore,
        public entity: SORespTempclin,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETempclinAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.durationDays)
        this.attributes.push(this.details.offsetDays)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.loadQuantity)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.items)
        this.attributes.push(this.details.slots)
        this.attributes.push(this.details.slotlets)
        this.attributes.push(this.details.clins)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.temprices)
        this.attributes.push(this.details.temptraclins)
        this.attributes.push(this.details.bids)

    }
}