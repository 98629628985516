import {SORespTemptraclin} from '../../core/temptraclin/so-resp-temptraclin';
import {TemptraclinCore} from './temptraclin.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTemptraclinFormFields} from './put-temptraclin-form-fields';
import {SOGetTemptraclin} from '../../core/temptraclin/so-get-temptraclin';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';


export class CETemptraclinAttributesBase {

    tempclin: DetailEntry
    temptract: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTemptraclin,
        app: GasDirectApp,
        formFields: PutTemptraclinFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const tempclinCore = app.makeCore( 'tempclin');
        tempclinCore.soGet = SOGetTempclin.construct({
        });
        let tempclinCeProtocol;
        if (entity.tempclin) {
            tempclinCeProtocol = createCEProtocol(
                tempclinCore.ceProtocol,
                parentEntityViewSpec,
                tempclinCore,
                entity.tempclin,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempclinCore
        )
        tempclinCore.title = heading ? heading : tempclinCore.title
        tempclinCore.listTitle = 'Tempclin'

        if (entity.tempclin) {
            displayStringFactory.reconfigure(
                entity.tempclin,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        tempclinCore.configureForDisplay(parentEntityViewSpec)

        this.tempclin = new DetailEntry(
          heading ? heading : tempclinCore.listTitle,
          tempclinCeProtocol ? tempclinCeProtocol.entityListView.fullHeading : null,
          'tempclin',
          BaseDetailType.pushedDetail,
          tempclinCore,
          EditableType.always,
          formFields.tempclin
        )
        if (entity.tempclin) {
          this.tempclin.ceProtocol = tempclinCeProtocol;
          this.tempclin.cachedListEntry = tempclinCore.makeListEntry(parentEntityViewSpec, entity.tempclin, 0)
          tempclinCore.selectedEntity = entity.tempclin;
        }
        this.tempclin.singleFieldSelect = true

        const temptractCore = app.makeCore( 'temptract');
        temptractCore.soGet = SOGetTemptract.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            temptractCore
        )
        temptractCore.title = heading ? heading : temptractCore.title
        temptractCore.listTitle = 'Temptract'

        temptractCore.configureForDisplay(parentEntityViewSpec)

        this.temptract = new DetailEntry(
          heading ? heading : temptractCore.listTitle,
          null,
          'temptract',
          BaseDetailType.pushedDetail,
          temptractCore,
          EditableType.always,
          formFields.temptract
        )
        this.temptract.singleFieldSelect = true
    }
}