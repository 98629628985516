import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespInquiry implements ObjectiveProtocol {

  entityType: string = 'inquiry';
  uid: string;
  companyName: string;
  givenName: string;
  surname: string;
  productInquiryStr: string = "Shute Creek LNG";
  estimatedPurchaseQuantity: string;
  preferredEmailAddress: string;
  phoneNumber: string;
  @Type(() => Date)
  dateCreated: Date;
}