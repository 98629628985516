import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTagreportFormFields {

    public value: FormField

    constructor() {

        this.value = new FormField(
          'value',
          'Value',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostTagreportFormFields()

export const postTagreportFormFieldOptions: {[key: string]: FormField} = {
  value: fields.value,
};