import {SORespAdjustment} from '../../core/adjustment/so-resp-adjustment';
import {AdjustmentCore} from './adjustment.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAdjustmentFormFields} from './put-adjustment-form-fields';
import {SOGetAdjustment} from '../../core/adjustment/so-get-adjustment';
import {SOGetDowntime} from '../../core/downtime/so-get-downtime';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';


export class CEAdjustmentAttributesBase {

    quantity: DetailEntry
    description: DetailEntry
    acqAdjustment: DetailEntry
    downtime: DetailEntry
    agreemonth: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAdjustment,
        app: GasDirectApp,
        formFields: PutAdjustmentFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.quantity
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.acqAdjustment = new DetailEntry(
            'Acq adjustment',
            entity.acqAdjustment,
            'acqAdjustment',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )


        const downtimeCore = app.makeCore( 'downtime');
        downtimeCore.soGet = SOGetDowntime.construct({
        });
        let downtimeCeProtocol;
        if (entity.downtime) {
            downtimeCeProtocol = createCEProtocol(
                downtimeCore.ceProtocol,
                parentEntityViewSpec,
                downtimeCore,
                entity.downtime,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            downtimeCore
        )
        downtimeCore.title = heading ? heading : downtimeCore.title
        downtimeCore.listTitle = 'Downtime'

        if (entity.downtime) {
            displayStringFactory.reconfigure(
                entity.downtime,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        downtimeCore.configureForDisplay(parentEntityViewSpec)

        this.downtime = new DetailEntry(
          heading ? heading : downtimeCore.listTitle,
          downtimeCeProtocol ? downtimeCeProtocol.entityListView.fullHeading : null,
          'downtime',
          BaseDetailType.pushedDetail,
          downtimeCore,
          EditableType.never
        )
        if (entity.downtime) {
          this.downtime.ceProtocol = downtimeCeProtocol;
          this.downtime.cachedListEntry = downtimeCore.makeListEntry(parentEntityViewSpec, entity.downtime, 0)
          downtimeCore.selectedEntity = entity.downtime;
        }
        this.downtime.singleFieldSelect = true

        const agreemonthCore = app.makeCore( 'agreemonth');
        agreemonthCore.soGet = SOGetAgreemonth.construct({
        });
        agreemonthCore.soGet.queryOptions.sortKey = 'month';
        let agreemonthCeProtocol;
        if (entity.agreemonth) {
            agreemonthCeProtocol = createCEProtocol(
                agreemonthCore.ceProtocol,
                parentEntityViewSpec,
                agreemonthCore,
                entity.agreemonth,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreemonthCore
        )
        agreemonthCore.title = heading ? heading : agreemonthCore.title
        agreemonthCore.listTitle = 'Agreemonth'

        if (entity.agreemonth) {
            displayStringFactory.reconfigure(
                entity.agreemonth,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreemonthCore.configureForDisplay(parentEntityViewSpec)

        this.agreemonth = new DetailEntry(
          heading ? heading : agreemonthCore.listTitle,
          agreemonthCeProtocol ? agreemonthCeProtocol.entityListView.fullHeading : null,
          'agreemonth',
          BaseDetailType.pushedDetail,
          agreemonthCore,
          EditableType.never
        )
        if (entity.agreemonth) {
          this.agreemonth.ceProtocol = agreemonthCeProtocol;
          this.agreemonth.cachedListEntry = agreemonthCore.makeListEntry(parentEntityViewSpec, entity.agreemonth, 0)
          agreemonthCore.selectedEntity = entity.agreemonth;
        }
        this.agreemonth.singleFieldSelect = true
    }
}