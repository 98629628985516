import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutOrgrola } from './so-put-orgrola';
import { SOGetOrgrola } from './so-get-orgrola';
import { SOPostOrgrola } from './so-post-orgrola';
import { SODeleteOrgrola } from './so-delete-orgrola';
import {SORespOrgrola} from './so-resp-orgrola';
import {SOPluralOrgrola} from './so-plural-orgrola';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOOrgrolaService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'orgrola',
      entityCore
    );
  }

  /** GET Orgrola by id. Will 404 if id not found */
  get(request: SOGetOrgrola): Observable<SOPluralOrgrola> {
    return this.httpClient.get<SOPluralOrgrola>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralOrgrola, resp) }),
      tap(_ => this.log(`fetched Orgrola`)),
      catchError(this.handleError<SOPluralOrgrola>(`get Orgrola`))
    );
  }

  /** POST: add a new Orgrola to the server */
  post(request: SOPostOrgrola): Observable<SORespOrgrola> {
    return this.httpClient.post<SORespOrgrola>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespOrgrola, resp) }),
      tap((response: SORespOrgrola) => this.log(`added Orgrola w/ id=${response.uid}`)),
      catchError(this.handleError<SORespOrgrola>('Orgrola post'))
    );
  }

  /** PUT: update Orgrola  */
  put(request: SOPutOrgrola): Observable<SORespOrgrola> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespOrgrola>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespOrgrola, resp) }),
      tap((response: SORespOrgrola) => this.log(`edited Orgrola w/ uid=${uid}`)),
      catchError(this.handleError<SORespOrgrola>('Orgrola put'))
    );
  }

  /** DELETE: delete the Orgrola from the server */
  delete(request: SODeleteOrgrola): Observable<SORespOrgrola> {
    return this.httpClient.delete<SORespOrgrola>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Orgrola uid=${request.uid}`)),
      catchError(this.handleError<SORespOrgrola>('delete Orgrola'))
    );
  }
}
