import {SORespSlot} from '../../core/slot/so-resp-slot';
import {SlotCore} from './slot.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSlotFormFields} from './put-slot-form-fields';
import { CESlotAttributes } from './ce-slot-attributes'


export class CESlotBase extends CEBase {

    public details: CESlotAttributes;
    public fromFields: PutSlotFormFields = new PutSlotFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: SlotCore,
        public entity: SORespSlot,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CESlotAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.slotNumber)
        this.attributes.push(this.details.slotDay)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.loadType)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.calendar)
        this.attributes.push(this.details.reservations)
        this.attributes.push(this.details.activitys)
        this.attributes.push(this.details.slotoptions)
        this.attributes.push(this.details.distribution)

    }
}