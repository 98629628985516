import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOLngloadService} from '../../core/lngload/eo-lngload';
import {SOGetLngload} from '../../core/lngload/so-get-lngload';
import {RAPutLngload} from './ra-put-lngload';
import {RAPostLngload} from './ra-post-lngload';
import {RADeleteLngload} from './ra-delete-lngload';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CELngload } from "./ce-lngload"


export class LngloadCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'lngload';
  title = 'Lngload';
  ceProtocol: CEConstructor = CELngload

  entityService: EOLngloadService;
  soGetClass = SOGetLngload;
  soGet: SOGetLngload;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOLngloadService
    );
    this.adapters = [
      new RAPostLngload(this),
      new RAPutLngload(this),
      new RADeleteLngload(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetLngload();
    this.soGet.queryOptions.limit = this.limit;
  }


}