import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTrailermerge } from './so-put-trailermerge';
import { SOGetTrailermerge } from './so-get-trailermerge';
import { SOPostTrailermerge } from './so-post-trailermerge';
import { SODeleteTrailermerge } from './so-delete-trailermerge';
import {SORespTrailermerge} from './so-resp-trailermerge';
import {SOPluralTrailermerge} from './so-plural-trailermerge';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTrailermergeService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'trailermerge',
      entityCore
    );
  }

  /** GET Trailermerge by id. Will 404 if id not found */
  get(request: SOGetTrailermerge): Observable<SOPluralTrailermerge> {
    return this.httpClient.get<SOPluralTrailermerge>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTrailermerge, resp) }),
      tap(_ => this.log(`fetched Trailermerge`)),
      catchError(this.handleError<SOPluralTrailermerge>(`get Trailermerge`))
    );
  }

  /** POST: add a new Trailermerge to the server */
  post(request: SOPostTrailermerge): Observable<SORespTrailermerge> {
    return this.httpClient.post<SORespTrailermerge>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTrailermerge, resp) }),
      tap((response: SORespTrailermerge) => this.log(`added Trailermerge w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTrailermerge>('Trailermerge post'))
    );
  }

  /** PUT: update Trailermerge  */
  put(request: SOPutTrailermerge): Observable<SORespTrailermerge> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTrailermerge>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTrailermerge, resp) }),
      tap((response: SORespTrailermerge) => this.log(`edited Trailermerge w/ uid=${uid}`)),
      catchError(this.handleError<SORespTrailermerge>('Trailermerge put'))
    );
  }

  /** DELETE: delete the Trailermerge from the server */
  delete(request: SODeleteTrailermerge): Observable<SORespTrailermerge> {
    return this.httpClient.delete<SORespTrailermerge>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Trailermerge uid=${request.uid}`)),
      catchError(this.handleError<SORespTrailermerge>('delete Trailermerge'))
    );
  }
}
