import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutFeescheduleFormFields {

    public displayName: FormField
    public start: FormField
    public end: FormField

    constructor() {

        this.displayName = new FormField(
          'displayName',
          'Display name',
          null,
          FormFieldType.text,
          false,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          false,
        )

    }
}

const fields = new PutFeescheduleFormFields()

export const putFeescheduleFormFieldOptions: {[key: string]: FormField} = {
  displayName: fields.displayName,
  start: fields.start,
  end: fields.end,
};