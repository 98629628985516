import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAgreementService} from '../../core/agreement/eo-agreement';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {RAPutAgreement} from './ra-put-agreement';
import {RAPostAgreement} from './ra-post-agreement';
import {RADeleteAgreement} from './ra-delete-agreement';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import { CEAgreement } from "./ce-agreement"


export class AgreementCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'agreement';
  title = 'Agreement';
  ceProtocol: CEConstructor = CEAgreement

  entityService: EOAgreementService;
  soGetClass = SOGetAgreement;
  soGet: SOGetAgreement;

  isScope = true;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAgreementService
    );
    this.adapters = [
      new RAPostAgreement(this),
      new RAPutAgreement(this),
      new RADeleteAgreement(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAgreement();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}