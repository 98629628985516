import {SORespClin} from '../../core/clin/so-resp-clin';
import {ClinCore} from './clin.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutClinFormFields} from './put-clin-form-fields';
import {SOGetClin} from '../../core/clin/so-get-clin';
import {SOGetContract} from '../../core/contract/so-get-contract';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {SOGetClinyearlng} from '../../core/clinyearlng/so-get-clinyearlng';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEClinAttributesBase {

    name: DetailEntry
    state: DetailEntry
    type: DetailEntry
    start: DetailEntry
    end: DetailEntry
    quantity: DetailEntry
    quantityUsed: DetailEntry
    loadQuantity: DetailEntry
    loadQuantityUsed: DetailEntry
    description: DetailEntry
    contract: DetailEntry
    tempclin: DetailEntry
    slot: DetailEntry
    clinyearlng: DetailEntry
    prices: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespClin,
        app: GasDirectApp,
        formFields: PutClinFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )

        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.quantityUsed = new DetailEntry(
            'Quantity used',
            entity.quantityUsed,
            'quantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadQuantity = new DetailEntry(
            'Load quantity',
            entity.loadQuantity,
            'loadQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadQuantityUsed = new DetailEntry(
            'Load quantity used',
            entity.loadQuantityUsed,
            'loadQuantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )


        const contractCore = app.makeCore( 'contract');
        contractCore.soGet = SOGetContract.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            contractCore
        )
        contractCore.title = heading ? heading : contractCore.title
        contractCore.listTitle = 'Contract'

        contractCore.configureForDisplay(parentEntityViewSpec)

        this.contract = new DetailEntry(
          heading ? heading : contractCore.listTitle,
          null,
          'contract',
          BaseDetailType.pushedDetail,
          contractCore,
          EditableType.never
        )
        this.contract.singleFieldSelect = true

        const tempclinCore = app.makeCore( 'tempclin');
        tempclinCore.soGet = SOGetTempclin.construct({
        });
        let tempclinCeProtocol;
        if (entity.tempclin) {
            tempclinCeProtocol = createCEProtocol(
                tempclinCore.ceProtocol,
                parentEntityViewSpec,
                tempclinCore,
                entity.tempclin,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempclinCore
        )
        tempclinCore.title = heading ? heading : tempclinCore.title
        tempclinCore.listTitle = 'Tempclin'

        if (entity.tempclin) {
            displayStringFactory.reconfigure(
                entity.tempclin,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        tempclinCore.configureForDisplay(parentEntityViewSpec)

        this.tempclin = new DetailEntry(
          heading ? heading : tempclinCore.listTitle,
          tempclinCeProtocol ? tempclinCeProtocol.entityListView.fullHeading : null,
          'tempclin',
          BaseDetailType.pushedDetail,
          tempclinCore,
          EditableType.never
        )
        if (entity.tempclin) {
          this.tempclin.ceProtocol = tempclinCeProtocol;
          this.tempclin.cachedListEntry = tempclinCore.makeListEntry(parentEntityViewSpec, entity.tempclin, 0)
          tempclinCore.selectedEntity = entity.tempclin;
        }
        this.tempclin.singleFieldSelect = true

        const slotCore = app.makeCore( 'slot');
        slotCore.soGet = SOGetSlot.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        slotCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotCore
        )
        slotCore.title = heading ? heading : slotCore.title
        slotCore.listTitle = 'Slot'

        slotCore.configureForDisplay(parentEntityViewSpec)

        this.slot = new DetailEntry(
          heading ? heading : slotCore.listTitle,
          null,
          'slot',
          BaseDetailType.pushedDetail,
          slotCore,
          EditableType.never
        )
        this.slot.singleFieldSelect = true

        const clinyearlngCore = app.makeCore( 'clinyearlng');
        clinyearlngCore.soGet = SOGetClinyearlng.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            clinyearlngCore
        )
        clinyearlngCore.title = heading ? heading : clinyearlngCore.title
        clinyearlngCore.listTitle = 'Clinyearlng'

        clinyearlngCore.configureForDisplay(parentEntityViewSpec)

        this.clinyearlng = new DetailEntry(
          heading ? heading : clinyearlngCore.listTitle,
          null,
          'clinyearlng',
          BaseDetailType.pushedDetail,
          clinyearlngCore,
          EditableType.never
        )
        this.clinyearlng.singleFieldSelect = true

        const pricesCore = app.makeCore( 'price');
        pricesCore.soGet = SOGetPrice.construct({
          clin: new SOGetClin(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            pricesCore
        )
        pricesCore.title = heading ? heading : pricesCore.title
        pricesCore.listTitle = 'Prices'

        pricesCore.configureForDisplay(parentEntityViewSpec)

        this.prices = new DetailEntry(
          heading ? heading : pricesCore.listTitle,
          null,
          'prices',
          BaseDetailType.embeddedList,
          pricesCore,
          EditableType.never
        )
    }
}