import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTagFormFields {

    public name: FormField
    public identifier: FormField
    public dcs: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          true,
        )
        this.dcs = new FormField(
          'dcs',
          'Dcs',
          'dcs',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostTagFormFields()

export const postTagFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  identifier: fields.identifier,
  dcs: fields.dcs,
};