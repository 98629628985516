import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutDistributionmonthFormFields {

    public state: FormField
    public started: FormField
    public ordersCreated: FormField
    public ordersSubmitted: FormField
    public scheduleMade: FormField
    public loadsAllocated: FormField
    public midMonthCheckpointComplete: FormField
    public invoicesSent: FormField
    public paymentsReceived: FormField
    public closed: FormField
    public productionVolume: FormField
    public productionVolumeEstimated: FormField
    public distribution: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )
        this.started = new FormField(
          'started',
          'Started',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.ordersCreated = new FormField(
          'ordersCreated',
          'Orders created',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.ordersSubmitted = new FormField(
          'ordersSubmitted',
          'Orders submitted',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.scheduleMade = new FormField(
          'scheduleMade',
          'Schedule made',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.loadsAllocated = new FormField(
          'loadsAllocated',
          'Loads allocated',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.midMonthCheckpointComplete = new FormField(
          'midMonthCheckpointComplete',
          'Mid month checkpoint complete',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.invoicesSent = new FormField(
          'invoicesSent',
          'Invoices sent',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.paymentsReceived = new FormField(
          'paymentsReceived',
          'Payments received',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.closed = new FormField(
          'closed',
          'Closed',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.productionVolume = new FormField(
          'productionVolume',
          'Production volume',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.productionVolumeEstimated = new FormField(
          'productionVolumeEstimated',
          'Production volume estimated',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutDistributionmonthFormFields()

export const putDistributionmonthFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  started: fields.started,
  ordersCreated: fields.ordersCreated,
  ordersSubmitted: fields.ordersSubmitted,
  scheduleMade: fields.scheduleMade,
  loadsAllocated: fields.loadsAllocated,
  midMonthCheckpointComplete: fields.midMonthCheckpointComplete,
  invoicesSent: fields.invoicesSent,
  paymentsReceived: fields.paymentsReceived,
  closed: fields.closed,
  productionVolume: fields.productionVolume,
  productionVolumeEstimated: fields.productionVolumeEstimated,
};