

export class SOPostBulk {

  constructor(
    public body: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostBulk(
      'body' in params ? params.body : null,
    );
  }
}
