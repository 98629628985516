import {SORespWonderware} from '../../core/wonderware/so-resp-wonderware';
import {WonderwareCore} from './wonderware.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutWonderwareFormFields} from './put-wonderware-form-fields';
import { CEWonderwareAttributes } from './ce-wonderware-attributes'


export class CEWonderwareBase extends CEBase {

    public details: CEWonderwareAttributes;
    public fromFields: PutWonderwareFormFields = new PutWonderwareFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: WonderwareCore,
        public entity: SORespWonderware,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEWonderwareAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []

    }
}