import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutOfferingFormFields {

    public distoffers: FormField
    public name: FormField
    public description: FormField
    public units: FormField
    public size: FormField
    public items: FormField
    public loads: FormField

    constructor() {

        this.distoffers = new FormField(
          'distoffers',
          'Distoffers',
          'distoffer',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.units = new FormField(
          'units',
          'Units',
          null,
          FormFieldType.text,
          false,
        )
        this.size = new FormField(
          'size',
          'Size',
          null,
          FormFieldType.text,
          false,
        )
        this.items = new FormField(
          'items',
          'Items',
          'item',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.loads = new FormField(
          'loads',
          'Loads',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )

    }
}

const fields = new PutOfferingFormFields()

export const putOfferingFormFieldOptions: {[key: string]: FormField} = {
  distoffers: fields.distoffers,
  name: fields.name,
  description: fields.description,
  units: fields.units,
  size: fields.size,
  items: fields.items,
  loads: fields.loads,
};