import { SOGetLoad } from '../load/so-get-load';
import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostChecklist {

  constructor(
    public body: string,
    public name: string,
    public identifier: string,
    public load: SOGetLoad = null,
    public distribution: SOGetDistribution = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostChecklist(
      'body' in params ? params.body : null,
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'load' in params ? params.load : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
