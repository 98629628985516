import {SORespTemptraclin} from '../../core/temptraclin/so-resp-temptraclin';
import {TemptraclinCore} from './temptraclin.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTemptraclinFormFields} from './put-temptraclin-form-fields';
import { CETemptraclinAttributes } from './ce-temptraclin-attributes'


export class CETemptraclinBase extends CEBase {

    public details: CETemptraclinAttributes;
    public fromFields: PutTemptraclinFormFields = new PutTemptraclinFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TemptraclinCore,
        public entity: SORespTemptraclin,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETemptraclinAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.tempclin)
        this.attributes.push(this.details.temptract)

    }
}