import {SORespTemprice} from '../../core/temprice/so-resp-temprice';
import {TempriceCore} from './temprice.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTempriceFormFields} from './put-temprice-form-fields';
import {SOGetTemprice} from '../../core/temprice/so-get-temprice';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';


export class CETempriceAttributesBase {

    rate: DetailEntry
    quantity: DetailEntry
    loadQuantity: DetailEntry
    rank: DetailEntry
    tempclin: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTemprice,
        app: GasDirectApp,
        formFields: PutTempriceFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.rate = new DetailEntry(
            'Rate',
            entity.rate,
            'rate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.rate
        )

        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.quantity
        )

        this.loadQuantity = new DetailEntry(
            'Load quantity',
            entity.loadQuantity,
            'loadQuantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadQuantity
        )

        this.rank = new DetailEntry(
            'Rank',
            entity.rank,
            'rank',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.rank
        )


        const tempclinCore = app.makeCore( 'tempclin');
        tempclinCore.soGet = SOGetTempclin.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempclinCore
        )
        tempclinCore.title = heading ? heading : tempclinCore.title
        tempclinCore.listTitle = 'Tempclin'

        tempclinCore.configureForDisplay(parentEntityViewSpec)

        this.tempclin = new DetailEntry(
          heading ? heading : tempclinCore.listTitle,
          null,
          'tempclin',
          BaseDetailType.pushedDetail,
          tempclinCore,
          EditableType.always,
          formFields.tempclin
        )
        this.tempclin.singleFieldSelect = true
    }
}