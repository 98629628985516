import {Component, ElementRef, ViewChild} from '@angular/core';
import {SOPostBulk} from '../../core/bulk/so-post-bulk';
import {SORespLoad} from '../../core/load/so-resp-load';
import {SORespPlant} from '../../core/plant/so-resp-plant';
import {EntityViewSpecProtocol, ListEntry, SvCalendarComponent} from "lionheartland";
import {ContainersessionCore} from "../../entities/containersession/containersession.core";
import {getDateInPlantTimeFromServerDate} from "../../custom-shared/display-time-util";
import {GasDirectApp} from "../../land-app";
import {CEContainersession} from "../../entities/containersession/ce-containersession";
import {BulkCore} from "../../entities/bulk/bulk.core";

export function getIndexOfDate(date: Date, adjustmentDate: Date, plant: SORespPlant = null) {
  const plantDate = plant ? getDateInPlantTimeFromServerDate(date, plant) : date;
  return Math.floor(
    (plantDate.getTime() - 4 * 3600000) / 1000 / 60 / 60 / 24
  ) - Math.floor(
    (adjustmentDate.getTime() - 4 * 3600000) / 1000 / 60 / 60 / 24
  );
}

@Component({
  selector: 'app-av-calendar',
  templateUrl: './av-calendar.component.html',
  styleUrls: ['./av-calendar.component.css']
})
export class AvCalendarComponent extends SvCalendarComponent {

  @ViewChild('uploader')
  uploader: ElementRef;

  showCalendar = true;
  showUnknowns = true;
  unknowns: ListEntry[] = [];
  empties: ListEntry[] = [];

  soak = false;

  containersessionCore: ContainersessionCore;
  private fetchedUnknowns = false;

  get app() {
    return this.activeUserService.app as GasDirectApp
  }

  get showDownload() {
    return this.activeUserService && this.app.isCustomer;
  }

  get showUpload(): boolean {
    return !this.activeUserService.isHandset && (
      this.isScheduler || (this.app.isHelium && this.app.isCustomer)
    );
  }

  get isScheduler() {
    return this.activeUserService.activeGrant && ['scheduler', 'productionManager'].includes(this.activeUserService.activeGrant.role.identifier);
  }

  init() {
    super.init();
    this.setCalendarDate();
    if (!this.fetchedUnknowns && this.activeUserService.activeGrant.role.identifier !== 'carrier') {

      this.fetchedUnknowns = true;

      this.containersessionCore = new ContainersessionCore(this.activeUserService);
      this.containersessionCore.soGet.predicate = `unknowns${
        this.app.account ? '::' + this.app.account.uid : ''
      }`;
      this.containersessionCore.entityService.get(this.containersessionCore.soGet).subscribe(resp => {
        this.app.unknowns = [];
        if (resp) {
          for (const containersession of resp.objects) {
            const ce = new CEContainersession(this.entityViewSpec, this.containersessionCore, containersession, this.app)
            if (!containersession.shipEmpty) {
              this.unknowns.push(
                ce.entityListView
              );
              this.app.unknowns.push(containersession);
            } else {

              if (
                ['scheduler', 'operator'].includes(this.activeUserService.activeGrant.role.identifier)
                || (
                  containersession.account
                  && this.app.account
                  && this.app.account.uid === containersession.account.uid
                )
              ) {
                this.empties.push(
                  ce.entityListView
                );
              }
            }
          }
        }
      });
    }

  }

  uploadFile($event) {
    this.loading = true;
    console.log('Upload file selected');
    const data = $event.target.files[0];
    this.excelService.readFirstSheet(data, this);
  }

  readComplete(data) {
    this.uploader.nativeElement.value = '';
    this.loading = true;
    console.log(data);
    let body;
    if (this.app.isHelium && this.app.isCustomer) {
      body = {
        heCustomerLines: data,
        accountUid: this.app.account.uid
      };
    }

    if (!this.app.isCustomer) {
      console.log(data);
      body = {
        slotLines: data,
        distributionUid: this.app.distribution.uid
      };
    }

    const bulk = new BulkCore(this.activeUserService);
    bulk.delegate = this;
    bulk.entityService.post(new SOPostBulk(body)).subscribe(resp => {
      this.loading = false;
      if (resp) {
        this.snackBar.open(`${resp['msg']}`);
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
          this.router.navigate(['events']));
      }
    });

  }

  downloadSchedule() {

    // const scheduleExportCore = new ScheduleexportCore(this.activeUserService);
    // scheduleExportCore.listTitle = 'Schedule export';
    // scheduleExportCore.soGet.account = new SOGetAccount(this.app.account.uid);
    // scheduleExportCore.soGet.status = 'available';
    // scheduleExportCore.soGet.queryOptions.limit = 10000;
    // scheduleExportCore.soGet.predicate = 'template';
    //
    // scheduleExportCore.entityService.get(scheduleExportCore.soGet).subscribe(resp => {
    //   this.loading = false;
    //   if (resp) {
    //     scheduleExportCore.entities = resp.objects;
    //     const excelService = new ExcelService(this.httpsClient);
    //     excelService.exportCoreAsExcelFile(scheduleExportCore, {data: scheduleExportCore.entitesForUploadTemplate});
    //   }
    // });
  }

  toggleUnknowns() {
    this.showUnknowns = !this.showUnknowns;
  }

  showError(message: string, identifier: string = null) {
    this.snackBar.open(message);
  }

  requestSuccess(resp: SORespLoad, identifier: string = null) {

  }

}
