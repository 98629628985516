import {SORespCarrierauth} from '../../core/carrierauth/so-resp-carrierauth';
import {CarrierauthCore} from './carrierauth.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutCarrierauthFormFields} from './put-carrierauth-form-fields';
import {SOGetCarrierauth} from '../../core/carrierauth/so-get-carrierauth';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CECarrierauthAttributesBase {

    account: DetailEntry
    carrier: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespCarrierauth,
        app: GasDirectApp,
        formFields: PutCarrierauthFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.always,
          formFields.account
        )
        this.account.singleFieldSelect = true

        const carrierCore = app.makeCore( 'carrier');
        carrierCore.soGet = SOGetCarrier.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            carrierCore
        )
        carrierCore.title = heading ? heading : carrierCore.title
        carrierCore.listTitle = 'Carrier'

        carrierCore.configureForDisplay(parentEntityViewSpec)

        this.carrier = new DetailEntry(
          heading ? heading : carrierCore.listTitle,
          null,
          'carrier',
          BaseDetailType.pushedDetail,
          carrierCore,
          EditableType.always,
          formFields.carrier
        )
        this.carrier.singleFieldSelect = true
    }
}