import {SOPutTaskreport} from '../../core/taskreport/so-put-taskreport';
import {TaskreportCoreBase} from './taskreport.core.base';
import {SOGetTaskreport} from '../../core/taskreport/so-get-taskreport';
import {FormField, SelectionOption} from 'lionheartland';
import {putTaskreportFormFieldOptions} from './put-taskreport-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutTaskreport extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTaskreportFormFieldOptions);

  RequestClass = SOPutTaskreport

  constructor(
    public entityCore: TaskreportCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('dateRequested'),
      this.getFormValueFromIdentifier('dateStart'),
      this.getFormValueFromIdentifier('dateEnd'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}