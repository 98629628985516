import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostClinFormFields {

    public name: FormField
    public state: FormField
    public type: FormField
    public start: FormField
    public end: FormField
    public description: FormField
    public contract: FormField
    public tempclin: FormField
    public slot: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.contract = new FormField(
          'contract',
          'Contract',
          'contract',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.tempclin = new FormField(
          'tempclin',
          'Tempclin',
          'tempclin',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.slot = new FormField(
          'slot',
          'Slot',
          'slot',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostClinFormFields()

export const postClinFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  state: fields.state,
  type: fields.type,
  start: fields.start,
  end: fields.end,
  description: fields.description,
  contract: fields.contract,
  tempclin: fields.tempclin,
  slot: fields.slot,
};