import { SOGetLoad } from '../load/so-get-load';
import { SOGetTask } from '../task/so-get-task';
import { SOGetContainersession } from '../containersession/so-get-containersession';


export class SOPutHemon {

  constructor(
    public uid: string,
    public dateCreated: Date = null,
    public state: string = null,
    public n2Level: number = null,
    public n2Pressure: number = null,
    public heTemp: number = null,
    public heLevel: number = null,
    public hePressure: number = null,
    public load: SOGetLoad = null,
    public task: SOGetTask = null,
    public containersession: SOGetContainersession = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutHemon(
      'uid' in params ? params.uid : null,
      'dateCreated' in params ? params.dateCreated : null,
      'state' in params ? params.state : null,
      'n2Level' in params ? params.n2Level : null,
      'n2Pressure' in params ? params.n2Pressure : null,
      'heTemp' in params ? params.heTemp : null,
      'heLevel' in params ? params.heLevel : null,
      'hePressure' in params ? params.hePressure : null,
      'load' in params ? params.load : null,
      'task' in params ? params.task : null,
      'containersession' in params ? params.containersession : null,
    );
  }
}
