import {SOPostHestatusitem} from '../../core/hestatusitem/so-post-hestatusitem';
import {HestatusitemCoreBase} from './hestatusitem.core.base';
import {SOGetHestatusitem} from '../../core/hestatusitem/so-get-hestatusitem';
import {FormField, SelectionOption} from 'lionheartland';
import {postHestatusitemFormFieldOptions} from './post-hestatusitem-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {HestatusCore} from '../hestatus/hestatus.core';
import {SOGetHestatus} from '../../core/hestatus/so-get-hestatus';
import {ContainersessionCore} from '../containersession/containersession.core';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';

export class RAPostHestatusitem extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postHestatusitemFormFieldOptions);

  RequestClass = SOPostHestatusitem

  constructor(
    public entityCore: HestatusitemCoreBase
  ) {
    super();
    if (postHestatusitemFormFieldOptions.hestatus) {
      postHestatusitemFormFieldOptions.hestatus.makeCore = true;
    }
    if (postHestatusitemFormFieldOptions.containersession) {
      postHestatusitemFormFieldOptions.containersession.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'hestatus',
        SOGetHestatus,
      ),
      this.getFormValueFromIdentifier(
        'containersession',
        SOGetContainersession,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}