import {SORespLngload} from '../../core/lngload/so-resp-lngload';
import {LngloadCore} from './lngload.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutLngloadFormFields} from './put-lngload-form-fields';
import { CELngloadAttributes } from './ce-lngload-attributes'


export class CELngloadBase extends CEBase {

    public details: CELngloadAttributes;
    public fromFields: PutLngloadFormFields = new PutLngloadFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: LngloadCore,
        public entity: SORespLngload,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CELngloadAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.load)

    }
}