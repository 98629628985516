import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutSaleaccountasc } from './so-put-saleaccountasc';
import { SOGetSaleaccountasc } from './so-get-saleaccountasc';
import { SOPostSaleaccountasc } from './so-post-saleaccountasc';
import { SODeleteSaleaccountasc } from './so-delete-saleaccountasc';
import {SORespSaleaccountasc} from './so-resp-saleaccountasc';
import {SOPluralSaleaccountasc} from './so-plural-saleaccountasc';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOSaleaccountascService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'saleaccountasc',
      entityCore
    );
  }

  /** GET Saleaccountasc by id. Will 404 if id not found */
  get(request: SOGetSaleaccountasc): Observable<SOPluralSaleaccountasc> {
    return this.httpClient.get<SOPluralSaleaccountasc>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralSaleaccountasc, resp) }),
      tap(_ => this.log(`fetched Saleaccountasc`)),
      catchError(this.handleError<SOPluralSaleaccountasc>(`get Saleaccountasc`))
    );
  }

  /** POST: add a new Saleaccountasc to the server */
  post(request: SOPostSaleaccountasc): Observable<SORespSaleaccountasc> {
    return this.httpClient.post<SORespSaleaccountasc>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSaleaccountasc, resp) }),
      tap((response: SORespSaleaccountasc) => this.log(`added Saleaccountasc w/ id=${response.uid}`)),
      catchError(this.handleError<SORespSaleaccountasc>('Saleaccountasc post'))
    );
  }

  /** PUT: update Saleaccountasc  */
  put(request: SOPutSaleaccountasc): Observable<SORespSaleaccountasc> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSaleaccountasc>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSaleaccountasc, resp) }),
      tap((response: SORespSaleaccountasc) => this.log(`edited Saleaccountasc w/ uid=${uid}`)),
      catchError(this.handleError<SORespSaleaccountasc>('Saleaccountasc put'))
    );
  }

  /** DELETE: delete the Saleaccountasc from the server */
  delete(request: SODeleteSaleaccountasc): Observable<SORespSaleaccountasc> {
    return this.httpClient.delete<SORespSaleaccountasc>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Saleaccountasc uid=${request.uid}`)),
      catchError(this.handleError<SORespSaleaccountasc>('delete Saleaccountasc'))
    );
  }
}
