<div class="list-container" fxLayout="column">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent"></mat-progress-bar>
  <div *ngIf="!loading" class="hidden-progress-spacer"></div>
  <div fxLayout="row" class="calendar-container">
    <div class="calendar" *ngIf="showCalendar" fxLayout="column">
      <div fxLayout="row" class="control-bar">
        <div class="cal-buttons" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <h2>{{month}} {{year}}</h2>
            <button mat-stroked-button class="arrow" (click)="back()">
              <
            </button>
            <button mat-stroked-button color="primary" (click)="pressTodayButton()">
              Today
            </button>
            <button mat-stroked-button class="arrow" (click)="next()">
              >
            </button>
          </div>
          <div class="accessory-buttons">
            <input hidden type="file" accept=".xls,.xlsx,.csv" #uploader (change)=uploadFile($event)>
            <button mat-stroked-button *ngIf="showDownload" (click)="downloadSchedule()">
              Download template
            </button>
            <button mat-stroked-button *ngIf="showUpload" (click)="uploader.click()">
              Upload
            </button>
            <button mat-stroked-button *ngIf="isScheduler" (click)="startPost(null)">
              Add event
            </button>
            <button mat-icon-button *ngIf="unknowns.length > 0" (click)="toggleUnknowns()">
              <mat-icon>
                menu_open
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="core-days" fxLayout="column" *ngIf="days">
        <div class="day-names" fxLayout="row">
          <p class="day-col" *ngFor="let day of days">{{day.heading}}</p>
        </div>
        <div class="account-rows">
          <div fxLayout="row" class="customer-loads">
            <div *ngFor="let day of days" class="days day-col" fxLayout="column">
              <div *ngFor="let slot of day.events">
                <app-sv-event [listEntry]="slot.entityView" [parent]="this"></app-sv-event>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showUnknowns" class="soak" fxLayout="column">
      <mat-selection-list [multiple]="false">
        <div mat-subheader>Shipping empty</div>
        <mat-list-option *ngFor="let listEntry of empties" (click)="selectListEntry(listEntry)" style="height: 45px">
          <div mat-line>{{listEntry.lineHeading}}</div>
          <div mat-line>Ar: {{activeUserService.time.niceVeryShortDateAndTime(listEntry.entity.arrived)}}</div>
        </mat-list-option>
      </mat-selection-list>
      <mat-selection-list [multiple]="false">
      <div mat-subheader>Unknowns</div>
        <mat-list-option *ngFor="let listEntry of unknowns" (click)="selectListEntry(listEntry)" style="height: 45px">
          <div mat-line>{{listEntry.lineHeading}}</div>
          <div mat-line>Ar: {{activeUserService.time.niceVeryShortDateAndTime(listEntry.entity.arrived)}}</div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
</div>


