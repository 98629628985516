import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutInvoiceitem } from './so-put-invoiceitem';
import { SOGetInvoiceitem } from './so-get-invoiceitem';
import { SOPostInvoiceitem } from './so-post-invoiceitem';
import { SODeleteInvoiceitem } from './so-delete-invoiceitem';
import {SORespInvoiceitem} from './so-resp-invoiceitem';
import {SOPluralInvoiceitem} from './so-plural-invoiceitem';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOInvoiceitemService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'invoiceitem',
      entityCore
    );
  }

  /** GET Invoiceitem by id. Will 404 if id not found */
  get(request: SOGetInvoiceitem): Observable<SOPluralInvoiceitem> {
    return this.httpClient.get<SOPluralInvoiceitem>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralInvoiceitem, resp) }),
      tap(_ => this.log(`fetched Invoiceitem`)),
      catchError(this.handleError<SOPluralInvoiceitem>(`get Invoiceitem`))
    );
  }

  /** POST: add a new Invoiceitem to the server */
  post(request: SOPostInvoiceitem): Observable<SORespInvoiceitem> {
    return this.httpClient.post<SORespInvoiceitem>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInvoiceitem, resp) }),
      tap((response: SORespInvoiceitem) => this.log(`added Invoiceitem w/ id=${response.uid}`)),
      catchError(this.handleError<SORespInvoiceitem>('Invoiceitem post'))
    );
  }

  /** PUT: update Invoiceitem  */
  put(request: SOPutInvoiceitem): Observable<SORespInvoiceitem> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespInvoiceitem>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInvoiceitem, resp) }),
      tap((response: SORespInvoiceitem) => this.log(`edited Invoiceitem w/ uid=${uid}`)),
      catchError(this.handleError<SORespInvoiceitem>('Invoiceitem put'))
    );
  }

  /** DELETE: delete the Invoiceitem from the server */
  delete(request: SODeleteInvoiceitem): Observable<SORespInvoiceitem> {
    return this.httpClient.delete<SORespInvoiceitem>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Invoiceitem uid=${request.uid}`)),
      catchError(this.handleError<SORespInvoiceitem>('delete Invoiceitem'))
    );
  }
}
