import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespCarrier implements ObjectiveProtocol {

  entityType: string = 'carrier';
  uid: string;
  companyName: string;
  emailDomain: string = null;
  handle: string;
  @Type(() => Date)
  dateCreated: Date;
  signedAgreement: string = null;
}