import {SORespProductionreport} from '../../core/productionreport/so-resp-productionreport';
import {ProductionreportCore} from './productionreport.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutProductionreportFormFields} from './put-productionreport-form-fields';
import { CEProductionreportAttributes } from './ce-productionreport-attributes'


export class CEProductionreportBase extends CEBase {

    public details: CEProductionreportAttributes;
    public fromFields: PutProductionreportFormFields = new PutProductionreportFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ProductionreportCore,
        public entity: SORespProductionreport,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEProductionreportAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.ident)
        this.attributes.push(this.details.timeWindow)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.average)
        this.attributes.push(this.details.averageUnits)
        this.attributes.push(this.details.midnightWeight)
        this.attributes.push(this.details.production)

    }
}