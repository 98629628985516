import {SORespNavroa} from '../../core/navroa/so-resp-navroa';
import {NavroaCore} from './navroa.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutNavroaFormFields} from './put-navroa-form-fields';
import { CENavroaAttributes } from './ce-navroa-attributes'


export class CENavroaBase extends CEBase {

    public details: CENavroaAttributes;
    public fromFields: PutNavroaFormFields = new PutNavroaFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: NavroaCore,
        public entity: SORespNavroa,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CENavroaAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.index)
        this.attributes.push(this.details.mainNav)
        this.attributes.push(this.details.role)
        this.attributes.push(this.details.navi)

    }
}