import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOChecklistService} from '../../core/checklist/eo-checklist';
import {SOGetChecklist} from '../../core/checklist/so-get-checklist';
import {RAPutChecklist} from './ra-put-checklist';
import {RAPostChecklist} from './ra-post-checklist';
import {RADeleteChecklist} from './ra-delete-checklist';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEChecklist } from "./ce-checklist"


export class ChecklistCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'checklist';
  title = 'Checklist';
  ceProtocol: CEConstructor = CEChecklist

  entityService: EOChecklistService;
  soGetClass = SOGetChecklist;
  soGet: SOGetChecklist;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOChecklistService
    );
    this.adapters = [
      new RAPostChecklist(this),
      new RAPutChecklist(this),
      new RADeleteChecklist(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetChecklist();
    this.soGet.queryOptions.limit = this.limit;
  }


}