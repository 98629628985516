import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutWonderware } from './so-put-wonderware';
import { SOGetWonderware } from './so-get-wonderware';
import { SOPostWonderware } from './so-post-wonderware';
import { SODeleteWonderware } from './so-delete-wonderware';
import {SORespWonderware} from './so-resp-wonderware';
import {SOPluralWonderware} from './so-plural-wonderware';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOWonderwareService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'wonderware',
      entityCore
    );
  }

  /** GET Wonderware by id. Will 404 if id not found */
  get(request: SOGetWonderware): Observable<SOPluralWonderware> {
    return this.httpClient.get<SOPluralWonderware>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralWonderware, resp) }),
      tap(_ => this.log(`fetched Wonderware`)),
      catchError(this.handleError<SOPluralWonderware>(`get Wonderware`))
    );
  }

  /** POST: add a new Wonderware to the server */
  post(request: SOPostWonderware): Observable<SORespWonderware> {
    return this.httpClient.post<SORespWonderware>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWonderware, resp) }),
      tap((response: SORespWonderware) => this.log(`added Wonderware w/ id=${response.uid}`)),
      catchError(this.handleError<SORespWonderware>('Wonderware post'))
    );
  }

  /** PUT: update Wonderware  */
  put(request: SOPutWonderware): Observable<SORespWonderware> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespWonderware>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWonderware, resp) }),
      tap((response: SORespWonderware) => this.log(`edited Wonderware w/ uid=${uid}`)),
      catchError(this.handleError<SORespWonderware>('Wonderware put'))
    );
  }

  /** DELETE: delete the Wonderware from the server */
  delete(request: SODeleteWonderware): Observable<SORespWonderware> {
    return this.httpClient.delete<SORespWonderware>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Wonderware uid=${request.uid}`)),
      catchError(this.handleError<SORespWonderware>('delete Wonderware'))
    );
  }
}
