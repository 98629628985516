import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOInvoiceService} from '../../core/invoice/eo-invoice';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {RAPutInvoice} from './ra-put-invoice';
import {RAPostInvoice} from './ra-post-invoice';
import {RADeleteInvoice} from './ra-delete-invoice';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import { CEInvoice } from "./ce-invoice"


export class InvoiceCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'invoice';
  title = 'Invoice';
  ceProtocol: CEConstructor = CEInvoice

  entityService: EOInvoiceService;
  soGetClass = SOGetInvoice;
  soGet: SOGetInvoice;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOInvoiceService
    );
    this.adapters = [
      new RAPostInvoice(this),
      new RAPutInvoice(this),
      new RADeleteInvoice(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetInvoice();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}