import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTank implements ObjectiveProtocol {

  entityType: string = 'tank';
  uid: string;
  name: string;
  units: string;
  type: string = "tank";
  capacity: number = 0;
  average: number = 0;
  value: number = 0;
}