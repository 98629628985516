import {SOPostHmonth} from '../../core/hmonth/so-post-hmonth';
import {HmonthCoreBase} from './hmonth.core.base';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {FormField, SelectionOption} from 'lionheartland';
import {postHmonthFormFieldOptions} from './post-hmonth-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {HyearCore} from '../hyear/hyear.core';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {DistributionmonthCore} from '../distributionmonth/distributionmonth.core';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';

export class RAPostHmonth extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postHmonthFormFieldOptions);

  RequestClass = SOPostHmonth

  constructor(
    public entityCore: HmonthCoreBase
  ) {
    super();
    if (postHmonthFormFieldOptions.hyear) {
      postHmonthFormFieldOptions.hyear.makeCore = true;
    }
    if (postHmonthFormFieldOptions.distributionmonth) {
      postHmonthFormFieldOptions.distributionmonth.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('month'),
      this.getFormValueFromIdentifier('year'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier(
        'hyear',
        SOGetHyear,
      ),
      this.getFormValueFromIdentifier(
        'distributionmonth',
        SOGetDistributionmonth,
      ),
      this.getFormValueFromIdentifier('mcq'),
      this.getFormValueFromIdentifier('mcqUsed'),
      this.getFormValueFromIdentifier('spotQuantity'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}