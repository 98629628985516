import { SOGetTempclin } from '../tempclin/so-get-tempclin';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetSale } from '../sale/so-get-sale';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetBid implements QueryProtocol {

  constructor(
    public uid: string = null,
    public tempclin: SOGetTempclin = null,
    public account: SOGetAccount = null,
    public sale: SOGetSale = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetBid(
      'uid' in params ? params.uid : null,
      'tempclin' in params ? params.tempclin : null,
      'account' in params ? params.account : null,
      'sale' in params ? params.sale : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
