import {SORespTemprole} from '../../core/temprole/so-resp-temprole';
import {TemproleCore} from './temprole.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTemproleFormFields} from './put-temprole-form-fields';
import {SOGetTemprole} from '../../core/temprole/so-get-temprole';
import {SOGetNavtemrola} from '../../core/navtemrola/so-get-navtemrola';


export class CETemproleAttributesBase {

    name: DetailEntry
    identifier: DetailEntry
    navtemrolas: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTemprole,
        app: GasDirectApp,
        formFields: PutTemproleFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )


        const navtemrolasCore = app.makeCore( 'navtemrola');
        navtemrolasCore.soGet = SOGetNavtemrola.construct({
          temprole: new SOGetTemprole(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            navtemrolasCore
        )
        navtemrolasCore.title = heading ? heading : navtemrolasCore.title
        navtemrolasCore.listTitle = 'Navtemrolas'

        navtemrolasCore.configureForDisplay(parentEntityViewSpec)

        this.navtemrolas = new DetailEntry(
          heading ? heading : navtemrolasCore.listTitle,
          null,
          'navtemrolas',
          BaseDetailType.embeddedList,
          navtemrolasCore,
          EditableType.never
        )
    }
}