import {SOPutWonderware} from '../../core/wonderware/so-put-wonderware';
import {WonderwareCoreBase} from './wonderware.core.base';
import {SOGetWonderware} from '../../core/wonderware/so-get-wonderware';
import {FormField, SelectionOption} from 'lionheartland';
import {putWonderwareFormFieldOptions} from './put-wonderware-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutWonderware extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putWonderwareFormFieldOptions);

  RequestClass = SOPutWonderware

  constructor(
    public entityCore: WonderwareCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('data'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}