import {SORespTagreport} from '../../core/tagreport/so-resp-tagreport';
import {TagreportCore} from './tagreport.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTagreportFormFields} from './put-tagreport-form-fields';
import {SOGetTagreport} from '../../core/tagreport/so-get-tagreport';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {SOGetWeight} from '../../core/weight/so-get-weight';
import {SOGetHemon} from '../../core/hemon/so-get-hemon';


export class CETagreportAttributesBase {

    value: DetailEntry
    valueTime: DetailEntry
    reportId: DetailEntry
    tag: DetailEntry
    weight: DetailEntry
    hemon: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTagreport,
        app: GasDirectApp,
        formFields: PutTagreportFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.value = new DetailEntry(
            'Value',
            entity.value,
            'value',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.valueTime = new DetailEntry(
            'Value time',
            app.activeUserService.time.dateAsString(entity.valueTime),
            'valueTime',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.reportId = new DetailEntry(
            'Report id',
            entity.reportId,
            'reportId',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const tagCore = app.makeCore( 'tag');
        tagCore.soGet = SOGetTag.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tagCore
        )
        tagCore.title = heading ? heading : tagCore.title
        tagCore.listTitle = 'Tag'

        tagCore.configureForDisplay(parentEntityViewSpec)

        this.tag = new DetailEntry(
          heading ? heading : tagCore.listTitle,
          null,
          'tag',
          BaseDetailType.pushedDetail,
          tagCore,
          EditableType.never
        )
        this.tag.singleFieldSelect = true

        const weightCore = app.makeCore( 'weight');
        weightCore.soGet = SOGetWeight.construct({
        });
        let weightCeProtocol;
        if (entity.weight) {
            weightCeProtocol = createCEProtocol(
                weightCore.ceProtocol,
                parentEntityViewSpec,
                weightCore,
                entity.weight,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            weightCore
        )
        weightCore.title = heading ? heading : weightCore.title
        weightCore.listTitle = 'Weight'

        if (entity.weight) {
            displayStringFactory.reconfigure(
                entity.weight,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        weightCore.configureForDisplay(parentEntityViewSpec)

        this.weight = new DetailEntry(
          heading ? heading : weightCore.listTitle,
          weightCeProtocol ? weightCeProtocol.entityListView.fullHeading : null,
          'weight',
          BaseDetailType.pushedDetail,
          weightCore,
          EditableType.never
        )
        if (entity.weight) {
          this.weight.ceProtocol = weightCeProtocol;
          this.weight.cachedListEntry = weightCore.makeListEntry(parentEntityViewSpec, entity.weight, 0)
          weightCore.selectedEntity = entity.weight;
        }
        this.weight.singleFieldSelect = true

        const hemonCore = app.makeCore( 'hemon');
        hemonCore.soGet = SOGetHemon.construct({
        });
        let hemonCeProtocol;
        if (entity.hemon) {
            hemonCeProtocol = createCEProtocol(
                hemonCore.ceProtocol,
                parentEntityViewSpec,
                hemonCore,
                entity.hemon,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hemonCore
        )
        hemonCore.title = heading ? heading : hemonCore.title
        hemonCore.listTitle = 'Hemon'

        if (entity.hemon) {
            displayStringFactory.reconfigure(
                entity.hemon,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        hemonCore.configureForDisplay(parentEntityViewSpec)

        this.hemon = new DetailEntry(
          heading ? heading : hemonCore.listTitle,
          hemonCeProtocol ? hemonCeProtocol.entityListView.fullHeading : null,
          'hemon',
          BaseDetailType.pushedDetail,
          hemonCore,
          EditableType.never
        )
        if (entity.hemon) {
          this.hemon.ceProtocol = hemonCeProtocol;
          this.hemon.cachedListEntry = hemonCore.makeListEntry(parentEntityViewSpec, entity.hemon, 0)
          hemonCore.selectedEntity = entity.hemon;
        }
        this.hemon.singleFieldSelect = true
    }
}