export enum ENLoadStatus {
  unscheduled = 'unscheduled',
  scheduled = 'scheduled',
  confirmed = 'confirmed',
  arrived = 'arrived',
  adHoc = 'adHoc',
  unknown = 'unknown',
  loading = 'loading',
  servicing = 'servicing',
  soaking = 'soaking',
  loadingComplete = 'loadingComplete',
  departed = 'departed',
  empty = 'empty',
  checkIn = 'checkIn',
  final = 'final',
  finalEmpty = 'finalEmpty',
}


export enum ENLocation {
  offsite = 'offsite',
  gate = 'gate',
  scales = 'scales',
  parkingLot = 'parkingLot',
  _1a = '1a',
  _1b = '1b',
  _1c = '1c',
  _2a = '2a',
  _2b = '2b',
  _2c = '2c',
}


export enum ENOrderStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
}


export enum ENPublicationOption {
  firstAccountLogin = 'firstAccountLogin',
  containerArrived = 'containerArrived',
  unknownContainerArrived = 'unknownContainerArrived',
  loadRequestChange = 'loadRequestChange',
  hotContainer = 'hotContainer',
  highPressureContainer = 'highPressureContainer',
  lowNet = 'lowNet',
  readyForPickup = 'readyForPickup',
  fullForFiveDays = 'fullForFiveDays',
  loadDeparted = 'loadDeparted',
  nextMonthScheduleReady = 'nextMonthScheduleReady',
  acceptingBidsOnNewSpot = 'acceptingBidsOnNewSpot',
  spotAwarded = 'spotAwarded',
  orderNeedsApproval = 'orderNeedsApproval',
  newCustomerInquiry = 'newCustomerInquiry',
}


export enum ENSalesTaxOptions {
  wy = 'wy',
  none = 'none',
}


export enum ENServiceType {
  fullFill = 'fullFill',
  initialFill = 'initialFill',
  finalFill = 'finalFill',
}


export enum ENTare {
  weight0 = 'weight0',
  weight1 = 'weight1',
  weight2 = 'weight2',
  weight3 = 'weight3',
  weight4 = 'weight4',
  rework0 = 'rework0',
  rework1 = 'rework1',
  midnight = 'midnight',
  midnightStarted = 'midnightStarted',
  midnightEntered = 'midnightEntered',
  dcsRead = 'dcsRead',
}


export enum ENViewType {
  primary = 'primary',
  secondary = 'secondary',
  hidden = 'hidden',
}


