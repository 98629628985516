import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutDcsFormFields {

    public name: FormField
    public endpoint: FormField
    public line: FormField
    public tags: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.endpoint = new FormField(
          'endpoint',
          'Endpoint',
          null,
          FormFieldType.text,
          false,
        )
        this.line = new FormField(
          'line',
          'Line',
          'line',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.tags = new FormField(
          'tags',
          'Tags',
          'tag',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutDcsFormFields()

export const putDcsFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  endpoint: fields.endpoint,
  line: fields.line,
  tags: fields.tags,
};