import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSaleService} from '../../core/sale/eo-sale';
import {SOGetSale} from '../../core/sale/so-get-sale';
import {RAPutSale} from './ra-put-sale';
import {RAPostSale} from './ra-post-sale';
import {RADeleteSale} from './ra-delete-sale';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CESale } from "./ce-sale"


export class SaleCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'sale';
  title = 'Sale';
  ceProtocol: CEConstructor = CESale

  entityService: EOSaleService;
  soGetClass = SOGetSale;
  soGet: SOGetSale;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSaleService
    );
    this.adapters = [
      new RAPostSale(this),
      new RAPutSale(this),
      new RADeleteSale(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSale();
    this.soGet.queryOptions.limit = this.limit;
  }


}