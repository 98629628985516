import {SORespDistribution} from '../../core/distribution/so-resp-distribution';
import {DistributionCore} from './distribution.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributionFormFields} from './put-distribution-form-fields';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetDistoffer} from '../../core/distoffer/so-get-distoffer';
import {SOGetDistributionreport} from '../../core/distributionreport/so-get-distributionreport';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetServiceRequest} from '../../core/service-request/so-get-service-request';
import {SOGetCalendar} from '../../core/calendar/so-get-calendar';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetMonthclose} from '../../core/monthclose/so-get-monthclose';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {SOGetFaq} from '../../core/faq/so-get-faq';
import {SOGetIndexprice} from '../../core/indexprice/so-get-indexprice';
import {SOGetChecklist} from '../../core/checklist/so-get-checklist';
import {SOGetTaskreport} from '../../core/taskreport/so-get-taskreport';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetDisttempagreement} from '../../core/disttempagreement/so-get-disttempagreement';
import {SOGetSale} from '../../core/sale/so-get-sale';
import {SOGetFeatureasc} from '../../core/featureasc/so-get-featureasc';


export class CEDistributionAttributesBase {

    name: DetailEntry
    type: DetailEntry
    marketIndexId: DetailEntry
    conversionRate: DetailEntry
    galToWeight: DetailEntry
    defaultSlotsPerDay: DetailEntry
    providerSlotDurationHours: DetailEntry
    customerSlotDurationHours: DetailEntry
    showSlotDuration: DetailEntry
    pickupInstructions: DetailEntry
    paymentInstructions: DetailEntry
    checkoutText: DetailEntry
    purchaseCompleteText: DetailEntry
    line: DetailEntry
    bolHeader: DetailEntry
    bolWarning: DetailEntry
    loadingChecklist: DetailEntry
    departureChecklist: DetailEntry
    loadNumberPrefix: DetailEntry
    loadNumberStart: DetailEntry
    nextLoadNumber: DetailEntry
    invoicePocText: DetailEntry
    invoicePaymentDetailText: DetailEntry
    invoiceNumberPrefix: DetailEntry
    distoffers: DetailEntry
    distributionreports: DetailEntry
    distributionyears: DetailEntry
    distributionmonths: DetailEntry
    distributionydays: DetailEntry
    slots: DetailEntry
    loads: DetailEntry
    serviceRequests: DetailEntry
    calendars: DetailEntry
    accounts: DetailEntry
    monthcloses: DetailEntry
    temptracts: DetailEntry
    tempclins: DetailEntry
    roles: DetailEntry
    publications: DetailEntry
    faqs: DetailEntry
    indexprices: DetailEntry
    checklists: DetailEntry
    taskreports: DetailEntry
    feeschedules: DetailEntry
    containersessions: DetailEntry
    disttempagreements: DetailEntry
    sales: DetailEntry
    featureascs: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDistribution,
        app: GasDirectApp,
        formFields: PutDistributionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.marketIndexId = new DetailEntry(
            'Market index id',
            entity.marketIndexId,
            'marketIndexId',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.marketIndexId
        )

        this.conversionRate = new DetailEntry(
            'Conversion rate',
            entity.conversionRate,
            'conversionRate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.conversionRate
        )

        this.galToWeight = new DetailEntry(
            'Gal to weight',
            entity.galToWeight,
            'galToWeight',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.galToWeight
        )

        this.defaultSlotsPerDay = new DetailEntry(
            'Default slots per day',
            entity.defaultSlotsPerDay,
            'defaultSlotsPerDay',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.defaultSlotsPerDay
        )

        this.providerSlotDurationHours = new DetailEntry(
            'Provider slot duration hours',
            entity.providerSlotDurationHours,
            'providerSlotDurationHours',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.providerSlotDurationHours
        )

        this.customerSlotDurationHours = new DetailEntry(
            'Customer slot duration hours',
            entity.customerSlotDurationHours,
            'customerSlotDurationHours',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.customerSlotDurationHours
        )

        this.showSlotDuration = new DetailEntry(
            'Show slot duration',
            entity.showSlotDuration,
            'showSlotDuration',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.showSlotDuration
        )

        this.pickupInstructions = new DetailEntry(
            'Pickup instructions',
            entity.pickupInstructions,
            'pickupInstructions',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.pickupInstructions
        )

        this.paymentInstructions = new DetailEntry(
            'Payment instructions',
            entity.paymentInstructions,
            'paymentInstructions',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.paymentInstructions
        )

        this.checkoutText = new DetailEntry(
            'Checkout text',
            entity.checkoutText,
            'checkoutText',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.checkoutText
        )

        this.purchaseCompleteText = new DetailEntry(
            'Purchase complete text',
            entity.purchaseCompleteText,
            'purchaseCompleteText',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.purchaseCompleteText
        )

        this.bolHeader = new DetailEntry(
            'Bol header',
            entity.bolHeader,
            'bolHeader',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.bolHeader
        )

        this.bolWarning = new DetailEntry(
            'Bol warning',
            entity.bolWarning,
            'bolWarning',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.bolWarning
        )

        this.loadingChecklist = new DetailEntry(
            'Loading checklist',
            entity.loadingChecklist,
            'loadingChecklist',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadingChecklist
        )

        this.departureChecklist = new DetailEntry(
            'Departure checklist',
            entity.departureChecklist,
            'departureChecklist',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.departureChecklist
        )

        this.loadNumberPrefix = new DetailEntry(
            'Load number prefix',
            entity.loadNumberPrefix,
            'loadNumberPrefix',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadNumberPrefix
        )

        this.loadNumberStart = new DetailEntry(
            'Load number start',
            entity.loadNumberStart,
            'loadNumberStart',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadNumberStart
        )

        this.nextLoadNumber = new DetailEntry(
            'Next load number',
            entity.nextLoadNumber,
            'nextLoadNumber',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.invoicePocText = new DetailEntry(
            'Invoice poc text',
            entity.invoicePocText,
            'invoicePocText',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.invoicePaymentDetailText = new DetailEntry(
            'Invoice payment detail text',
            entity.invoicePaymentDetailText,
            'invoicePaymentDetailText',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.invoiceNumberPrefix = new DetailEntry(
            'Invoice number prefix',
            entity.invoiceNumberPrefix,
            'invoiceNumberPrefix',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const lineCore = app.makeCore( 'line');
        lineCore.soGet = SOGetLine.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            lineCore
        )
        lineCore.title = heading ? heading : lineCore.title
        lineCore.listTitle = 'Line'

        lineCore.configureForDisplay(parentEntityViewSpec)

        this.line = new DetailEntry(
          heading ? heading : lineCore.listTitle,
          null,
          'line',
          BaseDetailType.pushedDetail,
          lineCore,
          EditableType.never
        )
        this.line.singleFieldSelect = true

        const distoffersCore = app.makeCore( 'distoffer');
        distoffersCore.soGet = SOGetDistoffer.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distoffersCore
        )
        distoffersCore.title = heading ? heading : distoffersCore.title
        distoffersCore.listTitle = 'Distoffers'

        distoffersCore.configureForDisplay(parentEntityViewSpec)

        this.distoffers = new DetailEntry(
          heading ? heading : distoffersCore.listTitle,
          null,
          'distoffers',
          BaseDetailType.pushedList,
          distoffersCore,
          EditableType.never
        )

        const distributionreportsCore = app.makeCore( 'distributionreport');
        distributionreportsCore.soGet = SOGetDistributionreport.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionreportsCore
        )
        distributionreportsCore.title = heading ? heading : distributionreportsCore.title
        distributionreportsCore.listTitle = 'Distributionreports'

        distributionreportsCore.configureForDisplay(parentEntityViewSpec)

        this.distributionreports = new DetailEntry(
          heading ? heading : distributionreportsCore.listTitle,
          null,
          'distributionreports',
          BaseDetailType.pushedList,
          distributionreportsCore,
          EditableType.never
        )

        const distributionyearsCore = app.makeCore( 'distributionyear');
        distributionyearsCore.soGet = SOGetDistributionyear.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        distributionyearsCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionyearsCore
        )
        distributionyearsCore.title = heading ? heading : distributionyearsCore.title
        distributionyearsCore.listTitle = 'Distributionyears'

        distributionyearsCore.configureForDisplay(parentEntityViewSpec)

        this.distributionyears = new DetailEntry(
          heading ? heading : distributionyearsCore.listTitle,
          null,
          'distributionyears',
          BaseDetailType.pushedList,
          distributionyearsCore,
          EditableType.never
        )

        const distributionmonthsCore = app.makeCore( 'distributionmonth');
        distributionmonthsCore.soGet = SOGetDistributionmonth.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        distributionmonthsCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthsCore
        )
        distributionmonthsCore.title = heading ? heading : distributionmonthsCore.title
        distributionmonthsCore.listTitle = 'Distributionmonths'

        distributionmonthsCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonths = new DetailEntry(
          heading ? heading : distributionmonthsCore.listTitle,
          null,
          'distributionmonths',
          BaseDetailType.pushedList,
          distributionmonthsCore,
          EditableType.never
        )

        const distributionydaysCore = app.makeCore( 'distributionday');
        distributionydaysCore.soGet = SOGetDistributionday.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        distributionydaysCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionydaysCore
        )
        distributionydaysCore.title = heading ? heading : distributionydaysCore.title
        distributionydaysCore.listTitle = 'Distributionydays'

        distributionydaysCore.configureForDisplay(parentEntityViewSpec)

        this.distributionydays = new DetailEntry(
          heading ? heading : distributionydaysCore.listTitle,
          null,
          'distributionydays',
          BaseDetailType.pushedList,
          distributionydaysCore,
          EditableType.never
        )

        const slotsCore = app.makeCore( 'slot');
        slotsCore.soGet = SOGetSlot.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: new SOGetDistribution(entity.uid),
        });
        slotsCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotsCore
        )
        slotsCore.title = heading ? heading : slotsCore.title
        slotsCore.listTitle = 'Slots'

        slotsCore.configureForDisplay(parentEntityViewSpec)

        this.slots = new DetailEntry(
          heading ? heading : slotsCore.listTitle,
          null,
          'slots',
          BaseDetailType.pushedList,
          slotsCore,
          EditableType.never
        )

        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.never
        )

        const serviceRequestsCore = app.makeCore( 'serviceRequest');
        serviceRequestsCore.soGet = SOGetServiceRequest.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            serviceRequestsCore
        )
        serviceRequestsCore.title = heading ? heading : serviceRequestsCore.title
        serviceRequestsCore.listTitle = 'Service requests'

        serviceRequestsCore.configureForDisplay(parentEntityViewSpec)

        this.serviceRequests = new DetailEntry(
          heading ? heading : serviceRequestsCore.listTitle,
          null,
          'serviceRequests',
          BaseDetailType.embeddedList,
          serviceRequestsCore,
          EditableType.never
        )

        const calendarsCore = app.makeCore( 'calendar');
        calendarsCore.soGet = SOGetCalendar.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            calendarsCore
        )
        calendarsCore.title = heading ? heading : calendarsCore.title
        calendarsCore.listTitle = 'Calendars'

        calendarsCore.configureForDisplay(parentEntityViewSpec)

        this.calendars = new DetailEntry(
          heading ? heading : calendarsCore.listTitle,
          null,
          'calendars',
          BaseDetailType.embeddedList,
          calendarsCore,
          EditableType.never
        )

        const accountsCore = app.makeCore( 'account');
        accountsCore.soGet = SOGetAccount.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountsCore
        )
        accountsCore.title = heading ? heading : accountsCore.title
        accountsCore.listTitle = 'Accounts'

        accountsCore.configureForDisplay(parentEntityViewSpec)

        this.accounts = new DetailEntry(
          heading ? heading : accountsCore.listTitle,
          null,
          'accounts',
          BaseDetailType.pushedList,
          accountsCore,
          EditableType.never
        )

        const monthclosesCore = app.makeCore( 'monthclose');
        monthclosesCore.soGet = SOGetMonthclose.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            monthclosesCore
        )
        monthclosesCore.title = heading ? heading : monthclosesCore.title
        monthclosesCore.listTitle = 'Monthcloses'

        monthclosesCore.configureForDisplay(parentEntityViewSpec)

        this.monthcloses = new DetailEntry(
          heading ? heading : monthclosesCore.listTitle,
          null,
          'monthcloses',
          BaseDetailType.pushedList,
          monthclosesCore,
          EditableType.never
        )

        const temptractsCore = app.makeCore( 'temptract');
        temptractsCore.soGet = SOGetTemptract.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            temptractsCore
        )
        temptractsCore.title = heading ? heading : temptractsCore.title
        temptractsCore.listTitle = 'Temptracts'

        temptractsCore.configureForDisplay(parentEntityViewSpec)

        this.temptracts = new DetailEntry(
          heading ? heading : temptractsCore.listTitle,
          null,
          'temptracts',
          BaseDetailType.pushedList,
          temptractsCore,
          EditableType.never
        )

        const tempclinsCore = app.makeCore( 'tempclin');
        tempclinsCore.soGet = SOGetTempclin.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempclinsCore
        )
        tempclinsCore.title = heading ? heading : tempclinsCore.title
        tempclinsCore.listTitle = 'Tempclins'

        tempclinsCore.configureForDisplay(parentEntityViewSpec)

        this.tempclins = new DetailEntry(
          heading ? heading : tempclinsCore.listTitle,
          null,
          'tempclins',
          BaseDetailType.pushedList,
          tempclinsCore,
          EditableType.never
        )

        const rolesCore = app.makeCore( 'role');
        rolesCore.soGet = SOGetRole.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rolesCore
        )
        rolesCore.title = heading ? heading : rolesCore.title
        rolesCore.listTitle = 'Roles'

        rolesCore.configureForDisplay(parentEntityViewSpec)

        this.roles = new DetailEntry(
          heading ? heading : rolesCore.listTitle,
          null,
          'roles',
          BaseDetailType.pushedList,
          rolesCore,
          EditableType.never
        )

        const publicationsCore = app.makeCore( 'publication');
        publicationsCore.soGet = SOGetPublication.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            publicationsCore
        )
        publicationsCore.title = heading ? heading : publicationsCore.title
        publicationsCore.listTitle = 'Publications'

        publicationsCore.configureForDisplay(parentEntityViewSpec)

        this.publications = new DetailEntry(
          heading ? heading : publicationsCore.listTitle,
          null,
          'publications',
          BaseDetailType.pushedList,
          publicationsCore,
          EditableType.never
        )

        const faqsCore = app.makeCore( 'faq');
        faqsCore.soGet = SOGetFaq.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            faqsCore
        )
        faqsCore.title = heading ? heading : faqsCore.title
        faqsCore.listTitle = 'Faqs'

        faqsCore.configureForDisplay(parentEntityViewSpec)

        this.faqs = new DetailEntry(
          heading ? heading : faqsCore.listTitle,
          null,
          'faqs',
          BaseDetailType.embeddedList,
          faqsCore,
          EditableType.never
        )

        const indexpricesCore = app.makeCore( 'indexprice');
        indexpricesCore.soGet = SOGetIndexprice.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            indexpricesCore
        )
        indexpricesCore.title = heading ? heading : indexpricesCore.title
        indexpricesCore.listTitle = 'Indexprices'

        indexpricesCore.configureForDisplay(parentEntityViewSpec)

        this.indexprices = new DetailEntry(
          heading ? heading : indexpricesCore.listTitle,
          null,
          'indexprices',
          BaseDetailType.pushedList,
          indexpricesCore,
          EditableType.never
        )

        const checklistsCore = app.makeCore( 'checklist');
        checklistsCore.soGet = SOGetChecklist.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            checklistsCore
        )
        checklistsCore.title = heading ? heading : checklistsCore.title
        checklistsCore.listTitle = 'Checklists'

        checklistsCore.configureForDisplay(parentEntityViewSpec)

        this.checklists = new DetailEntry(
          heading ? heading : checklistsCore.listTitle,
          null,
          'checklists',
          BaseDetailType.pushedList,
          checklistsCore,
          EditableType.never
        )

        const taskreportsCore = app.makeCore( 'taskreport');
        taskreportsCore.soGet = SOGetTaskreport.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            taskreportsCore
        )
        taskreportsCore.title = heading ? heading : taskreportsCore.title
        taskreportsCore.listTitle = 'Taskreports'

        taskreportsCore.configureForDisplay(parentEntityViewSpec)

        this.taskreports = new DetailEntry(
          heading ? heading : taskreportsCore.listTitle,
          null,
          'taskreports',
          BaseDetailType.pushedList,
          taskreportsCore,
          EditableType.never
        )

        const feeschedulesCore = app.makeCore( 'feeschedule');
        feeschedulesCore.soGet = SOGetFeeschedule.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feeschedulesCore
        )
        feeschedulesCore.title = heading ? heading : feeschedulesCore.title
        feeschedulesCore.listTitle = 'Feeschedules'

        feeschedulesCore.configureForDisplay(parentEntityViewSpec)

        this.feeschedules = new DetailEntry(
          heading ? heading : feeschedulesCore.listTitle,
          null,
          'feeschedules',
          BaseDetailType.pushedList,
          feeschedulesCore,
          EditableType.never
        )

        const containersessionsCore = app.makeCore( 'containersession');
        containersessionsCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: new SOGetDistribution(entity.uid),
        });
        containersessionsCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionsCore
        )
        containersessionsCore.title = heading ? heading : containersessionsCore.title
        containersessionsCore.listTitle = 'Containersessions'

        containersessionsCore.configureForDisplay(parentEntityViewSpec)

        this.containersessions = new DetailEntry(
          heading ? heading : containersessionsCore.listTitle,
          null,
          'containersessions',
          BaseDetailType.pushedList,
          containersessionsCore,
          EditableType.never
        )

        const disttempagreementsCore = app.makeCore( 'disttempagreement');
        disttempagreementsCore.soGet = SOGetDisttempagreement.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            disttempagreementsCore
        )
        disttempagreementsCore.title = heading ? heading : disttempagreementsCore.title
        disttempagreementsCore.listTitle = 'Disttempagreements'

        disttempagreementsCore.configureForDisplay(parentEntityViewSpec)

        this.disttempagreements = new DetailEntry(
          heading ? heading : disttempagreementsCore.listTitle,
          null,
          'disttempagreements',
          BaseDetailType.pushedList,
          disttempagreementsCore,
          EditableType.never
        )

        const salesCore = app.makeCore( 'sale');
        salesCore.soGet = SOGetSale.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            salesCore
        )
        salesCore.title = heading ? heading : salesCore.title
        salesCore.listTitle = 'Sales'

        salesCore.configureForDisplay(parentEntityViewSpec)

        this.sales = new DetailEntry(
          heading ? heading : salesCore.listTitle,
          null,
          'sales',
          BaseDetailType.pushedList,
          salesCore,
          EditableType.never
        )

        const featureascsCore = app.makeCore( 'featureasc');
        featureascsCore.soGet = SOGetFeatureasc.construct({
          distribution: new SOGetDistribution(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            featureascsCore
        )
        featureascsCore.title = heading ? heading : featureascsCore.title
        featureascsCore.listTitle = 'Featureascs'

        featureascsCore.configureForDisplay(parentEntityViewSpec)

        this.featureascs = new DetailEntry(
          heading ? heading : featureascsCore.listTitle,
          null,
          'featureascs',
          BaseDetailType.pushedList,
          featureascsCore,
          EditableType.never
        )
    }
}