import {SOPutDcs} from '../../core/dcs/so-put-dcs';
import {DcsCoreBase} from './dcs.core.base';
import {SOGetDcs} from '../../core/dcs/so-get-dcs';
import {FormField, SelectionOption} from 'lionheartland';
import {putDcsFormFieldOptions} from './put-dcs-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {LineCore} from '../line/line.core';
import {SOGetLine} from '../../core/line/so-get-line';
import {TagCore} from '../tag/tag.core';
import {SOGetTag} from '../../core/tag/so-get-tag';

export class RAPutDcs extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putDcsFormFieldOptions);

  RequestClass = SOPutDcs

  constructor(
    public entityCore: DcsCoreBase
  ) {
    super();
    if (putDcsFormFieldOptions.line) {
      putDcsFormFieldOptions.line.makeCore = true;
    }
    if (putDcsFormFieldOptions.tags) {
      putDcsFormFieldOptions.tags.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('endpoint'),
      this.getFormValueFromIdentifier(
        'line',
        SOGetLine,
      ),
      [this.getFormValueFromIdentifier('tags', SOGetTag)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}