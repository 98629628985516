import {Component} from '@angular/core';
import {ActiveUserService, UserLoaded} from 'lionheartland';
import 'reflect-metadata';
import {GasDirectApp} from "./land-app";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements UserLoaded {

  id = 'appComponent';

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    activeUserService.initialize(new GasDirectApp());
    this.activeUserService.registerUserLoadedView(this);
  }

  userLoaded() {
  }

  userUnloaded() {
  }
}