import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOFeescheduleService} from '../../core/feeschedule/eo-feeschedule';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {RAPutFeeschedule} from './ra-put-feeschedule';
import {RAPostFeeschedule} from './ra-post-feeschedule';
import {RADeleteFeeschedule} from './ra-delete-feeschedule';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution'
import { CEFeeschedule } from "./ce-feeschedule"


export class FeescheduleCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'feeschedule';
  title = 'Feeschedule';
  ceProtocol: CEConstructor = CEFeeschedule

  entityService: EOFeescheduleService;
  soGetClass = SOGetFeeschedule;
  soGet: SOGetFeeschedule;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOFeescheduleService
    );
    this.adapters = [
      new RAPostFeeschedule(this),
      new RAPutFeeschedule(this),
      new RADeleteFeeschedule(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetFeeschedule();
    if (this.activeUserService.getScope('distribution')) {
        this.soGet.distribution = new SOGetDistribution(
          this.activeUserService.getScope('distribution').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}