import {SORespActivity} from '../../core/activity/so-resp-activity';
import {ActivityCore} from './activity.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutActivityFormFields} from './put-activity-form-fields';
import {SOGetActivity} from '../../core/activity/so-get-activity';
import {SOGetMessage} from '../../core/message/so-get-message';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEActivityAttributesBase {

    messages: DetailEntry
    fixmeUser: DetailEntry
    type: DetailEntry
    summary: DetailEntry
    body: DetailEntry
    dateCreated: DetailEntry
    order: DetailEntry
    load: DetailEntry
    user: DetailEntry
    containersession: DetailEntry
    slot: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespActivity,
        app: GasDirectApp,
        formFields: PutActivityFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.fixmeUser = new DetailEntry(
            'Fixme user',
            entity.fixmeUser,
            'fixmeUser',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.summary = new DetailEntry(
            'Summary',
            entity.summary,
            'summary',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.summary.listHeadingIndex = 0;

        this.body = new DetailEntry(
            'Body',
            entity.body,
            'body',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.body
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.dateCreated.listTrailing = true;


        const messagesCore = app.makeCore( 'message');
        messagesCore.soGet = SOGetMessage.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            messagesCore
        )
        messagesCore.title = heading ? heading : messagesCore.title
        messagesCore.listTitle = 'Messages'

        messagesCore.configureForDisplay(parentEntityViewSpec)

        this.messages = new DetailEntry(
          heading ? heading : messagesCore.listTitle,
          null,
          'messages',
          BaseDetailType.embeddedList,
          messagesCore,
          EditableType.never
        )

        const orderCore = app.makeCore( 'order');
        orderCore.soGet = SOGetOrder.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreement: app.getScope('agreement') ? new SOGetAgreement(app.getScope('agreement').uid) : null,
          agreemonth: app.getScope('agreemonth') ? new SOGetAgreemonth(app.getScope('agreemonth').uid) : null,
          distributionmonth: app.getScope('distributionmonth') ? new SOGetDistributionmonth(app.getScope('distributionmonth').uid) : null,
        });
        let orderCeProtocol;
        if (entity.order) {
            orderCeProtocol = createCEProtocol(
                orderCore.ceProtocol,
                parentEntityViewSpec,
                orderCore,
                entity.order,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            orderCore
        )
        orderCore.title = heading ? heading : orderCore.title
        orderCore.listTitle = 'Order'

        if (entity.order) {
            displayStringFactory.reconfigure(
                entity.order,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        orderCore.configureForDisplay(parentEntityViewSpec)

        this.order = new DetailEntry(
          heading ? heading : orderCore.listTitle,
          orderCeProtocol ? orderCeProtocol.entityListView.fullHeading : null,
          'order',
          BaseDetailType.pushedDetail,
          orderCore,
          EditableType.never
        )
        if (entity.order) {
          this.order.ceProtocol = orderCeProtocol;
          this.order.cachedListEntry = orderCore.makeListEntry(parentEntityViewSpec, entity.order, 0)
          orderCore.selectedEntity = entity.order;
        }
        this.order.singleFieldSelect = true

        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        let loadCeProtocol;
        if (entity.load) {
            loadCeProtocol = createCEProtocol(
                loadCore.ceProtocol,
                parentEntityViewSpec,
                loadCore,
                entity.load,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        if (entity.load) {
            displayStringFactory.reconfigure(
                entity.load,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          loadCeProtocol ? loadCeProtocol.entityListView.fullHeading : null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.never
        )
        if (entity.load) {
          this.load.ceProtocol = loadCeProtocol;
          this.load.cachedListEntry = loadCore.makeListEntry(parentEntityViewSpec, entity.load, 0)
          loadCore.selectedEntity = entity.load;
        }
        this.load.singleFieldSelect = true

        const userCore = app.makeCore( 'user');
        userCore.soGet = SOGetUser.construct({
        });
        userCore.soGet.queryOptions.sortKey = 'surname';
        let userCeProtocol;
        if (entity.user) {
            userCeProtocol = createCEProtocol(
                userCore.ceProtocol,
                parentEntityViewSpec,
                userCore,
                entity.user,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            userCore
        )
        userCore.title = heading ? heading : userCore.title
        userCore.listTitle = 'User'

        if (entity.user) {
            displayStringFactory.reconfigure(
                entity.user,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        userCore.configureForDisplay(parentEntityViewSpec)

        this.user = new DetailEntry(
          heading ? heading : userCore.listTitle,
          userCeProtocol ? userCeProtocol.entityListView.fullHeading : null,
          'user',
          BaseDetailType.pushedDetail,
          userCore,
          EditableType.never
        )
        if (entity.user) {
          this.user.ceProtocol = userCeProtocol;
          this.user.cachedListEntry = userCore.makeListEntry(parentEntityViewSpec, entity.user, 0)
          userCore.selectedEntity = entity.user;
        }
        this.user.singleFieldSelect = true
        this.user.listSubheadingIndex = 0;

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        let containersessionCeProtocol;
        if (entity.containersession) {
            containersessionCeProtocol = createCEProtocol(
                containersessionCore.ceProtocol,
                parentEntityViewSpec,
                containersessionCore,
                entity.containersession,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        if (entity.containersession) {
            displayStringFactory.reconfigure(
                entity.containersession,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          containersessionCeProtocol ? containersessionCeProtocol.entityListView.fullHeading : null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        if (entity.containersession) {
          this.containersession.ceProtocol = containersessionCeProtocol;
          this.containersession.cachedListEntry = containersessionCore.makeListEntry(parentEntityViewSpec, entity.containersession, 0)
          containersessionCore.selectedEntity = entity.containersession;
        }
        this.containersession.singleFieldSelect = true

        const slotCore = app.makeCore( 'slot');
        slotCore.soGet = SOGetSlot.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        slotCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotCore
        )
        slotCore.title = heading ? heading : slotCore.title
        slotCore.listTitle = 'Slot'

        slotCore.configureForDisplay(parentEntityViewSpec)

        this.slot = new DetailEntry(
          heading ? heading : slotCore.listTitle,
          null,
          'slot',
          BaseDetailType.pushedDetail,
          slotCore,
          EditableType.never
        )
        this.slot.singleFieldSelect = true
    }
}