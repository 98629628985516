import {SORespFeature} from '../../core/feature/so-resp-feature';
import {FeatureCore} from './feature.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeatureFormFields} from './put-feature-form-fields';
import { CEFeatureAttributes } from './ce-feature-attributes'


export class CEFeatureBase extends CEBase {

    public details: CEFeatureAttributes;
    public fromFields: PutFeatureFormFields = new PutFeatureFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: FeatureCore,
        public entity: SORespFeature,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEFeatureAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.component)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.featureascs)

    }
}