import { Type } from 'class-transformer';
import { SORespNavi } from '../navi/so-resp-navi';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespNavroa implements ObjectiveProtocol {

  entityType: string = 'navroa';
  uid: string;
  index: number = null;
  mainNav: boolean = null;
  @Type(() => SORespNavi)
  navi: SORespNavi;
}