import {SOPostTagreport} from '../../core/tagreport/so-post-tagreport';
import {TagreportCoreBase} from './tagreport.core.base';
import {SOGetTagreport} from '../../core/tagreport/so-get-tagreport';
import {FormField, SelectionOption} from 'lionheartland';
import {postTagreportFormFieldOptions} from './post-tagreport-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostTagreport extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTagreportFormFieldOptions);

  RequestClass = SOPostTagreport

  constructor(
    public entityCore: TagreportCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('value'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}