import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOBacklogitemService} from '../../core/backlogitem/eo-backlogitem';
import {SOGetBacklogitem} from '../../core/backlogitem/so-get-backlogitem';
import {RAPutBacklogitem} from './ra-put-backlogitem';
import {RAPostBacklogitem} from './ra-post-backlogitem';
import {RADeleteBacklogitem} from './ra-delete-backlogitem';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEBacklogitem } from "./ce-backlogitem"


export class BacklogitemCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'backlogitem';
  title = 'Backlogitem';
  ceProtocol: CEConstructor = CEBacklogitem

  entityService: EOBacklogitemService;
  soGetClass = SOGetBacklogitem;
  soGet: SOGetBacklogitem;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOBacklogitemService
    );
    this.adapters = [
      new RAPostBacklogitem(this),
      new RAPutBacklogitem(this),
      new RADeleteBacklogitem(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBacklogitem();
    this.soGet.queryOptions.limit = this.limit;
  }


}