import { Type } from 'class-transformer';
import { SORespDistributionmonth } from '../distributionmonth/so-resp-distributionmonth';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespSale implements ObjectiveProtocol {

  entityType: string = 'sale';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  status: string = "pending";
  description: string = "";
  @Type(() => SORespDistributionmonth)
  distributionmonth: SORespDistributionmonth;
}