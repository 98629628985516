import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOBulkService} from '../../core/bulk/eo-bulk';
import {SOGetBulk} from '../../core/bulk/so-get-bulk';
import {RAPutBulk} from './ra-put-bulk';
import {RAPostBulk} from './ra-post-bulk';
import {RADeleteBulk} from './ra-delete-bulk';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEBulk } from "./ce-bulk"


export class BulkCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'bulk';
  title = 'Bulk';
  ceProtocol: CEConstructor = CEBulk

  entityService: EOBulkService;
  soGetClass = SOGetBulk;
  soGet: SOGetBulk;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOBulkService
    );
    this.adapters = [
      new RAPostBulk(this),
      new RAPutBulk(this),
      new RADeleteBulk(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBulk();
    this.soGet.queryOptions.limit = this.limit;
  }


}