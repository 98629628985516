import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutAdjustmentFormFields {

    public quantity: FormField
    public description: FormField

    constructor() {

        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )

    }
}

const fields = new PutAdjustmentFormFields()

export const putAdjustmentFormFieldOptions: {[key: string]: FormField} = {
  quantity: fields.quantity,
  description: fields.description,
};