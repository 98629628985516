import {SORespClinyearlng} from '../../core/clinyearlng/so-resp-clinyearlng';
import {ClinyearlngCore} from './clinyearlng.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutClinyearlngFormFields} from './put-clinyearlng-form-fields';
import { CEClinyearlngAttributes } from './ce-clinyearlng-attributes'


export class CEClinyearlngBase extends CEBase {

    public details: CEClinyearlngAttributes;
    public fromFields: PutClinyearlngFormFields = new PutClinyearlngFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ClinyearlngCore,
        public entity: SORespClinyearlng,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEClinyearlngAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.period)
        this.attributes.push(this.details.periodNumber)
        this.attributes.push(this.details.volumeTier1)
        this.attributes.push(this.details.priceTier1)
        this.attributes.push(this.details.volumeTier2)
        this.attributes.push(this.details.priceTier2)
        this.attributes.push(this.details.clins)
        this.attributes.push(this.details.contract)

    }
}