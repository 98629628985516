import { Type } from 'class-transformer';
import { SORespDistributionmonth } from '../distributionmonth/so-resp-distributionmonth';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDistributionday implements ObjectiveProtocol {

  entityType: string = 'distributionday';
  uid: string;
  state: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  day: number;
  @Type(() => SORespDistributionmonth)
  distributionmonth: SORespDistributionmonth;
  netHeliumTransfer: number;
  netHeliumTransferBillable: number;
  netHeliumTransferNonBillable: number;
  netLinTransfer: number;
  dewarsLevelInitial: number;
  dewarsLevelFinal: number;
  dewarDelta: number;
  netWithDewarDelta: number;
  netWithDewarDeltaKscf: number;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
}