import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutLinag } from './so-put-linag';
import { SOGetLinag } from './so-get-linag';
import { SOPostLinag } from './so-post-linag';
import { SODeleteLinag } from './so-delete-linag';
import {SORespLinag} from './so-resp-linag';
import {SOPluralLinag} from './so-plural-linag';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOLinagService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'linag',
      entityCore
    );
  }

  /** GET Linag by id. Will 404 if id not found */
  get(request: SOGetLinag): Observable<SOPluralLinag> {
    return this.httpClient.get<SOPluralLinag>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralLinag, resp) }),
      tap(_ => this.log(`fetched Linag`)),
      catchError(this.handleError<SOPluralLinag>(`get Linag`))
    );
  }

  /** POST: add a new Linag to the server */
  post(request: SOPostLinag): Observable<SORespLinag> {
    return this.httpClient.post<SORespLinag>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLinag, resp) }),
      tap((response: SORespLinag) => this.log(`added Linag w/ id=${response.uid}`)),
      catchError(this.handleError<SORespLinag>('Linag post'))
    );
  }

  /** PUT: update Linag  */
  put(request: SOPutLinag): Observable<SORespLinag> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespLinag>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLinag, resp) }),
      tap((response: SORespLinag) => this.log(`edited Linag w/ uid=${uid}`)),
      catchError(this.handleError<SORespLinag>('Linag put'))
    );
  }

  /** DELETE: delete the Linag from the server */
  delete(request: SODeleteLinag): Observable<SORespLinag> {
    return this.httpClient.delete<SORespLinag>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Linag uid=${request.uid}`)),
      catchError(this.handleError<SORespLinag>('delete Linag'))
    );
  }
}
