import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutAgreemonthFormFields {

    public state: FormField
    public mcq: FormField
    public delta: FormField
    public hmonth: FormField
    public agreement: FormField
    public agreeyear: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )
        this.mcq = new FormField(
          'mcq',
          'Mcq',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.delta = new FormField(
          'delta',
          'Delta',
          null,
          FormFieldType.text,
          false,
        )
        this.hmonth = new FormField(
          'hmonth',
          'Hmonth',
          'hmonth',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.agreement = new FormField(
          'agreement',
          'Agreement',
          'agreement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.agreeyear = new FormField(
          'agreeyear',
          'Agreeyear',
          'agreeyear',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutAgreemonthFormFields()

export const putAgreemonthFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  mcq: fields.mcq,
  delta: fields.delta,
  hmonth: fields.hmonth,
  agreement: fields.agreement,
  agreeyear: fields.agreeyear,
};