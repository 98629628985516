import {SORespDowntime} from '../../core/downtime/so-resp-downtime';
import {DowntimeCore} from './downtime.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDowntimeFormFields} from './put-downtime-form-fields';
import {SOGetDowntime} from '../../core/downtime/so-get-downtime';
import {SOGetAdjustment} from '../../core/adjustment/so-get-adjustment';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEDowntimeAttributesBase {

    adjustments: DetailEntry
    description: DetailEntry
    start: DetailEntry
    end: DetailEntry
    distributionmonth: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDowntime,
        app: GasDirectApp,
        formFields: PutDowntimeFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )


        const adjustmentsCore = app.makeCore( 'adjustment');
        adjustmentsCore.soGet = SOGetAdjustment.construct({
          downtime: new SOGetDowntime(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            adjustmentsCore
        )
        adjustmentsCore.title = heading ? heading : adjustmentsCore.title
        adjustmentsCore.listTitle = 'Adjustments'

        adjustmentsCore.configureForDisplay(parentEntityViewSpec)

        this.adjustments = new DetailEntry(
          heading ? heading : adjustmentsCore.listTitle,
          null,
          'adjustments',
          BaseDetailType.embeddedList,
          adjustmentsCore,
          EditableType.never
        )

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true
    }
}