import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOContainersessionService} from '../../core/containersession/eo-containersession';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {RAPutContainersession} from './ra-put-containersession';
import {RAPostContainersession} from './ra-post-containersession';
import {RADeleteContainersession} from './ra-delete-containersession';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import {SOGetDistribution} from '../../core/distribution/so-get-distribution'
import { CEContainersession } from "./ce-containersession"


export class ContainersessionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'containersession';
  title = 'Containersession';
  ceProtocol: CEConstructor = CEContainersession

  entityService: EOContainersessionService;
  soGetClass = SOGetContainersession;
  soGet: SOGetContainersession;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOContainersessionService
    );
    this.adapters = [
      new RAPostContainersession(this),
      new RAPutContainersession(this),
      new RADeleteContainersession(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetContainersession();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    if (this.activeUserService.getScope('distribution')) {
        this.soGet.distribution = new SOGetDistribution(
          this.activeUserService.getScope('distribution').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'arrived';
  }


}