import { SOGetSlot } from '../slot/so-get-slot';
import { SOGetLoad } from '../load/so-get-load';
import { SOGetAccount } from '../account/so-get-account';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetReservation implements QueryProtocol {

  constructor(
    public uid: string = null,
    public slot: SOGetSlot = null,
    public load: SOGetLoad = null,
    public account: SOGetAccount = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetReservation(
      'uid' in params ? params.uid : null,
      'slot' in params ? params.slot : null,
      'load' in params ? params.load : null,
      'account' in params ? params.account : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
