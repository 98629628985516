import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutInreport } from './so-put-inreport';
import { SOGetInreport } from './so-get-inreport';
import { SOPostInreport } from './so-post-inreport';
import { SODeleteInreport } from './so-delete-inreport';
import {SORespInreport} from './so-resp-inreport';
import {SOPluralInreport} from './so-plural-inreport';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOInreportService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'inreport',
      entityCore
    );
  }

  /** GET Inreport by id. Will 404 if id not found */
  get(request: SOGetInreport): Observable<SOPluralInreport> {
    return this.httpClient.get<SOPluralInreport>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralInreport, resp) }),
      tap(_ => this.log(`fetched Inreport`)),
      catchError(this.handleError<SOPluralInreport>(`get Inreport`))
    );
  }

  /** POST: add a new Inreport to the server */
  post(request: SOPostInreport): Observable<SORespInreport> {
    return this.httpClient.post<SORespInreport>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInreport, resp) }),
      tap((response: SORespInreport) => this.log(`added Inreport w/ id=${response.uid}`)),
      catchError(this.handleError<SORespInreport>('Inreport post'))
    );
  }

  /** PUT: update Inreport  */
  put(request: SOPutInreport): Observable<SORespInreport> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespInreport>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInreport, resp) }),
      tap((response: SORespInreport) => this.log(`edited Inreport w/ uid=${uid}`)),
      catchError(this.handleError<SORespInreport>('Inreport put'))
    );
  }

  /** DELETE: delete the Inreport from the server */
  delete(request: SODeleteInreport): Observable<SORespInreport> {
    return this.httpClient.delete<SORespInreport>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Inreport uid=${request.uid}`)),
      catchError(this.handleError<SORespInreport>('delete Inreport'))
    );
  }
}
