import {SORespDisttempagreement} from '../../core/disttempagreement/so-resp-disttempagreement';
import {DisttempagreementCore} from './disttempagreement.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDisttempagreementFormFields} from './put-disttempagreement-form-fields';
import {SOGetDisttempagreement} from '../../core/disttempagreement/so-get-disttempagreement';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';


export class CEDisttempagreementAttributesBase {

    isDefault: DetailEntry
    distribution: DetailEntry
    tempagreement: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDisttempagreement,
        app: GasDirectApp,
        formFields: PutDisttempagreementFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.isDefault = new DetailEntry(
            'Is default',
            entity.isDefault,
            'isDefault',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.isDefault
        )


        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const tempagreementCore = app.makeCore( 'tempagreement');
        tempagreementCore.soGet = SOGetTempagreement.construct({
        });
        let tempagreementCeProtocol;
        if (entity.tempagreement) {
            tempagreementCeProtocol = createCEProtocol(
                tempagreementCore.ceProtocol,
                parentEntityViewSpec,
                tempagreementCore,
                entity.tempagreement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempagreementCore
        )
        tempagreementCore.title = heading ? heading : tempagreementCore.title
        tempagreementCore.listTitle = 'Tempagreement'

        if (entity.tempagreement) {
            displayStringFactory.reconfigure(
                entity.tempagreement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        tempagreementCore.configureForDisplay(parentEntityViewSpec)

        this.tempagreement = new DetailEntry(
          heading ? heading : tempagreementCore.listTitle,
          tempagreementCeProtocol ? tempagreementCeProtocol.entityListView.fullHeading : null,
          'tempagreement',
          BaseDetailType.pushedDetail,
          tempagreementCore,
          EditableType.always,
          formFields.tempagreement
        )
        if (entity.tempagreement) {
          this.tempagreement.ceProtocol = tempagreementCeProtocol;
          this.tempagreement.cachedListEntry = tempagreementCore.makeListEntry(parentEntityViewSpec, entity.tempagreement, 0)
          tempagreementCore.selectedEntity = entity.tempagreement;
        }
        this.tempagreement.singleFieldSelect = true
    }
}