import { SOGetTempagreement } from '../tempagreement/so-get-tempagreement';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetFeeschedule } from '../feeschedule/so-get-feeschedule';
import { SOGetAward } from '../award/so-get-award';


export class SOPostAgreement {

  constructor(
    public name: string,
    public units: string,
    public state: string,
    public start: Date,
    public end: Date,
    public account: SOGetAccount,
    public description: string = "",
    public docusignLink: string = "",
    public autoRenew: boolean = false,
    public tempagreement: SOGetTempagreement = null,
    public acq: number = null,
    public top: number = null,
    public feeschedule: SOGetFeeschedule = null,
    public variableAcq: boolean = null,
    public rateable: boolean = null,
    public interruptible: boolean = null,
    public spotQuantity: number = null,
    public award: SOGetAward = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAgreement(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'docusignLink' in params ? params.docusignLink : null,
      'units' in params ? params.units : null,
      'state' in params ? params.state : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'autoRenew' in params ? params.autoRenew : null,
      'tempagreement' in params ? params.tempagreement : null,
      'account' in params ? params.account : null,
      'acq' in params ? params.acq : null,
      'top' in params ? params.top : null,
      'feeschedule' in params ? params.feeschedule : null,
      'variableAcq' in params ? params.variableAcq : null,
      'rateable' in params ? params.rateable : null,
      'interruptible' in params ? params.interruptible : null,
      'spotQuantity' in params ? params.spotQuantity : null,
      'award' in params ? params.award : null,
    );
  }
}
