import {SOPutChecklist} from '../../core/checklist/so-put-checklist';
import {ChecklistCoreBase} from './checklist.core.base';
import {SOGetChecklist} from '../../core/checklist/so-get-checklist';
import {FormField, SelectionOption} from 'lionheartland';
import {putChecklistFormFieldOptions} from './put-checklist-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {ChecklistitemCore} from '../checklistitem/checklistitem.core';
import {SOGetChecklistitem} from '../../core/checklistitem/so-get-checklistitem';

export class RAPutChecklist extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putChecklistFormFieldOptions);

  RequestClass = SOPutChecklist

  constructor(
    public entityCore: ChecklistCoreBase
  ) {
    super();
    if (putChecklistFormFieldOptions.load) {
      putChecklistFormFieldOptions.load.makeCore = true;
    }
    if (putChecklistFormFieldOptions.distribution) {
      putChecklistFormFieldOptions.distribution.makeCore = true;
    }
    if (putChecklistFormFieldOptions.items) {
      putChecklistFormFieldOptions.items.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('body'),
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
      this.getFormValueFromIdentifier('fileLink'),
      [this.getFormValueFromIdentifier('items', SOGetChecklistitem)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}