import { Type } from 'class-transformer';
import { SORespRelease } from '../release/so-resp-release';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespBacklogitem implements ObjectiveProtocol {

  entityType: string = 'backlogitem';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  type: string;
  state: string;
  summary: string;
  details: string;
  @Type(() => SORespRelease)
  release: SORespRelease;
}