import {SORespBol} from '../../core/bol/so-resp-bol';
import {BolCore} from './bol.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutBolFormFields} from './put-bol-form-fields';
import {SOGetBol} from '../../core/bol/so-get-bol';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEBolAttributesBase {

    dateCreated: DetailEntry
    dateSigned: DetailEntry
    body: DetailEntry
    carrierSignature: DetailEntry
    providerSignature: DetailEntry
    driverName: DetailEntry
    destination: DetailEntry
    load: DetailEntry
    containersession: DetailEntry
    carrier: DetailEntry
    rearAxelWeight: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespBol,
        app: GasDirectApp,
        formFields: PutBolFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dateSigned = new DetailEntry(
            'Date signed',
            app.activeUserService.time.dateAsString(entity.dateSigned),
            'dateSigned',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.body = new DetailEntry(
            'Body',
            entity.body,
            'body',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.carrierSignature = new DetailEntry(
            'Carrier signature',
            entity.carrierSignature,
            'carrierSignature',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.carrierSignature
        )

        this.providerSignature = new DetailEntry(
            'Provider signature',
            entity.providerSignature,
            'providerSignature',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.providerSignature
        )

        this.driverName = new DetailEntry(
            'Driver name',
            entity.driverName,
            'driverName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.driverName
        )

        this.destination = new DetailEntry(
            'Destination',
            entity.destination,
            'destination',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.destination
        )

        this.rearAxelWeight = new DetailEntry(
            'Rear axel weight',
            entity.rearAxelWeight,
            'rearAxelWeight',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.rearAxelWeight
        )


        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.never
        )
        this.load.singleFieldSelect = true

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        let containersessionCeProtocol;
        if (entity.containersession) {
            containersessionCeProtocol = createCEProtocol(
                containersessionCore.ceProtocol,
                parentEntityViewSpec,
                containersessionCore,
                entity.containersession,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        if (entity.containersession) {
            displayStringFactory.reconfigure(
                entity.containersession,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          containersessionCeProtocol ? containersessionCeProtocol.entityListView.fullHeading : null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        if (entity.containersession) {
          this.containersession.ceProtocol = containersessionCeProtocol;
          this.containersession.cachedListEntry = containersessionCore.makeListEntry(parentEntityViewSpec, entity.containersession, 0)
          containersessionCore.selectedEntity = entity.containersession;
        }
        this.containersession.singleFieldSelect = true

        const carrierCore = app.makeCore( 'organization');
        carrierCore.soGet = SOGetOrganization.construct({
        });
        let carrierCeProtocol;
        if (entity.carrier) {
            carrierCeProtocol = createCEProtocol(
                carrierCore.ceProtocol,
                parentEntityViewSpec,
                carrierCore,
                entity.carrier,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            carrierCore
        )
        carrierCore.title = heading ? heading : carrierCore.title
        carrierCore.listTitle = 'Carrier'

        if (entity.carrier) {
            displayStringFactory.reconfigure(
                entity.carrier,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        carrierCore.configureForDisplay(parentEntityViewSpec)

        this.carrier = new DetailEntry(
          heading ? heading : carrierCore.listTitle,
          carrierCeProtocol ? carrierCeProtocol.entityListView.fullHeading : null,
          'carrier',
          BaseDetailType.pushedDetail,
          carrierCore,
          EditableType.always,
          formFields.carrier
        )
        if (entity.carrier) {
          this.carrier.ceProtocol = carrierCeProtocol;
          this.carrier.cachedListEntry = carrierCore.makeListEntry(parentEntityViewSpec, entity.carrier, 0)
          carrierCore.selectedEntity = entity.carrier;
        }
        this.carrier.singleFieldSelect = true
    }
}