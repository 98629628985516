import { DistributiondayCoreBase } from "./distributionday.core.base"


export class DistributiondayCore extends DistributiondayCoreBase {

    resetSoGet() {
        this.limit = 2;
        super.resetSoGet();
        this.soGet.queryOptions.datetimeStart = new Date();
    }

}