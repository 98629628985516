import {SORespPlant} from '../../core/plant/so-resp-plant';
import {PlantCore} from './plant.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutPlantFormFields} from './put-plant-form-fields';
import { CEPlantAttributes } from './ce-plant-attributes'


export class CEPlantBase extends CEBase {

    public details: CEPlantAttributes;
    public fromFields: PutPlantFormFields = new PutPlantFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: PlantCore,
        public entity: SORespPlant,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEPlantAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.closed)
        this.attributes.push(this.details.message)
        this.attributes.push(this.details.timeZone)
        this.attributes.push(this.details.timeZoneOffset)
        this.attributes.push(this.details.daylightSavingsObserved)
        this.attributes.push(this.details.lines)
        this.attributes.push(this.details.roles)

    }
}