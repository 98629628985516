import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOEventService} from '../../core/event/eo-event';
import {SOGetEvent} from '../../core/event/so-get-event';
import {RAPutEvent} from './ra-put-event';
import {RAPostEvent} from './ra-post-event';
import {RADeleteEvent} from './ra-delete-event';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEEvent } from "./ce-event"


export class EventCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'event';
  title = 'Event';
  ceProtocol: CEConstructor = CEEvent

  entityService: EOEventService;
  soGetClass = SOGetEvent;
  soGet: SOGetEvent;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOEventService
    );
    this.adapters = [
      new RAPostEvent(this),
      new RAPutEvent(this),
      new RADeleteEvent(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEvent();
    this.soGet.queryOptions.limit = this.limit;
  }


}