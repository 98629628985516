

export class SOPostBacklogitem {

  constructor(
    public type: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostBacklogitem(
      'type' in params ? params.type : null,
    );
  }
}
