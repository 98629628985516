import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespHello implements ObjectiveProtocol {

  entityType: string = 'hello';
  uid: string;
  message: string;
  loginStyle: number;
}