import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOHyearService} from '../../core/hyear/eo-hyear';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {RAPutHyear} from './ra-put-hyear';
import {RAPostHyear} from './ra-post-hyear';
import {RADeleteHyear} from './ra-delete-hyear';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import { CEHyear } from "./ce-hyear"


export class HyearCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'hyear';
  title = 'Hyear';
  ceProtocol: CEConstructor = CEHyear

  entityService: EOHyearService;
  soGetClass = SOGetHyear;
  soGet: SOGetHyear;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOHyearService
    );
    this.adapters = [
      new RAPostHyear(this),
      new RAPutHyear(this),
      new RADeleteHyear(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetHyear();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'year';
  }


}