

export class SOPutTaskreport {

  constructor(
    public uid: string,
    public status: string = null,
    public dateRequested: Date = null,
    public dateStart: Date = null,
    public dateEnd: Date = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTaskreport(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
      'dateRequested' in params ? params.dateRequested : null,
      'dateStart' in params ? params.dateStart : null,
      'dateEnd' in params ? params.dateEnd : null,
    );
  }
}
