import {Component, OnInit} from '@angular/core';
import {SvEntityViewComponent} from "lionheartland";
import {ContainersessionCore} from "../../entities/containersession/containersession.core";
import {SlotCore} from "../../entities/slot/slot.core";
import {LoadCore} from "../../entities/load/load.core";

@Component({
    selector: 'app-gas-direct-entity-view',
    templateUrl: './gas-direct-entity-view.component.html',
    styleUrls: ['./gas-direct-entity-view.component.scss']
})
export class GasDirectEntityViewComponent extends SvEntityViewComponent {
    get isBoard(): boolean {

        if (this.entityViewSpec.entityCore.type === 'load') {
            const core = this.entityViewSpec.entityCore as LoadCore;
            return core.board;
        }

        return false
    };

    get isStatus(): boolean {

        if (this.entityViewSpec.entityCore.type === 'load') {
            const core = this.entityViewSpec.entityCore as LoadCore;
            return core.status;
        }

        return false
    };

    get isCal(): boolean {

        if (this.entityViewSpec.entityCore.type === 'slot') {
            const core = this.entityViewSpec.entityCore as SlotCore;
            return core.calendar;
        }

        return false
    };

}
