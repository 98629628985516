import {SORespHyear} from '../../core/hyear/so-resp-hyear';
import {HyearCore} from './hyear.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHyearFormFields} from './put-hyear-form-fields';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEHyearAttributesBase {

    state: DetailEntry
    acq: DetailEntry
    acqUsed: DetailEntry
    year: DetailEntry
    account: DetailEntry
    spotQuantity: DetailEntry
    spotQuantityUsed: DetailEntry
    delta: DetailEntry
    distributionyear: DetailEntry
    feeschedule: DetailEntry
    acqInitial: DetailEntry
    hmonths: DetailEntry
    agreeyears: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespHyear,
        app: GasDirectApp,
        formFields: PutHyearFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.acq = new DetailEntry(
            'Acq',
            entity.acq,
            'acq',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.acqUsed = new DetailEntry(
            'Acq used',
            entity.acqUsed,
            'acqUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.year = new DetailEntry(
            'Year',
            entity.year,
            'year',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.year.listHeadingIndex = 1;

        this.spotQuantity = new DetailEntry(
            'Spot quantity',
            entity.spotQuantity,
            'spotQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.spotQuantityUsed = new DetailEntry(
            'Spot quantity used',
            entity.spotQuantityUsed,
            'spotQuantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.delta = new DetailEntry(
            'Delta',
            entity.delta,
            'delta',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.acqInitial = new DetailEntry(
            'Acq initial',
            entity.acqInitial,
            'acqInitial',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.never
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true
        this.account.listHeadingIndex = 0;

        const distributionyearCore = app.makeCore( 'distributionyear');
        distributionyearCore.soGet = SOGetDistributionyear.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionyearCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionyearCore
        )
        distributionyearCore.title = heading ? heading : distributionyearCore.title
        distributionyearCore.listTitle = 'Distributionyear'

        distributionyearCore.configureForDisplay(parentEntityViewSpec)

        this.distributionyear = new DetailEntry(
          heading ? heading : distributionyearCore.listTitle,
          null,
          'distributionyear',
          BaseDetailType.pushedDetail,
          distributionyearCore,
          EditableType.never
        )
        this.distributionyear.singleFieldSelect = true

        const feescheduleCore = app.makeCore( 'feeschedule');
        feescheduleCore.soGet = SOGetFeeschedule.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let feescheduleCeProtocol;
        if (entity.feeschedule) {
            feescheduleCeProtocol = createCEProtocol(
                feescheduleCore.ceProtocol,
                parentEntityViewSpec,
                feescheduleCore,
                entity.feeschedule,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feescheduleCore
        )
        feescheduleCore.title = heading ? heading : feescheduleCore.title
        feescheduleCore.listTitle = 'Feeschedule'

        if (entity.feeschedule) {
            displayStringFactory.reconfigure(
                entity.feeschedule,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        feescheduleCore.configureForDisplay(parentEntityViewSpec)

        this.feeschedule = new DetailEntry(
          heading ? heading : feescheduleCore.listTitle,
          feescheduleCeProtocol ? feescheduleCeProtocol.entityListView.fullHeading : null,
          'feeschedule',
          BaseDetailType.pushedDetail,
          feescheduleCore,
          EditableType.always,
          formFields.feeschedule
        )
        if (entity.feeschedule) {
          this.feeschedule.ceProtocol = feescheduleCeProtocol;
          this.feeschedule.cachedListEntry = feescheduleCore.makeListEntry(parentEntityViewSpec, entity.feeschedule, 0)
          feescheduleCore.selectedEntity = entity.feeschedule;
        }
        this.feeschedule.singleFieldSelect = true

        const hmonthsCore = app.makeCore( 'hmonth');
        hmonthsCore.soGet = SOGetHmonth.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          hyear: new SOGetHyear(entity.uid),
        });
        hmonthsCore.soGet.queryOptions.sortKey = 'month';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hmonthsCore
        )
        hmonthsCore.title = heading ? heading : hmonthsCore.title
        hmonthsCore.listTitle = 'Hmonths'

        hmonthsCore.configureForDisplay(parentEntityViewSpec)

        this.hmonths = new DetailEntry(
          heading ? heading : hmonthsCore.listTitle,
          null,
          'hmonths',
          BaseDetailType.embeddedList,
          hmonthsCore,
          EditableType.never
        )

        const agreeyearsCore = app.makeCore( 'agreeyear');
        agreeyearsCore.soGet = SOGetAgreeyear.construct({
          hyear: new SOGetHyear(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreeyearsCore
        )
        agreeyearsCore.title = heading ? heading : agreeyearsCore.title
        agreeyearsCore.listTitle = 'Agreeyears'

        agreeyearsCore.configureForDisplay(parentEntityViewSpec)

        this.agreeyears = new DetailEntry(
          heading ? heading : agreeyearsCore.listTitle,
          null,
          'agreeyears',
          BaseDetailType.embeddedList,
          agreeyearsCore,
          EditableType.never
        )
    }
}