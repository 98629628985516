import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespRelease implements ObjectiveProtocol {

  entityType: string = 'release';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  name: string;
  summary: string;
  @Type(() => Date)
  dateScheduled: Date = null;
  @Type(() => Date)
  dateDeployed: Date = null;
  state: string;
  description: string = null;
  message: string = null;
}