import {SORespLine} from '../../core/line/so-resp-line';
import {LineCore} from './line.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutLineFormFields} from './put-line-form-fields';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetPlant} from '../../core/plant/so-get-plant';
import {SOGetProduct} from '../../core/product/so-get-product';
import {SOGetProduction} from '../../core/production/so-get-production';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetDcs} from '../../core/dcs/so-get-dcs';
import {SOGetLinag} from '../../core/linag/so-get-linag';


export class CELineAttributesBase {

    name: DetailEntry
    plant: DetailEntry
    product: DetailEntry
    units: DetailEntry
    production: DetailEntry
    distribution: DetailEntry
    dcss: DetailEntry
    linags: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespLine,
        app: GasDirectApp,
        formFields: PutLineFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.units = new DetailEntry(
            'Units',
            entity.units,
            'units',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.units
        )


        const plantCore = app.makeCore( 'plant');
        plantCore.soGet = SOGetPlant.construct({
        });
        let plantCeProtocol;
        if (entity.plant) {
            plantCeProtocol = createCEProtocol(
                plantCore.ceProtocol,
                parentEntityViewSpec,
                plantCore,
                entity.plant,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            plantCore
        )
        plantCore.title = heading ? heading : plantCore.title
        plantCore.listTitle = 'Plant'

        if (entity.plant) {
            displayStringFactory.reconfigure(
                entity.plant,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        plantCore.configureForDisplay(parentEntityViewSpec)

        this.plant = new DetailEntry(
          heading ? heading : plantCore.listTitle,
          plantCeProtocol ? plantCeProtocol.entityListView.fullHeading : null,
          'plant',
          BaseDetailType.pushedDetail,
          plantCore,
          EditableType.always,
          formFields.plant
        )
        if (entity.plant) {
          this.plant.ceProtocol = plantCeProtocol;
          this.plant.cachedListEntry = plantCore.makeListEntry(parentEntityViewSpec, entity.plant, 0)
          plantCore.selectedEntity = entity.plant;
        }
        this.plant.singleFieldSelect = true

        const productCore = app.makeCore( 'product');
        productCore.soGet = SOGetProduct.construct({
        });
        let productCeProtocol;
        if (entity.product) {
            productCeProtocol = createCEProtocol(
                productCore.ceProtocol,
                parentEntityViewSpec,
                productCore,
                entity.product,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            productCore
        )
        productCore.title = heading ? heading : productCore.title
        productCore.listTitle = 'Product'

        if (entity.product) {
            displayStringFactory.reconfigure(
                entity.product,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        productCore.configureForDisplay(parentEntityViewSpec)

        this.product = new DetailEntry(
          heading ? heading : productCore.listTitle,
          productCeProtocol ? productCeProtocol.entityListView.fullHeading : null,
          'product',
          BaseDetailType.pushedDetail,
          productCore,
          EditableType.always,
          formFields.product
        )
        if (entity.product) {
          this.product.ceProtocol = productCeProtocol;
          this.product.cachedListEntry = productCore.makeListEntry(parentEntityViewSpec, entity.product, 0)
          productCore.selectedEntity = entity.product;
        }
        this.product.singleFieldSelect = true

        const productionCore = app.makeCore( 'production');
        productionCore.soGet = SOGetProduction.construct({
        });
        let productionCeProtocol;
        if (entity.production) {
            productionCeProtocol = createCEProtocol(
                productionCore.ceProtocol,
                parentEntityViewSpec,
                productionCore,
                entity.production,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            productionCore
        )
        productionCore.title = heading ? heading : productionCore.title
        productionCore.listTitle = 'Production'

        if (entity.production) {
            displayStringFactory.reconfigure(
                entity.production,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        productionCore.configureForDisplay(parentEntityViewSpec)

        this.production = new DetailEntry(
          heading ? heading : productionCore.listTitle,
          productionCeProtocol ? productionCeProtocol.entityListView.fullHeading : null,
          'production',
          BaseDetailType.pushedDetail,
          productionCore,
          EditableType.always,
          formFields.production
        )
        if (entity.production) {
          this.production.ceProtocol = productionCeProtocol;
          this.production.cachedListEntry = productionCore.makeListEntry(parentEntityViewSpec, entity.production, 0)
          productionCore.selectedEntity = entity.production;
        }
        this.production.singleFieldSelect = true

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const dcssCore = app.makeCore( 'dcs');
        dcssCore.soGet = SOGetDcs.construct({
          line: new SOGetLine(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            dcssCore
        )
        dcssCore.title = heading ? heading : dcssCore.title
        dcssCore.listTitle = 'Dcss'

        dcssCore.configureForDisplay(parentEntityViewSpec)

        this.dcss = new DetailEntry(
          heading ? heading : dcssCore.listTitle,
          null,
          'dcss',
          BaseDetailType.embeddedList,
          dcssCore,
          EditableType.never
        )

        const linagsCore = app.makeCore( 'linag');
        linagsCore.soGet = SOGetLinag.construct({
          line: new SOGetLine(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            linagsCore
        )
        linagsCore.title = heading ? heading : linagsCore.title
        linagsCore.listTitle = 'Linags'

        linagsCore.configureForDisplay(parentEntityViewSpec)

        this.linags = new DetailEntry(
          heading ? heading : linagsCore.listTitle,
          null,
          'linags',
          BaseDetailType.embeddedList,
          linagsCore,
          EditableType.never
        )
    }
}