import {SOPutSlotlet} from '../../core/slotlet/so-put-slotlet';
import {SlotletCoreBase} from './slotlet.core.base';
import {SOGetSlotlet} from '../../core/slotlet/so-get-slotlet';
import {FormField, SelectionOption} from 'lionheartland';
import {putSlotletFormFieldOptions} from './put-slotlet-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {EventCore} from '../event/event.core';
import {SOGetEvent} from '../../core/event/so-get-event';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {TempclinCore} from '../tempclin/tempclin.core';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';

export class RAPutSlotlet extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putSlotletFormFieldOptions);

  RequestClass = SOPutSlotlet

  constructor(
    public entityCore: SlotletCoreBase
  ) {
    super();
    if (putSlotletFormFieldOptions.event) {
      putSlotletFormFieldOptions.event.makeCore = true;
    }
    if (putSlotletFormFieldOptions.account) {
      putSlotletFormFieldOptions.account.makeCore = true;
    }
    if (putSlotletFormFieldOptions.tempclin) {
      putSlotletFormFieldOptions.tempclin.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('dayOffset'),
      this.getFormValueFromIdentifier('slotNumber'),
      this.getFormValueFromIdentifier(
        'event',
        SOGetEvent,
      ),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
      ),
      this.getFormValueFromIdentifier(
        'tempclin',
        SOGetTempclin,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}