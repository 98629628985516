import { SOGetOrder } from '../order/so-get-order';
import { SOGetTrailer } from '../trailer/so-get-trailer';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetOffering } from '../offering/so-get-offering';
import { SOGetAgreemonth } from '../agreemonth/so-get-agreemonth';
import { SOGetAgreement } from '../agreement/so-get-agreement';
import {ENLoadStatus} from '../../enums';


export class SOPutLoad {

  constructor(
    public uid: string,
    public status: ENLoadStatus = null,
    public action: string = null,
    public weightRequested: number = null,
    public destination: string = null,
    public carrierSignature: string = null,
    public providerSignature: string = null,
    public quantity: number = null,
    public net: number = null,
    public netDcs: number = null,
    public lngNetTargetSetpoint: number = null,
    public lngNetTargetSetpointManual: number = null,
    public arrived: Date = null,
    public departed: Date = null,
    public loadingBegin: Date = null,
    public loadingEnd: Date = null,
    public loadCode: string = null,
    public location: string = null,
    public specialRequests: string = null,
    public order: SOGetOrder = null,
    public trailer: SOGetTrailer = null,
    public carrier: SOGetOrganization = null,
    public offering: SOGetOffering = null,
    public driverName: string = null,
    public operatorName: string = null,
    public eta: Date = null,
    public requestedFillDate: Date = null,
    public coolDownDays: number = null,
    public rearAxelWeight: number = null,
    public droppedLin: number = null,
    public finalAnalysis: string = null,
    public waterAnalysis: string = null,
    public prepVol: string = null,
    public prepTrailer: string = null,
    public nitrogenDetected: boolean = null,
    public weight0: number = null,
    public weight0Manual: number = null,
    public weight1: number = null,
    public weight1Manual: number = null,
    public weight2: number = null,
    public weight2Manual: number = null,
    public pressure0: number = null,
    public pressure1: number = null,
    public process: string = null,
    public chassisId: string = null,
    public dotInspectionDate: string = null,
    public dotSp: string = null,
    public dotE: string = null,
    public setOrderAccountUid: string = null,
    public attachment: string = null,
    public agreemonth: SOGetAgreemonth = null,
    public agreement: SOGetAgreement = null,
    public shield: boolean = null,
    public loadSheetId: string = null,
    public holdRelease: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutLoad(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
      'action' in params ? params.action : null,
      'weightRequested' in params ? params.weightRequested : null,
      'destination' in params ? params.destination : null,
      'carrierSignature' in params ? params.carrierSignature : null,
      'providerSignature' in params ? params.providerSignature : null,
      'quantity' in params ? params.quantity : null,
      'net' in params ? params.net : null,
      'netDcs' in params ? params.netDcs : null,
      'lngNetTargetSetpoint' in params ? params.lngNetTargetSetpoint : null,
      'lngNetTargetSetpointManual' in params ? params.lngNetTargetSetpointManual : null,
      'arrived' in params ? params.arrived : null,
      'departed' in params ? params.departed : null,
      'loadingBegin' in params ? params.loadingBegin : null,
      'loadingEnd' in params ? params.loadingEnd : null,
      'loadCode' in params ? params.loadCode : null,
      'location' in params ? params.location : null,
      'specialRequests' in params ? params.specialRequests : null,
      'order' in params ? params.order : null,
      'trailer' in params ? params.trailer : null,
      'carrier' in params ? params.carrier : null,
      'offering' in params ? params.offering : null,
      'driverName' in params ? params.driverName : null,
      'operatorName' in params ? params.operatorName : null,
      'eta' in params ? params.eta : null,
      'requestedFillDate' in params ? params.requestedFillDate : null,
      'coolDownDays' in params ? params.coolDownDays : null,
      'rearAxelWeight' in params ? params.rearAxelWeight : null,
      'droppedLin' in params ? params.droppedLin : null,
      'finalAnalysis' in params ? params.finalAnalysis : null,
      'waterAnalysis' in params ? params.waterAnalysis : null,
      'prepVol' in params ? params.prepVol : null,
      'prepTrailer' in params ? params.prepTrailer : null,
      'nitrogenDetected' in params ? params.nitrogenDetected : null,
      'weight0' in params ? params.weight0 : null,
      'weight0Manual' in params ? params.weight0Manual : null,
      'weight1' in params ? params.weight1 : null,
      'weight1Manual' in params ? params.weight1Manual : null,
      'weight2' in params ? params.weight2 : null,
      'weight2Manual' in params ? params.weight2Manual : null,
      'pressure0' in params ? params.pressure0 : null,
      'pressure1' in params ? params.pressure1 : null,
      'process' in params ? params.process : null,
      'chassisId' in params ? params.chassisId : null,
      'dotInspectionDate' in params ? params.dotInspectionDate : null,
      'dotSp' in params ? params.dotSp : null,
      'dotE' in params ? params.dotE : null,
      'setOrderAccountUid' in params ? params.setOrderAccountUid : null,
      'attachment' in params ? params.attachment : null,
      'agreemonth' in params ? params.agreemonth : null,
      'agreement' in params ? params.agreement : null,
      'shield' in params ? params.shield : null,
      'loadSheetId' in params ? params.loadSheetId : null,
      'holdRelease' in params ? params.holdRelease : null,
    );
  }
}
