import {SOPostDowntime} from '../../core/downtime/so-post-downtime';
import {DowntimeCoreBase} from './downtime.core.base';
import {SOGetDowntime} from '../../core/downtime/so-get-downtime';
import {FormField, SelectionOption} from 'lionheartland';
import {postDowntimeFormFieldOptions} from './post-downtime-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionmonthCore} from '../distributionmonth/distributionmonth.core';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';

export class RAPostDowntime extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postDowntimeFormFieldOptions);

  RequestClass = SOPostDowntime

  constructor(
    public entityCore: DowntimeCoreBase
  ) {
    super();
    if (postDowntimeFormFieldOptions.distributionmonth) {
      postDowntimeFormFieldOptions.distributionmonth.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier(
        'distributionmonth',
        SOGetDistributionmonth,
      ),
      this.getFormValueFromIdentifier('cutPercent'),
      this.getFormValueFromIdentifier('end'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}