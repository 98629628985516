import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespTempclin } from '../tempclin/so-resp-tempclin';
import { SORespReservation } from '../reservation/so-resp-reservation';
import { SORespClin } from '../clin/so-resp-clin';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespSlot implements ObjectiveProtocol {

  entityType: string = 'slot';
  uid: string;
  status: string;
  slotNumber: number = null;
  slotDay: number = null;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  @Type(() => SORespAccount)
  account: SORespAccount = null;
  @Type(() => SORespTempclin)
  tempclin: SORespTempclin = null;
  loadType: string = null;
  @Type(() => SORespReservation)
  reservations: SORespReservation[] = [];
  @Type(() => SORespClin)
  clins: SORespClin[] = [];
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
}