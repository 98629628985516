

export class SOPutGrant {

  constructor(
    public uid: string,
    public isDefault: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutGrant(
      'uid' in params ? params.uid : null,
      'isDefault' in params ? params.isDefault : null,
    );
  }
}
