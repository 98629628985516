import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPutDistributionmonth {

  constructor(
    public uid: string,
    public state: string = null,
    public started: boolean = null,
    public ordersCreated: boolean = null,
    public ordersSubmitted: boolean = null,
    public scheduleMade: boolean = null,
    public loadsAllocated: boolean = null,
    public midMonthCheckpointComplete: boolean = null,
    public invoicesSent: boolean = null,
    public paymentsReceived: boolean = null,
    public closed: boolean = null,
    public productionVolume: number = null,
    public productionVolumeEstimated: number = null,
    public distribution: SOGetDistribution = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDistributionmonth(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'started' in params ? params.started : null,
      'ordersCreated' in params ? params.ordersCreated : null,
      'ordersSubmitted' in params ? params.ordersSubmitted : null,
      'scheduleMade' in params ? params.scheduleMade : null,
      'loadsAllocated' in params ? params.loadsAllocated : null,
      'midMonthCheckpointComplete' in params ? params.midMonthCheckpointComplete : null,
      'invoicesSent' in params ? params.invoicesSent : null,
      'paymentsReceived' in params ? params.paymentsReceived : null,
      'closed' in params ? params.closed : null,
      'productionVolume' in params ? params.productionVolume : null,
      'productionVolumeEstimated' in params ? params.productionVolumeEstimated : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
