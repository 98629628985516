import { Type } from 'class-transformer';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import { SORespDistributionday } from '../distributionday/so-resp-distributionday';
import { SORespContainersession } from '../containersession/so-resp-containersession';
import {ENServiceType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespServiceRequest implements ObjectiveProtocol {

  entityType: string = 'serviceRequest';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateRequested: Date;
  serviceType: ENServiceType;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
  @Type(() => SORespDistributionday)
  distributionday: SORespDistributionday;
  @Type(() => SORespContainersession)
  containersession: SORespContainersession;
}