import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOProductionreportService} from '../../core/productionreport/eo-productionreport';
import {SOGetProductionreport} from '../../core/productionreport/so-get-productionreport';
import {RAPutProductionreport} from './ra-put-productionreport';
import {RAPostProductionreport} from './ra-post-productionreport';
import {RADeleteProductionreport} from './ra-delete-productionreport';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEProductionreport } from "./ce-productionreport"


export class ProductionreportCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'productionreport';
  title = 'Productionreport';
  ceProtocol: CEConstructor = CEProductionreport

  entityService: EOProductionreportService;
  soGetClass = SOGetProductionreport;
  soGet: SOGetProductionreport;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOProductionreportService
    );
    this.adapters = [
      new RAPostProductionreport(this),
      new RAPutProductionreport(this),
      new RADeleteProductionreport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetProductionreport();
    this.soGet.queryOptions.limit = this.limit;
  }


}