import {SORespNavi} from '../../core/navi/so-resp-navi';
import {NaviCore} from './navi.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutNaviFormFields} from './put-navi-form-fields';
import {SOGetNavi} from '../../core/navi/so-get-navi';
import {SOGetNavroa} from '../../core/navroa/so-get-navroa';
import {SOGetNavtemrola} from '../../core/navtemrola/so-get-navtemrola';


export class CENaviAttributesBase {

    identifier: DetailEntry
    name: DetailEntry
    type: DetailEntry
    navroas: DetailEntry
    navtemrolas: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespNavi,
        app: GasDirectApp,
        formFields: PutNaviFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )

        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.type
        )


        const navroasCore = app.makeCore( 'navroa');
        navroasCore.soGet = SOGetNavroa.construct({
          navi: new SOGetNavi(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            navroasCore
        )
        navroasCore.title = heading ? heading : navroasCore.title
        navroasCore.listTitle = 'Navroas'

        navroasCore.configureForDisplay(parentEntityViewSpec)

        this.navroas = new DetailEntry(
          heading ? heading : navroasCore.listTitle,
          null,
          'navroas',
          BaseDetailType.embeddedList,
          navroasCore,
          EditableType.never
        )

        const navtemrolasCore = app.makeCore( 'navtemrola');
        navtemrolasCore.soGet = SOGetNavtemrola.construct({
          navi: new SOGetNavi(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            navtemrolasCore
        )
        navtemrolasCore.title = heading ? heading : navtemrolasCore.title
        navtemrolasCore.listTitle = 'Navtemrolas'

        navtemrolasCore.configureForDisplay(parentEntityViewSpec)

        this.navtemrolas = new DetailEntry(
          heading ? heading : navtemrolasCore.listTitle,
          null,
          'navtemrolas',
          BaseDetailType.embeddedList,
          navtemrolasCore,
          EditableType.never
        )
    }
}