import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutRateableagreeyearallocation } from './so-put-rateableagreeyearallocation';
import { SOGetRateableagreeyearallocation } from './so-get-rateableagreeyearallocation';
import { SOPostRateableagreeyearallocation } from './so-post-rateableagreeyearallocation';
import { SODeleteRateableagreeyearallocation } from './so-delete-rateableagreeyearallocation';
import {SORespRateableagreeyearallocation} from './so-resp-rateableagreeyearallocation';
import {SOPluralRateableagreeyearallocation} from './so-plural-rateableagreeyearallocation';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EORateableagreeyearallocationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'rateableagreeyearallocation',
      entityCore
    );
  }

  /** GET Rateableagreeyearallocation by id. Will 404 if id not found */
  get(request: SOGetRateableagreeyearallocation): Observable<SOPluralRateableagreeyearallocation> {
    return this.httpClient.get<SOPluralRateableagreeyearallocation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRateableagreeyearallocation, resp) }),
      tap(_ => this.log(`fetched Rateableagreeyearallocation`)),
      catchError(this.handleError<SOPluralRateableagreeyearallocation>(`get Rateableagreeyearallocation`))
    );
  }

  /** POST: add a new Rateableagreeyearallocation to the server */
  post(request: SOPostRateableagreeyearallocation): Observable<SORespRateableagreeyearallocation> {
    return this.httpClient.post<SORespRateableagreeyearallocation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRateableagreeyearallocation, resp) }),
      tap((response: SORespRateableagreeyearallocation) => this.log(`added Rateableagreeyearallocation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRateableagreeyearallocation>('Rateableagreeyearallocation post'))
    );
  }

  /** PUT: update Rateableagreeyearallocation  */
  put(request: SOPutRateableagreeyearallocation): Observable<SORespRateableagreeyearallocation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRateableagreeyearallocation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRateableagreeyearallocation, resp) }),
      tap((response: SORespRateableagreeyearallocation) => this.log(`edited Rateableagreeyearallocation w/ uid=${uid}`)),
      catchError(this.handleError<SORespRateableagreeyearallocation>('Rateableagreeyearallocation put'))
    );
  }

  /** DELETE: delete the Rateableagreeyearallocation from the server */
  delete(request: SODeleteRateableagreeyearallocation): Observable<SORespRateableagreeyearallocation> {
    return this.httpClient.delete<SORespRateableagreeyearallocation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Rateableagreeyearallocation uid=${request.uid}`)),
      catchError(this.handleError<SORespRateableagreeyearallocation>('delete Rateableagreeyearallocation'))
    );
  }
}
