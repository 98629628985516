import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTankreportFormFields {

    public value: FormField
    public tank: FormField

    constructor() {

        this.value = new FormField(
          'value',
          'Value',
          null,
          FormFieldType.text,
          true,
        )
        this.tank = new FormField(
          'tank',
          'Tank',
          'tank',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostTankreportFormFields()

export const postTankreportFormFieldOptions: {[key: string]: FormField} = {
  value: fields.value,
  tank: fields.tank,
};