import { SOGetAccount } from '../account/so-get-account';
import { SOGetHyear } from '../hyear/so-get-hyear';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetHmonth implements QueryProtocol {

  constructor(
    public uid: string = null,
    public state: string = null,
    public month: number = null,
    public year: number = null,
    public account: SOGetAccount = null,
    public hyear: SOGetHyear = null,
    public distributionmonth: SOGetDistributionmonth = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetHmonth(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'month' in params ? params.month : null,
      'year' in params ? params.year : null,
      'account' in params ? params.account : null,
      'hyear' in params ? params.hyear : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
