import { Type } from 'class-transformer';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import { SORespTask } from '../task/so-resp-task';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTaskreport implements ObjectiveProtocol {

  entityType: string = 'taskreport';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  type: string;
  status: string;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
  @Type(() => Date)
  dateRequested: Date = null;
  @Type(() => Date)
  dateStart: Date = null;
  @Type(() => Date)
  dateEnd: Date = null;
  @Type(() => SORespTask)
  tasks: SORespTask[];
}