import {SOPutDelta} from '../../core/delta/so-put-delta';
import {DeltaCoreBase} from './delta.core.base';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {FormField, SelectionOption} from 'lionheartland';
import {putDeltaFormFieldOptions} from './put-delta-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutDelta extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putDeltaFormFieldOptions);

  RequestClass = SOPutDelta

  constructor(
    public entityCore: DeltaCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}