import {LoadCoreBase} from "./load.core.base"
import {ENLoadStatus} from "../../enums";
import {LoadCore} from "./load.core";


export class StatusCore extends LoadCore {
  status = true;
  navTitle = 'Status';

}
