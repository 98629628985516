import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutCalendarFormFields {


    constructor() {


    }
}

const fields = new PutCalendarFormFields()

export const putCalendarFormFieldOptions: {[key: string]: FormField} = {
};