import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostAccountFormFields {

    public customer: FormField
    public distribution: FormField

    constructor() {

        this.customer = new FormField(
          'customer',
          'Customer',
          'customer',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostAccountFormFields()

export const postAccountFormFieldOptions: {[key: string]: FormField} = {
  customer: fields.customer,
};