import {SORespLinag} from '../../core/linag/so-resp-linag';
import {LinagCore} from './linag.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutLinagFormFields} from './put-linag-form-fields';
import { CELinagAttributes } from './ce-linag-attributes'


export class CELinagBase extends CEBase {

    public details: CELinagAttributes;
    public fromFields: PutLinagFormFields = new PutLinagFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: LinagCore,
        public entity: SORespLinag,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CELinagAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.tag)
        this.attributes.push(this.details.line)
        this.attributes.push(this.details.tank)

    }
}