
import {GENERATED_AND_BASE_DISPLAY_OPTIONS} from "./display-rules.generated";

export const APP_DISPLAY_OPTIONS = []

for (const option of GENERATED_AND_BASE_DISPLAY_OPTIONS) {
    APP_DISPLAY_OPTIONS.push(option)
}

APP_DISPLAY_OPTIONS.push()
