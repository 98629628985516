import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostLoadfeeFormFields {

    public quantity: FormField
    public notes: FormField
    public fee: FormField
    public load: FormField
    public unitCost: FormField
    public feeDate: FormField
    public distributionmonth: FormField
    public price: FormField

    constructor() {

        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          true,
        )
        this.notes = new FormField(
          'notes',
          'Notes',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.fee = new FormField(
          'fee',
          'Fee',
          'fee',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          true,
        )
        this.unitCost = new FormField(
          'unitCost',
          'Unit cost',
          null,
          FormFieldType.text,
          true,
        )
        this.feeDate = new FormField(
          'feeDate',
          'Fee date',
          null,
          FormFieldType.datetime,
          true,
        )
        this.distributionmonth = new FormField(
          'distributionmonth',
          'Distributionmonth',
          'distributionmonth',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.price = new FormField(
          'price',
          'Price',
          'price',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostLoadfeeFormFields()

export const postLoadfeeFormFieldOptions: {[key: string]: FormField} = {
  quantity: fields.quantity,
  notes: fields.notes,
  fee: fields.fee,
  load: fields.load,
  unitCost: fields.unitCost,
  feeDate: fields.feeDate,
  distributionmonth: fields.distributionmonth,
  price: fields.price,
};