import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutContract } from './so-put-contract';
import { SOGetContract } from './so-get-contract';
import { SOPostContract } from './so-post-contract';
import { SODeleteContract } from './so-delete-contract';
import {SORespContract} from './so-resp-contract';
import {SOPluralContract} from './so-plural-contract';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOContractService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'contract',
      entityCore
    );
  }

  /** GET Contract by id. Will 404 if id not found */
  get(request: SOGetContract): Observable<SOPluralContract> {
    return this.httpClient.get<SOPluralContract>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralContract, resp) }),
      tap(_ => this.log(`fetched Contract`)),
      catchError(this.handleError<SOPluralContract>(`get Contract`))
    );
  }

  /** POST: add a new Contract to the server */
  post(request: SOPostContract): Observable<SORespContract> {
    return this.httpClient.post<SORespContract>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespContract, resp) }),
      tap((response: SORespContract) => this.log(`added Contract w/ id=${response.uid}`)),
      catchError(this.handleError<SORespContract>('Contract post'))
    );
  }

  /** PUT: update Contract  */
  put(request: SOPutContract): Observable<SORespContract> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespContract>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespContract, resp) }),
      tap((response: SORespContract) => this.log(`edited Contract w/ uid=${uid}`)),
      catchError(this.handleError<SORespContract>('Contract put'))
    );
  }

  /** DELETE: delete the Contract from the server */
  delete(request: SODeleteContract): Observable<SORespContract> {
    return this.httpClient.delete<SORespContract>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Contract uid=${request.uid}`)),
      catchError(this.handleError<SORespContract>('delete Contract'))
    );
  }
}
