import { SOGetAccount } from '../account/so-get-account';
import { SOGetTrailer } from '../trailer/so-get-trailer';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetTrailerauth implements QueryProtocol {

  constructor(
    public uid: string = null,
    public account: SOGetAccount = null,
    public trailer: SOGetTrailer = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetTrailerauth(
      'uid' in params ? params.uid : null,
      'account' in params ? params.account : null,
      'trailer' in params ? params.trailer : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
