import { Type } from 'class-transformer';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDistributionyear implements ObjectiveProtocol {

  entityType: string = 'distributionyear';
  uid: string;
  state: string;
  year: number;
  volumeContracted: number = 0;
  volumeSold: number = 0;
  volumeSoldEstimated: number = 0;
  productionVolume: number = 0;
  productionVolumeEstimated: number = 0;
  spotVolumeContracted: number = 0;
  spotVolumeSold: number = 0;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
}