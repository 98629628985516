import { Type } from 'class-transformer';
import { SORespTempclin } from '../tempclin/so-resp-tempclin';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTemptraclin implements ObjectiveProtocol {

  entityType: string = 'temptraclin';
  uid: string;
  @Type(() => SORespTempclin)
  tempclin: SORespTempclin;
}