import {LandApp} from "lionheartland";
import {environment} from "../environments/environment";
import {entityCoreMap} from "./entity-core-map";
import {APP_DISPLAY_OPTIONS} from "./app-display-options";
import {ALL_NAV_ITEMS} from "./nav-items-custom";

export class GasDirectAppBase extends LandApp {

    constructor(
        useSpecificCustomProcessorPatterns: string[] = null
    ) {
        super(
            useSpecificCustomProcessorPatterns
        )
        this.home = 'load';
        this.navItems = ALL_NAV_ITEMS;
        this.coreApiUrl = environment.coreApiUrl;
        this.title = 'Gas direct';
        this.company = 'ExxonMobil Corporation';
        this.summary = 'Energy lives here';
        this.entityCoreMap = entityCoreMap;
        this.displayOptions = APP_DISPLAY_OPTIONS;
        this.termsAndConditionsLink = 'https://corporate.exxonmobil.com/Global-legal-pages/terms-and-conditions';
        this.defaultShowAddOnAll = false
        this.privacyPolicyLink = 'https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy';
    }
}
