import {SOPostTemptraclin} from '../../core/temptraclin/so-post-temptraclin';
import {TemptraclinCoreBase} from './temptraclin.core.base';
import {SOGetTemptraclin} from '../../core/temptraclin/so-get-temptraclin';
import {FormField, SelectionOption} from 'lionheartland';
import {postTemptraclinFormFieldOptions} from './post-temptraclin-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TempclinCore} from '../tempclin/tempclin.core';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {TemptractCore} from '../temptract/temptract.core';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';

export class RAPostTemptraclin extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTemptraclinFormFieldOptions);

  RequestClass = SOPostTemptraclin

  constructor(
    public entityCore: TemptraclinCoreBase
  ) {
    super();
    if (postTemptraclinFormFieldOptions.tempclin) {
      postTemptraclinFormFieldOptions.tempclin.makeCore = true;
    }
    if (postTemptraclinFormFieldOptions.temptract) {
      postTemptraclinFormFieldOptions.temptract.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'tempclin',
        SOGetTempclin,
      ),
      this.getFormValueFromIdentifier(
        'temptract',
        SOGetTemptract,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}