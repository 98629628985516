import {SORespHestatus} from '../../core/hestatus/so-resp-hestatus';
import {HestatusCore} from './hestatus.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHestatusFormFields} from './put-hestatus-form-fields';
import {SOGetHestatus} from '../../core/hestatus/so-get-hestatus';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetHestatusitem} from '../../core/hestatusitem/so-get-hestatusitem';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEHestatusAttributesBase {

    dateCreated: DetailEntry
    state: DetailEntry
    distributionday: DetailEntry
    hestatusitems: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespHestatus,
        app: GasDirectApp,
        formFields: PutHestatusFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )


        const distributiondayCore = app.makeCore( 'distributionday');
        distributiondayCore.soGet = SOGetDistributionday.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributiondayCore.soGet.queryOptions.sortKey = 'start';
        let distributiondayCeProtocol;
        if (entity.distributionday) {
            distributiondayCeProtocol = createCEProtocol(
                distributiondayCore.ceProtocol,
                parentEntityViewSpec,
                distributiondayCore,
                entity.distributionday,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributiondayCore
        )
        distributiondayCore.title = heading ? heading : distributiondayCore.title
        distributiondayCore.listTitle = 'Distributionday'

        if (entity.distributionday) {
            displayStringFactory.reconfigure(
                entity.distributionday,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributiondayCore.configureForDisplay(parentEntityViewSpec)

        this.distributionday = new DetailEntry(
          heading ? heading : distributiondayCore.listTitle,
          distributiondayCeProtocol ? distributiondayCeProtocol.entityListView.fullHeading : null,
          'distributionday',
          BaseDetailType.pushedDetail,
          distributiondayCore,
          EditableType.never
        )
        if (entity.distributionday) {
          this.distributionday.ceProtocol = distributiondayCeProtocol;
          this.distributionday.cachedListEntry = distributiondayCore.makeListEntry(parentEntityViewSpec, entity.distributionday, 0)
          distributiondayCore.selectedEntity = entity.distributionday;
        }
        this.distributionday.singleFieldSelect = true

        const hestatusitemsCore = app.makeCore( 'hestatusitem');
        hestatusitemsCore.soGet = SOGetHestatusitem.construct({
          hestatus: new SOGetHestatus(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hestatusitemsCore
        )
        hestatusitemsCore.title = heading ? heading : hestatusitemsCore.title
        hestatusitemsCore.listTitle = 'Hestatusitems'

        hestatusitemsCore.configureForDisplay(parentEntityViewSpec)

        this.hestatusitems = new DetailEntry(
          heading ? heading : hestatusitemsCore.listTitle,
          null,
          'hestatusitems',
          BaseDetailType.embeddedList,
          hestatusitemsCore,
          EditableType.never
        )
    }
}