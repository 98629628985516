import { SOGetOrder } from '../order/so-get-order';
import { SOGetTempclin } from '../tempclin/so-get-tempclin';
import { SOGetOffering } from '../offering/so-get-offering';


export class SOPutItem {

  constructor(
    public uid: string,
    public quantity: number = null,
    public order: SOGetOrder = null,
    public tempclin: SOGetTempclin = null,
    public offering: SOGetOffering = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutItem(
      'uid' in params ? params.uid : null,
      'quantity' in params ? params.quantity : null,
      'order' in params ? params.order : null,
      'tempclin' in params ? params.tempclin : null,
      'offering' in params ? params.offering : null,
    );
  }
}
