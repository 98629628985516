import {SORespTrailerauth} from '../../core/trailerauth/so-resp-trailerauth';
import {TrailerauthCore} from './trailerauth.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTrailerauthFormFields} from './put-trailerauth-form-fields';
import {SOGetTrailerauth} from '../../core/trailerauth/so-get-trailerauth';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CETrailerauthAttributesBase {

    account: DetailEntry
    trailer: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTrailerauth,
        app: GasDirectApp,
        formFields: PutTrailerauthFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.always,
          formFields.account
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true
        this.account.listHeadingIndex = 1;

        const trailerCore = app.makeCore( 'trailer');
        trailerCore.soGet = SOGetTrailer.construct({
        });
        let trailerCeProtocol;
        if (entity.trailer) {
            trailerCeProtocol = createCEProtocol(
                trailerCore.ceProtocol,
                parentEntityViewSpec,
                trailerCore,
                entity.trailer,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            trailerCore
        )
        trailerCore.title = heading ? heading : trailerCore.title
        trailerCore.listTitle = 'Trailer'

        if (entity.trailer) {
            displayStringFactory.reconfigure(
                entity.trailer,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        trailerCore.configureForDisplay(parentEntityViewSpec)

        this.trailer = new DetailEntry(
          heading ? heading : trailerCore.listTitle,
          trailerCeProtocol ? trailerCeProtocol.entityListView.fullHeading : null,
          'trailer',
          BaseDetailType.pushedDetail,
          trailerCore,
          EditableType.always,
          formFields.trailer
        )
        if (entity.trailer) {
          this.trailer.ceProtocol = trailerCeProtocol;
          this.trailer.cachedListEntry = trailerCore.makeListEntry(parentEntityViewSpec, entity.trailer, 0)
          trailerCore.selectedEntity = entity.trailer;
        }
        this.trailer.singleFieldSelect = true
        this.trailer.listHeadingIndex = 0;
    }
}