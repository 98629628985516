import {SORespTemptract} from '../../core/temptract/so-resp-temptract';
import {TemptractCore} from './temptract.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTemptractFormFields} from './put-temptract-form-fields';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';
import {SOGetContract} from '../../core/contract/so-get-contract';
import {SOGetTempagreetract} from '../../core/tempagreetract/so-get-tempagreetract';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetTemptraclin} from '../../core/temptraclin/so-get-temptraclin';


export class CETemptractAttributesBase {

    contracts: DetailEntry
    tempagreetracts: DetailEntry
    name: DetailEntry
    durationDays: DetailEntry
    takeOrPay: DetailEntry
    minLoadSizeToQualify: DetailEntry
    quantity: DetailEntry
    loadQuantity: DetailEntry
    type: DetailEntry
    docusignLink: DetailEntry
    description: DetailEntry
    distribution: DetailEntry
    temptraclins: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTemptract,
        app: GasDirectApp,
        formFields: PutTemptractFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.durationDays = new DetailEntry(
            'Duration days',
            entity.durationDays,
            'durationDays',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.durationDays
        )

        this.takeOrPay = new DetailEntry(
            'Take or pay',
            entity.takeOrPay,
            'takeOrPay',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.takeOrPay
        )

        this.minLoadSizeToQualify = new DetailEntry(
            'Min load size to qualify',
            entity.minLoadSizeToQualify,
            'minLoadSizeToQualify',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.minLoadSizeToQualify
        )

        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadQuantity = new DetailEntry(
            'Load quantity',
            entity.loadQuantity,
            'loadQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.type
        )

        this.docusignLink = new DetailEntry(
            'Docusign link',
            entity.docusignLink,
            'docusignLink',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.docusignLink
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )


        const contractsCore = app.makeCore( 'contract');
        contractsCore.soGet = SOGetContract.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            contractsCore
        )
        contractsCore.title = heading ? heading : contractsCore.title
        contractsCore.listTitle = 'Contracts'

        contractsCore.configureForDisplay(parentEntityViewSpec)

        this.contracts = new DetailEntry(
          heading ? heading : contractsCore.listTitle,
          null,
          'contracts',
          BaseDetailType.embeddedList,
          contractsCore,
          EditableType.never
        )

        const tempagreetractsCore = app.makeCore( 'tempagreetract');
        tempagreetractsCore.soGet = SOGetTempagreetract.construct({
          temptract: new SOGetTemptract(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempagreetractsCore
        )
        tempagreetractsCore.title = heading ? heading : tempagreetractsCore.title
        tempagreetractsCore.listTitle = 'Tempagreetracts'

        tempagreetractsCore.configureForDisplay(parentEntityViewSpec)

        this.tempagreetracts = new DetailEntry(
          heading ? heading : tempagreetractsCore.listTitle,
          null,
          'tempagreetracts',
          BaseDetailType.embeddedList,
          tempagreetractsCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        this.distribution.singleFieldSelect = true

        const temptraclinsCore = app.makeCore( 'temptraclin');
        temptraclinsCore.soGet = SOGetTemptraclin.construct({
          temptract: new SOGetTemptract(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            temptraclinsCore
        )
        temptraclinsCore.title = heading ? heading : temptraclinsCore.title
        temptraclinsCore.listTitle = 'Temptraclins'

        temptraclinsCore.configureForDisplay(parentEntityViewSpec)

        this.temptraclins = new DetailEntry(
          heading ? heading : temptraclinsCore.listTitle,
          null,
          'temptraclins',
          BaseDetailType.embeddedList,
          temptraclinsCore,
          EditableType.never
        )
    }
}