import {SORespDistributionday} from '../../core/distributionday/so-resp-distributionday';
import {DistributiondayCore} from './distributionday.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributiondayFormFields} from './put-distributionday-form-fields';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetTankreport} from '../../core/tankreport/so-get-tankreport';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {SOGetHestatus} from '../../core/hestatus/so-get-hestatus';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetServiceRequest} from '../../core/service-request/so-get-service-request';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CEDistributiondayAttributesBase {

    state: DetailEntry
    start: DetailEntry
    end: DetailEntry
    day: DetailEntry
    distributionmonth: DetailEntry
    netHeliumTransfer: DetailEntry
    netHeliumTransferBillable: DetailEntry
    netHeliumTransferNonBillable: DetailEntry
    netLinTransfer: DetailEntry
    dewarsLevelInitial: DetailEntry
    dewarsLevelFinal: DetailEntry
    dewarDelta: DetailEntry
    netWithDewarDelta: DetailEntry
    netWithDewarDeltaKscf: DetailEntry
    transactions: DetailEntry
    loads: DetailEntry
    tankreports: DetailEntry
    deltas: DetailEntry
    hestatuss: DetailEntry
    distribution: DetailEntry
    serviceRequests: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDistributionday,
        app: GasDirectApp,
        formFields: PutDistributiondayFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.start.interactiveRowSpec = new RowSpec(0);

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.day = new DetailEntry(
            'Day',
            entity.day,
            'day',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.day.listHeadingIndex = 1;

        this.netHeliumTransfer = new DetailEntry(
            'Net helium transfer',
            entity.netHeliumTransfer,
            'netHeliumTransfer',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.netHeliumTransferBillable = new DetailEntry(
            'Net helium transfer billable',
            entity.netHeliumTransferBillable,
            'netHeliumTransferBillable',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.netHeliumTransferNonBillable = new DetailEntry(
            'Net helium transfer non billable',
            entity.netHeliumTransferNonBillable,
            'netHeliumTransferNonBillable',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.netLinTransfer = new DetailEntry(
            'Net lin transfer',
            entity.netLinTransfer,
            'netLinTransfer',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dewarsLevelInitial = new DetailEntry(
            'Dewars level initial',
            entity.dewarsLevelInitial,
            'dewarsLevelInitial',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dewarsLevelFinal = new DetailEntry(
            'Dewars level final',
            entity.dewarsLevelFinal,
            'dewarsLevelFinal',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dewarDelta = new DetailEntry(
            'Dewar delta',
            entity.dewarDelta,
            'dewarDelta',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.netWithDewarDelta = new DetailEntry(
            'Net with dewar delta',
            entity.netWithDewarDelta,
            'netWithDewarDelta',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.netWithDewarDeltaKscf = new DetailEntry(
            'Net with dewar delta kscf',
            entity.netWithDewarDeltaKscf,
            'netWithDewarDeltaKscf',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        let distributionmonthCeProtocol;
        if (entity.distributionmonth) {
            distributionmonthCeProtocol = createCEProtocol(
                distributionmonthCore.ceProtocol,
                parentEntityViewSpec,
                distributionmonthCore,
                entity.distributionmonth,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        if (entity.distributionmonth) {
            displayStringFactory.reconfigure(
                entity.distributionmonth,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          distributionmonthCeProtocol ? distributionmonthCeProtocol.entityListView.fullHeading : null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        if (entity.distributionmonth) {
          this.distributionmonth.ceProtocol = distributionmonthCeProtocol;
          this.distributionmonth.cachedListEntry = distributionmonthCore.makeListEntry(parentEntityViewSpec, entity.distributionmonth, 0)
          distributionmonthCore.selectedEntity = entity.distributionmonth;
        }
        this.distributionmonth.singleFieldSelect = true
        this.distributionmonth.listHeadingIndex = 0;

        const transactionsCore = app.makeCore( 'transaction');
        transactionsCore.soGet = SOGetTransaction.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distributionday: new SOGetDistributionday(entity.uid),
        });
        transactionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        transactionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            transactionsCore
        )
        transactionsCore.title = heading ? heading : transactionsCore.title
        transactionsCore.listTitle = 'Transactions'

        transactionsCore.configureForDisplay(parentEntityViewSpec)

        this.transactions = new DetailEntry(
          heading ? heading : transactionsCore.listTitle,
          null,
          'transactions',
          BaseDetailType.pushedList,
          transactionsCore,
          EditableType.never
        )

        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distributionday: new SOGetDistributionday(entity.uid),
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.never
        )
        this.loads.interactiveRowSpec = new RowSpec(1);
        this.loads.interactiveRowSpec.showQuickAdd = true;

        const tankreportsCore = app.makeCore( 'tankreport');
        tankreportsCore.soGet = SOGetTankreport.construct({
          distributionday: new SOGetDistributionday(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tankreportsCore
        )
        tankreportsCore.title = heading ? heading : tankreportsCore.title
        tankreportsCore.listTitle = 'Tankreports'

        tankreportsCore.configureForDisplay(parentEntityViewSpec)

        this.tankreports = new DetailEntry(
          heading ? heading : tankreportsCore.listTitle,
          null,
          'tankreports',
          BaseDetailType.pushedList,
          tankreportsCore,
          EditableType.never
        )

        const deltasCore = app.makeCore( 'delta');
        deltasCore.soGet = SOGetDelta.construct({
          distributionday: new SOGetDistributionday(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deltasCore
        )
        deltasCore.title = heading ? heading : deltasCore.title
        deltasCore.listTitle = 'Deltas'

        deltasCore.configureForDisplay(parentEntityViewSpec)

        this.deltas = new DetailEntry(
          heading ? heading : deltasCore.listTitle,
          null,
          'deltas',
          BaseDetailType.embeddedList,
          deltasCore,
          EditableType.never
        )

        const hestatussCore = app.makeCore( 'hestatus');
        hestatussCore.soGet = SOGetHestatus.construct({
          distributionday: new SOGetDistributionday(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hestatussCore
        )
        hestatussCore.title = heading ? heading : hestatussCore.title
        hestatussCore.listTitle = 'Hestatuss'

        hestatussCore.configureForDisplay(parentEntityViewSpec)

        this.hestatuss = new DetailEntry(
          heading ? heading : hestatussCore.listTitle,
          null,
          'hestatuss',
          BaseDetailType.embeddedList,
          hestatussCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const serviceRequestsCore = app.makeCore( 'serviceRequest');
        serviceRequestsCore.soGet = SOGetServiceRequest.construct({
          distributionday: new SOGetDistributionday(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            serviceRequestsCore
        )
        serviceRequestsCore.title = heading ? heading : serviceRequestsCore.title
        serviceRequestsCore.listTitle = 'Service requests'

        serviceRequestsCore.configureForDisplay(parentEntityViewSpec)

        this.serviceRequests = new DetailEntry(
          heading ? heading : serviceRequestsCore.listTitle,
          null,
          'serviceRequests',
          BaseDetailType.embeddedList,
          serviceRequestsCore,
          EditableType.never
        )
    }
}