import {SOPostContainersession} from '../../core/containersession/so-post-containersession';
import {ContainersessionCoreBase} from './containersession.core.base';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {FormField, SelectionOption} from 'lionheartland';
import {postContainersessionFormFieldOptions} from './post-containersession-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TrailerCore} from '../trailer/trailer.core';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';

export class RAPostContainersession extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postContainersessionFormFieldOptions);

  RequestClass = SOPostContainersession

  constructor(
    public entityCore: ContainersessionCoreBase
  ) {
    super();
    if (postContainersessionFormFieldOptions.trailer) {
      postContainersessionFormFieldOptions.trailer.makeCore = true;
    }
    if (postContainersessionFormFieldOptions.load) {
      postContainersessionFormFieldOptions.load.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'trailer',
        SOGetTrailer,
      ),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
        true,
      ),
      this.getFormValueFromIdentifier('notes'),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}