import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutCustomer } from './so-put-customer';
import { SOGetCustomer } from './so-get-customer';
import { SOPostCustomer } from './so-post-customer';
import { SODeleteCustomer } from './so-delete-customer';
import {SORespCustomer} from './so-resp-customer';
import {SOPluralCustomer} from './so-plural-customer';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOCustomerService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'customer',
      entityCore
    );
  }

  /** GET Customer by id. Will 404 if id not found */
  get(request: SOGetCustomer): Observable<SOPluralCustomer> {
    return this.httpClient.get<SOPluralCustomer>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCustomer, resp) }),
      tap(_ => this.log(`fetched Customer`)),
      catchError(this.handleError<SOPluralCustomer>(`get Customer`))
    );
  }

  /** POST: add a new Customer to the server */
  post(request: SOPostCustomer): Observable<SORespCustomer> {
    return this.httpClient.post<SORespCustomer>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCustomer, resp) }),
      tap((response: SORespCustomer) => this.log(`added Customer w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCustomer>('Customer post'))
    );
  }

  /** PUT: update Customer  */
  put(request: SOPutCustomer): Observable<SORespCustomer> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCustomer>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCustomer, resp) }),
      tap((response: SORespCustomer) => this.log(`edited Customer w/ uid=${uid}`)),
      catchError(this.handleError<SORespCustomer>('Customer put'))
    );
  }

  /** DELETE: delete the Customer from the server */
  delete(request: SODeleteCustomer): Observable<SORespCustomer> {
    return this.httpClient.delete<SORespCustomer>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Customer uid=${request.uid}`)),
      catchError(this.handleError<SORespCustomer>('delete Customer'))
    );
  }
}
