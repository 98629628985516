import { Type } from 'class-transformer';
import { SORespPlant } from '../plant/so-resp-plant';
import { SORespProduct } from '../product/so-resp-product';
import { SORespProduction } from '../production/so-resp-production';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespLine implements ObjectiveProtocol {

  entityType: string = 'line';
  uid: string;
  name: string;
  @Type(() => SORespPlant)
  plant: SORespPlant;
  @Type(() => SORespProduct)
  product: SORespProduct;
  units: string;
  @Type(() => SORespProduction)
  production: SORespProduction = null;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution = null;
}