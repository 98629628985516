import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTemprole } from './so-put-temprole';
import { SOGetTemprole } from './so-get-temprole';
import { SOPostTemprole } from './so-post-temprole';
import { SODeleteTemprole } from './so-delete-temprole';
import {SORespTemprole} from './so-resp-temprole';
import {SOPluralTemprole} from './so-plural-temprole';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTemproleService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'temprole',
      entityCore
    );
  }

  /** GET Temprole by id. Will 404 if id not found */
  get(request: SOGetTemprole): Observable<SOPluralTemprole> {
    return this.httpClient.get<SOPluralTemprole>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTemprole, resp) }),
      tap(_ => this.log(`fetched Temprole`)),
      catchError(this.handleError<SOPluralTemprole>(`get Temprole`))
    );
  }

  /** POST: add a new Temprole to the server */
  post(request: SOPostTemprole): Observable<SORespTemprole> {
    return this.httpClient.post<SORespTemprole>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemprole, resp) }),
      tap((response: SORespTemprole) => this.log(`added Temprole w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTemprole>('Temprole post'))
    );
  }

  /** PUT: update Temprole  */
  put(request: SOPutTemprole): Observable<SORespTemprole> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTemprole>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemprole, resp) }),
      tap((response: SORespTemprole) => this.log(`edited Temprole w/ uid=${uid}`)),
      catchError(this.handleError<SORespTemprole>('Temprole put'))
    );
  }

  /** DELETE: delete the Temprole from the server */
  delete(request: SODeleteTemprole): Observable<SORespTemprole> {
    return this.httpClient.delete<SORespTemprole>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Temprole uid=${request.uid}`)),
      catchError(this.handleError<SORespTemprole>('delete Temprole'))
    );
  }
}
