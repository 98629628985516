import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODistributiondayService} from '../../core/distributionday/eo-distributionday';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {RAPutDistributionday} from './ra-put-distributionday';
import {RAPostDistributionday} from './ra-post-distributionday';
import {RADeleteDistributionday} from './ra-delete-distributionday';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution'
import { CEDistributionday } from "./ce-distributionday"


export class DistributiondayCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'distributionday';
  title = 'Distributionday';
  ceProtocol: CEConstructor = CEDistributionday

  entityService: EODistributiondayService;
  soGetClass = SOGetDistributionday;
  soGet: SOGetDistributionday;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODistributiondayService
    );
    this.adapters = [
      new RAPostDistributionday(this),
      new RAPutDistributionday(this),
      new RADeleteDistributionday(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDistributionday();
    if (this.activeUserService.getScope('distribution')) {
        this.soGet.distribution = new SOGetDistribution(
          this.activeUserService.getScope('distribution').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'start';
  }


}