import {SOPostTemprice} from '../../core/temprice/so-post-temprice';
import {TempriceCoreBase} from './temprice.core.base';
import {SOGetTemprice} from '../../core/temprice/so-get-temprice';
import {FormField, SelectionOption} from 'lionheartland';
import {postTempriceFormFieldOptions} from './post-temprice-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TempclinCore} from '../tempclin/tempclin.core';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';

export class RAPostTemprice extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTempriceFormFieldOptions);

  RequestClass = SOPostTemprice

  constructor(
    public entityCore: TempriceCoreBase
  ) {
    super();
    if (postTempriceFormFieldOptions.tempclin) {
      postTempriceFormFieldOptions.tempclin.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('rate'),
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier('loadQuantity'),
      this.getFormValueFromIdentifier('rank'),
      this.getFormValueFromIdentifier(
        'tempclin',
        SOGetTempclin,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}