import {SOPostPublication} from '../../core/publication/so-post-publication';
import {PublicationCoreBase} from './publication.core.base';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {FormField, SelectionOption} from 'lionheartland';
import {postPublicationFormFieldOptions} from './post-publication-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {CarrierCore} from '../carrier/carrier.core';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';

export class RAPostPublication extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postPublicationFormFieldOptions);

  RequestClass = SOPostPublication

  constructor(
    public entityCore: PublicationCoreBase
  ) {
    super();
    if (postPublicationFormFieldOptions.distribution) {
      postPublicationFormFieldOptions.distribution.makeCore = true;
    }
    if (postPublicationFormFieldOptions.account) {
      postPublicationFormFieldOptions.account.makeCore = true;
    }
    if (postPublicationFormFieldOptions.carrier) {
      postPublicationFormFieldOptions.carrier.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier('displayName'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
      ),
      this.getFormValueFromIdentifier(
        'carrier',
        SOGetCarrier,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}