import { Type } from 'class-transformer';
import { SORespLoad } from '../load/so-resp-load';
import { SORespAccount } from '../account/so-resp-account';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespReservation implements ObjectiveProtocol {

  entityType: string = 'reservation';
  uid: string;
  status: string = null;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  description: string = "";
  @Type(() => SORespLoad)
  load: SORespLoad;
  @Type(() => SORespAccount)
  account: SORespAccount;
}