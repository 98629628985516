import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostMonthcloseFormFields {

    public distribution: FormField

    constructor() {

        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostMonthcloseFormFields()

export const postMonthcloseFormFieldOptions: {[key: string]: FormField} = {
  distribution: fields.distribution,
};