import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSlotletService} from '../../core/slotlet/eo-slotlet';
import {SOGetSlotlet} from '../../core/slotlet/so-get-slotlet';
import {RAPutSlotlet} from './ra-put-slotlet';
import {RAPostSlotlet} from './ra-post-slotlet';
import {RADeleteSlotlet} from './ra-delete-slotlet';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CESlotlet } from "./ce-slotlet"


export class SlotletCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'slotlet';
  title = 'Slotlet';
  ceProtocol: CEConstructor = CESlotlet

  entityService: EOSlotletService;
  soGetClass = SOGetSlotlet;
  soGet: SOGetSlotlet;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSlotletService
    );
    this.adapters = [
      new RAPostSlotlet(this),
      new RAPutSlotlet(this),
      new RADeleteSlotlet(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSlotlet();
    this.soGet.queryOptions.limit = this.limit;
  }


}