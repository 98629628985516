import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostFeescheduleagreementascFormFields {

    public agreement: FormField
    public feeschedule: FormField

    constructor() {

        this.agreement = new FormField(
          'agreement',
          'Agreement',
          'agreement',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.feeschedule = new FormField(
          'feeschedule',
          'Feeschedule',
          'feeschedule',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostFeescheduleagreementascFormFields()

export const postFeescheduleagreementascFormFieldOptions: {[key: string]: FormField} = {
  agreement: fields.agreement,
  feeschedule: fields.feeschedule,
};