import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTempclinService} from '../../core/tempclin/eo-tempclin';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {RAPutTempclin} from './ra-put-tempclin';
import {RAPostTempclin} from './ra-post-tempclin';
import {RADeleteTempclin} from './ra-delete-tempclin';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETempclin } from "./ce-tempclin"


export class TempclinCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'tempclin';
  title = 'Tempclin';
  ceProtocol: CEConstructor = CETempclin

  entityService: EOTempclinService;
  soGetClass = SOGetTempclin;
  soGet: SOGetTempclin;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTempclinService
    );
    this.adapters = [
      new RAPostTempclin(this),
      new RAPutTempclin(this),
      new RADeleteTempclin(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTempclin();
    this.soGet.queryOptions.limit = this.limit;
  }


}