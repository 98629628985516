import {SORespCarrier} from '../../core/carrier/so-resp-carrier';
import {CarrierCore} from './carrier.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutCarrierFormFields} from './put-carrier-form-fields';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetCarrierauth} from '../../core/carrierauth/so-get-carrierauth';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CECarrierAttributesBase {

    organization: DetailEntry
    carrierauths: DetailEntry
    roles: DetailEntry
    publications: DetailEntry
    companyName: DetailEntry
    emailDomain: DetailEntry
    handle: DetailEntry
    dateCreated: DetailEntry
    signedAgreement: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespCarrier,
        app: GasDirectApp,
        formFields: PutCarrierFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.companyName = new DetailEntry(
            'Company name',
            entity.companyName,
            'companyName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.companyName
        )

        this.emailDomain = new DetailEntry(
            'Email domain',
            entity.emailDomain,
            'emailDomain',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.emailDomain
        )

        this.handle = new DetailEntry(
            'Handle',
            entity.handle,
            'handle',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.handle
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateCreated
        )

        this.signedAgreement = new DetailEntry(
            'Signed agreement',
            entity.signedAgreement,
            'signedAgreement',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.signedAgreement
        )


        const organizationCore = app.makeCore( 'organization');
        organizationCore.soGet = SOGetOrganization.construct({
          carrier: new SOGetCarrier(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            organizationCore
        )
        organizationCore.title = heading ? heading : organizationCore.title
        organizationCore.listTitle = 'Organization'

        organizationCore.configureForDisplay(parentEntityViewSpec)

        this.organization = new DetailEntry(
          heading ? heading : organizationCore.listTitle,
          null,
          'organization',
          BaseDetailType.pushedDetail,
          organizationCore,
          EditableType.never
        )
        this.organization.singleFieldSelect = true

        const carrierauthsCore = app.makeCore( 'carrierauth');
        carrierauthsCore.soGet = SOGetCarrierauth.construct({
          carrier: new SOGetCarrier(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            carrierauthsCore
        )
        carrierauthsCore.title = heading ? heading : carrierauthsCore.title
        carrierauthsCore.listTitle = 'Carrierauths'

        carrierauthsCore.configureForDisplay(parentEntityViewSpec)

        this.carrierauths = new DetailEntry(
          heading ? heading : carrierauthsCore.listTitle,
          null,
          'carrierauths',
          BaseDetailType.embeddedList,
          carrierauthsCore,
          EditableType.never
        )

        const rolesCore = app.makeCore( 'role');
        rolesCore.soGet = SOGetRole.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
          carrier: new SOGetCarrier(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rolesCore
        )
        rolesCore.title = heading ? heading : rolesCore.title
        rolesCore.listTitle = 'Roles'

        rolesCore.configureForDisplay(parentEntityViewSpec)

        this.roles = new DetailEntry(
          heading ? heading : rolesCore.listTitle,
          null,
          'roles',
          BaseDetailType.embeddedList,
          rolesCore,
          EditableType.never
        )

        const publicationsCore = app.makeCore( 'publication');
        publicationsCore.soGet = SOGetPublication.construct({
          carrier: new SOGetCarrier(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            publicationsCore
        )
        publicationsCore.title = heading ? heading : publicationsCore.title
        publicationsCore.listTitle = 'Publications'

        publicationsCore.configureForDisplay(parentEntityViewSpec)

        this.publications = new DetailEntry(
          heading ? heading : publicationsCore.listTitle,
          null,
          'publications',
          BaseDetailType.embeddedList,
          publicationsCore,
          EditableType.never
        )
    }
}