import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostHestatusitemFormFields {

    public hestatus: FormField
    public containersession: FormField

    constructor() {

        this.hestatus = new FormField(
          'hestatus',
          'Hestatus',
          'hestatus',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostHestatusitemFormFields()

export const postHestatusitemFormFieldOptions: {[key: string]: FormField} = {
  hestatus: fields.hestatus,
  containersession: fields.containersession,
};