import {SORespInreport} from '../../core/inreport/so-resp-inreport';
import {InreportCore} from './inreport.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutInreportFormFields} from './put-inreport-form-fields';
import {SOGetInreport} from '../../core/inreport/so-get-inreport';
import {SOGetAccountreport} from '../../core/accountreport/so-get-accountreport';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEInreportAttributesBase {

    accounreport: DetailEntry
    load: DetailEntry
    distributionmonth: DetailEntry
    leftoverPrior: DetailEntry
    prefillPrior: DetailEntry
    leftoverNext: DetailEntry
    prefillNext: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespInreport,
        app: GasDirectApp,
        formFields: PutInreportFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.leftoverPrior = new DetailEntry(
            'Leftover prior',
            entity.leftoverPrior,
            'leftoverPrior',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )
        this.leftoverPrior.tableIndex = 1;

        this.prefillPrior = new DetailEntry(
            'Prefill prior',
            entity.prefillPrior,
            'prefillPrior',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )
        this.prefillPrior.tableIndex = 2;

        this.leftoverNext = new DetailEntry(
            'Leftover next',
            entity.leftoverNext,
            'leftoverNext',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )
        this.leftoverNext.tableIndex = 3;

        this.prefillNext = new DetailEntry(
            'Prefill next',
            entity.prefillNext,
            'prefillNext',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )
        this.prefillNext.tableIndex = 4;


        const accounreportCore = app.makeCore( 'accountreport');
        accounreportCore.soGet = SOGetAccountreport.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accounreportCore
        )
        accounreportCore.title = heading ? heading : accounreportCore.title
        accounreportCore.listTitle = 'Accounreport'

        accounreportCore.configureForDisplay(parentEntityViewSpec)

        this.accounreport = new DetailEntry(
          heading ? heading : accounreportCore.listTitle,
          null,
          'accounreport',
          BaseDetailType.pushedDetail,
          accounreportCore,
          EditableType.always,
          formFields.accounreport
        )
        this.accounreport.singleFieldSelect = true

        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        let loadCeProtocol;
        if (entity.load) {
            loadCeProtocol = createCEProtocol(
                loadCore.ceProtocol,
                parentEntityViewSpec,
                loadCore,
                entity.load,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        if (entity.load) {
            displayStringFactory.reconfigure(
                entity.load,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          loadCeProtocol ? loadCeProtocol.entityListView.fullHeading : null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.always,
          formFields.load
        )
        if (entity.load) {
          this.load.ceProtocol = loadCeProtocol;
          this.load.cachedListEntry = loadCore.makeListEntry(parentEntityViewSpec, entity.load, 0)
          loadCore.selectedEntity = entity.load;
        }
        this.load.singleFieldSelect = true
        this.load.tableIndex = 0;

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true
    }
}