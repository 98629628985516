import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutCarrierauth } from './so-put-carrierauth';
import { SOGetCarrierauth } from './so-get-carrierauth';
import { SOPostCarrierauth } from './so-post-carrierauth';
import { SODeleteCarrierauth } from './so-delete-carrierauth';
import {SORespCarrierauth} from './so-resp-carrierauth';
import {SOPluralCarrierauth} from './so-plural-carrierauth';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOCarrierauthService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'carrierauth',
      entityCore
    );
  }

  /** GET Carrierauth by id. Will 404 if id not found */
  get(request: SOGetCarrierauth): Observable<SOPluralCarrierauth> {
    return this.httpClient.get<SOPluralCarrierauth>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCarrierauth, resp) }),
      tap(_ => this.log(`fetched Carrierauth`)),
      catchError(this.handleError<SOPluralCarrierauth>(`get Carrierauth`))
    );
  }

  /** POST: add a new Carrierauth to the server */
  post(request: SOPostCarrierauth): Observable<SORespCarrierauth> {
    return this.httpClient.post<SORespCarrierauth>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCarrierauth, resp) }),
      tap((response: SORespCarrierauth) => this.log(`added Carrierauth w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCarrierauth>('Carrierauth post'))
    );
  }

  /** PUT: update Carrierauth  */
  put(request: SOPutCarrierauth): Observable<SORespCarrierauth> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCarrierauth>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCarrierauth, resp) }),
      tap((response: SORespCarrierauth) => this.log(`edited Carrierauth w/ uid=${uid}`)),
      catchError(this.handleError<SORespCarrierauth>('Carrierauth put'))
    );
  }

  /** DELETE: delete the Carrierauth from the server */
  delete(request: SODeleteCarrierauth): Observable<SORespCarrierauth> {
    return this.httpClient.delete<SORespCarrierauth>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Carrierauth uid=${request.uid}`)),
      catchError(this.handleError<SORespCarrierauth>('delete Carrierauth'))
    );
  }
}
