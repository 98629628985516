import {SOPutSaleaccountasc} from '../../core/saleaccountasc/so-put-saleaccountasc';
import {SaleaccountascCoreBase} from './saleaccountasc.core.base';
import {SOGetSaleaccountasc} from '../../core/saleaccountasc/so-get-saleaccountasc';
import {FormField, SelectionOption} from 'lionheartland';
import {putSaleaccountascFormFieldOptions} from './put-saleaccountasc-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutSaleaccountasc extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putSaleaccountascFormFieldOptions);

  RequestClass = SOPutSaleaccountasc

  constructor(
    public entityCore: SaleaccountascCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}