import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOChecklistitemService} from '../../core/checklistitem/eo-checklistitem';
import {SOGetChecklistitem} from '../../core/checklistitem/so-get-checklistitem';
import {RAPutChecklistitem} from './ra-put-checklistitem';
import {RAPostChecklistitem} from './ra-post-checklistitem';
import {RADeleteChecklistitem} from './ra-delete-checklistitem';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEChecklistitem } from "./ce-checklistitem"


export class ChecklistitemCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'checklistitem';
  title = 'Checklistitem';
  ceProtocol: CEConstructor = CEChecklistitem

  entityService: EOChecklistitemService;
  soGetClass = SOGetChecklistitem;
  soGet: SOGetChecklistitem;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOChecklistitemService
    );
    this.adapters = [
      new RAPostChecklistitem(this),
      new RAPutChecklistitem(this),
      new RADeleteChecklistitem(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetChecklistitem();
    this.soGet.queryOptions.limit = this.limit;
  }


}