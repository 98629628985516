import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutAccount } from './so-put-account';
import { SOGetAccount } from './so-get-account';
import { SOPostAccount } from './so-post-account';
import { SODeleteAccount } from './so-delete-account';
import {SORespAccount} from './so-resp-account';
import {SOPluralAccount} from './so-plural-account';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOAccountService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'account',
      entityCore
    );
  }

  /** GET Account by id. Will 404 if id not found */
  get(request: SOGetAccount): Observable<SOPluralAccount> {
    return this.httpClient.get<SOPluralAccount>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAccount, resp) }),
      tap(_ => this.log(`fetched Account`)),
      catchError(this.handleError<SOPluralAccount>(`get Account`))
    );
  }

  /** POST: add a new Account to the server */
  post(request: SOPostAccount): Observable<SORespAccount> {
    return this.httpClient.post<SORespAccount>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAccount, resp) }),
      tap((response: SORespAccount) => this.log(`added Account w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAccount>('Account post'))
    );
  }

  /** PUT: update Account  */
  put(request: SOPutAccount): Observable<SORespAccount> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAccount>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAccount, resp) }),
      tap((response: SORespAccount) => this.log(`edited Account w/ uid=${uid}`)),
      catchError(this.handleError<SORespAccount>('Account put'))
    );
  }

  /** DELETE: delete the Account from the server */
  delete(request: SODeleteAccount): Observable<SORespAccount> {
    return this.httpClient.delete<SORespAccount>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Account uid=${request.uid}`)),
      catchError(this.handleError<SORespAccount>('delete Account'))
    );
  }
}
