import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';


export class SOPostSale {

  constructor(
    public distribution: SOGetDistribution,
    public distributionmonth: SOGetDistributionmonth,
    public description: string = "",
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostSale(
      'description' in params ? params.description : null,
      'distribution' in params ? params.distribution : null,
      'distributionmonth' in params ? params.distributionmonth : null,
    );
  }
}
