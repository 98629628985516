

export class SOPutDeltaweight {

  constructor(
    public uid: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDeltaweight(
      'uid' in params ? params.uid : null,
    );
  }
}
