import {SORespContainersession} from '../../core/containersession/so-resp-containersession';
import {ContainersessionCore} from './containersession.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutContainersessionFormFields} from './put-containersession-form-fields';
import { CEContainersessionAttributes } from './ce-containersession-attributes'


export class CEContainersessionBase extends CEBase {

    public details: CEContainersessionAttributes;
    public fromFields: PutContainersessionFormFields = new PutContainersessionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ContainersessionCore,
        public entity: SORespContainersession,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEContainersessionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.arrived)
        this.attributes.push(this.details.shipEmpty)
        this.attributes.push(this.details.carrierSignature)
        this.attributes.push(this.details.providerSignature)
        this.attributes.push(this.details.notes)
        this.attributes.push(this.details.departed)
        this.attributes.push(this.details.loadSheetId)
        this.attributes.push(this.details.activitys)
        this.attributes.push(this.details.weights)
        this.attributes.push(this.details.hemons)
        this.attributes.push(this.details.impuritys)
        this.attributes.push(this.details.checklists)
        this.attributes.push(this.details.tasks)
        this.attributes.push(this.details.bols)
        this.attributes.push(this.details.loadfees)
        this.attributes.push(this.details.trailer)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.carrier)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.sitesessions)
        this.attributes.push(this.details.baysessions)
        this.attributes.push(this.details.deltas)
        this.attributes.push(this.details.hestatusitems)
        this.attributes.push(this.details.lastHemon)
        this.attributes.push(this.details.serviceRequests)
        this.attributes.push(this.details.subscriptions)

    }
}