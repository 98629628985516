import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutFee } from './so-put-fee';
import { SOGetFee } from './so-get-fee';
import { SOPostFee } from './so-post-fee';
import { SODeleteFee } from './so-delete-fee';
import {SORespFee} from './so-resp-fee';
import {SOPluralFee} from './so-plural-fee';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOFeeService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'fee',
      entityCore
    );
  }

  /** GET Fee by id. Will 404 if id not found */
  get(request: SOGetFee): Observable<SOPluralFee> {
    return this.httpClient.get<SOPluralFee>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralFee, resp) }),
      tap(_ => this.log(`fetched Fee`)),
      catchError(this.handleError<SOPluralFee>(`get Fee`))
    );
  }

  /** POST: add a new Fee to the server */
  post(request: SOPostFee): Observable<SORespFee> {
    return this.httpClient.post<SORespFee>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFee, resp) }),
      tap((response: SORespFee) => this.log(`added Fee w/ id=${response.uid}`)),
      catchError(this.handleError<SORespFee>('Fee post'))
    );
  }

  /** PUT: update Fee  */
  put(request: SOPutFee): Observable<SORespFee> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespFee>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFee, resp) }),
      tap((response: SORespFee) => this.log(`edited Fee w/ uid=${uid}`)),
      catchError(this.handleError<SORespFee>('Fee put'))
    );
  }

  /** DELETE: delete the Fee from the server */
  delete(request: SODeleteFee): Observable<SORespFee> {
    return this.httpClient.delete<SORespFee>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Fee uid=${request.uid}`)),
      catchError(this.handleError<SORespFee>('delete Fee'))
    );
  }
}
