import {SORespRole} from '../../core/role/so-resp-role';
import {RoleCore} from './role.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutRoleFormFields} from './put-role-form-fields';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetPlant} from '../../core/plant/so-get-plant';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';
import {SOGetGrant} from '../../core/grant/so-get-grant';
import {SOGetNavroa} from '../../core/navroa/so-get-navroa';
import {SOGetOrgrola} from '../../core/orgrola/so-get-orgrola';
import {SOGetFeatureasc} from '../../core/featureasc/so-get-featureasc';


export class CERoleAttributesBase {

    name: DetailEntry
    identifier: DetailEntry
    plant: DetailEntry
    distribution: DetailEntry
    account: DetailEntry
    carrier: DetailEntry
    grants: DetailEntry
    navroas: DetailEntry
    orgrolas: DetailEntry
    featureascs: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRole,
        app: GasDirectApp,
        formFields: PutRoleFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )


        const plantCore = app.makeCore( 'plant');
        plantCore.soGet = SOGetPlant.construct({
        });
        let plantCeProtocol;
        if (entity.plant) {
            plantCeProtocol = createCEProtocol(
                plantCore.ceProtocol,
                parentEntityViewSpec,
                plantCore,
                entity.plant,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            plantCore
        )
        plantCore.title = heading ? heading : plantCore.title
        plantCore.listTitle = 'Plant'

        if (entity.plant) {
            displayStringFactory.reconfigure(
                entity.plant,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        plantCore.configureForDisplay(parentEntityViewSpec)

        this.plant = new DetailEntry(
          heading ? heading : plantCore.listTitle,
          plantCeProtocol ? plantCeProtocol.entityListView.fullHeading : null,
          'plant',
          BaseDetailType.pushedDetail,
          plantCore,
          EditableType.never
        )
        if (entity.plant) {
          this.plant.ceProtocol = plantCeProtocol;
          this.plant.cachedListEntry = plantCore.makeListEntry(parentEntityViewSpec, entity.plant, 0)
          plantCore.selectedEntity = entity.plant;
        }
        this.plant.singleFieldSelect = true

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.never
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true
        this.account.listSubheadingIndex = 0;

        const carrierCore = app.makeCore( 'carrier');
        carrierCore.soGet = SOGetCarrier.construct({
        });
        let carrierCeProtocol;
        if (entity.carrier) {
            carrierCeProtocol = createCEProtocol(
                carrierCore.ceProtocol,
                parentEntityViewSpec,
                carrierCore,
                entity.carrier,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            carrierCore
        )
        carrierCore.title = heading ? heading : carrierCore.title
        carrierCore.listTitle = 'Carrier'

        if (entity.carrier) {
            displayStringFactory.reconfigure(
                entity.carrier,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        carrierCore.configureForDisplay(parentEntityViewSpec)

        this.carrier = new DetailEntry(
          heading ? heading : carrierCore.listTitle,
          carrierCeProtocol ? carrierCeProtocol.entityListView.fullHeading : null,
          'carrier',
          BaseDetailType.pushedDetail,
          carrierCore,
          EditableType.never
        )
        if (entity.carrier) {
          this.carrier.ceProtocol = carrierCeProtocol;
          this.carrier.cachedListEntry = carrierCore.makeListEntry(parentEntityViewSpec, entity.carrier, 0)
          carrierCore.selectedEntity = entity.carrier;
        }
        this.carrier.singleFieldSelect = true
        this.carrier.listSubheadingIndex = 0;

        const grantsCore = app.makeCore( 'grant');
        grantsCore.soGet = SOGetGrant.construct({
          role: new SOGetRole(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            grantsCore
        )
        grantsCore.title = heading ? heading : grantsCore.title
        grantsCore.listTitle = 'Grants'

        grantsCore.configureForDisplay(parentEntityViewSpec)

        this.grants = new DetailEntry(
          heading ? heading : grantsCore.listTitle,
          null,
          'grants',
          BaseDetailType.embeddedList,
          grantsCore,
          EditableType.never
        )

        const navroasCore = app.makeCore( 'navroa');
        navroasCore.soGet = SOGetNavroa.construct({
          role: new SOGetRole(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            navroasCore
        )
        navroasCore.title = heading ? heading : navroasCore.title
        navroasCore.listTitle = 'Navroas'

        navroasCore.configureForDisplay(parentEntityViewSpec)

        this.navroas = new DetailEntry(
          heading ? heading : navroasCore.listTitle,
          null,
          'navroas',
          BaseDetailType.embeddedList,
          navroasCore,
          EditableType.never
        )

        const orgrolasCore = app.makeCore( 'orgrola');
        orgrolasCore.soGet = SOGetOrgrola.construct({
          role: new SOGetRole(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            orgrolasCore
        )
        orgrolasCore.title = heading ? heading : orgrolasCore.title
        orgrolasCore.listTitle = 'Orgrolas'

        orgrolasCore.configureForDisplay(parentEntityViewSpec)

        this.orgrolas = new DetailEntry(
          heading ? heading : orgrolasCore.listTitle,
          null,
          'orgrolas',
          BaseDetailType.embeddedList,
          orgrolasCore,
          EditableType.never
        )

        const featureascsCore = app.makeCore( 'featureasc');
        featureascsCore.soGet = SOGetFeatureasc.construct({
          role: new SOGetRole(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            featureascsCore
        )
        featureascsCore.title = heading ? heading : featureascsCore.title
        featureascsCore.listTitle = 'Featureascs'

        featureascsCore.configureForDisplay(parentEntityViewSpec)

        this.featureascs = new DetailEntry(
          heading ? heading : featureascsCore.listTitle,
          null,
          'featureascs',
          BaseDetailType.embeddedList,
          featureascsCore,
          EditableType.never
        )
    }
}