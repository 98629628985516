import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import { SOGetDistribution } from '../distribution/so-get-distribution';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetDistributionday implements QueryProtocol {

  constructor(
    public uid: string = null,
    public state: string = null,
    public day: number = null,
    public distributionmonth: SOGetDistributionmonth = null,
    public distribution: SOGetDistribution = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetDistributionday(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'day' in params ? params.day : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'distribution' in params ? params.distribution : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
