import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespAgreement } from '../agreement/so-resp-agreement';
import { SORespAgreemonth } from '../agreemonth/so-resp-agreemonth';
import { SORespItem } from '../item/so-resp-item';
import { SORespQuotelet } from '../quotelet/so-resp-quotelet';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespOrder implements ObjectiveProtocol {

  entityType: string = 'order';
  uid: string;
  amount: number = 0;
  paidAmount: number = 0;
  @Type(() => Date)
  orderDate: Date = null;
  ref: string;
  status: string;
  receiptUrl: string;
  @Type(() => SORespAccount)
  account: SORespAccount;
  @Type(() => SORespAgreement)
  agreement: SORespAgreement = null;
  @Type(() => SORespAgreemonth)
  agreemonth: SORespAgreemonth = null;
  @Type(() => SORespItem)
  items: SORespItem[] = [];
  @Type(() => SORespQuotelet)
  quotelets: SORespQuotelet[] = [];
}