import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutContainersession } from './so-put-containersession';
import { SOGetContainersession } from './so-get-containersession';
import { SOPostContainersession } from './so-post-containersession';
import { SODeleteContainersession } from './so-delete-containersession';
import {SORespContainersession} from './so-resp-containersession';
import {SOPluralContainersession} from './so-plural-containersession';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOContainersessionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'containersession',
      entityCore
    );
  }

  /** GET Containersession by id. Will 404 if id not found */
  get(request: SOGetContainersession): Observable<SOPluralContainersession> {
    return this.httpClient.get<SOPluralContainersession>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralContainersession, resp) }),
      tap(_ => this.log(`fetched Containersession`)),
      catchError(this.handleError<SOPluralContainersession>(`get Containersession`))
    );
  }

  /** POST: add a new Containersession to the server */
  post(request: SOPostContainersession): Observable<SORespContainersession> {
    return this.httpClient.post<SORespContainersession>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespContainersession, resp) }),
      tap((response: SORespContainersession) => this.log(`added Containersession w/ id=${response.uid}`)),
      catchError(this.handleError<SORespContainersession>('Containersession post'))
    );
  }

  /** PUT: update Containersession  */
  put(request: SOPutContainersession): Observable<SORespContainersession> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespContainersession>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespContainersession, resp) }),
      tap((response: SORespContainersession) => this.log(`edited Containersession w/ uid=${uid}`)),
      catchError(this.handleError<SORespContainersession>('Containersession put'))
    );
  }

  /** DELETE: delete the Containersession from the server */
  delete(request: SODeleteContainersession): Observable<SORespContainersession> {
    return this.httpClient.delete<SORespContainersession>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Containersession uid=${request.uid}`)),
      catchError(this.handleError<SORespContainersession>('delete Containersession'))
    );
  }
}
