import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutBacklogitem } from './so-put-backlogitem';
import { SOGetBacklogitem } from './so-get-backlogitem';
import { SOPostBacklogitem } from './so-post-backlogitem';
import { SODeleteBacklogitem } from './so-delete-backlogitem';
import {SORespBacklogitem} from './so-resp-backlogitem';
import {SOPluralBacklogitem} from './so-plural-backlogitem';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOBacklogitemService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'backlogitem',
      entityCore
    );
  }

  /** GET Backlogitem by id. Will 404 if id not found */
  get(request: SOGetBacklogitem): Observable<SOPluralBacklogitem> {
    return this.httpClient.get<SOPluralBacklogitem>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralBacklogitem, resp) }),
      tap(_ => this.log(`fetched Backlogitem`)),
      catchError(this.handleError<SOPluralBacklogitem>(`get Backlogitem`))
    );
  }

  /** POST: add a new Backlogitem to the server */
  post(request: SOPostBacklogitem): Observable<SORespBacklogitem> {
    return this.httpClient.post<SORespBacklogitem>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBacklogitem, resp) }),
      tap((response: SORespBacklogitem) => this.log(`added Backlogitem w/ id=${response.uid}`)),
      catchError(this.handleError<SORespBacklogitem>('Backlogitem post'))
    );
  }

  /** PUT: update Backlogitem  */
  put(request: SOPutBacklogitem): Observable<SORespBacklogitem> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespBacklogitem>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBacklogitem, resp) }),
      tap((response: SORespBacklogitem) => this.log(`edited Backlogitem w/ uid=${uid}`)),
      catchError(this.handleError<SORespBacklogitem>('Backlogitem put'))
    );
  }

  /** DELETE: delete the Backlogitem from the server */
  delete(request: SODeleteBacklogitem): Observable<SORespBacklogitem> {
    return this.httpClient.delete<SORespBacklogitem>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Backlogitem uid=${request.uid}`)),
      catchError(this.handleError<SORespBacklogitem>('delete Backlogitem'))
    );
  }
}
