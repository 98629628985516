import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutBidFormFields {

    public displayName: FormField
    public description: FormField
    public amount: FormField
    public status: FormField
    public amountTwo: FormField
    public amountThree: FormField

    constructor() {

        this.displayName = new FormField(
          'displayName',
          'Display name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          false,
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
          true,
          "submitted"
        )
        this.amountTwo = new FormField(
          'amountTwo',
          'Amount two',
          null,
          FormFieldType.text,
          false,
        )
        this.amountThree = new FormField(
          'amountThree',
          'Amount three',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutBidFormFields()

export const putBidFormFieldOptions: {[key: string]: FormField} = {
  displayName: fields.displayName,
  description: fields.description,
  amount: fields.amount,
  status: fields.status,
  amountTwo: fields.amountTwo,
  amountThree: fields.amountThree,
};