import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODistributionyearService} from '../../core/distributionyear/eo-distributionyear';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';
import {RAPutDistributionyear} from './ra-put-distributionyear';
import {RAPostDistributionyear} from './ra-post-distributionyear';
import {RADeleteDistributionyear} from './ra-delete-distributionyear';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution'
import { CEDistributionyear } from "./ce-distributionyear"


export class DistributionyearCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'distributionyear';
  title = 'Distributionyear';
  ceProtocol: CEConstructor = CEDistributionyear

  entityService: EODistributionyearService;
  soGetClass = SOGetDistributionyear;
  soGet: SOGetDistributionyear;

  listSpecType = ListSpecType.tableEntityRows;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODistributionyearService
    );
    this.adapters = [
      new RAPostDistributionyear(this),
      new RAPutDistributionyear(this),
      new RADeleteDistributionyear(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDistributionyear();
    if (this.activeUserService.getScope('distribution')) {
        this.soGet.distribution = new SOGetDistribution(
          this.activeUserService.getScope('distribution').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'start';
  }


}