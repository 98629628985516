import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOReservationService} from '../../core/reservation/eo-reservation';
import {SOGetReservation} from '../../core/reservation/so-get-reservation';
import {RAPutReservation} from './ra-put-reservation';
import {RAPostReservation} from './ra-post-reservation';
import {RADeleteReservation} from './ra-delete-reservation';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEReservation } from "./ce-reservation"


export class ReservationCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'reservation';
  title = 'Reservation';
  ceProtocol: CEConstructor = CEReservation

  entityService: EOReservationService;
  soGetClass = SOGetReservation;
  soGet: SOGetReservation;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOReservationService
    );
    this.adapters = [
      new RAPostReservation(this),
      new RAPutReservation(this),
      new RADeleteReservation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetReservation();
    this.soGet.queryOptions.limit = this.limit;
  }


}