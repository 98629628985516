import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOFeeService} from '../../core/fee/eo-fee';
import {SOGetFee} from '../../core/fee/so-get-fee';
import {RAPutFee} from './ra-put-fee';
import {RAPostFee} from './ra-post-fee';
import {RADeleteFee} from './ra-delete-fee';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEFee } from "./ce-fee"


export class FeeCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'fee';
  title = 'Fee';
  ceProtocol: CEConstructor = CEFee

  entityService: EOFeeService;
  soGetClass = SOGetFee;
  soGet: SOGetFee;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOFeeService
    );
    this.adapters = [
      new RAPostFee(this),
      new RAPutFee(this),
      new RADeleteFee(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetFee();
    this.soGet.queryOptions.limit = this.limit;
  }


}