import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostTemptract {

  constructor(
    public name: string,
    public takeOrPay: number,
    public minLoadSizeToQualify: number,
    public type: string,
    public docusignLink: string,
    public durationDays: number = 365,
    public description: string = "",
    public distribution: SOGetDistribution = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTemptract(
      'name' in params ? params.name : null,
      'durationDays' in params ? params.durationDays : null,
      'takeOrPay' in params ? params.takeOrPay : null,
      'minLoadSizeToQualify' in params ? params.minLoadSizeToQualify : null,
      'type' in params ? params.type : null,
      'docusignLink' in params ? params.docusignLink : null,
      'description' in params ? params.description : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
