import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTrailermergeService} from '../../core/trailermerge/eo-trailermerge';
import {SOGetTrailermerge} from '../../core/trailermerge/so-get-trailermerge';
import {RAPutTrailermerge} from './ra-put-trailermerge';
import {RAPostTrailermerge} from './ra-post-trailermerge';
import {RADeleteTrailermerge} from './ra-delete-trailermerge';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETrailermerge } from "./ce-trailermerge"


export class TrailermergeCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'trailermerge';
  title = 'Trailermerge';
  ceProtocol: CEConstructor = CETrailermerge

  entityService: EOTrailermergeService;
  soGetClass = SOGetTrailermerge;
  soGet: SOGetTrailermerge;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTrailermergeService
    );
    this.adapters = [
      new RAPostTrailermerge(this),
      new RAPutTrailermerge(this),
      new RADeleteTrailermerge(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTrailermerge();
    this.soGet.queryOptions.limit = this.limit;
  }


}