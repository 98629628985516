import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostLineFormFields {

    public name: FormField
    public plant: FormField
    public product: FormField
    public units: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.plant = new FormField(
          'plant',
          'Plant',
          'plant',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.product = new FormField(
          'product',
          'Product',
          'product',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.units = new FormField(
          'units',
          'Units',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostLineFormFields()

export const postLineFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  plant: fields.plant,
  product: fields.product,
  units: fields.units,
};