import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostClinyearlngFormFields {

    public name: FormField
    public start: FormField
    public period: FormField
    public periodNumber: FormField
    public volumeTier1: FormField
    public priceTier1: FormField
    public volumeTier2: FormField
    public priceTier2: FormField
    public contract: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.period = new FormField(
          'period',
          'Period',
          null,
          FormFieldType.text,
          true,
        )
        this.periodNumber = new FormField(
          'periodNumber',
          'Period number',
          null,
          FormFieldType.text,
          true,
        )
        this.volumeTier1 = new FormField(
          'volumeTier1',
          'Volume tier1',
          null,
          FormFieldType.text,
          true,
        )
        this.priceTier1 = new FormField(
          'priceTier1',
          'Price tier1',
          null,
          FormFieldType.text,
          true,
        )
        this.volumeTier2 = new FormField(
          'volumeTier2',
          'Volume tier2',
          null,
          FormFieldType.text,
          false,
        )
        this.priceTier2 = new FormField(
          'priceTier2',
          'Price tier2',
          null,
          FormFieldType.text,
          false,
        )
        this.contract = new FormField(
          'contract',
          'Contract',
          'contract',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostClinyearlngFormFields()

export const postClinyearlngFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  start: fields.start,
  period: fields.period,
  periodNumber: fields.periodNumber,
  volumeTier1: fields.volumeTier1,
  priceTier1: fields.priceTier1,
  volumeTier2: fields.volumeTier2,
  priceTier2: fields.priceTier2,
  contract: fields.contract,
};