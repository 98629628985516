import { SOGetDistributionday } from '../distributionday/so-get-distributionday';
import { SOGetContainersession } from '../containersession/so-get-containersession';
import {ENServiceType} from '../../enums';


export class SOPostServiceRequest {

  constructor(
    public dateCreated: Date,
    public dateRequested: Date,
    public serviceType: ENServiceType,
    public distributionday: SOGetDistributionday,
    public containersession: SOGetContainersession,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostServiceRequest(
      'dateCreated' in params ? params.dateCreated : null,
      'dateRequested' in params ? params.dateRequested : null,
      'serviceType' in params ? params.serviceType : null,
      'distributionday' in params ? params.distributionday : null,
      'containersession' in params ? params.containersession : null,
    );
  }
}
