import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENTare} from '../../enums';

export class PutWeightFormFields {

    public dateCreated: FormField
    public tare: FormField
    public amount: FormField
    public load: FormField

    constructor() {

        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          false,
        )
        this.tare = new FormField(
          'tare',
          'Tare',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.tare.enumeration = ENTare
        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )

    }
}

const fields = new PutWeightFormFields()

export const putWeightFormFieldOptions: {[key: string]: FormField} = {
  dateCreated: fields.dateCreated,
  tare: fields.tare,
  amount: fields.amount,
  load: fields.load,
};