import { SOGetAccount } from '../account/so-get-account';
import { SOGetCarrier } from '../carrier/so-get-carrier';


export class SOPutCarrierauth {

  constructor(
    public uid: string,
    public account: SOGetAccount = null,
    public carrier: SOGetCarrier = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutCarrierauth(
      'uid' in params ? params.uid : null,
      'account' in params ? params.account : null,
      'carrier' in params ? params.carrier : null,
    );
  }
}
