import {SORespDowntime} from '../../core/downtime/so-resp-downtime';
import {DowntimeCore} from './downtime.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDowntimeFormFields} from './put-downtime-form-fields';
import { CEDowntimeAttributes } from './ce-downtime-attributes'


export class CEDowntimeBase extends CEBase {

    public details: CEDowntimeAttributes;
    public fromFields: PutDowntimeFormFields = new PutDowntimeFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DowntimeCore,
        public entity: SORespDowntime,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDowntimeAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.adjustments)
        this.attributes.push(this.details.distributionmonth)

    }
}