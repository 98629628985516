import { SOGetDowntime } from '../downtime/so-get-downtime';
import { SOGetAgreemonth } from '../agreemonth/so-get-agreemonth';


export class SOPostAdjustment {

  constructor(
    public quantity: number,
    public agreemonth: SOGetAgreemonth,
    public description: string = "",
    public acqAdjustment: boolean = false,
    public downtime: SOGetDowntime = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAdjustment(
      'quantity' in params ? params.quantity : null,
      'description' in params ? params.description : null,
      'acqAdjustment' in params ? params.acqAdjustment : null,
      'downtime' in params ? params.downtime : null,
      'agreemonth' in params ? params.agreemonth : null,
    );
  }
}
