

export class SOPutExternalindexprice {

  constructor(
    public uid: string,
    public data: any = null,
    public EventProcessedUtcTime: string = null,
    public PartitionId: string = null,
    public EventEnqueuedUtcTime: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutExternalindexprice(
      'uid' in params ? params.uid : null,
      'data' in params ? params.data : null,
      'EventProcessedUtcTime' in params ? params.EventProcessedUtcTime : null,
      'PartitionId' in params ? params.PartitionId : null,
      'EventEnqueuedUtcTime' in params ? params.EventEnqueuedUtcTime : null,
    );
  }
}
