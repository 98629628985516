import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTokenFormFields {


    constructor() {


    }
}

const fields = new PutTokenFormFields()

export const putTokenFormFieldOptions: {[key: string]: FormField} = {
};