import { Type } from 'class-transformer';
import { SORespFeeschedule } from '../feeschedule/so-resp-feeschedule';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTempagreement implements ObjectiveProtocol {

  entityType: string = 'tempagreement';
  uid: string;
  name: string;
  description: string = "";
  autoRenew: boolean = false;
  docusignLink: string;
  units: string;
  durationDays: number = 365;
  @Type(() => SORespFeeschedule)
  feeschedule: SORespFeeschedule = null;
  acq: number = null;
  top: number = null;
}