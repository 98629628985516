import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTempagreetractFormFields {

    public tempagreement: FormField
    public temptract: FormField

    constructor() {

        this.tempagreement = new FormField(
          'tempagreement',
          'Tempagreement',
          'tempagreement',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.temptract = new FormField(
          'temptract',
          'Temptract',
          'temptract',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostTempagreetractFormFields()

export const postTempagreetractFormFieldOptions: {[key: string]: FormField} = {
  tempagreement: fields.tempagreement,
  temptract: fields.temptract,
};