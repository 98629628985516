import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostUser {

  constructor(
    public username: string,
    public password: string,
    public givenName: string,
    public surname: string,
    public organization: SOGetOrganization = null,
    public companyName: string = null,
    public isExxon: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostUser(
      'username' in params ? params.username : null,
      'password' in params ? params.password : null,
      'givenName' in params ? params.givenName : null,
      'surname' in params ? params.surname : null,
      'organization' in params ? params.organization : null,
      'companyName' in params ? params.companyName : null,
      'isExxon' in params ? params.isExxon : null,
    );
  }
}
