import { SOGetHyear } from '../hyear/so-get-hyear';
import { SOGetAgreement } from '../agreement/so-get-agreement';


export class SOPostAgreeyear {

  constructor(
    public state: string,
    public year: number,
    public hyear: SOGetHyear,
    public agreement: SOGetAgreement,
    public acq: number = 0,
    public acqUsed: number = 0,
    public spotQuantity: number = 0,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAgreeyear(
      'state' in params ? params.state : null,
      'acq' in params ? params.acq : null,
      'acqUsed' in params ? params.acqUsed : null,
      'year' in params ? params.year : null,
      'hyear' in params ? params.hyear : null,
      'agreement' in params ? params.agreement : null,
      'spotQuantity' in params ? params.spotQuantity : null,
    );
  }
}
