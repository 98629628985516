import { Type } from 'class-transformer';
import { SORespCustomer } from '../customer/so-resp-customer';
import {ENSalesTaxOptions} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespAccount implements ObjectiveProtocol {

  entityType: string = 'account';
  uid: string;
  activeAgreement: boolean = false;
  balance: number = 0;
  availableBalance: number = 0;
  @Type(() => SORespCustomer)
  customer: SORespCustomer;
  scheduledLoads: number = 0;
  unscheduledLoads: number = 0;
  confirmedLoads: number = 0;
  creditLimit: number = null;
  salesTaxState: ENSalesTaxOptions = null;
  @Type(() => Date)
  interruptibleExpiration: Date = null;
  averageLoadSize: number = null;
  balanceMatchThreshold: number = null;
}