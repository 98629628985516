import {SOPutHmonth} from '../../core/hmonth/so-put-hmonth';
import {HmonthCoreBase} from './hmonth.core.base';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {FormField, SelectionOption} from 'lionheartland';
import {putHmonthFormFieldOptions} from './put-hmonth-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {HyearCore} from '../hyear/hyear.core';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';

export class RAPutHmonth extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putHmonthFormFieldOptions);

  RequestClass = SOPutHmonth

  constructor(
    public entityCore: HmonthCoreBase
  ) {
    super();
    if (putHmonthFormFieldOptions.hyear) {
      putHmonthFormFieldOptions.hyear.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('mcq'),
      this.getFormValueFromIdentifier('month'),
      this.getFormValueFromIdentifier('year'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier('delta'),
      this.getFormValueFromIdentifier(
        'hyear',
        SOGetHyear,
      ),
      this.getFormValueFromIdentifier('ordersSubmitted'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}