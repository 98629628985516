import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODeltaService} from '../../core/delta/eo-delta';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {RAPutDelta} from './ra-put-delta';
import {RAPostDelta} from './ra-post-delta';
import {RADeleteDelta} from './ra-delete-delta';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEDelta } from "./ce-delta"


export class DeltaCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'delta';
  title = 'Delta';
  ceProtocol: CEConstructor = CEDelta

  entityService: EODeltaService;
  soGetClass = SOGetDelta;
  soGet: SOGetDelta;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODeltaService
    );
    this.adapters = [
      new RAPostDelta(this),
      new RAPutDelta(this),
      new RADeleteDelta(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDelta();
    this.soGet.queryOptions.limit = this.limit;
  }


}