import {SORespDistributionday} from '../../core/distributionday/so-resp-distributionday';
import {DistributiondayCore} from './distributionday.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributiondayFormFields} from './put-distributionday-form-fields';
import { CEDistributiondayAttributes } from './ce-distributionday-attributes'


export class CEDistributiondayBase extends CEBase {

    public details: CEDistributiondayAttributes;
    public fromFields: PutDistributiondayFormFields = new PutDistributiondayFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DistributiondayCore,
        public entity: SORespDistributionday,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDistributiondayAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.day)
        this.attributes.push(this.details.netHeliumTransfer)
        this.attributes.push(this.details.netHeliumTransferBillable)
        this.attributes.push(this.details.netHeliumTransferNonBillable)
        this.attributes.push(this.details.netLinTransfer)
        this.attributes.push(this.details.dewarsLevelInitial)
        this.attributes.push(this.details.dewarsLevelFinal)
        this.attributes.push(this.details.dewarDelta)
        this.attributes.push(this.details.netWithDewarDelta)
        this.attributes.push(this.details.netWithDewarDeltaKscf)
        this.attributes.push(this.details.distributionmonth)
        this.attributes.push(this.details.transactions)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.tankreports)
        this.attributes.push(this.details.deltas)
        this.attributes.push(this.details.hestatuss)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.serviceRequests)

    }
}