

export class SOPostInquiry {

  constructor(
    public companyName: string,
    public givenName: string,
    public surname: string,
    public estimatedPurchaseQuantity: string,
    public preferredEmailAddress: string,
    public phoneNumber: string,
    public productInquiryStr: string = "Shute Creek LNG",
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostInquiry(
      'companyName' in params ? params.companyName : null,
      'givenName' in params ? params.givenName : null,
      'surname' in params ? params.surname : null,
      'productInquiryStr' in params ? params.productInquiryStr : null,
      'estimatedPurchaseQuantity' in params ? params.estimatedPurchaseQuantity : null,
      'preferredEmailAddress' in params ? params.preferredEmailAddress : null,
      'phoneNumber' in params ? params.phoneNumber : null,
    );
  }
}
