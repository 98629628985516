

export class SOPutCustomer {

  constructor(
    public uid: string,
    public companyName: string = null,
    public emailDomain: string = null,
    public signedAgreement: string = null,
    public handle: string = null,
    public fullCompanyName: string = null,
    public address: string = null,
    public remittorNumber: string = null,
    public additionalInvoiceText: string = null,
    public billingPoc: string = null,
    public paymentTerms: number = null,
    public stripeRef: string = null,
    public stripeDirectDebit: boolean = null,
    public stripeVerify1: number = null,
    public stripeVerify2: number = null,
    public creditLimit: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutCustomer(
      'uid' in params ? params.uid : null,
      'companyName' in params ? params.companyName : null,
      'emailDomain' in params ? params.emailDomain : null,
      'signedAgreement' in params ? params.signedAgreement : null,
      'handle' in params ? params.handle : null,
      'fullCompanyName' in params ? params.fullCompanyName : null,
      'address' in params ? params.address : null,
      'remittorNumber' in params ? params.remittorNumber : null,
      'additionalInvoiceText' in params ? params.additionalInvoiceText : null,
      'billingPoc' in params ? params.billingPoc : null,
      'paymentTerms' in params ? params.paymentTerms : null,
      'stripeRef' in params ? params.stripeRef : null,
      'stripeDirectDebit' in params ? params.stripeDirectDebit : null,
      'stripeVerify1' in params ? params.stripeVerify1 : null,
      'stripeVerify2' in params ? params.stripeVerify2 : null,
      'creditLimit' in params ? params.creditLimit : null,
    );
  }
}
