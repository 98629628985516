import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostAdjustmentFormFields {

    public quantity: FormField
    public description: FormField
    public acqAdjustment: FormField
    public downtime: FormField
    public agreemonth: FormField

    constructor() {

        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.acqAdjustment = new FormField(
          'acqAdjustment',
          'Acq adjustment',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.downtime = new FormField(
          'downtime',
          'Downtime',
          'downtime',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.agreemonth = new FormField(
          'agreemonth',
          'Agreemonth',
          'agreemonth',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostAdjustmentFormFields()

export const postAdjustmentFormFieldOptions: {[key: string]: FormField} = {
  quantity: fields.quantity,
  description: fields.description,
  acqAdjustment: fields.acqAdjustment,
  downtime: fields.downtime,
  agreemonth: fields.agreemonth,
};