

export class SOPutDistribution {

  constructor(
    public uid: string,
    public name: string = null,
    public marketIndexId: string = null,
    public conversionRate: number = null,
    public galToWeight: number = null,
    public defaultSlotsPerDay: number = null,
    public providerSlotDurationHours: number = null,
    public customerSlotDurationHours: number = null,
    public showSlotDuration: boolean = null,
    public pickupInstructions: string = null,
    public paymentInstructions: string = null,
    public checkoutText: string = null,
    public purchaseCompleteText: string = null,
    public bolHeader: string = null,
    public bolWarning: string = null,
    public loadingChecklist: string = null,
    public departureChecklist: string = null,
    public loadNumberPrefix: string = null,
    public loadNumberStart: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDistribution(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'marketIndexId' in params ? params.marketIndexId : null,
      'conversionRate' in params ? params.conversionRate : null,
      'galToWeight' in params ? params.galToWeight : null,
      'defaultSlotsPerDay' in params ? params.defaultSlotsPerDay : null,
      'providerSlotDurationHours' in params ? params.providerSlotDurationHours : null,
      'customerSlotDurationHours' in params ? params.customerSlotDurationHours : null,
      'showSlotDuration' in params ? params.showSlotDuration : null,
      'pickupInstructions' in params ? params.pickupInstructions : null,
      'paymentInstructions' in params ? params.paymentInstructions : null,
      'checkoutText' in params ? params.checkoutText : null,
      'purchaseCompleteText' in params ? params.purchaseCompleteText : null,
      'bolHeader' in params ? params.bolHeader : null,
      'bolWarning' in params ? params.bolWarning : null,
      'loadingChecklist' in params ? params.loadingChecklist : null,
      'departureChecklist' in params ? params.departureChecklist : null,
      'loadNumberPrefix' in params ? params.loadNumberPrefix : null,
      'loadNumberStart' in params ? params.loadNumberStart : null,
    );
  }
}
