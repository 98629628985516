import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutProductFormFields {

    public lines: FormField
    public name: FormField

    constructor() {

        this.lines = new FormField(
          'lines',
          'Lines',
          'line',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutProductFormFields()

export const putProductFormFieldOptions: {[key: string]: FormField} = {
  lines: fields.lines,
  name: fields.name,
};