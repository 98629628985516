import {SORespTempclin} from '../../core/tempclin/so-resp-tempclin';
import {TempclinCore} from './tempclin.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTempclinFormFields} from './put-tempclin-form-fields';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {SOGetSlotlet} from '../../core/slotlet/so-get-slotlet';
import {SOGetClin} from '../../core/clin/so-get-clin';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetTemprice} from '../../core/temprice/so-get-temprice';
import {SOGetTemptraclin} from '../../core/temptraclin/so-get-temptraclin';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CETempclinAttributesBase {

    items: DetailEntry
    slots: DetailEntry
    slotlets: DetailEntry
    clins: DetailEntry
    name: DetailEntry
    type: DetailEntry
    state: DetailEntry
    durationDays: DetailEntry
    offsetDays: DetailEntry
    quantity: DetailEntry
    loadQuantity: DetailEntry
    description: DetailEntry
    start: DetailEntry
    end: DetailEntry
    distribution: DetailEntry
    temprices: DetailEntry
    temptraclins: DetailEntry
    bids: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTempclin,
        app: GasDirectApp,
        formFields: PutTempclinFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.durationDays = new DetailEntry(
            'Duration days',
            entity.durationDays,
            'durationDays',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.durationDays
        )

        this.offsetDays = new DetailEntry(
            'Offset days',
            entity.offsetDays,
            'offsetDays',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.offsetDays
        )

        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadQuantity = new DetailEntry(
            'Load quantity',
            entity.loadQuantity,
            'loadQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )


        const itemsCore = app.makeCore( 'item');
        itemsCore.soGet = SOGetItem.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            itemsCore
        )
        itemsCore.title = heading ? heading : itemsCore.title
        itemsCore.listTitle = 'Items'

        itemsCore.configureForDisplay(parentEntityViewSpec)

        this.items = new DetailEntry(
          heading ? heading : itemsCore.listTitle,
          null,
          'items',
          BaseDetailType.embeddedList,
          itemsCore,
          EditableType.never
        )

        const slotsCore = app.makeCore( 'slot');
        slotsCore.soGet = SOGetSlot.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        slotsCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotsCore
        )
        slotsCore.title = heading ? heading : slotsCore.title
        slotsCore.listTitle = 'Slots'

        slotsCore.configureForDisplay(parentEntityViewSpec)

        this.slots = new DetailEntry(
          heading ? heading : slotsCore.listTitle,
          null,
          'slots',
          BaseDetailType.embeddedList,
          slotsCore,
          EditableType.never
        )

        const slotletsCore = app.makeCore( 'slotlet');
        slotletsCore.soGet = SOGetSlotlet.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotletsCore
        )
        slotletsCore.title = heading ? heading : slotletsCore.title
        slotletsCore.listTitle = 'Slotlets'

        slotletsCore.configureForDisplay(parentEntityViewSpec)

        this.slotlets = new DetailEntry(
          heading ? heading : slotletsCore.listTitle,
          null,
          'slotlets',
          BaseDetailType.embeddedList,
          slotletsCore,
          EditableType.never
        )

        const clinsCore = app.makeCore( 'clin');
        clinsCore.soGet = SOGetClin.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            clinsCore
        )
        clinsCore.title = heading ? heading : clinsCore.title
        clinsCore.listTitle = 'Clins'

        clinsCore.configureForDisplay(parentEntityViewSpec)

        this.clins = new DetailEntry(
          heading ? heading : clinsCore.listTitle,
          null,
          'clins',
          BaseDetailType.embeddedList,
          clinsCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const tempricesCore = app.makeCore( 'temprice');
        tempricesCore.soGet = SOGetTemprice.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempricesCore
        )
        tempricesCore.title = heading ? heading : tempricesCore.title
        tempricesCore.listTitle = 'Temprices'

        tempricesCore.configureForDisplay(parentEntityViewSpec)

        this.temprices = new DetailEntry(
          heading ? heading : tempricesCore.listTitle,
          null,
          'temprices',
          BaseDetailType.embeddedList,
          tempricesCore,
          EditableType.never
        )

        const temptraclinsCore = app.makeCore( 'temptraclin');
        temptraclinsCore.soGet = SOGetTemptraclin.construct({
          tempclin: new SOGetTempclin(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            temptraclinsCore
        )
        temptraclinsCore.title = heading ? heading : temptraclinsCore.title
        temptraclinsCore.listTitle = 'Temptraclins'

        temptraclinsCore.configureForDisplay(parentEntityViewSpec)

        this.temptraclins = new DetailEntry(
          heading ? heading : temptraclinsCore.listTitle,
          null,
          'temptraclins',
          BaseDetailType.embeddedList,
          temptraclinsCore,
          EditableType.never
        )

        const bidsCore = app.makeCore( 'bid');
        bidsCore.soGet = SOGetBid.construct({
          tempclin: new SOGetTempclin(entity.uid),
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            bidsCore
        )
        bidsCore.title = heading ? heading : bidsCore.title
        bidsCore.listTitle = 'Bids'

        bidsCore.configureForDisplay(parentEntityViewSpec)

        this.bids = new DetailEntry(
          heading ? heading : bidsCore.listTitle,
          null,
          'bids',
          BaseDetailType.embeddedList,
          bidsCore,
          EditableType.never
        )
    }
}