import { SOGetAgreeyear } from '../agreeyear/so-get-agreeyear';


export class SOPostRateableagreeyearallocation {

  constructor(
    public agreeyear: SOGetAgreeyear,
    public jan: number = null,
    public feb: number = null,
    public mar: number = null,
    public apr: number = null,
    public may: number = null,
    public jun: number = null,
    public jul: number = null,
    public aug: number = null,
    public sep: number = null,
    public oct: number = null,
    public nov: number = null,
    public dec: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRateableagreeyearallocation(
      'jan' in params ? params.jan : null,
      'feb' in params ? params.feb : null,
      'mar' in params ? params.mar : null,
      'apr' in params ? params.apr : null,
      'may' in params ? params.may : null,
      'jun' in params ? params.jun : null,
      'jul' in params ? params.jul : null,
      'aug' in params ? params.aug : null,
      'sep' in params ? params.sep : null,
      'oct' in params ? params.oct : null,
      'nov' in params ? params.nov : null,
      'dec' in params ? params.dec : null,
      'agreeyear' in params ? params.agreeyear : null,
    );
  }
}
