import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOActivityService} from '../../core/activity/eo-activity';
import {SOGetActivity} from '../../core/activity/so-get-activity';
import {RAPutActivity} from './ra-put-activity';
import {RAPostActivity} from './ra-post-activity';
import {RADeleteActivity} from './ra-delete-activity';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEActivity } from "./ce-activity"


export class ActivityCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'activity';
  title = 'Activity';
  ceProtocol: CEConstructor = CEActivity

  entityService: EOActivityService;
  soGetClass = SOGetActivity;
  soGet: SOGetActivity;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOActivityService
    );
    this.adapters = [
      new RAPostActivity(this),
      new RAPutActivity(this),
      new RADeleteActivity(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetActivity();
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}