import {SORespMessage} from '../../core/message/so-resp-message';
import {MessageCore} from './message.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutMessageFormFields} from './put-message-form-fields';
import {SOGetMessage} from '../../core/message/so-get-message';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {SOGetActivity} from '../../core/activity/so-get-activity';
import {SOGetUser} from '../../core/user/so-get-user';


export class CEMessageAttributesBase {

    timeSent: DetailEntry
    body: DetailEntry
    read: DetailEntry
    type: DetailEntry
    ref: DetailEntry
    subscription: DetailEntry
    publication: DetailEntry
    activity: DetailEntry
    user: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespMessage,
        app: GasDirectApp,
        formFields: PutMessageFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.timeSent = new DetailEntry(
            'Time sent',
            app.activeUserService.time.dateAsString(entity.timeSent),
            'timeSent',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.body = new DetailEntry(
            'Body',
            entity.body,
            'body',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.body
        )

        this.read = new DetailEntry(
            'Read',
            entity.read,
            'read',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.read
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.type
        )

        this.ref = new DetailEntry(
            'Ref',
            entity.ref,
            'ref',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const subscriptionCore = app.makeCore( 'subscription');
        subscriptionCore.soGet = SOGetSubscription.construct({
        });
        let subscriptionCeProtocol;
        if (entity.subscription) {
            subscriptionCeProtocol = createCEProtocol(
                subscriptionCore.ceProtocol,
                parentEntityViewSpec,
                subscriptionCore,
                entity.subscription,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            subscriptionCore
        )
        subscriptionCore.title = heading ? heading : subscriptionCore.title
        subscriptionCore.listTitle = 'Subscription'

        if (entity.subscription) {
            displayStringFactory.reconfigure(
                entity.subscription,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        subscriptionCore.configureForDisplay(parentEntityViewSpec)

        this.subscription = new DetailEntry(
          heading ? heading : subscriptionCore.listTitle,
          subscriptionCeProtocol ? subscriptionCeProtocol.entityListView.fullHeading : null,
          'subscription',
          BaseDetailType.pushedDetail,
          subscriptionCore,
          EditableType.never
        )
        if (entity.subscription) {
          this.subscription.ceProtocol = subscriptionCeProtocol;
          this.subscription.cachedListEntry = subscriptionCore.makeListEntry(parentEntityViewSpec, entity.subscription, 0)
          subscriptionCore.selectedEntity = entity.subscription;
        }
        this.subscription.singleFieldSelect = true

        const publicationCore = app.makeCore( 'publication');
        publicationCore.soGet = SOGetPublication.construct({
        });
        let publicationCeProtocol;
        if (entity.publication) {
            publicationCeProtocol = createCEProtocol(
                publicationCore.ceProtocol,
                parentEntityViewSpec,
                publicationCore,
                entity.publication,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            publicationCore
        )
        publicationCore.title = heading ? heading : publicationCore.title
        publicationCore.listTitle = 'Publication'

        if (entity.publication) {
            displayStringFactory.reconfigure(
                entity.publication,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        publicationCore.configureForDisplay(parentEntityViewSpec)

        this.publication = new DetailEntry(
          heading ? heading : publicationCore.listTitle,
          publicationCeProtocol ? publicationCeProtocol.entityListView.fullHeading : null,
          'publication',
          BaseDetailType.pushedDetail,
          publicationCore,
          EditableType.never
        )
        if (entity.publication) {
          this.publication.ceProtocol = publicationCeProtocol;
          this.publication.cachedListEntry = publicationCore.makeListEntry(parentEntityViewSpec, entity.publication, 0)
          publicationCore.selectedEntity = entity.publication;
        }
        this.publication.singleFieldSelect = true

        const activityCore = app.makeCore( 'activity');
        activityCore.soGet = SOGetActivity.construct({
        });
        activityCore.soGet.queryOptions.sortKey = 'dateCreated';
        activityCore.soGet.queryOptions.descending = true;
        let activityCeProtocol;
        if (entity.activity) {
            activityCeProtocol = createCEProtocol(
                activityCore.ceProtocol,
                parentEntityViewSpec,
                activityCore,
                entity.activity,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            activityCore
        )
        activityCore.title = heading ? heading : activityCore.title
        activityCore.listTitle = 'Activity'

        if (entity.activity) {
            displayStringFactory.reconfigure(
                entity.activity,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        activityCore.configureForDisplay(parentEntityViewSpec)

        this.activity = new DetailEntry(
          heading ? heading : activityCore.listTitle,
          activityCeProtocol ? activityCeProtocol.entityListView.fullHeading : null,
          'activity',
          BaseDetailType.pushedDetail,
          activityCore,
          EditableType.never
        )
        if (entity.activity) {
          this.activity.ceProtocol = activityCeProtocol;
          this.activity.cachedListEntry = activityCore.makeListEntry(parentEntityViewSpec, entity.activity, 0)
          activityCore.selectedEntity = entity.activity;
        }
        this.activity.singleFieldSelect = true

        const userCore = app.makeCore( 'user');
        userCore.soGet = SOGetUser.construct({
        });
        userCore.soGet.queryOptions.sortKey = 'surname';
        let userCeProtocol;
        if (entity.user) {
            userCeProtocol = createCEProtocol(
                userCore.ceProtocol,
                parentEntityViewSpec,
                userCore,
                entity.user,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            userCore
        )
        userCore.title = heading ? heading : userCore.title
        userCore.listTitle = 'User'

        if (entity.user) {
            displayStringFactory.reconfigure(
                entity.user,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        userCore.configureForDisplay(parentEntityViewSpec)

        this.user = new DetailEntry(
          heading ? heading : userCore.listTitle,
          userCeProtocol ? userCeProtocol.entityListView.fullHeading : null,
          'user',
          BaseDetailType.pushedDetail,
          userCore,
          EditableType.never
        )
        if (entity.user) {
          this.user.ceProtocol = userCeProtocol;
          this.user.cachedListEntry = userCore.makeListEntry(parentEntityViewSpec, entity.user, 0)
          userCore.selectedEntity = entity.user;
        }
        this.user.singleFieldSelect = true
    }
}