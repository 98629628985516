import {SOPutTransaction} from '../../core/transaction/so-put-transaction';
import {TransactionCoreBase} from './transaction.core.base';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {FormField, SelectionOption} from 'lionheartland';
import {putTransactionFormFieldOptions} from './put-transaction-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {InvoiceCore} from '../invoice/invoice.core';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';

export class RAPutTransaction extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTransactionFormFieldOptions);

  RequestClass = SOPutTransaction

  constructor(
    public entityCore: TransactionCoreBase
  ) {
    super();
    if (putTransactionFormFieldOptions.invoice) {
      putTransactionFormFieldOptions.invoice.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('dateCreated'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier(
        'invoice',
        SOGetInvoice,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}