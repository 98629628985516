import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOPublicationService} from '../../core/publication/eo-publication';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {RAPutPublication} from './ra-put-publication';
import {RAPostPublication} from './ra-post-publication';
import {RADeletePublication} from './ra-delete-publication';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEPublication } from "./ce-publication"


export class PublicationCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'publication';
  title = 'Publication';
  ceProtocol: CEConstructor = CEPublication

  entityService: EOPublicationService;
  soGetClass = SOGetPublication;
  soGet: SOGetPublication;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOPublicationService
    );
    this.adapters = [
      new RAPostPublication(this),
      new RAPutPublication(this),
      new RADeletePublication(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPublication();
    this.soGet.queryOptions.limit = this.limit;
  }


}