import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutFeescheduleagreementascFormFields {


    constructor() {


    }
}

const fields = new PutFeescheduleagreementascFormFields()

export const putFeescheduleagreementascFormFieldOptions: {[key: string]: FormField} = {
};