import { SOGetLoad } from '../load/so-get-load';
import { SOGetContainersession } from '../containersession/so-get-containersession';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetImpurity implements QueryProtocol {

  constructor(
    public uid: string = null,
    public load: SOGetLoad = null,
    public containersession: SOGetContainersession = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetImpurity(
      'uid' in params ? params.uid : null,
      'load' in params ? params.load : null,
      'containersession' in params ? params.containersession : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
