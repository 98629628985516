import {SORespContainersession} from '../../core/containersession/so-resp-containersession';
import {ContainersessionCore} from './containersession.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutContainersessionFormFields} from './put-containersession-form-fields';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetActivity} from '../../core/activity/so-get-activity';
import {SOGetWeight} from '../../core/weight/so-get-weight';
import {SOGetHemon} from '../../core/hemon/so-get-hemon';
import {SOGetImpurity} from '../../core/impurity/so-get-impurity';
import {SOGetChecklist} from '../../core/checklist/so-get-checklist';
import {SOGetTask} from '../../core/task/so-get-task';
import {SOGetBol} from '../../core/bol/so-get-bol';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetSitesession} from '../../core/sitesession/so-get-sitesession';
import {SOGetBaysession} from '../../core/baysession/so-get-baysession';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {SOGetHestatusitem} from '../../core/hestatusitem/so-get-hestatusitem';
import {SOGetServiceRequest} from '../../core/service-request/so-get-service-request';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';


export class CEContainersessionAttributesBase {

    activitys: DetailEntry
    weights: DetailEntry
    hemons: DetailEntry
    impuritys: DetailEntry
    checklists: DetailEntry
    tasks: DetailEntry
    bols: DetailEntry
    loadfees: DetailEntry
    arrived: DetailEntry
    shipEmpty: DetailEntry
    carrierSignature: DetailEntry
    providerSignature: DetailEntry
    notes: DetailEntry
    departed: DetailEntry
    trailer: DetailEntry
    account: DetailEntry
    carrier: DetailEntry
    distribution: DetailEntry
    load: DetailEntry
    loadSheetId: DetailEntry
    sitesessions: DetailEntry
    baysessions: DetailEntry
    deltas: DetailEntry
    hestatusitems: DetailEntry
    lastHemon: DetailEntry
    serviceRequests: DetailEntry
    subscriptions: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespContainersession,
        app: GasDirectApp,
        formFields: PutContainersessionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.arrived = new DetailEntry(
            'Arrived',
            app.activeUserService.time.dateAsString(entity.arrived),
            'arrived',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.arrived
        )

        this.shipEmpty = new DetailEntry(
            'Ship empty',
            entity.shipEmpty,
            'shipEmpty',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.shipEmpty
        )

        this.carrierSignature = new DetailEntry(
            'Carrier signature',
            entity.carrierSignature,
            'carrierSignature',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.carrierSignature
        )

        this.providerSignature = new DetailEntry(
            'Provider signature',
            entity.providerSignature,
            'providerSignature',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.providerSignature
        )

        this.notes = new DetailEntry(
            'Notes',
            entity.notes,
            'notes',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.notes
        )

        this.departed = new DetailEntry(
            'Departed',
            app.activeUserService.time.dateAsString(entity.departed),
            'departed',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.departed
        )

        this.loadSheetId = new DetailEntry(
            'Load sheet id',
            entity.loadSheetId,
            'loadSheetId',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadSheetId
        )


        const activitysCore = app.makeCore( 'activity');
        activitysCore.soGet = SOGetActivity.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        activitysCore.soGet.queryOptions.sortKey = 'dateCreated';
        activitysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            activitysCore
        )
        activitysCore.title = heading ? heading : activitysCore.title
        activitysCore.listTitle = 'Activitys'

        activitysCore.configureForDisplay(parentEntityViewSpec)

        this.activitys = new DetailEntry(
          heading ? heading : activitysCore.listTitle,
          null,
          'activitys',
          BaseDetailType.embeddedList,
          activitysCore,
          EditableType.never
        )

        const weightsCore = app.makeCore( 'weight');
        weightsCore.soGet = SOGetWeight.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            weightsCore
        )
        weightsCore.title = heading ? heading : weightsCore.title
        weightsCore.listTitle = 'Weights'

        weightsCore.configureForDisplay(parentEntityViewSpec)

        this.weights = new DetailEntry(
          heading ? heading : weightsCore.listTitle,
          null,
          'weights',
          BaseDetailType.embeddedList,
          weightsCore,
          EditableType.never
        )

        const hemonsCore = app.makeCore( 'hemon');
        hemonsCore.soGet = SOGetHemon.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hemonsCore
        )
        hemonsCore.title = heading ? heading : hemonsCore.title
        hemonsCore.listTitle = 'Hemons'

        hemonsCore.configureForDisplay(parentEntityViewSpec)

        this.hemons = new DetailEntry(
          heading ? heading : hemonsCore.listTitle,
          null,
          'hemons',
          BaseDetailType.embeddedList,
          hemonsCore,
          EditableType.never
        )

        const impuritysCore = app.makeCore( 'impurity');
        impuritysCore.soGet = SOGetImpurity.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            impuritysCore
        )
        impuritysCore.title = heading ? heading : impuritysCore.title
        impuritysCore.listTitle = 'Impuritys'

        impuritysCore.configureForDisplay(parentEntityViewSpec)

        this.impuritys = new DetailEntry(
          heading ? heading : impuritysCore.listTitle,
          null,
          'impuritys',
          BaseDetailType.embeddedList,
          impuritysCore,
          EditableType.never
        )

        const checklistsCore = app.makeCore( 'checklist');
        checklistsCore.soGet = SOGetChecklist.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            checklistsCore
        )
        checklistsCore.title = heading ? heading : checklistsCore.title
        checklistsCore.listTitle = 'Checklists'

        checklistsCore.configureForDisplay(parentEntityViewSpec)

        this.checklists = new DetailEntry(
          heading ? heading : checklistsCore.listTitle,
          null,
          'checklists',
          BaseDetailType.embeddedList,
          checklistsCore,
          EditableType.never
        )

        const tasksCore = app.makeCore( 'task');
        tasksCore.soGet = SOGetTask.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        tasksCore.soGet.queryOptions.sortKey = 'dateCreated';
        tasksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tasksCore
        )
        tasksCore.title = heading ? heading : tasksCore.title
        tasksCore.listTitle = 'Tasks'

        tasksCore.configureForDisplay(parentEntityViewSpec)

        this.tasks = new DetailEntry(
          heading ? heading : tasksCore.listTitle,
          null,
          'tasks',
          BaseDetailType.embeddedList,
          tasksCore,
          EditableType.never
        )

        const bolsCore = app.makeCore( 'bol');
        bolsCore.soGet = SOGetBol.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            bolsCore
        )
        bolsCore.title = heading ? heading : bolsCore.title
        bolsCore.listTitle = 'Bols'

        bolsCore.configureForDisplay(parentEntityViewSpec)

        this.bols = new DetailEntry(
          heading ? heading : bolsCore.listTitle,
          null,
          'bols',
          BaseDetailType.embeddedList,
          bolsCore,
          EditableType.never
        )

        const loadfeesCore = app.makeCore( 'loadfee');
        loadfeesCore.soGet = SOGetLoadfee.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadfeesCore
        )
        loadfeesCore.title = heading ? heading : loadfeesCore.title
        loadfeesCore.listTitle = 'Loadfees'

        loadfeesCore.configureForDisplay(parentEntityViewSpec)

        this.loadfees = new DetailEntry(
          heading ? heading : loadfeesCore.listTitle,
          null,
          'loadfees',
          BaseDetailType.embeddedList,
          loadfeesCore,
          EditableType.never
        )
        this.loadfees.limitViewToRoles = [
            'scheduler',
            'accountant',
        ];

        const trailerCore = app.makeCore( 'trailer');
        trailerCore.soGet = SOGetTrailer.construct({
        });
        let trailerCeProtocol;
        if (entity.trailer) {
            trailerCeProtocol = createCEProtocol(
                trailerCore.ceProtocol,
                parentEntityViewSpec,
                trailerCore,
                entity.trailer,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            trailerCore
        )
        trailerCore.title = heading ? heading : trailerCore.title
        trailerCore.listTitle = 'Trailer'

        if (entity.trailer) {
            displayStringFactory.reconfigure(
                entity.trailer,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        trailerCore.configureForDisplay(parentEntityViewSpec)

        this.trailer = new DetailEntry(
          heading ? heading : trailerCore.listTitle,
          trailerCeProtocol ? trailerCeProtocol.entityListView.fullHeading : null,
          'trailer',
          BaseDetailType.pushedDetail,
          trailerCore,
          EditableType.always,
          formFields.trailer
        )
        if (entity.trailer) {
          this.trailer.ceProtocol = trailerCeProtocol;
          this.trailer.cachedListEntry = trailerCore.makeListEntry(parentEntityViewSpec, entity.trailer, 0)
          trailerCore.selectedEntity = entity.trailer;
        }
        this.trailer.singleFieldSelect = true

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.always,
          formFields.account
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const carrierCore = app.makeCore( 'organization');
        carrierCore.soGet = SOGetOrganization.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            carrierCore
        )
        carrierCore.title = heading ? heading : carrierCore.title
        carrierCore.listTitle = 'Carrier'

        carrierCore.configureForDisplay(parentEntityViewSpec)

        this.carrier = new DetailEntry(
          heading ? heading : carrierCore.listTitle,
          null,
          'carrier',
          BaseDetailType.pushedDetail,
          carrierCore,
          EditableType.never
        )
        this.carrier.singleFieldSelect = true

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        this.distribution.singleFieldSelect = true

        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        let loadCeProtocol;
        if (entity.load) {
            loadCeProtocol = createCEProtocol(
                loadCore.ceProtocol,
                parentEntityViewSpec,
                loadCore,
                entity.load,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        if (entity.load) {
            displayStringFactory.reconfigure(
                entity.load,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          loadCeProtocol ? loadCeProtocol.entityListView.fullHeading : null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.always,
          formFields.load
        )
        if (entity.load) {
          this.load.ceProtocol = loadCeProtocol;
          this.load.cachedListEntry = loadCore.makeListEntry(parentEntityViewSpec, entity.load, 0)
          loadCore.selectedEntity = entity.load;
        }
        this.load.singleFieldSelect = true

        const sitesessionsCore = app.makeCore( 'sitesession');
        sitesessionsCore.soGet = SOGetSitesession.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            sitesessionsCore
        )
        sitesessionsCore.title = heading ? heading : sitesessionsCore.title
        sitesessionsCore.listTitle = 'Sitesessions'

        sitesessionsCore.configureForDisplay(parentEntityViewSpec)

        this.sitesessions = new DetailEntry(
          heading ? heading : sitesessionsCore.listTitle,
          null,
          'sitesessions',
          BaseDetailType.embeddedList,
          sitesessionsCore,
          EditableType.never
        )

        const baysessionsCore = app.makeCore( 'baysession');
        baysessionsCore.soGet = SOGetBaysession.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            baysessionsCore
        )
        baysessionsCore.title = heading ? heading : baysessionsCore.title
        baysessionsCore.listTitle = 'Baysessions'

        baysessionsCore.configureForDisplay(parentEntityViewSpec)

        this.baysessions = new DetailEntry(
          heading ? heading : baysessionsCore.listTitle,
          null,
          'baysessions',
          BaseDetailType.embeddedList,
          baysessionsCore,
          EditableType.never
        )

        const deltasCore = app.makeCore( 'delta');
        deltasCore.soGet = SOGetDelta.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deltasCore
        )
        deltasCore.title = heading ? heading : deltasCore.title
        deltasCore.listTitle = 'Deltas'

        deltasCore.configureForDisplay(parentEntityViewSpec)

        this.deltas = new DetailEntry(
          heading ? heading : deltasCore.listTitle,
          null,
          'deltas',
          BaseDetailType.embeddedList,
          deltasCore,
          EditableType.never
        )

        const hestatusitemsCore = app.makeCore( 'hestatusitem');
        hestatusitemsCore.soGet = SOGetHestatusitem.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hestatusitemsCore
        )
        hestatusitemsCore.title = heading ? heading : hestatusitemsCore.title
        hestatusitemsCore.listTitle = 'Hestatusitems'

        hestatusitemsCore.configureForDisplay(parentEntityViewSpec)

        this.hestatusitems = new DetailEntry(
          heading ? heading : hestatusitemsCore.listTitle,
          null,
          'hestatusitems',
          BaseDetailType.embeddedList,
          hestatusitemsCore,
          EditableType.never
        )

        const lastHemonCore = app.makeCore( 'hemon');
        lastHemonCore.soGet = SOGetHemon.construct({
          containersession: new SOGetContainersession(entity.uid),
        });
        let lastHemonCeProtocol;
        if (entity.lastHemon) {
            lastHemonCeProtocol = createCEProtocol(
                lastHemonCore.ceProtocol,
                parentEntityViewSpec,
                lastHemonCore,
                entity.lastHemon,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            lastHemonCore
        )
        lastHemonCore.title = heading ? heading : lastHemonCore.title
        lastHemonCore.listTitle = 'Last hemon'

        if (entity.lastHemon) {
            displayStringFactory.reconfigure(
                entity.lastHemon,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        lastHemonCore.configureForDisplay(parentEntityViewSpec)

        this.lastHemon = new DetailEntry(
          heading ? heading : lastHemonCore.listTitle,
          lastHemonCeProtocol ? lastHemonCeProtocol.entityListView.fullHeading : null,
          'lastHemon',
          BaseDetailType.pushedDetail,
          lastHemonCore,
          EditableType.never
        )
        if (entity.lastHemon) {
          this.lastHemon.ceProtocol = lastHemonCeProtocol;
          this.lastHemon.cachedListEntry = lastHemonCore.makeListEntry(parentEntityViewSpec, entity.lastHemon, 0)
          lastHemonCore.selectedEntity = entity.lastHemon;
        }
        this.lastHemon.singleFieldSelect = true

        const serviceRequestsCore = app.makeCore( 'serviceRequest');
        serviceRequestsCore.soGet = SOGetServiceRequest.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            serviceRequestsCore
        )
        serviceRequestsCore.title = heading ? heading : serviceRequestsCore.title
        serviceRequestsCore.listTitle = 'Service requests'

        serviceRequestsCore.configureForDisplay(parentEntityViewSpec)

        this.serviceRequests = new DetailEntry(
          heading ? heading : serviceRequestsCore.listTitle,
          null,
          'serviceRequests',
          BaseDetailType.embeddedList,
          serviceRequestsCore,
          EditableType.never
        )

        const subscriptionsCore = app.makeCore( 'subscription');
        subscriptionsCore.soGet = SOGetSubscription.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            subscriptionsCore
        )
        subscriptionsCore.title = heading ? heading : subscriptionsCore.title
        subscriptionsCore.listTitle = 'Subscriptions'

        subscriptionsCore.configureForDisplay(parentEntityViewSpec)

        this.subscriptions = new DetailEntry(
          heading ? heading : subscriptionsCore.listTitle,
          null,
          'subscriptions',
          BaseDetailType.embeddedList,
          subscriptionsCore,
          EditableType.never
        )
    }
}