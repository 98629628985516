import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutPlant } from './so-put-plant';
import { SOGetPlant } from './so-get-plant';
import { SOPostPlant } from './so-post-plant';
import { SODeletePlant } from './so-delete-plant';
import {SORespPlant} from './so-resp-plant';
import {SOPluralPlant} from './so-plural-plant';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOPlantService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'plant',
      entityCore
    );
  }

  /** GET Plant by id. Will 404 if id not found */
  get(request: SOGetPlant): Observable<SOPluralPlant> {
    return this.httpClient.get<SOPluralPlant>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralPlant, resp) }),
      tap(_ => this.log(`fetched Plant`)),
      catchError(this.handleError<SOPluralPlant>(`get Plant`))
    );
  }

  /** POST: add a new Plant to the server */
  post(request: SOPostPlant): Observable<SORespPlant> {
    return this.httpClient.post<SORespPlant>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPlant, resp) }),
      tap((response: SORespPlant) => this.log(`added Plant w/ id=${response.uid}`)),
      catchError(this.handleError<SORespPlant>('Plant post'))
    );
  }

  /** PUT: update Plant  */
  put(request: SOPutPlant): Observable<SORespPlant> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPlant>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPlant, resp) }),
      tap((response: SORespPlant) => this.log(`edited Plant w/ uid=${uid}`)),
      catchError(this.handleError<SORespPlant>('Plant put'))
    );
  }

  /** DELETE: delete the Plant from the server */
  delete(request: SODeletePlant): Observable<SORespPlant> {
    return this.httpClient.delete<SORespPlant>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Plant uid=${request.uid}`)),
      catchError(this.handleError<SORespPlant>('delete Plant'))
    );
  }
}
