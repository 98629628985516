import {SORespOrganization} from '../../core/organization/so-resp-organization';
import {OrganizationCore} from './organization.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutOrganizationFormFields} from './put-organization-form-fields';
import { CEOrganizationAttributes } from './ce-organization-attributes'


export class CEOrganizationBase extends CEBase {

    public details: CEOrganizationAttributes;
    public fromFields: PutOrganizationFormFields = new PutOrganizationFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: OrganizationCore,
        public entity: SORespOrganization,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEOrganizationAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.handle)
        this.attributes.push(this.details.ownerId)
        this.attributes.push(this.details.users)
        this.attributes.push(this.details.customer)
        this.attributes.push(this.details.carrier)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.providers)
        this.attributes.push(this.details.orgrolas)
        this.attributes.push(this.details.trailers)
        this.attributes.push(this.details.bols)
        this.attributes.push(this.details.containersessions)
        this.attributes.push(this.details.featureascs)

    }
}