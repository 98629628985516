import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutExternalindexprice } from './so-put-externalindexprice';
import { SOGetExternalindexprice } from './so-get-externalindexprice';
import { SOPostExternalindexprice } from './so-post-externalindexprice';
import { SODeleteExternalindexprice } from './so-delete-externalindexprice';
import {SORespExternalindexprice} from './so-resp-externalindexprice';
import {SOPluralExternalindexprice} from './so-plural-externalindexprice';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOExternalindexpriceService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'externalindexprice',
      entityCore
    );
  }

  /** GET Externalindexprice by id. Will 404 if id not found */
  get(request: SOGetExternalindexprice): Observable<SOPluralExternalindexprice> {
    return this.httpClient.get<SOPluralExternalindexprice>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralExternalindexprice, resp) }),
      tap(_ => this.log(`fetched Externalindexprice`)),
      catchError(this.handleError<SOPluralExternalindexprice>(`get Externalindexprice`))
    );
  }

  /** POST: add a new Externalindexprice to the server */
  post(request: SOPostExternalindexprice): Observable<SORespExternalindexprice> {
    return this.httpClient.post<SORespExternalindexprice>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespExternalindexprice, resp) }),
      tap((response: SORespExternalindexprice) => this.log(`added Externalindexprice w/ id=${response.uid}`)),
      catchError(this.handleError<SORespExternalindexprice>('Externalindexprice post'))
    );
  }

  /** PUT: update Externalindexprice  */
  put(request: SOPutExternalindexprice): Observable<SORespExternalindexprice> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespExternalindexprice>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespExternalindexprice, resp) }),
      tap((response: SORespExternalindexprice) => this.log(`edited Externalindexprice w/ uid=${uid}`)),
      catchError(this.handleError<SORespExternalindexprice>('Externalindexprice put'))
    );
  }

  /** DELETE: delete the Externalindexprice from the server */
  delete(request: SODeleteExternalindexprice): Observable<SORespExternalindexprice> {
    return this.httpClient.delete<SORespExternalindexprice>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Externalindexprice uid=${request.uid}`)),
      catchError(this.handleError<SORespExternalindexprice>('delete Externalindexprice'))
    );
  }
}
