import {SORespQuotelet} from '../../core/quotelet/so-resp-quotelet';
import {QuoteletCore} from './quotelet.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutQuoteletFormFields} from './put-quotelet-form-fields';
import { CEQuoteletAttributes } from './ce-quotelet-attributes'


export class CEQuoteletBase extends CEBase {

    public details: CEQuoteletAttributes;
    public fromFields: PutQuoteletFormFields = new PutQuoteletFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: QuoteletCore,
        public entity: SORespQuotelet,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEQuoteletAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.units)
        this.attributes.push(this.details.quantityVolume)
        this.attributes.push(this.details.quantityLoads)
        this.attributes.push(this.details.indexRate)
        this.attributes.push(this.details.variableRate)
        this.attributes.push(this.details.totalRate)
        this.attributes.push(this.details.totalAmount)
        this.attributes.push(this.details.order)

    }
}