import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOItemService} from '../../core/item/eo-item';
import {SOGetItem} from '../../core/item/so-get-item';
import {RAPutItem} from './ra-put-item';
import {RAPostItem} from './ra-post-item';
import {RADeleteItem} from './ra-delete-item';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEItem } from "./ce-item"


export class ItemCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'item';
  title = 'Item';
  ceProtocol: CEConstructor = CEItem

  entityService: EOItemService;
  soGetClass = SOGetItem;
  soGet: SOGetItem;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOItemService
    );
    this.adapters = [
      new RAPostItem(this),
      new RAPutItem(this),
      new RADeleteItem(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetItem();
    this.soGet.queryOptions.limit = this.limit;
  }


}