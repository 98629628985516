import {SORespTrailerauth} from '../../core/trailerauth/so-resp-trailerauth';
import {TrailerauthCore} from './trailerauth.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTrailerauthFormFields} from './put-trailerauth-form-fields';
import { CETrailerauthAttributes } from './ce-trailerauth-attributes'


export class CETrailerauthBase extends CEBase {

    public details: CETrailerauthAttributes;
    public fromFields: PutTrailerauthFormFields = new PutTrailerauthFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TrailerauthCore,
        public entity: SORespTrailerauth,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETrailerauthAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.trailer)

    }
}