import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODowntimeService} from '../../core/downtime/eo-downtime';
import {SOGetDowntime} from '../../core/downtime/so-get-downtime';
import {RAPutDowntime} from './ra-put-downtime';
import {RAPostDowntime} from './ra-post-downtime';
import {RADeleteDowntime} from './ra-delete-downtime';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEDowntime } from "./ce-downtime"


export class DowntimeCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'downtime';
  title = 'Downtime';
  ceProtocol: CEConstructor = CEDowntime

  entityService: EODowntimeService;
  soGetClass = SOGetDowntime;
  soGet: SOGetDowntime;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODowntimeService
    );
    this.adapters = [
      new RAPostDowntime(this),
      new RAPutDowntime(this),
      new RADeleteDowntime(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDowntime();
    this.soGet.queryOptions.limit = this.limit;
  }


}