import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAccountreportService} from '../../core/accountreport/eo-accountreport';
import {SOGetAccountreport} from '../../core/accountreport/so-get-accountreport';
import {RAPutAccountreport} from './ra-put-accountreport';
import {RAPostAccountreport} from './ra-post-accountreport';
import {RADeleteAccountreport} from './ra-delete-accountreport';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEAccountreport } from "./ce-accountreport"


export class AccountreportCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'accountreport';
  title = 'Accountreport';
  ceProtocol: CEConstructor = CEAccountreport

  entityService: EOAccountreportService;
  soGetClass = SOGetAccountreport;
  soGet: SOGetAccountreport;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAccountreportService
    );
    this.adapters = [
      new RAPostAccountreport(this),
      new RAPutAccountreport(this),
      new RADeleteAccountreport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAccountreport();
    this.soGet.queryOptions.limit = this.limit;
  }


}