import { SOGetHmonth } from '../hmonth/so-get-hmonth';
import { SOGetAgreement } from '../agreement/so-get-agreement';
import { SOGetAgreeyear } from '../agreeyear/so-get-agreeyear';


export class SOPutAgreemonth {

  constructor(
    public uid: string,
    public state: string = null,
    public mcq: number = null,
    public delta: number = null,
    public hmonth: SOGetHmonth = null,
    public agreement: SOGetAgreement = null,
    public agreeyear: SOGetAgreeyear = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAgreemonth(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'mcq' in params ? params.mcq : null,
      'delta' in params ? params.delta : null,
      'hmonth' in params ? params.hmonth : null,
      'agreement' in params ? params.agreement : null,
      'agreeyear' in params ? params.agreeyear : null,
    );
  }
}
