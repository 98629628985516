import { SOGetCustomer } from '../customer/so-get-customer';
import { SOGetCarrier } from '../carrier/so-get-carrier';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetOrganization implements QueryProtocol {

  constructor(
    public uid: string = null,
    public handle: string = null,
    public ownerId: string = null,
    public customer: SOGetCustomer = null,
    public carrier: SOGetCarrier = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetOrganization(
      'uid' in params ? params.uid : null,
      'handle' in params ? params.handle : null,
      'ownerId' in params ? params.ownerId : null,
      'customer' in params ? params.customer : null,
      'carrier' in params ? params.carrier : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
