import {SOPostExternalindexprice} from '../../core/externalindexprice/so-post-externalindexprice';
import {ExternalindexpriceCoreBase} from './externalindexprice.core.base';
import {SOGetExternalindexprice} from '../../core/externalindexprice/so-get-externalindexprice';
import {FormField, SelectionOption} from 'lionheartland';
import {postExternalindexpriceFormFieldOptions} from './post-externalindexprice-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostExternalindexprice extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postExternalindexpriceFormFieldOptions);

  RequestClass = SOPostExternalindexprice

  constructor(
    public entityCore: ExternalindexpriceCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('data'),
      this.getFormValueFromIdentifier('eventProcessedUtcTime'),
      this.getFormValueFromIdentifier('partitionId'),
      this.getFormValueFromIdentifier('eventEnqueuedUtcTime'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}