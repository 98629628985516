import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutLoadfee } from './so-put-loadfee';
import { SOGetLoadfee } from './so-get-loadfee';
import { SOPostLoadfee } from './so-post-loadfee';
import { SODeleteLoadfee } from './so-delete-loadfee';
import {SORespLoadfee} from './so-resp-loadfee';
import {SOPluralLoadfee} from './so-plural-loadfee';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOLoadfeeService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'loadfee',
      entityCore
    );
  }

  /** GET Loadfee by id. Will 404 if id not found */
  get(request: SOGetLoadfee): Observable<SOPluralLoadfee> {
    return this.httpClient.get<SOPluralLoadfee>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralLoadfee, resp) }),
      tap(_ => this.log(`fetched Loadfee`)),
      catchError(this.handleError<SOPluralLoadfee>(`get Loadfee`))
    );
  }

  /** POST: add a new Loadfee to the server */
  post(request: SOPostLoadfee): Observable<SORespLoadfee> {
    return this.httpClient.post<SORespLoadfee>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLoadfee, resp) }),
      tap((response: SORespLoadfee) => this.log(`added Loadfee w/ id=${response.uid}`)),
      catchError(this.handleError<SORespLoadfee>('Loadfee post'))
    );
  }

  /** PUT: update Loadfee  */
  put(request: SOPutLoadfee): Observable<SORespLoadfee> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespLoadfee>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLoadfee, resp) }),
      tap((response: SORespLoadfee) => this.log(`edited Loadfee w/ uid=${uid}`)),
      catchError(this.handleError<SORespLoadfee>('Loadfee put'))
    );
  }

  /** DELETE: delete the Loadfee from the server */
  delete(request: SODeleteLoadfee): Observable<SORespLoadfee> {
    return this.httpClient.delete<SORespLoadfee>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Loadfee uid=${request.uid}`)),
      catchError(this.handleError<SORespLoadfee>('delete Loadfee'))
    );
  }
}
