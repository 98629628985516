import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTankreport } from './so-put-tankreport';
import { SOGetTankreport } from './so-get-tankreport';
import { SOPostTankreport } from './so-post-tankreport';
import { SODeleteTankreport } from './so-delete-tankreport';
import {SORespTankreport} from './so-resp-tankreport';
import {SOPluralTankreport} from './so-plural-tankreport';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTankreportService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'tankreport',
      entityCore
    );
  }

  /** GET Tankreport by id. Will 404 if id not found */
  get(request: SOGetTankreport): Observable<SOPluralTankreport> {
    return this.httpClient.get<SOPluralTankreport>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTankreport, resp) }),
      tap(_ => this.log(`fetched Tankreport`)),
      catchError(this.handleError<SOPluralTankreport>(`get Tankreport`))
    );
  }

  /** POST: add a new Tankreport to the server */
  post(request: SOPostTankreport): Observable<SORespTankreport> {
    return this.httpClient.post<SORespTankreport>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTankreport, resp) }),
      tap((response: SORespTankreport) => this.log(`added Tankreport w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTankreport>('Tankreport post'))
    );
  }

  /** PUT: update Tankreport  */
  put(request: SOPutTankreport): Observable<SORespTankreport> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTankreport>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTankreport, resp) }),
      tap((response: SORespTankreport) => this.log(`edited Tankreport w/ uid=${uid}`)),
      catchError(this.handleError<SORespTankreport>('Tankreport put'))
    );
  }

  /** DELETE: delete the Tankreport from the server */
  delete(request: SODeleteTankreport): Observable<SORespTankreport> {
    return this.httpClient.delete<SORespTankreport>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Tankreport uid=${request.uid}`)),
      catchError(this.handleError<SORespTankreport>('delete Tankreport'))
    );
  }
}
