import { Type } from 'class-transformer';
import { SORespDowntime } from '../downtime/so-resp-downtime';
import { SORespAgreemonth } from '../agreemonth/so-resp-agreemonth';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespAdjustment implements ObjectiveProtocol {

  entityType: string = 'adjustment';
  uid: string;
  quantity: number;
  description: string = "";
  acqAdjustment: boolean = false;
  @Type(() => SORespDowntime)
  downtime: SORespDowntime = null;
  @Type(() => SORespAgreemonth)
  agreemonth: SORespAgreemonth;
}