import {SOPostWonderware} from '../../core/wonderware/so-post-wonderware';
import {WonderwareCoreBase} from './wonderware.core.base';
import {SOGetWonderware} from '../../core/wonderware/so-get-wonderware';
import {FormField, SelectionOption} from 'lionheartland';
import {postWonderwareFormFieldOptions} from './post-wonderware-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostWonderware extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postWonderwareFormFieldOptions);

  RequestClass = SOPostWonderware

  constructor(
    public entityCore: WonderwareCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('data'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}