import { Type } from 'class-transformer';
import { SORespTempclin } from '../tempclin/so-resp-tempclin';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespClin implements ObjectiveProtocol {

  entityType: string = 'clin';
  uid: string;
  name: string;
  state: string;
  type: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  quantity: number = 0;
  quantityUsed: number = 0;
  loadQuantity: number = 0;
  loadQuantityUsed: number = 0;
  description: string = "";
  @Type(() => SORespTempclin)
  tempclin: SORespTempclin = null;
}