import { SOGetAccount } from '../account/so-get-account';
import { SOGetTrailer } from '../trailer/so-get-trailer';


export class SOPutTrailerauth {

  constructor(
    public uid: string,
    public account: SOGetAccount = null,
    public trailer: SOGetTrailer = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTrailerauth(
      'uid' in params ? params.uid : null,
      'account' in params ? params.account : null,
      'trailer' in params ? params.trailer : null,
    );
  }
}
