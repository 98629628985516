import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOFeatureService} from '../../core/feature/eo-feature';
import {SOGetFeature} from '../../core/feature/so-get-feature';
import {RAPutFeature} from './ra-put-feature';
import {RAPostFeature} from './ra-post-feature';
import {RADeleteFeature} from './ra-delete-feature';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEFeature } from "./ce-feature"


export class FeatureCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'feature';
  title = 'Feature';
  ceProtocol: CEConstructor = CEFeature

  entityService: EOFeatureService;
  soGetClass = SOGetFeature;
  soGet: SOGetFeature;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOFeatureService
    );
    this.adapters = [
      new RAPostFeature(this),
      new RAPutFeature(this),
      new RADeleteFeature(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetFeature();
    this.soGet.queryOptions.limit = this.limit;
  }


}