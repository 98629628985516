import { SOGetLoad } from '../load/so-get-load';


export class SOPostImpurity {

  constructor(
    public state: string,
    public h20: number,
    public neon: number,
    public h2: number,
    public o2: number,
    public n2: number,
    public carbonMonoxide: number,
    public hydrocarbons: number,
    public carbonDioxide: number,
    public load: SOGetLoad,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostImpurity(
      'state' in params ? params.state : null,
      'h20' in params ? params.h20 : null,
      'neon' in params ? params.neon : null,
      'h2' in params ? params.h2 : null,
      'o2' in params ? params.o2 : null,
      'n2' in params ? params.n2 : null,
      'carbonMonoxide' in params ? params.carbonMonoxide : null,
      'hydrocarbons' in params ? params.hydrocarbons : null,
      'carbonDioxide' in params ? params.carbonDioxide : null,
      'load' in params ? params.load : null,
    );
  }
}
