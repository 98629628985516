import {SORespChecklistitem} from '../../core/checklistitem/so-resp-checklistitem';
import {ChecklistitemCore} from './checklistitem.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutChecklistitemFormFields} from './put-checklistitem-form-fields';
import {SOGetChecklistitem} from '../../core/checklistitem/so-get-checklistitem';
import {SOGetChecklist} from '../../core/checklist/so-get-checklist';


export class CEChecklistitemAttributesBase {

    body: DetailEntry
    index: DetailEntry
    required: DetailEntry
    checked: DetailEntry
    checkedDatetime: DetailEntry
    checklist: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespChecklistitem,
        app: GasDirectApp,
        formFields: PutChecklistitemFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.body = new DetailEntry(
            'Body',
            entity.body,
            'body',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.body
        )

        this.index = new DetailEntry(
            'Index',
            entity.index,
            'index',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.index
        )

        this.required = new DetailEntry(
            'Required',
            entity.required,
            'required',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.required
        )

        this.checked = new DetailEntry(
            'Checked',
            entity.checked,
            'checked',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.checked
        )

        this.checkedDatetime = new DetailEntry(
            'Checked datetime',
            app.activeUserService.time.dateAsString(entity.checkedDatetime),
            'checkedDatetime',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const checklistCore = app.makeCore( 'checklist');
        checklistCore.soGet = SOGetChecklist.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            checklistCore
        )
        checklistCore.title = heading ? heading : checklistCore.title
        checklistCore.listTitle = 'Checklist'

        checklistCore.configureForDisplay(parentEntityViewSpec)

        this.checklist = new DetailEntry(
          heading ? heading : checklistCore.listTitle,
          null,
          'checklist',
          BaseDetailType.pushedDetail,
          checklistCore,
          EditableType.always,
          formFields.checklist
        )
        this.checklist.singleFieldSelect = true
    }
}