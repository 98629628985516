import {SOPostTempagreetract} from '../../core/tempagreetract/so-post-tempagreetract';
import {TempagreetractCoreBase} from './tempagreetract.core.base';
import {SOGetTempagreetract} from '../../core/tempagreetract/so-get-tempagreetract';
import {FormField, SelectionOption} from 'lionheartland';
import {postTempagreetractFormFieldOptions} from './post-tempagreetract-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TempagreementCore} from '../tempagreement/tempagreement.core';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';
import {TemptractCore} from '../temptract/temptract.core';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';

export class RAPostTempagreetract extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTempagreetractFormFieldOptions);

  RequestClass = SOPostTempagreetract

  constructor(
    public entityCore: TempagreetractCoreBase
  ) {
    super();
    if (postTempagreetractFormFieldOptions.tempagreement) {
      postTempagreetractFormFieldOptions.tempagreement.makeCore = true;
    }
    if (postTempagreetractFormFieldOptions.temptract) {
      postTempagreetractFormFieldOptions.temptract.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'tempagreement',
        SOGetTempagreement,
      ),
      this.getFormValueFromIdentifier(
        'temptract',
        SOGetTemptract,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}