import { SOGetAgreement } from '../agreement/so-get-agreement';
import { SOGetTemptract } from '../temptract/so-get-temptract';


export class SOPostContract {

  constructor(
    public name: string,
    public takeOrPay: number,
    public minLoadSizeToQualify: number,
    public docusignLink: string,
    public state: string,
    public type: string,
    public start: Date,
    public end: Date,
    public agreement: SOGetAgreement,
    public description: string = "",
    public temptract: SOGetTemptract = null,
    public loadType: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostContract(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'takeOrPay' in params ? params.takeOrPay : null,
      'minLoadSizeToQualify' in params ? params.minLoadSizeToQualify : null,
      'docusignLink' in params ? params.docusignLink : null,
      'state' in params ? params.state : null,
      'type' in params ? params.type : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'agreement' in params ? params.agreement : null,
      'temptract' in params ? params.temptract : null,
      'loadType' in params ? params.loadType : null,
    );
  }
}
