import {SOPutTask} from '../../core/task/so-put-task';
import {TaskCoreBase} from './task.core.base';
import {SOGetTask} from '../../core/task/so-get-task';
import {FormField, SelectionOption} from 'lionheartland';
import {putTaskFormFieldOptions} from './put-task-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {WeightCore} from '../weight/weight.core';
import {SOGetWeight} from '../../core/weight/so-get-weight';

export class RAPutTask extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTaskFormFieldOptions);

  RequestClass = SOPutTask

  constructor(
    public entityCore: TaskCoreBase
  ) {
    super();
    if (putTaskFormFieldOptions.weight) {
      putTaskFormFieldOptions.weight.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('trailerGone'),
      this.getFormValueFromIdentifier('completed'),
      this.getFormValueFromIdentifier(
        'weight',
        SOGetWeight,
      ),
      this.getFormValueFromIdentifier('location'),
      this.getFormValueFromIdentifier('loadStatus'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}