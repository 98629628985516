import {SORespTempagreetract} from '../../core/tempagreetract/so-resp-tempagreetract';
import {TempagreetractCore} from './tempagreetract.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTempagreetractFormFields} from './put-tempagreetract-form-fields';
import {SOGetTempagreetract} from '../../core/tempagreetract/so-get-tempagreetract';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';


export class CETempagreetractAttributesBase {

    tempagreement: DetailEntry
    temptract: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTempagreetract,
        app: GasDirectApp,
        formFields: PutTempagreetractFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const tempagreementCore = app.makeCore( 'tempagreement');
        tempagreementCore.soGet = SOGetTempagreement.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempagreementCore
        )
        tempagreementCore.title = heading ? heading : tempagreementCore.title
        tempagreementCore.listTitle = 'Tempagreement'

        tempagreementCore.configureForDisplay(parentEntityViewSpec)

        this.tempagreement = new DetailEntry(
          heading ? heading : tempagreementCore.listTitle,
          null,
          'tempagreement',
          BaseDetailType.pushedDetail,
          tempagreementCore,
          EditableType.always,
          formFields.tempagreement
        )
        this.tempagreement.singleFieldSelect = true

        const temptractCore = app.makeCore( 'temptract');
        temptractCore.soGet = SOGetTemptract.construct({
        });
        let temptractCeProtocol;
        if (entity.temptract) {
            temptractCeProtocol = createCEProtocol(
                temptractCore.ceProtocol,
                parentEntityViewSpec,
                temptractCore,
                entity.temptract,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            temptractCore
        )
        temptractCore.title = heading ? heading : temptractCore.title
        temptractCore.listTitle = 'Temptract'

        if (entity.temptract) {
            displayStringFactory.reconfigure(
                entity.temptract,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        temptractCore.configureForDisplay(parentEntityViewSpec)

        this.temptract = new DetailEntry(
          heading ? heading : temptractCore.listTitle,
          temptractCeProtocol ? temptractCeProtocol.entityListView.fullHeading : null,
          'temptract',
          BaseDetailType.pushedDetail,
          temptractCore,
          EditableType.always,
          formFields.temptract
        )
        if (entity.temptract) {
          this.temptract.ceProtocol = temptractCeProtocol;
          this.temptract.cachedListEntry = temptractCore.makeListEntry(parentEntityViewSpec, entity.temptract, 0)
          temptractCore.selectedEntity = entity.temptract;
        }
        this.temptract.singleFieldSelect = true
    }
}