import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespFeeschedule } from '../feeschedule/so-resp-feeschedule';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespAgreement implements ObjectiveProtocol {

  entityType: string = 'agreement';
  uid: string;
  name: string;
  description: string = "";
  docusignLink: string = "";
  units: string;
  signedDocumentUrl: string = null;
  state: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  autoRenew: boolean = false;
  @Type(() => SORespAccount)
  account: SORespAccount;
  acq: number = null;
  top: number = null;
  @Type(() => SORespFeeschedule)
  feeschedule: SORespFeeschedule = null;
  variableAcq: boolean = null;
  rateable: boolean = null;
  interruptible: boolean = null;
  spotQuantity: number = null;
  prePaymentRequired: boolean = null;
  sapContractNumber: string = null;
}