import {SORespRelease} from '../../core/release/so-resp-release';
import {ReleaseCore} from './release.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutReleaseFormFields} from './put-release-form-fields';
import { CEReleaseAttributes } from './ce-release-attributes'


export class CEReleaseBase extends CEBase {

    public details: CEReleaseAttributes;
    public fromFields: PutReleaseFormFields = new PutReleaseFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ReleaseCore,
        public entity: SORespRelease,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEReleaseAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.summary)
        this.attributes.push(this.details.dateScheduled)
        this.attributes.push(this.details.dateDeployed)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.message)
        this.attributes.push(this.details.backlogitems)

    }
}