import { SOGetAccountreport } from '../accountreport/so-get-accountreport';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetInreport implements QueryProtocol {

  constructor(
    public uid: string = null,
    public accounreport: SOGetAccountreport = null,
    public distributionmonth: SOGetDistributionmonth = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetInreport(
      'uid' in params ? params.uid : null,
      'accounreport' in params ? params.accounreport : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
