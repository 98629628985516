import { SOGetTank } from '../tank/so-get-tank';


export class SOPutTankreport {

  constructor(
    public uid: string,
    public dateCreated: Date = null,
    public value: number = null,
    public tank: SOGetTank = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTankreport(
      'uid' in params ? params.uid : null,
      'dateCreated' in params ? params.dateCreated : null,
      'value' in params ? params.value : null,
      'tank' in params ? params.tank : null,
    );
  }
}
