import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTaskreportService} from '../../core/taskreport/eo-taskreport';
import {SOGetTaskreport} from '../../core/taskreport/so-get-taskreport';
import {RAPutTaskreport} from './ra-put-taskreport';
import {RAPostTaskreport} from './ra-post-taskreport';
import {RADeleteTaskreport} from './ra-delete-taskreport';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETaskreport } from "./ce-taskreport"


export class TaskreportCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'taskreport';
  title = 'Taskreport';
  ceProtocol: CEConstructor = CETaskreport

  entityService: EOTaskreportService;
  soGetClass = SOGetTaskreport;
  soGet: SOGetTaskreport;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTaskreportService
    );
    this.adapters = [
      new RAPostTaskreport(this),
      new RAPutTaskreport(this),
      new RADeleteTaskreport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTaskreport();
    this.soGet.queryOptions.limit = this.limit;
  }


}