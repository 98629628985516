import { Type } from 'class-transformer';
import { SORespDistributionyear } from '../distributionyear/so-resp-distributionyear';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDistributionmonth implements ObjectiveProtocol {

  entityType: string = 'distributionmonth';
  uid: string;
  state: string;
  month: number;
  started: boolean = false;
  ordersCreated: boolean = false;
  ordersSubmitted: boolean = false;
  scheduleMade: boolean = false;
  loadsAllocated: boolean = false;
  midMonthCheckpointComplete: boolean = false;
  invoicesSent: boolean = false;
  paymentsReceived: boolean = false;
  closed: boolean = false;
  volumeContracted: number = 0;
  volumeSold: number = 0;
  volumeSoldEstimated: number = 0;
  productionVolume: number = 0;
  productionVolumeEstimated: number = 0;
  spotVolumeContracted: number = 0;
  spotVolumeSold: number = 0;
  excessVolumeEstimated: number = 0;
  @Type(() => SORespDistributionyear)
  distributionyear: SORespDistributionyear;
  numberOfLoads: number = null;
  loadsOrdered: number = null;
  loadsScheduled: number = null;
  loadsFilled: number = null;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
}