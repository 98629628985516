import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutCarrierFormFields {

    public companyName: FormField
    public emailDomain: FormField
    public handle: FormField
    public dateCreated: FormField
    public signedAgreement: FormField

    constructor() {

        this.companyName = new FormField(
          'companyName',
          'Company name',
          null,
          FormFieldType.text,
          false,
        )
        this.emailDomain = new FormField(
          'emailDomain',
          'Email domain',
          null,
          FormFieldType.text,
          false,
        )
        this.handle = new FormField(
          'handle',
          'Handle',
          null,
          FormFieldType.text,
          false,
        )
        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          false,
        )
        this.signedAgreement = new FormField(
          'signedAgreement',
          'Signed agreement',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutCarrierFormFields()

export const putCarrierFormFieldOptions: {[key: string]: FormField} = {
  companyName: fields.companyName,
  emailDomain: fields.emailDomain,
  handle: fields.handle,
  dateCreated: fields.dateCreated,
  signedAgreement: fields.signedAgreement,
};