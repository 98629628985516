import {SORespSlot} from '../../core/slot/so-resp-slot';
import {SlotCore} from './slot.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSlotFormFields} from './put-slot-form-fields';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetCalendar} from '../../core/calendar/so-get-calendar';
import {SOGetReservation} from '../../core/reservation/so-get-reservation';
import {SOGetActivity} from '../../core/activity/so-get-activity';
import {SOGetSlotoption} from '../../core/slotoption/so-get-slotoption';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CESlotAttributesBase {

    status: DetailEntry
    slotNumber: DetailEntry
    slotDay: DetailEntry
    start: DetailEntry
    end: DetailEntry
    account: DetailEntry
    calendar: DetailEntry
    loadType: DetailEntry
    reservations: DetailEntry
    activitys: DetailEntry
    slotoptions: DetailEntry
    distribution: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSlot,
        app: GasDirectApp,
        formFields: PutSlotFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.status
        )
        this.status.listSubheadingIndex = 1;

        this.slotNumber = new DetailEntry(
            'Slot number',
            entity.slotNumber,
            'slotNumber',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.slotNumber
        )

        this.slotDay = new DetailEntry(
            'Slot day',
            entity.slotDay,
            'slotDay',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.slotDay
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )
        this.start.listHeadingIndex = 0;

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )

        this.loadType = new DetailEntry(
            'Load type',
            entity.loadType,
            'loadType',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.always,
          formFields.account
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true
        this.account.listSubheadingIndex = 0;

        const calendarCore = app.makeCore( 'calendar');
        calendarCore.soGet = SOGetCalendar.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            calendarCore
        )
        calendarCore.title = heading ? heading : calendarCore.title
        calendarCore.listTitle = 'Calendar'

        calendarCore.configureForDisplay(parentEntityViewSpec)

        this.calendar = new DetailEntry(
          heading ? heading : calendarCore.listTitle,
          null,
          'calendar',
          BaseDetailType.pushedDetail,
          calendarCore,
          EditableType.always,
          formFields.calendar
        )
        this.calendar.singleFieldSelect = true

        const reservationsCore = app.makeCore( 'reservation');
        reservationsCore.soGet = SOGetReservation.construct({
          slot: new SOGetSlot(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            reservationsCore
        )
        reservationsCore.title = heading ? heading : reservationsCore.title
        reservationsCore.listTitle = 'Reservations'

        reservationsCore.configureForDisplay(parentEntityViewSpec)

        this.reservations = new DetailEntry(
          heading ? heading : reservationsCore.listTitle,
          null,
          'reservations',
          BaseDetailType.embeddedList,
          reservationsCore,
          EditableType.never
        )

        const activitysCore = app.makeCore( 'activity');
        activitysCore.soGet = SOGetActivity.construct({
          slot: new SOGetSlot(entity.uid),
        });
        activitysCore.soGet.queryOptions.sortKey = 'dateCreated';
        activitysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            activitysCore
        )
        activitysCore.title = heading ? heading : activitysCore.title
        activitysCore.listTitle = 'Activitys'

        activitysCore.configureForDisplay(parentEntityViewSpec)

        this.activitys = new DetailEntry(
          heading ? heading : activitysCore.listTitle,
          null,
          'activitys',
          BaseDetailType.pushedList,
          activitysCore,
          EditableType.never
        )

        const slotoptionsCore = app.makeCore( 'slotoption');
        slotoptionsCore.soGet = SOGetSlotoption.construct({
          slot: new SOGetSlot(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotoptionsCore
        )
        slotoptionsCore.title = heading ? heading : slotoptionsCore.title
        slotoptionsCore.listTitle = 'Slotoptions'

        slotoptionsCore.configureForDisplay(parentEntityViewSpec)

        this.slotoptions = new DetailEntry(
          heading ? heading : slotoptionsCore.listTitle,
          null,
          'slotoptions',
          BaseDetailType.embeddedList,
          slotoptionsCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true
    }
}