import {SOPutHyear} from '../../core/hyear/so-put-hyear';
import {HyearCoreBase} from './hyear.core.base';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {FormField, SelectionOption} from 'lionheartland';
import {putHyearFormFieldOptions} from './put-hyear-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {FeescheduleCore} from '../feeschedule/feeschedule.core';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';

export class RAPutHyear extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putHyearFormFieldOptions);

  RequestClass = SOPutHyear

  constructor(
    public entityCore: HyearCoreBase
  ) {
    super();
    if (putHyearFormFieldOptions.feeschedule) {
      putHyearFormFieldOptions.feeschedule.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier(
        'feeschedule',
        SOGetFeeschedule,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}