import {SOPostAgreeyear} from '../../core/agreeyear/so-post-agreeyear';
import {AgreeyearCoreBase} from './agreeyear.core.base';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';
import {FormField, SelectionOption} from 'lionheartland';
import {postAgreeyearFormFieldOptions} from './post-agreeyear-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {HyearCore} from '../hyear/hyear.core';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {AgreementCore} from '../agreement/agreement.core';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';

export class RAPostAgreeyear extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAgreeyearFormFieldOptions);

  RequestClass = SOPostAgreeyear

  constructor(
    public entityCore: AgreeyearCoreBase
  ) {
    super();
    if (postAgreeyearFormFieldOptions.hyear) {
      postAgreeyearFormFieldOptions.hyear.makeCore = true;
    }
    if (postAgreeyearFormFieldOptions.agreement) {
      postAgreeyearFormFieldOptions.agreement.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('year'),
      this.getFormValueFromIdentifier(
        'hyear',
        SOGetHyear,
      ),
      this.getFormValueFromIdentifier(
        'agreement',
        SOGetAgreement,
      ),
      this.getFormValueFromIdentifier('acq'),
      this.getFormValueFromIdentifier('acqUsed'),
      this.getFormValueFromIdentifier('spotQuantity'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}