import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENSalesTaxOptions} from '../../enums';

export class PutAccountFormFields {

    public salesTaxState: FormField
    public interruptibleExpiration: FormField
    public averageLoadSize: FormField
    public balanceMatchThreshold: FormField

    constructor() {

        this.salesTaxState = new FormField(
          'salesTaxState',
          'Sales tax state',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.salesTaxState.enumeration = ENSalesTaxOptions
        this.interruptibleExpiration = new FormField(
          'interruptibleExpiration',
          'Interruptible expiration',
          null,
          FormFieldType.datetime,
          false,
        )
        this.averageLoadSize = new FormField(
          'averageLoadSize',
          'Average load size',
          null,
          FormFieldType.text,
          false,
        )
        this.balanceMatchThreshold = new FormField(
          'balanceMatchThreshold',
          'Balance match threshold',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutAccountFormFields()

export const putAccountFormFieldOptions: {[key: string]: FormField} = {
  salesTaxState: fields.salesTaxState,
  interruptibleExpiration: fields.interruptibleExpiration,
  averageLoadSize: fields.averageLoadSize,
  balanceMatchThreshold: fields.balanceMatchThreshold,
};