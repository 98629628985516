import {SORespDistributionreport} from '../../core/distributionreport/so-resp-distributionreport';
import {DistributionreportCore} from './distributionreport.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributionreportFormFields} from './put-distributionreport-form-fields';
import { CEDistributionreportAttributes } from './ce-distributionreport-attributes'


export class CEDistributionreportBase extends CEBase {

    public details: CEDistributionreportAttributes;
    public fromFields: PutDistributionreportFormFields = new PutDistributionreportFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DistributionreportCore,
        public entity: SORespDistributionreport,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDistributionreportAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.ident)
        this.attributes.push(this.details.timeWindow)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.tankersScheduled)
        this.attributes.push(this.details.tankersActual)
        this.attributes.push(this.details.supertankersScheduled)
        this.attributes.push(this.details.supertankersActual)
        this.attributes.push(this.details.revenueProjected)
        this.attributes.push(this.details.revenueActual)
        this.attributes.push(this.details.revenueContracted)
        this.attributes.push(this.details.revenueScheduled)
        this.attributes.push(this.details.loadsActual)
        this.attributes.push(this.details.loadsProjected)
        this.attributes.push(this.details.loadsContracted)
        this.attributes.push(this.details.loadsScheduled)
        this.attributes.push(this.details.volumeActual)
        this.attributes.push(this.details.volumeProjected)
        this.attributes.push(this.details.volumeContracted)
        this.attributes.push(this.details.volumeScheduled)
        this.attributes.push(this.details.waspProjected)
        this.attributes.push(this.details.waspScheduled)
        this.attributes.push(this.details.waspContracted)
        this.attributes.push(this.details.waspActual)
        this.attributes.push(this.details.averageLoads)
        this.attributes.push(this.details.averageVolume)
        this.attributes.push(this.details.averageUnits)
        this.attributes.push(this.details.volumeDelta)
        this.attributes.push(this.details.loadsDelta)
        this.attributes.push(this.details.waspDelta)
        this.attributes.push(this.details.revenueDelta)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.distributionday)
        this.attributes.push(this.details.distributionmonth)
        this.attributes.push(this.details.distributionyear)
        this.attributes.push(this.details.accountreports)

    }
}