import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOReleaseService} from '../../core/release/eo-release';
import {SOGetRelease} from '../../core/release/so-get-release';
import {RAPutRelease} from './ra-put-release';
import {RAPostRelease} from './ra-post-release';
import {RADeleteRelease} from './ra-delete-release';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CERelease } from "./ce-release"


export class ReleaseCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'release';
  title = 'Release';
  ceProtocol: CEConstructor = CERelease

  entityService: EOReleaseService;
  soGetClass = SOGetRelease;
  soGet: SOGetRelease;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOReleaseService
    );
    this.adapters = [
      new RAPostRelease(this),
      new RAPutRelease(this),
      new RADeleteRelease(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelease();
    this.soGet.queryOptions.limit = this.limit;
  }


}