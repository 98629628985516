import { SOGetInquiry } from '../inquiry/so-get-inquiry';


export class SOPostCustomer {

  constructor(
    public companyName: string,
    public emailDomain: string = null,
    public inquiry: SOGetInquiry = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCustomer(
      'companyName' in params ? params.companyName : null,
      'emailDomain' in params ? params.emailDomain : null,
      'inquiry' in params ? params.inquiry : null,
    );
  }
}
