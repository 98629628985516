import {SbNavItem} from 'lionheartland';
import {NavItemLocation} from 'lionheartland';

export const DEFAULT_NAV_ITEMS = [
    new SbNavItem(
        'account',
        'account',
        '/account',
        'View your accounts',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'accountreport',
        'accountreport',
        '/accountreport',
        'View your accountreports',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'activity',
        'activity',
        '/activity',
        'View your activitys',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'adjustment',
        'adjustment',
        '/adjustment',
        'View your adjustments',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'agreement',
        'agreement',
        '/agreement',
        'View your agreements',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'agreemonth',
        'agreemonth',
        '/agreemonth',
        'View your agreemonths',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'agreeyear',
        'agreeyear',
        '/agreeyear',
        'View your agreeyears',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'award',
        'award',
        '/award',
        'View your awards',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'backlogitem',
        'backlogitem',
        '/backlogitem',
        'View your backlogitems',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'baysession',
        'baysession',
        '/baysession',
        'View your baysessions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'bid',
        'bid',
        '/bid',
        'View your bids',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'bol',
        'bol',
        '/bol',
        'View your bols',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'bulk',
        'bulk',
        '/bulk',
        'View your bulks',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'calendar',
        'calendar',
        '/calendar',
        'View your calendars',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'carrier',
        'carrier',
        '/carrier',
        'View your carriers',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'carrierauth',
        'carrierauth',
        '/carrierauth',
        'View your carrierauths',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'checklist',
        'checklist',
        '/checklist',
        'View your checklists',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'checklistitem',
        'checklistitem',
        '/checklistitem',
        'View your checklistitems',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'chronreport',
        'chronreport',
        '/chronreport',
        'View your chronreports',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'clin',
        'clin',
        '/clin',
        'View your clins',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'clinyearlng',
        'clinyearlng',
        '/clinyearlng',
        'View your clinyearlngs',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'containersession',
        'containersession',
        '/containersession',
        'View your containersessions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'contract',
        'contract',
        '/contract',
        'View your contracts',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'customer',
        'customer',
        '/customer',
        'View your customers',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'dcs',
        'dcs',
        '/dcs',
        'View your dcss',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'delta',
        'delta',
        '/delta',
        'View your deltas',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'deltaweight',
        'deltaweight',
        '/deltaweight',
        'View your deltaweights',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'distoffer',
        'distoffer',
        '/distoffer',
        'View your distoffers',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'distribution',
        'distribution',
        '/distribution',
        'View your distributions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'distributionday',
        'distributionday',
        '/distributionday',
        'View your distributiondays',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'distributionmonth',
        'distributionmonth',
        '/distributionmonth',
        'View your distributionmonths',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'distributionreport',
        'distributionreport',
        '/distributionreport',
        'View your distributionreports',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'distributionyear',
        'distributionyear',
        '/distributionyear',
        'View your distributionyears',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'disttempagreement',
        'disttempagreement',
        '/disttempagreement',
        'View your disttempagreements',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'downtime',
        'downtime',
        '/downtime',
        'View your downtimes',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'event',
        'event',
        '/event',
        'View your events',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'externalindexprice',
        'externalindexprice',
        '/externalindexprice',
        'View your externalindexprices',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'faq',
        'faq',
        '/faq',
        'View your faqs',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'feature',
        'feature',
        '/feature',
        'View your features',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'featureasc',
        'featureasc',
        '/featureasc',
        'View your featureascs',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'fee',
        'fee',
        '/fee',
        'View your fees',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'feeschedule',
        'feeschedule',
        '/feeschedule',
        'View your feeschedules',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'feescheduleagreementasc',
        'feescheduleagreementasc',
        '/feescheduleagreementasc',
        'View your feescheduleagreementascs',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'grant',
        'grant',
        '/grant',
        'View your grants',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'hello',
        'hello',
        '/hello',
        'View your hellos',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'hemon',
        'hemon',
        '/hemon',
        'View your hemons',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'hestatus',
        'hestatus',
        '/hestatus',
        'View your hestatuss',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'hestatusitem',
        'hestatusitem',
        '/hestatusitem',
        'View your hestatusitems',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'hmonth',
        'hmonth',
        '/hmonth',
        'View your hmonths',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'hyear',
        'hyear',
        '/hyear',
        'View your hyears',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'impurity',
        'impurity',
        '/impurity',
        'View your impuritys',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'indexprice',
        'indexprice',
        '/indexprice',
        'View your indexprices',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'inquiry',
        'inquiry',
        '/inquiry',
        'View your inquirys',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'inreport',
        'inreport',
        '/inreport',
        'View your inreports',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'invoice',
        'invoice',
        '/invoice',
        'View your invoices',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'invoiceitem',
        'invoiceitem',
        '/invoiceitem',
        'View your invoiceitems',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'item',
        'item',
        '/item',
        'View your items',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'linag',
        'linag',
        '/linag',
        'View your linags',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'line',
        'line',
        '/line',
        'View your lines',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'lngload',
        'lngload',
        '/lngload',
        'View your lngloads',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'load',
        'load',
        '/load',
        'View your loads',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'loadfee',
        'loadfee',
        '/loadfee',
        'View your loadfees',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'message',
        'message',
        '/message',
        'View your messages',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'monthclose',
        'monthclose',
        '/monthclose',
        'View your monthcloses',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'navi',
        'navi',
        '/navi',
        'View your navis',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'navroa',
        'navroa',
        '/navroa',
        'View your navroas',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'navtemrola',
        'navtemrola',
        '/navtemrola',
        'View your navtemrolas',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'offering',
        'offering',
        '/offering',
        'View your offerings',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'order',
        'order',
        '/order',
        'View your orders',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'organization',
        'organization',
        '/organization',
        'View your organizations',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'orgrola',
        'orgrola',
        '/orgrola',
        'View your orgrolas',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'plant',
        'plant',
        '/plant',
        'View your plants',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'price',
        'price',
        '/price',
        'View your prices',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'product',
        'product',
        '/product',
        'View your products',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'production',
        'production',
        '/production',
        'View your productions',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'productionreport',
        'productionreport',
        '/productionreport',
        'View your productionreports',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'provider',
        'provider',
        '/provider',
        'View your providers',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'publication',
        'publication',
        '/publication',
        'View your publications',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'quotelet',
        'quotelet',
        '/quotelet',
        'View your quotelets',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'rateableagreeyearallocation',
        'rateableagreeyearallocation',
        '/rateableagreeyearallocation',
        'View your rateableagreeyearallocations',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'release',
        'release',
        '/release',
        'View your releases',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'reservation',
        'reservation',
        '/reservation',
        'View your reservations',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'role',
        'role',
        '/role',
        'View your roles',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'sale',
        'sale',
        '/sale',
        'View your sales',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'saleaccountasc',
        'saleaccountasc',
        '/saleaccountasc',
        'View your saleaccountascs',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'serviceRequest',
        'serviceRequest',
        '/service_request',
        'View your service_requests',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'shutelng',
        'shutelng',
        '/shutelng',
        'View your shutelngs',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'sitesession',
        'sitesession',
        '/sitesession',
        'View your sitesessions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'slot',
        'slot',
        '/slot',
        'View your slots',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'slotlet',
        'slotlet',
        '/slotlet',
        'View your slotlets',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'slotoption',
        'slotoption',
        '/slotoption',
        'View your slotoptions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'subscription',
        'subscription',
        '/subscription',
        'View your subscriptions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'tag',
        'tag',
        '/tag',
        'View your tags',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'tagreport',
        'tagreport',
        '/tagreport',
        'View your tagreports',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'tank',
        'tank',
        '/tank',
        'View your tanks',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'tankreport',
        'tankreport',
        '/tankreport',
        'View your tankreports',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'task',
        'task',
        '/task',
        'View your tasks',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'taskreport',
        'taskreport',
        '/taskreport',
        'View your taskreports',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'tempagreement',
        'tempagreement',
        '/tempagreement',
        'View your tempagreements',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'tempagreetract',
        'tempagreetract',
        '/tempagreetract',
        'View your tempagreetracts',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'tempclin',
        'tempclin',
        '/tempclin',
        'View your tempclins',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'temprice',
        'temprice',
        '/temprice',
        'View your temprices',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'temprole',
        'temprole',
        '/temprole',
        'View your temproles',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'temptraclin',
        'temptraclin',
        '/temptraclin',
        'View your temptraclins',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'temptract',
        'temptract',
        '/temptract',
        'View your temptracts',
        NavItemLocation.more,
    ),
    new SbNavItem(
        'token',
        'token',
        '/token',
        'View your tokens',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'trailer',
        'trailer',
        '/trailer',
        'View your trailers',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'trailerauth',
        'trailerauth',
        '/trailerauth',
        'View your trailerauths',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'trailermerge',
        'trailermerge',
        '/trailermerge',
        'View your trailermerges',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'transaction',
        'transaction',
        '/transaction',
        'View your transactions',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'user',
        'user',
        '/user',
        'View your users',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'weight',
        'weight',
        '/weight',
        'View your weights',
        NavItemLocation.main,
    ),
    new SbNavItem(
        'wonderware',
        'wonderware',
        '/wonderware',
        'View your wonderwares',
        NavItemLocation.main,
    ),
];