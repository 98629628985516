import {SORespClinyearlng} from '../../core/clinyearlng/so-resp-clinyearlng';
import {ClinyearlngCore} from './clinyearlng.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutClinyearlngFormFields} from './put-clinyearlng-form-fields';
import {SOGetClinyearlng} from '../../core/clinyearlng/so-get-clinyearlng';
import {SOGetClin} from '../../core/clin/so-get-clin';
import {SOGetContract} from '../../core/contract/so-get-contract';


export class CEClinyearlngAttributesBase {

    clins: DetailEntry
    name: DetailEntry
    start: DetailEntry
    period: DetailEntry
    periodNumber: DetailEntry
    volumeTier1: DetailEntry
    priceTier1: DetailEntry
    volumeTier2: DetailEntry
    priceTier2: DetailEntry
    contract: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespClinyearlng,
        app: GasDirectApp,
        formFields: PutClinyearlngFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.period = new DetailEntry(
            'Period',
            entity.period,
            'period',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.periodNumber = new DetailEntry(
            'Period number',
            entity.periodNumber,
            'periodNumber',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.volumeTier1 = new DetailEntry(
            'Volume tier1',
            entity.volumeTier1,
            'volumeTier1',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.priceTier1 = new DetailEntry(
            'Price tier1',
            entity.priceTier1,
            'priceTier1',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.volumeTier2 = new DetailEntry(
            'Volume tier2',
            entity.volumeTier2,
            'volumeTier2',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.priceTier2 = new DetailEntry(
            'Price tier2',
            entity.priceTier2,
            'priceTier2',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const clinsCore = app.makeCore( 'clin');
        clinsCore.soGet = SOGetClin.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            clinsCore
        )
        clinsCore.title = heading ? heading : clinsCore.title
        clinsCore.listTitle = 'Clins'

        clinsCore.configureForDisplay(parentEntityViewSpec)

        this.clins = new DetailEntry(
          heading ? heading : clinsCore.listTitle,
          null,
          'clins',
          BaseDetailType.embeddedList,
          clinsCore,
          EditableType.never
        )

        const contractCore = app.makeCore( 'contract');
        contractCore.soGet = SOGetContract.construct({
        });
        let contractCeProtocol;
        if (entity.contract) {
            contractCeProtocol = createCEProtocol(
                contractCore.ceProtocol,
                parentEntityViewSpec,
                contractCore,
                entity.contract,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            contractCore
        )
        contractCore.title = heading ? heading : contractCore.title
        contractCore.listTitle = 'Contract'

        if (entity.contract) {
            displayStringFactory.reconfigure(
                entity.contract,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        contractCore.configureForDisplay(parentEntityViewSpec)

        this.contract = new DetailEntry(
          heading ? heading : contractCore.listTitle,
          contractCeProtocol ? contractCeProtocol.entityListView.fullHeading : null,
          'contract',
          BaseDetailType.pushedDetail,
          contractCore,
          EditableType.never
        )
        if (entity.contract) {
          this.contract.ceProtocol = contractCeProtocol;
          this.contract.cachedListEntry = contractCore.makeListEntry(parentEntityViewSpec, entity.contract, 0)
          contractCore.selectedEntity = entity.contract;
        }
        this.contract.singleFieldSelect = true
    }
}