import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutUser {

  constructor(
    public uid: string,
    public givenName: string = null,
    public surname: string = null,
    public organization: SOGetOrganization = null,
    public unread: number = null,
    public active: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutUser(
      'uid' in params ? params.uid : null,
      'givenName' in params ? params.givenName : null,
      'surname' in params ? params.surname : null,
      'organization' in params ? params.organization : null,
      'unread' in params ? params.unread : null,
      'active' in params ? params.active : null,
    );
  }
}
