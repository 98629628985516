import {SOPutTemprole} from '../../core/temprole/so-put-temprole';
import {TemproleCoreBase} from './temprole.core.base';
import {SOGetTemprole} from '../../core/temprole/so-get-temprole';
import {FormField, SelectionOption} from 'lionheartland';
import {putTemproleFormFieldOptions} from './put-temprole-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutTemprole extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTemproleFormFieldOptions);

  RequestClass = SOPutTemprole

  constructor(
    public entityCore: TemproleCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}