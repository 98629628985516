import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutBaysession } from './so-put-baysession';
import { SOGetBaysession } from './so-get-baysession';
import { SOPostBaysession } from './so-post-baysession';
import { SODeleteBaysession } from './so-delete-baysession';
import {SORespBaysession} from './so-resp-baysession';
import {SOPluralBaysession} from './so-plural-baysession';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOBaysessionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'baysession',
      entityCore
    );
  }

  /** GET Baysession by id. Will 404 if id not found */
  get(request: SOGetBaysession): Observable<SOPluralBaysession> {
    return this.httpClient.get<SOPluralBaysession>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralBaysession, resp) }),
      tap(_ => this.log(`fetched Baysession`)),
      catchError(this.handleError<SOPluralBaysession>(`get Baysession`))
    );
  }

  /** POST: add a new Baysession to the server */
  post(request: SOPostBaysession): Observable<SORespBaysession> {
    return this.httpClient.post<SORespBaysession>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBaysession, resp) }),
      tap((response: SORespBaysession) => this.log(`added Baysession w/ id=${response.uid}`)),
      catchError(this.handleError<SORespBaysession>('Baysession post'))
    );
  }

  /** PUT: update Baysession  */
  put(request: SOPutBaysession): Observable<SORespBaysession> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespBaysession>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBaysession, resp) }),
      tap((response: SORespBaysession) => this.log(`edited Baysession w/ uid=${uid}`)),
      catchError(this.handleError<SORespBaysession>('Baysession put'))
    );
  }

  /** DELETE: delete the Baysession from the server */
  delete(request: SODeleteBaysession): Observable<SORespBaysession> {
    return this.httpClient.delete<SORespBaysession>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Baysession uid=${request.uid}`)),
      catchError(this.handleError<SORespBaysession>('delete Baysession'))
    );
  }
}
