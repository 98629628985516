import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespChecklistitem implements ObjectiveProtocol {

  entityType: string = 'checklistitem';
  uid: string;
  body: string;
  index: number;
  required: boolean;
  checked: boolean = null;
  @Type(() => Date)
  checkedDatetime: Date = null;
}