import { Type } from 'class-transformer';
import { SORespWeight } from '../weight/so-resp-weight';
import { SORespHemon } from '../hemon/so-resp-hemon';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTagreport implements ObjectiveProtocol {

  entityType: string = 'tagreport';
  uid: string;
  value: number;
  @Type(() => Date)
  valueTime: Date;
  reportId: string;
  @Type(() => SORespWeight)
  weight: SORespWeight = null;
  @Type(() => SORespHemon)
  hemon: SORespHemon = null;
}