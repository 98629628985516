import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOQuoteletService} from '../../core/quotelet/eo-quotelet';
import {SOGetQuotelet} from '../../core/quotelet/so-get-quotelet';
import {RAPutQuotelet} from './ra-put-quotelet';
import {RAPostQuotelet} from './ra-post-quotelet';
import {RADeleteQuotelet} from './ra-delete-quotelet';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEQuotelet } from "./ce-quotelet"


export class QuoteletCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'quotelet';
  title = 'Quotelet';
  ceProtocol: CEConstructor = CEQuotelet

  entityService: EOQuoteletService;
  soGetClass = SOGetQuotelet;
  soGet: SOGetQuotelet;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOQuoteletService
    );
    this.adapters = [
      new RAPostQuotelet(this),
      new RAPutQuotelet(this),
      new RADeleteQuotelet(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetQuotelet();
    this.soGet.queryOptions.limit = this.limit;
  }


}