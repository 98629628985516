import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostFeatureFormFields {

    public name: FormField
    public component: FormField
    public description: FormField
    public status: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.component = new FormField(
          'component',
          'Component',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PostFeatureFormFields()

export const postFeatureFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  component: fields.component,
  description: fields.description,
  status: fields.status,
};