import {SOPutPrice} from '../../core/price/so-put-price';
import {PriceCoreBase} from './price.core.base';
import {SOGetPrice} from '../../core/price/so-get-price';
import {FormField, SelectionOption} from 'lionheartland';
import {putPriceFormFieldOptions} from './put-price-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutPrice extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putPriceFormFieldOptions);

  RequestClass = SOPutPrice

  constructor(
    public entityCore: PriceCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('startDate'),
      this.getFormValueFromIdentifier('endDate'),
      this.getFormValueFromIdentifier('rate'),
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier('loadQuantity'),
      this.getFormValueFromIdentifier('rank'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}