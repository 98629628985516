import {ENSalesTaxOptions} from '../../enums';


export class SOPutAccount {

  constructor(
    public uid: string,
    public salesTaxState: ENSalesTaxOptions = null,
    public interruptibleExpiration: Date = null,
    public averageLoadSize: number = null,
    public balanceMatchThreshold: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAccount(
      'uid' in params ? params.uid : null,
      'salesTaxState' in params ? params.salesTaxState : null,
      'interruptibleExpiration' in params ? params.interruptibleExpiration : null,
      'averageLoadSize' in params ? params.averageLoadSize : null,
      'balanceMatchThreshold' in params ? params.balanceMatchThreshold : null,
    );
  }
}
