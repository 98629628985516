import {SOPostOrganization} from '../../core/organization/so-post-organization';
import {OrganizationCoreBase} from './organization.core.base';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {FormField, SelectionOption} from 'lionheartland';
import {postOrganizationFormFieldOptions} from './post-organization-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostOrganization extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postOrganizationFormFieldOptions);

  RequestClass = SOPostOrganization

  constructor(
    public entityCore: OrganizationCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('handle'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}