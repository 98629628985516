import { SOGetAccount } from '../account/so-get-account';
import { SOGetDistributionyear } from '../distributionyear/so-get-distributionyear';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetHyear implements QueryProtocol {

  constructor(
    public uid: string = null,
    public state: string = null,
    public year: number = null,
    public account: SOGetAccount = null,
    public distributionyear: SOGetDistributionyear = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetHyear(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'year' in params ? params.year : null,
      'account' in params ? params.account : null,
      'distributionyear' in params ? params.distributionyear : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
