import {SORespContract} from '../../core/contract/so-resp-contract';
import {ContractCore} from './contract.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutContractFormFields} from './put-contract-form-fields';
import { CEContractAttributes } from './ce-contract-attributes'


export class CEContractBase extends CEBase {

    public details: CEContractAttributes;
    public fromFields: PutContractFormFields = new PutContractFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ContractCore,
        public entity: SORespContract,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEContractAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.takeOrPay)
        this.attributes.push(this.details.minLoadSizeToQualify)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.quantityUsed)
        this.attributes.push(this.details.loadQuantity)
        this.attributes.push(this.details.loadQuantityUsed)
        this.attributes.push(this.details.docusignLink)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.loadType)
        this.attributes.push(this.details.agreement)
        this.attributes.push(this.details.temptract)
        this.attributes.push(this.details.clins)
        this.attributes.push(this.details.clinyearlngs)

    }
}