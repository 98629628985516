import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespFeature implements ObjectiveProtocol {

  entityType: string = 'feature';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  name: string;
  component: string;
  description: string;
  status: string;
}