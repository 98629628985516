

export class SOPostEvent {

  constructor(
    public title: string,
    public description: string,
    public eventType: string,
    public start: Date,
    public end: Date,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostEvent(
      'title' in params ? params.title : null,
      'description' in params ? params.description : null,
      'eventType' in params ? params.eventType : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
    );
  }
}
