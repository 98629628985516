import { SOGetTrailer } from '../trailer/so-get-trailer';


export class SOPostTrailermerge {

  constructor(
    public newTrailer: SOGetTrailer,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTrailermerge(
      'newTrailer' in params ? params.newTrailer : null,
    );
  }
}
