import {SORespLoadfee} from '../../core/loadfee/so-resp-loadfee';
import {LoadfeeCore} from './loadfee.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutLoadfeeFormFields} from './put-loadfee-form-fields';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {SOGetFee} from '../../core/fee/so-get-fee';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetInvoiceitem} from '../../core/invoiceitem/so-get-invoiceitem';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CELoadfeeAttributesBase {

    quantity: DetailEntry
    amount: DetailEntry
    notes: DetailEntry
    fee: DetailEntry
    load: DetailEntry
    unitCost: DetailEntry
    containersession: DetailEntry
    invoice: DetailEntry
    dateCreated: DetailEntry
    feeDate: DetailEntry
    distributionmonth: DetailEntry
    price: DetailEntry
    invoiceitem: DetailEntry
    deltas: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespLoadfee,
        app: GasDirectApp,
        formFields: PutLoadfeeFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.quantity
        )

        this.amount = new DetailEntry(
            'Amount',
            entity.amount,
            'amount',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.notes = new DetailEntry(
            'Notes',
            entity.notes,
            'notes',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.notes
        )

        this.unitCost = new DetailEntry(
            'Unit cost',
            entity.unitCost,
            'unitCost',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.unitCost
        )

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.feeDate = new DetailEntry(
            'Fee date',
            app.activeUserService.time.dateAsString(entity.feeDate),
            'feeDate',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const feeCore = app.makeCore( 'fee');
        feeCore.soGet = SOGetFee.construct({
        });
        let feeCeProtocol;
        if (entity.fee) {
            feeCeProtocol = createCEProtocol(
                feeCore.ceProtocol,
                parentEntityViewSpec,
                feeCore,
                entity.fee,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feeCore
        )
        feeCore.title = heading ? heading : feeCore.title
        feeCore.listTitle = 'Fee'

        if (entity.fee) {
            displayStringFactory.reconfigure(
                entity.fee,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        feeCore.configureForDisplay(parentEntityViewSpec)

        this.fee = new DetailEntry(
          heading ? heading : feeCore.listTitle,
          feeCeProtocol ? feeCeProtocol.entityListView.fullHeading : null,
          'fee',
          BaseDetailType.pushedDetail,
          feeCore,
          EditableType.never
        )
        if (entity.fee) {
          this.fee.ceProtocol = feeCeProtocol;
          this.fee.cachedListEntry = feeCore.makeListEntry(parentEntityViewSpec, entity.fee, 0)
          feeCore.selectedEntity = entity.fee;
        }
        this.fee.singleFieldSelect = true

        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.never
        )
        this.load.singleFieldSelect = true

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        let containersessionCeProtocol;
        if (entity.containersession) {
            containersessionCeProtocol = createCEProtocol(
                containersessionCore.ceProtocol,
                parentEntityViewSpec,
                containersessionCore,
                entity.containersession,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        if (entity.containersession) {
            displayStringFactory.reconfigure(
                entity.containersession,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          containersessionCeProtocol ? containersessionCeProtocol.entityListView.fullHeading : null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        if (entity.containersession) {
          this.containersession.ceProtocol = containersessionCeProtocol;
          this.containersession.cachedListEntry = containersessionCore.makeListEntry(parentEntityViewSpec, entity.containersession, 0)
          containersessionCore.selectedEntity = entity.containersession;
        }
        this.containersession.singleFieldSelect = true

        const invoiceCore = app.makeCore( 'invoice');
        invoiceCore.soGet = SOGetInvoice.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoiceCore
        )
        invoiceCore.title = heading ? heading : invoiceCore.title
        invoiceCore.listTitle = 'Invoice'

        invoiceCore.configureForDisplay(parentEntityViewSpec)

        this.invoice = new DetailEntry(
          heading ? heading : invoiceCore.listTitle,
          null,
          'invoice',
          BaseDetailType.pushedDetail,
          invoiceCore,
          EditableType.never
        )
        this.invoice.singleFieldSelect = true

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true

        const priceCore = app.makeCore( 'price');
        priceCore.soGet = SOGetPrice.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            priceCore
        )
        priceCore.title = heading ? heading : priceCore.title
        priceCore.listTitle = 'Price'

        priceCore.configureForDisplay(parentEntityViewSpec)

        this.price = new DetailEntry(
          heading ? heading : priceCore.listTitle,
          null,
          'price',
          BaseDetailType.pushedDetail,
          priceCore,
          EditableType.never
        )
        this.price.singleFieldSelect = true

        const invoiceitemCore = app.makeCore( 'invoiceitem');
        invoiceitemCore.soGet = SOGetInvoiceitem.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoiceitemCore
        )
        invoiceitemCore.title = heading ? heading : invoiceitemCore.title
        invoiceitemCore.listTitle = 'Invoiceitem'

        invoiceitemCore.configureForDisplay(parentEntityViewSpec)

        this.invoiceitem = new DetailEntry(
          heading ? heading : invoiceitemCore.listTitle,
          null,
          'invoiceitem',
          BaseDetailType.pushedDetail,
          invoiceitemCore,
          EditableType.never
        )
        this.invoiceitem.singleFieldSelect = true

        const deltasCore = app.makeCore( 'delta');
        deltasCore.soGet = SOGetDelta.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deltasCore
        )
        deltasCore.title = heading ? heading : deltasCore.title
        deltasCore.listTitle = 'Deltas'

        deltasCore.configureForDisplay(parentEntityViewSpec)

        this.deltas = new DetailEntry(
          heading ? heading : deltasCore.listTitle,
          null,
          'deltas',
          BaseDetailType.embeddedList,
          deltasCore,
          EditableType.never
        )
    }
}