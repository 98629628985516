import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutOrganizationFormFields {

    public users: FormField
    public handle: FormField
    public ownerId: FormField
    public customer: FormField
    public carrier: FormField
    public orgrolas: FormField
    public trailers: FormField

    constructor() {

        this.users = new FormField(
          'users',
          'Users',
          'user',
          FormFieldType.autoCompleteList,
          false,
        )
        this.handle = new FormField(
          'handle',
          'Handle',
          null,
          FormFieldType.text,
          false,
        )
        this.ownerId = new FormField(
          'ownerId',
          'Owner id',
          null,
          FormFieldType.text,
          false,
        )
        this.customer = new FormField(
          'customer',
          'Customer',
          'customer',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.carrier = new FormField(
          'carrier',
          'Carrier',
          'carrier',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.orgrolas = new FormField(
          'orgrolas',
          'Orgrolas',
          'orgrola',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.trailers = new FormField(
          'trailers',
          'Trailers',
          'trailer',
          FormFieldType.autoCompleteList,
          false,
        )

    }
}

const fields = new PutOrganizationFormFields()

export const putOrganizationFormFieldOptions: {[key: string]: FormField} = {
  users: fields.users,
  handle: fields.handle,
  ownerId: fields.ownerId,
  customer: fields.customer,
  carrier: fields.carrier,
  orgrolas: fields.orgrolas,
  trailers: fields.trailers,
};