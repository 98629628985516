import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOImpurityService} from '../../core/impurity/eo-impurity';
import {SOGetImpurity} from '../../core/impurity/so-get-impurity';
import {RAPutImpurity} from './ra-put-impurity';
import {RAPostImpurity} from './ra-post-impurity';
import {RADeleteImpurity} from './ra-delete-impurity';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEImpurity } from "./ce-impurity"


export class ImpurityCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'impurity';
  title = 'Impurity';
  ceProtocol: CEConstructor = CEImpurity

  entityService: EOImpurityService;
  soGetClass = SOGetImpurity;
  soGet: SOGetImpurity;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOImpurityService
    );
    this.adapters = [
      new RAPostImpurity(this),
      new RAPutImpurity(this),
      new RADeleteImpurity(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetImpurity();
    this.soGet.queryOptions.limit = this.limit;
  }


}