import { Type } from 'class-transformer';
import { SORespTank } from '../tank/so-resp-tank';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespProduction implements ObjectiveProtocol {

  entityType: string = 'production';
  uid: string;
  name: string;
  dailyRate: number = 0;
  dailyRateUnits: string;
  @Type(() => SORespTank)
  tanks: SORespTank[] = [];
}