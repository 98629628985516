import {SORespProduction} from '../../core/production/so-resp-production';
import {ProductionCore} from './production.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutProductionFormFields} from './put-production-form-fields';
import {SOGetProduction} from '../../core/production/so-get-production';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetProductionreport} from '../../core/productionreport/so-get-productionreport';
import {SOGetTank} from '../../core/tank/so-get-tank';


export class CEProductionAttributesBase {

    name: DetailEntry
    dailyRate: DetailEntry
    dailyRateUnits: DetailEntry
    line: DetailEntry
    productionreports: DetailEntry
    tanks: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespProduction,
        app: GasDirectApp,
        formFields: PutProductionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.dailyRate = new DetailEntry(
            'Daily rate',
            entity.dailyRate,
            'dailyRate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dailyRate
        )

        this.dailyRateUnits = new DetailEntry(
            'Daily rate units',
            entity.dailyRateUnits,
            'dailyRateUnits',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dailyRateUnits
        )


        const lineCore = app.makeCore( 'line');
        lineCore.soGet = SOGetLine.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            lineCore
        )
        lineCore.title = heading ? heading : lineCore.title
        lineCore.listTitle = 'Line'

        lineCore.configureForDisplay(parentEntityViewSpec)

        this.line = new DetailEntry(
          heading ? heading : lineCore.listTitle,
          null,
          'line',
          BaseDetailType.pushedDetail,
          lineCore,
          EditableType.always,
          formFields.line
        )
        this.line.singleFieldSelect = true

        const productionreportsCore = app.makeCore( 'productionreport');
        productionreportsCore.soGet = SOGetProductionreport.construct({
          production: new SOGetProduction(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            productionreportsCore
        )
        productionreportsCore.title = heading ? heading : productionreportsCore.title
        productionreportsCore.listTitle = 'Productionreports'

        productionreportsCore.configureForDisplay(parentEntityViewSpec)

        this.productionreports = new DetailEntry(
          heading ? heading : productionreportsCore.listTitle,
          null,
          'productionreports',
          BaseDetailType.embeddedList,
          productionreportsCore,
          EditableType.never
        )

        const tanksCore = app.makeCore( 'tank');
        tanksCore.soGet = SOGetTank.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tanksCore
        )
        tanksCore.title = heading ? heading : tanksCore.title
        tanksCore.listTitle = 'Tanks'

        tanksCore.configureForDisplay(parentEntityViewSpec)

        this.tanks = new DetailEntry(
          heading ? heading : tanksCore.listTitle,
          null,
          'tanks',
          BaseDetailType.embeddedList,
          tanksCore,
          EditableType.never
        )
    }
}