import { SOGetTempagreement } from '../tempagreement/so-get-tempagreement';
import { SOGetTemptract } from '../temptract/so-get-temptract';


export class SOPutTempagreetract {

  constructor(
    public uid: string,
    public tempagreement: SOGetTempagreement = null,
    public temptract: SOGetTemptract = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTempagreetract(
      'uid' in params ? params.uid : null,
      'tempagreement' in params ? params.tempagreement : null,
      'temptract' in params ? params.temptract : null,
    );
  }
}
