import {SORespTrailer} from '../../core/trailer/so-resp-trailer';
import {TrailerCore} from './trailer.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTrailerFormFields} from './put-trailer-form-fields';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetTrailerauth} from '../../core/trailerauth/so-get-trailerauth';
import {SOGetTrailermerge} from '../../core/trailermerge/so-get-trailermerge';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CETrailerAttributesBase {

    loads: DetailEntry
    trailerauths: DetailEntry
    trailermerges: DetailEntry
    chassisId: DetailEntry
    identifier: DetailEntry
    type: DetailEntry
    size: DetailEntry
    dotId: DetailEntry
    dotInspectionDate: DetailEntry
    dotE: DetailEntry
    dotSp: DetailEntry
    mawp: DetailEntry
    organization: DetailEntry
    attachment: DetailEntry
    linFirst: DetailEntry
    averageEmptyWeight: DetailEntry
    loadCount: DetailEntry
    fillVolume: DetailEntry
    mawt: DetailEntry
    containersessions: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTrailer,
        app: GasDirectApp,
        formFields: PutTrailerFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.chassisId = new DetailEntry(
            'Chassis id',
            entity.chassisId,
            'chassisId',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.chassisId
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.type
        )

        this.size = new DetailEntry(
            'Size',
            entity.size,
            'size',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.size
        )

        this.dotId = new DetailEntry(
            'Dot id',
            entity.dotId,
            'dotId',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dotId
        )

        this.dotInspectionDate = new DetailEntry(
            'Dot inspection date',
            app.activeUserService.time.dateAsString(entity.dotInspectionDate),
            'dotInspectionDate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dotInspectionDate
        )

        this.dotE = new DetailEntry(
            'Dot e',
            entity.dotE,
            'dotE',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dotE
        )

        this.dotSp = new DetailEntry(
            'Dot sp',
            entity.dotSp,
            'dotSp',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dotSp
        )

        this.mawp = new DetailEntry(
            'Mawp',
            entity.mawp,
            'mawp',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.mawp
        )

        this.attachment = new DetailEntry(
            'Attachment',
            entity.attachment,
            'attachment',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.attachment
        )

        this.linFirst = new DetailEntry(
            'Lin first',
            entity.linFirst,
            'linFirst',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.linFirst
        )

        this.averageEmptyWeight = new DetailEntry(
            'Average empty weight',
            entity.averageEmptyWeight,
            'averageEmptyWeight',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadCount = new DetailEntry(
            'Load count',
            entity.loadCount,
            'loadCount',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.fillVolume = new DetailEntry(
            'Fill volume',
            entity.fillVolume,
            'fillVolume',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.fillVolume
        )

        this.mawt = new DetailEntry(
            'Mawt',
            entity.mawt,
            'mawt',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.mawt
        )


        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          trailer: new SOGetTrailer(entity.uid),
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.never
        )

        const trailerauthsCore = app.makeCore( 'trailerauth');
        trailerauthsCore.soGet = SOGetTrailerauth.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          trailer: new SOGetTrailer(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            trailerauthsCore
        )
        trailerauthsCore.title = heading ? heading : trailerauthsCore.title
        trailerauthsCore.listTitle = 'Trailerauths'

        trailerauthsCore.configureForDisplay(parentEntityViewSpec)

        this.trailerauths = new DetailEntry(
          heading ? heading : trailerauthsCore.listTitle,
          null,
          'trailerauths',
          BaseDetailType.embeddedList,
          trailerauthsCore,
          EditableType.never
        )

        const trailermergesCore = app.makeCore( 'trailermerge');
        trailermergesCore.soGet = SOGetTrailermerge.construct({
          newTrailer: new SOGetTrailer(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            trailermergesCore
        )
        trailermergesCore.title = heading ? heading : trailermergesCore.title
        trailermergesCore.listTitle = 'Trailermerges'

        trailermergesCore.configureForDisplay(parentEntityViewSpec)

        this.trailermerges = new DetailEntry(
          heading ? heading : trailermergesCore.listTitle,
          null,
          'trailermerges',
          BaseDetailType.pushedList,
          trailermergesCore,
          EditableType.never
        )

        const organizationCore = app.makeCore( 'organization');
        organizationCore.soGet = SOGetOrganization.construct({
        });
        let organizationCeProtocol;
        if (entity.organization) {
            organizationCeProtocol = createCEProtocol(
                organizationCore.ceProtocol,
                parentEntityViewSpec,
                organizationCore,
                entity.organization,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            organizationCore
        )
        organizationCore.title = heading ? heading : organizationCore.title
        organizationCore.listTitle = 'Organization'

        if (entity.organization) {
            displayStringFactory.reconfigure(
                entity.organization,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        organizationCore.configureForDisplay(parentEntityViewSpec)

        this.organization = new DetailEntry(
          heading ? heading : organizationCore.listTitle,
          organizationCeProtocol ? organizationCeProtocol.entityListView.fullHeading : null,
          'organization',
          BaseDetailType.pushedDetail,
          organizationCore,
          EditableType.always,
          formFields.organization
        )
        if (entity.organization) {
          this.organization.ceProtocol = organizationCeProtocol;
          this.organization.cachedListEntry = organizationCore.makeListEntry(parentEntityViewSpec, entity.organization, 0)
          organizationCore.selectedEntity = entity.organization;
        }
        this.organization.singleFieldSelect = true

        const containersessionsCore = app.makeCore( 'containersession');
        containersessionsCore.soGet = SOGetContainersession.construct({
          trailer: new SOGetTrailer(entity.uid),
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionsCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionsCore
        )
        containersessionsCore.title = heading ? heading : containersessionsCore.title
        containersessionsCore.listTitle = 'Containersessions'

        containersessionsCore.configureForDisplay(parentEntityViewSpec)

        this.containersessions = new DetailEntry(
          heading ? heading : containersessionsCore.listTitle,
          null,
          'containersessions',
          BaseDetailType.embeddedList,
          containersessionsCore,
          EditableType.never
        )
    }
}