import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostItemFormFields {

    public quantity: FormField
    public order: FormField
    public tempclin: FormField
    public offering: FormField

    constructor() {

        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          true,
        )
        this.order = new FormField(
          'order',
          'Order',
          'order',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.tempclin = new FormField(
          'tempclin',
          'Tempclin',
          'tempclin',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.offering = new FormField(
          'offering',
          'Offering',
          'offering',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostItemFormFields()

export const postItemFormFieldOptions: {[key: string]: FormField} = {
  quantity: fields.quantity,
  order: fields.order,
  tempclin: fields.tempclin,
  offering: fields.offering,
};