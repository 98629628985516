import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTag } from './so-put-tag';
import { SOGetTag } from './so-get-tag';
import { SOPostTag } from './so-post-tag';
import { SODeleteTag } from './so-delete-tag';
import {SORespTag} from './so-resp-tag';
import {SOPluralTag} from './so-plural-tag';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTagService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'tag',
      entityCore
    );
  }

  /** GET Tag by id. Will 404 if id not found */
  get(request: SOGetTag): Observable<SOPluralTag> {
    return this.httpClient.get<SOPluralTag>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTag, resp) }),
      tap(_ => this.log(`fetched Tag`)),
      catchError(this.handleError<SOPluralTag>(`get Tag`))
    );
  }

  /** POST: add a new Tag to the server */
  post(request: SOPostTag): Observable<SORespTag> {
    return this.httpClient.post<SORespTag>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTag, resp) }),
      tap((response: SORespTag) => this.log(`added Tag w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTag>('Tag post'))
    );
  }

  /** PUT: update Tag  */
  put(request: SOPutTag): Observable<SORespTag> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTag>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTag, resp) }),
      tap((response: SORespTag) => this.log(`edited Tag w/ uid=${uid}`)),
      catchError(this.handleError<SORespTag>('Tag put'))
    );
  }

  /** DELETE: delete the Tag from the server */
  delete(request: SODeleteTag): Observable<SORespTag> {
    return this.httpClient.delete<SORespTag>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Tag uid=${request.uid}`)),
      catchError(this.handleError<SORespTag>('delete Tag'))
    );
  }
}
