import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutLoadfeeFormFields {

    public quantity: FormField
    public notes: FormField
    public unitCost: FormField

    constructor() {

        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          false,
        )
        this.notes = new FormField(
          'notes',
          'Notes',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.unitCost = new FormField(
          'unitCost',
          'Unit cost',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutLoadfeeFormFields()

export const putLoadfeeFormFieldOptions: {[key: string]: FormField} = {
  quantity: fields.quantity,
  notes: fields.notes,
  unitCost: fields.unitCost,
};