import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostHyearFormFields {

    public state: FormField
    public acq: FormField
    public acqUsed: FormField
    public year: FormField
    public account: FormField
    public spotQuantity: FormField
    public distributionyear: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          true,
        )
        this.acq = new FormField(
          'acq',
          'Acq',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.acqUsed = new FormField(
          'acqUsed',
          'Acq used',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.year = new FormField(
          'year',
          'Year',
          null,
          FormFieldType.text,
          true,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.spotQuantity = new FormField(
          'spotQuantity',
          'Spot quantity',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.distributionyear = new FormField(
          'distributionyear',
          'Distributionyear',
          'distributionyear',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostHyearFormFields()

export const postHyearFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  acq: fields.acq,
  acqUsed: fields.acqUsed,
  year: fields.year,
  spotQuantity: fields.spotQuantity,
  distributionyear: fields.distributionyear,
};