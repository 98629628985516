import {SORespFaq} from '../../core/faq/so-resp-faq';
import {FaqCore} from './faq.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFaqFormFields} from './put-faq-form-fields';
import { CEFaqAttributes } from './ce-faq-attributes'


export class CEFaqBase extends CEBase {

    public details: CEFaqAttributes;
    public fromFields: PutFaqFormFields = new PutFaqFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: FaqCore,
        public entity: SORespFaq,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEFaqAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.index)
        this.attributes.push(this.details.title)
        this.attributes.push(this.details.body)
        this.attributes.push(this.details.distribution)

    }
}