import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostContainersessionFormFields {

    public notes: FormField
    public trailer: FormField
    public distribution: FormField
    public load: FormField

    constructor() {

        this.notes = new FormField(
          'notes',
          'Notes',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.trailer = new FormField(
          'trailer',
          'Trailer',
          'trailer',
          FormFieldType.autoCompleteList,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )

    }
}

const fields = new PostContainersessionFormFields()

export const postContainersessionFormFieldOptions: {[key: string]: FormField} = {
  notes: fields.notes,
  trailer: fields.trailer,
  load: fields.load,
};