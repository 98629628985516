import { SOGetAccountreport } from '../accountreport/so-get-accountreport';
import { SOGetLoad } from '../load/so-get-load';


export class SOPostInreport {

  constructor(
    public accounreport: SOGetAccountreport,
    public load: SOGetLoad,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostInreport(
      'accounreport' in params ? params.accounreport : null,
      'load' in params ? params.load : null,
    );
  }
}
