import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutSlotoptionFormFields {


    constructor() {


    }
}

const fields = new PutSlotoptionFormFields()

export const putSlotoptionFormFieldOptions: {[key: string]: FormField} = {
};