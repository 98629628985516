import {SORespDcs} from '../../core/dcs/so-resp-dcs';
import {DcsCore} from './dcs.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDcsFormFields} from './put-dcs-form-fields';
import {SOGetDcs} from '../../core/dcs/so-get-dcs';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetTag} from '../../core/tag/so-get-tag';


export class CEDcsAttributesBase {

    name: DetailEntry
    endpoint: DetailEntry
    line: DetailEntry
    tags: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDcs,
        app: GasDirectApp,
        formFields: PutDcsFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.endpoint = new DetailEntry(
            'Endpoint',
            entity.endpoint,
            'endpoint',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.endpoint
        )


        const lineCore = app.makeCore( 'line');
        lineCore.soGet = SOGetLine.construct({
        });
        let lineCeProtocol;
        if (entity.line) {
            lineCeProtocol = createCEProtocol(
                lineCore.ceProtocol,
                parentEntityViewSpec,
                lineCore,
                entity.line,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            lineCore
        )
        lineCore.title = heading ? heading : lineCore.title
        lineCore.listTitle = 'Line'

        if (entity.line) {
            displayStringFactory.reconfigure(
                entity.line,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        lineCore.configureForDisplay(parentEntityViewSpec)

        this.line = new DetailEntry(
          heading ? heading : lineCore.listTitle,
          lineCeProtocol ? lineCeProtocol.entityListView.fullHeading : null,
          'line',
          BaseDetailType.pushedDetail,
          lineCore,
          EditableType.always,
          formFields.line
        )
        if (entity.line) {
          this.line.ceProtocol = lineCeProtocol;
          this.line.cachedListEntry = lineCore.makeListEntry(parentEntityViewSpec, entity.line, 0)
          lineCore.selectedEntity = entity.line;
        }
        this.line.singleFieldSelect = true

        const tagsCore = app.makeCore( 'tag');
        tagsCore.soGet = SOGetTag.construct({
          dcs: new SOGetDcs(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tagsCore
        )
        tagsCore.title = heading ? heading : tagsCore.title
        tagsCore.listTitle = 'Tags'

        tagsCore.configureForDisplay(parentEntityViewSpec)

        this.tags = new DetailEntry(
          heading ? heading : tagsCore.listTitle,
          null,
          'tags',
          BaseDetailType.embeddedList,
          tagsCore,
          EditableType.always,
          formFields.tags
        )
    }
}