import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutCustomerFormFields {

    public companyName: FormField
    public emailDomain: FormField
    public signedAgreement: FormField
    public handle: FormField
    public fullCompanyName: FormField
    public address: FormField
    public remittorNumber: FormField
    public additionalInvoiceText: FormField
    public billingPoc: FormField
    public paymentTerms: FormField
    public stripeRef: FormField
    public stripeDirectDebit: FormField
    public stripeVerify1: FormField
    public stripeVerify2: FormField
    public creditLimit: FormField

    constructor() {

        this.companyName = new FormField(
          'companyName',
          'Company name',
          null,
          FormFieldType.text,
          false,
        )
        this.emailDomain = new FormField(
          'emailDomain',
          'Email domain',
          null,
          FormFieldType.text,
          false,
        )
        this.signedAgreement = new FormField(
          'signedAgreement',
          'Signed agreement',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.handle = new FormField(
          'handle',
          'Handle',
          null,
          FormFieldType.text,
          false,
        )
        this.fullCompanyName = new FormField(
          'fullCompanyName',
          'Full company name',
          null,
          FormFieldType.text,
          false,
        )
        this.address = new FormField(
          'address',
          'Address',
          null,
          FormFieldType.text,
          false,
        )
        this.remittorNumber = new FormField(
          'remittorNumber',
          'Remittor number',
          null,
          FormFieldType.text,
          false,
        )
        this.additionalInvoiceText = new FormField(
          'additionalInvoiceText',
          'Additional invoice text',
          null,
          FormFieldType.text,
          false,
        )
        this.billingPoc = new FormField(
          'billingPoc',
          'Billing poc',
          null,
          FormFieldType.text,
          false,
        )
        this.paymentTerms = new FormField(
          'paymentTerms',
          'Payment terms',
          null,
          FormFieldType.text,
          false,
        )
        this.stripeRef = new FormField(
          'stripeRef',
          'Stripe ref',
          null,
          FormFieldType.text,
          false,
        )
        this.stripeDirectDebit = new FormField(
          'stripeDirectDebit',
          'Stripe direct debit',
          null,
          FormFieldType.boolean,
          false,
        )
        this.stripeVerify1 = new FormField(
          'stripeVerify1',
          'Stripe verify1',
          null,
          FormFieldType.text,
          false,
        )
        this.stripeVerify2 = new FormField(
          'stripeVerify2',
          'Stripe verify2',
          null,
          FormFieldType.text,
          false,
        )
        this.creditLimit = new FormField(
          'creditLimit',
          'Credit limit',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )

    }
}

const fields = new PutCustomerFormFields()

export const putCustomerFormFieldOptions: {[key: string]: FormField} = {
  companyName: fields.companyName,
  emailDomain: fields.emailDomain,
  signedAgreement: fields.signedAgreement,
  handle: fields.handle,
  fullCompanyName: fields.fullCompanyName,
  address: fields.address,
  remittorNumber: fields.remittorNumber,
  additionalInvoiceText: fields.additionalInvoiceText,
  billingPoc: fields.billingPoc,
  paymentTerms: fields.paymentTerms,
  stripeRef: fields.stripeRef,
  stripeDirectDebit: fields.stripeDirectDebit,
  stripeVerify1: fields.stripeVerify1,
  stripeVerify2: fields.stripeVerify2,
  creditLimit: fields.creditLimit,
};