import { Type } from 'class-transformer';
import {ENTare} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespWeight implements ObjectiveProtocol {

  entityType: string = 'weight';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  tare: ENTare;
  amount: number;
  amountDcs: number = null;
  @Type(() => Date)
  dcsTimestamp: Date = null;
}