import { SOGetAccount } from '../account/so-get-account';
import { SOGetOrder } from '../order/so-get-order';
import { SOGetLoad } from '../load/so-get-load';
import { SOGetInvoice } from '../invoice/so-get-invoice';


export class SOPostTransaction {

  constructor(
    public amount: number,
    public account: SOGetAccount,
    public dateCreated: Date = null,
    public description: string = "",
    public order: SOGetOrder = null,
    public load: SOGetLoad = null,
    public finalLoadCost: boolean = false,
    public reconciliationAdjustment: boolean = false,
    public transactionType: string = null,
    public invoice: SOGetInvoice = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTransaction(
      'dateCreated' in params ? params.dateCreated : null,
      'description' in params ? params.description : null,
      'amount' in params ? params.amount : null,
      'account' in params ? params.account : null,
      'order' in params ? params.order : null,
      'load' in params ? params.load : null,
      'finalLoadCost' in params ? params.finalLoadCost : null,
      'reconciliationAdjustment' in params ? params.reconciliationAdjustment : null,
      'transactionType' in params ? params.transactionType : null,
      'invoice' in params ? params.invoice : null,
    );
  }
}
