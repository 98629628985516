import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostProviderFormFields {

    public organization: FormField

    constructor() {

        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostProviderFormFields()

export const postProviderFormFieldOptions: {[key: string]: FormField} = {
  organization: fields.organization,
};