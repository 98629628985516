import { SOGetAccount } from '../account/so-get-account';
import { SOGetHyear } from '../hyear/so-get-hyear';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';


export class SOPostHmonth {

  constructor(
    public state: string,
    public month: number,
    public year: number,
    public account: SOGetAccount,
    public hyear: SOGetHyear,
    public distributionmonth: SOGetDistributionmonth,
    public mcq: number = 0,
    public mcqUsed: number = 0,
    public spotQuantity: number = 0,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostHmonth(
      'state' in params ? params.state : null,
      'mcq' in params ? params.mcq : null,
      'mcqUsed' in params ? params.mcqUsed : null,
      'month' in params ? params.month : null,
      'year' in params ? params.year : null,
      'account' in params ? params.account : null,
      'hyear' in params ? params.hyear : null,
      'spotQuantity' in params ? params.spotQuantity : null,
      'distributionmonth' in params ? params.distributionmonth : null,
    );
  }
}
