import {SORespWeight} from '../../core/weight/so-resp-weight';
import {WeightCore} from './weight.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutWeightFormFields} from './put-weight-form-fields';
import { CEWeightAttributes } from './ce-weight-attributes'


export class CEWeightBase extends CEBase {

    public details: CEWeightAttributes;
    public fromFields: PutWeightFormFields = new PutWeightFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: WeightCore,
        public entity: SORespWeight,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEWeightAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.tare)
        this.attributes.push(this.details.amount)
        this.attributes.push(this.details.amountDcs)
        this.attributes.push(this.details.dcsTimestamp)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.tasks)
        this.attributes.push(this.details.deltaweights)
        this.attributes.push(this.details.tagreports)

    }
}