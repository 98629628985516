import {Component, Input, OnInit} from '@angular/core';
import {SORespEvent} from '../../core/event/so-resp-event';
import {LoadRow} from '../dv-load-row/dv-load-row.component';
import {ContainersessionCore} from "../../entities/containersession/containersession.core";
import {CEProtocol, ListSpec, SvListComponent, ListBody} from "lionheartland";
import {GasDirectApp} from "../../land-app";


@Component({
  selector: 'app-av-status',
  templateUrl: './av-status.component.html',
  styleUrls: ['./av-status.component.css']
})
export class AvStatusComponent extends SvListComponent implements OnInit {

  get app(): GasDirectApp {
    return this.activeUserService.app as GasDirectApp
  }

  initialized = false;
  private containersessionCore: ContainersessionCore;

  listBody: ListBody;
  showList = false;

  @Input()
  set entityViewSpec(entityViewSpec) {
    console.log('in status spec');

    if (!this.initialized) {
      this.setLoadRows();
      this.containersessionCore = new ContainersessionCore(this.activeUserService);
    }

    super.entityViewSpec = entityViewSpec;
  }

  entities: SORespEvent[] = [];
  loadRows: LoadRow[];

  listFetched(entities, entityViewSpec: ListSpec, hasMore) {
    // this.setLoadRows();
    this._entityViewSpec = entityViewSpec;
    this.hasMore = hasMore;
    entityViewSpec.listBody.sections = [];
    this.entities = entities;

    this.showList = !entityViewSpec.listBody.isEmpty;
    this.listBody = entityViewSpec.listBody;

    for (const row of this.loadRows) {
      row.listEntries = [];
    }

    for (const load of entities) {

      let status;
      if (['checkIn'].includes(load.status)) {
        status = 'loading';
      } else if (['final'].includes(load.status)) {
        status = 'loadingComplete';
      } else if (this.app.isHelium && ['confirmed'].includes(load.status)) {
        status = 'scheduled';
      } else {
        status = load.status;
      }
      const ce: CEProtocol = new this.entityViewSpec.entityCore.ceProtocol(
          null, this.entityViewSpec.entityCore, load, this.app
      )
      const loadRow = this.loadRows.find(i => i.state === status);
      loadRow.listEntries.push(
          ce.entityListView
      );
    }

    if (this.activeUserService.activeGrant.role.identifier !== 'carrier') {
      this.fetchUnknowns();
    }

    // setTimeout(() => {
      // if (this.navigator.entityViewSpecs[this.navigator.entityViewSpecs.length - 1].type === entityViewSpecType.status) {
      //   this.entityViewSpec.entityCore.entities = null;
      //   this.listBody.sections = [];
      //   this.entities = [];
      //   this.entityViewSpec.entityCore.fetchList(this.entityViewSpec);
      // }
    // }, 10000);
  }

  fetchUnknowns() {

    this.containersessionCore.soGet.predicate = 'unknowns';
    this.containersessionCore.entityService.get(this.containersessionCore.soGet).subscribe(resp => {
      if (resp) {
        for (const containersession of resp.objects) {

          const loadRowUnknown = this.loadRows.find(i => i.state === 'adHoc');
          const ce: CEProtocol = new this.containersessionCore.ceProtocol(
              null, this.containersessionCore, containersession, this.app
          )
          loadRowUnknown.listEntries.push(
            ce.entityListView
          );
        }
      }
    });
  }

  setLoadRows() {

    if (this.app.isHelium) {
      this.loadRows = [
        // new LoadRow('approved', 'Approved'),
        new LoadRow('adHoc', 'Unknown', '#007096'),
        new LoadRow('scheduled', 'Scheduled', '#007096'),
        // new LoadRow('confirmed', 'Confirmed', '#007096'),
        new LoadRow('arrived', 'Arrived', '#A6193E'),
        new LoadRow('loading', 'Loading', '#FFD700'),
        new LoadRow('soaking', 'Soaking', '#FFD700'),
        new LoadRow('loadingComplete', 'Full', '#B4D405'),
        new LoadRow('empty', 'Empty', '#65B4F3'),
        // new LoadRow('departed', 'Departed', '#00A14D')
      ];
    } else {
      this.loadRows = [
        // new LoadRow('approved', 'Approved'),
        new LoadRow('adHoc', 'Unknown', '#007096'),
        new LoadRow('scheduled', 'Scheduled', '#007096'),
        new LoadRow('confirmed', 'Confirmed', '#007096'),
        new LoadRow('arrived', 'Arrived', '#A6193E'),
        // new LoadRow('preLoading', 'Pre-loading', '#A6193E'),
        // new LoadRow('readyToLoad', 'Ready to load', '#A6193E'),
        new LoadRow('loading', 'Loading', '#FFD700'),
        new LoadRow('loadingComplete', 'Full', '#B4D405'),
        // new LoadRow('readyForCheckout', 'Ready for checkout', '#FFD700'),
        // new LoadRow('departed', 'Departed', '#00A14D')
      ];
    }
  }

}
