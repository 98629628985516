import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOClinService} from '../../core/clin/eo-clin';
import {SOGetClin} from '../../core/clin/so-get-clin';
import {RAPutClin} from './ra-put-clin';
import {RAPostClin} from './ra-post-clin';
import {RADeleteClin} from './ra-delete-clin';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEClin } from "./ce-clin"


export class ClinCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'clin';
  title = 'Clin';
  ceProtocol: CEConstructor = CEClin

  entityService: EOClinService;
  soGetClass = SOGetClin;
  soGet: SOGetClin;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOClinService
    );
    this.adapters = [
      new RAPostClin(this),
      new RAPutClin(this),
      new RADeleteClin(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetClin();
    this.soGet.queryOptions.limit = this.limit;
  }


}