import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOContractService} from '../../core/contract/eo-contract';
import {SOGetContract} from '../../core/contract/so-get-contract';
import {RAPutContract} from './ra-put-contract';
import {RAPostContract} from './ra-post-contract';
import {RADeleteContract} from './ra-delete-contract';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEContract } from "./ce-contract"


export class ContractCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'contract';
  title = 'Contract';
  ceProtocol: CEConstructor = CEContract

  entityService: EOContractService;
  soGetClass = SOGetContract;
  soGet: SOGetContract;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOContractService
    );
    this.adapters = [
      new RAPostContract(this),
      new RAPutContract(this),
      new RADeleteContract(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetContract();
    this.soGet.queryOptions.limit = this.limit;
  }


}