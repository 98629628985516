import { SOGetDelta } from '../delta/so-get-delta';
import { SOGetWeight } from '../weight/so-get-weight';


export class SOPostDeltaweight {

  constructor(
    public initial: boolean,
    public delta: SOGetDelta,
    public weight: SOGetWeight,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDeltaweight(
      'initial' in params ? params.initial : null,
      'delta' in params ? params.delta : null,
      'weight' in params ? params.weight : null,
    );
  }
}
