import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTemptractService} from '../../core/temptract/eo-temptract';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';
import {RAPutTemptract} from './ra-put-temptract';
import {RAPostTemptract} from './ra-post-temptract';
import {RADeleteTemptract} from './ra-delete-temptract';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETemptract } from "./ce-temptract"


export class TemptractCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'temptract';
  title = 'Temptract';
  ceProtocol: CEConstructor = CETemptract

  entityService: EOTemptractService;
  soGetClass = SOGetTemptract;
  soGet: SOGetTemptract;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTemptractService
    );
    this.adapters = [
      new RAPostTemptract(this),
      new RAPutTemptract(this),
      new RADeleteTemptract(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTemptract();
    this.soGet.queryOptions.limit = this.limit;
  }


}