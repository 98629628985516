import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutHmonth } from './so-put-hmonth';
import { SOGetHmonth } from './so-get-hmonth';
import { SOPostHmonth } from './so-post-hmonth';
import { SODeleteHmonth } from './so-delete-hmonth';
import {SORespHmonth} from './so-resp-hmonth';
import {SOPluralHmonth} from './so-plural-hmonth';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOHmonthService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'hmonth',
      entityCore
    );
  }

  /** GET Hmonth by id. Will 404 if id not found */
  get(request: SOGetHmonth): Observable<SOPluralHmonth> {
    return this.httpClient.get<SOPluralHmonth>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralHmonth, resp) }),
      tap(_ => this.log(`fetched Hmonth`)),
      catchError(this.handleError<SOPluralHmonth>(`get Hmonth`))
    );
  }

  /** POST: add a new Hmonth to the server */
  post(request: SOPostHmonth): Observable<SORespHmonth> {
    return this.httpClient.post<SORespHmonth>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHmonth, resp) }),
      tap((response: SORespHmonth) => this.log(`added Hmonth w/ id=${response.uid}`)),
      catchError(this.handleError<SORespHmonth>('Hmonth post'))
    );
  }

  /** PUT: update Hmonth  */
  put(request: SOPutHmonth): Observable<SORespHmonth> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespHmonth>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHmonth, resp) }),
      tap((response: SORespHmonth) => this.log(`edited Hmonth w/ uid=${uid}`)),
      catchError(this.handleError<SORespHmonth>('Hmonth put'))
    );
  }

  /** DELETE: delete the Hmonth from the server */
  delete(request: SODeleteHmonth): Observable<SORespHmonth> {
    return this.httpClient.delete<SORespHmonth>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Hmonth uid=${request.uid}`)),
      catchError(this.handleError<SORespHmonth>('delete Hmonth'))
    );
  }
}
