import {SORespSaleaccountasc} from '../../core/saleaccountasc/so-resp-saleaccountasc';
import {SaleaccountascCore} from './saleaccountasc.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSaleaccountascFormFields} from './put-saleaccountasc-form-fields';
import {SOGetSaleaccountasc} from '../../core/saleaccountasc/so-get-saleaccountasc';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetSale} from '../../core/sale/so-get-sale';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CESaleaccountascAttributesBase {

    dateCreated: DetailEntry
    account: DetailEntry
    sale: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSaleaccountasc,
        app: GasDirectApp,
        formFields: PutSaleaccountascFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.never
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const saleCore = app.makeCore( 'sale');
        saleCore.soGet = SOGetSale.construct({
        });
        let saleCeProtocol;
        if (entity.sale) {
            saleCeProtocol = createCEProtocol(
                saleCore.ceProtocol,
                parentEntityViewSpec,
                saleCore,
                entity.sale,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            saleCore
        )
        saleCore.title = heading ? heading : saleCore.title
        saleCore.listTitle = 'Sale'

        if (entity.sale) {
            displayStringFactory.reconfigure(
                entity.sale,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        saleCore.configureForDisplay(parentEntityViewSpec)

        this.sale = new DetailEntry(
          heading ? heading : saleCore.listTitle,
          saleCeProtocol ? saleCeProtocol.entityListView.fullHeading : null,
          'sale',
          BaseDetailType.pushedDetail,
          saleCore,
          EditableType.never
        )
        if (entity.sale) {
          this.sale.ceProtocol = saleCeProtocol;
          this.sale.cachedListEntry = saleCore.makeListEntry(parentEntityViewSpec, entity.sale, 0)
          saleCore.selectedEntity = entity.sale;
        }
        this.sale.singleFieldSelect = true
    }
}