import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDistributionmonth } from './so-put-distributionmonth';
import { SOGetDistributionmonth } from './so-get-distributionmonth';
import { SOPostDistributionmonth } from './so-post-distributionmonth';
import { SODeleteDistributionmonth } from './so-delete-distributionmonth';
import {SORespDistributionmonth} from './so-resp-distributionmonth';
import {SOPluralDistributionmonth} from './so-plural-distributionmonth';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODistributionmonthService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'distributionmonth',
      entityCore
    );
  }

  /** GET Distributionmonth by id. Will 404 if id not found */
  get(request: SOGetDistributionmonth): Observable<SOPluralDistributionmonth> {
    return this.httpClient.get<SOPluralDistributionmonth>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDistributionmonth, resp) }),
      tap(_ => this.log(`fetched Distributionmonth`)),
      catchError(this.handleError<SOPluralDistributionmonth>(`get Distributionmonth`))
    );
  }

  /** POST: add a new Distributionmonth to the server */
  post(request: SOPostDistributionmonth): Observable<SORespDistributionmonth> {
    return this.httpClient.post<SORespDistributionmonth>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistributionmonth, resp) }),
      tap((response: SORespDistributionmonth) => this.log(`added Distributionmonth w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDistributionmonth>('Distributionmonth post'))
    );
  }

  /** PUT: update Distributionmonth  */
  put(request: SOPutDistributionmonth): Observable<SORespDistributionmonth> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDistributionmonth>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistributionmonth, resp) }),
      tap((response: SORespDistributionmonth) => this.log(`edited Distributionmonth w/ uid=${uid}`)),
      catchError(this.handleError<SORespDistributionmonth>('Distributionmonth put'))
    );
  }

  /** DELETE: delete the Distributionmonth from the server */
  delete(request: SODeleteDistributionmonth): Observable<SORespDistributionmonth> {
    return this.httpClient.delete<SORespDistributionmonth>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Distributionmonth uid=${request.uid}`)),
      catchError(this.handleError<SORespDistributionmonth>('delete Distributionmonth'))
    );
  }
}
