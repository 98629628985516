import { SOGetCustomer } from '../customer/so-get-customer';
import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostAccount {

  constructor(
    public customer: SOGetCustomer,
    public distribution: SOGetDistribution,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAccount(
      'customer' in params ? params.customer : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
