import { SOGetTaskreport } from '../taskreport/so-get-taskreport';
import { SOGetLoad } from '../load/so-get-load';


export class SOPostTask {

  constructor(
    public taskreport: SOGetTaskreport,
    public completed: boolean = false,
    public load: SOGetLoad = null,
    public loadStatus: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTask(
      'completed' in params ? params.completed : null,
      'taskreport' in params ? params.taskreport : null,
      'load' in params ? params.load : null,
      'loadStatus' in params ? params.loadStatus : null,
    );
  }
}
