import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostAgreementFormFields {

    public name: FormField
    public description: FormField
    public docusignLink: FormField
    public units: FormField
    public state: FormField
    public start: FormField
    public end: FormField
    public autoRenew: FormField
    public tempagreement: FormField
    public account: FormField
    public acq: FormField
    public top: FormField
    public feeschedule: FormField
    public variableAcq: FormField
    public rateable: FormField
    public interruptible: FormField
    public spotQuantity: FormField
    public award: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.docusignLink = new FormField(
          'docusignLink',
          'Docusign link',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.units = new FormField(
          'units',
          'Units',
          null,
          FormFieldType.text,
          true,
        )
        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          true,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          true,
        )
        this.autoRenew = new FormField(
          'autoRenew',
          'Auto renew',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.tempagreement = new FormField(
          'tempagreement',
          'Tempagreement',
          'tempagreement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.acq = new FormField(
          'acq',
          'Acq',
          null,
          FormFieldType.text,
          false,
        )
        this.top = new FormField(
          'top',
          'Top',
          null,
          FormFieldType.text,
          false,
        )
        this.feeschedule = new FormField(
          'feeschedule',
          'Feeschedule',
          'feeschedule',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.variableAcq = new FormField(
          'variableAcq',
          'Variable acq',
          null,
          FormFieldType.boolean,
          false,
        )
        this.rateable = new FormField(
          'rateable',
          'Rateable',
          null,
          FormFieldType.boolean,
          false,
        )
        this.interruptible = new FormField(
          'interruptible',
          'Interruptible',
          null,
          FormFieldType.boolean,
          false,
        )
        this.spotQuantity = new FormField(
          'spotQuantity',
          'Spot quantity',
          null,
          FormFieldType.text,
          false,
        )
        this.award = new FormField(
          'award',
          'Award',
          'award',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostAgreementFormFields()

export const postAgreementFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  docusignLink: fields.docusignLink,
  units: fields.units,
  state: fields.state,
  start: fields.start,
  end: fields.end,
  autoRenew: fields.autoRenew,
  tempagreement: fields.tempagreement,
  acq: fields.acq,
  top: fields.top,
  feeschedule: fields.feeschedule,
  variableAcq: fields.variableAcq,
  rateable: fields.rateable,
  interruptible: fields.interruptible,
  spotQuantity: fields.spotQuantity,
  award: fields.award,
};