import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutPublicationFormFields {

    public displayName: FormField

    constructor() {

        this.displayName = new FormField(
          'displayName',
          'Display name',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutPublicationFormFields()

export const putPublicationFormFieldOptions: {[key: string]: FormField} = {
  displayName: fields.displayName,
};