import {SOPostDistributionmonth} from '../../core/distributionmonth/so-post-distributionmonth';
import {DistributionmonthCoreBase} from './distributionmonth.core.base';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {FormField, SelectionOption} from 'lionheartland';
import {postDistributionmonthFormFieldOptions} from './post-distributionmonth-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionyearCore} from '../distributionyear/distributionyear.core';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPostDistributionmonth extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postDistributionmonthFormFieldOptions);

  RequestClass = SOPostDistributionmonth

  constructor(
    public entityCore: DistributionmonthCoreBase
  ) {
    super();
    if (postDistributionmonthFormFieldOptions.distributionyear) {
      postDistributionmonthFormFieldOptions.distributionyear.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('month'),
      this.getFormValueFromIdentifier(
        'distributionyear',
        SOGetDistributionyear,
      ),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}