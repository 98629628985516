import {SORespTagreport} from '../../core/tagreport/so-resp-tagreport';
import {TagreportCore} from './tagreport.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTagreportFormFields} from './put-tagreport-form-fields';
import { CETagreportAttributes } from './ce-tagreport-attributes'


export class CETagreportBase extends CEBase {

    public details: CETagreportAttributes;
    public fromFields: PutTagreportFormFields = new PutTagreportFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TagreportCore,
        public entity: SORespTagreport,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETagreportAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.value)
        this.attributes.push(this.details.valueTime)
        this.attributes.push(this.details.reportId)
        this.attributes.push(this.details.tag)
        this.attributes.push(this.details.weight)
        this.attributes.push(this.details.hemon)

    }
}