import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespOrder } from '../order/so-resp-order';
import { SORespLoad } from '../load/so-resp-load';
import { SORespInvoice } from '../invoice/so-resp-invoice';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTransaction implements ObjectiveProtocol {

  entityType: string = 'transaction';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  description: string = "";
  amount: number;
  balance: number;
  @Type(() => SORespAccount)
  account: SORespAccount;
  @Type(() => SORespOrder)
  order: SORespOrder = null;
  @Type(() => SORespLoad)
  load: SORespLoad = null;
  finalLoadCost: boolean = false;
  reconciliationAdjustment: boolean = false;
  transactionType: string = null;
  @Type(() => SORespInvoice)
  invoice: SORespInvoice = null;
}