import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDowntime } from './so-put-downtime';
import { SOGetDowntime } from './so-get-downtime';
import { SOPostDowntime } from './so-post-downtime';
import { SODeleteDowntime } from './so-delete-downtime';
import {SORespDowntime} from './so-resp-downtime';
import {SOPluralDowntime} from './so-plural-downtime';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODowntimeService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'downtime',
      entityCore
    );
  }

  /** GET Downtime by id. Will 404 if id not found */
  get(request: SOGetDowntime): Observable<SOPluralDowntime> {
    return this.httpClient.get<SOPluralDowntime>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDowntime, resp) }),
      tap(_ => this.log(`fetched Downtime`)),
      catchError(this.handleError<SOPluralDowntime>(`get Downtime`))
    );
  }

  /** POST: add a new Downtime to the server */
  post(request: SOPostDowntime): Observable<SORespDowntime> {
    return this.httpClient.post<SORespDowntime>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDowntime, resp) }),
      tap((response: SORespDowntime) => this.log(`added Downtime w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDowntime>('Downtime post'))
    );
  }

  /** PUT: update Downtime  */
  put(request: SOPutDowntime): Observable<SORespDowntime> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDowntime>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDowntime, resp) }),
      tap((response: SORespDowntime) => this.log(`edited Downtime w/ uid=${uid}`)),
      catchError(this.handleError<SORespDowntime>('Downtime put'))
    );
  }

  /** DELETE: delete the Downtime from the server */
  delete(request: SODeleteDowntime): Observable<SORespDowntime> {
    return this.httpClient.delete<SORespDowntime>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Downtime uid=${request.uid}`)),
      catchError(this.handleError<SORespDowntime>('delete Downtime'))
    );
  }
}
