import {SOPostTag} from '../../core/tag/so-post-tag';
import {TagCoreBase} from './tag.core.base';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {FormField, SelectionOption} from 'lionheartland';
import {postTagFormFieldOptions} from './post-tag-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DcsCore} from '../dcs/dcs.core';
import {SOGetDcs} from '../../core/dcs/so-get-dcs';

export class RAPostTag extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTagFormFieldOptions);

  RequestClass = SOPostTag

  constructor(
    public entityCore: TagCoreBase
  ) {
    super();
    if (postTagFormFieldOptions.dcs) {
      postTagFormFieldOptions.dcs.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier(
        'dcs',
        SOGetDcs,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}