import {SOPutProduction} from '../../core/production/so-put-production';
import {ProductionCoreBase} from './production.core.base';
import {SOGetProduction} from '../../core/production/so-get-production';
import {FormField, SelectionOption} from 'lionheartland';
import {putProductionFormFieldOptions} from './put-production-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {LineCore} from '../line/line.core';
import {SOGetLine} from '../../core/line/so-get-line';

export class RAPutProduction extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putProductionFormFieldOptions);

  RequestClass = SOPutProduction

  constructor(
    public entityCore: ProductionCoreBase
  ) {
    super();
    if (putProductionFormFieldOptions.line) {
      putProductionFormFieldOptions.line.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('dailyRate'),
      this.getFormValueFromIdentifier('dailyRateUnits'),
      this.getFormValueFromIdentifier(
        'line',
        SOGetLine,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}