import {SORespAgreemonth} from '../../core/agreemonth/so-resp-agreemonth';
import {AgreemonthCore} from './agreemonth.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAgreemonthFormFields} from './put-agreemonth-form-fields';
import { CEAgreemonthAttributes } from './ce-agreemonth-attributes'


export class CEAgreemonthBase extends CEBase {

    public details: CEAgreemonthAttributes;
    public fromFields: PutAgreemonthFormFields = new PutAgreemonthFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AgreemonthCore,
        public entity: SORespAgreemonth,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAgreemonthAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.mcq)
        this.attributes.push(this.details.mcqUsed)
        this.attributes.push(this.details.delta)
        this.attributes.push(this.details.year)
        this.attributes.push(this.details.month)
        this.attributes.push(this.details.spotQuantity)
        this.attributes.push(this.details.spotQuantityUsed)
        this.attributes.push(this.details.mcqEstimated)
        this.attributes.push(this.details.mcqInitial)
        this.attributes.push(this.details.orders)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.hmonth)
        this.attributes.push(this.details.agreement)
        this.attributes.push(this.details.agreeyear)
        this.attributes.push(this.details.distributionmonth)
        this.attributes.push(this.details.adjustments)
        this.attributes.push(this.details.slotoptions)

    }
}