import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTask } from './so-put-task';
import { SOGetTask } from './so-get-task';
import { SOPostTask } from './so-post-task';
import { SODeleteTask } from './so-delete-task';
import {SORespTask} from './so-resp-task';
import {SOPluralTask} from './so-plural-task';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTaskService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'task',
      entityCore
    );
  }

  /** GET Task by id. Will 404 if id not found */
  get(request: SOGetTask): Observable<SOPluralTask> {
    return this.httpClient.get<SOPluralTask>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTask, resp) }),
      tap(_ => this.log(`fetched Task`)),
      catchError(this.handleError<SOPluralTask>(`get Task`))
    );
  }

  /** POST: add a new Task to the server */
  post(request: SOPostTask): Observable<SORespTask> {
    return this.httpClient.post<SORespTask>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTask, resp) }),
      tap((response: SORespTask) => this.log(`added Task w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTask>('Task post'))
    );
  }

  /** PUT: update Task  */
  put(request: SOPutTask): Observable<SORespTask> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTask>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTask, resp) }),
      tap((response: SORespTask) => this.log(`edited Task w/ uid=${uid}`)),
      catchError(this.handleError<SORespTask>('Task put'))
    );
  }

  /** DELETE: delete the Task from the server */
  delete(request: SODeleteTask): Observable<SORespTask> {
    return this.httpClient.delete<SORespTask>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Task uid=${request.uid}`)),
      catchError(this.handleError<SORespTask>('delete Task'))
    );
  }
}
