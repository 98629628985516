import {SORespTrailermerge} from '../../core/trailermerge/so-resp-trailermerge';
import {TrailermergeCore} from './trailermerge.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTrailermergeFormFields} from './put-trailermerge-form-fields';
import {SOGetTrailermerge} from '../../core/trailermerge/so-get-trailermerge';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';


export class CETrailermergeAttributesBase {

    description: DetailEntry
    newTrailer: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTrailermerge,
        app: GasDirectApp,
        formFields: PutTrailermergeFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const newTrailerCore = app.makeCore( 'trailer');
        newTrailerCore.soGet = SOGetTrailer.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            newTrailerCore
        )
        newTrailerCore.title = heading ? heading : newTrailerCore.title
        newTrailerCore.listTitle = 'New trailer'

        newTrailerCore.configureForDisplay(parentEntityViewSpec)

        this.newTrailer = new DetailEntry(
          heading ? heading : newTrailerCore.listTitle,
          null,
          'newTrailer',
          BaseDetailType.pushedDetail,
          newTrailerCore,
          EditableType.never
        )
        this.newTrailer.singleFieldSelect = true
    }
}