import { SOGetAccount } from '../account/so-get-account';
import { SOGetAgreement } from '../agreement/so-get-agreement';


export class SOPutOrder {

  constructor(
    public uid: string,
    public status: string = null,
    public account: SOGetAccount = null,
    public agreement: SOGetAgreement = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutOrder(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
      'account' in params ? params.account : null,
      'agreement' in params ? params.agreement : null,
    );
  }
}
