import {SOPostDistributionreport} from '../../core/distributionreport/so-post-distributionreport';
import {DistributionreportCoreBase} from './distributionreport.core.base';
import {SOGetDistributionreport} from '../../core/distributionreport/so-get-distributionreport';
import {FormField, SelectionOption} from 'lionheartland';
import {postDistributionreportFormFieldOptions} from './post-distributionreport-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPostDistributionreport extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postDistributionreportFormFieldOptions);

  RequestClass = SOPostDistributionreport

  constructor(
    public entityCore: DistributionreportCoreBase
  ) {
    super();
    if (postDistributionreportFormFieldOptions.distribution) {
      postDistributionreportFormFieldOptions.distribution.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('ident'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
      this.getFormValueFromIdentifier('timeWindow'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier('tankersScheduled'),
      this.getFormValueFromIdentifier('tankersActual'),
      this.getFormValueFromIdentifier('supertankersScheduled'),
      this.getFormValueFromIdentifier('supertankersActual'),
      this.getFormValueFromIdentifier('revenueProjected'),
      this.getFormValueFromIdentifier('revenueActual'),
      this.getFormValueFromIdentifier('revenueContracted'),
      this.getFormValueFromIdentifier('revenueScheduled'),
      this.getFormValueFromIdentifier('loadsActual'),
      this.getFormValueFromIdentifier('loadsProjected'),
      this.getFormValueFromIdentifier('loadsContracted'),
      this.getFormValueFromIdentifier('loadsScheduled'),
      this.getFormValueFromIdentifier('volumeActual'),
      this.getFormValueFromIdentifier('volumeProjected'),
      this.getFormValueFromIdentifier('volumeContracted'),
      this.getFormValueFromIdentifier('volumeScheduled'),
      this.getFormValueFromIdentifier('waspProjected'),
      this.getFormValueFromIdentifier('waspScheduled'),
      this.getFormValueFromIdentifier('waspContracted'),
      this.getFormValueFromIdentifier('waspActual'),
      this.getFormValueFromIdentifier('averageLoads'),
      this.getFormValueFromIdentifier('averageVolume'),
      this.getFormValueFromIdentifier('averageUnits'),
      this.getFormValueFromIdentifier('volumeDelta'),
      this.getFormValueFromIdentifier('loadsDelta'),
      this.getFormValueFromIdentifier('waspDelta'),
      this.getFormValueFromIdentifier('revenueDelta'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}