import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespUser implements ObjectiveProtocol {

  entityType: string = 'user';
  uid: string;
  username: string;
  givenName: string;
  surname: string;
  @Type(() => SORespOrganization)
  organization: SORespOrganization = null;
  companyName: string = null;
  isExxon: boolean = null;
  unread: number = 0;
  active: boolean = null;
}