import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutWeight } from './so-put-weight';
import { SOGetWeight } from './so-get-weight';
import { SOPostWeight } from './so-post-weight';
import { SODeleteWeight } from './so-delete-weight';
import {SORespWeight} from './so-resp-weight';
import {SOPluralWeight} from './so-plural-weight';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOWeightService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'weight',
      entityCore
    );
  }

  /** GET Weight by id. Will 404 if id not found */
  get(request: SOGetWeight): Observable<SOPluralWeight> {
    return this.httpClient.get<SOPluralWeight>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralWeight, resp) }),
      tap(_ => this.log(`fetched Weight`)),
      catchError(this.handleError<SOPluralWeight>(`get Weight`))
    );
  }

  /** POST: add a new Weight to the server */
  post(request: SOPostWeight): Observable<SORespWeight> {
    return this.httpClient.post<SORespWeight>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWeight, resp) }),
      tap((response: SORespWeight) => this.log(`added Weight w/ id=${response.uid}`)),
      catchError(this.handleError<SORespWeight>('Weight post'))
    );
  }

  /** PUT: update Weight  */
  put(request: SOPutWeight): Observable<SORespWeight> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespWeight>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWeight, resp) }),
      tap((response: SORespWeight) => this.log(`edited Weight w/ uid=${uid}`)),
      catchError(this.handleError<SORespWeight>('Weight put'))
    );
  }

  /** DELETE: delete the Weight from the server */
  delete(request: SODeleteWeight): Observable<SORespWeight> {
    return this.httpClient.delete<SORespWeight>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Weight uid=${request.uid}`)),
      catchError(this.handleError<SORespWeight>('delete Weight'))
    );
  }
}
