import {SORespImpurity} from '../../core/impurity/so-resp-impurity';
import {ImpurityCore} from './impurity.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutImpurityFormFields} from './put-impurity-form-fields';
import { CEImpurityAttributes } from './ce-impurity-attributes'


export class CEImpurityBase extends CEBase {

    public details: CEImpurityAttributes;
    public fromFields: PutImpurityFormFields = new PutImpurityFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ImpurityCore,
        public entity: SORespImpurity,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEImpurityAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.h20)
        this.attributes.push(this.details.neon)
        this.attributes.push(this.details.h2)
        this.attributes.push(this.details.o2)
        this.attributes.push(this.details.n2)
        this.attributes.push(this.details.carbonMonoxide)
        this.attributes.push(this.details.hydrocarbons)
        this.attributes.push(this.details.carbonDioxide)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.containersession)

    }
}