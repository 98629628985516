import { SOGetSlot } from '../slot/so-get-slot';
import { SOGetAgreemonth } from '../agreemonth/so-get-agreemonth';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetSlotoption implements QueryProtocol {

  constructor(
    public uid: string = null,
    public slot: SOGetSlot = null,
    public agreemonth: SOGetAgreemonth = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetSlotoption(
      'uid' in params ? params.uid : null,
      'slot' in params ? params.slot : null,
      'agreemonth' in params ? params.agreemonth : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
