import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostFeeFormFields {

    public displayName: FormField
    public identifier: FormField
    public amount: FormField
    public feeschedule: FormField

    constructor() {

        this.displayName = new FormField(
          'displayName',
          'Display name',
          null,
          FormFieldType.text,
          true,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          true,
        )
        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          true,
        )
        this.feeschedule = new FormField(
          'feeschedule',
          'Feeschedule',
          'feeschedule',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostFeeFormFields()

export const postFeeFormFieldOptions: {[key: string]: FormField} = {
  displayName: fields.displayName,
  identifier: fields.identifier,
  amount: fields.amount,
  feeschedule: fields.feeschedule,
};