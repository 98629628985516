import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTankFormFields {

    public name: FormField
    public units: FormField
    public type: FormField
    public capacity: FormField
    public average: FormField
    public production: FormField
    public value: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.units = new FormField(
          'units',
          'Units',
          null,
          FormFieldType.text,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          false,
          true,
          "tank"
        )
        this.capacity = new FormField(
          'capacity',
          'Capacity',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.average = new FormField(
          'average',
          'Average',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.production = new FormField(
          'production',
          'Production',
          'production',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.value = new FormField(
          'value',
          'Value',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )

    }
}

const fields = new PostTankFormFields()

export const postTankFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  units: fields.units,
  type: fields.type,
  capacity: fields.capacity,
  average: fields.average,
  production: fields.production,
  value: fields.value,
};