import {SORespDeltaweight} from '../../core/deltaweight/so-resp-deltaweight';
import {DeltaweightCore} from './deltaweight.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDeltaweightFormFields} from './put-deltaweight-form-fields';
import { CEDeltaweightAttributes } from './ce-deltaweight-attributes'


export class CEDeltaweightBase extends CEBase {

    public details: CEDeltaweightAttributes;
    public fromFields: PutDeltaweightFormFields = new PutDeltaweightFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DeltaweightCore,
        public entity: SORespDeltaweight,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDeltaweightAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.initial)
        this.attributes.push(this.details.delta)
        this.attributes.push(this.details.weight)

    }
}