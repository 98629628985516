import {SORespHemon} from '../../core/hemon/so-resp-hemon';
import {HemonCore} from './hemon.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHemonFormFields} from './put-hemon-form-fields';
import {SOGetHemon} from '../../core/hemon/so-get-hemon';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetTask} from '../../core/task/so-get-task';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetTagreport} from '../../core/tagreport/so-get-tagreport';
import {SOGetHestatusitem} from '../../core/hestatusitem/so-get-hestatusitem';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEHemonAttributesBase {

    dateCreated: DetailEntry
    state: DetailEntry
    n2Level: DetailEntry
    n2Pressure: DetailEntry
    heTemp: DetailEntry
    heLevel: DetailEntry
    hePressure: DetailEntry
    n2LevelDcs: DetailEntry
    n2PressureDcs: DetailEntry
    heTempDcs: DetailEntry
    heLevelDcs: DetailEntry
    hePressureDcs: DetailEntry
    load: DetailEntry
    task: DetailEntry
    containersession: DetailEntry
    tagreports: DetailEntry
    hestatusitems: DetailEntry
    containersessionsTwo: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespHemon,
        app: GasDirectApp,
        formFields: PutHemonFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateCreated
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.n2Level = new DetailEntry(
            'N2 level',
            entity.n2Level,
            'n2Level',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.n2Level
        )

        this.n2Pressure = new DetailEntry(
            'N2 pressure',
            entity.n2Pressure,
            'n2Pressure',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.n2Pressure
        )

        this.heTemp = new DetailEntry(
            'He temp',
            entity.heTemp,
            'heTemp',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.heTemp
        )

        this.heLevel = new DetailEntry(
            'He level',
            entity.heLevel,
            'heLevel',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.heLevel
        )

        this.hePressure = new DetailEntry(
            'He pressure',
            entity.hePressure,
            'hePressure',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.hePressure
        )

        this.n2LevelDcs = new DetailEntry(
            'N2 level dcs',
            entity.n2LevelDcs,
            'n2LevelDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.n2PressureDcs = new DetailEntry(
            'N2 pressure dcs',
            entity.n2PressureDcs,
            'n2PressureDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.heTempDcs = new DetailEntry(
            'He temp dcs',
            entity.heTempDcs,
            'heTempDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.heLevelDcs = new DetailEntry(
            'He level dcs',
            entity.heLevelDcs,
            'heLevelDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.hePressureDcs = new DetailEntry(
            'He pressure dcs',
            entity.hePressureDcs,
            'hePressureDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.always,
          formFields.load
        )
        this.load.singleFieldSelect = true

        const taskCore = app.makeCore( 'task');
        taskCore.soGet = SOGetTask.construct({
        });
        taskCore.soGet.queryOptions.sortKey = 'dateCreated';
        taskCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            taskCore
        )
        taskCore.title = heading ? heading : taskCore.title
        taskCore.listTitle = 'Task'

        taskCore.configureForDisplay(parentEntityViewSpec)

        this.task = new DetailEntry(
          heading ? heading : taskCore.listTitle,
          null,
          'task',
          BaseDetailType.pushedDetail,
          taskCore,
          EditableType.always,
          formFields.task
        )
        this.task.singleFieldSelect = true

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.always,
          formFields.containersession
        )
        this.containersession.singleFieldSelect = true

        const tagreportsCore = app.makeCore( 'tagreport');
        tagreportsCore.soGet = SOGetTagreport.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tagreportsCore
        )
        tagreportsCore.title = heading ? heading : tagreportsCore.title
        tagreportsCore.listTitle = 'Tagreports'

        tagreportsCore.configureForDisplay(parentEntityViewSpec)

        this.tagreports = new DetailEntry(
          heading ? heading : tagreportsCore.listTitle,
          null,
          'tagreports',
          BaseDetailType.embeddedList,
          tagreportsCore,
          EditableType.never
        )

        const hestatusitemsCore = app.makeCore( 'hestatusitem');
        hestatusitemsCore.soGet = SOGetHestatusitem.construct({
          hemon: new SOGetHemon(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hestatusitemsCore
        )
        hestatusitemsCore.title = heading ? heading : hestatusitemsCore.title
        hestatusitemsCore.listTitle = 'Hestatusitems'

        hestatusitemsCore.configureForDisplay(parentEntityViewSpec)

        this.hestatusitems = new DetailEntry(
          heading ? heading : hestatusitemsCore.listTitle,
          null,
          'hestatusitems',
          BaseDetailType.embeddedList,
          hestatusitemsCore,
          EditableType.never
        )

        const containersessionsTwoCore = app.makeCore( 'containersession');
        containersessionsTwoCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionsTwoCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionsTwoCore
        )
        containersessionsTwoCore.title = heading ? heading : containersessionsTwoCore.title
        containersessionsTwoCore.listTitle = 'Containersessions two'

        containersessionsTwoCore.configureForDisplay(parentEntityViewSpec)

        this.containersessionsTwo = new DetailEntry(
          heading ? heading : containersessionsTwoCore.listTitle,
          null,
          'containersessionsTwo',
          BaseDetailType.embeddedList,
          containersessionsTwoCore,
          EditableType.never
        )
    }
}