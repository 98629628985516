import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutDeltaweightFormFields {


    constructor() {


    }
}

const fields = new PutDeltaweightFormFields()

export const putDeltaweightFormFieldOptions: {[key: string]: FormField} = {
};