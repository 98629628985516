import {SORespFee} from '../../core/fee/so-resp-fee';
import {FeeCore} from './fee.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeeFormFields} from './put-fee-form-fields';
import { CEFeeAttributes } from './ce-fee-attributes'


export class CEFeeBase extends CEBase {

    public details: CEFeeAttributes;
    public fromFields: PutFeeFormFields = new PutFeeFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: FeeCore,
        public entity: SORespFee,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEFeeAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.displayName)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.amount)
        this.attributes.push(this.details.feeschedule)
        this.attributes.push(this.details.loadfees)

    }
}