import { SOGetEvent } from '../event/so-get-event';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetTempclin } from '../tempclin/so-get-tempclin';


export class SOPostSlotlet {

  constructor(
    public start: Date,
    public end: Date,
    public status: string,
    public dayOffset: number,
    public slotNumber: number,
    public event: SOGetEvent = null,
    public account: SOGetAccount = null,
    public tempclin: SOGetTempclin = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostSlotlet(
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'status' in params ? params.status : null,
      'dayOffset' in params ? params.dayOffset : null,
      'slotNumber' in params ? params.slotNumber : null,
      'event' in params ? params.event : null,
      'account' in params ? params.account : null,
      'tempclin' in params ? params.tempclin : null,
    );
  }
}
