import { SOGetTempclin } from '../tempclin/so-get-tempclin';


export class SOPostTemprice {

  constructor(
    public rate: number,
    public quantity: number,
    public loadQuantity: number,
    public rank: number,
    public tempclin: SOGetTempclin,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTemprice(
      'rate' in params ? params.rate : null,
      'quantity' in params ? params.quantity : null,
      'loadQuantity' in params ? params.loadQuantity : null,
      'rank' in params ? params.rank : null,
      'tempclin' in params ? params.tempclin : null,
    );
  }
}
