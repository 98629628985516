import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostReservationFormFields {

    public description: FormField
    public slot: FormField
    public account: FormField

    constructor() {

        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.slot = new FormField(
          'slot',
          'Slot',
          'slot',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostReservationFormFields()

export const postReservationFormFieldOptions: {[key: string]: FormField} = {
  description: fields.description,
  slot: fields.slot,
  account: fields.account,
};