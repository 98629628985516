import {SORespTask} from '../../core/task/so-resp-task';
import {TaskCore} from './task.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTaskFormFields} from './put-task-form-fields';
import { CETaskAttributes } from './ce-task-attributes'


export class CETaskBase extends CEBase {

    public details: CETaskAttributes;
    public fromFields: PutTaskFormFields = new PutTaskFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TaskCore,
        public entity: SORespTask,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETaskAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.trailerGone)
        this.attributes.push(this.details.completed)
        this.attributes.push(this.details.location)
        this.attributes.push(this.details.loadStatus)
        this.attributes.push(this.details.net)
        this.attributes.push(this.details.hemon)
        this.attributes.push(this.details.taskreport)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.weight)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.deltas)

    }
}