import {SOPutImpurity} from '../../core/impurity/so-put-impurity';
import {ImpurityCoreBase} from './impurity.core.base';
import {SOGetImpurity} from '../../core/impurity/so-get-impurity';
import {FormField, SelectionOption} from 'lionheartland';
import {putImpurityFormFieldOptions} from './put-impurity-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';

export class RAPutImpurity extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putImpurityFormFieldOptions);

  RequestClass = SOPutImpurity

  constructor(
    public entityCore: ImpurityCoreBase
  ) {
    super();
    if (putImpurityFormFieldOptions.load) {
      putImpurityFormFieldOptions.load.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('h20'),
      this.getFormValueFromIdentifier('neon'),
      this.getFormValueFromIdentifier('h2'),
      this.getFormValueFromIdentifier('o2'),
      this.getFormValueFromIdentifier('n2'),
      this.getFormValueFromIdentifier('carbonMonoxide'),
      this.getFormValueFromIdentifier('hydrocarbons'),
      this.getFormValueFromIdentifier('carbonDioxide'),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}