import {SOPostFaq} from '../../core/faq/so-post-faq';
import {FaqCoreBase} from './faq.core.base';
import {SOGetFaq} from '../../core/faq/so-get-faq';
import {FormField, SelectionOption} from 'lionheartland';
import {postFaqFormFieldOptions} from './post-faq-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPostFaq extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postFaqFormFieldOptions);

  RequestClass = SOPostFaq

  constructor(
    public entityCore: FaqCoreBase
  ) {
    super();
    if (postFaqFormFieldOptions.distribution) {
      postFaqFormFieldOptions.distribution.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('index'),
      this.getFormValueFromIdentifier('title'),
      this.getFormValueFromIdentifier('body'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}