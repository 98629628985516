import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetDistributionday } from '../distributionday/so-get-distributionday';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import { SOGetDistributionyear } from '../distributionyear/so-get-distributionyear';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetDistributionreport implements QueryProtocol {

  constructor(
    public uid: string = null,
    public ident: string = null,
    public distribution: SOGetDistribution = null,
    public timeWindow: string = null,
    public start: Date = null,
    public distributionday: SOGetDistributionday = null,
    public distributionmonth: SOGetDistributionmonth = null,
    public distributionyear: SOGetDistributionyear = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetDistributionreport(
      'uid' in params ? params.uid : null,
      'ident' in params ? params.ident : null,
      'distribution' in params ? params.distribution : null,
      'timeWindow' in params ? params.timeWindow : null,
      'start' in params ? params.start : null,
      'distributionday' in params ? params.distributionday : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'distributionyear' in params ? params.distributionyear : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
