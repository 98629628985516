import { SOGetFeeschedule } from '../feeschedule/so-get-feeschedule';


export class SOPutHyear {

  constructor(
    public uid: string,
    public state: string = null,
    public feeschedule: SOGetFeeschedule = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutHyear(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'feeschedule' in params ? params.feeschedule : null,
    );
  }
}
