import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPutFaq {

  constructor(
    public uid: string,
    public index: number = null,
    public title: string = null,
    public body: string = null,
    public distribution: SOGetDistribution = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutFaq(
      'uid' in params ? params.uid : null,
      'index' in params ? params.index : null,
      'title' in params ? params.title : null,
      'body' in params ? params.body : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
