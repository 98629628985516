import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostBulkFormFields {

    public body: FormField

    constructor() {

        this.body = new FormField(
          'body',
          'Body',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostBulkFormFields()

export const postBulkFormFieldOptions: {[key: string]: FormField} = {
  body: fields.body,
};