import { Type } from 'class-transformer';
import { SORespOffering } from '../offering/so-resp-offering';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDistoffer implements ObjectiveProtocol {

  entityType: string = 'distoffer';
  uid: string;
  @Type(() => SORespOffering)
  offering: SORespOffering;
}