import {SORespMessage} from '../../core/message/so-resp-message';
import {MessageCore} from './message.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutMessageFormFields} from './put-message-form-fields';
import { CEMessageAttributes } from './ce-message-attributes'


export class CEMessageBase extends CEBase {

    public details: CEMessageAttributes;
    public fromFields: PutMessageFormFields = new PutMessageFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: MessageCore,
        public entity: SORespMessage,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEMessageAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.timeSent)
        this.attributes.push(this.details.body)
        this.attributes.push(this.details.read)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.ref)
        this.attributes.push(this.details.subscription)
        this.attributes.push(this.details.publication)
        this.attributes.push(this.details.activity)
        this.attributes.push(this.details.user)

    }
}