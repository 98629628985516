import {SORespRateableagreeyearallocation} from '../../core/rateableagreeyearallocation/so-resp-rateableagreeyearallocation';
import {RateableagreeyearallocationCore} from './rateableagreeyearallocation.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutRateableagreeyearallocationFormFields} from './put-rateableagreeyearallocation-form-fields';
import { CERateableagreeyearallocationAttributes } from './ce-rateableagreeyearallocation-attributes'


export class CERateableagreeyearallocationBase extends CEBase {

    public details: CERateableagreeyearallocationAttributes;
    public fromFields: PutRateableagreeyearallocationFormFields = new PutRateableagreeyearallocationFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RateableagreeyearallocationCore,
        public entity: SORespRateableagreeyearallocation,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERateableagreeyearallocationAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.jan)
        this.attributes.push(this.details.feb)
        this.attributes.push(this.details.mar)
        this.attributes.push(this.details.apr)
        this.attributes.push(this.details.may)
        this.attributes.push(this.details.jun)
        this.attributes.push(this.details.jul)
        this.attributes.push(this.details.aug)
        this.attributes.push(this.details.sep)
        this.attributes.push(this.details.oct)
        this.attributes.push(this.details.nov)
        this.attributes.push(this.details.dec)
        this.attributes.push(this.details.agreeyear)

    }
}