import { Type } from 'class-transformer';
import { SORespTag } from '../tag/so-resp-tag';
import { SORespLine } from '../line/so-resp-line';
import { SORespTank } from '../tank/so-resp-tank';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespLinag implements ObjectiveProtocol {

  entityType: string = 'linag';
  uid: string;
  name: string;
  identifier: string;
  type: string;
  @Type(() => SORespTag)
  tag: SORespTag;
  @Type(() => SORespLine)
  line: SORespLine = null;
  @Type(() => SORespTank)
  tank: SORespTank = null;
}