import {SOPostAccountreport} from '../../core/accountreport/so-post-accountreport';
import {AccountreportCoreBase} from './accountreport.core.base';
import {SOGetAccountreport} from '../../core/accountreport/so-get-accountreport';
import {FormField, SelectionOption} from 'lionheartland';
import {postAccountreportFormFieldOptions} from './post-accountreport-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {DistributionreportCore} from '../distributionreport/distributionreport.core';
import {SOGetDistributionreport} from '../../core/distributionreport/so-get-distributionreport';

export class RAPostAccountreport extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAccountreportFormFieldOptions);

  RequestClass = SOPostAccountreport

  constructor(
    public entityCore: AccountreportCoreBase
  ) {
    super();
    if (postAccountreportFormFieldOptions.account) {
      postAccountreportFormFieldOptions.account.makeCore = true;
    }
    if (postAccountreportFormFieldOptions.distributionreport) {
      postAccountreportFormFieldOptions.distributionreport.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('ident'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
      ),
      this.getFormValueFromIdentifier('timeWindow'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier(
        'distributionreport',
        SOGetDistributionreport,
      ),
      this.getFormValueFromIdentifier('loadsProjected'),
      this.getFormValueFromIdentifier('loadsScheduled'),
      this.getFormValueFromIdentifier('loadsContracted'),
      this.getFormValueFromIdentifier('loadsActual'),
      this.getFormValueFromIdentifier('volumeActual'),
      this.getFormValueFromIdentifier('volumeProjected'),
      this.getFormValueFromIdentifier('volumeContracted'),
      this.getFormValueFromIdentifier('volumeScheduled'),
      this.getFormValueFromIdentifier('averageLoads'),
      this.getFormValueFromIdentifier('averageVolume'),
      this.getFormValueFromIdentifier('averageUnits'),
      this.getFormValueFromIdentifier('revenueProjected'),
      this.getFormValueFromIdentifier('revenueActual'),
      this.getFormValueFromIdentifier('revenueScheduled'),
      this.getFormValueFromIdentifier('revenueContracted'),
      this.getFormValueFromIdentifier('waspProjected'),
      this.getFormValueFromIdentifier('waspActual'),
      this.getFormValueFromIdentifier('waspScheduled'),
      this.getFormValueFromIdentifier('waspContracted'),
      this.getFormValueFromIdentifier('tankersActual'),
      this.getFormValueFromIdentifier('tankersScheduled'),
      this.getFormValueFromIdentifier('supertankersActual'),
      this.getFormValueFromIdentifier('supertankersScheduled'),
      this.getFormValueFromIdentifier('volumeDelta'),
      this.getFormValueFromIdentifier('loadsDelta'),
      this.getFormValueFromIdentifier('waspDelta'),
      this.getFormValueFromIdentifier('revenueDelta'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}