import {SOPutInvoice} from '../../core/invoice/so-put-invoice';
import {InvoiceCoreBase} from './invoice.core.base';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {FormField, SelectionOption} from 'lionheartland';
import {putInvoiceFormFieldOptions} from './put-invoice-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutInvoice extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putInvoiceFormFieldOptions);

  RequestClass = SOPutInvoice

  constructor(
    public entityCore: InvoiceCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('invoiceDate'),
      this.getFormValueFromIdentifier('sentDate'),
      this.getFormValueFromIdentifier('sent'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}