import { SOGetLoad } from '../load/so-get-load';


export class SOPutImpurity {

  constructor(
    public uid: string,
    public state: string = null,
    public h20: number = null,
    public neon: number = null,
    public h2: number = null,
    public o2: number = null,
    public n2: number = null,
    public carbonMonoxide: number = null,
    public hydrocarbons: number = null,
    public carbonDioxide: number = null,
    public load: SOGetLoad = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutImpurity(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'h20' in params ? params.h20 : null,
      'neon' in params ? params.neon : null,
      'h2' in params ? params.h2 : null,
      'o2' in params ? params.o2 : null,
      'n2' in params ? params.n2 : null,
      'carbonMonoxide' in params ? params.carbonMonoxide : null,
      'hydrocarbons' in params ? params.hydrocarbons : null,
      'carbonDioxide' in params ? params.carbonDioxide : null,
      'load' in params ? params.load : null,
    );
  }
}
