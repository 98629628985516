import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespOrgrola implements ObjectiveProtocol {

  entityType: string = 'orgrola';
  uid: string;
  isOwner: boolean = false;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}