import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostAccountreportFormFields {

    public ident: FormField
    public account: FormField
    public timeWindow: FormField
    public start: FormField
    public end: FormField
    public loadsProjected: FormField
    public loadsScheduled: FormField
    public loadsContracted: FormField
    public loadsActual: FormField
    public volumeActual: FormField
    public volumeProjected: FormField
    public volumeContracted: FormField
    public volumeScheduled: FormField
    public averageLoads: FormField
    public averageVolume: FormField
    public averageUnits: FormField
    public revenueProjected: FormField
    public revenueActual: FormField
    public revenueScheduled: FormField
    public revenueContracted: FormField
    public waspProjected: FormField
    public waspActual: FormField
    public waspScheduled: FormField
    public waspContracted: FormField
    public tankersActual: FormField
    public tankersScheduled: FormField
    public supertankersActual: FormField
    public supertankersScheduled: FormField
    public distributionreport: FormField
    public volumeDelta: FormField
    public loadsDelta: FormField
    public waspDelta: FormField
    public revenueDelta: FormField

    constructor() {

        this.ident = new FormField(
          'ident',
          'Ident',
          null,
          FormFieldType.text,
          true,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.timeWindow = new FormField(
          'timeWindow',
          'Time window',
          null,
          FormFieldType.text,
          true,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          true,
        )
        this.loadsProjected = new FormField(
          'loadsProjected',
          'Loads projected',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.loadsScheduled = new FormField(
          'loadsScheduled',
          'Loads scheduled',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.loadsContracted = new FormField(
          'loadsContracted',
          'Loads contracted',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.loadsActual = new FormField(
          'loadsActual',
          'Loads actual',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.volumeActual = new FormField(
          'volumeActual',
          'Volume actual',
          null,
          FormFieldType.text,
          false,
          true,
          0.0
        )
        this.volumeProjected = new FormField(
          'volumeProjected',
          'Volume projected',
          null,
          FormFieldType.text,
          false,
          true,
          0.0
        )
        this.volumeContracted = new FormField(
          'volumeContracted',
          'Volume contracted',
          null,
          FormFieldType.text,
          false,
          true,
          0.0
        )
        this.volumeScheduled = new FormField(
          'volumeScheduled',
          'Volume scheduled',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.averageLoads = new FormField(
          'averageLoads',
          'Average loads',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.averageVolume = new FormField(
          'averageVolume',
          'Average volume',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.averageUnits = new FormField(
          'averageUnits',
          'Average units',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.revenueProjected = new FormField(
          'revenueProjected',
          'Revenue projected',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.revenueActual = new FormField(
          'revenueActual',
          'Revenue actual',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.revenueScheduled = new FormField(
          'revenueScheduled',
          'Revenue scheduled',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.revenueContracted = new FormField(
          'revenueContracted',
          'Revenue contracted',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.waspProjected = new FormField(
          'waspProjected',
          'Wasp projected',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.waspActual = new FormField(
          'waspActual',
          'Wasp actual',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.waspScheduled = new FormField(
          'waspScheduled',
          'Wasp scheduled',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.waspContracted = new FormField(
          'waspContracted',
          'Wasp contracted',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.tankersActual = new FormField(
          'tankersActual',
          'Tankers actual',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.tankersScheduled = new FormField(
          'tankersScheduled',
          'Tankers scheduled',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.supertankersActual = new FormField(
          'supertankersActual',
          'Supertankers actual',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.supertankersScheduled = new FormField(
          'supertankersScheduled',
          'Supertankers scheduled',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.distributionreport = new FormField(
          'distributionreport',
          'Distributionreport',
          'distributionreport',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.volumeDelta = new FormField(
          'volumeDelta',
          'Volume delta',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.loadsDelta = new FormField(
          'loadsDelta',
          'Loads delta',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.waspDelta = new FormField(
          'waspDelta',
          'Wasp delta',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.revenueDelta = new FormField(
          'revenueDelta',
          'Revenue delta',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )

    }
}

const fields = new PostAccountreportFormFields()

export const postAccountreportFormFieldOptions: {[key: string]: FormField} = {
  ident: fields.ident,
  account: fields.account,
  timeWindow: fields.timeWindow,
  start: fields.start,
  end: fields.end,
  loadsProjected: fields.loadsProjected,
  loadsScheduled: fields.loadsScheduled,
  loadsContracted: fields.loadsContracted,
  loadsActual: fields.loadsActual,
  volumeActual: fields.volumeActual,
  volumeProjected: fields.volumeProjected,
  volumeContracted: fields.volumeContracted,
  volumeScheduled: fields.volumeScheduled,
  averageLoads: fields.averageLoads,
  averageVolume: fields.averageVolume,
  averageUnits: fields.averageUnits,
  revenueProjected: fields.revenueProjected,
  revenueActual: fields.revenueActual,
  revenueScheduled: fields.revenueScheduled,
  revenueContracted: fields.revenueContracted,
  waspProjected: fields.waspProjected,
  waspActual: fields.waspActual,
  waspScheduled: fields.waspScheduled,
  waspContracted: fields.waspContracted,
  tankersActual: fields.tankersActual,
  tankersScheduled: fields.tankersScheduled,
  supertankersActual: fields.supertankersActual,
  supertankersScheduled: fields.supertankersScheduled,
  distributionreport: fields.distributionreport,
  volumeDelta: fields.volumeDelta,
  loadsDelta: fields.loadsDelta,
  waspDelta: fields.waspDelta,
  revenueDelta: fields.revenueDelta,
};