import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODeltaweightService} from '../../core/deltaweight/eo-deltaweight';
import {SOGetDeltaweight} from '../../core/deltaweight/so-get-deltaweight';
import {RAPutDeltaweight} from './ra-put-deltaweight';
import {RAPostDeltaweight} from './ra-post-deltaweight';
import {RADeleteDeltaweight} from './ra-delete-deltaweight';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEDeltaweight } from "./ce-deltaweight"


export class DeltaweightCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'deltaweight';
  title = 'Deltaweight';
  ceProtocol: CEConstructor = CEDeltaweight

  entityService: EODeltaweightService;
  soGetClass = SOGetDeltaweight;
  soGet: SOGetDeltaweight;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODeltaweightService
    );
    this.adapters = [
      new RAPostDeltaweight(this),
      new RAPutDeltaweight(this),
      new RADeleteDeltaweight(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDeltaweight();
    this.soGet.queryOptions.limit = this.limit;
  }


}