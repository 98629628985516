import { Type } from 'class-transformer';
import {ENViewType} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespNavi implements ObjectiveProtocol {

  entityType: string = 'navi';
  uid: string;
  identifier: string;
  name: string;
  type: ENViewType;
}