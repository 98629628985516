import { SOGetSubscription } from '../subscription/so-get-subscription';
import { SOGetPublication } from '../publication/so-get-publication';
import { SOGetActivity } from '../activity/so-get-activity';


export class SOPostMessage {

  constructor(
    public body: string,
    public type: string,
    public ref: string = null,
    public subscription: SOGetSubscription = null,
    public publication: SOGetPublication = null,
    public activity: SOGetActivity = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostMessage(
      'body' in params ? params.body : null,
      'type' in params ? params.type : null,
      'ref' in params ? params.ref : null,
      'subscription' in params ? params.subscription : null,
      'publication' in params ? params.publication : null,
      'activity' in params ? params.activity : null,
    );
  }
}
