import {SOPostLine} from '../../core/line/so-post-line';
import {LineCoreBase} from './line.core.base';
import {SOGetLine} from '../../core/line/so-get-line';
import {FormField, SelectionOption} from 'lionheartland';
import {postLineFormFieldOptions} from './post-line-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {PlantCore} from '../plant/plant.core';
import {SOGetPlant} from '../../core/plant/so-get-plant';
import {ProductCore} from '../product/product.core';
import {SOGetProduct} from '../../core/product/so-get-product';

export class RAPostLine extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postLineFormFieldOptions);

  RequestClass = SOPostLine

  constructor(
    public entityCore: LineCoreBase
  ) {
    super();
    if (postLineFormFieldOptions.plant) {
      postLineFormFieldOptions.plant.makeCore = true;
    }
    if (postLineFormFieldOptions.product) {
      postLineFormFieldOptions.product.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'plant',
        SOGetPlant,
      ),
      this.getFormValueFromIdentifier(
        'product',
        SOGetProduct,
      ),
      this.getFormValueFromIdentifier('units'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}