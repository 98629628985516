import {SORespTrailer} from '../../core/trailer/so-resp-trailer';
import {TrailerCore} from './trailer.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTrailerFormFields} from './put-trailer-form-fields';
import { CETrailerAttributes } from './ce-trailer-attributes'


export class CETrailerBase extends CEBase {

    public details: CETrailerAttributes;
    public fromFields: PutTrailerFormFields = new PutTrailerFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TrailerCore,
        public entity: SORespTrailer,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETrailerAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.chassisId)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.size)
        this.attributes.push(this.details.dotId)
        this.attributes.push(this.details.dotInspectionDate)
        this.attributes.push(this.details.dotE)
        this.attributes.push(this.details.dotSp)
        this.attributes.push(this.details.mawp)
        this.attributes.push(this.details.attachment)
        this.attributes.push(this.details.linFirst)
        this.attributes.push(this.details.averageEmptyWeight)
        this.attributes.push(this.details.loadCount)
        this.attributes.push(this.details.fillVolume)
        this.attributes.push(this.details.mawt)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.trailerauths)
        this.attributes.push(this.details.trailermerges)
        this.attributes.push(this.details.organization)
        this.attributes.push(this.details.containersessions)

    }
}