import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostCalendarFormFields {

    public name: FormField
    public label: FormField
    public distribution: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.label = new FormField(
          'label',
          'Label',
          null,
          FormFieldType.text,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostCalendarFormFields()

export const postCalendarFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  label: fields.label,
};