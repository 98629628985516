import {SORespChronreport} from '../../core/chronreport/so-resp-chronreport';
import {ChronreportCore} from './chronreport.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutChronreportFormFields} from './put-chronreport-form-fields';
import {SOGetChronreport} from '../../core/chronreport/so-get-chronreport';


export class CEChronreportAttributesBase {


    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespChronreport,
        app: GasDirectApp,
        formFields: PutChronreportFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


    }
}