import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTemptractFormFields {

    public name: FormField
    public durationDays: FormField
    public takeOrPay: FormField
    public minLoadSizeToQualify: FormField
    public type: FormField
    public docusignLink: FormField
    public description: FormField
    public distribution: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.durationDays = new FormField(
          'durationDays',
          'Duration days',
          null,
          FormFieldType.text,
          false,
          true,
          365
        )
        this.takeOrPay = new FormField(
          'takeOrPay',
          'Take or pay',
          null,
          FormFieldType.text,
          true,
        )
        this.minLoadSizeToQualify = new FormField(
          'minLoadSizeToQualify',
          'Min load size to qualify',
          null,
          FormFieldType.text,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )
        this.docusignLink = new FormField(
          'docusignLink',
          'Docusign link',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostTemptractFormFields()

export const postTemptractFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  durationDays: fields.durationDays,
  takeOrPay: fields.takeOrPay,
  minLoadSizeToQualify: fields.minLoadSizeToQualify,
  type: fields.type,
  docusignLink: fields.docusignLink,
  description: fields.description,
  distribution: fields.distribution,
};