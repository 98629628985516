import {SOPutAccount} from '../../core/account/so-put-account';
import {AccountCoreBase} from './account.core.base';
import {SOGetAccount} from '../../core/account/so-get-account';
import {FormField, SelectionOption} from 'lionheartland';
import {putAccountFormFieldOptions} from './put-account-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutAccount extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAccountFormFieldOptions);

  RequestClass = SOPutAccount

  constructor(
    public entityCore: AccountCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('salesTaxState'),
      this.getFormValueFromIdentifier('interruptibleExpiration'),
      this.getFormValueFromIdentifier('averageLoadSize'),
      this.getFormValueFromIdentifier('balanceMatchThreshold'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}