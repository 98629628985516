import { SOGetProduction } from '../production/so-get-production';


export class SOPostProductionreport {

  constructor(
    public ident: string,
    public production: SOGetProduction,
    public timeWindow: string,
    public start: Date,
    public end: Date,
    public average: number = 0,
    public averageUnits: string = "",
    public midnightWeight: number = 0,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostProductionreport(
      'ident' in params ? params.ident : null,
      'production' in params ? params.production : null,
      'timeWindow' in params ? params.timeWindow : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'average' in params ? params.average : null,
      'averageUnits' in params ? params.averageUnits : null,
      'midnightWeight' in params ? params.midnightWeight : null,
    );
  }
}
