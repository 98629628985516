import {SOPostReservation} from '../../core/reservation/so-post-reservation';
import {ReservationCoreBase} from './reservation.core.base';
import {SOGetReservation} from '../../core/reservation/so-get-reservation';
import {FormField, SelectionOption} from 'lionheartland';
import {postReservationFormFieldOptions} from './post-reservation-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {SlotCore} from '../slot/slot.core';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';

export class RAPostReservation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postReservationFormFieldOptions);

  RequestClass = SOPostReservation

  constructor(
    public entityCore: ReservationCoreBase
  ) {
    super();
    if (postReservationFormFieldOptions.slot) {
      postReservationFormFieldOptions.slot.makeCore = true;
    }
    if (postReservationFormFieldOptions.account) {
      postReservationFormFieldOptions.account.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'slot',
        SOGetSlot,
      ),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
      ),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}