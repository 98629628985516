import { SOGetUser } from '../user/so-get-user';
import { SOGetCustomer } from '../customer/so-get-customer';
import { SOGetCarrier } from '../carrier/so-get-carrier';
import { SOGetOrgrola } from '../orgrola/so-get-orgrola';
import { SOGetTrailer } from '../trailer/so-get-trailer';


export class SOPutOrganization {

  constructor(
    public uid: string,
    public users: SOGetUser[] = null,
    public handle: string = null,
    public ownerId: string = null,
    public customer: SOGetCustomer = null,
    public carrier: SOGetCarrier = null,
    public orgrolas: SOGetOrgrola[] = null,
    public trailers: SOGetTrailer[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutOrganization(
      'uid' in params ? params.uid : null,
      'users' in params ? params.users : null,
      'handle' in params ? params.handle : null,
      'ownerId' in params ? params.ownerId : null,
      'customer' in params ? params.customer : null,
      'carrier' in params ? params.carrier : null,
      'orgrolas' in params ? params.orgrolas : null,
      'trailers' in params ? params.trailers : null,
    );
  }
}
