

export class SOPutInquiry {

  constructor(
    public uid: string,
    public companyName: string = null,
    public givenName: string = null,
    public surname: string = null,
    public productInquiryStr: string = null,
    public estimatedPurchaseQuantity: string = null,
    public preferredEmailAddress: string = null,
    public phoneNumber: string = null,
    public dateCreated: Date = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutInquiry(
      'uid' in params ? params.uid : null,
      'companyName' in params ? params.companyName : null,
      'givenName' in params ? params.givenName : null,
      'surname' in params ? params.surname : null,
      'productInquiryStr' in params ? params.productInquiryStr : null,
      'estimatedPurchaseQuantity' in params ? params.estimatedPurchaseQuantity : null,
      'preferredEmailAddress' in params ? params.preferredEmailAddress : null,
      'phoneNumber' in params ? params.phoneNumber : null,
      'dateCreated' in params ? params.dateCreated : null,
    );
  }
}
