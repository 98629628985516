import { SOGetLoad } from '../load/so-get-load';
import { SOGetContainersession } from '../containersession/so-get-containersession';


export class SOPostBol {

  constructor(
    public load: SOGetLoad = null,
    public containersession: SOGetContainersession = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostBol(
      'load' in params ? params.load : null,
      'containersession' in params ? params.containersession : null,
    );
  }
}
