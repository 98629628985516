import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostDistributionday {

  constructor(
    public day: number,
    public distributionmonth: SOGetDistributionmonth,
    public distribution: SOGetDistribution,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDistributionday(
      'day' in params ? params.day : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
