import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutReservation } from './so-put-reservation';
import { SOGetReservation } from './so-get-reservation';
import { SOPostReservation } from './so-post-reservation';
import { SODeleteReservation } from './so-delete-reservation';
import {SORespReservation} from './so-resp-reservation';
import {SOPluralReservation} from './so-plural-reservation';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOReservationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'reservation',
      entityCore
    );
  }

  /** GET Reservation by id. Will 404 if id not found */
  get(request: SOGetReservation): Observable<SOPluralReservation> {
    return this.httpClient.get<SOPluralReservation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralReservation, resp) }),
      tap(_ => this.log(`fetched Reservation`)),
      catchError(this.handleError<SOPluralReservation>(`get Reservation`))
    );
  }

  /** POST: add a new Reservation to the server */
  post(request: SOPostReservation): Observable<SORespReservation> {
    return this.httpClient.post<SORespReservation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespReservation, resp) }),
      tap((response: SORespReservation) => this.log(`added Reservation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespReservation>('Reservation post'))
    );
  }

  /** PUT: update Reservation  */
  put(request: SOPutReservation): Observable<SORespReservation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespReservation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespReservation, resp) }),
      tap((response: SORespReservation) => this.log(`edited Reservation w/ uid=${uid}`)),
      catchError(this.handleError<SORespReservation>('Reservation put'))
    );
  }

  /** DELETE: delete the Reservation from the server */
  delete(request: SODeleteReservation): Observable<SORespReservation> {
    return this.httpClient.delete<SORespReservation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Reservation uid=${request.uid}`)),
      catchError(this.handleError<SORespReservation>('delete Reservation'))
    );
  }
}
