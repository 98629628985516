import {SOPutCustomer} from '../../core/customer/so-put-customer';
import {CustomerCoreBase} from './customer.core.base';
import {SOGetCustomer} from '../../core/customer/so-get-customer';
import {FormField, SelectionOption} from 'lionheartland';
import {putCustomerFormFieldOptions} from './put-customer-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutCustomer extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putCustomerFormFieldOptions);

  RequestClass = SOPutCustomer

  constructor(
    public entityCore: CustomerCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('companyName'),
      this.getFormValueFromIdentifier('emailDomain'),
      this.getFormValueFromIdentifier('signedAgreement'),
      this.getFormValueFromIdentifier('handle'),
      this.getFormValueFromIdentifier('fullCompanyName'),
      this.getFormValueFromIdentifier('address'),
      this.getFormValueFromIdentifier('remittorNumber'),
      this.getFormValueFromIdentifier('additionalInvoiceText'),
      this.getFormValueFromIdentifier('billingPoc'),
      this.getFormValueFromIdentifier('paymentTerms'),
      this.getFormValueFromIdentifier('stripeRef'),
      this.getFormValueFromIdentifier('stripeDirectDebit'),
      this.getFormValueFromIdentifier('stripeVerify1'),
      this.getFormValueFromIdentifier('stripeVerify2'),
      this.getFormValueFromIdentifier('creditLimit'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}