

export class SOPutAdjustment {

  constructor(
    public uid: string,
    public quantity: number = null,
    public description: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAdjustment(
      'uid' in params ? params.uid : null,
      'quantity' in params ? params.quantity : null,
      'description' in params ? params.description : null,
    );
  }
}
