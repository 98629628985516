import {SOPutAgreement} from '../../core/agreement/so-put-agreement';
import {AgreementCoreBase} from './agreement.core.base';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {FormField, SelectionOption} from 'lionheartland';
import {putAgreementFormFieldOptions} from './put-agreement-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {FeescheduleCore} from '../feeschedule/feeschedule.core';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';

export class RAPutAgreement extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAgreementFormFieldOptions);

  RequestClass = SOPutAgreement

  constructor(
    public entityCore: AgreementCoreBase
  ) {
    super();
    if (putAgreementFormFieldOptions.feeschedule) {
      putAgreementFormFieldOptions.feeschedule.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('docusignLink'),
      this.getFormValueFromIdentifier('units'),
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier('autoRenew'),
      this.getFormValueFromIdentifier('acq'),
      this.getFormValueFromIdentifier('top'),
      this.getFormValueFromIdentifier(
        'feeschedule',
        SOGetFeeschedule,
      ),
      this.getFormValueFromIdentifier('variableAcq'),
      this.getFormValueFromIdentifier('rateable'),
      this.getFormValueFromIdentifier('prePaymentRequired'),
      this.getFormValueFromIdentifier('sapContractNumber'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}