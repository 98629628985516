import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOProviderService} from '../../core/provider/eo-provider';
import {SOGetProvider} from '../../core/provider/so-get-provider';
import {RAPutProvider} from './ra-put-provider';
import {RAPostProvider} from './ra-post-provider';
import {RADeleteProvider} from './ra-delete-provider';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEProvider } from "./ce-provider"


export class ProviderCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'provider';
  title = 'Provider';
  ceProtocol: CEConstructor = CEProvider

  entityService: EOProviderService;
  soGetClass = SOGetProvider;
  soGet: SOGetProvider;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOProviderService
    );
    this.adapters = [
      new RAPostProvider(this),
      new RAPutProvider(this),
      new RADeleteProvider(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetProvider();
    this.soGet.queryOptions.limit = this.limit;
  }


}