import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutSlotlet } from './so-put-slotlet';
import { SOGetSlotlet } from './so-get-slotlet';
import { SOPostSlotlet } from './so-post-slotlet';
import { SODeleteSlotlet } from './so-delete-slotlet';
import {SORespSlotlet} from './so-resp-slotlet';
import {SOPluralSlotlet} from './so-plural-slotlet';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOSlotletService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'slotlet',
      entityCore
    );
  }

  /** GET Slotlet by id. Will 404 if id not found */
  get(request: SOGetSlotlet): Observable<SOPluralSlotlet> {
    return this.httpClient.get<SOPluralSlotlet>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralSlotlet, resp) }),
      tap(_ => this.log(`fetched Slotlet`)),
      catchError(this.handleError<SOPluralSlotlet>(`get Slotlet`))
    );
  }

  /** POST: add a new Slotlet to the server */
  post(request: SOPostSlotlet): Observable<SORespSlotlet> {
    return this.httpClient.post<SORespSlotlet>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSlotlet, resp) }),
      tap((response: SORespSlotlet) => this.log(`added Slotlet w/ id=${response.uid}`)),
      catchError(this.handleError<SORespSlotlet>('Slotlet post'))
    );
  }

  /** PUT: update Slotlet  */
  put(request: SOPutSlotlet): Observable<SORespSlotlet> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSlotlet>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSlotlet, resp) }),
      tap((response: SORespSlotlet) => this.log(`edited Slotlet w/ uid=${uid}`)),
      catchError(this.handleError<SORespSlotlet>('Slotlet put'))
    );
  }

  /** DELETE: delete the Slotlet from the server */
  delete(request: SODeleteSlotlet): Observable<SORespSlotlet> {
    return this.httpClient.delete<SORespSlotlet>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Slotlet uid=${request.uid}`)),
      catchError(this.handleError<SORespSlotlet>('delete Slotlet'))
    );
  }
}
