import {SOPostPrice} from '../../core/price/so-post-price';
import {PriceCoreBase} from './price.core.base';
import {SOGetPrice} from '../../core/price/so-get-price';
import {FormField, SelectionOption} from 'lionheartland';
import {postPriceFormFieldOptions} from './post-price-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {ClinCore} from '../clin/clin.core';
import {SOGetClin} from '../../core/clin/so-get-clin';
import {AgreeyearCore} from '../agreeyear/agreeyear.core';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';

export class RAPostPrice extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postPriceFormFieldOptions);

  RequestClass = SOPostPrice

  constructor(
    public entityCore: PriceCoreBase
  ) {
    super();
    if (postPriceFormFieldOptions.clin) {
      postPriceFormFieldOptions.clin.makeCore = true;
    }
    if (postPriceFormFieldOptions.agreeyear) {
      postPriceFormFieldOptions.agreeyear.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('startDate'),
      this.getFormValueFromIdentifier('endDate'),
      this.getFormValueFromIdentifier('rate'),
      this.getFormValueFromIdentifier('rank'),
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier('loadQuantity'),
      this.getFormValueFromIdentifier(
        'clin',
        SOGetClin,
      ),
      this.getFormValueFromIdentifier(
        'agreeyear',
        SOGetAgreeyear,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}