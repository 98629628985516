import { SOGetAccount } from '../account/so-get-account';
import { SOGetCalendar } from '../calendar/so-get-calendar';
import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPutSlot {

  constructor(
    public uid: string,
    public status: string = null,
    public slotNumber: number = null,
    public slotDay: number = null,
    public start: Date = null,
    public end: Date = null,
    public account: SOGetAccount = null,
    public calendar: SOGetCalendar = null,
    public distribution: SOGetDistribution = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSlot(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
      'slotNumber' in params ? params.slotNumber : null,
      'slotDay' in params ? params.slotDay : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'account' in params ? params.account : null,
      'calendar' in params ? params.calendar : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
