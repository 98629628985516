import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTaskreportFormFields {

    public type: FormField
    public status: FormField
    public distribution: FormField
    public dateRequested: FormField
    public dateStart: FormField
    public dateEnd: FormField

    constructor() {

        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )
        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.dateRequested = new FormField(
          'dateRequested',
          'Date requested',
          null,
          FormFieldType.datetime,
          false,
        )
        this.dateStart = new FormField(
          'dateStart',
          'Date start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.dateEnd = new FormField(
          'dateEnd',
          'Date end',
          null,
          FormFieldType.datetime,
          false,
        )

    }
}

const fields = new PostTaskreportFormFields()

export const postTaskreportFormFieldOptions: {[key: string]: FormField} = {
  type: fields.type,
  status: fields.status,
  distribution: fields.distribution,
  dateRequested: fields.dateRequested,
  dateStart: fields.dateStart,
  dateEnd: fields.dateEnd,
};