import { Type } from 'class-transformer';
import { SORespContract } from '../contract/so-resp-contract';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespClinyearlng implements ObjectiveProtocol {

  entityType: string = 'clinyearlng';
  uid: string;
  name: string;
  @Type(() => Date)
  start: Date;
  period: string;
  periodNumber: number;
  volumeTier1: number;
  priceTier1: number;
  volumeTier2: number;
  priceTier2: number;
  @Type(() => SORespContract)
  contract: SORespContract;
}