import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTemptraclinFormFields {

    public tempclin: FormField
    public temptract: FormField

    constructor() {

        this.tempclin = new FormField(
          'tempclin',
          'Tempclin',
          'tempclin',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.temptract = new FormField(
          'temptract',
          'Temptract',
          'temptract',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutTemptraclinFormFields()

export const putTemptraclinFormFieldOptions: {[key: string]: FormField} = {
  tempclin: fields.tempclin,
  temptract: fields.temptract,
};