import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostDistributionreport {

  constructor(
    public ident: string,
    public distribution: SOGetDistribution,
    public timeWindow: string,
    public start: Date,
    public end: Date,
    public tankersScheduled: number = 0,
    public tankersActual: number = 0,
    public supertankersScheduled: number = 0,
    public supertankersActual: number = 0,
    public revenueProjected: number = 0,
    public revenueActual: number = 0,
    public revenueContracted: number = 0,
    public revenueScheduled: number = 0,
    public loadsActual: number = 0,
    public loadsProjected: number = 0,
    public loadsContracted: number = 0,
    public loadsScheduled: number = 0,
    public volumeActual: number = 0,
    public volumeProjected: number = 0,
    public volumeContracted: number = 0,
    public volumeScheduled: number = 0,
    public waspProjected: number = 0,
    public waspScheduled: number = 0,
    public waspContracted: number = 0,
    public waspActual: number = 0,
    public averageLoads: number = 0,
    public averageVolume: number = 0,
    public averageUnits: string = "",
    public volumeDelta: number = 0,
    public loadsDelta: number = 0,
    public waspDelta: number = 0,
    public revenueDelta: number = 0,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDistributionreport(
      'ident' in params ? params.ident : null,
      'distribution' in params ? params.distribution : null,
      'timeWindow' in params ? params.timeWindow : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'tankersScheduled' in params ? params.tankersScheduled : null,
      'tankersActual' in params ? params.tankersActual : null,
      'supertankersScheduled' in params ? params.supertankersScheduled : null,
      'supertankersActual' in params ? params.supertankersActual : null,
      'revenueProjected' in params ? params.revenueProjected : null,
      'revenueActual' in params ? params.revenueActual : null,
      'revenueContracted' in params ? params.revenueContracted : null,
      'revenueScheduled' in params ? params.revenueScheduled : null,
      'loadsActual' in params ? params.loadsActual : null,
      'loadsProjected' in params ? params.loadsProjected : null,
      'loadsContracted' in params ? params.loadsContracted : null,
      'loadsScheduled' in params ? params.loadsScheduled : null,
      'volumeActual' in params ? params.volumeActual : null,
      'volumeProjected' in params ? params.volumeProjected : null,
      'volumeContracted' in params ? params.volumeContracted : null,
      'volumeScheduled' in params ? params.volumeScheduled : null,
      'waspProjected' in params ? params.waspProjected : null,
      'waspScheduled' in params ? params.waspScheduled : null,
      'waspContracted' in params ? params.waspContracted : null,
      'waspActual' in params ? params.waspActual : null,
      'averageLoads' in params ? params.averageLoads : null,
      'averageVolume' in params ? params.averageVolume : null,
      'averageUnits' in params ? params.averageUnits : null,
      'volumeDelta' in params ? params.volumeDelta : null,
      'loadsDelta' in params ? params.loadsDelta : null,
      'waspDelta' in params ? params.waspDelta : null,
      'revenueDelta' in params ? params.revenueDelta : null,
    );
  }
}
