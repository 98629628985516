import {environment} from "../environments/environment";
import {AuthConfig} from "angular-oauth2-oidc";
import {
    LandAuthConfig
} from "lionheartland";

export const authConfig: LandAuthConfig = new LandAuthConfig(
    {
        // Url of the Identity Provider
        issuer: `https://login.microsoftonline.com/${environment.azureSsoAccountId}/v2.0`,
        strictDiscoveryDocumentValidation: false,

        // URL of the SPA to redirect the active-user to after login
        // URL of the SPA to redirect the active-user to after login
        redirectUri: environment.redirectUri,
        postLogoutRedirectUri: environment.postLogoutRedirectUri,

        // The SPA's id. The SPA is registerd with this id at the auth-server
        // clientId: 'server.code',
        clientId: environment.azureSsoAppId,

        // Just needed if your auth server demands a secret. In general, this
        // is a sign that the auth server is not configured with SPAs in mind
        // and it might not enforce further best practices vital for security
        // such applications.
        // dummyClientSecret: 'secret',

        responseType: 'code',
        // requestAccessToken: false,

        // set the scope for the permissions the client should request
        // The first four are defined by OIDC.
        // Important: Request offline_access to get a refresh token
        // The api scope is a usecase specific one
        scope: 'openid profile email offline_access',
        // scope: 'https://graph.microsoft.com/user.read offline_access',
        // scope: 'https://graph.microsoft.com/user.read',

        showDebugInformation: true,

        // Not recommented:
        // disablePKCI: true,
    },
    `https://login.microsoftonline.com/${environment.azureSsoAccountId}/v2.0/.well-known/openid-configuration`,
    'azure'
);
