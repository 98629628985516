import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOServiceRequestService} from '../../core/service-request/eo-service-request';
import {SOGetServiceRequest} from '../../core/service-request/so-get-service-request';
import {RAPutServiceRequest} from './ra-put-service-request';
import {RAPostServiceRequest} from './ra-post-service-request';
import {RADeleteServiceRequest} from './ra-delete-service-request';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEServiceRequest } from "./ce-service-request"


export class ServiceRequestCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'serviceRequest';
  title = 'Service request';
  ceProtocol: CEConstructor = CEServiceRequest

  entityService: EOServiceRequestService;
  soGetClass = SOGetServiceRequest;
  soGet: SOGetServiceRequest;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOServiceRequestService
    );
    this.adapters = [
      new RAPostServiceRequest(this),
      new RAPutServiceRequest(this),
      new RADeleteServiceRequest(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetServiceRequest();
    this.soGet.queryOptions.limit = this.limit;
  }


}