import { SOGetLoad } from '../load/so-get-load';
import {ENTare} from '../../enums';


export class SOPutWeight {

  constructor(
    public uid: string,
    public dateCreated: Date = null,
    public tare: ENTare = null,
    public amount: number = null,
    public load: SOGetLoad = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutWeight(
      'uid' in params ? params.uid : null,
      'dateCreated' in params ? params.dateCreated : null,
      'tare' in params ? params.tare : null,
      'amount' in params ? params.amount : null,
      'load' in params ? params.load : null,
    );
  }
}
