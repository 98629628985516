import {SOPostClin} from '../../core/clin/so-post-clin';
import {ClinCoreBase} from './clin.core.base';
import {SOGetClin} from '../../core/clin/so-get-clin';
import {FormField, SelectionOption} from 'lionheartland';
import {postClinFormFieldOptions} from './post-clin-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {ContractCore} from '../contract/contract.core';
import {SOGetContract} from '../../core/contract/so-get-contract';
import {TempclinCore} from '../tempclin/tempclin.core';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {SlotCore} from '../slot/slot.core';
import {SOGetSlot} from '../../core/slot/so-get-slot';

export class RAPostClin extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postClinFormFieldOptions);

  RequestClass = SOPostClin

  constructor(
    public entityCore: ClinCoreBase
  ) {
    super();
    if (postClinFormFieldOptions.contract) {
      postClinFormFieldOptions.contract.makeCore = true;
    }
    if (postClinFormFieldOptions.tempclin) {
      postClinFormFieldOptions.tempclin.makeCore = true;
    }
    if (postClinFormFieldOptions.slot) {
      postClinFormFieldOptions.slot.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier(
        'contract',
        SOGetContract,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'tempclin',
        SOGetTempclin,
      ),
      this.getFormValueFromIdentifier(
        'slot',
        SOGetSlot,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}