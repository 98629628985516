import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutQuoteletFormFields {

    public type: FormField
    public units: FormField
    public quantityVolume: FormField
    public quantityLoads: FormField
    public indexRate: FormField
    public variableRate: FormField
    public totalRate: FormField
    public totalAmount: FormField
    public order: FormField

    constructor() {

        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          false,
        )
        this.units = new FormField(
          'units',
          'Units',
          null,
          FormFieldType.text,
          false,
        )
        this.quantityVolume = new FormField(
          'quantityVolume',
          'Quantity volume',
          null,
          FormFieldType.text,
          false,
        )
        this.quantityLoads = new FormField(
          'quantityLoads',
          'Quantity loads',
          null,
          FormFieldType.text,
          false,
        )
        this.indexRate = new FormField(
          'indexRate',
          'Index rate',
          null,
          FormFieldType.text,
          false,
        )
        this.variableRate = new FormField(
          'variableRate',
          'Variable rate',
          null,
          FormFieldType.text,
          false,
        )
        this.totalRate = new FormField(
          'totalRate',
          'Total rate',
          null,
          FormFieldType.text,
          false,
        )
        this.totalAmount = new FormField(
          'totalAmount',
          'Total amount',
          null,
          FormFieldType.text,
          false,
        )
        this.order = new FormField(
          'order',
          'Order',
          'order',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutQuoteletFormFields()

export const putQuoteletFormFieldOptions: {[key: string]: FormField} = {
  type: fields.type,
  units: fields.units,
  quantityVolume: fields.quantityVolume,
  quantityLoads: fields.quantityLoads,
  indexRate: fields.indexRate,
  variableRate: fields.variableRate,
  totalRate: fields.totalRate,
  totalAmount: fields.totalAmount,
  order: fields.order,
};