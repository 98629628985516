import {SORespTransaction} from '../../core/transaction/so-resp-transaction';
import {TransactionCore} from './transaction.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTransactionFormFields} from './put-transaction-form-fields';
import { CETransactionAttributes } from './ce-transaction-attributes'


export class CETransactionBase extends CEBase {

    public details: CETransactionAttributes;
    public fromFields: PutTransactionFormFields = new PutTransactionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TransactionCore,
        public entity: SORespTransaction,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETransactionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.amount)
        this.attributes.push(this.details.balance)
        this.attributes.push(this.details.finalLoadCost)
        this.attributes.push(this.details.reconciliationAdjustment)
        this.attributes.push(this.details.transactionType)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.order)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.invoice)
        this.attributes.push(this.details.distributionday)
        this.attributes.push(this.details.distributionmonth)

    }
}