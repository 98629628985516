import {SOPostNavi} from '../../core/navi/so-post-navi';
import {NaviCoreBase} from './navi.core.base';
import {SOGetNavi} from '../../core/navi/so-get-navi';
import {FormField, SelectionOption} from 'lionheartland';
import {postNaviFormFieldOptions} from './post-navi-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostNavi extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postNaviFormFieldOptions);

  RequestClass = SOPostNavi

  constructor(
    public entityCore: NaviCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('type'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}