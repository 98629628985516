import { Type } from 'class-transformer';
import { SORespSlot } from '../slot/so-resp-slot';
import { SORespAgreemonth } from '../agreemonth/so-resp-agreemonth';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespSlotoption implements ObjectiveProtocol {

  entityType: string = 'slotoption';
  uid: string;
  @Type(() => SORespSlot)
  slot: SORespSlot;
  @Type(() => SORespAgreemonth)
  agreemonth: SORespAgreemonth;
}