import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostNavtemrolaFormFields {

    public index: FormField
    public mainNav: FormField
    public temprole: FormField
    public navi: FormField

    constructor() {

        this.index = new FormField(
          'index',
          'Index',
          null,
          FormFieldType.text,
          false,
        )
        this.mainNav = new FormField(
          'mainNav',
          'Main nav',
          null,
          FormFieldType.boolean,
          false,
        )
        this.temprole = new FormField(
          'temprole',
          'Temprole',
          'temprole',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.navi = new FormField(
          'navi',
          'Navi',
          'navi',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostNavtemrolaFormFields()

export const postNavtemrolaFormFieldOptions: {[key: string]: FormField} = {
  index: fields.index,
  mainNav: fields.mainNav,
  temprole: fields.temprole,
  navi: fields.navi,
};