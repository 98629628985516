import {SOPostProductionreport} from '../../core/productionreport/so-post-productionreport';
import {ProductionreportCoreBase} from './productionreport.core.base';
import {SOGetProductionreport} from '../../core/productionreport/so-get-productionreport';
import {FormField, SelectionOption} from 'lionheartland';
import {postProductionreportFormFieldOptions} from './post-productionreport-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {ProductionCore} from '../production/production.core';
import {SOGetProduction} from '../../core/production/so-get-production';

export class RAPostProductionreport extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postProductionreportFormFieldOptions);

  RequestClass = SOPostProductionreport

  constructor(
    public entityCore: ProductionreportCoreBase
  ) {
    super();
    if (postProductionreportFormFieldOptions.production) {
      postProductionreportFormFieldOptions.production.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('ident'),
      this.getFormValueFromIdentifier(
        'production',
        SOGetProduction,
      ),
      this.getFormValueFromIdentifier('timeWindow'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier('average'),
      this.getFormValueFromIdentifier('averageUnits'),
      this.getFormValueFromIdentifier('midnightWeight'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}