import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutBid } from './so-put-bid';
import { SOGetBid } from './so-get-bid';
import { SOPostBid } from './so-post-bid';
import { SODeleteBid } from './so-delete-bid';
import {SORespBid} from './so-resp-bid';
import {SOPluralBid} from './so-plural-bid';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOBidService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'bid',
      entityCore
    );
  }

  /** GET Bid by id. Will 404 if id not found */
  get(request: SOGetBid): Observable<SOPluralBid> {
    return this.httpClient.get<SOPluralBid>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralBid, resp) }),
      tap(_ => this.log(`fetched Bid`)),
      catchError(this.handleError<SOPluralBid>(`get Bid`))
    );
  }

  /** POST: add a new Bid to the server */
  post(request: SOPostBid): Observable<SORespBid> {
    return this.httpClient.post<SORespBid>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBid, resp) }),
      tap((response: SORespBid) => this.log(`added Bid w/ id=${response.uid}`)),
      catchError(this.handleError<SORespBid>('Bid post'))
    );
  }

  /** PUT: update Bid  */
  put(request: SOPutBid): Observable<SORespBid> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespBid>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBid, resp) }),
      tap((response: SORespBid) => this.log(`edited Bid w/ uid=${uid}`)),
      catchError(this.handleError<SORespBid>('Bid put'))
    );
  }

  /** DELETE: delete the Bid from the server */
  delete(request: SODeleteBid): Observable<SORespBid> {
    return this.httpClient.delete<SORespBid>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Bid uid=${request.uid}`)),
      catchError(this.handleError<SORespBid>('delete Bid'))
    );
  }
}
