import {SORespGrant} from '../../core/grant/so-resp-grant';
import {GrantCore} from './grant.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutGrantFormFields} from './put-grant-form-fields';
import { CEGrantAttributes } from './ce-grant-attributes'


export class CEGrantBase extends CEBase {

    public details: CEGrantAttributes;
    public fromFields: PutGrantFormFields = new PutGrantFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: GrantCore,
        public entity: SORespGrant,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEGrantAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.isDefault)
        this.attributes.push(this.details.userDisplayName)
        this.attributes.push(this.details.tokens)
        this.attributes.push(this.details.role)
        this.attributes.push(this.details.user)

    }
}