import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetDistributionday } from '../distributionday/so-get-distributionday';
import { SOGetContainersession } from '../containersession/so-get-containersession';
import {ENServiceType} from '../../enums';


export class SOPutServiceRequest {

  constructor(
    public uid: string,
    public dateCreated: Date = null,
    public dateRequested: Date = null,
    public serviceType: ENServiceType = null,
    public distribution: SOGetDistribution = null,
    public distributionday: SOGetDistributionday = null,
    public containersession: SOGetContainersession = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutServiceRequest(
      'uid' in params ? params.uid : null,
      'dateCreated' in params ? params.dateCreated : null,
      'dateRequested' in params ? params.dateRequested : null,
      'serviceType' in params ? params.serviceType : null,
      'distribution' in params ? params.distribution : null,
      'distributionday' in params ? params.distributionday : null,
      'containersession' in params ? params.containersession : null,
    );
  }
}
