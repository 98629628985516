import {SORespTankreport} from '../../core/tankreport/so-resp-tankreport';
import {TankreportCore} from './tankreport.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTankreportFormFields} from './put-tankreport-form-fields';
import {SOGetTankreport} from '../../core/tankreport/so-get-tankreport';
import {SOGetTank} from '../../core/tank/so-get-tank';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CETankreportAttributesBase {

    dateCreated: DetailEntry
    value: DetailEntry
    tank: DetailEntry
    distributionday: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTankreport,
        app: GasDirectApp,
        formFields: PutTankreportFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateCreated
        )

        this.value = new DetailEntry(
            'Value',
            entity.value,
            'value',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.value
        )


        const tankCore = app.makeCore( 'tank');
        tankCore.soGet = SOGetTank.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tankCore
        )
        tankCore.title = heading ? heading : tankCore.title
        tankCore.listTitle = 'Tank'

        tankCore.configureForDisplay(parentEntityViewSpec)

        this.tank = new DetailEntry(
          heading ? heading : tankCore.listTitle,
          null,
          'tank',
          BaseDetailType.pushedDetail,
          tankCore,
          EditableType.always,
          formFields.tank
        )
        this.tank.singleFieldSelect = true

        const distributiondayCore = app.makeCore( 'distributionday');
        distributiondayCore.soGet = SOGetDistributionday.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributiondayCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributiondayCore
        )
        distributiondayCore.title = heading ? heading : distributiondayCore.title
        distributiondayCore.listTitle = 'Distributionday'

        distributiondayCore.configureForDisplay(parentEntityViewSpec)

        this.distributionday = new DetailEntry(
          heading ? heading : distributiondayCore.listTitle,
          null,
          'distributionday',
          BaseDetailType.pushedDetail,
          distributiondayCore,
          EditableType.never
        )
        this.distributionday.singleFieldSelect = true
    }
}