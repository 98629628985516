import { Type } from 'class-transformer';
import { SORespOrder } from '../order/so-resp-order';
import { SORespLoad } from '../load/so-resp-load';
import { SORespUser } from '../user/so-resp-user';
import { SORespContainersession } from '../containersession/so-resp-containersession';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespActivity implements ObjectiveProtocol {

  entityType: string = 'activity';
  uid: string;
  fixmeUser: string;
  type: string;
  summary: string;
  body: string;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespOrder)
  order: SORespOrder = null;
  @Type(() => SORespLoad)
  load: SORespLoad = null;
  @Type(() => SORespUser)
  user: SORespUser = null;
  @Type(() => SORespContainersession)
  containersession: SORespContainersession = null;
}