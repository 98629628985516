import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostFeescheduleFormFields {

    public displayName: FormField
    public distribution: FormField
    public start: FormField
    public end: FormField

    constructor() {

        this.displayName = new FormField(
          'displayName',
          'Display name',
          null,
          FormFieldType.text,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          true,
        )

    }
}

const fields = new PostFeescheduleFormFields()

export const postFeescheduleFormFieldOptions: {[key: string]: FormField} = {
  displayName: fields.displayName,
  start: fields.start,
  end: fields.end,
};