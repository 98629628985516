import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOOrganizationService} from '../../core/organization/eo-organization';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {RAPutOrganization} from './ra-put-organization';
import {RAPostOrganization} from './ra-post-organization';
import {RADeleteOrganization} from './ra-delete-organization';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEOrganization } from "./ce-organization"


export class OrganizationCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'organization';
  title = 'Organization';
  ceProtocol: CEConstructor = CEOrganization

  entityService: EOOrganizationService;
  soGetClass = SOGetOrganization;
  soGet: SOGetOrganization;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOOrganizationService
    );
    this.adapters = [
      new RAPostOrganization(this),
      new RAPutOrganization(this),
      new RADeleteOrganization(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetOrganization();
    this.soGet.queryOptions.limit = this.limit;
  }


}