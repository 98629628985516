import { SOGetAgreement } from '../agreement/so-get-agreement';
import { SOGetFeeschedule } from '../feeschedule/so-get-feeschedule';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetFeescheduleagreementasc implements QueryProtocol {

  constructor(
    public uid: string = null,
    public agreement: SOGetAgreement = null,
    public feeschedule: SOGetFeeschedule = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetFeescheduleagreementasc(
      'uid' in params ? params.uid : null,
      'agreement' in params ? params.agreement : null,
      'feeschedule' in params ? params.feeschedule : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
