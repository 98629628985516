import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTemprice } from './so-put-temprice';
import { SOGetTemprice } from './so-get-temprice';
import { SOPostTemprice } from './so-post-temprice';
import { SODeleteTemprice } from './so-delete-temprice';
import {SORespTemprice} from './so-resp-temprice';
import {SOPluralTemprice} from './so-plural-temprice';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTempriceService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'temprice',
      entityCore
    );
  }

  /** GET Temprice by id. Will 404 if id not found */
  get(request: SOGetTemprice): Observable<SOPluralTemprice> {
    return this.httpClient.get<SOPluralTemprice>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTemprice, resp) }),
      tap(_ => this.log(`fetched Temprice`)),
      catchError(this.handleError<SOPluralTemprice>(`get Temprice`))
    );
  }

  /** POST: add a new Temprice to the server */
  post(request: SOPostTemprice): Observable<SORespTemprice> {
    return this.httpClient.post<SORespTemprice>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemprice, resp) }),
      tap((response: SORespTemprice) => this.log(`added Temprice w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTemprice>('Temprice post'))
    );
  }

  /** PUT: update Temprice  */
  put(request: SOPutTemprice): Observable<SORespTemprice> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTemprice>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemprice, resp) }),
      tap((response: SORespTemprice) => this.log(`edited Temprice w/ uid=${uid}`)),
      catchError(this.handleError<SORespTemprice>('Temprice put'))
    );
  }

  /** DELETE: delete the Temprice from the server */
  delete(request: SODeleteTemprice): Observable<SORespTemprice> {
    return this.httpClient.delete<SORespTemprice>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Temprice uid=${request.uid}`)),
      catchError(this.handleError<SORespTemprice>('delete Temprice'))
    );
  }
}
