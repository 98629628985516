import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENLoadStatus} from '../../enums';

export class PutLoadFormFields {

    public status: FormField
    public action: FormField
    public weightRequested: FormField
    public destination: FormField
    public carrierSignature: FormField
    public providerSignature: FormField
    public quantity: FormField
    public net: FormField
    public netDcs: FormField
    public lngNetTargetSetpoint: FormField
    public lngNetTargetSetpointManual: FormField
    public arrived: FormField
    public departed: FormField
    public loadingBegin: FormField
    public loadingEnd: FormField
    public loadCode: FormField
    public location: FormField
    public specialRequests: FormField
    public order: FormField
    public trailer: FormField
    public carrier: FormField
    public offering: FormField
    public driverName: FormField
    public operatorName: FormField
    public eta: FormField
    public requestedFillDate: FormField
    public coolDownDays: FormField
    public rearAxelWeight: FormField
    public droppedLin: FormField
    public finalAnalysis: FormField
    public waterAnalysis: FormField
    public prepVol: FormField
    public prepTrailer: FormField
    public nitrogenDetected: FormField
    public weight0: FormField
    public weight0Manual: FormField
    public weight1: FormField
    public weight1Manual: FormField
    public weight2: FormField
    public weight2Manual: FormField
    public pressure0: FormField
    public pressure1: FormField
    public process: FormField
    public chassisId: FormField
    public dotInspectionDate: FormField
    public dotSp: FormField
    public dotE: FormField
    public setOrderAccountUid: FormField
    public attachment: FormField
    public agreemonth: FormField
    public agreement: FormField
    public shield: FormField
    public loadSheetId: FormField
    public holdRelease: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.status.enumeration = ENLoadStatus
        this.action = new FormField(
          'action',
          'Action',
          null,
          FormFieldType.text,
          false,
        )
        this.weightRequested = new FormField(
          'weightRequested',
          'Weight requested',
          null,
          FormFieldType.text,
          false,
        )
        this.destination = new FormField(
          'destination',
          'Destination',
          null,
          FormFieldType.text,
          false,
        )
        this.carrierSignature = new FormField(
          'carrierSignature',
          'Carrier signature',
          null,
          FormFieldType.text,
          false,
        )
        this.providerSignature = new FormField(
          'providerSignature',
          'Provider signature',
          null,
          FormFieldType.text,
          false,
        )
        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          false,
        )
        this.net = new FormField(
          'net',
          'Net',
          null,
          FormFieldType.text,
          false,
        )
        this.netDcs = new FormField(
          'netDcs',
          'Net dcs',
          null,
          FormFieldType.text,
          false,
        )
        this.lngNetTargetSetpoint = new FormField(
          'lngNetTargetSetpoint',
          'Lng net target setpoint',
          null,
          FormFieldType.text,
          false,
        )
        this.lngNetTargetSetpointManual = new FormField(
          'lngNetTargetSetpointManual',
          'Lng net target setpoint manual',
          null,
          FormFieldType.text,
          false,
        )
        this.arrived = new FormField(
          'arrived',
          'Arrived',
          null,
          FormFieldType.datetime,
          false,
        )
        this.departed = new FormField(
          'departed',
          'Departed',
          null,
          FormFieldType.datetime,
          false,
        )
        this.loadingBegin = new FormField(
          'loadingBegin',
          'Loading begin',
          null,
          FormFieldType.datetime,
          false,
        )
        this.loadingEnd = new FormField(
          'loadingEnd',
          'Loading end',
          null,
          FormFieldType.datetime,
          false,
        )
        this.loadCode = new FormField(
          'loadCode',
          'Load code',
          null,
          FormFieldType.text,
          false,
        )
        this.location = new FormField(
          'location',
          'Location',
          null,
          FormFieldType.text,
          false,
        )
        this.specialRequests = new FormField(
          'specialRequests',
          'Special requests',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.order = new FormField(
          'order',
          'Order',
          'order',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.trailer = new FormField(
          'trailer',
          'Trailer',
          'trailer',
          FormFieldType.autoCompleteList,
          false,
        )
        this.carrier = new FormField(
          'carrier',
          'Carrier',
          'organization',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.offering = new FormField(
          'offering',
          'Offering',
          'offering',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.driverName = new FormField(
          'driverName',
          'Driver name',
          null,
          FormFieldType.text,
          false,
        )
        this.operatorName = new FormField(
          'operatorName',
          'Operator name',
          null,
          FormFieldType.text,
          false,
        )
        this.eta = new FormField(
          'eta',
          'Eta',
          null,
          FormFieldType.datetime,
          false,
        )
        this.requestedFillDate = new FormField(
          'requestedFillDate',
          'Requested fill date',
          null,
          FormFieldType.datetime,
          false,
        )
        this.coolDownDays = new FormField(
          'coolDownDays',
          'Cool down days',
          null,
          FormFieldType.text,
          false,
        )
        this.rearAxelWeight = new FormField(
          'rearAxelWeight',
          'Rear axel weight',
          null,
          FormFieldType.text,
          false,
        )
        this.droppedLin = new FormField(
          'droppedLin',
          'Dropped lin',
          null,
          FormFieldType.text,
          false,
        )
        this.finalAnalysis = new FormField(
          'finalAnalysis',
          'Final analysis',
          null,
          FormFieldType.text,
          false,
        )
        this.waterAnalysis = new FormField(
          'waterAnalysis',
          'Water analysis',
          null,
          FormFieldType.text,
          false,
        )
        this.prepVol = new FormField(
          'prepVol',
          'Prep vol',
          null,
          FormFieldType.text,
          false,
        )
        this.prepTrailer = new FormField(
          'prepTrailer',
          'Prep trailer',
          null,
          FormFieldType.text,
          false,
        )
        this.nitrogenDetected = new FormField(
          'nitrogenDetected',
          'Nitrogen detected',
          null,
          FormFieldType.boolean,
          false,
        )
        this.weight0 = new FormField(
          'weight0',
          'Weight0',
          null,
          FormFieldType.text,
          false,
        )
        this.weight0Manual = new FormField(
          'weight0Manual',
          'Weight0 manual',
          null,
          FormFieldType.text,
          false,
        )
        this.weight1 = new FormField(
          'weight1',
          'Weight1',
          null,
          FormFieldType.text,
          false,
        )
        this.weight1Manual = new FormField(
          'weight1Manual',
          'Weight1 manual',
          null,
          FormFieldType.text,
          false,
        )
        this.weight2 = new FormField(
          'weight2',
          'Weight2',
          null,
          FormFieldType.text,
          false,
        )
        this.weight2Manual = new FormField(
          'weight2Manual',
          'Weight2 manual',
          null,
          FormFieldType.text,
          false,
        )
        this.pressure0 = new FormField(
          'pressure0',
          'Pressure0',
          null,
          FormFieldType.text,
          false,
        )
        this.pressure1 = new FormField(
          'pressure1',
          'Pressure1',
          null,
          FormFieldType.text,
          false,
        )
        this.process = new FormField(
          'process',
          'Process',
          null,
          FormFieldType.text,
          false,
        )
        this.chassisId = new FormField(
          'chassisId',
          'Chassis id',
          null,
          FormFieldType.text,
          false,
        )
        this.dotInspectionDate = new FormField(
          'dotInspectionDate',
          'Dot inspection date',
          null,
          FormFieldType.text,
          false,
        )
        this.dotSp = new FormField(
          'dotSp',
          'Dot sp',
          null,
          FormFieldType.text,
          false,
        )
        this.dotE = new FormField(
          'dotE',
          'Dot e',
          null,
          FormFieldType.text,
          false,
        )
        this.setOrderAccountUid = new FormField(
          'setOrderAccountUid',
          'Set order account uid',
          null,
          FormFieldType.text,
          false,
        )
        this.attachment = new FormField(
          'attachment',
          'Attachment',
          null,
          FormFieldType.text,
          false,
        )
        this.agreemonth = new FormField(
          'agreemonth',
          'Agreemonth',
          'agreemonth',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.agreement = new FormField(
          'agreement',
          'Agreement',
          'agreement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.shield = new FormField(
          'shield',
          'Shield',
          null,
          FormFieldType.boolean,
          false,
        )
        this.loadSheetId = new FormField(
          'loadSheetId',
          'Load sheet id',
          null,
          FormFieldType.text,
          false,
        )
        this.holdRelease = new FormField(
          'holdRelease',
          'Hold release',
          null,
          FormFieldType.boolean,
          false,
        )

    }
}

const fields = new PutLoadFormFields()

export const putLoadFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
  action: fields.action,
  weightRequested: fields.weightRequested,
  destination: fields.destination,
  carrierSignature: fields.carrierSignature,
  providerSignature: fields.providerSignature,
  quantity: fields.quantity,
  net: fields.net,
  netDcs: fields.netDcs,
  lngNetTargetSetpoint: fields.lngNetTargetSetpoint,
  lngNetTargetSetpointManual: fields.lngNetTargetSetpointManual,
  arrived: fields.arrived,
  departed: fields.departed,
  loadingBegin: fields.loadingBegin,
  loadingEnd: fields.loadingEnd,
  loadCode: fields.loadCode,
  location: fields.location,
  specialRequests: fields.specialRequests,
  order: fields.order,
  trailer: fields.trailer,
  carrier: fields.carrier,
  offering: fields.offering,
  driverName: fields.driverName,
  operatorName: fields.operatorName,
  eta: fields.eta,
  requestedFillDate: fields.requestedFillDate,
  coolDownDays: fields.coolDownDays,
  rearAxelWeight: fields.rearAxelWeight,
  droppedLin: fields.droppedLin,
  finalAnalysis: fields.finalAnalysis,
  waterAnalysis: fields.waterAnalysis,
  prepVol: fields.prepVol,
  prepTrailer: fields.prepTrailer,
  nitrogenDetected: fields.nitrogenDetected,
  weight0: fields.weight0,
  weight0Manual: fields.weight0Manual,
  weight1: fields.weight1,
  weight1Manual: fields.weight1Manual,
  weight2: fields.weight2,
  weight2Manual: fields.weight2Manual,
  pressure0: fields.pressure0,
  pressure1: fields.pressure1,
  process: fields.process,
  chassisId: fields.chassisId,
  dotInspectionDate: fields.dotInspectionDate,
  dotSp: fields.dotSp,
  dotE: fields.dotE,
  setOrderAccountUid: fields.setOrderAccountUid,
  attachment: fields.attachment,
  agreemonth: fields.agreemonth,
  agreement: fields.agreement,
  shield: fields.shield,
  loadSheetId: fields.loadSheetId,
  holdRelease: fields.holdRelease,
};