import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostIndexpriceFormFields {

    public start: FormField
    public end: FormField
    public state: FormField
    public rate: FormField
    public distribution: FormField
    public externalindexprice: FormField

    constructor() {

        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          true,
        )
        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          true,
        )
        this.rate = new FormField(
          'rate',
          'Rate',
          null,
          FormFieldType.text,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.externalindexprice = new FormField(
          'externalindexprice',
          'Externalindexprice',
          'externalindexprice',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostIndexpriceFormFields()

export const postIndexpriceFormFieldOptions: {[key: string]: FormField} = {
  start: fields.start,
  end: fields.end,
  state: fields.state,
  rate: fields.rate,
  externalindexprice: fields.externalindexprice,
};