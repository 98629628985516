import { Type } from 'class-transformer';
import { SORespContainersession } from '../containersession/so-resp-containersession';
import { SORespUser } from '../user/so-resp-user';
import { SORespPublication } from '../publication/so-resp-publication';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespSubscription implements ObjectiveProtocol {

  entityType: string = 'subscription';
  uid: string;
  feed: boolean = true;
  webAlerts: boolean = true;
  mobileAlerts: boolean = true;
  emailAlerts: boolean = false;
  @Type(() => SORespContainersession)
  containersession: SORespContainersession = null;
  @Type(() => SORespUser)
  user: SORespUser;
  @Type(() => SORespPublication)
  publication: SORespPublication;
}