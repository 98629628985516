import {SORespHello} from '../../core/hello/so-resp-hello';
import {HelloCore} from './hello.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHelloFormFields} from './put-hello-form-fields';
import { CEHelloAttributes } from './ce-hello-attributes'


export class CEHelloBase extends CEBase {

    public details: CEHelloAttributes;
    public fromFields: PutHelloFormFields = new PutHelloFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: HelloCore,
        public entity: SORespHello,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEHelloAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.message)
        this.attributes.push(this.details.loginStyle)

    }
}