import {SOPostAgreement} from '../../core/agreement/so-post-agreement';
import {AgreementCoreBase} from './agreement.core.base';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {FormField, SelectionOption} from 'lionheartland';
import {postAgreementFormFieldOptions} from './post-agreement-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TempagreementCore} from '../tempagreement/tempagreement.core';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {FeescheduleCore} from '../feeschedule/feeschedule.core';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {AwardCore} from '../award/award.core';
import {SOGetAward} from '../../core/award/so-get-award';

export class RAPostAgreement extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAgreementFormFieldOptions);

  RequestClass = SOPostAgreement

  constructor(
    public entityCore: AgreementCoreBase
  ) {
    super();
    if (postAgreementFormFieldOptions.tempagreement) {
      postAgreementFormFieldOptions.tempagreement.makeCore = true;
    }
    if (postAgreementFormFieldOptions.feeschedule) {
      postAgreementFormFieldOptions.feeschedule.makeCore = true;
    }
    if (postAgreementFormFieldOptions.award) {
      postAgreementFormFieldOptions.award.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('units'),
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('docusignLink'),
      this.getFormValueFromIdentifier('autoRenew'),
      this.getFormValueFromIdentifier(
        'tempagreement',
        SOGetTempagreement,
      ),
      this.getFormValueFromIdentifier('acq'),
      this.getFormValueFromIdentifier('top'),
      this.getFormValueFromIdentifier(
        'feeschedule',
        SOGetFeeschedule,
      ),
      this.getFormValueFromIdentifier('variableAcq'),
      this.getFormValueFromIdentifier('rateable'),
      this.getFormValueFromIdentifier('interruptible'),
      this.getFormValueFromIdentifier('spotQuantity'),
      this.getFormValueFromIdentifier(
        'award',
        SOGetAward,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}