import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDeltaweight } from './so-put-deltaweight';
import { SOGetDeltaweight } from './so-get-deltaweight';
import { SOPostDeltaweight } from './so-post-deltaweight';
import { SODeleteDeltaweight } from './so-delete-deltaweight';
import {SORespDeltaweight} from './so-resp-deltaweight';
import {SOPluralDeltaweight} from './so-plural-deltaweight';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODeltaweightService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'deltaweight',
      entityCore
    );
  }

  /** GET Deltaweight by id. Will 404 if id not found */
  get(request: SOGetDeltaweight): Observable<SOPluralDeltaweight> {
    return this.httpClient.get<SOPluralDeltaweight>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDeltaweight, resp) }),
      tap(_ => this.log(`fetched Deltaweight`)),
      catchError(this.handleError<SOPluralDeltaweight>(`get Deltaweight`))
    );
  }

  /** POST: add a new Deltaweight to the server */
  post(request: SOPostDeltaweight): Observable<SORespDeltaweight> {
    return this.httpClient.post<SORespDeltaweight>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDeltaweight, resp) }),
      tap((response: SORespDeltaweight) => this.log(`added Deltaweight w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDeltaweight>('Deltaweight post'))
    );
  }

  /** PUT: update Deltaweight  */
  put(request: SOPutDeltaweight): Observable<SORespDeltaweight> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDeltaweight>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDeltaweight, resp) }),
      tap((response: SORespDeltaweight) => this.log(`edited Deltaweight w/ uid=${uid}`)),
      catchError(this.handleError<SORespDeltaweight>('Deltaweight put'))
    );
  }

  /** DELETE: delete the Deltaweight from the server */
  delete(request: SODeleteDeltaweight): Observable<SORespDeltaweight> {
    return this.httpClient.delete<SORespDeltaweight>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Deltaweight uid=${request.uid}`)),
      catchError(this.handleError<SORespDeltaweight>('delete Deltaweight'))
    );
  }
}
