import {SOPostBulk} from '../../core/bulk/so-post-bulk';
import {BulkCoreBase} from './bulk.core.base';
import {SOGetBulk} from '../../core/bulk/so-get-bulk';
import {FormField, SelectionOption} from 'lionheartland';
import {postBulkFormFieldOptions} from './post-bulk-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostBulk extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postBulkFormFieldOptions);

  RequestClass = SOPostBulk

  constructor(
    public entityCore: BulkCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('body'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}