import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOHestatusService} from '../../core/hestatus/eo-hestatus';
import {SOGetHestatus} from '../../core/hestatus/so-get-hestatus';
import {RAPutHestatus} from './ra-put-hestatus';
import {RAPostHestatus} from './ra-post-hestatus';
import {RADeleteHestatus} from './ra-delete-hestatus';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEHestatus } from "./ce-hestatus"


export class HestatusCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'hestatus';
  title = 'Hestatus';
  ceProtocol: CEConstructor = CEHestatus

  entityService: EOHestatusService;
  soGetClass = SOGetHestatus;
  soGet: SOGetHestatus;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOHestatusService
    );
    this.adapters = [
      new RAPostHestatus(this),
      new RAPutHestatus(this),
      new RADeleteHestatus(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetHestatus();
    this.soGet.queryOptions.limit = this.limit;
  }


}