import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostOrgrolaFormFields {

    public role: FormField
    public isOwner: FormField
    public organization: FormField

    constructor() {

        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.isOwner = new FormField(
          'isOwner',
          'Is owner',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostOrgrolaFormFields()

export const postOrgrolaFormFieldOptions: {[key: string]: FormField} = {
  role: fields.role,
  isOwner: fields.isOwner,
  organization: fields.organization,
};