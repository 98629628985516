import { SOGetOrder } from '../order/so-get-order';
import { SOGetLoad } from '../load/so-get-load';
import { SOGetUser } from '../user/so-get-user';
import { SOGetContainersession } from '../containersession/so-get-containersession';
import { SOGetSlot } from '../slot/so-get-slot';


export class SOPostActivity {

  constructor(
    public type: string,
    public summary: string,
    public body: string,
    public order: SOGetOrder = null,
    public load: SOGetLoad = null,
    public user: SOGetUser = null,
    public containersession: SOGetContainersession = null,
    public slot: SOGetSlot = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostActivity(
      'type' in params ? params.type : null,
      'summary' in params ? params.summary : null,
      'body' in params ? params.body : null,
      'order' in params ? params.order : null,
      'load' in params ? params.load : null,
      'user' in params ? params.user : null,
      'containersession' in params ? params.containersession : null,
      'slot' in params ? params.slot : null,
    );
  }
}
