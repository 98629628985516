import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTrailermergeFormFields {


    constructor() {


    }
}

const fields = new PutTrailermergeFormFields()

export const putTrailermergeFormFieldOptions: {[key: string]: FormField} = {
};