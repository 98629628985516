import {SOPutTemptract} from '../../core/temptract/so-put-temptract';
import {TemptractCoreBase} from './temptract.core.base';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';
import {FormField, SelectionOption} from 'lionheartland';
import {putTemptractFormFieldOptions} from './put-temptract-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutTemptract extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTemptractFormFieldOptions);

  RequestClass = SOPutTemptract

  constructor(
    public entityCore: TemptractCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('durationDays'),
      this.getFormValueFromIdentifier('takeOrPay'),
      this.getFormValueFromIdentifier('minLoadSizeToQualify'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('docusignLink'),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}