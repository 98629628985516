import { Type } from 'class-transformer';
import { SORespClin } from '../clin/so-resp-clin';
import { SORespAgreeyear } from '../agreeyear/so-resp-agreeyear';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespPrice implements ObjectiveProtocol {

  entityType: string = 'price';
  uid: string;
  @Type(() => Date)
  startDate: Date;
  @Type(() => Date)
  endDate: Date;
  rate: number;
  quantity: number = 0;
  quantityUsed: number = 0;
  loadQuantity: number = 0;
  loadQuantityUsed: number = 0;
  rank: number;
  @Type(() => SORespClin)
  clin: SORespClin = null;
  @Type(() => SORespAgreeyear)
  agreeyear: SORespAgreeyear = null;
}