import {SORespInvoice} from '../../core/invoice/so-resp-invoice';
import {InvoiceCore} from './invoice.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutInvoiceFormFields} from './put-invoice-form-fields';
import { CEInvoiceAttributes } from './ce-invoice-attributes'


export class CEInvoiceBase extends CEBase {

    public details: CEInvoiceAttributes;
    public fromFields: PutInvoiceFormFields = new PutInvoiceFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: InvoiceCore,
        public entity: SORespInvoice,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEInvoiceAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.ref)
        this.attributes.push(this.details.total)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.invoiceDate)
        this.attributes.push(this.details.sentDate)
        this.attributes.push(this.details.paymentTerms)
        this.attributes.push(this.details.productionDate)
        this.attributes.push(this.details.contractNumbers)
        this.attributes.push(this.details.remittorNumber)
        this.attributes.push(this.details.additionalText)
        this.attributes.push(this.details.sent)
        this.attributes.push(this.details.paymentStatus)
        this.attributes.push(this.details.stripeRef)
        this.attributes.push(this.details.stripeStatus)
        this.attributes.push(this.details.customerPocText)
        this.attributes.push(this.details.xomPaymentInstructions)
        this.attributes.push(this.details.xomPocText)
        this.attributes.push(this.details.balanceInitial)
        this.attributes.push(this.details.balanceFinal)
        this.attributes.push(this.details.paymentsReceivedSinceLastStatement)
        this.attributes.push(this.details.transactions)
        this.attributes.push(this.details.loadfees)
        this.attributes.push(this.details.deltas)
        this.attributes.push(this.details.distributionmonth)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.invoiceitems)

    }
}