import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutFaq } from './so-put-faq';
import { SOGetFaq } from './so-get-faq';
import { SOPostFaq } from './so-post-faq';
import { SODeleteFaq } from './so-delete-faq';
import {SORespFaq} from './so-resp-faq';
import {SOPluralFaq} from './so-plural-faq';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOFaqService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'faq',
      entityCore
    );
  }

  /** GET Faq by id. Will 404 if id not found */
  get(request: SOGetFaq): Observable<SOPluralFaq> {
    return this.httpClient.get<SOPluralFaq>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralFaq, resp) }),
      tap(_ => this.log(`fetched Faq`)),
      catchError(this.handleError<SOPluralFaq>(`get Faq`))
    );
  }

  /** POST: add a new Faq to the server */
  post(request: SOPostFaq): Observable<SORespFaq> {
    return this.httpClient.post<SORespFaq>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFaq, resp) }),
      tap((response: SORespFaq) => this.log(`added Faq w/ id=${response.uid}`)),
      catchError(this.handleError<SORespFaq>('Faq post'))
    );
  }

  /** PUT: update Faq  */
  put(request: SOPutFaq): Observable<SORespFaq> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespFaq>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFaq, resp) }),
      tap((response: SORespFaq) => this.log(`edited Faq w/ uid=${uid}`)),
      catchError(this.handleError<SORespFaq>('Faq put'))
    );
  }

  /** DELETE: delete the Faq from the server */
  delete(request: SODeleteFaq): Observable<SORespFaq> {
    return this.httpClient.delete<SORespFaq>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Faq uid=${request.uid}`)),
      catchError(this.handleError<SORespFaq>('delete Faq'))
    );
  }
}
