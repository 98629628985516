import { Type } from 'class-transformer';
import { SORespBid } from '../bid/so-resp-bid';
import { SORespAgreement } from '../agreement/so-resp-agreement';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespAward implements ObjectiveProtocol {

  entityType: string = 'award';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  amount: number;
  quantity: number;
  loadQuantity: number;
  notes: string;
  @Type(() => SORespBid)
  bid: SORespBid;
  @Type(() => SORespAgreement)
  agreement: SORespAgreement;
}