import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOCarrierauthService} from '../../core/carrierauth/eo-carrierauth';
import {SOGetCarrierauth} from '../../core/carrierauth/so-get-carrierauth';
import {RAPutCarrierauth} from './ra-put-carrierauth';
import {RAPostCarrierauth} from './ra-post-carrierauth';
import {RADeleteCarrierauth} from './ra-delete-carrierauth';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CECarrierauth } from "./ce-carrierauth"


export class CarrierauthCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'carrierauth';
  title = 'Carrierauth';
  ceProtocol: CEConstructor = CECarrierauth

  entityService: EOCarrierauthService;
  soGetClass = SOGetCarrierauth;
  soGet: SOGetCarrierauth;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOCarrierauthService
    );
    this.adapters = [
      new RAPostCarrierauth(this),
      new RAPutCarrierauth(this),
      new RADeleteCarrierauth(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCarrierauth();
    this.soGet.queryOptions.limit = this.limit;
  }


}