import {SORespOffering} from '../../core/offering/so-resp-offering';
import {OfferingCore} from './offering.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutOfferingFormFields} from './put-offering-form-fields';
import { CEOfferingAttributes } from './ce-offering-attributes'


export class CEOfferingBase extends CEBase {

    public details: CEOfferingAttributes;
    public fromFields: PutOfferingFormFields = new PutOfferingFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: OfferingCore,
        public entity: SORespOffering,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEOfferingAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.units)
        this.attributes.push(this.details.size)
        this.attributes.push(this.details.distoffers)
        this.attributes.push(this.details.items)
        this.attributes.push(this.details.loads)

    }
}