import {SORespBid} from '../../core/bid/so-resp-bid';
import {BidCore} from './bid.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutBidFormFields} from './put-bid-form-fields';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetSale} from '../../core/sale/so-get-sale';
import {SOGetAward} from '../../core/award/so-get-award';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEBidAttributesBase {

    prices: DetailEntry
    displayName: DetailEntry
    description: DetailEntry
    amount: DetailEntry
    tempclin: DetailEntry
    status: DetailEntry
    account: DetailEntry
    amountTwo: DetailEntry
    amountThree: DetailEntry
    sale: DetailEntry
    awards: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespBid,
        app: GasDirectApp,
        formFields: PutBidFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.displayName = new DetailEntry(
            'Display name',
            entity.displayName,
            'displayName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.displayName
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.amount = new DetailEntry(
            'Amount',
            entity.amount,
            'amount',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amount
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.status
        )

        this.amountTwo = new DetailEntry(
            'Amount two',
            entity.amountTwo,
            'amountTwo',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amountTwo
        )

        this.amountThree = new DetailEntry(
            'Amount three',
            entity.amountThree,
            'amountThree',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amountThree
        )


        const pricesCore = app.makeCore( 'price');
        pricesCore.soGet = SOGetPrice.construct({
          bid: new SOGetBid(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            pricesCore
        )
        pricesCore.title = heading ? heading : pricesCore.title
        pricesCore.listTitle = 'Prices'

        pricesCore.configureForDisplay(parentEntityViewSpec)

        this.prices = new DetailEntry(
          heading ? heading : pricesCore.listTitle,
          null,
          'prices',
          BaseDetailType.embeddedList,
          pricesCore,
          EditableType.never
        )

        const tempclinCore = app.makeCore( 'tempclin');
        tempclinCore.soGet = SOGetTempclin.construct({
        });
        let tempclinCeProtocol;
        if (entity.tempclin) {
            tempclinCeProtocol = createCEProtocol(
                tempclinCore.ceProtocol,
                parentEntityViewSpec,
                tempclinCore,
                entity.tempclin,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempclinCore
        )
        tempclinCore.title = heading ? heading : tempclinCore.title
        tempclinCore.listTitle = 'Tempclin'

        if (entity.tempclin) {
            displayStringFactory.reconfigure(
                entity.tempclin,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        tempclinCore.configureForDisplay(parentEntityViewSpec)

        this.tempclin = new DetailEntry(
          heading ? heading : tempclinCore.listTitle,
          tempclinCeProtocol ? tempclinCeProtocol.entityListView.fullHeading : null,
          'tempclin',
          BaseDetailType.pushedDetail,
          tempclinCore,
          EditableType.never
        )
        if (entity.tempclin) {
          this.tempclin.ceProtocol = tempclinCeProtocol;
          this.tempclin.cachedListEntry = tempclinCore.makeListEntry(parentEntityViewSpec, entity.tempclin, 0)
          tempclinCore.selectedEntity = entity.tempclin;
        }
        this.tempclin.singleFieldSelect = true

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.never
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const saleCore = app.makeCore( 'sale');
        saleCore.soGet = SOGetSale.construct({
        });
        let saleCeProtocol;
        if (entity.sale) {
            saleCeProtocol = createCEProtocol(
                saleCore.ceProtocol,
                parentEntityViewSpec,
                saleCore,
                entity.sale,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            saleCore
        )
        saleCore.title = heading ? heading : saleCore.title
        saleCore.listTitle = 'Sale'

        if (entity.sale) {
            displayStringFactory.reconfigure(
                entity.sale,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        saleCore.configureForDisplay(parentEntityViewSpec)

        this.sale = new DetailEntry(
          heading ? heading : saleCore.listTitle,
          saleCeProtocol ? saleCeProtocol.entityListView.fullHeading : null,
          'sale',
          BaseDetailType.pushedDetail,
          saleCore,
          EditableType.never
        )
        if (entity.sale) {
          this.sale.ceProtocol = saleCeProtocol;
          this.sale.cachedListEntry = saleCore.makeListEntry(parentEntityViewSpec, entity.sale, 0)
          saleCore.selectedEntity = entity.sale;
        }
        this.sale.singleFieldSelect = true

        const awardsCore = app.makeCore( 'award');
        awardsCore.soGet = SOGetAward.construct({
          bid: new SOGetBid(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            awardsCore
        )
        awardsCore.title = heading ? heading : awardsCore.title
        awardsCore.listTitle = 'Awards'

        awardsCore.configureForDisplay(parentEntityViewSpec)

        this.awards = new DetailEntry(
          heading ? heading : awardsCore.listTitle,
          null,
          'awards',
          BaseDetailType.embeddedList,
          awardsCore,
          EditableType.never
        )
    }
}