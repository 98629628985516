import {SORespInreport} from '../../core/inreport/so-resp-inreport';
import {InreportCore} from './inreport.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutInreportFormFields} from './put-inreport-form-fields';
import { CEInreportAttributes } from './ce-inreport-attributes'


export class CEInreportBase extends CEBase {

    public details: CEInreportAttributes;
    public fromFields: PutInreportFormFields = new PutInreportFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: InreportCore,
        public entity: SORespInreport,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEInreportAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.leftoverPrior)
        this.attributes.push(this.details.prefillPrior)
        this.attributes.push(this.details.leftoverNext)
        this.attributes.push(this.details.prefillNext)
        this.attributes.push(this.details.accounreport)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.distributionmonth)

    }
}