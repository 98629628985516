

export class SOPutShutelng {

  constructor(
    public uid: string,
    public data: any = null,
    public dateCreated: Date = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutShutelng(
      'uid' in params ? params.uid : null,
      'data' in params ? params.data : null,
      'dateCreated' in params ? params.dateCreated : null,
    );
  }
}
