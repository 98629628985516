import {SORespAgreement} from '../../core/agreement/so-resp-agreement';
import {AgreementCore} from './agreement.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAgreementFormFields} from './put-agreement-form-fields';
import { CEAgreementAttributes } from './ce-agreement-attributes'


export class CEAgreementBase extends CEBase {

    public details: CEAgreementAttributes;
    public fromFields: PutAgreementFormFields = new PutAgreementFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: AgreementCore,
        public entity: SORespAgreement,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEAgreementAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.docusignLink)
        this.attributes.push(this.details.units)
        this.attributes.push(this.details.signedDocumentUrl)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.autoRenew)
        this.attributes.push(this.details.acq)
        this.attributes.push(this.details.top)
        this.attributes.push(this.details.variableAcq)
        this.attributes.push(this.details.rateable)
        this.attributes.push(this.details.interruptible)
        this.attributes.push(this.details.spotQuantity)
        this.attributes.push(this.details.prePaymentRequired)
        this.attributes.push(this.details.sapContractNumber)
        this.attributes.push(this.details.orders)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.tempagreement)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.feeschedule)
        this.attributes.push(this.details.agreeyears)
        this.attributes.push(this.details.agreemonths)
        this.attributes.push(this.details.contracts)
        this.attributes.push(this.details.feescheduleagreementascs)
        this.attributes.push(this.details.award)

    }
}