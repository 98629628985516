import {Component, Input, OnInit} from '@angular/core';
import {AvStatusComponent} from '../av-status/av-status.component';
import {ListEntry} from "lionheartland";


export class LoadRow {
  constructor(
    public state: string,
    public title: string,
    public color = 'lightgrey',
    public listEntries: ListEntry[] = []
  ) {
  }
}


@Component({
  selector: 'app-dv-load-row',
  templateUrl: './dv-load-row.component.html',
  styleUrls: ['./dv-load-row.component.css']
})
export class DvLoadRowComponent implements OnInit {

  @Input()
  loadRow: LoadRow;

  @Input()
  parent: AvStatusComponent;

  @Input()
  color = 'lightgrey';

  showCollection = true;

  get rowVisabilityButtonText() {
    if (this.showCollection) {
      return 'Hide';
    } else {
      return 'Show';
    }
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleRowVisability() {
    this.showCollection = !this.showCollection;
  }
}
