import {SOPutReservation} from '../../core/reservation/so-put-reservation';
import {ReservationCoreBase} from './reservation.core.base';
import {SOGetReservation} from '../../core/reservation/so-get-reservation';
import {FormField, SelectionOption} from 'lionheartland';
import {putReservationFormFieldOptions} from './put-reservation-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {SlotCore} from '../slot/slot.core';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';

export class RAPutReservation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putReservationFormFieldOptions);

  RequestClass = SOPutReservation

  constructor(
    public entityCore: ReservationCoreBase
  ) {
    super();
    if (putReservationFormFieldOptions.slot) {
      putReservationFormFieldOptions.slot.makeCore = true;
    }
    if (putReservationFormFieldOptions.load) {
      putReservationFormFieldOptions.load.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'slot',
        SOGetSlot,
      ),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}