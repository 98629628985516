import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutDowntimeFormFields {

    public description: FormField
    public start: FormField
    public end: FormField

    constructor() {

        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          false,
        )

    }
}

const fields = new PutDowntimeFormFields()

export const putDowntimeFormFieldOptions: {[key: string]: FormField} = {
  description: fields.description,
  start: fields.start,
  end: fields.end,
};