import {SORespSale} from '../../core/sale/so-resp-sale';
import {SaleCore} from './sale.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSaleFormFields} from './put-sale-form-fields';
import {SOGetSale} from '../../core/sale/so-get-sale';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {SOGetSaleaccountasc} from '../../core/saleaccountasc/so-get-saleaccountasc';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CESaleAttributesBase {

    bids: DetailEntry
    saleaccountascs: DetailEntry
    dateCreated: DetailEntry
    status: DetailEntry
    description: DetailEntry
    distribution: DetailEntry
    distributionmonth: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSale,
        app: GasDirectApp,
        formFields: PutSaleFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.status
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )
        this.description.listHeadingIndex = 0;


        const bidsCore = app.makeCore( 'bid');
        bidsCore.soGet = SOGetBid.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          sale: new SOGetSale(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            bidsCore
        )
        bidsCore.title = heading ? heading : bidsCore.title
        bidsCore.listTitle = 'Bids'

        bidsCore.configureForDisplay(parentEntityViewSpec)

        this.bids = new DetailEntry(
          heading ? heading : bidsCore.listTitle,
          null,
          'bids',
          BaseDetailType.embeddedList,
          bidsCore,
          EditableType.never
        )

        const saleaccountascsCore = app.makeCore( 'saleaccountasc');
        saleaccountascsCore.soGet = SOGetSaleaccountasc.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          sale: new SOGetSale(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            saleaccountascsCore
        )
        saleaccountascsCore.title = heading ? heading : saleaccountascsCore.title
        saleaccountascsCore.listTitle = 'Saleaccountascs'

        saleaccountascsCore.configureForDisplay(parentEntityViewSpec)

        this.saleaccountascs = new DetailEntry(
          heading ? heading : saleaccountascsCore.listTitle,
          null,
          'saleaccountascs',
          BaseDetailType.embeddedList,
          saleaccountascsCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        this.distribution.singleFieldSelect = true

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        let distributionmonthCeProtocol;
        if (entity.distributionmonth) {
            distributionmonthCeProtocol = createCEProtocol(
                distributionmonthCore.ceProtocol,
                parentEntityViewSpec,
                distributionmonthCore,
                entity.distributionmonth,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        if (entity.distributionmonth) {
            displayStringFactory.reconfigure(
                entity.distributionmonth,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          distributionmonthCeProtocol ? distributionmonthCeProtocol.entityListView.fullHeading : null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        if (entity.distributionmonth) {
          this.distributionmonth.ceProtocol = distributionmonthCeProtocol;
          this.distributionmonth.cachedListEntry = distributionmonthCore.makeListEntry(parentEntityViewSpec, entity.distributionmonth, 0)
          distributionmonthCore.selectedEntity = entity.distributionmonth;
        }
        this.distributionmonth.singleFieldSelect = true
    }
}