import { Type } from 'class-transformer';
import { SORespTrailer } from '../trailer/so-resp-trailer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespLoad } from '../load/so-resp-load';
import { SORespHemon } from '../hemon/so-resp-hemon';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespContainersession implements ObjectiveProtocol {

  entityType: string = 'containersession';
  uid: string;
  @Type(() => Date)
  arrived: Date;
  shipEmpty: boolean = false;
  carrierSignature: string = null;
  providerSignature: string = null;
  notes: string = "";
  @Type(() => Date)
  departed: Date = null;
  @Type(() => SORespTrailer)
  trailer: SORespTrailer;
  @Type(() => SORespAccount)
  account: SORespAccount = null;
  @Type(() => SORespLoad)
  load: SORespLoad = null;
  loadSheetId: string = null;
  @Type(() => SORespHemon)
  lastHemon: SORespHemon = null;
}