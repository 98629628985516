import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTempagreementService} from '../../core/tempagreement/eo-tempagreement';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';
import {RAPutTempagreement} from './ra-put-tempagreement';
import {RAPostTempagreement} from './ra-post-tempagreement';
import {RADeleteTempagreement} from './ra-delete-tempagreement';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETempagreement } from "./ce-tempagreement"


export class TempagreementCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'tempagreement';
  title = 'Tempagreement';
  ceProtocol: CEConstructor = CETempagreement

  entityService: EOTempagreementService;
  soGetClass = SOGetTempagreement;
  soGet: SOGetTempagreement;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTempagreementService
    );
    this.adapters = [
      new RAPostTempagreement(this),
      new RAPutTempagreement(this),
      new RADeleteTempagreement(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTempagreement();
    this.soGet.queryOptions.limit = this.limit;
  }


}