import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTrailerauth } from './so-put-trailerauth';
import { SOGetTrailerauth } from './so-get-trailerauth';
import { SOPostTrailerauth } from './so-post-trailerauth';
import { SODeleteTrailerauth } from './so-delete-trailerauth';
import {SORespTrailerauth} from './so-resp-trailerauth';
import {SOPluralTrailerauth} from './so-plural-trailerauth';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTrailerauthService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'trailerauth',
      entityCore
    );
  }

  /** GET Trailerauth by id. Will 404 if id not found */
  get(request: SOGetTrailerauth): Observable<SOPluralTrailerauth> {
    return this.httpClient.get<SOPluralTrailerauth>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTrailerauth, resp) }),
      tap(_ => this.log(`fetched Trailerauth`)),
      catchError(this.handleError<SOPluralTrailerauth>(`get Trailerauth`))
    );
  }

  /** POST: add a new Trailerauth to the server */
  post(request: SOPostTrailerauth): Observable<SORespTrailerauth> {
    return this.httpClient.post<SORespTrailerauth>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTrailerauth, resp) }),
      tap((response: SORespTrailerauth) => this.log(`added Trailerauth w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTrailerauth>('Trailerauth post'))
    );
  }

  /** PUT: update Trailerauth  */
  put(request: SOPutTrailerauth): Observable<SORespTrailerauth> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTrailerauth>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTrailerauth, resp) }),
      tap((response: SORespTrailerauth) => this.log(`edited Trailerauth w/ uid=${uid}`)),
      catchError(this.handleError<SORespTrailerauth>('Trailerauth put'))
    );
  }

  /** DELETE: delete the Trailerauth from the server */
  delete(request: SODeleteTrailerauth): Observable<SORespTrailerauth> {
    return this.httpClient.delete<SORespTrailerauth>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Trailerauth uid=${request.uid}`)),
      catchError(this.handleError<SORespTrailerauth>('delete Trailerauth'))
    );
  }
}
