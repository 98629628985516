import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostFeeschedule {

  constructor(
    public displayName: string,
    public distribution: SOGetDistribution,
    public start: Date,
    public end: Date,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostFeeschedule(
      'displayName' in params ? params.displayName : null,
      'distribution' in params ? params.distribution : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
    );
  }
}
