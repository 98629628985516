import {SORespWeight} from '../../core/weight/so-resp-weight';
import {WeightCore} from './weight.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutWeightFormFields} from './put-weight-form-fields';
import {SOGetWeight} from '../../core/weight/so-get-weight';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetTask} from '../../core/task/so-get-task';
import {SOGetDeltaweight} from '../../core/deltaweight/so-get-deltaweight';
import {SOGetTagreport} from '../../core/tagreport/so-get-tagreport';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEWeightAttributesBase {

    dateCreated: DetailEntry
    tare: DetailEntry
    amount: DetailEntry
    load: DetailEntry
    amountDcs: DetailEntry
    containersession: DetailEntry
    dcsTimestamp: DetailEntry
    tasks: DetailEntry
    deltaweights: DetailEntry
    tagreports: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespWeight,
        app: GasDirectApp,
        formFields: PutWeightFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateCreated
        )

        this.tare = new DetailEntry(
            'Tare',
            entity.tare,
            'tare',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.tare
        )

        this.amount = new DetailEntry(
            'Amount',
            entity.amount,
            'amount',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amount
        )

        this.amountDcs = new DetailEntry(
            'Amount dcs',
            entity.amountDcs,
            'amountDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dcsTimestamp = new DetailEntry(
            'Dcs timestamp',
            app.activeUserService.time.dateAsString(entity.dcsTimestamp),
            'dcsTimestamp',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.always,
          formFields.load
        )
        this.load.singleFieldSelect = true

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        this.containersession.singleFieldSelect = true

        const tasksCore = app.makeCore( 'task');
        tasksCore.soGet = SOGetTask.construct({
          weight: new SOGetWeight(entity.uid),
        });
        tasksCore.soGet.queryOptions.sortKey = 'dateCreated';
        tasksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tasksCore
        )
        tasksCore.title = heading ? heading : tasksCore.title
        tasksCore.listTitle = 'Tasks'

        tasksCore.configureForDisplay(parentEntityViewSpec)

        this.tasks = new DetailEntry(
          heading ? heading : tasksCore.listTitle,
          null,
          'tasks',
          BaseDetailType.embeddedList,
          tasksCore,
          EditableType.never
        )

        const deltaweightsCore = app.makeCore( 'deltaweight');
        deltaweightsCore.soGet = SOGetDeltaweight.construct({
          weight: new SOGetWeight(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deltaweightsCore
        )
        deltaweightsCore.title = heading ? heading : deltaweightsCore.title
        deltaweightsCore.listTitle = 'Deltaweights'

        deltaweightsCore.configureForDisplay(parentEntityViewSpec)

        this.deltaweights = new DetailEntry(
          heading ? heading : deltaweightsCore.listTitle,
          null,
          'deltaweights',
          BaseDetailType.embeddedList,
          deltaweightsCore,
          EditableType.never
        )

        const tagreportsCore = app.makeCore( 'tagreport');
        tagreportsCore.soGet = SOGetTagreport.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tagreportsCore
        )
        tagreportsCore.title = heading ? heading : tagreportsCore.title
        tagreportsCore.listTitle = 'Tagreports'

        tagreportsCore.configureForDisplay(parentEntityViewSpec)

        this.tagreports = new DetailEntry(
          heading ? heading : tagreportsCore.listTitle,
          null,
          'tagreports',
          BaseDetailType.embeddedList,
          tagreportsCore,
          EditableType.never
        )
    }
}