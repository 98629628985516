import {SOPostCalendar} from '../../core/calendar/so-post-calendar';
import {CalendarCoreBase} from './calendar.core.base';
import {SOGetCalendar} from '../../core/calendar/so-get-calendar';
import {FormField, SelectionOption} from 'lionheartland';
import {postCalendarFormFieldOptions} from './post-calendar-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPostCalendar extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postCalendarFormFieldOptions);

  RequestClass = SOPostCalendar

  constructor(
    public entityCore: CalendarCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('label'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}