import {SORespQuotelet} from '../../core/quotelet/so-resp-quotelet';
import {QuoteletCore} from './quotelet.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutQuoteletFormFields} from './put-quotelet-form-fields';
import {SOGetQuotelet} from '../../core/quotelet/so-get-quotelet';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';


export class CEQuoteletAttributesBase {

    type: DetailEntry
    units: DetailEntry
    quantityVolume: DetailEntry
    quantityLoads: DetailEntry
    indexRate: DetailEntry
    variableRate: DetailEntry
    totalRate: DetailEntry
    totalAmount: DetailEntry
    order: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespQuotelet,
        app: GasDirectApp,
        formFields: PutQuoteletFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.type
        )

        this.units = new DetailEntry(
            'Units',
            entity.units,
            'units',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.units
        )

        this.quantityVolume = new DetailEntry(
            'Quantity volume',
            entity.quantityVolume,
            'quantityVolume',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.quantityVolume
        )

        this.quantityLoads = new DetailEntry(
            'Quantity loads',
            entity.quantityLoads,
            'quantityLoads',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.quantityLoads
        )

        this.indexRate = new DetailEntry(
            'Index rate',
            entity.indexRate,
            'indexRate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.indexRate
        )

        this.variableRate = new DetailEntry(
            'Variable rate',
            entity.variableRate,
            'variableRate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.variableRate
        )

        this.totalRate = new DetailEntry(
            'Total rate',
            entity.totalRate,
            'totalRate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.totalRate
        )

        this.totalAmount = new DetailEntry(
            'Total amount',
            entity.totalAmount,
            'totalAmount',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.totalAmount
        )


        const orderCore = app.makeCore( 'order');
        orderCore.soGet = SOGetOrder.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreement: app.getScope('agreement') ? new SOGetAgreement(app.getScope('agreement').uid) : null,
          agreemonth: app.getScope('agreemonth') ? new SOGetAgreemonth(app.getScope('agreemonth').uid) : null,
          distributionmonth: app.getScope('distributionmonth') ? new SOGetDistributionmonth(app.getScope('distributionmonth').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            orderCore
        )
        orderCore.title = heading ? heading : orderCore.title
        orderCore.listTitle = 'Order'

        orderCore.configureForDisplay(parentEntityViewSpec)

        this.order = new DetailEntry(
          heading ? heading : orderCore.listTitle,
          null,
          'order',
          BaseDetailType.pushedDetail,
          orderCore,
          EditableType.always,
          formFields.order
        )
        this.order.singleFieldSelect = true
    }
}