import { Type } from 'class-transformer';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import { SORespTemprice } from '../temprice/so-resp-temprice';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTempclin implements ObjectiveProtocol {

  entityType: string = 'tempclin';
  uid: string;
  name: string;
  type: string;
  state: string = "active";
  durationDays: number = 365;
  offsetDays: number = 0;
  quantity: number = 0;
  loadQuantity: number = 0;
  description: string = "";
  @Type(() => Date)
  start: Date = null;
  @Type(() => Date)
  end: Date = null;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution = null;
  @Type(() => SORespTemprice)
  temprices: SORespTemprice[] = [];
}