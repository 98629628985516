import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostMessageFormFields {

    public body: FormField
    public type: FormField
    public ref: FormField
    public subscription: FormField
    public publication: FormField
    public activity: FormField

    constructor() {

        this.body = new FormField(
          'body',
          'Body',
          null,
          FormFieldType.text,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )
        this.ref = new FormField(
          'ref',
          'Ref',
          null,
          FormFieldType.text,
          false,
        )
        this.subscription = new FormField(
          'subscription',
          'Subscription',
          'subscription',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.publication = new FormField(
          'publication',
          'Publication',
          'publication',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.activity = new FormField(
          'activity',
          'Activity',
          'activity',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostMessageFormFields()

export const postMessageFormFieldOptions: {[key: string]: FormField} = {
  body: fields.body,
  type: fields.type,
  ref: fields.ref,
  subscription: fields.subscription,
  publication: fields.publication,
  activity: fields.activity,
};