import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOProductService} from '../../core/product/eo-product';
import {SOGetProduct} from '../../core/product/so-get-product';
import {RAPutProduct} from './ra-put-product';
import {RAPostProduct} from './ra-post-product';
import {RADeleteProduct} from './ra-delete-product';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEProduct } from "./ce-product"


export class ProductCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'product';
  title = 'Product';
  ceProtocol: CEConstructor = CEProduct

  entityService: EOProductService;
  soGetClass = SOGetProduct;
  soGet: SOGetProduct;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOProductService
    );
    this.adapters = [
      new RAPostProduct(this),
      new RAPutProduct(this),
      new RADeleteProduct(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetProduct();
    this.soGet.queryOptions.limit = this.limit;
  }


}