import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetTempagreement } from '../tempagreement/so-get-tempagreement';


export class SOPutDisttempagreement {

  constructor(
    public uid: string,
    public isDefault: boolean = null,
    public distribution: SOGetDistribution = null,
    public tempagreement: SOGetTempagreement = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDisttempagreement(
      'uid' in params ? params.uid : null,
      'isDefault' in params ? params.isDefault : null,
      'distribution' in params ? params.distribution : null,
      'tempagreement' in params ? params.tempagreement : null,
    );
  }
}
