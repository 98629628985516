import { Type } from 'class-transformer';
import { SORespDcs } from '../dcs/so-resp-dcs';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTag implements ObjectiveProtocol {

  entityType: string = 'tag';
  uid: string;
  name: string;
  identifier: string;
  @Type(() => SORespDcs)
  dcs: SORespDcs;
}