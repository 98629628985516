import { SOGetSlot } from '../slot/so-get-slot';
import { SOGetAgreemonth } from '../agreemonth/so-get-agreemonth';


export class SOPostSlotoption {

  constructor(
    public slot: SOGetSlot,
    public agreemonth: SOGetAgreemonth,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostSlotoption(
      'slot' in params ? params.slot : null,
      'agreemonth' in params ? params.agreemonth : null,
    );
  }
}
