import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetExternalindexprice } from '../externalindexprice/so-get-externalindexprice';


export class SOPutIndexprice {

  constructor(
    public uid: string,
    public start: Date = null,
    public end: Date = null,
    public state: string = null,
    public rate: number = null,
    public distribution: SOGetDistribution = null,
    public externalindexprice: SOGetExternalindexprice = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutIndexprice(
      'uid' in params ? params.uid : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'state' in params ? params.state : null,
      'rate' in params ? params.rate : null,
      'distribution' in params ? params.distribution : null,
      'externalindexprice' in params ? params.externalindexprice : null,
    );
  }
}
