import { SOGetDcs } from '../dcs/so-get-dcs';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetTag implements QueryProtocol {

  constructor(
    public uid: string = null,
    public dcs: SOGetDcs = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetTag(
      'uid' in params ? params.uid : null,
      'dcs' in params ? params.dcs : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
