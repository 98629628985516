import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostProductionreportFormFields {

    public ident: FormField
    public production: FormField
    public timeWindow: FormField
    public start: FormField
    public end: FormField
    public average: FormField
    public averageUnits: FormField
    public midnightWeight: FormField

    constructor() {

        this.ident = new FormField(
          'ident',
          'Ident',
          null,
          FormFieldType.text,
          true,
        )
        this.production = new FormField(
          'production',
          'Production',
          'production',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.timeWindow = new FormField(
          'timeWindow',
          'Time window',
          null,
          FormFieldType.text,
          true,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          true,
        )
        this.average = new FormField(
          'average',
          'Average',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.averageUnits = new FormField(
          'averageUnits',
          'Average units',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.midnightWeight = new FormField(
          'midnightWeight',
          'Midnight weight',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )

    }
}

const fields = new PostProductionreportFormFields()

export const postProductionreportFormFieldOptions: {[key: string]: FormField} = {
  ident: fields.ident,
  production: fields.production,
  timeWindow: fields.timeWindow,
  start: fields.start,
  end: fields.end,
  average: fields.average,
  averageUnits: fields.averageUnits,
  midnightWeight: fields.midnightWeight,
};