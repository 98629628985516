

export class SOPutFeature {

  constructor(
    public uid: string,
    public name: string = null,
    public component: string = null,
    public description: string = null,
    public status: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutFeature(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'component' in params ? params.component : null,
      'description' in params ? params.description : null,
      'status' in params ? params.status : null,
    );
  }
}
