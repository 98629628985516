import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespOffering implements ObjectiveProtocol {

  entityType: string = 'offering';
  uid: string;
  name: string;
  description: string = "";
  units: string;
  size: number;
}