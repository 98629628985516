import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostReleaseFormFields {

    public name: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostReleaseFormFields()

export const postReleaseFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
};