import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOPlantService} from '../../core/plant/eo-plant';
import {SOGetPlant} from '../../core/plant/so-get-plant';
import {RAPutPlant} from './ra-put-plant';
import {RAPostPlant} from './ra-post-plant';
import {RADeletePlant} from './ra-delete-plant';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEPlant } from "./ce-plant"


export class PlantCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'plant';
  title = 'Plant';
  ceProtocol: CEConstructor = CEPlant

  entityService: EOPlantService;
  soGetClass = SOGetPlant;
  soGet: SOGetPlant;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOPlantService
    );
    this.adapters = [
      new RAPostPlant(this),
      new RAPutPlant(this),
      new RADeletePlant(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPlant();
    this.soGet.queryOptions.limit = this.limit;
  }


}