import {SORespContract} from '../../core/contract/so-resp-contract';
import {ContractCore} from './contract.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutContractFormFields} from './put-contract-form-fields';
import {SOGetContract} from '../../core/contract/so-get-contract';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';
import {SOGetClin} from '../../core/clin/so-get-clin';
import {SOGetClinyearlng} from '../../core/clinyearlng/so-get-clinyearlng';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CEContractAttributesBase {

    name: DetailEntry
    description: DetailEntry
    takeOrPay: DetailEntry
    minLoadSizeToQualify: DetailEntry
    quantity: DetailEntry
    quantityUsed: DetailEntry
    loadQuantity: DetailEntry
    loadQuantityUsed: DetailEntry
    docusignLink: DetailEntry
    state: DetailEntry
    type: DetailEntry
    start: DetailEntry
    end: DetailEntry
    agreement: DetailEntry
    temptract: DetailEntry
    loadType: DetailEntry
    clins: DetailEntry
    clinyearlngs: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespContract,
        app: GasDirectApp,
        formFields: PutContractFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.takeOrPay = new DetailEntry(
            'Take or pay',
            entity.takeOrPay,
            'takeOrPay',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.minLoadSizeToQualify = new DetailEntry(
            'Min load size to qualify',
            entity.minLoadSizeToQualify,
            'minLoadSizeToQualify',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.minLoadSizeToQualify
        )

        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.quantityUsed = new DetailEntry(
            'Quantity used',
            entity.quantityUsed,
            'quantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadQuantity = new DetailEntry(
            'Load quantity',
            entity.loadQuantity,
            'loadQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadQuantityUsed = new DetailEntry(
            'Load quantity used',
            entity.loadQuantityUsed,
            'loadQuantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.docusignLink = new DetailEntry(
            'Docusign link',
            entity.docusignLink,
            'docusignLink',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.docusignLink
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )

        this.loadType = new DetailEntry(
            'Load type',
            entity.loadType,
            'loadType',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const agreementCore = app.makeCore( 'agreement');
        agreementCore.soGet = SOGetAgreement.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        let agreementCeProtocol;
        if (entity.agreement) {
            agreementCeProtocol = createCEProtocol(
                agreementCore.ceProtocol,
                parentEntityViewSpec,
                agreementCore,
                entity.agreement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementCore
        )
        agreementCore.title = heading ? heading : agreementCore.title
        agreementCore.listTitle = 'Agreement'

        if (entity.agreement) {
            displayStringFactory.reconfigure(
                entity.agreement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreementCore.configureForDisplay(parentEntityViewSpec)

        this.agreement = new DetailEntry(
          heading ? heading : agreementCore.listTitle,
          agreementCeProtocol ? agreementCeProtocol.entityListView.fullHeading : null,
          'agreement',
          BaseDetailType.pushedDetail,
          agreementCore,
          EditableType.never
        )
        if (entity.agreement) {
          this.agreement.ceProtocol = agreementCeProtocol;
          this.agreement.cachedListEntry = agreementCore.makeListEntry(parentEntityViewSpec, entity.agreement, 0)
          agreementCore.selectedEntity = entity.agreement;
        }
        this.agreement.singleFieldSelect = true

        const temptractCore = app.makeCore( 'temptract');
        temptractCore.soGet = SOGetTemptract.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            temptractCore
        )
        temptractCore.title = heading ? heading : temptractCore.title
        temptractCore.listTitle = 'Temptract'

        temptractCore.configureForDisplay(parentEntityViewSpec)

        this.temptract = new DetailEntry(
          heading ? heading : temptractCore.listTitle,
          null,
          'temptract',
          BaseDetailType.pushedDetail,
          temptractCore,
          EditableType.never
        )
        this.temptract.singleFieldSelect = true

        const clinsCore = app.makeCore( 'clin');
        clinsCore.soGet = SOGetClin.construct({
          contract: new SOGetContract(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            clinsCore
        )
        clinsCore.title = heading ? heading : clinsCore.title
        clinsCore.listTitle = 'Clins'

        clinsCore.configureForDisplay(parentEntityViewSpec)

        this.clins = new DetailEntry(
          heading ? heading : clinsCore.listTitle,
          null,
          'clins',
          BaseDetailType.embeddedList,
          clinsCore,
          EditableType.never
        )

        const clinyearlngsCore = app.makeCore( 'clinyearlng');
        clinyearlngsCore.soGet = SOGetClinyearlng.construct({
          contract: new SOGetContract(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            clinyearlngsCore
        )
        clinyearlngsCore.title = heading ? heading : clinyearlngsCore.title
        clinyearlngsCore.listTitle = 'Clinyearlngs'

        clinyearlngsCore.configureForDisplay(parentEntityViewSpec)

        this.clinyearlngs = new DetailEntry(
          heading ? heading : clinyearlngsCore.listTitle,
          null,
          'clinyearlngs',
          BaseDetailType.embeddedList,
          clinyearlngsCore,
          EditableType.never
        )
    }
}