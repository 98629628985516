import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostTaskreport {

  constructor(
    public type: string,
    public status: string,
    public distribution: SOGetDistribution,
    public dateRequested: Date = null,
    public dateStart: Date = null,
    public dateEnd: Date = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTaskreport(
      'type' in params ? params.type : null,
      'status' in params ? params.status : null,
      'distribution' in params ? params.distribution : null,
      'dateRequested' in params ? params.dateRequested : null,
      'dateStart' in params ? params.dateStart : null,
      'dateEnd' in params ? params.dateEnd : null,
    );
  }
}
