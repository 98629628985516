import {SOPutNavi} from '../../core/navi/so-put-navi';
import {NaviCoreBase} from './navi.core.base';
import {SOGetNavi} from '../../core/navi/so-get-navi';
import {FormField, SelectionOption} from 'lionheartland';
import {putNaviFormFieldOptions} from './put-navi-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutNavi extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putNaviFormFieldOptions);

  RequestClass = SOPutNavi

  constructor(
    public entityCore: NaviCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('type'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}