import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutHemonFormFields {

    public dateCreated: FormField
    public state: FormField
    public n2Level: FormField
    public n2Pressure: FormField
    public heTemp: FormField
    public heLevel: FormField
    public hePressure: FormField
    public load: FormField
    public task: FormField
    public containersession: FormField

    constructor() {

        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          false,
        )
        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )
        this.n2Level = new FormField(
          'n2Level',
          'N2 level',
          null,
          FormFieldType.text,
          false,
        )
        this.n2Pressure = new FormField(
          'n2Pressure',
          'N2 pressure',
          null,
          FormFieldType.text,
          false,
        )
        this.heTemp = new FormField(
          'heTemp',
          'He temp',
          null,
          FormFieldType.text,
          false,
        )
        this.heLevel = new FormField(
          'heLevel',
          'He level',
          null,
          FormFieldType.text,
          false,
        )
        this.hePressure = new FormField(
          'hePressure',
          'He pressure',
          null,
          FormFieldType.text,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.task = new FormField(
          'task',
          'Task',
          'task',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutHemonFormFields()

export const putHemonFormFieldOptions: {[key: string]: FormField} = {
  dateCreated: fields.dateCreated,
  state: fields.state,
  n2Level: fields.n2Level,
  n2Pressure: fields.n2Pressure,
  heTemp: fields.heTemp,
  heLevel: fields.heLevel,
  hePressure: fields.hePressure,
  load: fields.load,
  task: fields.task,
  containersession: fields.containersession,
};