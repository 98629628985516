import { SOGetLine } from '../line/so-get-line';


export class SOPostProduction {

  constructor(
    public name: string,
    public dailyRateUnits: string,
    public line: SOGetLine,
    public dailyRate: number = 0,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostProduction(
      'name' in params ? params.name : null,
      'dailyRate' in params ? params.dailyRate : null,
      'dailyRateUnits' in params ? params.dailyRateUnits : null,
      'line' in params ? params.line : null,
    );
  }
}
