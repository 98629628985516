import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutAward } from './so-put-award';
import { SOGetAward } from './so-get-award';
import { SOPostAward } from './so-post-award';
import { SODeleteAward } from './so-delete-award';
import {SORespAward} from './so-resp-award';
import {SOPluralAward} from './so-plural-award';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOAwardService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'award',
      entityCore
    );
  }

  /** GET Award by id. Will 404 if id not found */
  get(request: SOGetAward): Observable<SOPluralAward> {
    return this.httpClient.get<SOPluralAward>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAward, resp) }),
      tap(_ => this.log(`fetched Award`)),
      catchError(this.handleError<SOPluralAward>(`get Award`))
    );
  }

  /** POST: add a new Award to the server */
  post(request: SOPostAward): Observable<SORespAward> {
    return this.httpClient.post<SORespAward>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAward, resp) }),
      tap((response: SORespAward) => this.log(`added Award w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAward>('Award post'))
    );
  }

  /** PUT: update Award  */
  put(request: SOPutAward): Observable<SORespAward> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAward>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAward, resp) }),
      tap((response: SORespAward) => this.log(`edited Award w/ uid=${uid}`)),
      catchError(this.handleError<SORespAward>('Award put'))
    );
  }

  /** DELETE: delete the Award from the server */
  delete(request: SODeleteAward): Observable<SORespAward> {
    return this.httpClient.delete<SORespAward>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Award uid=${request.uid}`)),
      catchError(this.handleError<SORespAward>('delete Award'))
    );
  }
}
