import { Type } from 'class-transformer';
import { SORespHmonth } from '../hmonth/so-resp-hmonth';
import { SORespAgreement } from '../agreement/so-resp-agreement';
import { SORespAgreeyear } from '../agreeyear/so-resp-agreeyear';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespAgreemonth implements ObjectiveProtocol {

  entityType: string = 'agreemonth';
  uid: string;
  state: string;
  mcq: number = 0;
  mcqUsed: number = 0;
  delta: number = null;
  @Type(() => SORespHmonth)
  hmonth: SORespHmonth;
  @Type(() => SORespAgreement)
  agreement: SORespAgreement;
  year: number;
  month: number;
  @Type(() => SORespAgreeyear)
  agreeyear: SORespAgreeyear;
  spotQuantity: number = 0;
  spotQuantityUsed: number = 0;
  mcqEstimated: number = null;
  mcqInitial: number = null;
}