import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutFaqFormFields {

    public index: FormField
    public title: FormField
    public body: FormField
    public distribution: FormField

    constructor() {

        this.index = new FormField(
          'index',
          'Index',
          null,
          FormFieldType.text,
          false,
        )
        this.title = new FormField(
          'title',
          'Title',
          null,
          FormFieldType.text,
          false,
        )
        this.body = new FormField(
          'body',
          'Body',
          null,
          FormFieldType.text,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutFaqFormFields()

export const putFaqFormFieldOptions: {[key: string]: FormField} = {
  index: fields.index,
  title: fields.title,
  body: fields.body,
  distribution: fields.distribution,
};