import { SOGetTag } from '../tag/so-get-tag';
import { SOGetLine } from '../line/so-get-line';
import { SOGetTank } from '../tank/so-get-tank';


export class SOPostLinag {

  constructor(
    public name: string,
    public identifier: string,
    public type: string,
    public tag: SOGetTag,
    public line: SOGetLine = null,
    public tank: SOGetTank = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostLinag(
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'type' in params ? params.type : null,
      'tag' in params ? params.tag : null,
      'line' in params ? params.line : null,
      'tank' in params ? params.tank : null,
    );
  }
}
