import {SORespSlotlet} from '../../core/slotlet/so-resp-slotlet';
import {SlotletCore} from './slotlet.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSlotletFormFields} from './put-slotlet-form-fields';
import {SOGetSlotlet} from '../../core/slotlet/so-get-slotlet';
import {SOGetEvent} from '../../core/event/so-get-event';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CESlotletAttributesBase {

    start: DetailEntry
    end: DetailEntry
    status: DetailEntry
    dayOffset: DetailEntry
    slotNumber: DetailEntry
    event: DetailEntry
    account: DetailEntry
    tempclin: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSlotlet,
        app: GasDirectApp,
        formFields: PutSlotletFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.status
        )

        this.dayOffset = new DetailEntry(
            'Day offset',
            entity.dayOffset,
            'dayOffset',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dayOffset
        )

        this.slotNumber = new DetailEntry(
            'Slot number',
            entity.slotNumber,
            'slotNumber',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.slotNumber
        )


        const eventCore = app.makeCore( 'event');
        eventCore.soGet = SOGetEvent.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            eventCore
        )
        eventCore.title = heading ? heading : eventCore.title
        eventCore.listTitle = 'Event'

        eventCore.configureForDisplay(parentEntityViewSpec)

        this.event = new DetailEntry(
          heading ? heading : eventCore.listTitle,
          null,
          'event',
          BaseDetailType.pushedDetail,
          eventCore,
          EditableType.always,
          formFields.event
        )
        this.event.singleFieldSelect = true

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.always,
          formFields.account
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const tempclinCore = app.makeCore( 'tempclin');
        tempclinCore.soGet = SOGetTempclin.construct({
        });
        let tempclinCeProtocol;
        if (entity.tempclin) {
            tempclinCeProtocol = createCEProtocol(
                tempclinCore.ceProtocol,
                parentEntityViewSpec,
                tempclinCore,
                entity.tempclin,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempclinCore
        )
        tempclinCore.title = heading ? heading : tempclinCore.title
        tempclinCore.listTitle = 'Tempclin'

        if (entity.tempclin) {
            displayStringFactory.reconfigure(
                entity.tempclin,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        tempclinCore.configureForDisplay(parentEntityViewSpec)

        this.tempclin = new DetailEntry(
          heading ? heading : tempclinCore.listTitle,
          tempclinCeProtocol ? tempclinCeProtocol.entityListView.fullHeading : null,
          'tempclin',
          BaseDetailType.pushedDetail,
          tempclinCore,
          EditableType.always,
          formFields.tempclin
        )
        if (entity.tempclin) {
          this.tempclin.ceProtocol = tempclinCeProtocol;
          this.tempclin.cachedListEntry = tempclinCore.makeListEntry(parentEntityViewSpec, entity.tempclin, 0)
          tempclinCore.selectedEntity = entity.tempclin;
        }
        this.tempclin.singleFieldSelect = true
    }
}