import {SOPostBid} from '../../core/bid/so-post-bid';
import {BidCoreBase} from './bid.core.base';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {FormField, SelectionOption} from 'lionheartland';
import {postBidFormFieldOptions} from './post-bid-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TempclinCore} from '../tempclin/tempclin.core';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SaleCore} from '../sale/sale.core';
import {SOGetSale} from '../../core/sale/so-get-sale';

export class RAPostBid extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postBidFormFieldOptions);

  RequestClass = SOPostBid

  constructor(
    public entityCore: BidCoreBase
  ) {
    super();
    if (postBidFormFieldOptions.tempclin) {
      postBidFormFieldOptions.tempclin.makeCore = true;
    }
    if (postBidFormFieldOptions.sale) {
      postBidFormFieldOptions.sale.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('displayName'),
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'tempclin',
        SOGetTempclin,
      ),
      this.getFormValueFromIdentifier('amountTwo'),
      this.getFormValueFromIdentifier('amountThree'),
      this.getFormValueFromIdentifier(
        'sale',
        SOGetSale,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}