import {Component, Input, OnInit} from '@angular/core';
import {SORespLoad} from "../../core/load/so-resp-load";
import {
  ActiveUserService,
  ListEntry
} from "lionheartland";
import {GasDirectApp} from "../../land-app";

export function getSoakDaysRemainingString(entity: SORespLoad) {
  if (entity.coolDownDays && entity.coolDownDays > 0) {
    let daysRemain;
    if (entity.soakStart) {
      daysRemain = (entity.dueDate.getTime() - new Date().getTime()) / 3600 / 24 / 1000;
      if (daysRemain < .1) {
        daysRemain = 0;
      }
      daysRemain = Math.ceil(daysRemain);
    } else {
      daysRemain = entity.coolDownDays;
    }
    return `${daysRemain.toLocaleString(undefined, {maximumFractionDigits: 1})}`;
  } else {
    return '-';
  }
}

@Component({
  selector: 'app-dv-load-summary',
  templateUrl: './dv-load-summary.component.html',
  styleUrls: ['./dv-load-summary.component.css']
})
export class DvLoadSummaryComponent implements OnInit {
  dueDate = '';
  soakDaysRemaining = '';
  soakStartDate = '';
  lastEight = '';
  customerName = '';

  get app(): GasDirectApp {
    return this.activeUserService.app as GasDirectApp
  }

  @Input()
  set listEntry(value: ListEntry) {
    this._listEntry = value;
    if (this.listEntry.entity.coolDownDays > 0) {
      console.log(this.listEntry.entity.dueDate);
    }
    this.dueDate = this.activeUserService.time.niceVeryShortDateAndTime(this.listEntry.entity.dueDate);
    this.soakDaysRemaining = getSoakDaysRemainingString(this.listEntry.entity);
    this.soakStartDate = this.listEntry ? this.activeUserService.time.niceVeryShortDateAndTime(this.listEntry.entity.soakStart) : '';
    this.customerName = this.listEntry.entity.account ? this.listEntry.entity.account.customer.companyName : '';

    this.lastEight = this.listEntry.trailingText;

    if (!this.listEntry.entity.requestedFillDate && !this.listEntry.entity.load) {
      this.arrived = this.activeUserService.time.niceShortNumericDateNoTime(this.listEntry.entity.arrived);
    }
  }

  get listEntry(): ListEntry {
    return this._listEntry;
  }

  // tslint:disable-next-line:variable-name
  _listEntry: ListEntry;

  @Input()
  soaker = false;
  arrived: string;

  get showHold() {
    return (
      this.listEntry.entity
      && this.listEntry.entity.cost
      && this.listEntry.entity.status === 'loadingComplete'
      && this.listEntry.entity.account
      && this.listEntry.entity.account.balance < 0
    );
  }

  get color() {
    let colors;
    if (this.app.isHelium) {
      colors = {
        scheduled: '#532160',
        confirmed: '#532160',
        arrived: '#007096',
        loading: 'orange',
        soaking: '#FA3235',
        loadingComplete: '#00A14D',
        departed: 'lightgrey'
      };
    } else {
      colors = {
        scheduled: '#532160',
        confirmed: 'grey',
        arrived: '#007096',
        checkIn: '#007096',
        loading: 'orange',
        loadingComplete: '#00A14D',
        final: '#00A14D',
        departed: 'lightgrey'
      };
    }
    return colors[this.listEntry.entity.status] ? colors[this.listEntry.entity.status] : 'black';
  }

  constructor(
    public activeUserService: ActiveUserService
  ) {
  }

  ngOnInit(): void {
  }

  getLine(line: string) {
    let result;
    result = line;
    return result;
  }
}
