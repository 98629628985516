import {SORespFeatureasc} from '../../core/featureasc/so-resp-featureasc';
import {FeatureascCore} from './featureasc.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeatureascFormFields} from './put-featureasc-form-fields';
import {SOGetFeatureasc} from '../../core/featureasc/so-get-featureasc';
import {SOGetFeature} from '../../core/feature/so-get-feature';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CEFeatureascAttributesBase {

    feature: DetailEntry
    distribution: DetailEntry
    user: DetailEntry
    role: DetailEntry
    account: DetailEntry
    organization: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespFeatureasc,
        app: GasDirectApp,
        formFields: PutFeatureascFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const featureCore = app.makeCore( 'feature');
        featureCore.soGet = SOGetFeature.construct({
        });
        let featureCeProtocol;
        if (entity.feature) {
            featureCeProtocol = createCEProtocol(
                featureCore.ceProtocol,
                parentEntityViewSpec,
                featureCore,
                entity.feature,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            featureCore
        )
        featureCore.title = heading ? heading : featureCore.title
        featureCore.listTitle = 'Feature'

        if (entity.feature) {
            displayStringFactory.reconfigure(
                entity.feature,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        featureCore.configureForDisplay(parentEntityViewSpec)

        this.feature = new DetailEntry(
          heading ? heading : featureCore.listTitle,
          featureCeProtocol ? featureCeProtocol.entityListView.fullHeading : null,
          'feature',
          BaseDetailType.pushedDetail,
          featureCore,
          EditableType.always,
          formFields.feature
        )
        if (entity.feature) {
          this.feature.ceProtocol = featureCeProtocol;
          this.feature.cachedListEntry = featureCore.makeListEntry(parentEntityViewSpec, entity.feature, 0)
          featureCore.selectedEntity = entity.feature;
        }
        this.feature.singleFieldSelect = true

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const userCore = app.makeCore( 'user');
        userCore.soGet = SOGetUser.construct({
        });
        userCore.soGet.queryOptions.sortKey = 'surname';
        let userCeProtocol;
        if (entity.user) {
            userCeProtocol = createCEProtocol(
                userCore.ceProtocol,
                parentEntityViewSpec,
                userCore,
                entity.user,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            userCore
        )
        userCore.title = heading ? heading : userCore.title
        userCore.listTitle = 'User'

        if (entity.user) {
            displayStringFactory.reconfigure(
                entity.user,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        userCore.configureForDisplay(parentEntityViewSpec)

        this.user = new DetailEntry(
          heading ? heading : userCore.listTitle,
          userCeProtocol ? userCeProtocol.entityListView.fullHeading : null,
          'user',
          BaseDetailType.pushedDetail,
          userCore,
          EditableType.always,
          formFields.user
        )
        if (entity.user) {
          this.user.ceProtocol = userCeProtocol;
          this.user.cachedListEntry = userCore.makeListEntry(parentEntityViewSpec, entity.user, 0)
          userCore.selectedEntity = entity.user;
        }
        this.user.singleFieldSelect = true

        const roleCore = app.makeCore( 'role');
        roleCore.soGet = SOGetRole.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let roleCeProtocol;
        if (entity.role) {
            roleCeProtocol = createCEProtocol(
                roleCore.ceProtocol,
                parentEntityViewSpec,
                roleCore,
                entity.role,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleCore
        )
        roleCore.title = heading ? heading : roleCore.title
        roleCore.listTitle = 'Role'

        if (entity.role) {
            displayStringFactory.reconfigure(
                entity.role,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        roleCore.configureForDisplay(parentEntityViewSpec)

        this.role = new DetailEntry(
          heading ? heading : roleCore.listTitle,
          roleCeProtocol ? roleCeProtocol.entityListView.fullHeading : null,
          'role',
          BaseDetailType.pushedDetail,
          roleCore,
          EditableType.always,
          formFields.role
        )
        if (entity.role) {
          this.role.ceProtocol = roleCeProtocol;
          this.role.cachedListEntry = roleCore.makeListEntry(parentEntityViewSpec, entity.role, 0)
          roleCore.selectedEntity = entity.role;
        }
        this.role.singleFieldSelect = true

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.always,
          formFields.account
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const organizationCore = app.makeCore( 'organization');
        organizationCore.soGet = SOGetOrganization.construct({
        });
        let organizationCeProtocol;
        if (entity.organization) {
            organizationCeProtocol = createCEProtocol(
                organizationCore.ceProtocol,
                parentEntityViewSpec,
                organizationCore,
                entity.organization,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            organizationCore
        )
        organizationCore.title = heading ? heading : organizationCore.title
        organizationCore.listTitle = 'Organization'

        if (entity.organization) {
            displayStringFactory.reconfigure(
                entity.organization,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        organizationCore.configureForDisplay(parentEntityViewSpec)

        this.organization = new DetailEntry(
          heading ? heading : organizationCore.listTitle,
          organizationCeProtocol ? organizationCeProtocol.entityListView.fullHeading : null,
          'organization',
          BaseDetailType.pushedDetail,
          organizationCore,
          EditableType.always,
          formFields.organization
        )
        if (entity.organization) {
          this.organization.ceProtocol = organizationCeProtocol;
          this.organization.cachedListEntry = organizationCore.makeListEntry(parentEntityViewSpec, entity.organization, 0)
          organizationCore.selectedEntity = entity.organization;
        }
        this.organization.singleFieldSelect = true
    }
}