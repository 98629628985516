import {SOPostTemptract} from '../../core/temptract/so-post-temptract';
import {TemptractCoreBase} from './temptract.core.base';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';
import {FormField, SelectionOption} from 'lionheartland';
import {postTemptractFormFieldOptions} from './post-temptract-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPostTemptract extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTemptractFormFieldOptions);

  RequestClass = SOPostTemptract

  constructor(
    public entityCore: TemptractCoreBase
  ) {
    super();
    if (postTemptractFormFieldOptions.distribution) {
      postTemptractFormFieldOptions.distribution.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('takeOrPay'),
      this.getFormValueFromIdentifier('minLoadSizeToQualify'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('docusignLink'),
      this.getFormValueFromIdentifier('durationDays'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}