import {SORespBol} from '../../core/bol/so-resp-bol';
import {BolCore} from './bol.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutBolFormFields} from './put-bol-form-fields';
import { CEBolAttributes } from './ce-bol-attributes'


export class CEBolBase extends CEBase {

    public details: CEBolAttributes;
    public fromFields: PutBolFormFields = new PutBolFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: BolCore,
        public entity: SORespBol,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEBolAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateSigned)
        this.attributes.push(this.details.body)
        this.attributes.push(this.details.carrierSignature)
        this.attributes.push(this.details.providerSignature)
        this.attributes.push(this.details.driverName)
        this.attributes.push(this.details.destination)
        this.attributes.push(this.details.rearAxelWeight)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.carrier)

    }
}