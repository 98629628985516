import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTemproleService} from '../../core/temprole/eo-temprole';
import {SOGetTemprole} from '../../core/temprole/so-get-temprole';
import {RAPutTemprole} from './ra-put-temprole';
import {RAPostTemprole} from './ra-post-temprole';
import {RADeleteTemprole} from './ra-delete-temprole';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETemprole } from "./ce-temprole"


export class TemproleCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'temprole';
  title = 'Temprole';
  ceProtocol: CEConstructor = CETemprole

  entityService: EOTemproleService;
  soGetClass = SOGetTemprole;
  soGet: SOGetTemprole;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTemproleService
    );
    this.adapters = [
      new RAPostTemprole(this),
      new RAPutTemprole(this),
      new RADeleteTemprole(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTemprole();
    this.soGet.queryOptions.limit = this.limit;
  }


}