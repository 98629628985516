import { Type } from 'class-transformer';
import { SORespCalendar } from '../calendar/so-resp-calendar';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDistribution implements ObjectiveProtocol {

  entityType: string = 'distribution';
  uid: string;
  name: string;
  type: string = "normal";
  marketIndexId: string = null;
  conversionRate: number = 0;
  galToWeight: number = 0;
  defaultSlotsPerDay: number = 6;
  providerSlotDurationHours: number = 4;
  customerSlotDurationHours: number = 2;
  showSlotDuration: boolean = true;
  pickupInstructions: string = null;
  paymentInstructions: string = null;
  checkoutText: string = null;
  purchaseCompleteText: string = null;
  bolHeader: string = "";
  bolWarning: string = "";
  loadingChecklist: string = "";
  departureChecklist: string = "";
  loadNumberPrefix: string = "LD";
  loadNumberStart: number = 0;
  nextLoadNumber: number = 1;
  invoicePocText: string = null;
  invoicePaymentDetailText: string = null;
  invoiceNumberPrefix: string = null;
  @Type(() => SORespCalendar)
  calendars: SORespCalendar[] = [];
}