import {SORespTank} from '../../core/tank/so-resp-tank';
import {TankCore} from './tank.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTankFormFields} from './put-tank-form-fields';
import { CETankAttributes } from './ce-tank-attributes'


export class CETankBase extends CEBase {

    public details: CETankAttributes;
    public fromFields: PutTankFormFields = new PutTankFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TankCore,
        public entity: SORespTank,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETankAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.units)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.capacity)
        this.attributes.push(this.details.average)
        this.attributes.push(this.details.value)
        this.attributes.push(this.details.production)
        this.attributes.push(this.details.tankreports)
        this.attributes.push(this.details.linags)

    }
}