import { Type } from 'class-transformer';
import { SORespTempclin } from '../tempclin/so-resp-tempclin';
import { SORespAccount } from '../account/so-resp-account';
import { SORespSale } from '../sale/so-resp-sale';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespBid implements ObjectiveProtocol {

  entityType: string = 'bid';
  uid: string;
  displayName: string;
  description: string = "";
  amount: number;
  @Type(() => SORespTempclin)
  tempclin: SORespTempclin = null;
  status: string = "submitted";
  @Type(() => SORespAccount)
  account: SORespAccount;
  amountTwo: number = null;
  amountThree: number = null;
  @Type(() => SORespSale)
  sale: SORespSale = null;
}