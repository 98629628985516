import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespSale } from '../sale/so-resp-sale';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespSaleaccountasc implements ObjectiveProtocol {

  entityType: string = 'saleaccountasc';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespAccount)
  account: SORespAccount;
  @Type(() => SORespSale)
  sale: SORespSale;
}