import {SOPutMonthclose} from '../../core/monthclose/so-put-monthclose';
import {MonthcloseCoreBase} from './monthclose.core.base';
import {SOGetMonthclose} from '../../core/monthclose/so-get-monthclose';
import {FormField, SelectionOption} from 'lionheartland';
import {putMonthcloseFormFieldOptions} from './put-monthclose-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutMonthclose extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putMonthcloseFormFieldOptions);

  RequestClass = SOPutMonthclose

  constructor(
    public entityCore: MonthcloseCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('status'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}