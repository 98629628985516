import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostChecklistFormFields {

    public body: FormField
    public name: FormField
    public identifier: FormField
    public load: FormField
    public distribution: FormField

    constructor() {

        this.body = new FormField(
          'body',
          'Body',
          null,
          FormFieldType.text,
          true,
        )
        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          true,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostChecklistFormFields()

export const postChecklistFormFieldOptions: {[key: string]: FormField} = {
  body: fields.body,
  name: fields.name,
  identifier: fields.identifier,
  load: fields.load,
  distribution: fields.distribution,
};