import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespPlant implements ObjectiveProtocol {

  entityType: string = 'plant';
  uid: string;
  name: string;
  closed: boolean = null;
  message: string = null;
  timeZone: string;
  timeZoneOffset: number;
  daylightSavingsObserved: boolean = true;
}