import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostAwardFormFields {

    public amount: FormField
    public quantity: FormField
    public loadQuantity: FormField
    public notes: FormField
    public bid: FormField
    public feeschedule: FormField

    constructor() {

        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          true,
        )
        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          true,
        )
        this.loadQuantity = new FormField(
          'loadQuantity',
          'Load quantity',
          null,
          FormFieldType.text,
          true,
        )
        this.notes = new FormField(
          'notes',
          'Notes',
          null,
          FormFieldType.text,
          false,
        )
        this.bid = new FormField(
          'bid',
          'Bid',
          'bid',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.feeschedule = new FormField(
          'feeschedule',
          'Feeschedule',
          'feeschedule',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostAwardFormFields()

export const postAwardFormFieldOptions: {[key: string]: FormField} = {
  amount: fields.amount,
  quantity: fields.quantity,
  loadQuantity: fields.loadQuantity,
  notes: fields.notes,
  bid: fields.bid,
  feeschedule: fields.feeschedule,
};