import {SOPostInquiry} from '../../core/inquiry/so-post-inquiry';
import {InquiryCoreBase} from './inquiry.core.base';
import {SOGetInquiry} from '../../core/inquiry/so-get-inquiry';
import {FormField, SelectionOption} from 'lionheartland';
import {postInquiryFormFieldOptions} from './post-inquiry-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostInquiry extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postInquiryFormFieldOptions);

  RequestClass = SOPostInquiry

  constructor(
    public entityCore: InquiryCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('companyName'),
      this.getFormValueFromIdentifier('givenName'),
      this.getFormValueFromIdentifier('surname'),
      this.getFormValueFromIdentifier('estimatedPurchaseQuantity'),
      this.getFormValueFromIdentifier('preferredEmailAddress'),
      this.getFormValueFromIdentifier('phoneNumber'),
      this.getFormValueFromIdentifier('productInquiryStr'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}