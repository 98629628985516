import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespFee implements ObjectiveProtocol {

  entityType: string = 'fee';
  uid: string;
  displayName: string;
  identifier: string;
  amount: number;
}