import {SORespSlotoption} from '../../core/slotoption/so-resp-slotoption';
import {SlotoptionCore} from './slotoption.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSlotoptionFormFields} from './put-slotoption-form-fields';
import {SOGetSlotoption} from '../../core/slotoption/so-get-slotoption';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CESlotoptionAttributesBase {

    slot: DetailEntry
    agreemonth: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSlotoption,
        app: GasDirectApp,
        formFields: PutSlotoptionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;



        const slotCore = app.makeCore( 'slot');
        slotCore.soGet = SOGetSlot.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        slotCore.soGet.queryOptions.sortKey = 'start';
        let slotCeProtocol;
        if (entity.slot) {
            slotCeProtocol = createCEProtocol(
                slotCore.ceProtocol,
                parentEntityViewSpec,
                slotCore,
                entity.slot,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotCore
        )
        slotCore.title = heading ? heading : slotCore.title
        slotCore.listTitle = 'Slot'

        if (entity.slot) {
            displayStringFactory.reconfigure(
                entity.slot,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        slotCore.configureForDisplay(parentEntityViewSpec)

        this.slot = new DetailEntry(
          heading ? heading : slotCore.listTitle,
          slotCeProtocol ? slotCeProtocol.entityListView.fullHeading : null,
          'slot',
          BaseDetailType.pushedDetail,
          slotCore,
          EditableType.never
        )
        if (entity.slot) {
          this.slot.ceProtocol = slotCeProtocol;
          this.slot.cachedListEntry = slotCore.makeListEntry(parentEntityViewSpec, entity.slot, 0)
          slotCore.selectedEntity = entity.slot;
        }
        this.slot.singleFieldSelect = true

        const agreemonthCore = app.makeCore( 'agreemonth');
        agreemonthCore.soGet = SOGetAgreemonth.construct({
        });
        agreemonthCore.soGet.queryOptions.sortKey = 'month';
        let agreemonthCeProtocol;
        if (entity.agreemonth) {
            agreemonthCeProtocol = createCEProtocol(
                agreemonthCore.ceProtocol,
                parentEntityViewSpec,
                agreemonthCore,
                entity.agreemonth,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreemonthCore
        )
        agreemonthCore.title = heading ? heading : agreemonthCore.title
        agreemonthCore.listTitle = 'Agreemonth'

        if (entity.agreemonth) {
            displayStringFactory.reconfigure(
                entity.agreemonth,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreemonthCore.configureForDisplay(parentEntityViewSpec)

        this.agreemonth = new DetailEntry(
          heading ? heading : agreemonthCore.listTitle,
          agreemonthCeProtocol ? agreemonthCeProtocol.entityListView.fullHeading : null,
          'agreemonth',
          BaseDetailType.pushedDetail,
          agreemonthCore,
          EditableType.never
        )
        if (entity.agreemonth) {
          this.agreemonth.ceProtocol = agreemonthCeProtocol;
          this.agreemonth.cachedListEntry = agreemonthCore.makeListEntry(parentEntityViewSpec, entity.agreemonth, 0)
          agreemonthCore.selectedEntity = entity.agreemonth;
        }
        this.agreemonth.singleFieldSelect = true
    }
}