import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostHestatusFormFields {

    public distributionday: FormField

    constructor() {

        this.distributionday = new FormField(
          'distributionday',
          'Distributionday',
          'distributionday',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostHestatusFormFields()

export const postHestatusFormFieldOptions: {[key: string]: FormField} = {
  distributionday: fields.distributionday,
};