import { SOGetLine } from '../line/so-get-line';


export class SOPutProduct {

  constructor(
    public uid: string,
    public lines: SOGetLine[] = null,
    public name: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutProduct(
      'uid' in params ? params.uid : null,
      'lines' in params ? params.lines : null,
      'name' in params ? params.name : null,
    );
  }
}
