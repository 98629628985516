import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespInvoice implements ObjectiveProtocol {

  entityType: string = 'invoice';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  ref: string;
  total: number;
  @Type(() => SORespAccount)
  account: SORespAccount;
  state: string;
  @Type(() => Date)
  invoiceDate: Date = null;
  @Type(() => Date)
  sentDate: Date = null;
  paymentTerms: string = null;
  productionDate: string;
  contractNumbers: string;
  remittorNumber: string = null;
  additionalText: string = null;
  sent: string;
  paymentStatus: string;
  stripeRef: string = null;
  stripeStatus: string = null;
  customerPocText: string = null;
  xomPaymentInstructions: string = null;
  xomPocText: string = null;
  balanceInitial: number;
  balanceFinal: number;
  paymentsReceivedSinceLastStatement: number;
}