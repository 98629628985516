import {SORespTask} from '../../core/task/so-resp-task';
import {TaskCore} from './task.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTaskFormFields} from './put-task-form-fields';
import {SOGetTask} from '../../core/task/so-get-task';
import {SOGetHemon} from '../../core/hemon/so-get-hemon';
import {SOGetTaskreport} from '../../core/taskreport/so-get-taskreport';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetWeight} from '../../core/weight/so-get-weight';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CETaskAttributesBase {

    hemon: DetailEntry
    dateCreated: DetailEntry
    trailerGone: DetailEntry
    completed: DetailEntry
    taskreport: DetailEntry
    load: DetailEntry
    weight: DetailEntry
    location: DetailEntry
    loadStatus: DetailEntry
    net: DetailEntry
    containersession: DetailEntry
    deltas: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTask,
        app: GasDirectApp,
        formFields: PutTaskFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.dateCreated.listTrailing = true;

        this.trailerGone = new DetailEntry(
            'Trailer gone',
            entity.trailerGone,
            'trailerGone',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.trailerGone
        )

        this.completed = new DetailEntry(
            'Completed',
            entity.completed,
            'completed',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.completed
        )

        this.location = new DetailEntry(
            'Location',
            entity.location,
            'location',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.location
        )

        this.loadStatus = new DetailEntry(
            'Load status',
            entity.loadStatus,
            'loadStatus',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadStatus
        )
        this.loadStatus.listHeadingIndex = 0;

        this.net = new DetailEntry(
            'Net',
            entity.net,
            'net',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const hemonCore = app.makeCore( 'hemon');
        hemonCore.soGet = SOGetHemon.construct({
        });
        let hemonCeProtocol;
        if (entity.hemon) {
            hemonCeProtocol = createCEProtocol(
                hemonCore.ceProtocol,
                parentEntityViewSpec,
                hemonCore,
                entity.hemon,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hemonCore
        )
        hemonCore.title = heading ? heading : hemonCore.title
        hemonCore.listTitle = 'Hemon'

        if (entity.hemon) {
            displayStringFactory.reconfigure(
                entity.hemon,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        hemonCore.configureForDisplay(parentEntityViewSpec)

        this.hemon = new DetailEntry(
          heading ? heading : hemonCore.listTitle,
          hemonCeProtocol ? hemonCeProtocol.entityListView.fullHeading : null,
          'hemon',
          BaseDetailType.pushedDetail,
          hemonCore,
          EditableType.never
        )
        if (entity.hemon) {
          this.hemon.ceProtocol = hemonCeProtocol;
          this.hemon.cachedListEntry = hemonCore.makeListEntry(parentEntityViewSpec, entity.hemon, 0)
          hemonCore.selectedEntity = entity.hemon;
        }
        this.hemon.singleFieldSelect = true

        const taskreportCore = app.makeCore( 'taskreport');
        taskreportCore.soGet = SOGetTaskreport.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            taskreportCore
        )
        taskreportCore.title = heading ? heading : taskreportCore.title
        taskreportCore.listTitle = 'Taskreport'

        taskreportCore.configureForDisplay(parentEntityViewSpec)

        this.taskreport = new DetailEntry(
          heading ? heading : taskreportCore.listTitle,
          null,
          'taskreport',
          BaseDetailType.pushedDetail,
          taskreportCore,
          EditableType.never
        )
        this.taskreport.singleFieldSelect = true

        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        let loadCeProtocol;
        if (entity.load) {
            loadCeProtocol = createCEProtocol(
                loadCore.ceProtocol,
                parentEntityViewSpec,
                loadCore,
                entity.load,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        if (entity.load) {
            displayStringFactory.reconfigure(
                entity.load,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          loadCeProtocol ? loadCeProtocol.entityListView.fullHeading : null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.never
        )
        if (entity.load) {
          this.load.ceProtocol = loadCeProtocol;
          this.load.cachedListEntry = loadCore.makeListEntry(parentEntityViewSpec, entity.load, 0)
          loadCore.selectedEntity = entity.load;
        }
        this.load.singleFieldSelect = true

        const weightCore = app.makeCore( 'weight');
        weightCore.soGet = SOGetWeight.construct({
        });
        let weightCeProtocol;
        if (entity.weight) {
            weightCeProtocol = createCEProtocol(
                weightCore.ceProtocol,
                parentEntityViewSpec,
                weightCore,
                entity.weight,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            weightCore
        )
        weightCore.title = heading ? heading : weightCore.title
        weightCore.listTitle = 'Weight'

        if (entity.weight) {
            displayStringFactory.reconfigure(
                entity.weight,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        weightCore.configureForDisplay(parentEntityViewSpec)

        this.weight = new DetailEntry(
          heading ? heading : weightCore.listTitle,
          weightCeProtocol ? weightCeProtocol.entityListView.fullHeading : null,
          'weight',
          BaseDetailType.pushedDetail,
          weightCore,
          EditableType.always,
          formFields.weight
        )
        if (entity.weight) {
          this.weight.ceProtocol = weightCeProtocol;
          this.weight.cachedListEntry = weightCore.makeListEntry(parentEntityViewSpec, entity.weight, 0)
          weightCore.selectedEntity = entity.weight;
        }
        this.weight.singleFieldSelect = true

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        let containersessionCeProtocol;
        if (entity.containersession) {
            containersessionCeProtocol = createCEProtocol(
                containersessionCore.ceProtocol,
                parentEntityViewSpec,
                containersessionCore,
                entity.containersession,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        if (entity.containersession) {
            displayStringFactory.reconfigure(
                entity.containersession,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          containersessionCeProtocol ? containersessionCeProtocol.entityListView.fullHeading : null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        if (entity.containersession) {
          this.containersession.ceProtocol = containersessionCeProtocol;
          this.containersession.cachedListEntry = containersessionCore.makeListEntry(parentEntityViewSpec, entity.containersession, 0)
          containersessionCore.selectedEntity = entity.containersession;
        }
        this.containersession.singleFieldSelect = true

        const deltasCore = app.makeCore( 'delta');
        deltasCore.soGet = SOGetDelta.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deltasCore
        )
        deltasCore.title = heading ? heading : deltasCore.title
        deltasCore.listTitle = 'Deltas'

        deltasCore.configureForDisplay(parentEntityViewSpec)

        this.deltas = new DetailEntry(
          heading ? heading : deltasCore.listTitle,
          null,
          'deltas',
          BaseDetailType.embeddedList,
          deltasCore,
          EditableType.never
        )
    }
}