import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOCustomerService} from '../../core/customer/eo-customer';
import {SOGetCustomer} from '../../core/customer/so-get-customer';
import {RAPutCustomer} from './ra-put-customer';
import {RAPostCustomer} from './ra-post-customer';
import {RADeleteCustomer} from './ra-delete-customer';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CECustomer } from "./ce-customer"


export class CustomerCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'customer';
  title = 'Customer';
  ceProtocol: CEConstructor = CECustomer

  entityService: EOCustomerService;
  soGetClass = SOGetCustomer;
  soGet: SOGetCustomer;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOCustomerService
    );
    this.adapters = [
      new RAPostCustomer(this),
      new RAPutCustomer(this),
      new RADeleteCustomer(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCustomer();
    this.soGet.queryOptions.limit = this.limit;
  }


}