import { Type } from 'class-transformer';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespMonthclose implements ObjectiveProtocol {

  entityType: string = 'monthclose';
  uid: string;
  @Type(() => Date)
  month: Date;
  status: string;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
}