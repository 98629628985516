import {SORespAgreeyear} from '../../core/agreeyear/so-resp-agreeyear';
import {AgreeyearCore} from './agreeyear.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAgreeyearFormFields} from './put-agreeyear-form-fields';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetRateableagreeyearallocation} from '../../core/rateableagreeyearallocation/so-get-rateableagreeyearallocation';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEAgreeyearAttributesBase {

    state: DetailEntry
    acq: DetailEntry
    acqUsed: DetailEntry
    year: DetailEntry
    hyear: DetailEntry
    agreement: DetailEntry
    spotQuantity: DetailEntry
    spotQuantityUsed: DetailEntry
    distributionyear: DetailEntry
    feeschedule: DetailEntry
    hasPricing: DetailEntry
    hasFeeschedule: DetailEntry
    acqInitial: DetailEntry
    agreemonths: DetailEntry
    prices: DetailEntry
    rateableagreeyearallocations: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAgreeyear,
        app: GasDirectApp,
        formFields: PutAgreeyearFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.acq = new DetailEntry(
            'Acq',
            entity.acq,
            'acq',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.acq
        )

        this.acqUsed = new DetailEntry(
            'Acq used',
            entity.acqUsed,
            'acqUsed',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.acqUsed
        )

        this.year = new DetailEntry(
            'Year',
            entity.year,
            'year',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.year.listHeadingIndex = 1;

        this.spotQuantity = new DetailEntry(
            'Spot quantity',
            entity.spotQuantity,
            'spotQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.spotQuantityUsed = new DetailEntry(
            'Spot quantity used',
            entity.spotQuantityUsed,
            'spotQuantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.hasPricing = new DetailEntry(
            'Has pricing',
            entity.hasPricing,
            'hasPricing',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )

        this.hasFeeschedule = new DetailEntry(
            'Has feeschedule',
            entity.hasFeeschedule,
            'hasFeeschedule',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )

        this.acqInitial = new DetailEntry(
            'Acq initial',
            entity.acqInitial,
            'acqInitial',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const hyearCore = app.makeCore( 'hyear');
        hyearCore.soGet = SOGetHyear.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        hyearCore.soGet.queryOptions.sortKey = 'year';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hyearCore
        )
        hyearCore.title = heading ? heading : hyearCore.title
        hyearCore.listTitle = 'Hyear'

        hyearCore.configureForDisplay(parentEntityViewSpec)

        this.hyear = new DetailEntry(
          heading ? heading : hyearCore.listTitle,
          null,
          'hyear',
          BaseDetailType.pushedDetail,
          hyearCore,
          EditableType.always,
          formFields.hyear
        )
        this.hyear.singleFieldSelect = true

        const agreementCore = app.makeCore( 'agreement');
        agreementCore.soGet = SOGetAgreement.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        let agreementCeProtocol;
        if (entity.agreement) {
            agreementCeProtocol = createCEProtocol(
                agreementCore.ceProtocol,
                parentEntityViewSpec,
                agreementCore,
                entity.agreement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementCore
        )
        agreementCore.title = heading ? heading : agreementCore.title
        agreementCore.listTitle = 'Agreement'

        if (entity.agreement) {
            displayStringFactory.reconfigure(
                entity.agreement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreementCore.configureForDisplay(parentEntityViewSpec)

        this.agreement = new DetailEntry(
          heading ? heading : agreementCore.listTitle,
          agreementCeProtocol ? agreementCeProtocol.entityListView.fullHeading : null,
          'agreement',
          BaseDetailType.pushedDetail,
          agreementCore,
          EditableType.always,
          formFields.agreement
        )
        if (entity.agreement) {
          this.agreement.ceProtocol = agreementCeProtocol;
          this.agreement.cachedListEntry = agreementCore.makeListEntry(parentEntityViewSpec, entity.agreement, 0)
          agreementCore.selectedEntity = entity.agreement;
        }
        this.agreement.singleFieldSelect = true
        this.agreement.listHeadingIndex = 0;

        const distributionyearCore = app.makeCore( 'distributionyear');
        distributionyearCore.soGet = SOGetDistributionyear.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionyearCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionyearCore
        )
        distributionyearCore.title = heading ? heading : distributionyearCore.title
        distributionyearCore.listTitle = 'Distributionyear'

        distributionyearCore.configureForDisplay(parentEntityViewSpec)

        this.distributionyear = new DetailEntry(
          heading ? heading : distributionyearCore.listTitle,
          null,
          'distributionyear',
          BaseDetailType.pushedDetail,
          distributionyearCore,
          EditableType.never
        )
        this.distributionyear.singleFieldSelect = true

        const feescheduleCore = app.makeCore( 'feeschedule');
        feescheduleCore.soGet = SOGetFeeschedule.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feescheduleCore
        )
        feescheduleCore.title = heading ? heading : feescheduleCore.title
        feescheduleCore.listTitle = 'Feeschedule'

        feescheduleCore.configureForDisplay(parentEntityViewSpec)

        this.feeschedule = new DetailEntry(
          heading ? heading : feescheduleCore.listTitle,
          null,
          'feeschedule',
          BaseDetailType.pushedDetail,
          feescheduleCore,
          EditableType.never
        )
        this.feeschedule.singleFieldSelect = true

        const agreemonthsCore = app.makeCore( 'agreemonth');
        agreemonthsCore.soGet = SOGetAgreemonth.construct({
          agreeyear: new SOGetAgreeyear(entity.uid),
        });
        agreemonthsCore.soGet.queryOptions.sortKey = 'month';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreemonthsCore
        )
        agreemonthsCore.title = heading ? heading : agreemonthsCore.title
        agreemonthsCore.listTitle = 'Agreemonths'

        agreemonthsCore.configureForDisplay(parentEntityViewSpec)

        this.agreemonths = new DetailEntry(
          heading ? heading : agreemonthsCore.listTitle,
          null,
          'agreemonths',
          BaseDetailType.embeddedList,
          agreemonthsCore,
          EditableType.never
        )

        const pricesCore = app.makeCore( 'price');
        pricesCore.soGet = SOGetPrice.construct({
          agreeyear: new SOGetAgreeyear(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            pricesCore
        )
        pricesCore.title = heading ? heading : pricesCore.title
        pricesCore.listTitle = 'Prices'

        pricesCore.configureForDisplay(parentEntityViewSpec)

        this.prices = new DetailEntry(
          heading ? heading : pricesCore.listTitle,
          null,
          'prices',
          BaseDetailType.embeddedList,
          pricesCore,
          EditableType.never
        )

        const rateableagreeyearallocationsCore = app.makeCore( 'rateableagreeyearallocation');
        rateableagreeyearallocationsCore.soGet = SOGetRateableagreeyearallocation.construct({
          agreeyear: new SOGetAgreeyear(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rateableagreeyearallocationsCore
        )
        rateableagreeyearallocationsCore.title = heading ? heading : rateableagreeyearallocationsCore.title
        rateableagreeyearallocationsCore.listTitle = 'Rateableagreeyearallocations'

        rateableagreeyearallocationsCore.configureForDisplay(parentEntityViewSpec)

        this.rateableagreeyearallocations = new DetailEntry(
          heading ? heading : rateableagreeyearallocationsCore.listTitle,
          null,
          'rateableagreeyearallocations',
          BaseDetailType.embeddedList,
          rateableagreeyearallocationsCore,
          EditableType.never
        )
    }
}