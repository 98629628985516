

export class SOPutSale {

  constructor(
    public uid: string,
    public status: string = null,
    public description: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSale(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
      'description' in params ? params.description : null,
    );
  }
}
