import {SOPutBid} from '../../core/bid/so-put-bid';
import {BidCoreBase} from './bid.core.base';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {FormField, SelectionOption} from 'lionheartland';
import {putBidFormFieldOptions} from './put-bid-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutBid extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putBidFormFieldOptions);

  RequestClass = SOPutBid

  constructor(
    public entityCore: BidCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('displayName'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('amountTwo'),
      this.getFormValueFromIdentifier('amountThree'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}