import { SOGetDistributionday } from '../distributionday/so-get-distributionday';


export class SOPostHestatus {

  constructor(
    public distributionday: SOGetDistributionday,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostHestatus(
      'distributionday' in params ? params.distributionday : null,
    );
  }
}
