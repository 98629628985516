import { SOGetLine } from '../line/so-get-line';


export class SOPutProduction {

  constructor(
    public uid: string,
    public name: string = null,
    public dailyRate: number = null,
    public dailyRateUnits: string = null,
    public line: SOGetLine = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutProduction(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'dailyRate' in params ? params.dailyRate : null,
      'dailyRateUnits' in params ? params.dailyRateUnits : null,
      'line' in params ? params.line : null,
    );
  }
}
