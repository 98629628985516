import {SOPostAward} from '../../core/award/so-post-award';
import {AwardCoreBase} from './award.core.base';
import {SOGetAward} from '../../core/award/so-get-award';
import {FormField, SelectionOption} from 'lionheartland';
import {postAwardFormFieldOptions} from './post-award-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {BidCore} from '../bid/bid.core';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {FeescheduleCore} from '../feeschedule/feeschedule.core';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';

export class RAPostAward extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAwardFormFieldOptions);

  RequestClass = SOPostAward

  constructor(
    public entityCore: AwardCoreBase
  ) {
    super();
    if (postAwardFormFieldOptions.bid) {
      postAwardFormFieldOptions.bid.makeCore = true;
    }
    if (postAwardFormFieldOptions.feeschedule) {
      postAwardFormFieldOptions.feeschedule.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier('loadQuantity'),
      this.getFormValueFromIdentifier(
        'bid',
        SOGetBid,
      ),
      this.getFormValueFromIdentifier('notes'),
      this.getFormValueFromIdentifier(
        'feeschedule',
        SOGetFeeschedule,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}