import {SORespTransaction} from '../../core/transaction/so-resp-transaction';
import {TransactionCore} from './transaction.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTransactionFormFields} from './put-transaction-form-fields';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';


export class CETransactionAttributesBase {

    dateCreated: DetailEntry
    description: DetailEntry
    amount: DetailEntry
    balance: DetailEntry
    account: DetailEntry
    order: DetailEntry
    load: DetailEntry
    finalLoadCost: DetailEntry
    reconciliationAdjustment: DetailEntry
    transactionType: DetailEntry
    invoice: DetailEntry
    distributionday: DetailEntry
    distributionmonth: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTransaction,
        app: GasDirectApp,
        formFields: PutTransactionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateCreated
        )
        this.dateCreated.listTrailing = true;

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )
        this.description.listHeadingIndex = 0;

        this.amount = new DetailEntry(
            'Amount',
            entity.amount,
            'amount',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amount
        )
        this.amount.listHeadingIndex = 1;

        this.balance = new DetailEntry(
            'Balance',
            entity.balance,
            'balance',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.balance.listHeadingIndex = 2;

        this.finalLoadCost = new DetailEntry(
            'Final load cost',
            entity.finalLoadCost,
            'finalLoadCost',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )

        this.reconciliationAdjustment = new DetailEntry(
            'Reconciliation adjustment',
            entity.reconciliationAdjustment,
            'reconciliationAdjustment',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )

        this.transactionType = new DetailEntry(
            'Transaction type',
            entity.transactionType,
            'transactionType',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.never
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const orderCore = app.makeCore( 'order');
        orderCore.soGet = SOGetOrder.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreement: app.getScope('agreement') ? new SOGetAgreement(app.getScope('agreement').uid) : null,
          agreemonth: app.getScope('agreemonth') ? new SOGetAgreemonth(app.getScope('agreemonth').uid) : null,
          distributionmonth: app.getScope('distributionmonth') ? new SOGetDistributionmonth(app.getScope('distributionmonth').uid) : null,
        });
        let orderCeProtocol;
        if (entity.order) {
            orderCeProtocol = createCEProtocol(
                orderCore.ceProtocol,
                parentEntityViewSpec,
                orderCore,
                entity.order,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            orderCore
        )
        orderCore.title = heading ? heading : orderCore.title
        orderCore.listTitle = 'Order'

        if (entity.order) {
            displayStringFactory.reconfigure(
                entity.order,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        orderCore.configureForDisplay(parentEntityViewSpec)

        this.order = new DetailEntry(
          heading ? heading : orderCore.listTitle,
          orderCeProtocol ? orderCeProtocol.entityListView.fullHeading : null,
          'order',
          BaseDetailType.pushedDetail,
          orderCore,
          EditableType.never
        )
        if (entity.order) {
          this.order.ceProtocol = orderCeProtocol;
          this.order.cachedListEntry = orderCore.makeListEntry(parentEntityViewSpec, entity.order, 0)
          orderCore.selectedEntity = entity.order;
        }
        this.order.singleFieldSelect = true

        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        let loadCeProtocol;
        if (entity.load) {
            loadCeProtocol = createCEProtocol(
                loadCore.ceProtocol,
                parentEntityViewSpec,
                loadCore,
                entity.load,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        if (entity.load) {
            displayStringFactory.reconfigure(
                entity.load,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          loadCeProtocol ? loadCeProtocol.entityListView.fullHeading : null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.never
        )
        if (entity.load) {
          this.load.ceProtocol = loadCeProtocol;
          this.load.cachedListEntry = loadCore.makeListEntry(parentEntityViewSpec, entity.load, 0)
          loadCore.selectedEntity = entity.load;
        }
        this.load.singleFieldSelect = true

        const invoiceCore = app.makeCore( 'invoice');
        invoiceCore.soGet = SOGetInvoice.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        let invoiceCeProtocol;
        if (entity.invoice) {
            invoiceCeProtocol = createCEProtocol(
                invoiceCore.ceProtocol,
                parentEntityViewSpec,
                invoiceCore,
                entity.invoice,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoiceCore
        )
        invoiceCore.title = heading ? heading : invoiceCore.title
        invoiceCore.listTitle = 'Invoice'

        if (entity.invoice) {
            displayStringFactory.reconfigure(
                entity.invoice,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        invoiceCore.configureForDisplay(parentEntityViewSpec)

        this.invoice = new DetailEntry(
          heading ? heading : invoiceCore.listTitle,
          invoiceCeProtocol ? invoiceCeProtocol.entityListView.fullHeading : null,
          'invoice',
          BaseDetailType.pushedDetail,
          invoiceCore,
          EditableType.always,
          formFields.invoice
        )
        if (entity.invoice) {
          this.invoice.ceProtocol = invoiceCeProtocol;
          this.invoice.cachedListEntry = invoiceCore.makeListEntry(parentEntityViewSpec, entity.invoice, 0)
          invoiceCore.selectedEntity = entity.invoice;
        }
        this.invoice.singleFieldSelect = true

        const distributiondayCore = app.makeCore( 'distributionday');
        distributiondayCore.soGet = SOGetDistributionday.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributiondayCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributiondayCore
        )
        distributiondayCore.title = heading ? heading : distributiondayCore.title
        distributiondayCore.listTitle = 'Distributionday'

        distributiondayCore.configureForDisplay(parentEntityViewSpec)

        this.distributionday = new DetailEntry(
          heading ? heading : distributiondayCore.listTitle,
          null,
          'distributionday',
          BaseDetailType.pushedDetail,
          distributiondayCore,
          EditableType.never
        )
        this.distributionday.singleFieldSelect = true

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true
    }
}