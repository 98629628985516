

export class SOPutInvoice {

  constructor(
    public uid: string,
    public state: string = null,
    public invoiceDate: Date = null,
    public sentDate: Date = null,
    public sent: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutInvoice(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'invoiceDate' in params ? params.invoiceDate : null,
      'sentDate' in params ? params.sentDate : null,
      'sent' in params ? params.sent : null,
    );
  }
}
