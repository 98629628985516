import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOOrgrolaService} from '../../core/orgrola/eo-orgrola';
import {SOGetOrgrola} from '../../core/orgrola/so-get-orgrola';
import {RAPutOrgrola} from './ra-put-orgrola';
import {RAPostOrgrola} from './ra-post-orgrola';
import {RADeleteOrgrola} from './ra-delete-orgrola';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEOrgrola } from "./ce-orgrola"


export class OrgrolaCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'orgrola';
  title = 'Orgrola';
  ceProtocol: CEConstructor = CEOrgrola

  entityService: EOOrgrolaService;
  soGetClass = SOGetOrgrola;
  soGet: SOGetOrgrola;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOOrgrolaService
    );
    this.adapters = [
      new RAPostOrgrola(this),
      new RAPutOrgrola(this),
      new RADeleteOrgrola(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetOrgrola();
    this.soGet.queryOptions.limit = this.limit;
  }


}