import { Type } from 'class-transformer';
import { SORespInvoice } from '../invoice/so-resp-invoice';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespInvoiceitem implements ObjectiveProtocol {

  entityType: string = 'invoiceitem';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  description: string;
  quantity: number = null;
  unitCost: number = null;
  total: number = null;
  @Type(() => SORespInvoice)
  invoice: SORespInvoice;
  code: string = null;
  codeDefinition: string = null;
  units: string = null;
  baseUnits: string = null;
  baseQuantity: number = null;
  conversion: number = null;
}