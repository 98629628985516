import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOHelloService} from '../../core/hello/eo-hello';
import {SOGetHello} from '../../core/hello/so-get-hello';
import {RAPutHello} from './ra-put-hello';
import {RAPostHello} from './ra-post-hello';
import {RADeleteHello} from './ra-delete-hello';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEHello } from "./ce-hello"


export class HelloCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'hello';
  title = 'Hello';
  ceProtocol: CEConstructor = CEHello

  entityService: EOHelloService;
  soGetClass = SOGetHello;
  soGet: SOGetHello;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOHelloService
    );
    this.adapters = [
      new RAPostHello(this),
      new RAPutHello(this),
      new RADeleteHello(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetHello();
    this.soGet.queryOptions.limit = this.limit;
  }


}