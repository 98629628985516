import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutLineFormFields {

    public name: FormField
    public plant: FormField
    public product: FormField
    public units: FormField
    public production: FormField
    public distribution: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.plant = new FormField(
          'plant',
          'Plant',
          'plant',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.product = new FormField(
          'product',
          'Product',
          'product',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.units = new FormField(
          'units',
          'Units',
          null,
          FormFieldType.text,
          false,
        )
        this.production = new FormField(
          'production',
          'Production',
          'production',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutLineFormFields()

export const putLineFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  plant: fields.plant,
  product: fields.product,
  units: fields.units,
  production: fields.production,
  distribution: fields.distribution,
};