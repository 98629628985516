import { Type } from 'class-transformer';
import { SORespPlant } from '../plant/so-resp-plant';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import { SORespAccount } from '../account/so-resp-account';
import { SORespCarrier } from '../carrier/so-resp-carrier';
import { SORespNavroa } from '../navroa/so-resp-navroa';
import { SORespOrgrola } from '../orgrola/so-resp-orgrola';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespRole implements ObjectiveProtocol {

  entityType: string = 'role';
  uid: string;
  name: string;
  identifier: string;
  @Type(() => SORespPlant)
  plant: SORespPlant = null;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution = null;
  @Type(() => SORespAccount)
  account: SORespAccount = null;
  @Type(() => SORespCarrier)
  carrier: SORespCarrier = null;
  @Type(() => SORespNavroa)
  navroas: SORespNavroa[] = [];
  @Type(() => SORespOrgrola)
  orgrolas: SORespOrgrola[] = [];
}