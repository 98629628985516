import { Type } from 'class-transformer';
import { SORespDistributionday } from '../distributionday/so-resp-distributionday';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespHestatus implements ObjectiveProtocol {

  entityType: string = 'hestatus';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  state: string;
  @Type(() => SORespDistributionday)
  distributionday: SORespDistributionday = null;
}