import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostGrantFormFields {

    public role: FormField
    public user: FormField

    constructor() {

        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.autoCompleteList,
          true,
        )

    }
}

const fields = new PostGrantFormFields()

export const postGrantFormFieldOptions: {[key: string]: FormField} = {
  role: fields.role,
  user: fields.user,
};