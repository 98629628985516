import { SOGetTempclin } from '../tempclin/so-get-tempclin';


export class SOPutTemprice {

  constructor(
    public uid: string,
    public rate: number = null,
    public quantity: number = null,
    public loadQuantity: number = null,
    public rank: number = null,
    public tempclin: SOGetTempclin = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTemprice(
      'uid' in params ? params.uid : null,
      'rate' in params ? params.rate : null,
      'quantity' in params ? params.quantity : null,
      'loadQuantity' in params ? params.loadQuantity : null,
      'rank' in params ? params.rank : null,
      'tempclin' in params ? params.tempclin : null,
    );
  }
}
