import {SORespTag} from '../../core/tag/so-resp-tag';
import {TagCore} from './tag.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTagFormFields} from './put-tag-form-fields';
import { CETagAttributes } from './ce-tag-attributes'


export class CETagBase extends CEBase {

    public details: CETagAttributes;
    public fromFields: PutTagFormFields = new PutTagFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TagCore,
        public entity: SORespTag,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETagAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.dcs)
        this.attributes.push(this.details.linags)
        this.attributes.push(this.details.tagreports)

    }
}