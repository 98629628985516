import {SORespNavtemrola} from '../../core/navtemrola/so-resp-navtemrola';
import {NavtemrolaCore} from './navtemrola.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutNavtemrolaFormFields} from './put-navtemrola-form-fields';
import { CENavtemrolaAttributes } from './ce-navtemrola-attributes'


export class CENavtemrolaBase extends CEBase {

    public details: CENavtemrolaAttributes;
    public fromFields: PutNavtemrolaFormFields = new PutNavtemrolaFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: NavtemrolaCore,
        public entity: SORespNavtemrola,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CENavtemrolaAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.index)
        this.attributes.push(this.details.mainNav)
        this.attributes.push(this.details.temprole)
        this.attributes.push(this.details.navi)

    }
}