import {SORespProduct} from '../../core/product/so-resp-product';
import {ProductCore} from './product.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutProductFormFields} from './put-product-form-fields';
import {SOGetProduct} from '../../core/product/so-get-product';
import {SOGetLine} from '../../core/line/so-get-line';


export class CEProductAttributesBase {

    lines: DetailEntry
    name: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespProduct,
        app: GasDirectApp,
        formFields: PutProductFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )


        const linesCore = app.makeCore( 'line');
        linesCore.soGet = SOGetLine.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            linesCore
        )
        linesCore.title = heading ? heading : linesCore.title
        linesCore.listTitle = 'Lines'

        linesCore.configureForDisplay(parentEntityViewSpec)

        this.lines = new DetailEntry(
          heading ? heading : linesCore.listTitle,
          null,
          'lines',
          BaseDetailType.embeddedList,
          linesCore,
          EditableType.always,
          formFields.lines
        )
    }
}