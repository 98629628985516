import { SOGetPlant } from '../plant/so-get-plant';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetLine implements QueryProtocol {

  constructor(
    public uid: string = null,
    public plant: SOGetPlant = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetLine(
      'uid' in params ? params.uid : null,
      'plant' in params ? params.plant : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
