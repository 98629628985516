import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTrailer } from './so-put-trailer';
import { SOGetTrailer } from './so-get-trailer';
import { SOPostTrailer } from './so-post-trailer';
import { SODeleteTrailer } from './so-delete-trailer';
import {SORespTrailer} from './so-resp-trailer';
import {SOPluralTrailer} from './so-plural-trailer';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTrailerService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'trailer',
      entityCore
    );
  }

  /** GET Trailer by id. Will 404 if id not found */
  get(request: SOGetTrailer): Observable<SOPluralTrailer> {
    return this.httpClient.get<SOPluralTrailer>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTrailer, resp) }),
      tap(_ => this.log(`fetched Trailer`)),
      catchError(this.handleError<SOPluralTrailer>(`get Trailer`))
    );
  }

  /** POST: add a new Trailer to the server */
  post(request: SOPostTrailer): Observable<SORespTrailer> {
    return this.httpClient.post<SORespTrailer>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTrailer, resp) }),
      tap((response: SORespTrailer) => this.log(`added Trailer w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTrailer>('Trailer post'))
    );
  }

  /** PUT: update Trailer  */
  put(request: SOPutTrailer): Observable<SORespTrailer> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTrailer>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTrailer, resp) }),
      tap((response: SORespTrailer) => this.log(`edited Trailer w/ uid=${uid}`)),
      catchError(this.handleError<SORespTrailer>('Trailer put'))
    );
  }

  /** DELETE: delete the Trailer from the server */
  delete(request: SODeleteTrailer): Observable<SORespTrailer> {
    return this.httpClient.delete<SORespTrailer>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Trailer uid=${request.uid}`)),
      catchError(this.handleError<SORespTrailer>('delete Trailer'))
    );
  }
}
