import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTrailer implements ObjectiveProtocol {

  entityType: string = 'trailer';
  uid: string;
  chassisId: string = null;
  identifier: string;
  type: string;
  size: number;
  dotId: string = null;
  @Type(() => Date)
  dotInspectionDate: Date = null;
  dotE: string = null;
  dotSp: string = null;
  mawp: string = null;
  @Type(() => SORespOrganization)
  organization: SORespOrganization = null;
  attachment: string = null;
  linFirst: boolean = null;
  averageEmptyWeight: number = null;
  loadCount: number = null;
  fillVolume: number = null;
  mawt: number = null;
}