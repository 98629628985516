import {SOPutOrder} from '../../core/order/so-put-order';
import {OrderCoreBase} from './order.core.base';
import {SOGetOrder} from '../../core/order/so-get-order';
import {FormField, SelectionOption} from 'lionheartland';
import {putOrderFormFieldOptions} from './put-order-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {AgreementCore} from '../agreement/agreement.core';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';

export class RAPutOrder extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putOrderFormFieldOptions);

  RequestClass = SOPutOrder

  constructor(
    public entityCore: OrderCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier(
        'agreement',
        SOGetAgreement,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}