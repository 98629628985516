import {SORespDeltaweight} from '../../core/deltaweight/so-resp-deltaweight';
import {DeltaweightCore} from './deltaweight.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDeltaweightFormFields} from './put-deltaweight-form-fields';
import {SOGetDeltaweight} from '../../core/deltaweight/so-get-deltaweight';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {SOGetWeight} from '../../core/weight/so-get-weight';


export class CEDeltaweightAttributesBase {

    initial: DetailEntry
    delta: DetailEntry
    weight: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDeltaweight,
        app: GasDirectApp,
        formFields: PutDeltaweightFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.initial = new DetailEntry(
            'Initial',
            entity.initial,
            'initial',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )


        const deltaCore = app.makeCore( 'delta');
        deltaCore.soGet = SOGetDelta.construct({
        });
        let deltaCeProtocol;
        if (entity.delta) {
            deltaCeProtocol = createCEProtocol(
                deltaCore.ceProtocol,
                parentEntityViewSpec,
                deltaCore,
                entity.delta,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deltaCore
        )
        deltaCore.title = heading ? heading : deltaCore.title
        deltaCore.listTitle = 'Delta'

        if (entity.delta) {
            displayStringFactory.reconfigure(
                entity.delta,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        deltaCore.configureForDisplay(parentEntityViewSpec)

        this.delta = new DetailEntry(
          heading ? heading : deltaCore.listTitle,
          deltaCeProtocol ? deltaCeProtocol.entityListView.fullHeading : null,
          'delta',
          BaseDetailType.pushedDetail,
          deltaCore,
          EditableType.never
        )
        if (entity.delta) {
          this.delta.ceProtocol = deltaCeProtocol;
          this.delta.cachedListEntry = deltaCore.makeListEntry(parentEntityViewSpec, entity.delta, 0)
          deltaCore.selectedEntity = entity.delta;
        }
        this.delta.singleFieldSelect = true

        const weightCore = app.makeCore( 'weight');
        weightCore.soGet = SOGetWeight.construct({
        });
        let weightCeProtocol;
        if (entity.weight) {
            weightCeProtocol = createCEProtocol(
                weightCore.ceProtocol,
                parentEntityViewSpec,
                weightCore,
                entity.weight,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            weightCore
        )
        weightCore.title = heading ? heading : weightCore.title
        weightCore.listTitle = 'Weight'

        if (entity.weight) {
            displayStringFactory.reconfigure(
                entity.weight,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        weightCore.configureForDisplay(parentEntityViewSpec)

        this.weight = new DetailEntry(
          heading ? heading : weightCore.listTitle,
          weightCeProtocol ? weightCeProtocol.entityListView.fullHeading : null,
          'weight',
          BaseDetailType.pushedDetail,
          weightCore,
          EditableType.never
        )
        if (entity.weight) {
          this.weight.ceProtocol = weightCeProtocol;
          this.weight.cachedListEntry = weightCore.makeListEntry(parentEntityViewSpec, entity.weight, 0)
          weightCore.selectedEntity = entity.weight;
        }
        this.weight.singleFieldSelect = true
    }
}