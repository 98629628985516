import { SOGetContract } from '../contract/so-get-contract';


export class SOPostClinyearlng {

  constructor(
    public name: string,
    public start: Date,
    public period: string,
    public periodNumber: number,
    public volumeTier1: number,
    public priceTier1: number,
    public contract: SOGetContract,
    public volumeTier2: number = null,
    public priceTier2: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostClinyearlng(
      'name' in params ? params.name : null,
      'start' in params ? params.start : null,
      'period' in params ? params.period : null,
      'periodNumber' in params ? params.periodNumber : null,
      'volumeTier1' in params ? params.volumeTier1 : null,
      'priceTier1' in params ? params.priceTier1 : null,
      'volumeTier2' in params ? params.volumeTier2 : null,
      'priceTier2' in params ? params.priceTier2 : null,
      'contract' in params ? params.contract : null,
    );
  }
}
