

export class SOPostOffering {

  constructor(
    public name: string,
    public units: string,
    public size: number,
    public description: string = "",
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostOffering(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'units' in params ? params.units : null,
      'size' in params ? params.size : null,
    );
  }
}
