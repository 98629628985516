

export class SOPutTemptract {

  constructor(
    public uid: string,
    public name: string = null,
    public durationDays: number = null,
    public takeOrPay: number = null,
    public minLoadSizeToQualify: number = null,
    public type: string = null,
    public docusignLink: string = null,
    public description: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTemptract(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'durationDays' in params ? params.durationDays : null,
      'takeOrPay' in params ? params.takeOrPay : null,
      'minLoadSizeToQualify' in params ? params.minLoadSizeToQualify : null,
      'type' in params ? params.type : null,
      'docusignLink' in params ? params.docusignLink : null,
      'description' in params ? params.description : null,
    );
  }
}
