import { SOGetSubscription } from '../subscription/so-get-subscription';
import { SOGetPublication } from '../publication/so-get-publication';
import { SOGetUser } from '../user/so-get-user';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetMessage implements QueryProtocol {

  constructor(
    public uid: string = null,
    public read: boolean = null,
    public subscription: SOGetSubscription = null,
    public publication: SOGetPublication = null,
    public user: SOGetUser = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetMessage(
      'uid' in params ? params.uid : null,
      'read' in params ? params.read : null,
      'subscription' in params ? params.subscription : null,
      'publication' in params ? params.publication : null,
      'user' in params ? params.user : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
