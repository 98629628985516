import {SORespAgreement} from '../../core/agreement/so-resp-agreement';
import {AgreementCore} from './agreement.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAgreementFormFields} from './put-agreement-form-fields';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetContract} from '../../core/contract/so-get-contract';
import {SOGetFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-get-feescheduleagreementasc';
import {SOGetAward} from '../../core/award/so-get-award';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEAgreementAttributesBase {

    orders: DetailEntry
    loads: DetailEntry
    name: DetailEntry
    description: DetailEntry
    docusignLink: DetailEntry
    units: DetailEntry
    signedDocumentUrl: DetailEntry
    state: DetailEntry
    start: DetailEntry
    end: DetailEntry
    autoRenew: DetailEntry
    tempagreement: DetailEntry
    account: DetailEntry
    acq: DetailEntry
    top: DetailEntry
    feeschedule: DetailEntry
    variableAcq: DetailEntry
    rateable: DetailEntry
    interruptible: DetailEntry
    spotQuantity: DetailEntry
    prePaymentRequired: DetailEntry
    sapContractNumber: DetailEntry
    agreeyears: DetailEntry
    agreemonths: DetailEntry
    contracts: DetailEntry
    feescheduleagreementascs: DetailEntry
    award: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAgreement,
        app: GasDirectApp,
        formFields: PutAgreementFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )
        this.name.listHeadingIndex = 0;

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.docusignLink = new DetailEntry(
            'Docusign link',
            entity.docusignLink,
            'docusignLink',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.docusignLink
        )

        this.units = new DetailEntry(
            'Units',
            entity.units,
            'units',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.units
        )

        this.signedDocumentUrl = new DetailEntry(
            'Signed document url',
            entity.signedDocumentUrl,
            'signedDocumentUrl',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )

        this.autoRenew = new DetailEntry(
            'Auto renew',
            entity.autoRenew,
            'autoRenew',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.autoRenew
        )

        this.acq = new DetailEntry(
            'Acq',
            entity.acq,
            'acq',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.acq
        )

        this.top = new DetailEntry(
            'Top',
            entity.top,
            'top',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.top
        )

        this.variableAcq = new DetailEntry(
            'Variable acq',
            entity.variableAcq,
            'variableAcq',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.variableAcq
        )

        this.rateable = new DetailEntry(
            'Rateable',
            entity.rateable,
            'rateable',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.rateable
        )

        this.interruptible = new DetailEntry(
            'Interruptible',
            entity.interruptible,
            'interruptible',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )

        this.spotQuantity = new DetailEntry(
            'Spot quantity',
            entity.spotQuantity,
            'spotQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.prePaymentRequired = new DetailEntry(
            'Pre payment required',
            entity.prePaymentRequired,
            'prePaymentRequired',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.prePaymentRequired
        )

        this.sapContractNumber = new DetailEntry(
            'Sap contract number',
            entity.sapContractNumber,
            'sapContractNumber',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.sapContractNumber
        )


        const ordersCore = app.makeCore( 'order');
        ordersCore.soGet = SOGetOrder.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreement: new SOGetAgreement(entity.uid),
          agreemonth: app.getScope('agreemonth') ? new SOGetAgreemonth(app.getScope('agreemonth').uid) : null,
          distributionmonth: app.getScope('distributionmonth') ? new SOGetDistributionmonth(app.getScope('distributionmonth').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            ordersCore
        )
        ordersCore.title = heading ? heading : ordersCore.title
        ordersCore.listTitle = 'Orders'

        ordersCore.configureForDisplay(parentEntityViewSpec)

        this.orders = new DetailEntry(
          heading ? heading : ordersCore.listTitle,
          null,
          'orders',
          BaseDetailType.embeddedList,
          ordersCore,
          EditableType.never
        )

        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreement: new SOGetAgreement(entity.uid),
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.never
        )

        const tempagreementCore = app.makeCore( 'tempagreement');
        tempagreementCore.soGet = SOGetTempagreement.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempagreementCore
        )
        tempagreementCore.title = heading ? heading : tempagreementCore.title
        tempagreementCore.listTitle = 'Tempagreement'

        tempagreementCore.configureForDisplay(parentEntityViewSpec)

        this.tempagreement = new DetailEntry(
          heading ? heading : tempagreementCore.listTitle,
          null,
          'tempagreement',
          BaseDetailType.pushedDetail,
          tempagreementCore,
          EditableType.never
        )
        this.tempagreement.singleFieldSelect = true

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.never
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const feescheduleCore = app.makeCore( 'feeschedule');
        feescheduleCore.soGet = SOGetFeeschedule.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let feescheduleCeProtocol;
        if (entity.feeschedule) {
            feescheduleCeProtocol = createCEProtocol(
                feescheduleCore.ceProtocol,
                parentEntityViewSpec,
                feescheduleCore,
                entity.feeschedule,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feescheduleCore
        )
        feescheduleCore.title = heading ? heading : feescheduleCore.title
        feescheduleCore.listTitle = 'Feeschedule'

        if (entity.feeschedule) {
            displayStringFactory.reconfigure(
                entity.feeschedule,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        feescheduleCore.configureForDisplay(parentEntityViewSpec)

        this.feeschedule = new DetailEntry(
          heading ? heading : feescheduleCore.listTitle,
          feescheduleCeProtocol ? feescheduleCeProtocol.entityListView.fullHeading : null,
          'feeschedule',
          BaseDetailType.pushedDetail,
          feescheduleCore,
          EditableType.always,
          formFields.feeschedule
        )
        if (entity.feeschedule) {
          this.feeschedule.ceProtocol = feescheduleCeProtocol;
          this.feeschedule.cachedListEntry = feescheduleCore.makeListEntry(parentEntityViewSpec, entity.feeschedule, 0)
          feescheduleCore.selectedEntity = entity.feeschedule;
        }
        this.feeschedule.singleFieldSelect = true

        const agreeyearsCore = app.makeCore( 'agreeyear');
        agreeyearsCore.soGet = SOGetAgreeyear.construct({
          agreement: new SOGetAgreement(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreeyearsCore
        )
        agreeyearsCore.title = heading ? heading : agreeyearsCore.title
        agreeyearsCore.listTitle = 'Agreeyears'

        agreeyearsCore.configureForDisplay(parentEntityViewSpec)

        this.agreeyears = new DetailEntry(
          heading ? heading : agreeyearsCore.listTitle,
          null,
          'agreeyears',
          BaseDetailType.embeddedList,
          agreeyearsCore,
          EditableType.never
        )

        const agreemonthsCore = app.makeCore( 'agreemonth');
        agreemonthsCore.soGet = SOGetAgreemonth.construct({
          agreement: new SOGetAgreement(entity.uid),
        });
        agreemonthsCore.soGet.queryOptions.sortKey = 'month';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreemonthsCore
        )
        agreemonthsCore.title = heading ? heading : agreemonthsCore.title
        agreemonthsCore.listTitle = 'Agreemonths'

        agreemonthsCore.configureForDisplay(parentEntityViewSpec)

        this.agreemonths = new DetailEntry(
          heading ? heading : agreemonthsCore.listTitle,
          null,
          'agreemonths',
          BaseDetailType.embeddedList,
          agreemonthsCore,
          EditableType.never
        )

        const contractsCore = app.makeCore( 'contract');
        contractsCore.soGet = SOGetContract.construct({
          agreement: new SOGetAgreement(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            contractsCore
        )
        contractsCore.title = heading ? heading : contractsCore.title
        contractsCore.listTitle = 'Contracts'

        contractsCore.configureForDisplay(parentEntityViewSpec)

        this.contracts = new DetailEntry(
          heading ? heading : contractsCore.listTitle,
          null,
          'contracts',
          BaseDetailType.embeddedList,
          contractsCore,
          EditableType.never
        )

        const feescheduleagreementascsCore = app.makeCore( 'feescheduleagreementasc');
        feescheduleagreementascsCore.soGet = SOGetFeescheduleagreementasc.construct({
          agreement: new SOGetAgreement(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feescheduleagreementascsCore
        )
        feescheduleagreementascsCore.title = heading ? heading : feescheduleagreementascsCore.title
        feescheduleagreementascsCore.listTitle = 'Feescheduleagreementascs'

        feescheduleagreementascsCore.configureForDisplay(parentEntityViewSpec)

        this.feescheduleagreementascs = new DetailEntry(
          heading ? heading : feescheduleagreementascsCore.listTitle,
          null,
          'feescheduleagreementascs',
          BaseDetailType.embeddedList,
          feescheduleagreementascsCore,
          EditableType.never
        )

        const awardCore = app.makeCore( 'award');
        awardCore.soGet = SOGetAward.construct({
          agreement: new SOGetAgreement(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            awardCore
        )
        awardCore.title = heading ? heading : awardCore.title
        awardCore.listTitle = 'Award'

        awardCore.configureForDisplay(parentEntityViewSpec)

        this.award = new DetailEntry(
          heading ? heading : awardCore.listTitle,
          null,
          'award',
          BaseDetailType.pushedDetail,
          awardCore,
          EditableType.never
        )
        this.award.singleFieldSelect = true
    }
}