import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOLinagService} from '../../core/linag/eo-linag';
import {SOGetLinag} from '../../core/linag/so-get-linag';
import {RAPutLinag} from './ra-put-linag';
import {RAPostLinag} from './ra-post-linag';
import {RADeleteLinag} from './ra-delete-linag';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CELinag } from "./ce-linag"


export class LinagCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'linag';
  title = 'Linag';
  ceProtocol: CEConstructor = CELinag

  entityService: EOLinagService;
  soGetClass = SOGetLinag;
  soGet: SOGetLinag;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOLinagService
    );
    this.adapters = [
      new RAPostLinag(this),
      new RAPutLinag(this),
      new RADeleteLinag(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetLinag();
    this.soGet.queryOptions.limit = this.limit;
  }


}