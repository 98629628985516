import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostPlantFormFields {

    public name: FormField
    public timeZone: FormField
    public timeZoneOffset: FormField
    public daylightSavingsObserved: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.timeZone = new FormField(
          'timeZone',
          'Time zone',
          null,
          FormFieldType.text,
          true,
        )
        this.timeZoneOffset = new FormField(
          'timeZoneOffset',
          'Time zone offset',
          null,
          FormFieldType.text,
          true,
        )
        this.daylightSavingsObserved = new FormField(
          'daylightSavingsObserved',
          'Daylight savings observed',
          null,
          FormFieldType.boolean,
          false,
          true,
          true
        )

    }
}

const fields = new PostPlantFormFields()

export const postPlantFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  timeZone: fields.timeZone,
  timeZoneOffset: fields.timeZoneOffset,
  daylightSavingsObserved: fields.daylightSavingsObserved,
};