import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutProvider {

  constructor(
    public uid: string,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutProvider(
      'uid' in params ? params.uid : null,
      'organization' in params ? params.organization : null,
    );
  }
}
