import { Type } from 'class-transformer';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import { SORespAccount } from '../account/so-resp-account';
import { SORespCarrier } from '../carrier/so-resp-carrier';
import {ENPublicationOption} from '../../enums';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespPublication implements ObjectiveProtocol {

  entityType: string = 'publication';
  uid: string;
  identifier: ENPublicationOption;
  displayName: string;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution = null;
  @Type(() => SORespAccount)
  account: SORespAccount = null;
  @Type(() => SORespCarrier)
  carrier: SORespCarrier = null;
}