import {SOPostMessage} from '../../core/message/so-post-message';
import {MessageCoreBase} from './message.core.base';
import {SOGetMessage} from '../../core/message/so-get-message';
import {FormField, SelectionOption} from 'lionheartland';
import {postMessageFormFieldOptions} from './post-message-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {SubscriptionCore} from '../subscription/subscription.core';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {PublicationCore} from '../publication/publication.core';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {ActivityCore} from '../activity/activity.core';
import {SOGetActivity} from '../../core/activity/so-get-activity';

export class RAPostMessage extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postMessageFormFieldOptions);

  RequestClass = SOPostMessage

  constructor(
    public entityCore: MessageCoreBase
  ) {
    super();
    if (postMessageFormFieldOptions.subscription) {
      postMessageFormFieldOptions.subscription.makeCore = true;
    }
    if (postMessageFormFieldOptions.publication) {
      postMessageFormFieldOptions.publication.makeCore = true;
    }
    if (postMessageFormFieldOptions.activity) {
      postMessageFormFieldOptions.activity.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('body'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('ref'),
      this.getFormValueFromIdentifier(
        'subscription',
        SOGetSubscription,
      ),
      this.getFormValueFromIdentifier(
        'publication',
        SOGetPublication,
      ),
      this.getFormValueFromIdentifier(
        'activity',
        SOGetActivity,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}