import {SORespServiceRequest} from '../../core/service-request/so-resp-service-request';
import {ServiceRequestCore} from './service-request.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutServiceRequestFormFields} from './put-service-request-form-fields';
import { CEServiceRequestAttributes } from './ce-service-request-attributes'


export class CEServiceRequestBase extends CEBase {

    public details: CEServiceRequestAttributes;
    public fromFields: PutServiceRequestFormFields = new PutServiceRequestFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ServiceRequestCore,
        public entity: SORespServiceRequest,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEServiceRequestAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.dateRequested)
        this.attributes.push(this.details.serviceType)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.distributionday)
        this.attributes.push(this.details.containersession)

    }
}