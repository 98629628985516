import {SOPutOffering} from '../../core/offering/so-put-offering';
import {OfferingCoreBase} from './offering.core.base';
import {SOGetOffering} from '../../core/offering/so-get-offering';
import {FormField, SelectionOption} from 'lionheartland';
import {putOfferingFormFieldOptions} from './put-offering-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {DistofferCore} from '../distoffer/distoffer.core';
import {SOGetDistoffer} from '../../core/distoffer/so-get-distoffer';
import {ItemCore} from '../item/item.core';
import {SOGetItem} from '../../core/item/so-get-item';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';

export class RAPutOffering extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putOfferingFormFieldOptions);

  RequestClass = SOPutOffering

  constructor(
    public entityCore: OfferingCoreBase
  ) {
    super();
    if (putOfferingFormFieldOptions.distoffers) {
      putOfferingFormFieldOptions.distoffers.makeCore = true;
    }
    if (putOfferingFormFieldOptions.items) {
      putOfferingFormFieldOptions.items.makeCore = true;
    }
    if (putOfferingFormFieldOptions.loads) {
      putOfferingFormFieldOptions.loads.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      [this.getFormValueFromIdentifier('distoffers', SOGetDistoffer)],
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('units'),
      this.getFormValueFromIdentifier('size'),
      [this.getFormValueFromIdentifier('items', SOGetItem)],
      [this.getFormValueFromIdentifier('loads', SOGetLoad)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}