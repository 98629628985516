import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutBacklogitemFormFields {


    constructor() {


    }
}

const fields = new PutBacklogitemFormFields()

export const putBacklogitemFormFieldOptions: {[key: string]: FormField} = {
};