import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTaskFormFields {

    public trailerGone: FormField
    public completed: FormField
    public weight: FormField
    public location: FormField
    public loadStatus: FormField

    constructor() {

        this.trailerGone = new FormField(
          'trailerGone',
          'Trailer gone',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.completed = new FormField(
          'completed',
          'Completed',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.weight = new FormField(
          'weight',
          'Weight',
          'weight',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.location = new FormField(
          'location',
          'Location',
          null,
          FormFieldType.text,
          false,
        )
        this.loadStatus = new FormField(
          'loadStatus',
          'Load status',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutTaskFormFields()

export const putTaskFormFieldOptions: {[key: string]: FormField} = {
  trailerGone: fields.trailerGone,
  completed: fields.completed,
  weight: fields.weight,
  location: fields.location,
  loadStatus: fields.loadStatus,
};