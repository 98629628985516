import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutInvoiceitemFormFields {


    constructor() {


    }
}

const fields = new PutInvoiceitemFormFields()

export const putInvoiceitemFormFieldOptions: {[key: string]: FormField} = {
};