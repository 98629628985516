import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOHestatusitemService} from '../../core/hestatusitem/eo-hestatusitem';
import {SOGetHestatusitem} from '../../core/hestatusitem/so-get-hestatusitem';
import {RAPutHestatusitem} from './ra-put-hestatusitem';
import {RAPostHestatusitem} from './ra-post-hestatusitem';
import {RADeleteHestatusitem} from './ra-delete-hestatusitem';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEHestatusitem } from "./ce-hestatusitem"


export class HestatusitemCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'hestatusitem';
  title = 'Hestatusitem';
  ceProtocol: CEConstructor = CEHestatusitem

  entityService: EOHestatusitemService;
  soGetClass = SOGetHestatusitem;
  soGet: SOGetHestatusitem;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOHestatusitemService
    );
    this.adapters = [
      new RAPostHestatusitem(this),
      new RAPutHestatusitem(this),
      new RADeleteHestatusitem(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetHestatusitem();
    this.soGet.queryOptions.limit = this.limit;
  }


}