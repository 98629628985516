import {SORespHestatusitem} from '../../core/hestatusitem/so-resp-hestatusitem';
import {HestatusitemCore} from './hestatusitem.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHestatusitemFormFields} from './put-hestatusitem-form-fields';
import {SOGetHestatusitem} from '../../core/hestatusitem/so-get-hestatusitem';
import {SOGetHemon} from '../../core/hemon/so-get-hemon';
import {SOGetHestatus} from '../../core/hestatus/so-get-hestatus';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEHestatusitemAttributesBase {

    dateCreated: DetailEntry
    state: DetailEntry
    location: DetailEntry
    loadStatus: DetailEntry
    n2Level: DetailEntry
    n2Pressure: DetailEntry
    heTemp: DetailEntry
    heLevel: DetailEntry
    hePressure: DetailEntry
    n2LevelDcs: DetailEntry
    n2PressureDcs: DetailEntry
    heTempDcs: DetailEntry
    heLevelDcs: DetailEntry
    hePressureDcs: DetailEntry
    hemon: DetailEntry
    hestatus: DetailEntry
    containersession: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespHestatusitem,
        app: GasDirectApp,
        formFields: PutHestatusitemFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.location = new DetailEntry(
            'Location',
            entity.location,
            'location',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.location
        )

        this.loadStatus = new DetailEntry(
            'Load status',
            entity.loadStatus,
            'loadStatus',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadStatus
        )

        this.n2Level = new DetailEntry(
            'N2 level',
            entity.n2Level,
            'n2Level',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.n2Level
        )

        this.n2Pressure = new DetailEntry(
            'N2 pressure',
            entity.n2Pressure,
            'n2Pressure',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.n2Pressure
        )

        this.heTemp = new DetailEntry(
            'He temp',
            entity.heTemp,
            'heTemp',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.heTemp
        )

        this.heLevel = new DetailEntry(
            'He level',
            entity.heLevel,
            'heLevel',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.heLevel
        )

        this.hePressure = new DetailEntry(
            'He pressure',
            entity.hePressure,
            'hePressure',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.hePressure
        )

        this.n2LevelDcs = new DetailEntry(
            'N2 level dcs',
            entity.n2LevelDcs,
            'n2LevelDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.n2PressureDcs = new DetailEntry(
            'N2 pressure dcs',
            entity.n2PressureDcs,
            'n2PressureDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.heTempDcs = new DetailEntry(
            'He temp dcs',
            entity.heTempDcs,
            'heTempDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.heLevelDcs = new DetailEntry(
            'He level dcs',
            entity.heLevelDcs,
            'heLevelDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.hePressureDcs = new DetailEntry(
            'He pressure dcs',
            entity.hePressureDcs,
            'hePressureDcs',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const hemonCore = app.makeCore( 'hemon');
        hemonCore.soGet = SOGetHemon.construct({
        });
        let hemonCeProtocol;
        if (entity.hemon) {
            hemonCeProtocol = createCEProtocol(
                hemonCore.ceProtocol,
                parentEntityViewSpec,
                hemonCore,
                entity.hemon,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hemonCore
        )
        hemonCore.title = heading ? heading : hemonCore.title
        hemonCore.listTitle = 'Hemon'

        if (entity.hemon) {
            displayStringFactory.reconfigure(
                entity.hemon,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        hemonCore.configureForDisplay(parentEntityViewSpec)

        this.hemon = new DetailEntry(
          heading ? heading : hemonCore.listTitle,
          hemonCeProtocol ? hemonCeProtocol.entityListView.fullHeading : null,
          'hemon',
          BaseDetailType.pushedDetail,
          hemonCore,
          EditableType.never
        )
        if (entity.hemon) {
          this.hemon.ceProtocol = hemonCeProtocol;
          this.hemon.cachedListEntry = hemonCore.makeListEntry(parentEntityViewSpec, entity.hemon, 0)
          hemonCore.selectedEntity = entity.hemon;
        }
        this.hemon.singleFieldSelect = true

        const hestatusCore = app.makeCore( 'hestatus');
        hestatusCore.soGet = SOGetHestatus.construct({
        });
        let hestatusCeProtocol;
        if (entity.hestatus) {
            hestatusCeProtocol = createCEProtocol(
                hestatusCore.ceProtocol,
                parentEntityViewSpec,
                hestatusCore,
                entity.hestatus,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hestatusCore
        )
        hestatusCore.title = heading ? heading : hestatusCore.title
        hestatusCore.listTitle = 'Hestatus'

        if (entity.hestatus) {
            displayStringFactory.reconfigure(
                entity.hestatus,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        hestatusCore.configureForDisplay(parentEntityViewSpec)

        this.hestatus = new DetailEntry(
          heading ? heading : hestatusCore.listTitle,
          hestatusCeProtocol ? hestatusCeProtocol.entityListView.fullHeading : null,
          'hestatus',
          BaseDetailType.pushedDetail,
          hestatusCore,
          EditableType.never
        )
        if (entity.hestatus) {
          this.hestatus.ceProtocol = hestatusCeProtocol;
          this.hestatus.cachedListEntry = hestatusCore.makeListEntry(parentEntityViewSpec, entity.hestatus, 0)
          hestatusCore.selectedEntity = entity.hestatus;
        }
        this.hestatus.singleFieldSelect = true

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        let containersessionCeProtocol;
        if (entity.containersession) {
            containersessionCeProtocol = createCEProtocol(
                containersessionCore.ceProtocol,
                parentEntityViewSpec,
                containersessionCore,
                entity.containersession,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        if (entity.containersession) {
            displayStringFactory.reconfigure(
                entity.containersession,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          containersessionCeProtocol ? containersessionCeProtocol.entityListView.fullHeading : null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        if (entity.containersession) {
          this.containersession.ceProtocol = containersessionCeProtocol;
          this.containersession.cachedListEntry = containersessionCore.makeListEntry(parentEntityViewSpec, entity.containersession, 0)
          containersessionCore.selectedEntity = entity.containersession;
        }
        this.containersession.singleFieldSelect = true
    }
}