import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTagreportService} from '../../core/tagreport/eo-tagreport';
import {SOGetTagreport} from '../../core/tagreport/so-get-tagreport';
import {RAPutTagreport} from './ra-put-tagreport';
import {RAPostTagreport} from './ra-post-tagreport';
import {RADeleteTagreport} from './ra-delete-tagreport';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETagreport } from "./ce-tagreport"


export class TagreportCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'tagreport';
  title = 'Tagreport';
  ceProtocol: CEConstructor = CETagreport

  entityService: EOTagreportService;
  soGetClass = SOGetTagreport;
  soGet: SOGetTagreport;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTagreportService
    );
    this.adapters = [
      new RAPostTagreport(this),
      new RAPutTagreport(this),
      new RADeleteTagreport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTagreport();
    this.soGet.queryOptions.limit = this.limit;
  }


}