import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTaskFormFields {

    public completed: FormField
    public taskreport: FormField
    public load: FormField
    public loadStatus: FormField

    constructor() {

        this.completed = new FormField(
          'completed',
          'Completed',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.taskreport = new FormField(
          'taskreport',
          'Taskreport',
          'taskreport',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.loadStatus = new FormField(
          'loadStatus',
          'Load status',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PostTaskFormFields()

export const postTaskFormFieldOptions: {[key: string]: FormField} = {
  completed: fields.completed,
  taskreport: fields.taskreport,
  load: fields.load,
  loadStatus: fields.loadStatus,
};