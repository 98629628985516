import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutPublication } from './so-put-publication';
import { SOGetPublication } from './so-get-publication';
import { SOPostPublication } from './so-post-publication';
import { SODeletePublication } from './so-delete-publication';
import {SORespPublication} from './so-resp-publication';
import {SOPluralPublication} from './so-plural-publication';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOPublicationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'publication',
      entityCore
    );
  }

  /** GET Publication by id. Will 404 if id not found */
  get(request: SOGetPublication): Observable<SOPluralPublication> {
    return this.httpClient.get<SOPluralPublication>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralPublication, resp) }),
      tap(_ => this.log(`fetched Publication`)),
      catchError(this.handleError<SOPluralPublication>(`get Publication`))
    );
  }

  /** POST: add a new Publication to the server */
  post(request: SOPostPublication): Observable<SORespPublication> {
    return this.httpClient.post<SORespPublication>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPublication, resp) }),
      tap((response: SORespPublication) => this.log(`added Publication w/ id=${response.uid}`)),
      catchError(this.handleError<SORespPublication>('Publication post'))
    );
  }

  /** PUT: update Publication  */
  put(request: SOPutPublication): Observable<SORespPublication> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPublication>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPublication, resp) }),
      tap((response: SORespPublication) => this.log(`edited Publication w/ uid=${uid}`)),
      catchError(this.handleError<SORespPublication>('Publication put'))
    );
  }

  /** DELETE: delete the Publication from the server */
  delete(request: SODeletePublication): Observable<SORespPublication> {
    return this.httpClient.delete<SORespPublication>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Publication uid=${request.uid}`)),
      catchError(this.handleError<SORespPublication>('delete Publication'))
    );
  }
}
