import { SOGetFeature } from '../feature/so-get-feature';
import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetUser } from '../user/so-get-user';
import { SOGetRole } from '../role/so-get-role';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetFeatureasc implements QueryProtocol {

  constructor(
    public uid: string = null,
    public feature: SOGetFeature = null,
    public distribution: SOGetDistribution = null,
    public user: SOGetUser = null,
    public role: SOGetRole = null,
    public account: SOGetAccount = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetFeatureasc(
      'uid' in params ? params.uid : null,
      'feature' in params ? params.feature : null,
      'distribution' in params ? params.distribution : null,
      'user' in params ? params.user : null,
      'role' in params ? params.role : null,
      'account' in params ? params.account : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
