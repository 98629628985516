import {SORespTank} from '../../core/tank/so-resp-tank';
import {TankCore} from './tank.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTankFormFields} from './put-tank-form-fields';
import {SOGetTank} from '../../core/tank/so-get-tank';
import {SOGetProduction} from '../../core/production/so-get-production';
import {SOGetTankreport} from '../../core/tankreport/so-get-tankreport';
import {SOGetLinag} from '../../core/linag/so-get-linag';


export class CETankAttributesBase {

    name: DetailEntry
    units: DetailEntry
    type: DetailEntry
    capacity: DetailEntry
    average: DetailEntry
    production: DetailEntry
    value: DetailEntry
    tankreports: DetailEntry
    linags: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTank,
        app: GasDirectApp,
        formFields: PutTankFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.units = new DetailEntry(
            'Units',
            entity.units,
            'units',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.units
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.type
        )

        this.capacity = new DetailEntry(
            'Capacity',
            entity.capacity,
            'capacity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.capacity
        )

        this.average = new DetailEntry(
            'Average',
            entity.average,
            'average',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.average
        )

        this.value = new DetailEntry(
            'Value',
            entity.value,
            'value',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.value
        )


        const productionCore = app.makeCore( 'production');
        productionCore.soGet = SOGetProduction.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            productionCore
        )
        productionCore.title = heading ? heading : productionCore.title
        productionCore.listTitle = 'Production'

        productionCore.configureForDisplay(parentEntityViewSpec)

        this.production = new DetailEntry(
          heading ? heading : productionCore.listTitle,
          null,
          'production',
          BaseDetailType.pushedDetail,
          productionCore,
          EditableType.always,
          formFields.production
        )
        this.production.singleFieldSelect = true

        const tankreportsCore = app.makeCore( 'tankreport');
        tankreportsCore.soGet = SOGetTankreport.construct({
          tank: new SOGetTank(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tankreportsCore
        )
        tankreportsCore.title = heading ? heading : tankreportsCore.title
        tankreportsCore.listTitle = 'Tankreports'

        tankreportsCore.configureForDisplay(parentEntityViewSpec)

        this.tankreports = new DetailEntry(
          heading ? heading : tankreportsCore.listTitle,
          null,
          'tankreports',
          BaseDetailType.embeddedList,
          tankreportsCore,
          EditableType.never
        )

        const linagsCore = app.makeCore( 'linag');
        linagsCore.soGet = SOGetLinag.construct({
          tank: new SOGetTank(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            linagsCore
        )
        linagsCore.title = heading ? heading : linagsCore.title
        linagsCore.listTitle = 'Linags'

        linagsCore.configureForDisplay(parentEntityViewSpec)

        this.linags = new DetailEntry(
          heading ? heading : linagsCore.listTitle,
          null,
          'linags',
          BaseDetailType.embeddedList,
          linagsCore,
          EditableType.never
        )
    }
}