import { Type } from 'class-transformer';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import { SORespTempagreement } from '../tempagreement/so-resp-tempagreement';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDisttempagreement implements ObjectiveProtocol {

  entityType: string = 'disttempagreement';
  uid: string;
  isDefault: boolean;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
  @Type(() => SORespTempagreement)
  tempagreement: SORespTempagreement;
}