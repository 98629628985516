import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutImpurity } from './so-put-impurity';
import { SOGetImpurity } from './so-get-impurity';
import { SOPostImpurity } from './so-post-impurity';
import { SODeleteImpurity } from './so-delete-impurity';
import {SORespImpurity} from './so-resp-impurity';
import {SOPluralImpurity} from './so-plural-impurity';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOImpurityService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'impurity',
      entityCore
    );
  }

  /** GET Impurity by id. Will 404 if id not found */
  get(request: SOGetImpurity): Observable<SOPluralImpurity> {
    return this.httpClient.get<SOPluralImpurity>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralImpurity, resp) }),
      tap(_ => this.log(`fetched Impurity`)),
      catchError(this.handleError<SOPluralImpurity>(`get Impurity`))
    );
  }

  /** POST: add a new Impurity to the server */
  post(request: SOPostImpurity): Observable<SORespImpurity> {
    return this.httpClient.post<SORespImpurity>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespImpurity, resp) }),
      tap((response: SORespImpurity) => this.log(`added Impurity w/ id=${response.uid}`)),
      catchError(this.handleError<SORespImpurity>('Impurity post'))
    );
  }

  /** PUT: update Impurity  */
  put(request: SOPutImpurity): Observable<SORespImpurity> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespImpurity>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespImpurity, resp) }),
      tap((response: SORespImpurity) => this.log(`edited Impurity w/ uid=${uid}`)),
      catchError(this.handleError<SORespImpurity>('Impurity put'))
    );
  }

  /** DELETE: delete the Impurity from the server */
  delete(request: SODeleteImpurity): Observable<SORespImpurity> {
    return this.httpClient.delete<SORespImpurity>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Impurity uid=${request.uid}`)),
      catchError(this.handleError<SORespImpurity>('delete Impurity'))
    );
  }
}
