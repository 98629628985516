import {SORespPlant} from '../../core/plant/so-resp-plant';
import {PlantCore} from './plant.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutPlantFormFields} from './put-plant-form-fields';
import {SOGetPlant} from '../../core/plant/so-get-plant';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEPlantAttributesBase {

    name: DetailEntry
    closed: DetailEntry
    message: DetailEntry
    timeZone: DetailEntry
    timeZoneOffset: DetailEntry
    daylightSavingsObserved: DetailEntry
    lines: DetailEntry
    roles: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespPlant,
        app: GasDirectApp,
        formFields: PutPlantFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.closed = new DetailEntry(
            'Closed',
            entity.closed,
            'closed',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.closed
        )

        this.message = new DetailEntry(
            'Message',
            entity.message,
            'message',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.message
        )

        this.timeZone = new DetailEntry(
            'Time zone',
            entity.timeZone,
            'timeZone',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.timeZone
        )

        this.timeZoneOffset = new DetailEntry(
            'Time zone offset',
            entity.timeZoneOffset,
            'timeZoneOffset',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.timeZoneOffset
        )

        this.daylightSavingsObserved = new DetailEntry(
            'Daylight savings observed',
            entity.daylightSavingsObserved,
            'daylightSavingsObserved',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.daylightSavingsObserved
        )


        const linesCore = app.makeCore( 'line');
        linesCore.soGet = SOGetLine.construct({
          plant: new SOGetPlant(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            linesCore
        )
        linesCore.title = heading ? heading : linesCore.title
        linesCore.listTitle = 'Lines'

        linesCore.configureForDisplay(parentEntityViewSpec)

        this.lines = new DetailEntry(
          heading ? heading : linesCore.listTitle,
          null,
          'lines',
          BaseDetailType.embeddedList,
          linesCore,
          EditableType.never
        )

        const rolesCore = app.makeCore( 'role');
        rolesCore.soGet = SOGetRole.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rolesCore
        )
        rolesCore.title = heading ? heading : rolesCore.title
        rolesCore.listTitle = 'Roles'

        rolesCore.configureForDisplay(parentEntityViewSpec)

        this.roles = new DetailEntry(
          heading ? heading : rolesCore.listTitle,
          null,
          'roles',
          BaseDetailType.embeddedList,
          rolesCore,
          EditableType.never
        )
    }
}