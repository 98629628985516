import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutFeatureascFormFields {

    public feature: FormField
    public distribution: FormField
    public user: FormField
    public role: FormField
    public account: FormField
    public organization: FormField

    constructor() {

        this.feature = new FormField(
          'feature',
          'Feature',
          'feature',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.autoCompleteList,
          false,
        )
        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutFeatureascFormFields()

export const putFeatureascFormFieldOptions: {[key: string]: FormField} = {
  feature: fields.feature,
  distribution: fields.distribution,
  user: fields.user,
  role: fields.role,
  account: fields.account,
  organization: fields.organization,
};