import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostOrderFormFields {

    public status: FormField
    public account: FormField
    public agreement: FormField
    public agreemonth: FormField
    public distributionmonth: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.agreement = new FormField(
          'agreement',
          'Agreement',
          'agreement',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.agreemonth = new FormField(
          'agreemonth',
          'Agreemonth',
          'agreemonth',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.distributionmonth = new FormField(
          'distributionmonth',
          'Distributionmonth',
          'distributionmonth',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostOrderFormFields()

export const postOrderFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
};