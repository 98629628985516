import {SORespOffering} from '../../core/offering/so-resp-offering';
import {OfferingCore} from './offering.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutOfferingFormFields} from './put-offering-form-fields';
import {SOGetOffering} from '../../core/offering/so-get-offering';
import {SOGetDistoffer} from '../../core/distoffer/so-get-distoffer';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CEOfferingAttributesBase {

    distoffers: DetailEntry
    name: DetailEntry
    description: DetailEntry
    units: DetailEntry
    size: DetailEntry
    items: DetailEntry
    loads: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespOffering,
        app: GasDirectApp,
        formFields: PutOfferingFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.units = new DetailEntry(
            'Units',
            entity.units,
            'units',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.units
        )

        this.size = new DetailEntry(
            'Size',
            entity.size,
            'size',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.size
        )


        const distoffersCore = app.makeCore( 'distoffer');
        distoffersCore.soGet = SOGetDistoffer.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distoffersCore
        )
        distoffersCore.title = heading ? heading : distoffersCore.title
        distoffersCore.listTitle = 'Distoffers'

        distoffersCore.configureForDisplay(parentEntityViewSpec)

        this.distoffers = new DetailEntry(
          heading ? heading : distoffersCore.listTitle,
          null,
          'distoffers',
          BaseDetailType.embeddedList,
          distoffersCore,
          EditableType.always,
          formFields.distoffers
        )

        const itemsCore = app.makeCore( 'item');
        itemsCore.soGet = SOGetItem.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            itemsCore
        )
        itemsCore.title = heading ? heading : itemsCore.title
        itemsCore.listTitle = 'Items'

        itemsCore.configureForDisplay(parentEntityViewSpec)

        this.items = new DetailEntry(
          heading ? heading : itemsCore.listTitle,
          null,
          'items',
          BaseDetailType.embeddedList,
          itemsCore,
          EditableType.always,
          formFields.items
        )

        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.always,
          formFields.loads
        )
    }
}