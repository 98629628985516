import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOExternalindexpriceService} from '../../core/externalindexprice/eo-externalindexprice';
import {SOGetExternalindexprice} from '../../core/externalindexprice/so-get-externalindexprice';
import {RAPutExternalindexprice} from './ra-put-externalindexprice';
import {RAPostExternalindexprice} from './ra-post-externalindexprice';
import {RADeleteExternalindexprice} from './ra-delete-externalindexprice';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEExternalindexprice } from "./ce-externalindexprice"


export class ExternalindexpriceCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'externalindexprice';
  title = 'Externalindexprice';
  ceProtocol: CEConstructor = CEExternalindexprice

  entityService: EOExternalindexpriceService;
  soGetClass = SOGetExternalindexprice;
  soGet: SOGetExternalindexprice;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOExternalindexpriceService
    );
    this.adapters = [
      new RAPostExternalindexprice(this),
      new RAPutExternalindexprice(this),
      new RADeleteExternalindexprice(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetExternalindexprice();
    this.soGet.queryOptions.limit = this.limit;
  }


}