import { SOGetAccount } from '../account/so-get-account';
import { SOGetTrailer } from '../trailer/so-get-trailer';


export class SOPostTrailerauth {

  constructor(
    public account: SOGetAccount,
    public trailer: SOGetTrailer,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTrailerauth(
      'account' in params ? params.account : null,
      'trailer' in params ? params.trailer : null,
    );
  }
}
