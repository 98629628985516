import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutNavtemrola } from './so-put-navtemrola';
import { SOGetNavtemrola } from './so-get-navtemrola';
import { SOPostNavtemrola } from './so-post-navtemrola';
import { SODeleteNavtemrola } from './so-delete-navtemrola';
import {SORespNavtemrola} from './so-resp-navtemrola';
import {SOPluralNavtemrola} from './so-plural-navtemrola';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EONavtemrolaService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'navtemrola',
      entityCore
    );
  }

  /** GET Navtemrola by id. Will 404 if id not found */
  get(request: SOGetNavtemrola): Observable<SOPluralNavtemrola> {
    return this.httpClient.get<SOPluralNavtemrola>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralNavtemrola, resp) }),
      tap(_ => this.log(`fetched Navtemrola`)),
      catchError(this.handleError<SOPluralNavtemrola>(`get Navtemrola`))
    );
  }

  /** POST: add a new Navtemrola to the server */
  post(request: SOPostNavtemrola): Observable<SORespNavtemrola> {
    return this.httpClient.post<SORespNavtemrola>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespNavtemrola, resp) }),
      tap((response: SORespNavtemrola) => this.log(`added Navtemrola w/ id=${response.uid}`)),
      catchError(this.handleError<SORespNavtemrola>('Navtemrola post'))
    );
  }

  /** PUT: update Navtemrola  */
  put(request: SOPutNavtemrola): Observable<SORespNavtemrola> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespNavtemrola>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespNavtemrola, resp) }),
      tap((response: SORespNavtemrola) => this.log(`edited Navtemrola w/ uid=${uid}`)),
      catchError(this.handleError<SORespNavtemrola>('Navtemrola put'))
    );
  }

  /** DELETE: delete the Navtemrola from the server */
  delete(request: SODeleteNavtemrola): Observable<SORespNavtemrola> {
    return this.httpClient.delete<SORespNavtemrola>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Navtemrola uid=${request.uid}`)),
      catchError(this.handleError<SORespNavtemrola>('delete Navtemrola'))
    );
  }
}
