import { SOGetPlant } from '../plant/so-get-plant';
import { SOGetProduct } from '../product/so-get-product';
import { SOGetProduction } from '../production/so-get-production';
import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPutLine {

  constructor(
    public uid: string,
    public name: string = null,
    public plant: SOGetPlant = null,
    public product: SOGetProduct = null,
    public units: string = null,
    public production: SOGetProduction = null,
    public distribution: SOGetDistribution = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutLine(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'plant' in params ? params.plant : null,
      'product' in params ? params.product : null,
      'units' in params ? params.units : null,
      'production' in params ? params.production : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
