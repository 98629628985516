

export class SOPostTagreport {

  constructor(
    public value: number,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTagreport(
      'value' in params ? params.value : null,
    );
  }
}
