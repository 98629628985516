import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutProduction } from './so-put-production';
import { SOGetProduction } from './so-get-production';
import { SOPostProduction } from './so-post-production';
import { SODeleteProduction } from './so-delete-production';
import {SORespProduction} from './so-resp-production';
import {SOPluralProduction} from './so-plural-production';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOProductionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'production',
      entityCore
    );
  }

  /** GET Production by id. Will 404 if id not found */
  get(request: SOGetProduction): Observable<SOPluralProduction> {
    return this.httpClient.get<SOPluralProduction>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralProduction, resp) }),
      tap(_ => this.log(`fetched Production`)),
      catchError(this.handleError<SOPluralProduction>(`get Production`))
    );
  }

  /** POST: add a new Production to the server */
  post(request: SOPostProduction): Observable<SORespProduction> {
    return this.httpClient.post<SORespProduction>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespProduction, resp) }),
      tap((response: SORespProduction) => this.log(`added Production w/ id=${response.uid}`)),
      catchError(this.handleError<SORespProduction>('Production post'))
    );
  }

  /** PUT: update Production  */
  put(request: SOPutProduction): Observable<SORespProduction> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespProduction>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespProduction, resp) }),
      tap((response: SORespProduction) => this.log(`edited Production w/ uid=${uid}`)),
      catchError(this.handleError<SORespProduction>('Production put'))
    );
  }

  /** DELETE: delete the Production from the server */
  delete(request: SODeleteProduction): Observable<SORespProduction> {
    return this.httpClient.delete<SORespProduction>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Production uid=${request.uid}`)),
      catchError(this.handleError<SORespProduction>('delete Production'))
    );
  }
}
