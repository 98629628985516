import {SORespOrder} from '../../core/order/so-resp-order';
import {OrderCore} from './order.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutOrderFormFields} from './put-order-form-fields';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetQuotelet} from '../../core/quotelet/so-get-quotelet';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetActivity} from '../../core/activity/so-get-activity';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEOrderAttributesBase {

    transactions: DetailEntry
    amount: DetailEntry
    paidAmount: DetailEntry
    orderDate: DetailEntry
    ref: DetailEntry
    status: DetailEntry
    receiptUrl: DetailEntry
    account: DetailEntry
    agreement: DetailEntry
    agreemonth: DetailEntry
    distributionmonth: DetailEntry
    items: DetailEntry
    quotelets: DetailEntry
    loads: DetailEntry
    activitys: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespOrder,
        app: GasDirectApp,
        formFields: PutOrderFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.amount = new DetailEntry(
            'Amount',
            entity.amount,
            'amount',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.paidAmount = new DetailEntry(
            'Paid amount',
            entity.paidAmount,
            'paidAmount',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.orderDate = new DetailEntry(
            'Order date',
            app.activeUserService.time.dateAsString(entity.orderDate),
            'orderDate',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.orderDate.listTrailing = true;
        this.orderDate.tableIndex = 5;

        this.ref = new DetailEntry(
            'Ref',
            entity.ref,
            'ref',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.ref.listHeadingIndex = 0;
        this.ref.tableIndex = 0;

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.status
        )
        this.status.tableIndex = 1;

        this.receiptUrl = new DetailEntry(
            'Receipt url',
            entity.receiptUrl,
            'receiptUrl',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const transactionsCore = app.makeCore( 'transaction');
        transactionsCore.soGet = SOGetTransaction.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          order: new SOGetOrder(entity.uid),
        });
        transactionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        transactionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            transactionsCore
        )
        transactionsCore.title = heading ? heading : transactionsCore.title
        transactionsCore.listTitle = 'Transactions'

        transactionsCore.configureForDisplay(parentEntityViewSpec)

        this.transactions = new DetailEntry(
          heading ? heading : transactionsCore.listTitle,
          null,
          'transactions',
          BaseDetailType.embeddedList,
          transactionsCore,
          EditableType.never
        )

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.always,
          formFields.account
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true
        this.account.tableIndex = 2;

        const agreementCore = app.makeCore( 'agreement');
        agreementCore.soGet = SOGetAgreement.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        let agreementCeProtocol;
        if (entity.agreement) {
            agreementCeProtocol = createCEProtocol(
                agreementCore.ceProtocol,
                parentEntityViewSpec,
                agreementCore,
                entity.agreement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementCore
        )
        agreementCore.title = heading ? heading : agreementCore.title
        agreementCore.listTitle = 'Agreement'

        if (entity.agreement) {
            displayStringFactory.reconfigure(
                entity.agreement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreementCore.configureForDisplay(parentEntityViewSpec)

        this.agreement = new DetailEntry(
          heading ? heading : agreementCore.listTitle,
          agreementCeProtocol ? agreementCeProtocol.entityListView.fullHeading : null,
          'agreement',
          BaseDetailType.pushedDetail,
          agreementCore,
          EditableType.always,
          formFields.agreement
        )
        if (entity.agreement) {
          this.agreement.ceProtocol = agreementCeProtocol;
          this.agreement.cachedListEntry = agreementCore.makeListEntry(parentEntityViewSpec, entity.agreement, 0)
          agreementCore.selectedEntity = entity.agreement;
        }
        this.agreement.singleFieldSelect = true
        this.agreement.tableIndex = 3;

        const agreemonthCore = app.makeCore( 'agreemonth');
        agreemonthCore.soGet = SOGetAgreemonth.construct({
        });
        agreemonthCore.soGet.queryOptions.sortKey = 'month';
        let agreemonthCeProtocol;
        if (entity.agreemonth) {
            agreemonthCeProtocol = createCEProtocol(
                agreemonthCore.ceProtocol,
                parentEntityViewSpec,
                agreemonthCore,
                entity.agreemonth,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreemonthCore
        )
        agreemonthCore.title = heading ? heading : agreemonthCore.title
        agreemonthCore.listTitle = 'Agreemonth'

        if (entity.agreemonth) {
            displayStringFactory.reconfigure(
                entity.agreemonth,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreemonthCore.configureForDisplay(parentEntityViewSpec)

        this.agreemonth = new DetailEntry(
          heading ? heading : agreemonthCore.listTitle,
          agreemonthCeProtocol ? agreemonthCeProtocol.entityListView.fullHeading : null,
          'agreemonth',
          BaseDetailType.pushedDetail,
          agreemonthCore,
          EditableType.never
        )
        if (entity.agreemonth) {
          this.agreemonth.ceProtocol = agreemonthCeProtocol;
          this.agreemonth.cachedListEntry = agreemonthCore.makeListEntry(parentEntityViewSpec, entity.agreemonth, 0)
          agreemonthCore.selectedEntity = entity.agreemonth;
        }
        this.agreemonth.singleFieldSelect = true
        this.agreemonth.listHeadingIndex = 1;
        this.agreemonth.tableIndex = 4;

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true

        const itemsCore = app.makeCore( 'item');
        itemsCore.soGet = SOGetItem.construct({
          order: new SOGetOrder(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            itemsCore
        )
        itemsCore.title = heading ? heading : itemsCore.title
        itemsCore.listTitle = 'Items'

        itemsCore.configureForDisplay(parentEntityViewSpec)

        this.items = new DetailEntry(
          heading ? heading : itemsCore.listTitle,
          null,
          'items',
          BaseDetailType.embeddedList,
          itemsCore,
          EditableType.never
        )

        const quoteletsCore = app.makeCore( 'quotelet');
        quoteletsCore.soGet = SOGetQuotelet.construct({
          order: new SOGetOrder(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            quoteletsCore
        )
        quoteletsCore.title = heading ? heading : quoteletsCore.title
        quoteletsCore.listTitle = 'Quotelets'

        quoteletsCore.configureForDisplay(parentEntityViewSpec)

        this.quotelets = new DetailEntry(
          heading ? heading : quoteletsCore.listTitle,
          null,
          'quotelets',
          BaseDetailType.embeddedList,
          quoteletsCore,
          EditableType.never
        )

        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          order: new SOGetOrder(entity.uid),
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.never
        )

        const activitysCore = app.makeCore( 'activity');
        activitysCore.soGet = SOGetActivity.construct({
          order: new SOGetOrder(entity.uid),
        });
        activitysCore.soGet.queryOptions.sortKey = 'dateCreated';
        activitysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            activitysCore
        )
        activitysCore.title = heading ? heading : activitysCore.title
        activitysCore.listTitle = 'Activitys'

        activitysCore.configureForDisplay(parentEntityViewSpec)

        this.activitys = new DetailEntry(
          heading ? heading : activitysCore.listTitle,
          null,
          'activitys',
          BaseDetailType.embeddedList,
          activitysCore,
          EditableType.never
        )
    }
}