import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutBaysessionFormFields {


    constructor() {


    }
}

const fields = new PutBaysessionFormFields()

export const putBaysessionFormFieldOptions: {[key: string]: FormField} = {
};