import {SORespRole} from '../../core/role/so-resp-role';
import {RoleCore} from './role.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutRoleFormFields} from './put-role-form-fields';
import { CERoleAttributes } from './ce-role-attributes'


export class CERoleBase extends CEBase {

    public details: CERoleAttributes;
    public fromFields: PutRoleFormFields = new PutRoleFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: RoleCore,
        public entity: SORespRole,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CERoleAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.plant)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.carrier)
        this.attributes.push(this.details.grants)
        this.attributes.push(this.details.navroas)
        this.attributes.push(this.details.orgrolas)
        this.attributes.push(this.details.featureascs)

    }
}