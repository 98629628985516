import {SORespTemptract} from '../../core/temptract/so-resp-temptract';
import {TemptractCore} from './temptract.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTemptractFormFields} from './put-temptract-form-fields';
import { CETemptractAttributes } from './ce-temptract-attributes'


export class CETemptractBase extends CEBase {

    public details: CETemptractAttributes;
    public fromFields: PutTemptractFormFields = new PutTemptractFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TemptractCore,
        public entity: SORespTemptract,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETemptractAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.durationDays)
        this.attributes.push(this.details.takeOrPay)
        this.attributes.push(this.details.minLoadSizeToQualify)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.loadQuantity)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.docusignLink)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.contracts)
        this.attributes.push(this.details.tempagreetracts)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.temptraclins)

    }
}