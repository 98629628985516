import {SORespBacklogitem} from '../../core/backlogitem/so-resp-backlogitem';
import {BacklogitemCore} from './backlogitem.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutBacklogitemFormFields} from './put-backlogitem-form-fields';
import { CEBacklogitemAttributes } from './ce-backlogitem-attributes'


export class CEBacklogitemBase extends CEBase {

    public details: CEBacklogitemAttributes;
    public fromFields: PutBacklogitemFormFields = new PutBacklogitemFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: BacklogitemCore,
        public entity: SORespBacklogitem,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEBacklogitemAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.summary)
        this.attributes.push(this.details.details)
        this.attributes.push(this.details.release)

    }
}