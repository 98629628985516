import {SOPutAgreemonth} from '../../core/agreemonth/so-put-agreemonth';
import {AgreemonthCoreBase} from './agreemonth.core.base';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {FormField, SelectionOption} from 'lionheartland';
import {putAgreemonthFormFieldOptions} from './put-agreemonth-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {HmonthCore} from '../hmonth/hmonth.core';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {AgreementCore} from '../agreement/agreement.core';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {AgreeyearCore} from '../agreeyear/agreeyear.core';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';

export class RAPutAgreemonth extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAgreemonthFormFieldOptions);

  RequestClass = SOPutAgreemonth

  constructor(
    public entityCore: AgreemonthCoreBase
  ) {
    super();
    if (putAgreemonthFormFieldOptions.hmonth) {
      putAgreemonthFormFieldOptions.hmonth.makeCore = true;
    }
    if (putAgreemonthFormFieldOptions.agreement) {
      putAgreemonthFormFieldOptions.agreement.makeCore = true;
    }
    if (putAgreemonthFormFieldOptions.agreeyear) {
      putAgreemonthFormFieldOptions.agreeyear.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('mcq'),
      this.getFormValueFromIdentifier('delta'),
      this.getFormValueFromIdentifier(
        'hmonth',
        SOGetHmonth,
      ),
      this.getFormValueFromIdentifier(
        'agreement',
        SOGetAgreement,
      ),
      this.getFormValueFromIdentifier(
        'agreeyear',
        SOGetAgreeyear,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}