import { SOGetAccount } from '../account/so-get-account';
import { SOGetOrder } from '../order/so-get-order';
import { SOGetLoad } from '../load/so-get-load';
import { SOGetInvoice } from '../invoice/so-get-invoice';
import { SOGetDistributionday } from '../distributionday/so-get-distributionday';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetTransaction implements QueryProtocol {

  constructor(
    public uid: string = null,
    public account: SOGetAccount = null,
    public order: SOGetOrder = null,
    public load: SOGetLoad = null,
    public transactionType: string = null,
    public invoice: SOGetInvoice = null,
    public distributionday: SOGetDistributionday = null,
    public distributionmonth: SOGetDistributionmonth = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetTransaction(
      'uid' in params ? params.uid : null,
      'account' in params ? params.account : null,
      'order' in params ? params.order : null,
      'load' in params ? params.load : null,
      'transactionType' in params ? params.transactionType : null,
      'invoice' in params ? params.invoice : null,
      'distributionday' in params ? params.distributionday : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
