import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutSlotFormFields {

    public status: FormField
    public slotNumber: FormField
    public slotDay: FormField
    public start: FormField
    public end: FormField
    public account: FormField
    public calendar: FormField
    public distribution: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
        )
        this.slotNumber = new FormField(
          'slotNumber',
          'Slot number',
          null,
          FormFieldType.text,
          false,
        )
        this.slotDay = new FormField(
          'slotDay',
          'Slot day',
          null,
          FormFieldType.text,
          false,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.calendar = new FormField(
          'calendar',
          'Calendar',
          'calendar',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutSlotFormFields()

export const putSlotFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
  slotNumber: fields.slotNumber,
  slotDay: fields.slotDay,
  start: fields.start,
  end: fields.end,
  calendar: fields.calendar,
};