import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutHestatusitem } from './so-put-hestatusitem';
import { SOGetHestatusitem } from './so-get-hestatusitem';
import { SOPostHestatusitem } from './so-post-hestatusitem';
import { SODeleteHestatusitem } from './so-delete-hestatusitem';
import {SORespHestatusitem} from './so-resp-hestatusitem';
import {SOPluralHestatusitem} from './so-plural-hestatusitem';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOHestatusitemService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'hestatusitem',
      entityCore
    );
  }

  /** GET Hestatusitem by id. Will 404 if id not found */
  get(request: SOGetHestatusitem): Observable<SOPluralHestatusitem> {
    return this.httpClient.get<SOPluralHestatusitem>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralHestatusitem, resp) }),
      tap(_ => this.log(`fetched Hestatusitem`)),
      catchError(this.handleError<SOPluralHestatusitem>(`get Hestatusitem`))
    );
  }

  /** POST: add a new Hestatusitem to the server */
  post(request: SOPostHestatusitem): Observable<SORespHestatusitem> {
    return this.httpClient.post<SORespHestatusitem>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHestatusitem, resp) }),
      tap((response: SORespHestatusitem) => this.log(`added Hestatusitem w/ id=${response.uid}`)),
      catchError(this.handleError<SORespHestatusitem>('Hestatusitem post'))
    );
  }

  /** PUT: update Hestatusitem  */
  put(request: SOPutHestatusitem): Observable<SORespHestatusitem> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespHestatusitem>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHestatusitem, resp) }),
      tap((response: SORespHestatusitem) => this.log(`edited Hestatusitem w/ uid=${uid}`)),
      catchError(this.handleError<SORespHestatusitem>('Hestatusitem put'))
    );
  }

  /** DELETE: delete the Hestatusitem from the server */
  delete(request: SODeleteHestatusitem): Observable<SORespHestatusitem> {
    return this.httpClient.delete<SORespHestatusitem>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Hestatusitem uid=${request.uid}`)),
      catchError(this.handleError<SORespHestatusitem>('delete Hestatusitem'))
    );
  }
}
