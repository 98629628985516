import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutChronreport } from './so-put-chronreport';
import { SOGetChronreport } from './so-get-chronreport';
import { SOPostChronreport } from './so-post-chronreport';
import { SODeleteChronreport } from './so-delete-chronreport';
import {SORespChronreport} from './so-resp-chronreport';
import {SOPluralChronreport} from './so-plural-chronreport';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOChronreportService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'chronreport',
      entityCore
    );
  }

  /** GET Chronreport by id. Will 404 if id not found */
  get(request: SOGetChronreport): Observable<SOPluralChronreport> {
    return this.httpClient.get<SOPluralChronreport>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralChronreport, resp) }),
      tap(_ => this.log(`fetched Chronreport`)),
      catchError(this.handleError<SOPluralChronreport>(`get Chronreport`))
    );
  }

  /** POST: add a new Chronreport to the server */
  post(request: SOPostChronreport): Observable<SORespChronreport> {
    return this.httpClient.post<SORespChronreport>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespChronreport, resp) }),
      tap((response: SORespChronreport) => this.log(`added Chronreport w/ id=${response.uid}`)),
      catchError(this.handleError<SORespChronreport>('Chronreport post'))
    );
  }

  /** PUT: update Chronreport  */
  put(request: SOPutChronreport): Observable<SORespChronreport> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespChronreport>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespChronreport, resp) }),
      tap((response: SORespChronreport) => this.log(`edited Chronreport w/ uid=${uid}`)),
      catchError(this.handleError<SORespChronreport>('Chronreport put'))
    );
  }

  /** DELETE: delete the Chronreport from the server */
  delete(request: SODeleteChronreport): Observable<SORespChronreport> {
    return this.httpClient.delete<SORespChronreport>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Chronreport uid=${request.uid}`)),
      catchError(this.handleError<SORespChronreport>('delete Chronreport'))
    );
  }
}
