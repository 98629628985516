

export class SOPostOrganization {

  constructor(
    public handle: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostOrganization(
      'handle' in params ? params.handle : null,
    );
  }
}
