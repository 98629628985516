import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostCustomerFormFields {

    public companyName: FormField
    public emailDomain: FormField
    public inquiry: FormField

    constructor() {

        this.companyName = new FormField(
          'companyName',
          'Company name',
          null,
          FormFieldType.text,
          true,
        )
        this.emailDomain = new FormField(
          'emailDomain',
          'Email domain',
          null,
          FormFieldType.text,
          false,
        )
        this.inquiry = new FormField(
          'inquiry',
          'Inquiry',
          'inquiry',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostCustomerFormFields()

export const postCustomerFormFieldOptions: {[key: string]: FormField} = {
  companyName: fields.companyName,
  emailDomain: fields.emailDomain,
  inquiry: fields.inquiry,
};