import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTransactionService} from '../../core/transaction/eo-transaction';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {RAPutTransaction} from './ra-put-transaction';
import {RAPostTransaction} from './ra-post-transaction';
import {RADeleteTransaction} from './ra-delete-transaction';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import { CETransaction } from "./ce-transaction"


export class TransactionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'transaction';
  title = 'Transaction';
  ceProtocol: CEConstructor = CETransaction

  entityService: EOTransactionService;
  soGetClass = SOGetTransaction;
  soGet: SOGetTransaction;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTransactionService
    );
    this.adapters = [
      new RAPostTransaction(this),
      new RAPutTransaction(this),
      new RADeleteTransaction(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTransaction();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}