import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutBol } from './so-put-bol';
import { SOGetBol } from './so-get-bol';
import { SOPostBol } from './so-post-bol';
import { SODeleteBol } from './so-delete-bol';
import {SORespBol} from './so-resp-bol';
import {SOPluralBol} from './so-plural-bol';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOBolService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'bol',
      entityCore
    );
  }

  /** GET Bol by id. Will 404 if id not found */
  get(request: SOGetBol): Observable<SOPluralBol> {
    return this.httpClient.get<SOPluralBol>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralBol, resp) }),
      tap(_ => this.log(`fetched Bol`)),
      catchError(this.handleError<SOPluralBol>(`get Bol`))
    );
  }

  /** POST: add a new Bol to the server */
  post(request: SOPostBol): Observable<SORespBol> {
    return this.httpClient.post<SORespBol>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBol, resp) }),
      tap((response: SORespBol) => this.log(`added Bol w/ id=${response.uid}`)),
      catchError(this.handleError<SORespBol>('Bol post'))
    );
  }

  /** PUT: update Bol  */
  put(request: SOPutBol): Observable<SORespBol> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespBol>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBol, resp) }),
      tap((response: SORespBol) => this.log(`edited Bol w/ uid=${uid}`)),
      catchError(this.handleError<SORespBol>('Bol put'))
    );
  }

  /** DELETE: delete the Bol from the server */
  delete(request: SODeleteBol): Observable<SORespBol> {
    return this.httpClient.delete<SORespBol>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Bol uid=${request.uid}`)),
      catchError(this.handleError<SORespBol>('delete Bol'))
    );
  }
}
