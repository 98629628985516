import { SOGetTempclin } from '../tempclin/so-get-tempclin';
import { SOGetTemptract } from '../temptract/so-get-temptract';


export class SOPutTemptraclin {

  constructor(
    public uid: string,
    public tempclin: SOGetTempclin = null,
    public temptract: SOGetTemptract = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTemptraclin(
      'uid' in params ? params.uid : null,
      'tempclin' in params ? params.tempclin : null,
      'temptract' in params ? params.temptract : null,
    );
  }
}
