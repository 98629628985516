import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutAwardFormFields {


    constructor() {


    }
}

const fields = new PutAwardFormFields()

export const putAwardFormFieldOptions: {[key: string]: FormField} = {
};