import {SORespAward} from '../../core/award/so-resp-award';
import {AwardCore} from './award.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAwardFormFields} from './put-award-form-fields';
import {SOGetAward} from '../../core/award/so-get-award';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEAwardAttributesBase {

    dateCreated: DetailEntry
    amount: DetailEntry
    quantity: DetailEntry
    loadQuantity: DetailEntry
    notes: DetailEntry
    bid: DetailEntry
    agreement: DetailEntry
    feeschedule: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAward,
        app: GasDirectApp,
        formFields: PutAwardFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.amount = new DetailEntry(
            'Amount',
            entity.amount,
            'amount',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.loadQuantity = new DetailEntry(
            'Load quantity',
            entity.loadQuantity,
            'loadQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.notes = new DetailEntry(
            'Notes',
            entity.notes,
            'notes',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const bidCore = app.makeCore( 'bid');
        bidCore.soGet = SOGetBid.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        let bidCeProtocol;
        if (entity.bid) {
            bidCeProtocol = createCEProtocol(
                bidCore.ceProtocol,
                parentEntityViewSpec,
                bidCore,
                entity.bid,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            bidCore
        )
        bidCore.title = heading ? heading : bidCore.title
        bidCore.listTitle = 'Bid'

        if (entity.bid) {
            displayStringFactory.reconfigure(
                entity.bid,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        bidCore.configureForDisplay(parentEntityViewSpec)

        this.bid = new DetailEntry(
          heading ? heading : bidCore.listTitle,
          bidCeProtocol ? bidCeProtocol.entityListView.fullHeading : null,
          'bid',
          BaseDetailType.pushedDetail,
          bidCore,
          EditableType.never
        )
        if (entity.bid) {
          this.bid.ceProtocol = bidCeProtocol;
          this.bid.cachedListEntry = bidCore.makeListEntry(parentEntityViewSpec, entity.bid, 0)
          bidCore.selectedEntity = entity.bid;
        }
        this.bid.singleFieldSelect = true

        const agreementCore = app.makeCore( 'agreement');
        agreementCore.soGet = SOGetAgreement.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        let agreementCeProtocol;
        if (entity.agreement) {
            agreementCeProtocol = createCEProtocol(
                agreementCore.ceProtocol,
                parentEntityViewSpec,
                agreementCore,
                entity.agreement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementCore
        )
        agreementCore.title = heading ? heading : agreementCore.title
        agreementCore.listTitle = 'Agreement'

        if (entity.agreement) {
            displayStringFactory.reconfigure(
                entity.agreement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreementCore.configureForDisplay(parentEntityViewSpec)

        this.agreement = new DetailEntry(
          heading ? heading : agreementCore.listTitle,
          agreementCeProtocol ? agreementCeProtocol.entityListView.fullHeading : null,
          'agreement',
          BaseDetailType.pushedDetail,
          agreementCore,
          EditableType.never
        )
        if (entity.agreement) {
          this.agreement.ceProtocol = agreementCeProtocol;
          this.agreement.cachedListEntry = agreementCore.makeListEntry(parentEntityViewSpec, entity.agreement, 0)
          agreementCore.selectedEntity = entity.agreement;
        }
        this.agreement.singleFieldSelect = true

        const feescheduleCore = app.makeCore( 'feeschedule');
        feescheduleCore.soGet = SOGetFeeschedule.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feescheduleCore
        )
        feescheduleCore.title = heading ? heading : feescheduleCore.title
        feescheduleCore.listTitle = 'Feeschedule'

        feescheduleCore.configureForDisplay(parentEntityViewSpec)

        this.feeschedule = new DetailEntry(
          heading ? heading : feescheduleCore.listTitle,
          null,
          'feeschedule',
          BaseDetailType.pushedDetail,
          feescheduleCore,
          EditableType.never
        )
        this.feeschedule.singleFieldSelect = true
    }
}