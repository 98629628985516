import {SORespOrder} from '../../core/order/so-resp-order';
import {OrderCore} from './order.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutOrderFormFields} from './put-order-form-fields';
import { CEOrderAttributes } from './ce-order-attributes'


export class CEOrderBase extends CEBase {

    public details: CEOrderAttributes;
    public fromFields: PutOrderFormFields = new PutOrderFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: OrderCore,
        public entity: SORespOrder,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEOrderAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.amount)
        this.attributes.push(this.details.paidAmount)
        this.attributes.push(this.details.orderDate)
        this.attributes.push(this.details.ref)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.receiptUrl)
        this.attributes.push(this.details.transactions)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.agreement)
        this.attributes.push(this.details.agreemonth)
        this.attributes.push(this.details.distributionmonth)
        this.attributes.push(this.details.items)
        this.attributes.push(this.details.quotelets)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.activitys)

    }
}