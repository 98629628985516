

export class SOPostCarrier {

  constructor(
    public companyName: string,
    public emailDomain: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCarrier(
      'companyName' in params ? params.companyName : null,
      'emailDomain' in params ? params.emailDomain : null,
    );
  }
}
