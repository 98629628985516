import { SOGetAccount } from '../account/so-get-account';
import { SOGetDistributionyear } from '../distributionyear/so-get-distributionyear';


export class SOPostHyear {

  constructor(
    public state: string,
    public year: number,
    public account: SOGetAccount,
    public distributionyear: SOGetDistributionyear,
    public acq: number = 0,
    public acqUsed: number = 0,
    public spotQuantity: number = 0,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostHyear(
      'state' in params ? params.state : null,
      'acq' in params ? params.acq : null,
      'acqUsed' in params ? params.acqUsed : null,
      'year' in params ? params.year : null,
      'account' in params ? params.account : null,
      'spotQuantity' in params ? params.spotQuantity : null,
      'distributionyear' in params ? params.distributionyear : null,
    );
  }
}
