import { SOGetTank } from '../tank/so-get-tank';


export class SOPostTankreport {

  constructor(
    public value: number,
    public tank: SOGetTank,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTankreport(
      'value' in params ? params.value : null,
      'tank' in params ? params.tank : null,
    );
  }
}
