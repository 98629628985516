import { Type } from 'class-transformer';
import { SORespHemon } from '../hemon/so-resp-hemon';
import { SORespLoad } from '../load/so-resp-load';
import { SORespWeight } from '../weight/so-resp-weight';
import { SORespContainersession } from '../containersession/so-resp-containersession';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTask implements ObjectiveProtocol {

  entityType: string = 'task';
  uid: string;
  @Type(() => SORespHemon)
  hemon: SORespHemon = null;
  @Type(() => Date)
  dateCreated: Date;
  trailerGone: boolean = false;
  completed: boolean = false;
  @Type(() => SORespLoad)
  load: SORespLoad = null;
  @Type(() => SORespWeight)
  weight: SORespWeight = null;
  location: string = null;
  loadStatus: string = null;
  net: number = null;
  @Type(() => SORespContainersession)
  containersession: SORespContainersession = null;
}