import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutItem } from './so-put-item';
import { SOGetItem } from './so-get-item';
import { SOPostItem } from './so-post-item';
import { SODeleteItem } from './so-delete-item';
import {SORespItem} from './so-resp-item';
import {SOPluralItem} from './so-plural-item';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOItemService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'item',
      entityCore
    );
  }

  /** GET Item by id. Will 404 if id not found */
  get(request: SOGetItem): Observable<SOPluralItem> {
    return this.httpClient.get<SOPluralItem>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralItem, resp) }),
      tap(_ => this.log(`fetched Item`)),
      catchError(this.handleError<SOPluralItem>(`get Item`))
    );
  }

  /** POST: add a new Item to the server */
  post(request: SOPostItem): Observable<SORespItem> {
    return this.httpClient.post<SORespItem>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespItem, resp) }),
      tap((response: SORespItem) => this.log(`added Item w/ id=${response.uid}`)),
      catchError(this.handleError<SORespItem>('Item post'))
    );
  }

  /** PUT: update Item  */
  put(request: SOPutItem): Observable<SORespItem> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespItem>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespItem, resp) }),
      tap((response: SORespItem) => this.log(`edited Item w/ uid=${uid}`)),
      catchError(this.handleError<SORespItem>('Item put'))
    );
  }

  /** DELETE: delete the Item from the server */
  delete(request: SODeleteItem): Observable<SORespItem> {
    return this.httpClient.delete<SORespItem>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Item uid=${request.uid}`)),
      catchError(this.handleError<SORespItem>('delete Item'))
    );
  }
}
