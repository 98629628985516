import {SOPostServiceRequest} from '../../core/service-request/so-post-service-request';
import {ServiceRequestCoreBase} from './service-request.core.base';
import {SOGetServiceRequest} from '../../core/service-request/so-get-service-request';
import {FormField, SelectionOption} from 'lionheartland';
import {postServiceRequestFormFieldOptions} from './post-service-request-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributiondayCore} from '../distributionday/distributionday.core';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {ContainersessionCore} from '../containersession/containersession.core';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';

export class RAPostServiceRequest extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postServiceRequestFormFieldOptions);

  RequestClass = SOPostServiceRequest

  constructor(
    public entityCore: ServiceRequestCoreBase
  ) {
    super();
    if (postServiceRequestFormFieldOptions.distributionday) {
      postServiceRequestFormFieldOptions.distributionday.makeCore = true;
    }
    if (postServiceRequestFormFieldOptions.containersession) {
      postServiceRequestFormFieldOptions.containersession.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('dateCreated'),
      this.getFormValueFromIdentifier('dateRequested'),
      this.getFormValueFromIdentifier('serviceType'),
      this.getFormValueFromIdentifier(
        'distributionday',
        SOGetDistributionday,
      ),
      this.getFormValueFromIdentifier(
        'containersession',
        SOGetContainersession,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}