import {SORespTempagreement} from '../../core/tempagreement/so-resp-tempagreement';
import {TempagreementCore} from './tempagreement.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTempagreementFormFields} from './put-tempagreement-form-fields';
import { CETempagreementAttributes } from './ce-tempagreement-attributes'


export class CETempagreementBase extends CEBase {

    public details: CETempagreementAttributes;
    public fromFields: PutTempagreementFormFields = new PutTempagreementFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: TempagreementCore,
        public entity: SORespTempagreement,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CETempagreementAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.autoRenew)
        this.attributes.push(this.details.docusignLink)
        this.attributes.push(this.details.units)
        this.attributes.push(this.details.durationDays)
        this.attributes.push(this.details.acq)
        this.attributes.push(this.details.top)
        this.attributes.push(this.details.agreements)
        this.attributes.push(this.details.feeschedule)
        this.attributes.push(this.details.tempagreetracts)
        this.attributes.push(this.details.disttempagreements)

    }
}