import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOChronreportService} from '../../core/chronreport/eo-chronreport';
import {SOGetChronreport} from '../../core/chronreport/so-get-chronreport';
import {RAPutChronreport} from './ra-put-chronreport';
import {RAPostChronreport} from './ra-post-chronreport';
import {RADeleteChronreport} from './ra-delete-chronreport';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEChronreport } from "./ce-chronreport"


export class ChronreportCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'chronreport';
  title = 'Chronreport';
  ceProtocol: CEConstructor = CEChronreport

  entityService: EOChronreportService;
  soGetClass = SOGetChronreport;
  soGet: SOGetChronreport;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOChronreportService
    );
    this.adapters = [
      new RAPostChronreport(this),
      new RAPutChronreport(this),
      new RADeleteChronreport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetChronreport();
    this.soGet.queryOptions.limit = this.limit;
  }


}