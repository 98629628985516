import { SOGetTempagreement } from '../tempagreement/so-get-tempagreement';
import { SOGetTemptract } from '../temptract/so-get-temptract';


export class SOPostTempagreetract {

  constructor(
    public tempagreement: SOGetTempagreement,
    public temptract: SOGetTemptract,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTempagreetract(
      'tempagreement' in params ? params.tempagreement : null,
      'temptract' in params ? params.temptract : null,
    );
  }
}
