import {SORespRateableagreeyearallocation} from '../../core/rateableagreeyearallocation/so-resp-rateableagreeyearallocation';
import {RateableagreeyearallocationCore} from './rateableagreeyearallocation.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutRateableagreeyearallocationFormFields} from './put-rateableagreeyearallocation-form-fields';
import {SOGetRateableagreeyearallocation} from '../../core/rateableagreeyearallocation/so-get-rateableagreeyearallocation';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';


export class CERateableagreeyearallocationAttributesBase {

    dateCreated: DetailEntry
    jan: DetailEntry
    feb: DetailEntry
    mar: DetailEntry
    apr: DetailEntry
    may: DetailEntry
    jun: DetailEntry
    jul: DetailEntry
    aug: DetailEntry
    sep: DetailEntry
    oct: DetailEntry
    nov: DetailEntry
    dec: DetailEntry
    agreeyear: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespRateableagreeyearallocation,
        app: GasDirectApp,
        formFields: PutRateableagreeyearallocationFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.jan = new DetailEntry(
            'Jan',
            entity.jan,
            'jan',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.feb = new DetailEntry(
            'Feb',
            entity.feb,
            'feb',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.mar = new DetailEntry(
            'Mar',
            entity.mar,
            'mar',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.apr = new DetailEntry(
            'Apr',
            entity.apr,
            'apr',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.may = new DetailEntry(
            'May',
            entity.may,
            'may',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.jun = new DetailEntry(
            'Jun',
            entity.jun,
            'jun',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.jul = new DetailEntry(
            'Jul',
            entity.jul,
            'jul',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.aug = new DetailEntry(
            'Aug',
            entity.aug,
            'aug',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.sep = new DetailEntry(
            'Sep',
            entity.sep,
            'sep',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.oct = new DetailEntry(
            'Oct',
            entity.oct,
            'oct',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.nov = new DetailEntry(
            'Nov',
            entity.nov,
            'nov',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.dec = new DetailEntry(
            'Dec',
            entity.dec,
            'dec',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const agreeyearCore = app.makeCore( 'agreeyear');
        agreeyearCore.soGet = SOGetAgreeyear.construct({
        });
        let agreeyearCeProtocol;
        if (entity.agreeyear) {
            agreeyearCeProtocol = createCEProtocol(
                agreeyearCore.ceProtocol,
                parentEntityViewSpec,
                agreeyearCore,
                entity.agreeyear,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreeyearCore
        )
        agreeyearCore.title = heading ? heading : agreeyearCore.title
        agreeyearCore.listTitle = 'Agreeyear'

        if (entity.agreeyear) {
            displayStringFactory.reconfigure(
                entity.agreeyear,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreeyearCore.configureForDisplay(parentEntityViewSpec)

        this.agreeyear = new DetailEntry(
          heading ? heading : agreeyearCore.listTitle,
          agreeyearCeProtocol ? agreeyearCeProtocol.entityListView.fullHeading : null,
          'agreeyear',
          BaseDetailType.pushedDetail,
          agreeyearCore,
          EditableType.never
        )
        if (entity.agreeyear) {
          this.agreeyear.ceProtocol = agreeyearCeProtocol;
          this.agreeyear.cachedListEntry = agreeyearCore.makeListEntry(parentEntityViewSpec, entity.agreeyear, 0)
          agreeyearCore.selectedEntity = entity.agreeyear;
        }
        this.agreeyear.singleFieldSelect = true
    }
}