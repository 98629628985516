import {SOPutBol} from '../../core/bol/so-put-bol';
import {BolCoreBase} from './bol.core.base';
import {SOGetBol} from '../../core/bol/so-get-bol';
import {FormField, SelectionOption} from 'lionheartland';
import {putBolFormFieldOptions} from './put-bol-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutBol extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putBolFormFieldOptions);

  RequestClass = SOPutBol

  constructor(
    public entityCore: BolCoreBase
  ) {
    super();
    if (putBolFormFieldOptions.carrier) {
      putBolFormFieldOptions.carrier.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('carrierSignature'),
      this.getFormValueFromIdentifier('providerSignature'),
      this.getFormValueFromIdentifier('driverName'),
      this.getFormValueFromIdentifier('destination'),
      this.getFormValueFromIdentifier(
        'carrier',
        SOGetOrganization,
      ),
      this.getFormValueFromIdentifier('rearAxelWeight'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}