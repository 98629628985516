import {SOPostSlotlet} from '../../core/slotlet/so-post-slotlet';
import {SlotletCoreBase} from './slotlet.core.base';
import {SOGetSlotlet} from '../../core/slotlet/so-get-slotlet';
import {FormField, SelectionOption} from 'lionheartland';
import {postSlotletFormFieldOptions} from './post-slotlet-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {EventCore} from '../event/event.core';
import {SOGetEvent} from '../../core/event/so-get-event';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {TempclinCore} from '../tempclin/tempclin.core';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';

export class RAPostSlotlet extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSlotletFormFieldOptions);

  RequestClass = SOPostSlotlet

  constructor(
    public entityCore: SlotletCoreBase
  ) {
    super();
    if (postSlotletFormFieldOptions.event) {
      postSlotletFormFieldOptions.event.makeCore = true;
    }
    if (postSlotletFormFieldOptions.account) {
      postSlotletFormFieldOptions.account.makeCore = true;
    }
    if (postSlotletFormFieldOptions.tempclin) {
      postSlotletFormFieldOptions.tempclin.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('dayOffset'),
      this.getFormValueFromIdentifier('slotNumber'),
      this.getFormValueFromIdentifier(
        'event',
        SOGetEvent,
      ),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
      ),
      this.getFormValueFromIdentifier(
        'tempclin',
        SOGetTempclin,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}