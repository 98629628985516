import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOInvoiceitemService} from '../../core/invoiceitem/eo-invoiceitem';
import {SOGetInvoiceitem} from '../../core/invoiceitem/so-get-invoiceitem';
import {RAPutInvoiceitem} from './ra-put-invoiceitem';
import {RAPostInvoiceitem} from './ra-post-invoiceitem';
import {RADeleteInvoiceitem} from './ra-delete-invoiceitem';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEInvoiceitem } from "./ce-invoiceitem"


export class InvoiceitemCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'invoiceitem';
  title = 'Invoiceitem';
  ceProtocol: CEConstructor = CEInvoiceitem

  entityService: EOInvoiceitemService;
  soGetClass = SOGetInvoiceitem;
  soGet: SOGetInvoiceitem;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOInvoiceitemService
    );
    this.adapters = [
      new RAPostInvoiceitem(this),
      new RAPutInvoiceitem(this),
      new RADeleteInvoiceitem(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetInvoiceitem();
    this.soGet.queryOptions.limit = this.limit;
  }


}