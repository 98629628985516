import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostSaleFormFields {

    public description: FormField
    public distribution: FormField
    public distributionmonth: FormField

    constructor() {

        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          true,
          true,
          ""
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.distributionmonth = new FormField(
          'distributionmonth',
          'Distributionmonth',
          'distributionmonth',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostSaleFormFields()

export const postSaleFormFieldOptions: {[key: string]: FormField} = {
  description: fields.description,
  distribution: fields.distribution,
  distributionmonth: fields.distributionmonth,
};