import {SORespHello} from '../../core/hello/so-resp-hello';
import {HelloCore} from './hello.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHelloFormFields} from './put-hello-form-fields';
import {SOGetHello} from '../../core/hello/so-get-hello';


export class CEHelloAttributesBase {

    message: DetailEntry
    loginStyle: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespHello,
        app: GasDirectApp,
        formFields: PutHelloFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.message = new DetailEntry(
            'Message',
            entity.message,
            'message',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.message
        )

        this.loginStyle = new DetailEntry(
            'Login style',
            entity.loginStyle,
            'loginStyle',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loginStyle
        )

    }
}