import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostAgreemonthFormFields {

    public state: FormField
    public mcq: FormField
    public mcqUsed: FormField
    public hmonth: FormField
    public agreement: FormField
    public year: FormField
    public month: FormField
    public agreeyear: FormField
    public spotQuantity: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          true,
        )
        this.mcq = new FormField(
          'mcq',
          'Mcq',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.mcqUsed = new FormField(
          'mcqUsed',
          'Mcq used',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.hmonth = new FormField(
          'hmonth',
          'Hmonth',
          'hmonth',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.agreement = new FormField(
          'agreement',
          'Agreement',
          'agreement',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.year = new FormField(
          'year',
          'Year',
          null,
          FormFieldType.text,
          true,
        )
        this.month = new FormField(
          'month',
          'Month',
          null,
          FormFieldType.text,
          true,
        )
        this.agreeyear = new FormField(
          'agreeyear',
          'Agreeyear',
          'agreeyear',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.spotQuantity = new FormField(
          'spotQuantity',
          'Spot quantity',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )

    }
}

const fields = new PostAgreemonthFormFields()

export const postAgreemonthFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  mcq: fields.mcq,
  mcqUsed: fields.mcqUsed,
  hmonth: fields.hmonth,
  agreement: fields.agreement,
  year: fields.year,
  month: fields.month,
  agreeyear: fields.agreeyear,
  spotQuantity: fields.spotQuantity,
};