import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOClinyearlngService} from '../../core/clinyearlng/eo-clinyearlng';
import {SOGetClinyearlng} from '../../core/clinyearlng/so-get-clinyearlng';
import {RAPutClinyearlng} from './ra-put-clinyearlng';
import {RAPostClinyearlng} from './ra-post-clinyearlng';
import {RADeleteClinyearlng} from './ra-delete-clinyearlng';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEClinyearlng } from "./ce-clinyearlng"


export class ClinyearlngCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'clinyearlng';
  title = 'Clinyearlng';
  ceProtocol: CEConstructor = CEClinyearlng

  entityService: EOClinyearlngService;
  soGetClass = SOGetClinyearlng;
  soGet: SOGetClinyearlng;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOClinyearlngService
    );
    this.adapters = [
      new RAPostClinyearlng(this),
      new RAPutClinyearlng(this),
      new RADeleteClinyearlng(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetClinyearlng();
    this.soGet.queryOptions.limit = this.limit;
  }


}