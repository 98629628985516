import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutNavroaFormFields {

    public index: FormField
    public mainNav: FormField

    constructor() {

        this.index = new FormField(
          'index',
          'Index',
          null,
          FormFieldType.text,
          false,
        )
        this.mainNav = new FormField(
          'mainNav',
          'Main nav',
          null,
          FormFieldType.boolean,
          false,
        )

    }
}

const fields = new PutNavroaFormFields()

export const putNavroaFormFieldOptions: {[key: string]: FormField} = {
  index: fields.index,
  mainNav: fields.mainNav,
};