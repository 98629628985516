import {SORespFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-resp-feescheduleagreementasc';
import {FeescheduleagreementascCore} from './feescheduleagreementasc.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeescheduleagreementascFormFields} from './put-feescheduleagreementasc-form-fields';
import { CEFeescheduleagreementascAttributes } from './ce-feescheduleagreementasc-attributes'


export class CEFeescheduleagreementascBase extends CEBase {

    public details: CEFeescheduleagreementascAttributes;
    public fromFields: PutFeescheduleagreementascFormFields = new PutFeescheduleagreementascFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: FeescheduleagreementascCore,
        public entity: SORespFeescheduleagreementasc,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEFeescheduleagreementascAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.agreement)
        this.attributes.push(this.details.feeschedule)

    }
}