import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutActivityFormFields {

    public body: FormField

    constructor() {

        this.body = new FormField(
          'body',
          'Body',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutActivityFormFields()

export const putActivityFormFieldOptions: {[key: string]: FormField} = {
  body: fields.body,
};