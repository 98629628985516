import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostHelloFormFields {

    public message: FormField
    public loginStyle: FormField

    constructor() {

        this.message = new FormField(
          'message',
          'Message',
          null,
          FormFieldType.text,
          true,
        )
        this.loginStyle = new FormField(
          'loginStyle',
          'Login style',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostHelloFormFields()

export const postHelloFormFieldOptions: {[key: string]: FormField} = {
  message: fields.message,
  loginStyle: fields.loginStyle,
};