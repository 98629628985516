import { Type } from 'class-transformer';
import { SORespAgreement } from '../agreement/so-resp-agreement';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespAgreeyear implements ObjectiveProtocol {

  entityType: string = 'agreeyear';
  uid: string;
  state: string;
  acq: number = 0;
  acqUsed: number = 0;
  year: number;
  @Type(() => SORespAgreement)
  agreement: SORespAgreement;
  spotQuantity: number = 0;
  spotQuantityUsed: number = 0;
  hasPricing: boolean = null;
  hasFeeschedule: boolean = null;
  acqInitial: number = null;
}