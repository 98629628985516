import { SOGetSlot } from '../slot/so-get-slot';
import { SOGetLoad } from '../load/so-get-load';


export class SOPutReservation {

  constructor(
    public uid: string,
    public status: string = null,
    public start: Date = null,
    public end: Date = null,
    public description: string = null,
    public slot: SOGetSlot = null,
    public load: SOGetLoad = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutReservation(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'description' in params ? params.description : null,
      'slot' in params ? params.slot : null,
      'load' in params ? params.load : null,
    );
  }
}
