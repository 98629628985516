import { Type } from 'class-transformer';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import { SORespExternalindexprice } from '../externalindexprice/so-resp-externalindexprice';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespIndexprice implements ObjectiveProtocol {

  entityType: string = 'indexprice';
  uid: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  state: string;
  rate: number;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution;
  @Type(() => SORespExternalindexprice)
  externalindexprice: SORespExternalindexprice = null;
}