import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutUser } from './so-put-user';
import { SOGetUser } from './so-get-user';
import { SOPostUser } from './so-post-user';
import { SODeleteUser } from './so-delete-user';
import {SORespUser} from './so-resp-user';
import {SOPluralUser} from './so-plural-user';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOUserService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'user',
      entityCore
    );
  }

  /** GET User by id. Will 404 if id not found */
  get(request: SOGetUser): Observable<SOPluralUser> {
    return this.httpClient.get<SOPluralUser>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralUser, resp) }),
      tap(_ => this.log(`fetched User`)),
      catchError(this.handleError<SOPluralUser>(`get User`))
    );
  }

  /** POST: add a new User to the server */
  post(request: SOPostUser): Observable<SORespUser> {
    return this.httpClient.post<SORespUser>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespUser, resp) }),
      tap((response: SORespUser) => this.log(`added User w/ id=${response.uid}`)),
      catchError(this.handleError<SORespUser>('User post'))
    );
  }

  /** PUT: update User  */
  put(request: SOPutUser): Observable<SORespUser> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespUser>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespUser, resp) }),
      tap((response: SORespUser) => this.log(`edited User w/ uid=${uid}`)),
      catchError(this.handleError<SORespUser>('User put'))
    );
  }

  /** DELETE: delete the User from the server */
  delete(request: SODeleteUser): Observable<SORespUser> {
    return this.httpClient.delete<SORespUser>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted User uid=${request.uid}`)),
      catchError(this.handleError<SORespUser>('delete User'))
    );
  }
}
