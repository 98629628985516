import { SOGetContainersession } from '../containersession/so-get-containersession';
import { SOGetLoad } from '../load/so-get-load';


export class SOPostDelta {

  constructor(
    public type: string,
    public billable: boolean,
    public containersession: SOGetContainersession = null,
    public load: SOGetLoad = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDelta(
      'type' in params ? params.type : null,
      'containersession' in params ? params.containersession : null,
      'load' in params ? params.load : null,
      'billable' in params ? params.billable : null,
    );
  }
}
