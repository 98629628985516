import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTemptraclinService} from '../../core/temptraclin/eo-temptraclin';
import {SOGetTemptraclin} from '../../core/temptraclin/so-get-temptraclin';
import {RAPutTemptraclin} from './ra-put-temptraclin';
import {RAPostTemptraclin} from './ra-post-temptraclin';
import {RADeleteTemptraclin} from './ra-delete-temptraclin';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETemptraclin } from "./ce-temptraclin"


export class TemptraclinCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'temptraclin';
  title = 'Temptraclin';
  ceProtocol: CEConstructor = CETemptraclin

  entityService: EOTemptraclinService;
  soGetClass = SOGetTemptraclin;
  soGet: SOGetTemptraclin;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTemptraclinService
    );
    this.adapters = [
      new RAPostTemptraclin(this),
      new RAPutTemptraclin(this),
      new RADeleteTemptraclin(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTemptraclin();
    this.soGet.queryOptions.limit = this.limit;
  }


}