import {SORespLine} from '../../core/line/so-resp-line';
import {LineCore} from './line.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutLineFormFields} from './put-line-form-fields';
import { CELineAttributes } from './ce-line-attributes'


export class CELineBase extends CEBase {

    public details: CELineAttributes;
    public fromFields: PutLineFormFields = new PutLineFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: LineCore,
        public entity: SORespLine,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CELineAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.units)
        this.attributes.push(this.details.plant)
        this.attributes.push(this.details.product)
        this.attributes.push(this.details.production)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.dcss)
        this.attributes.push(this.details.linags)

    }
}