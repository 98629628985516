import {SORespImpurity} from '../../core/impurity/so-resp-impurity';
import {ImpurityCore} from './impurity.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutImpurityFormFields} from './put-impurity-form-fields';
import {SOGetImpurity} from '../../core/impurity/so-get-impurity';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEImpurityAttributesBase {

    dateCreated: DetailEntry
    state: DetailEntry
    h20: DetailEntry
    neon: DetailEntry
    h2: DetailEntry
    o2: DetailEntry
    n2: DetailEntry
    carbonMonoxide: DetailEntry
    hydrocarbons: DetailEntry
    carbonDioxide: DetailEntry
    load: DetailEntry
    containersession: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespImpurity,
        app: GasDirectApp,
        formFields: PutImpurityFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.h20 = new DetailEntry(
            'H20',
            entity.h20,
            'h20',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.h20
        )

        this.neon = new DetailEntry(
            'Neon',
            entity.neon,
            'neon',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.neon
        )

        this.h2 = new DetailEntry(
            'H2',
            entity.h2,
            'h2',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.h2
        )

        this.o2 = new DetailEntry(
            'O2',
            entity.o2,
            'o2',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.o2
        )

        this.n2 = new DetailEntry(
            'N2',
            entity.n2,
            'n2',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.n2
        )

        this.carbonMonoxide = new DetailEntry(
            'Carbon monoxide',
            entity.carbonMonoxide,
            'carbonMonoxide',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.carbonMonoxide
        )

        this.hydrocarbons = new DetailEntry(
            'Hydrocarbons',
            entity.hydrocarbons,
            'hydrocarbons',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.hydrocarbons
        )

        this.carbonDioxide = new DetailEntry(
            'Carbon dioxide',
            entity.carbonDioxide,
            'carbonDioxide',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.carbonDioxide
        )


        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.always,
          formFields.load
        )
        this.load.singleFieldSelect = true

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        this.containersession.singleFieldSelect = true
    }
}