import {SORespAgreemonth} from '../../core/agreemonth/so-resp-agreemonth';
import {AgreemonthCore} from './agreemonth.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAgreemonthFormFields} from './put-agreemonth-form-fields';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetAdjustment} from '../../core/adjustment/so-get-adjustment';
import {SOGetSlotoption} from '../../core/slotoption/so-get-slotoption';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEAgreemonthAttributesBase {

    orders: DetailEntry
    loads: DetailEntry
    state: DetailEntry
    mcq: DetailEntry
    mcqUsed: DetailEntry
    delta: DetailEntry
    hmonth: DetailEntry
    agreement: DetailEntry
    year: DetailEntry
    month: DetailEntry
    agreeyear: DetailEntry
    spotQuantity: DetailEntry
    spotQuantityUsed: DetailEntry
    mcqEstimated: DetailEntry
    distributionmonth: DetailEntry
    mcqInitial: DetailEntry
    adjustments: DetailEntry
    slotoptions: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAgreemonth,
        app: GasDirectApp,
        formFields: PutAgreemonthFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.mcq = new DetailEntry(
            'Mcq',
            entity.mcq,
            'mcq',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.mcq
        )

        this.mcqUsed = new DetailEntry(
            'Mcq used',
            entity.mcqUsed,
            'mcqUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.delta = new DetailEntry(
            'Delta',
            entity.delta,
            'delta',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.delta
        )

        this.year = new DetailEntry(
            'Year',
            entity.year,
            'year',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.year.listHeadingIndex = 1;

        this.month = new DetailEntry(
            'Month',
            entity.month,
            'month',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.month.listHeadingIndex = 2;

        this.spotQuantity = new DetailEntry(
            'Spot quantity',
            entity.spotQuantity,
            'spotQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.spotQuantityUsed = new DetailEntry(
            'Spot quantity used',
            entity.spotQuantityUsed,
            'spotQuantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.mcqEstimated = new DetailEntry(
            'Mcq estimated',
            entity.mcqEstimated,
            'mcqEstimated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.mcqInitial = new DetailEntry(
            'Mcq initial',
            entity.mcqInitial,
            'mcqInitial',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const ordersCore = app.makeCore( 'order');
        ordersCore.soGet = SOGetOrder.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreement: app.getScope('agreement') ? new SOGetAgreement(app.getScope('agreement').uid) : null,
          agreemonth: new SOGetAgreemonth(entity.uid),
          distributionmonth: app.getScope('distributionmonth') ? new SOGetDistributionmonth(app.getScope('distributionmonth').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            ordersCore
        )
        ordersCore.title = heading ? heading : ordersCore.title
        ordersCore.listTitle = 'Orders'

        ordersCore.configureForDisplay(parentEntityViewSpec)

        this.orders = new DetailEntry(
          heading ? heading : ordersCore.listTitle,
          null,
          'orders',
          BaseDetailType.embeddedList,
          ordersCore,
          EditableType.never
        )

        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreemonth: new SOGetAgreemonth(entity.uid),
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.never
        )

        const hmonthCore = app.makeCore( 'hmonth');
        hmonthCore.soGet = SOGetHmonth.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        hmonthCore.soGet.queryOptions.sortKey = 'month';
        let hmonthCeProtocol;
        if (entity.hmonth) {
            hmonthCeProtocol = createCEProtocol(
                hmonthCore.ceProtocol,
                parentEntityViewSpec,
                hmonthCore,
                entity.hmonth,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hmonthCore
        )
        hmonthCore.title = heading ? heading : hmonthCore.title
        hmonthCore.listTitle = 'Hmonth'

        if (entity.hmonth) {
            displayStringFactory.reconfigure(
                entity.hmonth,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        hmonthCore.configureForDisplay(parentEntityViewSpec)

        this.hmonth = new DetailEntry(
          heading ? heading : hmonthCore.listTitle,
          hmonthCeProtocol ? hmonthCeProtocol.entityListView.fullHeading : null,
          'hmonth',
          BaseDetailType.pushedDetail,
          hmonthCore,
          EditableType.always,
          formFields.hmonth
        )
        if (entity.hmonth) {
          this.hmonth.ceProtocol = hmonthCeProtocol;
          this.hmonth.cachedListEntry = hmonthCore.makeListEntry(parentEntityViewSpec, entity.hmonth, 0)
          hmonthCore.selectedEntity = entity.hmonth;
        }
        this.hmonth.singleFieldSelect = true

        const agreementCore = app.makeCore( 'agreement');
        agreementCore.soGet = SOGetAgreement.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        let agreementCeProtocol;
        if (entity.agreement) {
            agreementCeProtocol = createCEProtocol(
                agreementCore.ceProtocol,
                parentEntityViewSpec,
                agreementCore,
                entity.agreement,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementCore
        )
        agreementCore.title = heading ? heading : agreementCore.title
        agreementCore.listTitle = 'Agreement'

        if (entity.agreement) {
            displayStringFactory.reconfigure(
                entity.agreement,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreementCore.configureForDisplay(parentEntityViewSpec)

        this.agreement = new DetailEntry(
          heading ? heading : agreementCore.listTitle,
          agreementCeProtocol ? agreementCeProtocol.entityListView.fullHeading : null,
          'agreement',
          BaseDetailType.pushedDetail,
          agreementCore,
          EditableType.always,
          formFields.agreement
        )
        if (entity.agreement) {
          this.agreement.ceProtocol = agreementCeProtocol;
          this.agreement.cachedListEntry = agreementCore.makeListEntry(parentEntityViewSpec, entity.agreement, 0)
          agreementCore.selectedEntity = entity.agreement;
        }
        this.agreement.singleFieldSelect = true
        this.agreement.listHeadingIndex = 0;

        const agreeyearCore = app.makeCore( 'agreeyear');
        agreeyearCore.soGet = SOGetAgreeyear.construct({
        });
        let agreeyearCeProtocol;
        if (entity.agreeyear) {
            agreeyearCeProtocol = createCEProtocol(
                agreeyearCore.ceProtocol,
                parentEntityViewSpec,
                agreeyearCore,
                entity.agreeyear,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreeyearCore
        )
        agreeyearCore.title = heading ? heading : agreeyearCore.title
        agreeyearCore.listTitle = 'Agreeyear'

        if (entity.agreeyear) {
            displayStringFactory.reconfigure(
                entity.agreeyear,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreeyearCore.configureForDisplay(parentEntityViewSpec)

        this.agreeyear = new DetailEntry(
          heading ? heading : agreeyearCore.listTitle,
          agreeyearCeProtocol ? agreeyearCeProtocol.entityListView.fullHeading : null,
          'agreeyear',
          BaseDetailType.pushedDetail,
          agreeyearCore,
          EditableType.always,
          formFields.agreeyear
        )
        if (entity.agreeyear) {
          this.agreeyear.ceProtocol = agreeyearCeProtocol;
          this.agreeyear.cachedListEntry = agreeyearCore.makeListEntry(parentEntityViewSpec, entity.agreeyear, 0)
          agreeyearCore.selectedEntity = entity.agreeyear;
        }
        this.agreeyear.singleFieldSelect = true

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true

        const adjustmentsCore = app.makeCore( 'adjustment');
        adjustmentsCore.soGet = SOGetAdjustment.construct({
          agreemonth: new SOGetAgreemonth(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            adjustmentsCore
        )
        adjustmentsCore.title = heading ? heading : adjustmentsCore.title
        adjustmentsCore.listTitle = 'Adjustments'

        adjustmentsCore.configureForDisplay(parentEntityViewSpec)

        this.adjustments = new DetailEntry(
          heading ? heading : adjustmentsCore.listTitle,
          null,
          'adjustments',
          BaseDetailType.embeddedList,
          adjustmentsCore,
          EditableType.never
        )

        const slotoptionsCore = app.makeCore( 'slotoption');
        slotoptionsCore.soGet = SOGetSlotoption.construct({
          agreemonth: new SOGetAgreemonth(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotoptionsCore
        )
        slotoptionsCore.title = heading ? heading : slotoptionsCore.title
        slotoptionsCore.listTitle = 'Slotoptions'

        slotoptionsCore.configureForDisplay(parentEntityViewSpec)

        this.slotoptions = new DetailEntry(
          heading ? heading : slotoptionsCore.listTitle,
          null,
          'slotoptions',
          BaseDetailType.embeddedList,
          slotoptionsCore,
          EditableType.never
        )
    }
}