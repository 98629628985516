import { Type } from 'class-transformer';
import { SORespCustomer } from '../customer/so-resp-customer';
import { SORespCarrier } from '../carrier/so-resp-carrier';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespOrganization implements ObjectiveProtocol {

  entityType: string = 'organization';
  uid: string;
  handle: string;
  ownerId: string = null;
  @Type(() => SORespCustomer)
  customer: SORespCustomer = null;
  @Type(() => SORespCarrier)
  carrier: SORespCarrier = null;
}