import { SOGetHemon } from '../hemon/so-get-hemon';
import { SOGetHestatus } from '../hestatus/so-get-hestatus';
import { SOGetContainersession } from '../containersession/so-get-containersession';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetHestatusitem implements QueryProtocol {

  constructor(
    public uid: string = null,
    public hemon: SOGetHemon = null,
    public hestatus: SOGetHestatus = null,
    public containersession: SOGetContainersession = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetHestatusitem(
      'uid' in params ? params.uid : null,
      'hemon' in params ? params.hemon : null,
      'hestatus' in params ? params.hestatus : null,
      'containersession' in params ? params.containersession : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
