import { Type } from 'class-transformer';
import { SORespContainersession } from '../containersession/so-resp-containersession';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespBol implements ObjectiveProtocol {

  entityType: string = 'bol';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateSigned: Date = null;
  body: string;
  carrierSignature: string = null;
  providerSignature: string = null;
  driverName: string = null;
  destination: string = null;
  @Type(() => SORespContainersession)
  containersession: SORespContainersession;
  @Type(() => SORespOrganization)
  carrier: SORespOrganization = null;
  rearAxelWeight: number = null;
}