import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetAccount } from '../account/so-get-account';
import { SOGetCarrier } from '../carrier/so-get-carrier';
import {ENPublicationOption} from '../../enums';


export class SOPostPublication {

  constructor(
    public identifier: ENPublicationOption,
    public displayName: string,
    public distribution: SOGetDistribution = null,
    public account: SOGetAccount = null,
    public carrier: SOGetCarrier = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostPublication(
      'identifier' in params ? params.identifier : null,
      'displayName' in params ? params.displayName : null,
      'distribution' in params ? params.distribution : null,
      'account' in params ? params.account : null,
      'carrier' in params ? params.carrier : null,
    );
  }
}
