import {SOPutHestatusitem} from '../../core/hestatusitem/so-put-hestatusitem';
import {HestatusitemCoreBase} from './hestatusitem.core.base';
import {SOGetHestatusitem} from '../../core/hestatusitem/so-get-hestatusitem';
import {FormField, SelectionOption} from 'lionheartland';
import {putHestatusitemFormFieldOptions} from './put-hestatusitem-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutHestatusitem extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putHestatusitemFormFieldOptions);

  RequestClass = SOPutHestatusitem

  constructor(
    public entityCore: HestatusitemCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('location'),
      this.getFormValueFromIdentifier('loadStatus'),
      this.getFormValueFromIdentifier('gone'),
      this.getFormValueFromIdentifier('n2Level'),
      this.getFormValueFromIdentifier('n2Pressure'),
      this.getFormValueFromIdentifier('heTemp'),
      this.getFormValueFromIdentifier('heLevel'),
      this.getFormValueFromIdentifier('hePressure'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}