

export class SOPutPlant {

  constructor(
    public uid: string,
    public name: string = null,
    public closed: boolean = null,
    public message: string = null,
    public timeZone: string = null,
    public timeZoneOffset: number = null,
    public daylightSavingsObserved: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutPlant(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'closed' in params ? params.closed : null,
      'message' in params ? params.message : null,
      'timeZone' in params ? params.timeZone : null,
      'timeZoneOffset' in params ? params.timeZoneOffset : null,
      'daylightSavingsObserved' in params ? params.daylightSavingsObserved : null,
    );
  }
}
