import { Type } from 'class-transformer';
import { SORespHemon } from '../hemon/so-resp-hemon';
import { SORespHestatus } from '../hestatus/so-resp-hestatus';
import { SORespContainersession } from '../containersession/so-resp-containersession';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespHestatusitem implements ObjectiveProtocol {

  entityType: string = 'hestatusitem';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  state: string;
  location: string;
  loadStatus: string;
  n2Level: number = null;
  n2Pressure: number = null;
  heTemp: number = null;
  heLevel: number = null;
  hePressure: number = null;
  n2LevelDcs: number = null;
  n2PressureDcs: number = null;
  heTempDcs: number = null;
  heLevelDcs: number = null;
  hePressureDcs: number = null;
  @Type(() => SORespHemon)
  hemon: SORespHemon = null;
  @Type(() => SORespHestatus)
  hestatus: SORespHestatus = null;
  @Type(() => SORespContainersession)
  containersession: SORespContainersession = null;
}