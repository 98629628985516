import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutBulk } from './so-put-bulk';
import { SOGetBulk } from './so-get-bulk';
import { SOPostBulk } from './so-post-bulk';
import { SODeleteBulk } from './so-delete-bulk';
import {SORespBulk} from './so-resp-bulk';
import {SOPluralBulk} from './so-plural-bulk';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOBulkService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'bulk',
      entityCore
    );
  }

  /** GET Bulk by id. Will 404 if id not found */
  get(request: SOGetBulk): Observable<SOPluralBulk> {
    return this.httpClient.get<SOPluralBulk>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralBulk, resp) }),
      tap(_ => this.log(`fetched Bulk`)),
      catchError(this.handleError<SOPluralBulk>(`get Bulk`))
    );
  }

  /** POST: add a new Bulk to the server */
  post(request: SOPostBulk): Observable<SORespBulk> {
    return this.httpClient.post<SORespBulk>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBulk, resp) }),
      tap((response: SORespBulk) => this.log(`added Bulk w/ id=${response.uid}`)),
      catchError(this.handleError<SORespBulk>('Bulk post'))
    );
  }

  /** PUT: update Bulk  */
  put(request: SOPutBulk): Observable<SORespBulk> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespBulk>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBulk, resp) }),
      tap((response: SORespBulk) => this.log(`edited Bulk w/ uid=${uid}`)),
      catchError(this.handleError<SORespBulk>('Bulk put'))
    );
  }

  /** DELETE: delete the Bulk from the server */
  delete(request: SODeleteBulk): Observable<SORespBulk> {
    return this.httpClient.delete<SORespBulk>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Bulk uid=${request.uid}`)),
      catchError(this.handleError<SORespBulk>('delete Bulk'))
    );
  }
}
