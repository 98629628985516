import {SOPutContainersession} from '../../core/containersession/so-put-containersession';
import {ContainersessionCoreBase} from './containersession.core.base';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {FormField, SelectionOption} from 'lionheartland';
import {putContainersessionFormFieldOptions} from './put-containersession-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {TrailerCore} from '../trailer/trailer.core';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';

export class RAPutContainersession extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putContainersessionFormFieldOptions);

  RequestClass = SOPutContainersession

  constructor(
    public entityCore: ContainersessionCoreBase
  ) {
    super();
    if (putContainersessionFormFieldOptions.trailer) {
      putContainersessionFormFieldOptions.trailer.makeCore = true;
    }
    if (putContainersessionFormFieldOptions.load) {
      putContainersessionFormFieldOptions.load.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('arrived'),
      this.getFormValueFromIdentifier('soakStart'),
      this.getFormValueFromIdentifier('shipEmpty'),
      this.getFormValueFromIdentifier('carrierSignature'),
      this.getFormValueFromIdentifier('providerSignature'),
      this.getFormValueFromIdentifier('notes'),
      this.getFormValueFromIdentifier('departed'),
      this.getFormValueFromIdentifier(
        'trailer',
        SOGetTrailer,
      ),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
      this.getFormValueFromIdentifier('loadSheetId'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}