import { SOGetRole } from '../role/so-get-role';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostOrgrola {

  constructor(
    public role: SOGetRole,
    public organization: SOGetOrganization,
    public isOwner: boolean = false,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostOrgrola(
      'role' in params ? params.role : null,
      'isOwner' in params ? params.isOwner : null,
      'organization' in params ? params.organization : null,
    );
  }
}
