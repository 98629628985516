import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespCalendar implements ObjectiveProtocol {

  entityType: string = 'calendar';
  uid: string;
  name: string;
  label: string;
}