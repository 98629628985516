import {SOPostClinyearlng} from '../../core/clinyearlng/so-post-clinyearlng';
import {ClinyearlngCoreBase} from './clinyearlng.core.base';
import {SOGetClinyearlng} from '../../core/clinyearlng/so-get-clinyearlng';
import {FormField, SelectionOption} from 'lionheartland';
import {postClinyearlngFormFieldOptions} from './post-clinyearlng-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {ContractCore} from '../contract/contract.core';
import {SOGetContract} from '../../core/contract/so-get-contract';

export class RAPostClinyearlng extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postClinyearlngFormFieldOptions);

  RequestClass = SOPostClinyearlng

  constructor(
    public entityCore: ClinyearlngCoreBase
  ) {
    super();
    if (postClinyearlngFormFieldOptions.contract) {
      postClinyearlngFormFieldOptions.contract.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('period'),
      this.getFormValueFromIdentifier('periodNumber'),
      this.getFormValueFromIdentifier('volumeTier1'),
      this.getFormValueFromIdentifier('priceTier1'),
      this.getFormValueFromIdentifier(
        'contract',
        SOGetContract,
      ),
      this.getFormValueFromIdentifier('volumeTier2'),
      this.getFormValueFromIdentifier('priceTier2'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}