import {SORespInquiry} from '../../core/inquiry/so-resp-inquiry';
import {InquiryCore} from './inquiry.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutInquiryFormFields} from './put-inquiry-form-fields';
import { CEInquiryAttributes } from './ce-inquiry-attributes'


export class CEInquiryBase extends CEBase {

    public details: CEInquiryAttributes;
    public fromFields: PutInquiryFormFields = new PutInquiryFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: InquiryCore,
        public entity: SORespInquiry,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEInquiryAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.companyName)
        this.attributes.push(this.details.givenName)
        this.attributes.push(this.details.surname)
        this.attributes.push(this.details.productInquiryStr)
        this.attributes.push(this.details.estimatedPurchaseQuantity)
        this.attributes.push(this.details.preferredEmailAddress)
        this.attributes.push(this.details.phoneNumber)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.customers)

    }
}