import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTaskService} from '../../core/task/eo-task';
import {SOGetTask} from '../../core/task/so-get-task';
import {RAPutTask} from './ra-put-task';
import {RAPostTask} from './ra-post-task';
import {RADeleteTask} from './ra-delete-task';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETask } from "./ce-task"


export class TaskCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'task';
  title = 'Task';
  ceProtocol: CEConstructor = CETask

  entityService: EOTaskService;
  soGetClass = SOGetTask;
  soGet: SOGetTask;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTaskService
    );
    this.adapters = [
      new RAPostTask(this),
      new RAPutTask(this),
      new RADeleteTask(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTask();
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}