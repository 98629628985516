import { SOGetProduction } from '../production/so-get-production';


export class SOPostTank {

  constructor(
    public name: string,
    public units: string,
    public production: SOGetProduction,
    public type: string = "tank",
    public capacity: number = 0,
    public average: number = 0,
    public value: number = 0,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTank(
      'name' in params ? params.name : null,
      'units' in params ? params.units : null,
      'type' in params ? params.type : null,
      'capacity' in params ? params.capacity : null,
      'average' in params ? params.average : null,
      'production' in params ? params.production : null,
      'value' in params ? params.value : null,
    );
  }
}
