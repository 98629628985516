import { Type } from 'class-transformer';
import { SORespAgreeyear } from '../agreeyear/so-resp-agreeyear';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespRateableagreeyearallocation implements ObjectiveProtocol {

  entityType: string = 'rateableagreeyearallocation';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
  @Type(() => SORespAgreeyear)
  agreeyear: SORespAgreeyear;
}