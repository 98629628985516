import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutContainersessionFormFields {

    public arrived: FormField
    public soakStart: FormField
    public shipEmpty: FormField
    public carrierSignature: FormField
    public providerSignature: FormField
    public notes: FormField
    public departed: FormField
    public trailer: FormField
    public account: FormField
    public load: FormField
    public loadSheetId: FormField

    constructor() {

        this.arrived = new FormField(
          'arrived',
          'Arrived',
          null,
          FormFieldType.datetime,
          false,
        )
        this.soakStart = new FormField(
          'soakStart',
          'Soak start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.shipEmpty = new FormField(
          'shipEmpty',
          'Ship empty',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.carrierSignature = new FormField(
          'carrierSignature',
          'Carrier signature',
          null,
          FormFieldType.text,
          false,
        )
        this.providerSignature = new FormField(
          'providerSignature',
          'Provider signature',
          null,
          FormFieldType.text,
          false,
        )
        this.notes = new FormField(
          'notes',
          'Notes',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.departed = new FormField(
          'departed',
          'Departed',
          null,
          FormFieldType.datetime,
          false,
        )
        this.trailer = new FormField(
          'trailer',
          'Trailer',
          'trailer',
          FormFieldType.autoCompleteList,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.loadSheetId = new FormField(
          'loadSheetId',
          'Load sheet id',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutContainersessionFormFields()

export const putContainersessionFormFieldOptions: {[key: string]: FormField} = {
  arrived: fields.arrived,
  soakStart: fields.soakStart,
  shipEmpty: fields.shipEmpty,
  carrierSignature: fields.carrierSignature,
  providerSignature: fields.providerSignature,
  notes: fields.notes,
  departed: fields.departed,
  trailer: fields.trailer,
  load: fields.load,
  loadSheetId: fields.loadSheetId,
};