import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EONavtemrolaService} from '../../core/navtemrola/eo-navtemrola';
import {SOGetNavtemrola} from '../../core/navtemrola/so-get-navtemrola';
import {RAPutNavtemrola} from './ra-put-navtemrola';
import {RAPostNavtemrola} from './ra-post-navtemrola';
import {RADeleteNavtemrola} from './ra-delete-navtemrola';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CENavtemrola } from "./ce-navtemrola"


export class NavtemrolaCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'navtemrola';
  title = 'Navtemrola';
  ceProtocol: CEConstructor = CENavtemrola

  entityService: EONavtemrolaService;
  soGetClass = SOGetNavtemrola;
  soGet: SOGetNavtemrola;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EONavtemrolaService
    );
    this.adapters = [
      new RAPostNavtemrola(this),
      new RAPutNavtemrola(this),
      new RADeleteNavtemrola(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetNavtemrola();
    this.soGet.queryOptions.limit = this.limit;
  }


}