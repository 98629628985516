import {SOPostFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-post-feescheduleagreementasc';
import {FeescheduleagreementascCoreBase} from './feescheduleagreementasc.core.base';
import {SOGetFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-get-feescheduleagreementasc';
import {FormField, SelectionOption} from 'lionheartland';
import {postFeescheduleagreementascFormFieldOptions} from './post-feescheduleagreementasc-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AgreementCore} from '../agreement/agreement.core';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {FeescheduleCore} from '../feeschedule/feeschedule.core';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';

export class RAPostFeescheduleagreementasc extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postFeescheduleagreementascFormFieldOptions);

  RequestClass = SOPostFeescheduleagreementasc

  constructor(
    public entityCore: FeescheduleagreementascCoreBase
  ) {
    super();
    if (postFeescheduleagreementascFormFieldOptions.agreement) {
      postFeescheduleagreementascFormFieldOptions.agreement.makeCore = true;
    }
    if (postFeescheduleagreementascFormFieldOptions.feeschedule) {
      postFeescheduleagreementascFormFieldOptions.feeschedule.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'agreement',
        SOGetAgreement,
      ),
      this.getFormValueFromIdentifier(
        'feeschedule',
        SOGetFeeschedule,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}