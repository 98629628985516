import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespAccountreport implements ObjectiveProtocol {

  entityType: string = 'accountreport';
  uid: string;
  ident: string;
  @Type(() => SORespAccount)
  account: SORespAccount;
  timeWindow: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  loadsProjected: number = 0;
  loadsScheduled: number = 0;
  loadsContracted: number = 0;
  loadsActual: number = 0;
  volumeActual: number = 0.0;
  volumeProjected: number = 0.0;
  volumeContracted: number = 0.0;
  volumeScheduled: number = 0;
  averageLoads: number = 0;
  averageVolume: number = 0;
  averageUnits: string = "";
  revenueProjected: number = 0;
  revenueActual: number = 0;
  revenueScheduled: number = 0;
  revenueContracted: number = 0;
  waspProjected: number = 0;
  waspActual: number = 0;
  waspScheduled: number = 0;
  waspContracted: number = 0;
  tankersActual: number = 0;
  tankersScheduled: number = 0;
  supertankersActual: number = 0;
  supertankersScheduled: number = 0;
  volumeDelta: number = 0;
  loadsDelta: number = 0;
  waspDelta: number = 0;
  revenueDelta: number = 0;
}