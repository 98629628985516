import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutFeeschedule } from './so-put-feeschedule';
import { SOGetFeeschedule } from './so-get-feeschedule';
import { SOPostFeeschedule } from './so-post-feeschedule';
import { SODeleteFeeschedule } from './so-delete-feeschedule';
import {SORespFeeschedule} from './so-resp-feeschedule';
import {SOPluralFeeschedule} from './so-plural-feeschedule';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOFeescheduleService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'feeschedule',
      entityCore
    );
  }

  /** GET Feeschedule by id. Will 404 if id not found */
  get(request: SOGetFeeschedule): Observable<SOPluralFeeschedule> {
    return this.httpClient.get<SOPluralFeeschedule>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralFeeschedule, resp) }),
      tap(_ => this.log(`fetched Feeschedule`)),
      catchError(this.handleError<SOPluralFeeschedule>(`get Feeschedule`))
    );
  }

  /** POST: add a new Feeschedule to the server */
  post(request: SOPostFeeschedule): Observable<SORespFeeschedule> {
    return this.httpClient.post<SORespFeeschedule>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFeeschedule, resp) }),
      tap((response: SORespFeeschedule) => this.log(`added Feeschedule w/ id=${response.uid}`)),
      catchError(this.handleError<SORespFeeschedule>('Feeschedule post'))
    );
  }

  /** PUT: update Feeschedule  */
  put(request: SOPutFeeschedule): Observable<SORespFeeschedule> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespFeeschedule>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFeeschedule, resp) }),
      tap((response: SORespFeeschedule) => this.log(`edited Feeschedule w/ uid=${uid}`)),
      catchError(this.handleError<SORespFeeschedule>('Feeschedule put'))
    );
  }

  /** DELETE: delete the Feeschedule from the server */
  delete(request: SODeleteFeeschedule): Observable<SORespFeeschedule> {
    return this.httpClient.delete<SORespFeeschedule>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Feeschedule uid=${request.uid}`)),
      catchError(this.handleError<SORespFeeschedule>('delete Feeschedule'))
    );
  }
}
