import {SORespFee} from '../../core/fee/so-resp-fee';
import {FeeCore} from './fee.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeeFormFields} from './put-fee-form-fields';
import {SOGetFee} from '../../core/fee/so-get-fee';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEFeeAttributesBase {

    displayName: DetailEntry
    identifier: DetailEntry
    amount: DetailEntry
    feeschedule: DetailEntry
    loadfees: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespFee,
        app: GasDirectApp,
        formFields: PutFeeFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.displayName = new DetailEntry(
            'Display name',
            entity.displayName,
            'displayName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.displayName
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )

        this.amount = new DetailEntry(
            'Amount',
            entity.amount,
            'amount',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.amount
        )


        const feescheduleCore = app.makeCore( 'feeschedule');
        feescheduleCore.soGet = SOGetFeeschedule.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feescheduleCore
        )
        feescheduleCore.title = heading ? heading : feescheduleCore.title
        feescheduleCore.listTitle = 'Feeschedule'

        feescheduleCore.configureForDisplay(parentEntityViewSpec)

        this.feeschedule = new DetailEntry(
          heading ? heading : feescheduleCore.listTitle,
          null,
          'feeschedule',
          BaseDetailType.pushedDetail,
          feescheduleCore,
          EditableType.always,
          formFields.feeschedule
        )
        this.feeschedule.singleFieldSelect = true

        const loadfeesCore = app.makeCore( 'loadfee');
        loadfeesCore.soGet = SOGetLoadfee.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadfeesCore
        )
        loadfeesCore.title = heading ? heading : loadfeesCore.title
        loadfeesCore.listTitle = 'Loadfees'

        loadfeesCore.configureForDisplay(parentEntityViewSpec)

        this.loadfees = new DetailEntry(
          heading ? heading : loadfeesCore.listTitle,
          null,
          'loadfees',
          BaseDetailType.embeddedList,
          loadfeesCore,
          EditableType.never
        )
    }
}