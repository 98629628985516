import {SORespDcs} from '../../core/dcs/so-resp-dcs';
import {DcsCore} from './dcs.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDcsFormFields} from './put-dcs-form-fields';
import { CEDcsAttributes } from './ce-dcs-attributes'


export class CEDcsBase extends CEBase {

    public details: CEDcsAttributes;
    public fromFields: PutDcsFormFields = new PutDcsFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DcsCore,
        public entity: SORespDcs,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDcsAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.endpoint)
        this.attributes.push(this.details.line)
        this.attributes.push(this.details.tags)

    }
}