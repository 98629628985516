import { SOGetOrder } from '../order/so-get-order';


export class SOPostLoad {

  constructor(
    public order: SOGetOrder,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostLoad(
      'order' in params ? params.order : null,
    );
  }
}
