import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutHyear } from './so-put-hyear';
import { SOGetHyear } from './so-get-hyear';
import { SOPostHyear } from './so-post-hyear';
import { SODeleteHyear } from './so-delete-hyear';
import {SORespHyear} from './so-resp-hyear';
import {SOPluralHyear} from './so-plural-hyear';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOHyearService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'hyear',
      entityCore
    );
  }

  /** GET Hyear by id. Will 404 if id not found */
  get(request: SOGetHyear): Observable<SOPluralHyear> {
    return this.httpClient.get<SOPluralHyear>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralHyear, resp) }),
      tap(_ => this.log(`fetched Hyear`)),
      catchError(this.handleError<SOPluralHyear>(`get Hyear`))
    );
  }

  /** POST: add a new Hyear to the server */
  post(request: SOPostHyear): Observable<SORespHyear> {
    return this.httpClient.post<SORespHyear>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHyear, resp) }),
      tap((response: SORespHyear) => this.log(`added Hyear w/ id=${response.uid}`)),
      catchError(this.handleError<SORespHyear>('Hyear post'))
    );
  }

  /** PUT: update Hyear  */
  put(request: SOPutHyear): Observable<SORespHyear> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespHyear>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHyear, resp) }),
      tap((response: SORespHyear) => this.log(`edited Hyear w/ uid=${uid}`)),
      catchError(this.handleError<SORespHyear>('Hyear put'))
    );
  }

  /** DELETE: delete the Hyear from the server */
  delete(request: SODeleteHyear): Observable<SORespHyear> {
    return this.httpClient.delete<SORespHyear>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Hyear uid=${request.uid}`)),
      catchError(this.handleError<SORespHyear>('delete Hyear'))
    );
  }
}
