import { SOGetTrailer } from '../trailer/so-get-trailer';
import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetLoad } from '../load/so-get-load';


export class SOPostContainersession {

  constructor(
    public trailer: SOGetTrailer,
    public distribution: SOGetDistribution,
    public notes: string = "",
    public load: SOGetLoad = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostContainersession(
      'notes' in params ? params.notes : null,
      'trailer' in params ? params.trailer : null,
      'distribution' in params ? params.distribution : null,
      'load' in params ? params.load : null,
    );
  }
}
