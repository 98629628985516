import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAwardService} from '../../core/award/eo-award';
import {SOGetAward} from '../../core/award/so-get-award';
import {RAPutAward} from './ra-put-award';
import {RAPostAward} from './ra-post-award';
import {RADeleteAward} from './ra-delete-award';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEAward } from "./ce-award"


export class AwardCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'award';
  title = 'Award';
  ceProtocol: CEConstructor = CEAward

  entityService: EOAwardService;
  soGetClass = SOGetAward;
  soGet: SOGetAward;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAwardService
    );
    this.adapters = [
      new RAPostAward(this),
      new RAPutAward(this),
      new RADeleteAward(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAward();
    this.soGet.queryOptions.limit = this.limit;
  }


}