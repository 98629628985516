import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAdjustmentService} from '../../core/adjustment/eo-adjustment';
import {SOGetAdjustment} from '../../core/adjustment/so-get-adjustment';
import {RAPutAdjustment} from './ra-put-adjustment';
import {RAPostAdjustment} from './ra-post-adjustment';
import {RADeleteAdjustment} from './ra-delete-adjustment';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEAdjustment } from "./ce-adjustment"


export class AdjustmentCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'adjustment';
  title = 'Adjustment';
  ceProtocol: CEConstructor = CEAdjustment

  entityService: EOAdjustmentService;
  soGetClass = SOGetAdjustment;
  soGet: SOGetAdjustment;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAdjustmentService
    );
    this.adapters = [
      new RAPostAdjustment(this),
      new RAPutAdjustment(this),
      new RADeleteAdjustment(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAdjustment();
    this.soGet.queryOptions.limit = this.limit;
  }


}