import {SORespDistributionyear} from '../../core/distributionyear/so-resp-distributionyear';
import {DistributionyearCore} from './distributionyear.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributionyearFormFields} from './put-distributionyear-form-fields';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CEDistributionyearAttributesBase {

    distributionmonths: DetailEntry
    state: DetailEntry
    year: DetailEntry
    volumeContracted: DetailEntry
    volumeSold: DetailEntry
    volumeSoldEstimated: DetailEntry
    productionVolume: DetailEntry
    productionVolumeEstimated: DetailEntry
    spotVolumeContracted: DetailEntry
    spotVolumeSold: DetailEntry
    distribution: DetailEntry
    hyears: DetailEntry
    agreeyears: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDistributionyear,
        app: GasDirectApp,
        formFields: PutDistributionyearFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.year = new DetailEntry(
            'Year',
            entity.year,
            'year',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.year.listHeadingIndex = 0;
        this.year.tableIndex = 0;

        this.volumeContracted = new DetailEntry(
            'Volume contracted',
            entity.volumeContracted,
            'volumeContracted',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.volumeSold = new DetailEntry(
            'Volume sold',
            entity.volumeSold,
            'volumeSold',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.volumeSoldEstimated = new DetailEntry(
            'Volume sold estimated',
            entity.volumeSoldEstimated,
            'volumeSoldEstimated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.productionVolume = new DetailEntry(
            'Production volume',
            entity.productionVolume,
            'productionVolume',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.productionVolumeEstimated = new DetailEntry(
            'Production volume estimated',
            entity.productionVolumeEstimated,
            'productionVolumeEstimated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.spotVolumeContracted = new DetailEntry(
            'Spot volume contracted',
            entity.spotVolumeContracted,
            'spotVolumeContracted',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.spotVolumeSold = new DetailEntry(
            'Spot volume sold',
            entity.spotVolumeSold,
            'spotVolumeSold',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const distributionmonthsCore = app.makeCore( 'distributionmonth');
        distributionmonthsCore.soGet = SOGetDistributionmonth.construct({
          distributionyear: new SOGetDistributionyear(entity.uid),
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthsCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthsCore
        )
        distributionmonthsCore.title = heading ? heading : distributionmonthsCore.title
        distributionmonthsCore.listTitle = 'Distributionmonths'

        distributionmonthsCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonths = new DetailEntry(
          heading ? heading : distributionmonthsCore.listTitle,
          null,
          'distributionmonths',
          BaseDetailType.embeddedList,
          distributionmonthsCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const hyearsCore = app.makeCore( 'hyear');
        hyearsCore.soGet = SOGetHyear.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distributionyear: new SOGetDistributionyear(entity.uid),
        });
        hyearsCore.soGet.queryOptions.sortKey = 'year';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hyearsCore
        )
        hyearsCore.title = heading ? heading : hyearsCore.title
        hyearsCore.listTitle = 'Hyears'

        hyearsCore.configureForDisplay(parentEntityViewSpec)

        this.hyears = new DetailEntry(
          heading ? heading : hyearsCore.listTitle,
          null,
          'hyears',
          BaseDetailType.pushedList,
          hyearsCore,
          EditableType.never
        )

        const agreeyearsCore = app.makeCore( 'agreeyear');
        agreeyearsCore.soGet = SOGetAgreeyear.construct({
          distributionyear: new SOGetDistributionyear(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreeyearsCore
        )
        agreeyearsCore.title = heading ? heading : agreeyearsCore.title
        agreeyearsCore.listTitle = 'Agreeyears'

        agreeyearsCore.configureForDisplay(parentEntityViewSpec)

        this.agreeyears = new DetailEntry(
          heading ? heading : agreeyearsCore.listTitle,
          null,
          'agreeyears',
          BaseDetailType.pushedList,
          agreeyearsCore,
          EditableType.never
        )
    }
}