import { SOGetOrder } from '../order/so-get-order';
import { SOGetTempclin } from '../tempclin/so-get-tempclin';
import { SOGetOffering } from '../offering/so-get-offering';


export class SOPostItem {

  constructor(
    public quantity: number,
    public order: SOGetOrder,
    public offering: SOGetOffering,
    public tempclin: SOGetTempclin = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostItem(
      'quantity' in params ? params.quantity : null,
      'order' in params ? params.order : null,
      'tempclin' in params ? params.tempclin : null,
      'offering' in params ? params.offering : null,
    );
  }
}
