import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDisttempagreement } from './so-put-disttempagreement';
import { SOGetDisttempagreement } from './so-get-disttempagreement';
import { SOPostDisttempagreement } from './so-post-disttempagreement';
import { SODeleteDisttempagreement } from './so-delete-disttempagreement';
import {SORespDisttempagreement} from './so-resp-disttempagreement';
import {SOPluralDisttempagreement} from './so-plural-disttempagreement';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODisttempagreementService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'disttempagreement',
      entityCore
    );
  }

  /** GET Disttempagreement by id. Will 404 if id not found */
  get(request: SOGetDisttempagreement): Observable<SOPluralDisttempagreement> {
    return this.httpClient.get<SOPluralDisttempagreement>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDisttempagreement, resp) }),
      tap(_ => this.log(`fetched Disttempagreement`)),
      catchError(this.handleError<SOPluralDisttempagreement>(`get Disttempagreement`))
    );
  }

  /** POST: add a new Disttempagreement to the server */
  post(request: SOPostDisttempagreement): Observable<SORespDisttempagreement> {
    return this.httpClient.post<SORespDisttempagreement>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDisttempagreement, resp) }),
      tap((response: SORespDisttempagreement) => this.log(`added Disttempagreement w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDisttempagreement>('Disttempagreement post'))
    );
  }

  /** PUT: update Disttempagreement  */
  put(request: SOPutDisttempagreement): Observable<SORespDisttempagreement> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDisttempagreement>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDisttempagreement, resp) }),
      tap((response: SORespDisttempagreement) => this.log(`edited Disttempagreement w/ uid=${uid}`)),
      catchError(this.handleError<SORespDisttempagreement>('Disttempagreement put'))
    );
  }

  /** DELETE: delete the Disttempagreement from the server */
  delete(request: SODeleteDisttempagreement): Observable<SORespDisttempagreement> {
    return this.httpClient.delete<SORespDisttempagreement>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Disttempagreement uid=${request.uid}`)),
      catchError(this.handleError<SORespDisttempagreement>('delete Disttempagreement'))
    );
  }
}
