import { Type } from 'class-transformer';
import { SORespTempclin } from '../tempclin/so-resp-tempclin';
import { SORespOffering } from '../offering/so-resp-offering';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespItem implements ObjectiveProtocol {

  entityType: string = 'item';
  uid: string;
  quantity: number;
  @Type(() => SORespTempclin)
  tempclin: SORespTempclin = null;
  @Type(() => SORespOffering)
  offering: SORespOffering;
}