import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutLngload } from './so-put-lngload';
import { SOGetLngload } from './so-get-lngload';
import { SOPostLngload } from './so-post-lngload';
import { SODeleteLngload } from './so-delete-lngload';
import {SORespLngload} from './so-resp-lngload';
import {SOPluralLngload} from './so-plural-lngload';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOLngloadService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'lngload',
      entityCore
    );
  }

  /** GET Lngload by id. Will 404 if id not found */
  get(request: SOGetLngload): Observable<SOPluralLngload> {
    return this.httpClient.get<SOPluralLngload>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralLngload, resp) }),
      tap(_ => this.log(`fetched Lngload`)),
      catchError(this.handleError<SOPluralLngload>(`get Lngload`))
    );
  }

  /** POST: add a new Lngload to the server */
  post(request: SOPostLngload): Observable<SORespLngload> {
    return this.httpClient.post<SORespLngload>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLngload, resp) }),
      tap((response: SORespLngload) => this.log(`added Lngload w/ id=${response.uid}`)),
      catchError(this.handleError<SORespLngload>('Lngload post'))
    );
  }

  /** PUT: update Lngload  */
  put(request: SOPutLngload): Observable<SORespLngload> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespLngload>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLngload, resp) }),
      tap((response: SORespLngload) => this.log(`edited Lngload w/ uid=${uid}`)),
      catchError(this.handleError<SORespLngload>('Lngload put'))
    );
  }

  /** DELETE: delete the Lngload from the server */
  delete(request: SODeleteLngload): Observable<SORespLngload> {
    return this.httpClient.delete<SORespLngload>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Lngload uid=${request.uid}`)),
      catchError(this.handleError<SORespLngload>('delete Lngload'))
    );
  }
}
