import {SOPutInquiry} from '../../core/inquiry/so-put-inquiry';
import {InquiryCoreBase} from './inquiry.core.base';
import {SOGetInquiry} from '../../core/inquiry/so-get-inquiry';
import {FormField, SelectionOption} from 'lionheartland';
import {putInquiryFormFieldOptions} from './put-inquiry-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutInquiry extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putInquiryFormFieldOptions);

  RequestClass = SOPutInquiry

  constructor(
    public entityCore: InquiryCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('companyName'),
      this.getFormValueFromIdentifier('givenName'),
      this.getFormValueFromIdentifier('surname'),
      this.getFormValueFromIdentifier('productInquiryStr'),
      this.getFormValueFromIdentifier('estimatedPurchaseQuantity'),
      this.getFormValueFromIdentifier('preferredEmailAddress'),
      this.getFormValueFromIdentifier('phoneNumber'),
      this.getFormValueFromIdentifier('dateCreated'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}