import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDistribution } from './so-put-distribution';
import { SOGetDistribution } from './so-get-distribution';
import { SOPostDistribution } from './so-post-distribution';
import { SODeleteDistribution } from './so-delete-distribution';
import {SORespDistribution} from './so-resp-distribution';
import {SOPluralDistribution} from './so-plural-distribution';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODistributionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'distribution',
      entityCore
    );
  }

  /** GET Distribution by id. Will 404 if id not found */
  get(request: SOGetDistribution): Observable<SOPluralDistribution> {
    return this.httpClient.get<SOPluralDistribution>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDistribution, resp) }),
      tap(_ => this.log(`fetched Distribution`)),
      catchError(this.handleError<SOPluralDistribution>(`get Distribution`))
    );
  }

  /** POST: add a new Distribution to the server */
  post(request: SOPostDistribution): Observable<SORespDistribution> {
    return this.httpClient.post<SORespDistribution>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistribution, resp) }),
      tap((response: SORespDistribution) => this.log(`added Distribution w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDistribution>('Distribution post'))
    );
  }

  /** PUT: update Distribution  */
  put(request: SOPutDistribution): Observable<SORespDistribution> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDistribution>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistribution, resp) }),
      tap((response: SORespDistribution) => this.log(`edited Distribution w/ uid=${uid}`)),
      catchError(this.handleError<SORespDistribution>('Distribution put'))
    );
  }

  /** DELETE: delete the Distribution from the server */
  delete(request: SODeleteDistribution): Observable<SORespDistribution> {
    return this.httpClient.delete<SORespDistribution>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Distribution uid=${request.uid}`)),
      catchError(this.handleError<SORespDistribution>('delete Distribution'))
    );
  }
}
