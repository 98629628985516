import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTempagreementFormFields {

    public name: FormField
    public description: FormField
    public autoRenew: FormField
    public docusignLink: FormField
    public units: FormField
    public durationDays: FormField
    public feeschedule: FormField
    public acq: FormField
    public top: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.autoRenew = new FormField(
          'autoRenew',
          'Auto renew',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.docusignLink = new FormField(
          'docusignLink',
          'Docusign link',
          null,
          FormFieldType.text,
          false,
        )
        this.units = new FormField(
          'units',
          'Units',
          null,
          FormFieldType.text,
          false,
        )
        this.durationDays = new FormField(
          'durationDays',
          'Duration days',
          null,
          FormFieldType.text,
          false,
          true,
          365
        )
        this.feeschedule = new FormField(
          'feeschedule',
          'Feeschedule',
          'feeschedule',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.acq = new FormField(
          'acq',
          'Acq',
          null,
          FormFieldType.text,
          false,
        )
        this.top = new FormField(
          'top',
          'Top',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutTempagreementFormFields()

export const putTempagreementFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  autoRenew: fields.autoRenew,
  docusignLink: fields.docusignLink,
  units: fields.units,
  durationDays: fields.durationDays,
  feeschedule: fields.feeschedule,
  acq: fields.acq,
  top: fields.top,
};