import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTempriceFormFields {

    public rate: FormField
    public quantity: FormField
    public loadQuantity: FormField
    public rank: FormField
    public tempclin: FormField

    constructor() {

        this.rate = new FormField(
          'rate',
          'Rate',
          null,
          FormFieldType.text,
          true,
        )
        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          true,
        )
        this.loadQuantity = new FormField(
          'loadQuantity',
          'Load quantity',
          null,
          FormFieldType.text,
          true,
        )
        this.rank = new FormField(
          'rank',
          'Rank',
          null,
          FormFieldType.text,
          true,
        )
        this.tempclin = new FormField(
          'tempclin',
          'Tempclin',
          'tempclin',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostTempriceFormFields()

export const postTempriceFormFieldOptions: {[key: string]: FormField} = {
  rate: fields.rate,
  quantity: fields.quantity,
  loadQuantity: fields.loadQuantity,
  rank: fields.rank,
  tempclin: fields.tempclin,
};