import {SORespLoad} from '../../core/load/so-resp-load';
import {LoadCore} from './load.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutLoadFormFields} from './put-load-form-fields';
import { CELoadAttributes } from './ce-load-attributes'


export class CELoadBase extends CEBase {

    public details: CELoadAttributes;
    public fromFields: PutLoadFormFields = new PutLoadFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: LoadCore,
        public entity: SORespLoad,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CELoadAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.action)
        this.attributes.push(this.details.weightRequested)
        this.attributes.push(this.details.destination)
        this.attributes.push(this.details.carrierSignature)
        this.attributes.push(this.details.providerSignature)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.net)
        this.attributes.push(this.details.cost)
        this.attributes.push(this.details.arrived)
        this.attributes.push(this.details.soakStart)
        this.attributes.push(this.details.dueDate)
        this.attributes.push(this.details.departed)
        this.attributes.push(this.details.loadingBegin)
        this.attributes.push(this.details.loadingEnd)
        this.attributes.push(this.details.loadCode)
        this.attributes.push(this.details.location)
        this.attributes.push(this.details.ref)
        this.attributes.push(this.details.specialRequests)
        this.attributes.push(this.details.driverName)
        this.attributes.push(this.details.operatorName)
        this.attributes.push(this.details.eta)
        this.attributes.push(this.details.requestedFillDate)
        this.attributes.push(this.details.coolDownDays)
        this.attributes.push(this.details.rearAxelWeight)
        this.attributes.push(this.details.droppedLin)
        this.attributes.push(this.details.finalAnalysis)
        this.attributes.push(this.details.waterAnalysis)
        this.attributes.push(this.details.prepVol)
        this.attributes.push(this.details.prepTrailer)
        this.attributes.push(this.details.nitrogenDetected)
        this.attributes.push(this.details.weight0)
        this.attributes.push(this.details.weight0Manual)
        this.attributes.push(this.details.weight1)
        this.attributes.push(this.details.weight1Manual)
        this.attributes.push(this.details.weight2)
        this.attributes.push(this.details.weight2Manual)
        this.attributes.push(this.details.pressure0)
        this.attributes.push(this.details.pressure1)
        this.attributes.push(this.details.process)
        this.attributes.push(this.details.chassisId)
        this.attributes.push(this.details.dotInspectionDate)
        this.attributes.push(this.details.dotSp)
        this.attributes.push(this.details.dotE)
        this.attributes.push(this.details.attachment)
        this.attributes.push(this.details.bol)
        this.attributes.push(this.details.quantityProjected)
        this.attributes.push(this.details.costProjected)
        this.attributes.push(this.details.shield)
        this.attributes.push(this.details.loadSheetId)
        this.attributes.push(this.details.holdRelease)
        this.attributes.push(this.details.netRework)
        this.attributes.push(this.details.netNormal)
        this.attributes.push(this.details.quantityRework)
        this.attributes.push(this.details.quantityNormal)
        this.attributes.push(this.details.inreports)
        this.attributes.push(this.details.transactions)
        this.attributes.push(this.details.reservation)
        this.attributes.push(this.details.account)
        this.attributes.push(this.details.order)
        this.attributes.push(this.details.trailer)
        this.attributes.push(this.details.carrier)
        this.attributes.push(this.details.price)
        this.attributes.push(this.details.offering)
        this.attributes.push(this.details.agreemonth)
        this.attributes.push(this.details.agreement)
        this.attributes.push(this.details.hmonth)
        this.attributes.push(this.details.distributionday)
        this.attributes.push(this.details.activitys)
        this.attributes.push(this.details.weights)
        this.attributes.push(this.details.hemons)
        this.attributes.push(this.details.impuritys)
        this.attributes.push(this.details.checklists)
        this.attributes.push(this.details.tasks)
        this.attributes.push(this.details.bols)
        this.attributes.push(this.details.loadfees)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.sitesessions)
        this.attributes.push(this.details.baysessions)
        this.attributes.push(this.details.deltas)
        this.attributes.push(this.details.distribution)

    }
}