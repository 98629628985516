import {SOPostLinag} from '../../core/linag/so-post-linag';
import {LinagCoreBase} from './linag.core.base';
import {SOGetLinag} from '../../core/linag/so-get-linag';
import {FormField, SelectionOption} from 'lionheartland';
import {postLinagFormFieldOptions} from './post-linag-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TagCore} from '../tag/tag.core';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {LineCore} from '../line/line.core';
import {SOGetLine} from '../../core/line/so-get-line';
import {TankCore} from '../tank/tank.core';
import {SOGetTank} from '../../core/tank/so-get-tank';

export class RAPostLinag extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postLinagFormFieldOptions);

  RequestClass = SOPostLinag

  constructor(
    public entityCore: LinagCoreBase
  ) {
    super();
    if (postLinagFormFieldOptions.tag) {
      postLinagFormFieldOptions.tag.makeCore = true;
    }
    if (postLinagFormFieldOptions.line) {
      postLinagFormFieldOptions.line.makeCore = true;
    }
    if (postLinagFormFieldOptions.tank) {
      postLinagFormFieldOptions.tank.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier(
        'tag',
        SOGetTag,
      ),
      this.getFormValueFromIdentifier(
        'line',
        SOGetLine,
      ),
      this.getFormValueFromIdentifier(
        'tank',
        SOGetTank,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}