import { SOGetAgreement } from '../agreement/so-get-agreement';
import { SOGetFeeschedule } from '../feeschedule/so-get-feeschedule';


export class SOPostFeescheduleagreementasc {

  constructor(
    public agreement: SOGetAgreement,
    public feeschedule: SOGetFeeschedule,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostFeescheduleagreementasc(
      'agreement' in params ? params.agreement : null,
      'feeschedule' in params ? params.feeschedule : null,
    );
  }
}
