import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutClinyearlng } from './so-put-clinyearlng';
import { SOGetClinyearlng } from './so-get-clinyearlng';
import { SOPostClinyearlng } from './so-post-clinyearlng';
import { SODeleteClinyearlng } from './so-delete-clinyearlng';
import {SORespClinyearlng} from './so-resp-clinyearlng';
import {SOPluralClinyearlng} from './so-plural-clinyearlng';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOClinyearlngService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'clinyearlng',
      entityCore
    );
  }

  /** GET Clinyearlng by id. Will 404 if id not found */
  get(request: SOGetClinyearlng): Observable<SOPluralClinyearlng> {
    return this.httpClient.get<SOPluralClinyearlng>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralClinyearlng, resp) }),
      tap(_ => this.log(`fetched Clinyearlng`)),
      catchError(this.handleError<SOPluralClinyearlng>(`get Clinyearlng`))
    );
  }

  /** POST: add a new Clinyearlng to the server */
  post(request: SOPostClinyearlng): Observable<SORespClinyearlng> {
    return this.httpClient.post<SORespClinyearlng>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespClinyearlng, resp) }),
      tap((response: SORespClinyearlng) => this.log(`added Clinyearlng w/ id=${response.uid}`)),
      catchError(this.handleError<SORespClinyearlng>('Clinyearlng post'))
    );
  }

  /** PUT: update Clinyearlng  */
  put(request: SOPutClinyearlng): Observable<SORespClinyearlng> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespClinyearlng>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespClinyearlng, resp) }),
      tap((response: SORespClinyearlng) => this.log(`edited Clinyearlng w/ uid=${uid}`)),
      catchError(this.handleError<SORespClinyearlng>('Clinyearlng put'))
    );
  }

  /** DELETE: delete the Clinyearlng from the server */
  delete(request: SODeleteClinyearlng): Observable<SORespClinyearlng> {
    return this.httpClient.delete<SORespClinyearlng>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Clinyearlng uid=${request.uid}`)),
      catchError(this.handleError<SORespClinyearlng>('delete Clinyearlng'))
    );
  }
}
