import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutDeltaFormFields {


    constructor() {


    }
}

const fields = new PutDeltaFormFields()

export const putDeltaFormFieldOptions: {[key: string]: FormField} = {
};