import {SORespNavi} from '../../core/navi/so-resp-navi';
import {NaviCore} from './navi.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutNaviFormFields} from './put-navi-form-fields';
import { CENaviAttributes } from './ce-navi-attributes'


export class CENaviBase extends CEBase {

    public details: CENaviAttributes;
    public fromFields: PutNaviFormFields = new PutNaviFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: NaviCore,
        public entity: SORespNavi,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CENaviAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.navroas)
        this.attributes.push(this.details.navtemrolas)

    }
}