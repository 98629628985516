import {SOPostBol} from '../../core/bol/so-post-bol';
import {BolCoreBase} from './bol.core.base';
import {SOGetBol} from '../../core/bol/so-get-bol';
import {FormField, SelectionOption} from 'lionheartland';
import {postBolFormFieldOptions} from './post-bol-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';
import {ContainersessionCore} from '../containersession/containersession.core';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';

export class RAPostBol extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postBolFormFieldOptions);

  RequestClass = SOPostBol

  constructor(
    public entityCore: BolCoreBase
  ) {
    super();
    if (postBolFormFieldOptions.load) {
      postBolFormFieldOptions.load.makeCore = true;
    }
    if (postBolFormFieldOptions.containersession) {
      postBolFormFieldOptions.containersession.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
      this.getFormValueFromIdentifier(
        'containersession',
        SOGetContainersession,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}