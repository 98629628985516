import {InreportCoreBase} from "./inreport.core.base"
import {ActiveUserService, ButtonPressProtocol, EntityViewSpecProtocol, MenuItem, MenuItemType} from "lionheartland";
import {SORespLoad} from "../../core/load/so-resp-load";
import {SORespOrganization} from "../../core/organization/so-resp-organization";
import {SORespActivity} from "../../core/activity/so-resp-activity";
import {SORespImpurity} from "../../core/impurity/so-resp-impurity";
import {SORespTrailer} from "../../core/trailer/so-resp-trailer";
import {SORespOffering} from "../../core/offering/so-resp-offering";
import {SORespAccount} from "../../core/account/so-resp-account";
import {SORespHemon} from "../../core/hemon/so-resp-hemon";
import {GasDirectApp} from "../../land-app";


export function getFeeTotal(entity: SORespLoad) {
  let feeTotal = 0;
  // if (entity.loadfees) {
  //   for (const fee of entity.loadfees) {
  //     feeTotal = feeTotal + fee.amount;
  //   }
  // }
  return feeTotal;
}

export function getImpurityForDisplay(value: number) {
  return value || value === 0 ? value.toLocaleString(undefined, {maximumFractionDigits: 3, minimumFractionDigits: 3}) : '';
}

export function getSingleImpurityDisplayString(value: number) {
  if (value === 10001) {
    return 'NA';
  } else if (value === 10002) {
    return 'ND';
  } else if (value === 10003) {
    return 'NR';
  } else {
    return getImpurityForDisplay(value);
  }
}



export function getHeliumLoadForAccountreport(entity: SORespLoad, heload, activeUserService: ActiveUserService) {

  const app = activeUserService.app as GasDirectApp;

  const feeTotal = getFeeTotal(entity);

  let heRate;
  let heOriginal;
  let heRework;
  let heOriginalCost;
  let heReworkCost;

  if (entity.quantity) {
    heRate = (entity.cost - feeTotal) / entity.quantity;
    heOriginal = entity.quantity - (entity.prepVol != null && entity.prepVol !== 'false' && entity.prepVol !== '' ? +entity.prepVol : 0) * app.distribution.conversionRate;
    heRework = entity.quantity - heOriginal;
    heOriginalCost = heOriginal * heRate;
    heReworkCost = heRework * heRate;
  }

  const load = {
    Customer: heload.account.customer.companyName,
    'Requested fill date': activeUserService.time.niceDateYearMonthDayForFilename(heload.requestedFillDate),
    'Loading end (date)': entity.loadingEnd ? activeUserService.time.niceDateYearMonthDayForFilename(heload.loadingEnd) : null,
    'Loading end (time)': entity.loadingEnd ? activeUserService.time.niceTime(heload.loadingEnd) : null,
    'Actual departure (date)': entity.departed ? activeUserService.time.niceDateYearMonthDayForFilename(heload.departed) : null,
    'Actual departure (time)': entity.departed ? activeUserService.time.niceTime(heload.departed) : null,
    'Container ID': heload.trailer ? heload.trailer.identifier : '',
    ArrCalcTotImp: heload.impuritiesInitialTotal,
    'Arv He P': heload.hemonPressureInitial.hePressure,
    'Arrival He Temp (K)': heload.hemonTempInitial.heTemp,
    'Departure Water Impurity': heload.impuritiesFinal && heload.impuritiesFinal.h20 ? getSingleImpurityDisplayString(
      heload.impuritiesFinal.h20
    ) : 'NA',
    'Kscf Loaded': heload.kscf ? heload.kscf : '',
    Rework: heload.prepTrailer,
    'Rework Vol (lbs)': heload.prepVol ? heload.prepVol : '',
    'Rework Vol (kscf)': heRework ? heRework : '',
    COA: heload.finalAnalysis,
    'Load #': heload.ref,
    'LIN first': ['linFirst', 'hotLinFirst'].includes(entity.process) ? 'yes' : 'no',
    Scale: null,
    Destination: heload.destination,
    'Dropped LIN': heload.droppedLin,
    'Soak days': heload.coolDownDays,
    'Tare 1': heload.weight1 ? heload.weight1.amount : '',
    'Tare 2': heload.weight2 ? heload.weight2.amount : '',
    'Tare 3': heload.weight3 ? heload.weight3.amount : '',
    Gross: heload.weight4 ? heload.weight4.amount : '',
    'Calculated net': heload.net ? heload.net : '',
    'Total cost': entity.cost ? entity.cost : '',
    'He cost': 0,
    'Rework he cost': 0,
    'Fee cost': 0
  };

  // if (entity.loadfees) {
  //   for (const loadFee of entity.loadfees) {
  //     const feeName = loadFee.fee && loadFee.fee.displayName ? loadFee.fee.displayName : 'FeeMissing';
  //     load[feeName + ' Quantity'] = loadFee.quantity;
  //     load[feeName + ' Unit price'] = loadFee.unitCost;
  //     load[feeName + ' Cost'] = loadFee.amount;
  //     if (loadFee.fee.identifier === 'initialFill') {
  //       load['He cost'] = load['He cost'] +  loadFee.amount;
  //     } else {
  //       load['Fee cost'] = load['Fee cost'] +  loadFee.amount;
  //     }
  //   }
  // }

  return load;
}


export function impurityValueForTotal(value: number) {
  if (value === 10001) {
    return 0;
  } else if (value === 10002) {
    return 0;
  } else if (value === 10003) {
    return 0;
  } else {
    return value;
  }
}

export function getImpurityTotal(entity: SORespImpurity) {
  return impurityValueForTotal(entity.h20) +
    impurityValueForTotal(entity.neon) +
    impurityValueForTotal(entity.h2) +
    impurityValueForTotal(entity.o2) +
    impurityValueForTotal(entity.n2) +
    impurityValueForTotal(entity.carbonMonoxide) +
    impurityValueForTotal(entity.hydrocarbons) +
    impurityValueForTotal(entity.carbonDioxide);
}


export class HeLoadProcessed {

  comments: SORespActivity[];

  get un1963() {
    return this.trailer && this.trailer.type === 'UN1963';
  }

  get un1046() {
    return this.trailer && this.trailer.type === 'UN1046';
  }

  get finalAnalysis(): any {
    return this.load.finalAnalysis === 'yes';
  }

  get prepTrailer(): any {
    return this.load.prepVol !== null && this.load.prepVol !== '';
  }

  get coolDownDays(): any {
    return this.load.coolDownDays;
  }

  get weight0(): any {
    const weight = this.load.weights.find(obj => obj.tare === 'weight0');
    return weight ? weight : 0;
  }

  get weight1(): any {
    const weight = this.load.weights.find(obj => obj.tare === 'weight1');
    return weight ? weight : 0;
  }

  get weight2(): any {
    const weight = this.load.weights.find(obj => obj.tare === 'weight2');
    return weight ? weight : 0;
  }

  get weight3(): any {
    const weight = this.load.weights.find(obj => obj.tare === 'weight3');
    return weight ? weight : 0;
  }

  get weight4(): any {
    const weight = this.load.weights.find(obj => obj.tare === 'weight4');
    return weight ? weight : 0;
  }

  get net(): any {
    return this.load.net ? this.load.net : 0;
  }

  get impuritiesInitial(): any {
    const imp = this.load.impuritys.find(obj => obj.state === 'initial');
    return imp ? imp : new SORespImpurity();
  }

  get impuritiesInitialTotal(): any {
    return getImpurityTotal(this.impuritiesInitial);
  }

  get impuritiesInitialTotalForDisplay() {
    return getImpurityForDisplay(this.impuritiesInitialTotal);
  }

  get impuritiesFinal(): SORespImpurity {
    let imp = this.load.impuritys.find(obj => obj.state === 'final');

    if (!imp && this.load.finalAnalysis !== 'yes') {
      imp = new SORespImpurity();
      // imp = new SORespImpurity(null, null, null, 10003, 10003, 10003, 10003, 10003, 10003, 10003, 10003);
    } else if (!imp) {
      imp = new SORespImpurity();
    }
    return imp;
  }

  get impuritiesFinalTotal(): any {
    return getImpurityTotal(this.impuritiesFinal);
  }

  get impuritiesFinalTotalForDisplay() {
    return getImpurityForDisplay(this.impuritiesFinalTotal);
  }

  get hemonTempInitial(): any {
    const bayHemons = [];
    for (const hemonSample of this.load.hemons) {
      if (hemonSample.state === 'bay') {
        bayHemons.push(hemonSample);
      }
    }

    const hemon = bayHemons[0];
    return hemon ? hemon : new SORespHemon();
  }

  get hemonPressureInitial(): any {
    if (this.hemonTempInitial && this.hemonTempInitial.uid) {
      return this.hemonTempInitial;
    } else {
      const hemon = this.load.hemons[0];
      return hemon ? hemon : new SORespHemon();
    }
  }

  get hemonTempFinal(): any {
    const bayHemons = [];
    for (const hemonSample of this.load.hemons) {
      if (hemonSample.state === 'bay') {
        bayHemons.push(hemonSample);
      }
    }
    let hemon;
    if (bayHemons.length > 1) {
      hemon = bayHemons[bayHemons.length - 1];
    }
    return hemon ? hemon : new SORespHemon();
  }

  get hemonPressureFinal(): any {
    const hemon = this.load.hemons[this.load.hemons.length - 1];
    return hemon && this.hemonTempFinal.uid ? hemon : new SORespHemon();
  }

  get kscf(): any {
    return this.quantity ? this.quantity : '';
  }

  get droppedLin(): any {
    return this.load.droppedLin;
  }

  get prepVol(): any {
    return this.load.prepVol;
  }

  get rearAxelWeight(): any {
    return this.load.rearAxelWeight;
  }

  get uid(): string {
    return this.load.uid;
  }

  get status(): string {
    return this.load.status;
  }

  get type(): string {
    return this.load.type;
  }

  get ref(): string {
    return this.load.ref;
  }

  get account(): SORespAccount {
    return this.load.account;
  }

  get offering(): SORespOffering {
    return this.load.offering;
  }

  get weightRequested(): number {
    return this.load.weightRequested;
  }

  get destination(): string {
    return this.load.destination;
  }

  get carrierSignature(): string {
    return this.load.carrierSignature;
  }

  get providerSignature(): string {
    return this.load.providerSignature;
  }

  get quantity(): number {
    return this.load.quantity;
  }

  get cost(): number {
    return this.load.cost;
  }

  get arrived(): Date {
    return this.load.arrived;
  }

  get departed(): Date {
    return this.load.departed ? this.load.departed : new Date();
  }

  get loadingBegin(): Date {
    return this.load.loadingBegin;
  }

  get loadingEnd(): Date {
    return this.load.loadingEnd;
  }

  get loadCode(): string {
    return this.load.loadCode;
  }

  get specialRequests(): string {
    return this.load.specialRequests;
  }

  get requestedFillDate(): Date {
    return this.load.requestedFillDate;
  }

  get trailer(): SORespTrailer {
    return this.load.trailer;
  }

  get carrier(): SORespOrganization {
    return this.load.carrier;
  }

  get driverName(): string {
    return this.load.driverName;
  }

  get operatorName(): string {
    return this.load.operatorName;
  }

  constructor(
    public load: SORespLoad
  ) {
    load.hemons.sort((a, b) => a.dateCreated < b.dateCreated ? -1 : a.dateCreated > b.dateCreated ? 1 : 0);
  }

}


export class InreportCore extends InreportCoreBase implements ButtonPressProtocol {

  initListHeaderSpec() {
    super.initListHeaderSpec();
    this.listHeaderSpec.showMenu = true;
    this.listHeaderSpec.menuItems = [
      new MenuItem(
        'excel',
        'Excel export',
        this,
        MenuItemType.excelTemplate
      )
    ]
    // this.listHeaderSpec.buttons = [
    //   new LLButton(
    //     'export',
    //
    //   )
    // ]
  }

  menuClick(item: MenuItem, entityViewSpec: EntityViewSpecProtocol) {
    item.header.parent.exportToExcel();
  }

  entitiesForExcel() {
    const resp = [];
    const sheetsToEntities = {
      data: resp,
    };

    const prefilledLastMonth = [];
    sheetsToEntities['prefilledLastMonth'] = prefilledLastMonth;

    const prefilledThisMonth = [];
    sheetsToEntities['prefilledThisMonth'] = prefilledThisMonth;

    const leftoverFromLastMonth = [];
    sheetsToEntities['leftoverFromLastMonth'] = leftoverFromLastMonth;

    const leftoverFromThisMonth = [];
    sheetsToEntities['leftoverFromThisMonth'] = leftoverFromThisMonth;

    const spot = [];
    sheetsToEntities['spot'] = spot;

    for (const entity of this.entities) {

      const heload = new HeLoadProcessed(entity.load);
      const loadLine = getHeliumLoadForAccountreport(entity.load, heload, this.activeUserService);

      // note - +1 is added because january is 0
      this.addToHeliumReport(resp, loadLine, sheetsToEntities, heload.account.customer.companyName.slice(0, 30));

      if (entity.leftoverPrior) {
        leftoverFromLastMonth.push(loadLine);
      } else if (entity.prefillPrior) {
        prefilledLastMonth.push(loadLine);
      } else if (entity.prefillNext) {
        prefilledThisMonth.push(loadLine);
      } else if (entity.leftoverNext) {
        leftoverFromThisMonth.push(loadLine);
      }

      if (entity.load.agreemonth.agreeyear.agreement.interruptible) {
        spot.push(loadLine);
      }

    }
    return sheetsToEntities;


  }

  private addToHeliumReport(resp: any[], load, sheetsToEntities: { data: any[] }, customerName) {
    resp.push(load);

    const sheetName = customerName;
    if (!Object.keys(sheetsToEntities).includes(sheetName)) {
      sheetsToEntities[sheetName] = [];
    }
    sheetsToEntities[sheetName].push(load);
  }

}