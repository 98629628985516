import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutAdjustment } from './so-put-adjustment';
import { SOGetAdjustment } from './so-get-adjustment';
import { SOPostAdjustment } from './so-post-adjustment';
import { SODeleteAdjustment } from './so-delete-adjustment';
import {SORespAdjustment} from './so-resp-adjustment';
import {SOPluralAdjustment} from './so-plural-adjustment';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOAdjustmentService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'adjustment',
      entityCore
    );
  }

  /** GET Adjustment by id. Will 404 if id not found */
  get(request: SOGetAdjustment): Observable<SOPluralAdjustment> {
    return this.httpClient.get<SOPluralAdjustment>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAdjustment, resp) }),
      tap(_ => this.log(`fetched Adjustment`)),
      catchError(this.handleError<SOPluralAdjustment>(`get Adjustment`))
    );
  }

  /** POST: add a new Adjustment to the server */
  post(request: SOPostAdjustment): Observable<SORespAdjustment> {
    return this.httpClient.post<SORespAdjustment>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAdjustment, resp) }),
      tap((response: SORespAdjustment) => this.log(`added Adjustment w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAdjustment>('Adjustment post'))
    );
  }

  /** PUT: update Adjustment  */
  put(request: SOPutAdjustment): Observable<SORespAdjustment> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAdjustment>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAdjustment, resp) }),
      tap((response: SORespAdjustment) => this.log(`edited Adjustment w/ uid=${uid}`)),
      catchError(this.handleError<SORespAdjustment>('Adjustment put'))
    );
  }

  /** DELETE: delete the Adjustment from the server */
  delete(request: SODeleteAdjustment): Observable<SORespAdjustment> {
    return this.httpClient.delete<SORespAdjustment>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Adjustment uid=${request.uid}`)),
      catchError(this.handleError<SORespAdjustment>('delete Adjustment'))
    );
  }
}
