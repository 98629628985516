import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostPriceFormFields {

    public startDate: FormField
    public endDate: FormField
    public rate: FormField
    public quantity: FormField
    public loadQuantity: FormField
    public rank: FormField
    public clin: FormField
    public agreeyear: FormField

    constructor() {

        this.startDate = new FormField(
          'startDate',
          'Start date',
          null,
          FormFieldType.datetime,
          true,
        )
        this.endDate = new FormField(
          'endDate',
          'End date',
          null,
          FormFieldType.datetime,
          true,
        )
        this.rate = new FormField(
          'rate',
          'Rate',
          null,
          FormFieldType.text,
          true,
        )
        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.loadQuantity = new FormField(
          'loadQuantity',
          'Load quantity',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.rank = new FormField(
          'rank',
          'Rank',
          null,
          FormFieldType.text,
          true,
        )
        this.clin = new FormField(
          'clin',
          'Clin',
          'clin',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.agreeyear = new FormField(
          'agreeyear',
          'Agreeyear',
          'agreeyear',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostPriceFormFields()

export const postPriceFormFieldOptions: {[key: string]: FormField} = {
  startDate: fields.startDate,
  endDate: fields.endDate,
  rate: fields.rate,
  quantity: fields.quantity,
  loadQuantity: fields.loadQuantity,
  rank: fields.rank,
  clin: fields.clin,
  agreeyear: fields.agreeyear,
};