import { SOGetAccount } from '../account/so-get-account';
import { SOGetSale } from '../sale/so-get-sale';


export class SOPostSaleaccountasc {

  constructor(
    public account: SOGetAccount,
    public sale: SOGetSale,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostSaleaccountasc(
      'account' in params ? params.account : null,
      'sale' in params ? params.sale : null,
    );
  }
}
