import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostDcsFormFields {

    public name: FormField
    public endpoint: FormField
    public line: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.endpoint = new FormField(
          'endpoint',
          'Endpoint',
          null,
          FormFieldType.text,
          true,
        )
        this.line = new FormField(
          'line',
          'Line',
          'line',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostDcsFormFields()

export const postDcsFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  endpoint: fields.endpoint,
  line: fields.line,
};