import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostOrganizationFormFields {

    public handle: FormField

    constructor() {

        this.handle = new FormField(
          'handle',
          'Handle',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostOrganizationFormFields()

export const postOrganizationFormFieldOptions: {[key: string]: FormField} = {
  handle: fields.handle,
};