

export class SOPutNavtemrola {

  constructor(
    public uid: string,
    public index: number = null,
    public mainNav: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutNavtemrola(
      'uid' in params ? params.uid : null,
      'index' in params ? params.index : null,
      'mainNav' in params ? params.mainNav : null,
    );
  }
}
