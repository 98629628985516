import {SOPutSlot} from '../../core/slot/so-put-slot';
import {SlotCoreBase} from './slot.core.base';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {FormField, SelectionOption} from 'lionheartland';
import {putSlotFormFieldOptions} from './put-slot-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {CalendarCore} from '../calendar/calendar.core';
import {SOGetCalendar} from '../../core/calendar/so-get-calendar';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPutSlot extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putSlotFormFieldOptions);

  RequestClass = SOPutSlot

  constructor(
    public entityCore: SlotCoreBase
  ) {
    super();
    if (putSlotFormFieldOptions.calendar) {
      putSlotFormFieldOptions.calendar.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('slotNumber'),
      this.getFormValueFromIdentifier('slotDay'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier(
        'calendar',
        SOGetCalendar,
      ),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}