import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutChecklistitemFormFields {

    public body: FormField
    public index: FormField
    public required: FormField
    public checked: FormField
    public checklist: FormField

    constructor() {

        this.body = new FormField(
          'body',
          'Body',
          null,
          FormFieldType.text,
          false,
        )
        this.index = new FormField(
          'index',
          'Index',
          null,
          FormFieldType.text,
          false,
        )
        this.required = new FormField(
          'required',
          'Required',
          null,
          FormFieldType.boolean,
          false,
        )
        this.checked = new FormField(
          'checked',
          'Checked',
          null,
          FormFieldType.boolean,
          false,
        )
        this.checklist = new FormField(
          'checklist',
          'Checklist',
          'checklist',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutChecklistitemFormFields()

export const putChecklistitemFormFieldOptions: {[key: string]: FormField} = {
  body: fields.body,
  index: fields.index,
  required: fields.required,
  checked: fields.checked,
  checklist: fields.checklist,
};