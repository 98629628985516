import {FormField, FormFieldType, SelectionOption} from 'lionheartland';
import {ENServiceType} from '../../enums';

export class PutServiceRequestFormFields {

    public dateCreated: FormField
    public dateRequested: FormField
    public serviceType: FormField
    public distribution: FormField
    public distributionday: FormField
    public containersession: FormField

    constructor() {

        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          false,
        )
        this.dateRequested = new FormField(
          'dateRequested',
          'Date requested',
          null,
          FormFieldType.datetime,
          false,
        )
        this.serviceType = new FormField(
          'serviceType',
          'Service type',
          null,
          FormFieldType.enumeration,
          false,
        )
        this.serviceType.enumeration = ENServiceType
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.distributionday = new FormField(
          'distributionday',
          'Distributionday',
          'distributionday',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutServiceRequestFormFields()

export const putServiceRequestFormFieldOptions: {[key: string]: FormField} = {
  dateCreated: fields.dateCreated,
  dateRequested: fields.dateRequested,
  serviceType: fields.serviceType,
  distribution: fields.distribution,
  distributionday: fields.distributionday,
  containersession: fields.containersession,
};