import {SOPutTempagreement} from '../../core/tempagreement/so-put-tempagreement';
import {TempagreementCoreBase} from './tempagreement.core.base';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';
import {FormField, SelectionOption} from 'lionheartland';
import {putTempagreementFormFieldOptions} from './put-tempagreement-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {FeescheduleCore} from '../feeschedule/feeschedule.core';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';

export class RAPutTempagreement extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTempagreementFormFieldOptions);

  RequestClass = SOPutTempagreement

  constructor(
    public entityCore: TempagreementCoreBase
  ) {
    super();
    if (putTempagreementFormFieldOptions.feeschedule) {
      putTempagreementFormFieldOptions.feeschedule.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('autoRenew'),
      this.getFormValueFromIdentifier('docusignLink'),
      this.getFormValueFromIdentifier('units'),
      this.getFormValueFromIdentifier('durationDays'),
      this.getFormValueFromIdentifier(
        'feeschedule',
        SOGetFeeschedule,
      ),
      this.getFormValueFromIdentifier('acq'),
      this.getFormValueFromIdentifier('top'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}