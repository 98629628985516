import { SOGetDcs } from '../dcs/so-get-dcs';


export class SOPostTag {

  constructor(
    public name: string,
    public identifier: string,
    public dcs: SOGetDcs,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTag(
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'dcs' in params ? params.dcs : null,
    );
  }
}
