import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTank } from './so-put-tank';
import { SOGetTank } from './so-get-tank';
import { SOPostTank } from './so-post-tank';
import { SODeleteTank } from './so-delete-tank';
import {SORespTank} from './so-resp-tank';
import {SOPluralTank} from './so-plural-tank';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTankService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'tank',
      entityCore
    );
  }

  /** GET Tank by id. Will 404 if id not found */
  get(request: SOGetTank): Observable<SOPluralTank> {
    return this.httpClient.get<SOPluralTank>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTank, resp) }),
      tap(_ => this.log(`fetched Tank`)),
      catchError(this.handleError<SOPluralTank>(`get Tank`))
    );
  }

  /** POST: add a new Tank to the server */
  post(request: SOPostTank): Observable<SORespTank> {
    return this.httpClient.post<SORespTank>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTank, resp) }),
      tap((response: SORespTank) => this.log(`added Tank w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTank>('Tank post'))
    );
  }

  /** PUT: update Tank  */
  put(request: SOPutTank): Observable<SORespTank> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTank>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTank, resp) }),
      tap((response: SORespTank) => this.log(`edited Tank w/ uid=${uid}`)),
      catchError(this.handleError<SORespTank>('Tank put'))
    );
  }

  /** DELETE: delete the Tank from the server */
  delete(request: SODeleteTank): Observable<SORespTank> {
    return this.httpClient.delete<SORespTank>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Tank uid=${request.uid}`)),
      catchError(this.handleError<SORespTank>('delete Tank'))
    );
  }
}
