import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutHestatusFormFields {

    public state: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutHestatusFormFields()

export const putHestatusFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
};