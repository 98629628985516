import {SOPutItem} from '../../core/item/so-put-item';
import {ItemCoreBase} from './item.core.base';
import {SOGetItem} from '../../core/item/so-get-item';
import {FormField, SelectionOption} from 'lionheartland';
import {putItemFormFieldOptions} from './put-item-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {OrderCore} from '../order/order.core';
import {SOGetOrder} from '../../core/order/so-get-order';
import {TempclinCore} from '../tempclin/tempclin.core';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {OfferingCore} from '../offering/offering.core';
import {SOGetOffering} from '../../core/offering/so-get-offering';

export class RAPutItem extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putItemFormFieldOptions);

  RequestClass = SOPutItem

  constructor(
    public entityCore: ItemCoreBase
  ) {
    super();
    if (putItemFormFieldOptions.order) {
      putItemFormFieldOptions.order.makeCore = true;
    }
    if (putItemFormFieldOptions.tempclin) {
      putItemFormFieldOptions.tempclin.makeCore = true;
    }
    if (putItemFormFieldOptions.offering) {
      putItemFormFieldOptions.offering.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier(
        'order',
        SOGetOrder,
      ),
      this.getFormValueFromIdentifier(
        'tempclin',
        SOGetTempclin,
      ),
      this.getFormValueFromIdentifier(
        'offering',
        SOGetOffering,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}