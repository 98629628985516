

export class SOPostPlant {

  constructor(
    public name: string,
    public timeZone: string,
    public timeZoneOffset: number,
    public daylightSavingsObserved: boolean = true,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostPlant(
      'name' in params ? params.name : null,
      'timeZone' in params ? params.timeZone : null,
      'timeZoneOffset' in params ? params.timeZoneOffset : null,
      'daylightSavingsObserved' in params ? params.daylightSavingsObserved : null,
    );
  }
}
