import { SOGetFeeschedule } from '../feeschedule/so-get-feeschedule';


export class SOPutFee {

  constructor(
    public uid: string,
    public displayName: string = null,
    public identifier: string = null,
    public amount: number = null,
    public feeschedule: SOGetFeeschedule = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutFee(
      'uid' in params ? params.uid : null,
      'displayName' in params ? params.displayName : null,
      'identifier' in params ? params.identifier : null,
      'amount' in params ? params.amount : null,
      'feeschedule' in params ? params.feeschedule : null,
    );
  }
}
