import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutAccountreport } from './so-put-accountreport';
import { SOGetAccountreport } from './so-get-accountreport';
import { SOPostAccountreport } from './so-post-accountreport';
import { SODeleteAccountreport } from './so-delete-accountreport';
import {SORespAccountreport} from './so-resp-accountreport';
import {SOPluralAccountreport} from './so-plural-accountreport';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOAccountreportService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'accountreport',
      entityCore
    );
  }

  /** GET Accountreport by id. Will 404 if id not found */
  get(request: SOGetAccountreport): Observable<SOPluralAccountreport> {
    return this.httpClient.get<SOPluralAccountreport>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAccountreport, resp) }),
      tap(_ => this.log(`fetched Accountreport`)),
      catchError(this.handleError<SOPluralAccountreport>(`get Accountreport`))
    );
  }

  /** POST: add a new Accountreport to the server */
  post(request: SOPostAccountreport): Observable<SORespAccountreport> {
    return this.httpClient.post<SORespAccountreport>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAccountreport, resp) }),
      tap((response: SORespAccountreport) => this.log(`added Accountreport w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAccountreport>('Accountreport post'))
    );
  }

  /** PUT: update Accountreport  */
  put(request: SOPutAccountreport): Observable<SORespAccountreport> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAccountreport>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAccountreport, resp) }),
      tap((response: SORespAccountreport) => this.log(`edited Accountreport w/ uid=${uid}`)),
      catchError(this.handleError<SORespAccountreport>('Accountreport put'))
    );
  }

  /** DELETE: delete the Accountreport from the server */
  delete(request: SODeleteAccountreport): Observable<SORespAccountreport> {
    return this.httpClient.delete<SORespAccountreport>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Accountreport uid=${request.uid}`)),
      catchError(this.handleError<SORespAccountreport>('delete Accountreport'))
    );
  }
}
