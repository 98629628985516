import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespTrailer } from '../trailer/so-resp-trailer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTrailerauth implements ObjectiveProtocol {

  entityType: string = 'trailerauth';
  uid: string;
  @Type(() => SORespAccount)
  account: SORespAccount;
  @Type(() => SORespTrailer)
  trailer: SORespTrailer;
}