import {SORespCalendar} from '../../core/calendar/so-resp-calendar';
import {CalendarCore} from './calendar.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutCalendarFormFields} from './put-calendar-form-fields';
import {SOGetCalendar} from '../../core/calendar/so-get-calendar';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CECalendarAttributesBase {

    name: DetailEntry
    label: DetailEntry
    distribution: DetailEntry
    slots: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespCalendar,
        app: GasDirectApp,
        formFields: PutCalendarFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.label = new DetailEntry(
            'Label',
            entity.label,
            'label',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        this.distribution.singleFieldSelect = true

        const slotsCore = app.makeCore( 'slot');
        slotsCore.soGet = SOGetSlot.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        slotsCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotsCore
        )
        slotsCore.title = heading ? heading : slotsCore.title
        slotsCore.listTitle = 'Slots'

        slotsCore.configureForDisplay(parentEntityViewSpec)

        this.slots = new DetailEntry(
          heading ? heading : slotsCore.listTitle,
          null,
          'slots',
          BaseDetailType.embeddedList,
          slotsCore,
          EditableType.never
        )
    }
}