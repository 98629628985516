import { BASE_ROUTES, AuthGuard, SvNavigatorComponent } from 'lionheartland';


import {AccountCore} from './entities/account/account.core';
import {AccountreportCore} from './entities/accountreport/accountreport.core';
import {ActivityCore} from './entities/activity/activity.core';
import {AdjustmentCore} from './entities/adjustment/adjustment.core';
import {AgreementCore} from './entities/agreement/agreement.core';
import {AgreemonthCore} from './entities/agreemonth/agreemonth.core';
import {AgreeyearCore} from './entities/agreeyear/agreeyear.core';
import {AwardCore} from './entities/award/award.core';
import {BacklogitemCore} from './entities/backlogitem/backlogitem.core';
import {BaysessionCore} from './entities/baysession/baysession.core';
import {BidCore} from './entities/bid/bid.core';
import {BolCore} from './entities/bol/bol.core';
import {BulkCore} from './entities/bulk/bulk.core';
import {CalendarCore} from './entities/calendar/calendar.core';
import {CarrierCore} from './entities/carrier/carrier.core';
import {CarrierauthCore} from './entities/carrierauth/carrierauth.core';
import {ChecklistCore} from './entities/checklist/checklist.core';
import {ChecklistitemCore} from './entities/checklistitem/checklistitem.core';
import {ChronreportCore} from './entities/chronreport/chronreport.core';
import {ClinCore} from './entities/clin/clin.core';
import {ClinyearlngCore} from './entities/clinyearlng/clinyearlng.core';
import {ContainersessionCore} from './entities/containersession/containersession.core';
import {ContractCore} from './entities/contract/contract.core';
import {CustomerCore} from './entities/customer/customer.core';
import {DcsCore} from './entities/dcs/dcs.core';
import {DeltaCore} from './entities/delta/delta.core';
import {DeltaweightCore} from './entities/deltaweight/deltaweight.core';
import {DistofferCore} from './entities/distoffer/distoffer.core';
import {DistributionCore} from './entities/distribution/distribution.core';
import {DistributiondayCore} from './entities/distributionday/distributionday.core';
import {DistributionmonthCore} from './entities/distributionmonth/distributionmonth.core';
import {DistributionreportCore} from './entities/distributionreport/distributionreport.core';
import {DistributionyearCore} from './entities/distributionyear/distributionyear.core';
import {DisttempagreementCore} from './entities/disttempagreement/disttempagreement.core';
import {DowntimeCore} from './entities/downtime/downtime.core';
import {EventCore} from './entities/event/event.core';
import {ExternalindexpriceCore} from './entities/externalindexprice/externalindexprice.core';
import {FaqCore} from './entities/faq/faq.core';
import {FeatureCore} from './entities/feature/feature.core';
import {FeatureascCore} from './entities/featureasc/featureasc.core';
import {FeeCore} from './entities/fee/fee.core';
import {FeescheduleCore} from './entities/feeschedule/feeschedule.core';
import {FeescheduleagreementascCore} from './entities/feescheduleagreementasc/feescheduleagreementasc.core';
import {GrantCore} from './entities/grant/grant.core';
import {HelloCore} from './entities/hello/hello.core';
import {HemonCore} from './entities/hemon/hemon.core';
import {HestatusCore} from './entities/hestatus/hestatus.core';
import {HestatusitemCore} from './entities/hestatusitem/hestatusitem.core';
import {HmonthCore} from './entities/hmonth/hmonth.core';
import {HyearCore} from './entities/hyear/hyear.core';
import {ImpurityCore} from './entities/impurity/impurity.core';
import {IndexpriceCore} from './entities/indexprice/indexprice.core';
import {InquiryCore} from './entities/inquiry/inquiry.core';
import {InreportCore} from './entities/inreport/inreport.core';
import {InvoiceCore} from './entities/invoice/invoice.core';
import {InvoiceitemCore} from './entities/invoiceitem/invoiceitem.core';
import {ItemCore} from './entities/item/item.core';
import {LinagCore} from './entities/linag/linag.core';
import {LineCore} from './entities/line/line.core';
import {LngloadCore} from './entities/lngload/lngload.core';
import {LoadCore} from './entities/load/load.core';
import {LoadfeeCore} from './entities/loadfee/loadfee.core';
import {MessageCore} from './entities/message/message.core';
import {MonthcloseCore} from './entities/monthclose/monthclose.core';
import {NaviCore} from './entities/navi/navi.core';
import {NavroaCore} from './entities/navroa/navroa.core';
import {NavtemrolaCore} from './entities/navtemrola/navtemrola.core';
import {OfferingCore} from './entities/offering/offering.core';
import {OrderCore} from './entities/order/order.core';
import {OrganizationCore} from './entities/organization/organization.core';
import {OrgrolaCore} from './entities/orgrola/orgrola.core';
import {PlantCore} from './entities/plant/plant.core';
import {PriceCore} from './entities/price/price.core';
import {ProductCore} from './entities/product/product.core';
import {ProductionCore} from './entities/production/production.core';
import {ProductionreportCore} from './entities/productionreport/productionreport.core';
import {ProviderCore} from './entities/provider/provider.core';
import {PublicationCore} from './entities/publication/publication.core';
import {QuoteletCore} from './entities/quotelet/quotelet.core';
import {RateableagreeyearallocationCore} from './entities/rateableagreeyearallocation/rateableagreeyearallocation.core';
import {ReleaseCore} from './entities/release/release.core';
import {ReservationCore} from './entities/reservation/reservation.core';
import {RoleCore} from './entities/role/role.core';
import {SaleCore} from './entities/sale/sale.core';
import {SaleaccountascCore} from './entities/saleaccountasc/saleaccountasc.core';
import {ServiceRequestCore} from './entities/service-request/service-request.core';
import {ShutelngCore} from './entities/shutelng/shutelng.core';
import {SitesessionCore} from './entities/sitesession/sitesession.core';
import {SlotCore} from './entities/slot/slot.core';
import {SlotletCore} from './entities/slotlet/slotlet.core';
import {SlotoptionCore} from './entities/slotoption/slotoption.core';
import {SubscriptionCore} from './entities/subscription/subscription.core';
import {TagCore} from './entities/tag/tag.core';
import {TagreportCore} from './entities/tagreport/tagreport.core';
import {TankCore} from './entities/tank/tank.core';
import {TankreportCore} from './entities/tankreport/tankreport.core';
import {TaskCore} from './entities/task/task.core';
import {TaskreportCore} from './entities/taskreport/taskreport.core';
import {TempagreementCore} from './entities/tempagreement/tempagreement.core';
import {TempagreetractCore} from './entities/tempagreetract/tempagreetract.core';
import {TempclinCore} from './entities/tempclin/tempclin.core';
import {TempriceCore} from './entities/temprice/temprice.core';
import {TemproleCore} from './entities/temprole/temprole.core';
import {TemptraclinCore} from './entities/temptraclin/temptraclin.core';
import {TemptractCore} from './entities/temptract/temptract.core';
import {TokenCore} from './entities/token/token.core';
import {TrailerCore} from './entities/trailer/trailer.core';
import {TrailerauthCore} from './entities/trailerauth/trailerauth.core';
import {TrailermergeCore} from './entities/trailermerge/trailermerge.core';
import {TransactionCore} from './entities/transaction/transaction.core';
import {UserCore} from './entities/user/user.core';
import {WeightCore} from './entities/weight/weight.core';
import {WonderwareCore} from './entities/wonderware/wonderware.core';

export const DEFAULT_ROUTES = [];

for (const route of BASE_ROUTES) {
  DEFAULT_ROUTES.push(
      route
  )
}


DEFAULT_ROUTES.push(
  { path: 'account', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AccountCore, identifier: 'account'} },
  { path: 'account/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AccountCore, identifier: 'account'} },
  { path: 'accountreport', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AccountreportCore, identifier: 'accountreport'} },
  { path: 'accountreport/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AccountreportCore, identifier: 'accountreport'} },
  { path: 'activity', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ActivityCore, identifier: 'activity'} },
  { path: 'activity/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ActivityCore, identifier: 'activity'} },
  { path: 'adjustment', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AdjustmentCore, identifier: 'adjustment'} },
  { path: 'adjustment/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AdjustmentCore, identifier: 'adjustment'} },
  { path: 'agreement', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AgreementCore, identifier: 'agreement'} },
  { path: 'agreement/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AgreementCore, identifier: 'agreement'} },
  { path: 'agreemonth', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AgreemonthCore, identifier: 'agreemonth'} },
  { path: 'agreemonth/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AgreemonthCore, identifier: 'agreemonth'} },
  { path: 'agreeyear', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AgreeyearCore, identifier: 'agreeyear'} },
  { path: 'agreeyear/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AgreeyearCore, identifier: 'agreeyear'} },
  { path: 'award', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AwardCore, identifier: 'award'} },
  { path: 'award/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AwardCore, identifier: 'award'} },
  { path: 'backlogitem', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BacklogitemCore, identifier: 'backlogitem'} },
  { path: 'backlogitem/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BacklogitemCore, identifier: 'backlogitem'} },
  { path: 'baysession', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BaysessionCore, identifier: 'baysession'} },
  { path: 'baysession/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BaysessionCore, identifier: 'baysession'} },
  { path: 'bid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BidCore, identifier: 'bid'} },
  { path: 'bid/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BidCore, identifier: 'bid'} },
  { path: 'bol', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BolCore, identifier: 'bol'} },
  { path: 'bol/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BolCore, identifier: 'bol'} },
  { path: 'bulk', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BulkCore, identifier: 'bulk'} },
  { path: 'bulk/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BulkCore, identifier: 'bulk'} },
  { path: 'calendar', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CalendarCore, identifier: 'calendar'} },
  { path: 'calendar/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CalendarCore, identifier: 'calendar'} },
  { path: 'carrier', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CarrierCore, identifier: 'carrier'} },
  { path: 'carrier/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CarrierCore, identifier: 'carrier'} },
  { path: 'carrierauth', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CarrierauthCore, identifier: 'carrierauth'} },
  { path: 'carrierauth/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CarrierauthCore, identifier: 'carrierauth'} },
  { path: 'checklist', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ChecklistCore, identifier: 'checklist'} },
  { path: 'checklist/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ChecklistCore, identifier: 'checklist'} },
  { path: 'checklistitem', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ChecklistitemCore, identifier: 'checklistitem'} },
  { path: 'checklistitem/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ChecklistitemCore, identifier: 'checklistitem'} },
  { path: 'chronreport', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ChronreportCore, identifier: 'chronreport'} },
  { path: 'chronreport/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ChronreportCore, identifier: 'chronreport'} },
  { path: 'clin', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ClinCore, identifier: 'clin'} },
  { path: 'clin/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ClinCore, identifier: 'clin'} },
  { path: 'clinyearlng', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ClinyearlngCore, identifier: 'clinyearlng'} },
  { path: 'clinyearlng/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ClinyearlngCore, identifier: 'clinyearlng'} },
  { path: 'containersession', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ContainersessionCore, identifier: 'containersession'} },
  { path: 'containersession/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ContainersessionCore, identifier: 'containersession'} },
  { path: 'contract', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ContractCore, identifier: 'contract'} },
  { path: 'contract/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ContractCore, identifier: 'contract'} },
  { path: 'customer', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CustomerCore, identifier: 'customer'} },
  { path: 'customer/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CustomerCore, identifier: 'customer'} },
  { path: 'dcs', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DcsCore, identifier: 'dcs'} },
  { path: 'dcs/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DcsCore, identifier: 'dcs'} },
  { path: 'delta', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DeltaCore, identifier: 'delta'} },
  { path: 'delta/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DeltaCore, identifier: 'delta'} },
  { path: 'deltaweight', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DeltaweightCore, identifier: 'deltaweight'} },
  { path: 'deltaweight/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DeltaweightCore, identifier: 'deltaweight'} },
  { path: 'distoffer', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistofferCore, identifier: 'distoffer'} },
  { path: 'distoffer/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistofferCore, identifier: 'distoffer'} },
  { path: 'distribution', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributionCore, identifier: 'distribution'} },
  { path: 'distribution/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributionCore, identifier: 'distribution'} },
  { path: 'distributionday', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributiondayCore, identifier: 'distributionday'} },
  { path: 'distributionday/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributiondayCore, identifier: 'distributionday'} },
  { path: 'distributionmonth', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributionmonthCore, identifier: 'distributionmonth'} },
  { path: 'distributionmonth/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributionmonthCore, identifier: 'distributionmonth'} },
  { path: 'distributionreport', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributionreportCore, identifier: 'distributionreport'} },
  { path: 'distributionreport/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributionreportCore, identifier: 'distributionreport'} },
  { path: 'distributionyear', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributionyearCore, identifier: 'distributionyear'} },
  { path: 'distributionyear/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributionyearCore, identifier: 'distributionyear'} },
  { path: 'disttempagreement', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DisttempagreementCore, identifier: 'disttempagreement'} },
  { path: 'disttempagreement/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DisttempagreementCore, identifier: 'disttempagreement'} },
  { path: 'downtime', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DowntimeCore, identifier: 'downtime'} },
  { path: 'downtime/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DowntimeCore, identifier: 'downtime'} },
  { path: 'event', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EventCore, identifier: 'event'} },
  { path: 'event/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EventCore, identifier: 'event'} },
  { path: 'externalindexprice', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ExternalindexpriceCore, identifier: 'externalindexprice'} },
  { path: 'externalindexprice/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ExternalindexpriceCore, identifier: 'externalindexprice'} },
  { path: 'faq', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FaqCore, identifier: 'faq'} },
  { path: 'faq/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FaqCore, identifier: 'faq'} },
  { path: 'feature', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeatureCore, identifier: 'feature'} },
  { path: 'feature/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeatureCore, identifier: 'feature'} },
  { path: 'featureasc', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeatureascCore, identifier: 'featureasc'} },
  { path: 'featureasc/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeatureascCore, identifier: 'featureasc'} },
  { path: 'fee', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeeCore, identifier: 'fee'} },
  { path: 'fee/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeeCore, identifier: 'fee'} },
  { path: 'feeschedule', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeescheduleCore, identifier: 'feeschedule'} },
  { path: 'feeschedule/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeescheduleCore, identifier: 'feeschedule'} },
  { path: 'feescheduleagreementasc', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeescheduleagreementascCore, identifier: 'feescheduleagreementasc'} },
  { path: 'feescheduleagreementasc/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: FeescheduleagreementascCore, identifier: 'feescheduleagreementasc'} },
  { path: 'grant', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: GrantCore, identifier: 'grant'} },
  { path: 'grant/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: GrantCore, identifier: 'grant'} },
  { path: 'hello', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HelloCore, identifier: 'hello'} },
  { path: 'hello/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HelloCore, identifier: 'hello'} },
  { path: 'hemon', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HemonCore, identifier: 'hemon'} },
  { path: 'hemon/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HemonCore, identifier: 'hemon'} },
  { path: 'hestatus', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HestatusCore, identifier: 'hestatus'} },
  { path: 'hestatus/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HestatusCore, identifier: 'hestatus'} },
  { path: 'hestatusitem', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HestatusitemCore, identifier: 'hestatusitem'} },
  { path: 'hestatusitem/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HestatusitemCore, identifier: 'hestatusitem'} },
  { path: 'hmonth', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HmonthCore, identifier: 'hmonth'} },
  { path: 'hmonth/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HmonthCore, identifier: 'hmonth'} },
  { path: 'hyear', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HyearCore, identifier: 'hyear'} },
  { path: 'hyear/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HyearCore, identifier: 'hyear'} },
  { path: 'impurity', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ImpurityCore, identifier: 'impurity'} },
  { path: 'impurity/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ImpurityCore, identifier: 'impurity'} },
  { path: 'indexprice', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IndexpriceCore, identifier: 'indexprice'} },
  { path: 'indexprice/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IndexpriceCore, identifier: 'indexprice'} },
  { path: 'inquiry', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InquiryCore, identifier: 'inquiry'} },
  { path: 'inquiry/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InquiryCore, identifier: 'inquiry'} },
  { path: 'inreport', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InreportCore, identifier: 'inreport'} },
  { path: 'inreport/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InreportCore, identifier: 'inreport'} },
  { path: 'invoice', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceCore, identifier: 'invoice'} },
  { path: 'invoice/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceCore, identifier: 'invoice'} },
  { path: 'invoiceitem', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceitemCore, identifier: 'invoiceitem'} },
  { path: 'invoiceitem/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceitemCore, identifier: 'invoiceitem'} },
  { path: 'item', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ItemCore, identifier: 'item'} },
  { path: 'item/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ItemCore, identifier: 'item'} },
  { path: 'linag', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LinagCore, identifier: 'linag'} },
  { path: 'linag/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LinagCore, identifier: 'linag'} },
  { path: 'line', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LineCore, identifier: 'line'} },
  { path: 'line/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LineCore, identifier: 'line'} },
  { path: 'lngload', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LngloadCore, identifier: 'lngload'} },
  { path: 'lngload/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LngloadCore, identifier: 'lngload'} },
  { path: 'load', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LoadCore, identifier: 'load'} },
  { path: 'load/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LoadCore, identifier: 'load'} },
  { path: 'loadfee', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LoadfeeCore, identifier: 'loadfee'} },
  { path: 'loadfee/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LoadfeeCore, identifier: 'loadfee'} },
  { path: 'message', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: MessageCore, identifier: 'message'} },
  { path: 'message/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: MessageCore, identifier: 'message'} },
  { path: 'monthclose', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: MonthcloseCore, identifier: 'monthclose'} },
  { path: 'monthclose/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: MonthcloseCore, identifier: 'monthclose'} },
  { path: 'navi', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: NaviCore, identifier: 'navi'} },
  { path: 'navi/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: NaviCore, identifier: 'navi'} },
  { path: 'navroa', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: NavroaCore, identifier: 'navroa'} },
  { path: 'navroa/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: NavroaCore, identifier: 'navroa'} },
  { path: 'navtemrola', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: NavtemrolaCore, identifier: 'navtemrola'} },
  { path: 'navtemrola/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: NavtemrolaCore, identifier: 'navtemrola'} },
  { path: 'offering', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OfferingCore, identifier: 'offering'} },
  { path: 'offering/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OfferingCore, identifier: 'offering'} },
  { path: 'order', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrderCore, identifier: 'order'} },
  { path: 'order/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrderCore, identifier: 'order'} },
  { path: 'organization', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationCore, identifier: 'organization'} },
  { path: 'organization/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationCore, identifier: 'organization'} },
  { path: 'orgrola', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrgrolaCore, identifier: 'orgrola'} },
  { path: 'orgrola/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrgrolaCore, identifier: 'orgrola'} },
  { path: 'plant', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PlantCore, identifier: 'plant'} },
  { path: 'plant/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PlantCore, identifier: 'plant'} },
  { path: 'price', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PriceCore, identifier: 'price'} },
  { path: 'price/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PriceCore, identifier: 'price'} },
  { path: 'product', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductCore, identifier: 'product'} },
  { path: 'product/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductCore, identifier: 'product'} },
  { path: 'production', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductionCore, identifier: 'production'} },
  { path: 'production/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductionCore, identifier: 'production'} },
  { path: 'productionreport', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductionreportCore, identifier: 'productionreport'} },
  { path: 'productionreport/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductionreportCore, identifier: 'productionreport'} },
  { path: 'provider', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProviderCore, identifier: 'provider'} },
  { path: 'provider/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProviderCore, identifier: 'provider'} },
  { path: 'publication', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PublicationCore, identifier: 'publication'} },
  { path: 'publication/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PublicationCore, identifier: 'publication'} },
  { path: 'quotelet', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: QuoteletCore, identifier: 'quotelet'} },
  { path: 'quotelet/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: QuoteletCore, identifier: 'quotelet'} },
  { path: 'rateableagreeyearallocation', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RateableagreeyearallocationCore, identifier: 'rateableagreeyearallocation'} },
  { path: 'rateableagreeyearallocation/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RateableagreeyearallocationCore, identifier: 'rateableagreeyearallocation'} },
  { path: 'release', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ReleaseCore, identifier: 'release'} },
  { path: 'release/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ReleaseCore, identifier: 'release'} },
  { path: 'reservation', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ReservationCore, identifier: 'reservation'} },
  { path: 'reservation/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ReservationCore, identifier: 'reservation'} },
  { path: 'role', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleCore, identifier: 'role'} },
  { path: 'role/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleCore, identifier: 'role'} },
  { path: 'sale', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SaleCore, identifier: 'sale'} },
  { path: 'sale/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SaleCore, identifier: 'sale'} },
  { path: 'saleaccountasc', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SaleaccountascCore, identifier: 'saleaccountasc'} },
  { path: 'saleaccountasc/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SaleaccountascCore, identifier: 'saleaccountasc'} },
  { path: 'service_request', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ServiceRequestCore, identifier: 'serviceRequest'} },
  { path: 'service_request/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ServiceRequestCore, identifier: 'serviceRequest'} },
  { path: 'shutelng', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ShutelngCore, identifier: 'shutelng'} },
  { path: 'shutelng/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ShutelngCore, identifier: 'shutelng'} },
  { path: 'sitesession', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SitesessionCore, identifier: 'sitesession'} },
  { path: 'sitesession/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SitesessionCore, identifier: 'sitesession'} },
  { path: 'slot', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SlotCore, identifier: 'slot'} },
  { path: 'slot/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SlotCore, identifier: 'slot'} },
  { path: 'slotlet', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SlotletCore, identifier: 'slotlet'} },
  { path: 'slotlet/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SlotletCore, identifier: 'slotlet'} },
  { path: 'slotoption', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SlotoptionCore, identifier: 'slotoption'} },
  { path: 'slotoption/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SlotoptionCore, identifier: 'slotoption'} },
  { path: 'subscription', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SubscriptionCore, identifier: 'subscription'} },
  { path: 'subscription/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SubscriptionCore, identifier: 'subscription'} },
  { path: 'tag', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TagCore, identifier: 'tag'} },
  { path: 'tag/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TagCore, identifier: 'tag'} },
  { path: 'tagreport', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TagreportCore, identifier: 'tagreport'} },
  { path: 'tagreport/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TagreportCore, identifier: 'tagreport'} },
  { path: 'tank', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TankCore, identifier: 'tank'} },
  { path: 'tank/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TankCore, identifier: 'tank'} },
  { path: 'tankreport', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TankreportCore, identifier: 'tankreport'} },
  { path: 'tankreport/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TankreportCore, identifier: 'tankreport'} },
  { path: 'task', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TaskCore, identifier: 'task'} },
  { path: 'task/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TaskCore, identifier: 'task'} },
  { path: 'taskreport', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TaskreportCore, identifier: 'taskreport'} },
  { path: 'taskreport/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TaskreportCore, identifier: 'taskreport'} },
  { path: 'tempagreement', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TempagreementCore, identifier: 'tempagreement'} },
  { path: 'tempagreement/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TempagreementCore, identifier: 'tempagreement'} },
  { path: 'tempagreetract', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TempagreetractCore, identifier: 'tempagreetract'} },
  { path: 'tempagreetract/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TempagreetractCore, identifier: 'tempagreetract'} },
  { path: 'tempclin', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TempclinCore, identifier: 'tempclin'} },
  { path: 'tempclin/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TempclinCore, identifier: 'tempclin'} },
  { path: 'temprice', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TempriceCore, identifier: 'temprice'} },
  { path: 'temprice/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TempriceCore, identifier: 'temprice'} },
  { path: 'temprole', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemproleCore, identifier: 'temprole'} },
  { path: 'temprole/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemproleCore, identifier: 'temprole'} },
  { path: 'temptraclin', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemptraclinCore, identifier: 'temptraclin'} },
  { path: 'temptraclin/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemptraclinCore, identifier: 'temptraclin'} },
  { path: 'temptract', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemptractCore, identifier: 'temptract'} },
  { path: 'temptract/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemptractCore, identifier: 'temptract'} },
  { path: 'token', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TokenCore, identifier: 'token'} },
  { path: 'token/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TokenCore, identifier: 'token'} },
  { path: 'trailer', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TrailerCore, identifier: 'trailer'} },
  { path: 'trailer/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TrailerCore, identifier: 'trailer'} },
  { path: 'trailerauth', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TrailerauthCore, identifier: 'trailerauth'} },
  { path: 'trailerauth/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TrailerauthCore, identifier: 'trailerauth'} },
  { path: 'trailermerge', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TrailermergeCore, identifier: 'trailermerge'} },
  { path: 'trailermerge/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TrailermergeCore, identifier: 'trailermerge'} },
  { path: 'transaction', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TransactionCore, identifier: 'transaction'} },
  { path: 'transaction/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TransactionCore, identifier: 'transaction'} },
  { path: 'user', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: UserCore, identifier: 'user'} },
  { path: 'user/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: UserCore, identifier: 'user'} },
  { path: 'weight', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WeightCore, identifier: 'weight'} },
  { path: 'weight/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WeightCore, identifier: 'weight'} },
  { path: 'wonderware', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WonderwareCore, identifier: 'wonderware'} },
  { path: 'wonderware/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WonderwareCore, identifier: 'wonderware'} },
)