import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOHemonService} from '../../core/hemon/eo-hemon';
import {SOGetHemon} from '../../core/hemon/so-get-hemon';
import {RAPutHemon} from './ra-put-hemon';
import {RAPostHemon} from './ra-post-hemon';
import {RADeleteHemon} from './ra-delete-hemon';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEHemon } from "./ce-hemon"


export class HemonCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'hemon';
  title = 'Hemon';
  ceProtocol: CEConstructor = CEHemon

  entityService: EOHemonService;
  soGetClass = SOGetHemon;
  soGet: SOGetHemon;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOHemonService
    );
    this.adapters = [
      new RAPostHemon(this),
      new RAPutHemon(this),
      new RADeleteHemon(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetHemon();
    this.soGet.queryOptions.limit = this.limit;
  }


}