import { SOGetChecklist } from '../checklist/so-get-checklist';


export class SOPostChecklistitem {

  constructor(
    public body: string,
    public index: number,
    public required: boolean,
    public checklist: SOGetChecklist,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostChecklistitem(
      'body' in params ? params.body : null,
      'index' in params ? params.index : null,
      'required' in params ? params.required : null,
      'checklist' in params ? params.checklist : null,
    );
  }
}
