import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostBacklogitemFormFields {

    public type: FormField

    constructor() {

        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostBacklogitemFormFields()

export const postBacklogitemFormFieldOptions: {[key: string]: FormField} = {
  type: fields.type,
};