import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutSitesessionFormFields {

    public arrived: FormField
    public departed: FormField

    constructor() {

        this.arrived = new FormField(
          'arrived',
          'Arrived',
          null,
          FormFieldType.datetime,
          false,
        )
        this.departed = new FormField(
          'departed',
          'Departed',
          null,
          FormFieldType.datetime,
          false,
        )

    }
}

const fields = new PutSitesessionFormFields()

export const putSitesessionFormFieldOptions: {[key: string]: FormField} = {
  arrived: fields.arrived,
  departed: fields.departed,
};