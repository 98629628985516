import { SOGetInvoice } from '../invoice/so-get-invoice';


export class SOPutTransaction {

  constructor(
    public uid: string,
    public dateCreated: Date = null,
    public description: string = null,
    public amount: number = null,
    public invoice: SOGetInvoice = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTransaction(
      'uid' in params ? params.uid : null,
      'dateCreated' in params ? params.dateCreated : null,
      'description' in params ? params.description : null,
      'amount' in params ? params.amount : null,
      'invoice' in params ? params.invoice : null,
    );
  }
}
