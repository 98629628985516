

export class SOPutHestatusitem {

  constructor(
    public uid: string,
    public state: string = null,
    public location: string = null,
    public loadStatus: string = null,
    public gone: boolean = null,
    public n2Level: number = null,
    public n2Pressure: number = null,
    public heTemp: number = null,
    public heLevel: number = null,
    public hePressure: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutHestatusitem(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'location' in params ? params.location : null,
      'loadStatus' in params ? params.loadStatus : null,
      'gone' in params ? params.gone : null,
      'n2Level' in params ? params.n2Level : null,
      'n2Pressure' in params ? params.n2Pressure : null,
      'heTemp' in params ? params.heTemp : null,
      'heLevel' in params ? params.heLevel : null,
      'hePressure' in params ? params.hePressure : null,
    );
  }
}
