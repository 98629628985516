import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutSale } from './so-put-sale';
import { SOGetSale } from './so-get-sale';
import { SOPostSale } from './so-post-sale';
import { SODeleteSale } from './so-delete-sale';
import {SORespSale} from './so-resp-sale';
import {SOPluralSale} from './so-plural-sale';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOSaleService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'sale',
      entityCore
    );
  }

  /** GET Sale by id. Will 404 if id not found */
  get(request: SOGetSale): Observable<SOPluralSale> {
    return this.httpClient.get<SOPluralSale>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralSale, resp) }),
      tap(_ => this.log(`fetched Sale`)),
      catchError(this.handleError<SOPluralSale>(`get Sale`))
    );
  }

  /** POST: add a new Sale to the server */
  post(request: SOPostSale): Observable<SORespSale> {
    return this.httpClient.post<SORespSale>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSale, resp) }),
      tap((response: SORespSale) => this.log(`added Sale w/ id=${response.uid}`)),
      catchError(this.handleError<SORespSale>('Sale post'))
    );
  }

  /** PUT: update Sale  */
  put(request: SOPutSale): Observable<SORespSale> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSale>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSale, resp) }),
      tap((response: SORespSale) => this.log(`edited Sale w/ uid=${uid}`)),
      catchError(this.handleError<SORespSale>('Sale put'))
    );
  }

  /** DELETE: delete the Sale from the server */
  delete(request: SODeleteSale): Observable<SORespSale> {
    return this.httpClient.delete<SORespSale>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Sale uid=${request.uid}`)),
      catchError(this.handleError<SORespSale>('delete Sale'))
    );
  }
}
