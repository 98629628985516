import {SORespPublication} from '../../core/publication/so-resp-publication';
import {PublicationCore} from './publication.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutPublicationFormFields} from './put-publication-form-fields';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';
import {SOGetMessage} from '../../core/message/so-get-message';


export class CEPublicationAttributesBase {

    subscriptions: DetailEntry
    identifier: DetailEntry
    displayName: DetailEntry
    distribution: DetailEntry
    account: DetailEntry
    carrier: DetailEntry
    messages: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespPublication,
        app: GasDirectApp,
        formFields: PutPublicationFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.toggleGroup,
            null,
            EditableType.never,
        )

        this.displayName = new DetailEntry(
            'Display name',
            entity.displayName,
            'displayName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.displayName
        )


        const subscriptionsCore = app.makeCore( 'subscription');
        subscriptionsCore.soGet = SOGetSubscription.construct({
          publication: new SOGetPublication(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            subscriptionsCore
        )
        subscriptionsCore.title = heading ? heading : subscriptionsCore.title
        subscriptionsCore.listTitle = 'Subscriptions'

        subscriptionsCore.configureForDisplay(parentEntityViewSpec)

        this.subscriptions = new DetailEntry(
          heading ? heading : subscriptionsCore.listTitle,
          null,
          'subscriptions',
          BaseDetailType.embeddedList,
          subscriptionsCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.never
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true

        const carrierCore = app.makeCore( 'carrier');
        carrierCore.soGet = SOGetCarrier.construct({
        });
        let carrierCeProtocol;
        if (entity.carrier) {
            carrierCeProtocol = createCEProtocol(
                carrierCore.ceProtocol,
                parentEntityViewSpec,
                carrierCore,
                entity.carrier,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            carrierCore
        )
        carrierCore.title = heading ? heading : carrierCore.title
        carrierCore.listTitle = 'Carrier'

        if (entity.carrier) {
            displayStringFactory.reconfigure(
                entity.carrier,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        carrierCore.configureForDisplay(parentEntityViewSpec)

        this.carrier = new DetailEntry(
          heading ? heading : carrierCore.listTitle,
          carrierCeProtocol ? carrierCeProtocol.entityListView.fullHeading : null,
          'carrier',
          BaseDetailType.pushedDetail,
          carrierCore,
          EditableType.never
        )
        if (entity.carrier) {
          this.carrier.ceProtocol = carrierCeProtocol;
          this.carrier.cachedListEntry = carrierCore.makeListEntry(parentEntityViewSpec, entity.carrier, 0)
          carrierCore.selectedEntity = entity.carrier;
        }
        this.carrier.singleFieldSelect = true

        const messagesCore = app.makeCore( 'message');
        messagesCore.soGet = SOGetMessage.construct({
          publication: new SOGetPublication(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            messagesCore
        )
        messagesCore.title = heading ? heading : messagesCore.title
        messagesCore.listTitle = 'Messages'

        messagesCore.configureForDisplay(parentEntityViewSpec)

        this.messages = new DetailEntry(
          heading ? heading : messagesCore.listTitle,
          null,
          'messages',
          BaseDetailType.embeddedList,
          messagesCore,
          EditableType.never
        )
    }
}