import { SOGetAccount } from '../account/so-get-account';
import { SOGetCarrier } from '../carrier/so-get-carrier';


export class SOPostCarrierauth {

  constructor(
    public account: SOGetAccount,
    public carrier: SOGetCarrier,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCarrierauth(
      'account' in params ? params.account : null,
      'carrier' in params ? params.carrier : null,
    );
  }
}
