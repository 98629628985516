import { Type } from 'class-transformer';
import { SORespAgreement } from '../agreement/so-resp-agreement';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespContract implements ObjectiveProtocol {

  entityType: string = 'contract';
  uid: string;
  name: string;
  description: string = "";
  takeOrPay: number;
  minLoadSizeToQualify: number = null;
  quantity: number = 0;
  quantityUsed: number = 0;
  loadQuantity: number = 0;
  loadQuantityUsed: number = 0;
  docusignLink: string;
  state: string;
  type: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  @Type(() => SORespAgreement)
  agreement: SORespAgreement;
  loadType: string = null;
}