import {SOPostTrailer} from '../../core/trailer/so-post-trailer';
import {TrailerCoreBase} from './trailer.core.base';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {FormField, SelectionOption} from 'lionheartland';
import {postTrailerFormFieldOptions} from './post-trailer-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostTrailer extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTrailerFormFieldOptions);

  RequestClass = SOPostTrailer

  constructor(
    public entityCore: TrailerCoreBase
  ) {
    super();
    if (postTrailerFormFieldOptions.organization) {
      postTrailerFormFieldOptions.organization.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('size'),
      this.getFormValueFromIdentifier('chassisId'),
      this.getFormValueFromIdentifier('dotId'),
      this.getFormValueFromIdentifier('dotInspectionDate'),
      this.getFormValueFromIdentifier('dotE'),
      this.getFormValueFromIdentifier('dotSp'),
      this.getFormValueFromIdentifier('mawp'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
      ),
      this.getFormValueFromIdentifier('requestingAccount'),
      this.getFormValueFromIdentifier('fillVolume'),
      this.getFormValueFromIdentifier('mawt'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}