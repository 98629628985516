import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODistributionreportService} from '../../core/distributionreport/eo-distributionreport';
import {SOGetDistributionreport} from '../../core/distributionreport/so-get-distributionreport';
import {RAPutDistributionreport} from './ra-put-distributionreport';
import {RAPostDistributionreport} from './ra-post-distributionreport';
import {RADeleteDistributionreport} from './ra-delete-distributionreport';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEDistributionreport } from "./ce-distributionreport"


export class DistributionreportCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'distributionreport';
  title = 'Distributionreport';
  ceProtocol: CEConstructor = CEDistributionreport

  entityService: EODistributionreportService;
  soGetClass = SOGetDistributionreport;
  soGet: SOGetDistributionreport;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODistributionreportService
    );
    this.adapters = [
      new RAPostDistributionreport(this),
      new RAPutDistributionreport(this),
      new RADeleteDistributionreport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDistributionreport();
    this.soGet.queryOptions.limit = this.limit;
  }


}