import {SORespDistribution} from '../../core/distribution/so-resp-distribution';
import {DistributionCore} from './distribution.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistributionFormFields} from './put-distribution-form-fields';
import { CEDistributionAttributes } from './ce-distribution-attributes'


export class CEDistributionBase extends CEBase {

    public details: CEDistributionAttributes;
    public fromFields: PutDistributionFormFields = new PutDistributionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DistributionCore,
        public entity: SORespDistribution,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDistributionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.marketIndexId)
        this.attributes.push(this.details.conversionRate)
        this.attributes.push(this.details.galToWeight)
        this.attributes.push(this.details.defaultSlotsPerDay)
        this.attributes.push(this.details.providerSlotDurationHours)
        this.attributes.push(this.details.customerSlotDurationHours)
        this.attributes.push(this.details.showSlotDuration)
        this.attributes.push(this.details.pickupInstructions)
        this.attributes.push(this.details.paymentInstructions)
        this.attributes.push(this.details.checkoutText)
        this.attributes.push(this.details.purchaseCompleteText)
        this.attributes.push(this.details.bolHeader)
        this.attributes.push(this.details.bolWarning)
        this.attributes.push(this.details.loadingChecklist)
        this.attributes.push(this.details.departureChecklist)
        this.attributes.push(this.details.loadNumberPrefix)
        this.attributes.push(this.details.loadNumberStart)
        this.attributes.push(this.details.nextLoadNumber)
        this.attributes.push(this.details.invoicePocText)
        this.attributes.push(this.details.invoicePaymentDetailText)
        this.attributes.push(this.details.invoiceNumberPrefix)
        this.attributes.push(this.details.line)
        this.attributes.push(this.details.distoffers)
        this.attributes.push(this.details.distributionreports)
        this.attributes.push(this.details.distributionyears)
        this.attributes.push(this.details.distributionmonths)
        this.attributes.push(this.details.distributionydays)
        this.attributes.push(this.details.slots)
        this.attributes.push(this.details.loads)
        this.attributes.push(this.details.serviceRequests)
        this.attributes.push(this.details.calendars)
        this.attributes.push(this.details.accounts)
        this.attributes.push(this.details.monthcloses)
        this.attributes.push(this.details.temptracts)
        this.attributes.push(this.details.tempclins)
        this.attributes.push(this.details.roles)
        this.attributes.push(this.details.publications)
        this.attributes.push(this.details.faqs)
        this.attributes.push(this.details.indexprices)
        this.attributes.push(this.details.checklists)
        this.attributes.push(this.details.taskreports)
        this.attributes.push(this.details.feeschedules)
        this.attributes.push(this.details.containersessions)
        this.attributes.push(this.details.disttempagreements)
        this.attributes.push(this.details.sales)
        this.attributes.push(this.details.featureascs)

    }
}