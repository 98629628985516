import {SORespChecklist} from '../../core/checklist/so-resp-checklist';
import {ChecklistCore} from './checklist.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutChecklistFormFields} from './put-checklist-form-fields';
import {SOGetChecklist} from '../../core/checklist/so-get-checklist';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetChecklistitem} from '../../core/checklistitem/so-get-checklistitem';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CEChecklistAttributesBase {

    body: DetailEntry
    name: DetailEntry
    identifier: DetailEntry
    load: DetailEntry
    distribution: DetailEntry
    fileLink: DetailEntry
    containersession: DetailEntry
    items: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespChecklist,
        app: GasDirectApp,
        formFields: PutChecklistFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.body = new DetailEntry(
            'Body',
            entity.body,
            'body',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.body
        )

        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )

        this.fileLink = new DetailEntry(
            'File link',
            entity.fileLink,
            'fileLink',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.fileLink
        )


        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.always,
          formFields.load
        )
        this.load.singleFieldSelect = true

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        this.distribution.singleFieldSelect = true

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        this.containersession.singleFieldSelect = true

        const itemsCore = app.makeCore( 'checklistitem');
        itemsCore.soGet = SOGetChecklistitem.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            itemsCore
        )
        itemsCore.title = heading ? heading : itemsCore.title
        itemsCore.listTitle = 'Items'

        itemsCore.configureForDisplay(parentEntityViewSpec)

        this.items = new DetailEntry(
          heading ? heading : itemsCore.listTitle,
          null,
          'items',
          BaseDetailType.embeddedList,
          itemsCore,
          EditableType.always,
          formFields.items
        )
    }
}