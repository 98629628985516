import { SOGetOrder } from '../order/so-get-order';


export class SOPutQuotelet {

  constructor(
    public uid: string,
    public type: string = null,
    public units: string = null,
    public quantityVolume: number = null,
    public quantityLoads: number = null,
    public indexRate: number = null,
    public variableRate: number = null,
    public totalRate: number = null,
    public totalAmount: number = null,
    public order: SOGetOrder = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutQuotelet(
      'uid' in params ? params.uid : null,
      'type' in params ? params.type : null,
      'units' in params ? params.units : null,
      'quantityVolume' in params ? params.quantityVolume : null,
      'quantityLoads' in params ? params.quantityLoads : null,
      'indexRate' in params ? params.indexRate : null,
      'variableRate' in params ? params.variableRate : null,
      'totalRate' in params ? params.totalRate : null,
      'totalAmount' in params ? params.totalAmount : null,
      'order' in params ? params.order : null,
    );
  }
}
