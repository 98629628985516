import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostActivityFormFields {

    public type: FormField
    public summary: FormField
    public body: FormField
    public order: FormField
    public load: FormField
    public user: FormField
    public containersession: FormField
    public slot: FormField

    constructor() {

        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )
        this.summary = new FormField(
          'summary',
          'Summary',
          null,
          FormFieldType.text,
          true,
        )
        this.body = new FormField(
          'body',
          'Body',
          null,
          FormFieldType.text,
          true,
        )
        this.order = new FormField(
          'order',
          'Order',
          'order',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.autoCompleteList,
          false,
        )
        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.slot = new FormField(
          'slot',
          'Slot',
          'slot',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostActivityFormFields()

export const postActivityFormFieldOptions: {[key: string]: FormField} = {
  type: fields.type,
  summary: fields.summary,
  body: fields.body,
  order: fields.order,
  load: fields.load,
  user: fields.user,
  containersession: fields.containersession,
  slot: fields.slot,
};