import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAgreeyearService} from '../../core/agreeyear/eo-agreeyear';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';
import {RAPutAgreeyear} from './ra-put-agreeyear';
import {RAPostAgreeyear} from './ra-post-agreeyear';
import {RADeleteAgreeyear} from './ra-delete-agreeyear';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEAgreeyear } from "./ce-agreeyear"


export class AgreeyearCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'agreeyear';
  title = 'Agreeyear';
  ceProtocol: CEConstructor = CEAgreeyear

  entityService: EOAgreeyearService;
  soGetClass = SOGetAgreeyear;
  soGet: SOGetAgreeyear;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAgreeyearService
    );
    this.adapters = [
      new RAPostAgreeyear(this),
      new RAPutAgreeyear(this),
      new RADeleteAgreeyear(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAgreeyear();
    this.soGet.queryOptions.limit = this.limit;
  }


}