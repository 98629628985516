import {SOPutCarrierauth} from '../../core/carrierauth/so-put-carrierauth';
import {CarrierauthCoreBase} from './carrierauth.core.base';
import {SOGetCarrierauth} from '../../core/carrierauth/so-get-carrierauth';
import {FormField, SelectionOption} from 'lionheartland';
import {putCarrierauthFormFieldOptions} from './put-carrierauth-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {CarrierCore} from '../carrier/carrier.core';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';

export class RAPutCarrierauth extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putCarrierauthFormFieldOptions);

  RequestClass = SOPutCarrierauth

  constructor(
    public entityCore: CarrierauthCoreBase
  ) {
    super();
    if (putCarrierauthFormFieldOptions.account) {
      putCarrierauthFormFieldOptions.account.makeCore = true;
    }
    if (putCarrierauthFormFieldOptions.carrier) {
      putCarrierauthFormFieldOptions.carrier.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
      ),
      this.getFormValueFromIdentifier(
        'carrier',
        SOGetCarrier,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}