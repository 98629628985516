import {SORespItem} from '../../core/item/so-resp-item';
import {ItemCore} from './item.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutItemFormFields} from './put-item-form-fields';
import { CEItemAttributes } from './ce-item-attributes'


export class CEItemBase extends CEBase {

    public details: CEItemAttributes;
    public fromFields: PutItemFormFields = new PutItemFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ItemCore,
        public entity: SORespItem,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEItemAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.order)
        this.attributes.push(this.details.tempclin)
        this.attributes.push(this.details.offering)

    }
}