import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostUserFormFields {

    public username: FormField
    public password: FormField
    public givenName: FormField
    public surname: FormField
    public organization: FormField
    public companyName: FormField
    public isExxon: FormField

    constructor() {

        this.username = new FormField(
          'username',
          'Username',
          null,
          FormFieldType.text,
          true,
        )
        this.password = new FormField(
          'password',
          'Password',
          null,
          FormFieldType.text,
          true,
        )
        this.givenName = new FormField(
          'givenName',
          'Given name',
          null,
          FormFieldType.text,
          true,
        )
        this.surname = new FormField(
          'surname',
          'Surname',
          null,
          FormFieldType.text,
          true,
        )
        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.companyName = new FormField(
          'companyName',
          'Company name',
          null,
          FormFieldType.text,
          false,
        )
        this.isExxon = new FormField(
          'isExxon',
          'Is exxon',
          null,
          FormFieldType.boolean,
          false,
        )

    }
}

const fields = new PostUserFormFields()

export const postUserFormFieldOptions: {[key: string]: FormField} = {
  username: fields.username,
  password: fields.password,
  givenName: fields.givenName,
  surname: fields.surname,
  organization: fields.organization,
  companyName: fields.companyName,
  isExxon: fields.isExxon,
};