import {SORespProductionreport} from '../../core/productionreport/so-resp-productionreport';
import {ProductionreportCore} from './productionreport.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutProductionreportFormFields} from './put-productionreport-form-fields';
import {SOGetProductionreport} from '../../core/productionreport/so-get-productionreport';
import {SOGetProduction} from '../../core/production/so-get-production';


export class CEProductionreportAttributesBase {

    ident: DetailEntry
    production: DetailEntry
    timeWindow: DetailEntry
    start: DetailEntry
    end: DetailEntry
    average: DetailEntry
    averageUnits: DetailEntry
    midnightWeight: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespProductionreport,
        app: GasDirectApp,
        formFields: PutProductionreportFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.ident = new DetailEntry(
            'Ident',
            entity.ident,
            'ident',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.ident
        )

        this.timeWindow = new DetailEntry(
            'Time window',
            entity.timeWindow,
            'timeWindow',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.timeWindow
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )

        this.average = new DetailEntry(
            'Average',
            entity.average,
            'average',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.average
        )

        this.averageUnits = new DetailEntry(
            'Average units',
            entity.averageUnits,
            'averageUnits',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.averageUnits
        )

        this.midnightWeight = new DetailEntry(
            'Midnight weight',
            entity.midnightWeight,
            'midnightWeight',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.midnightWeight
        )


        const productionCore = app.makeCore( 'production');
        productionCore.soGet = SOGetProduction.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            productionCore
        )
        productionCore.title = heading ? heading : productionCore.title
        productionCore.listTitle = 'Production'

        productionCore.configureForDisplay(parentEntityViewSpec)

        this.production = new DetailEntry(
          heading ? heading : productionCore.listTitle,
          null,
          'production',
          BaseDetailType.pushedDetail,
          productionCore,
          EditableType.always,
          formFields.production
        )
        this.production.singleFieldSelect = true
    }
}