import {SOPostGrant} from '../../core/grant/so-post-grant';
import {GrantCoreBase} from './grant.core.base';
import {SOGetGrant} from '../../core/grant/so-get-grant';
import {FormField, SelectionOption} from 'lionheartland';
import {postGrantFormFieldOptions} from './post-grant-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {RoleCore} from '../role/role.core';
import {SOGetRole} from '../../core/role/so-get-role';
import {UserCore} from '../user/user.core';
import {SOGetUser} from '../../core/user/so-get-user';

export class RAPostGrant extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postGrantFormFieldOptions);

  RequestClass = SOPostGrant

  constructor(
    public entityCore: GrantCoreBase
  ) {
    super();
    if (postGrantFormFieldOptions.role) {
      postGrantFormFieldOptions.role.makeCore = true;
    }
    if (postGrantFormFieldOptions.user) {
      postGrantFormFieldOptions.user.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'role',
        SOGetRole,
      ),
      this.getFormValueFromIdentifier(
        'user',
        SOGetUser,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}