import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODisttempagreementService} from '../../core/disttempagreement/eo-disttempagreement';
import {SOGetDisttempagreement} from '../../core/disttempagreement/so-get-disttempagreement';
import {RAPutDisttempagreement} from './ra-put-disttempagreement';
import {RAPostDisttempagreement} from './ra-post-disttempagreement';
import {RADeleteDisttempagreement} from './ra-delete-disttempagreement';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEDisttempagreement } from "./ce-disttempagreement"


export class DisttempagreementCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'disttempagreement';
  title = 'Disttempagreement';
  ceProtocol: CEConstructor = CEDisttempagreement

  entityService: EODisttempagreementService;
  soGetClass = SOGetDisttempagreement;
  soGet: SOGetDisttempagreement;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODisttempagreementService
    );
    this.adapters = [
      new RAPostDisttempagreement(this),
      new RAPutDisttempagreement(this),
      new RADeleteDisttempagreement(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDisttempagreement();
    this.soGet.queryOptions.limit = this.limit;
  }


}