import { SOGetAccountreport } from '../accountreport/so-get-accountreport';
import { SOGetLoad } from '../load/so-get-load';


export class SOPutInreport {

  constructor(
    public uid: string,
    public accounreport: SOGetAccountreport = null,
    public load: SOGetLoad = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutInreport(
      'uid' in params ? params.uid : null,
      'accounreport' in params ? params.accounreport : null,
      'load' in params ? params.load : null,
    );
  }
}
