import {SOPutUser} from '../../core/user/so-put-user';
import {UserCoreBase} from './user.core.base';
import {SOGetUser} from '../../core/user/so-get-user';
import {FormField, SelectionOption} from 'lionheartland';
import {putUserFormFieldOptions} from './put-user-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutUser extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putUserFormFieldOptions);

  RequestClass = SOPutUser

  constructor(
    public entityCore: UserCoreBase
  ) {
    super();
    if (putUserFormFieldOptions.organization) {
      putUserFormFieldOptions.organization.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('givenName'),
      this.getFormValueFromIdentifier('surname'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
      ),
      this.getFormValueFromIdentifier('unread'),
      this.getFormValueFromIdentifier('active'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}