import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import { SOGetAccount } from '../account/so-get-account';


export class SOPostInvoice {

  constructor(
    public distributionmonth: SOGetDistributionmonth = null,
    public account: SOGetAccount = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostInvoice(
      'distributionmonth' in params ? params.distributionmonth : null,
      'account' in params ? params.account : null,
    );
  }
}
