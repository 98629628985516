import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutInquiry } from './so-put-inquiry';
import { SOGetInquiry } from './so-get-inquiry';
import { SOPostInquiry } from './so-post-inquiry';
import { SODeleteInquiry } from './so-delete-inquiry';
import {SORespInquiry} from './so-resp-inquiry';
import {SOPluralInquiry} from './so-plural-inquiry';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOInquiryService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'inquiry',
      entityCore
    );
  }

  /** GET Inquiry by id. Will 404 if id not found */
  get(request: SOGetInquiry): Observable<SOPluralInquiry> {
    return this.httpClient.get<SOPluralInquiry>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralInquiry, resp) }),
      tap(_ => this.log(`fetched Inquiry`)),
      catchError(this.handleError<SOPluralInquiry>(`get Inquiry`))
    );
  }

  /** POST: add a new Inquiry to the server */
  post(request: SOPostInquiry): Observable<SORespInquiry> {
    return this.httpClient.post<SORespInquiry>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInquiry, resp) }),
      tap((response: SORespInquiry) => this.log(`added Inquiry w/ id=${response.uid}`)),
      catchError(this.handleError<SORespInquiry>('Inquiry post'))
    );
  }

  /** PUT: update Inquiry  */
  put(request: SOPutInquiry): Observable<SORespInquiry> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespInquiry>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInquiry, resp) }),
      tap((response: SORespInquiry) => this.log(`edited Inquiry w/ uid=${uid}`)),
      catchError(this.handleError<SORespInquiry>('Inquiry put'))
    );
  }

  /** DELETE: delete the Inquiry from the server */
  delete(request: SODeleteInquiry): Observable<SORespInquiry> {
    return this.httpClient.delete<SORespInquiry>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Inquiry uid=${request.uid}`)),
      catchError(this.handleError<SORespInquiry>('delete Inquiry'))
    );
  }
}
