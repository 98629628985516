import { Type } from 'class-transformer';
import { SORespRole } from '../role/so-resp-role';
import { SORespUser } from '../user/so-resp-user';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespGrant implements ObjectiveProtocol {

  entityType: string = 'grant';
  uid: string;
  isDefault: boolean = null;
  @Type(() => SORespRole)
  role: SORespRole;
  @Type(() => SORespUser)
  user: SORespUser;
  userDisplayName: string = "";
}