import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetTempagreement } from '../tempagreement/so-get-tempagreement';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetDisttempagreement implements QueryProtocol {

  constructor(
    public uid: string = null,
    public distribution: SOGetDistribution = null,
    public tempagreement: SOGetTempagreement = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetDisttempagreement(
      'uid' in params ? params.uid : null,
      'distribution' in params ? params.distribution : null,
      'tempagreement' in params ? params.tempagreement : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
