import { SOGetAccount } from '../account/so-get-account';
import { SOGetDistributionreport } from '../distributionreport/so-get-distributionreport';
import { SOGetDistributionday } from '../distributionday/so-get-distributionday';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import { SOGetDistributionyear } from '../distributionyear/so-get-distributionyear';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetAccountreport implements QueryProtocol {

  constructor(
    public uid: string = null,
    public ident: string = null,
    public account: SOGetAccount = null,
    public timeWindow: string = null,
    public distributionreport: SOGetDistributionreport = null,
    public distributionday: SOGetDistributionday = null,
    public distributionmonth: SOGetDistributionmonth = null,
    public distributionyear: SOGetDistributionyear = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetAccountreport(
      'uid' in params ? params.uid : null,
      'ident' in params ? params.ident : null,
      'account' in params ? params.account : null,
      'timeWindow' in params ? params.timeWindow : null,
      'distributionreport' in params ? params.distributionreport : null,
      'distributionday' in params ? params.distributionday : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'distributionyear' in params ? params.distributionyear : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
