import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostRoleFormFields {

    public name: FormField
    public identifier: FormField
    public plant: FormField
    public distribution: FormField
    public account: FormField
    public carrier: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          true,
        )
        this.plant = new FormField(
          'plant',
          'Plant',
          'plant',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.carrier = new FormField(
          'carrier',
          'Carrier',
          'carrier',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostRoleFormFields()

export const postRoleFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  identifier: fields.identifier,
  plant: fields.plant,
  account: fields.account,
  carrier: fields.carrier,
};