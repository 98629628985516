import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostContractFormFields {

    public name: FormField
    public description: FormField
    public takeOrPay: FormField
    public minLoadSizeToQualify: FormField
    public docusignLink: FormField
    public state: FormField
    public type: FormField
    public start: FormField
    public end: FormField
    public agreement: FormField
    public temptract: FormField
    public loadType: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.takeOrPay = new FormField(
          'takeOrPay',
          'Take or pay',
          null,
          FormFieldType.text,
          true,
        )
        this.minLoadSizeToQualify = new FormField(
          'minLoadSizeToQualify',
          'Min load size to qualify',
          null,
          FormFieldType.text,
          true,
        )
        this.docusignLink = new FormField(
          'docusignLink',
          'Docusign link',
          null,
          FormFieldType.text,
          true,
        )
        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          true,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          true,
        )
        this.agreement = new FormField(
          'agreement',
          'Agreement',
          'agreement',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.temptract = new FormField(
          'temptract',
          'Temptract',
          'temptract',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.loadType = new FormField(
          'loadType',
          'Load type',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PostContractFormFields()

export const postContractFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  description: fields.description,
  takeOrPay: fields.takeOrPay,
  minLoadSizeToQualify: fields.minLoadSizeToQualify,
  docusignLink: fields.docusignLink,
  state: fields.state,
  type: fields.type,
  start: fields.start,
  end: fields.end,
  agreement: fields.agreement,
  temptract: fields.temptract,
  loadType: fields.loadType,
};