import {SOPutDowntime} from '../../core/downtime/so-put-downtime';
import {DowntimeCoreBase} from './downtime.core.base';
import {SOGetDowntime} from '../../core/downtime/so-get-downtime';
import {FormField, SelectionOption} from 'lionheartland';
import {putDowntimeFormFieldOptions} from './put-downtime-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutDowntime extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putDowntimeFormFieldOptions);

  RequestClass = SOPutDowntime

  constructor(
    public entityCore: DowntimeCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}