import { SOGetOffering } from '../offering/so-get-offering';
import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostDistoffer {

  constructor(
    public offering: SOGetOffering,
    public distribution: SOGetDistribution,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDistoffer(
      'offering' in params ? params.offering : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
