import {SOPostSlot} from '../../core/slot/so-post-slot';
import {SlotCoreBase} from './slot.core.base';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {FormField, SelectionOption} from 'lionheartland';
import {postSlotFormFieldOptions} from './post-slot-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {TempclinCore} from '../tempclin/tempclin.core';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPostSlot extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSlotFormFieldOptions);

  RequestClass = SOPostSlot

  constructor(
    public entityCore: SlotCoreBase
  ) {
    super();
    if (postSlotFormFieldOptions.tempclin) {
      postSlotFormFieldOptions.tempclin.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
        true,
      ),
      this.getFormValueFromIdentifier('slotNumber'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier(
        'tempclin',
        SOGetTempclin,
      ),
      this.getFormValueFromIdentifier('loadType'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}