import {SOPutSale} from '../../core/sale/so-put-sale';
import {SaleCoreBase} from './sale.core.base';
import {SOGetSale} from '../../core/sale/so-get-sale';
import {FormField, SelectionOption} from 'lionheartland';
import {putSaleFormFieldOptions} from './put-sale-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutSale extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putSaleFormFieldOptions);

  RequestClass = SOPutSale

  constructor(
    public entityCore: SaleCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}