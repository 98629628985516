import {SOPostTempclin} from '../../core/tempclin/so-post-tempclin';
import {TempclinCoreBase} from './tempclin.core.base';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {FormField, SelectionOption} from 'lionheartland';
import {postTempclinFormFieldOptions} from './post-tempclin-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';

export class RAPostTempclin extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTempclinFormFieldOptions);

  RequestClass = SOPostTempclin

  constructor(
    public entityCore: TempclinCoreBase
  ) {
    super();
    if (postTempclinFormFieldOptions.distribution) {
      postTempclinFormFieldOptions.distribution.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('durationDays'),
      this.getFormValueFromIdentifier('offsetDays'),
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier('loadQuantity'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}