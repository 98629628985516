import { Type } from 'class-transformer';
import { SORespContainersession } from '../containersession/so-resp-containersession';
import { SORespLoadfee } from '../loadfee/so-resp-loadfee';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDelta implements ObjectiveProtocol {

  entityType: string = 'delta';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  type: string;
  quantity: number = null;
  @Type(() => SORespContainersession)
  containersession: SORespContainersession = null;
  billable: boolean;
  @Type(() => SORespLoadfee)
  loadfee: SORespLoadfee = null;
}