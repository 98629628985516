import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutHestatusitemFormFields {

    public state: FormField
    public location: FormField
    public loadStatus: FormField
    public gone: FormField
    public n2Level: FormField
    public n2Pressure: FormField
    public heTemp: FormField
    public heLevel: FormField
    public hePressure: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )
        this.location = new FormField(
          'location',
          'Location',
          null,
          FormFieldType.text,
          false,
        )
        this.loadStatus = new FormField(
          'loadStatus',
          'Load status',
          null,
          FormFieldType.text,
          false,
        )
        this.gone = new FormField(
          'gone',
          'Gone',
          null,
          FormFieldType.boolean,
          false,
        )
        this.n2Level = new FormField(
          'n2Level',
          'N2 level',
          null,
          FormFieldType.text,
          false,
        )
        this.n2Pressure = new FormField(
          'n2Pressure',
          'N2 pressure',
          null,
          FormFieldType.text,
          false,
        )
        this.heTemp = new FormField(
          'heTemp',
          'He temp',
          null,
          FormFieldType.text,
          false,
        )
        this.heLevel = new FormField(
          'heLevel',
          'He level',
          null,
          FormFieldType.text,
          false,
        )
        this.hePressure = new FormField(
          'hePressure',
          'He pressure',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutHestatusitemFormFields()

export const putHestatusitemFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  location: fields.location,
  loadStatus: fields.loadStatus,
  gone: fields.gone,
  n2Level: fields.n2Level,
  n2Pressure: fields.n2Pressure,
  heTemp: fields.heTemp,
  heLevel: fields.heLevel,
  hePressure: fields.hePressure,
};