import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostNavroaFormFields {

    public index: FormField
    public mainNav: FormField
    public role: FormField
    public navi: FormField

    constructor() {

        this.index = new FormField(
          'index',
          'Index',
          null,
          FormFieldType.text,
          false,
        )
        this.mainNav = new FormField(
          'mainNav',
          'Main nav',
          null,
          FormFieldType.boolean,
          false,
        )
        this.role = new FormField(
          'role',
          'Role',
          'role',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.navi = new FormField(
          'navi',
          'Navi',
          'navi',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostNavroaFormFields()

export const postNavroaFormFieldOptions: {[key: string]: FormField} = {
  index: fields.index,
  mainNav: fields.mainNav,
  role: fields.role,
  navi: fields.navi,
};