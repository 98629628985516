import { Type } from 'class-transformer';
import { SORespAccount } from '../account/so-resp-account';
import { SORespTempclin } from '../tempclin/so-resp-tempclin';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespSlotlet implements ObjectiveProtocol {

  entityType: string = 'slotlet';
  uid: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  status: string;
  dayOffset: number;
  slotNumber: number;
  @Type(() => SORespAccount)
  account: SORespAccount = null;
  @Type(() => SORespTempclin)
  tempclin: SORespTempclin = null;
}