import {SOPostCustomer} from '../../core/customer/so-post-customer';
import {CustomerCoreBase} from './customer.core.base';
import {SOGetCustomer} from '../../core/customer/so-get-customer';
import {FormField, SelectionOption} from 'lionheartland';
import {postCustomerFormFieldOptions} from './post-customer-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {InquiryCore} from '../inquiry/inquiry.core';
import {SOGetInquiry} from '../../core/inquiry/so-get-inquiry';

export class RAPostCustomer extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postCustomerFormFieldOptions);

  RequestClass = SOPostCustomer

  constructor(
    public entityCore: CustomerCoreBase
  ) {
    super();
    if (postCustomerFormFieldOptions.inquiry) {
      postCustomerFormFieldOptions.inquiry.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('companyName'),
      this.getFormValueFromIdentifier('emailDomain'),
      this.getFormValueFromIdentifier(
        'inquiry',
        SOGetInquiry,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}