import { SOGetClin } from '../clin/so-get-clin';
import { SOGetAgreeyear } from '../agreeyear/so-get-agreeyear';
import { SOGetBid } from '../bid/so-get-bid';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetPrice implements QueryProtocol {

  constructor(
    public uid: string = null,
    public clin: SOGetClin = null,
    public agreeyear: SOGetAgreeyear = null,
    public bid: SOGetBid = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetPrice(
      'uid' in params ? params.uid : null,
      'clin' in params ? params.clin : null,
      'agreeyear' in params ? params.agreeyear : null,
      'bid' in params ? params.bid : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
