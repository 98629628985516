<div fxLayout="row" class="route">
    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
    <app-gas-direct-entity-view *ngFor="let entityViewSpec of visibleEntityViewSpecs"
                        [entityViewSpec]="entityViewSpec"
                        [parentRouteView]="this"
                        class="core"
                        style="width:{{width}}%">

    </app-gas-direct-entity-view>
</div>
