import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostInreportFormFields {

    public accounreport: FormField
    public load: FormField

    constructor() {

        this.accounreport = new FormField(
          'accounreport',
          'Accounreport',
          'accountreport',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          true,
        )

    }
}

const fields = new PostInreportFormFields()

export const postInreportFormFieldOptions: {[key: string]: FormField} = {
  accounreport: fields.accounreport,
  load: fields.load,
};