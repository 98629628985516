import {SORespAccount} from '../../core/account/so-resp-account';
import {AccountCore} from './account.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutAccountFormFields} from './put-account-form-fields';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetCustomer} from '../../core/customer/so-get-customer';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetTransaction} from '../../core/transaction/so-get-transaction';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {SOGetReservation} from '../../core/reservation/so-get-reservation';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetTrailerauth} from '../../core/trailerauth/so-get-trailerauth';
import {SOGetCarrierauth} from '../../core/carrierauth/so-get-carrierauth';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetSaleaccountasc} from '../../core/saleaccountasc/so-get-saleaccountasc';
import {SOGetFeatureasc} from '../../core/featureasc/so-get-featureasc';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';


export class CEAccountAttributesBase {

    activeAgreement: DetailEntry
    balance: DetailEntry
    availableBalance: DetailEntry
    customer: DetailEntry
    distribution: DetailEntry
    scheduledLoads: DetailEntry
    unscheduledLoads: DetailEntry
    confirmedLoads: DetailEntry
    creditLimit: DetailEntry
    salesTaxState: DetailEntry
    interruptibleExpiration: DetailEntry
    averageLoadSize: DetailEntry
    balanceMatchThreshold: DetailEntry
    transactions: DetailEntry
    orders: DetailEntry
    slots: DetailEntry
    reservations: DetailEntry
    loads: DetailEntry
    agreements: DetailEntry
    hyears: DetailEntry
    hmonths: DetailEntry
    roles: DetailEntry
    trailerauths: DetailEntry
    carrierauths: DetailEntry
    bids: DetailEntry
    containersessions: DetailEntry
    saleaccountascs: DetailEntry
    featureascs: DetailEntry
    invoices: DetailEntry
    publications: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespAccount,
        app: GasDirectApp,
        formFields: PutAccountFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.activeAgreement = new DetailEntry(
            'Active agreement',
            entity.activeAgreement,
            'activeAgreement',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )

        this.balance = new DetailEntry(
            'Balance',
            app.formatAsString.currency(
                entity.balance,
                2,
                2,
            ),
            'balance',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.balance.listSubheadingIndex = 0;

        this.availableBalance = new DetailEntry(
            'Available balance',
            app.formatAsString.currency(
                entity.availableBalance,
                2,
                2,
            ),
            'availableBalance',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.scheduledLoads = new DetailEntry(
            'Scheduled loads',
            entity.scheduledLoads,
            'scheduledLoads',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.unscheduledLoads = new DetailEntry(
            'Unscheduled loads',
            entity.unscheduledLoads,
            'unscheduledLoads',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.confirmedLoads = new DetailEntry(
            'Confirmed loads',
            entity.confirmedLoads,
            'confirmedLoads',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.creditLimit = new DetailEntry(
            'Credit limit',
            entity.creditLimit,
            'creditLimit',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.salesTaxState = new DetailEntry(
            'Sales tax state',
            entity.salesTaxState,
            'salesTaxState',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.salesTaxState
        )

        this.interruptibleExpiration = new DetailEntry(
            'Interruptible expiration',
            app.activeUserService.time.dateAsString(entity.interruptibleExpiration),
            'interruptibleExpiration',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.interruptibleExpiration
        )

        this.averageLoadSize = new DetailEntry(
            'Average load size',
            entity.averageLoadSize,
            'averageLoadSize',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.averageLoadSize
        )

        this.balanceMatchThreshold = new DetailEntry(
            'Balance match threshold',
            entity.balanceMatchThreshold,
            'balanceMatchThreshold',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.balanceMatchThreshold
        )


        const customerCore = app.makeCore( 'customer');
        customerCore.soGet = SOGetCustomer.construct({
        });
        let customerCeProtocol;
        if (entity.customer) {
            customerCeProtocol = createCEProtocol(
                customerCore.ceProtocol,
                parentEntityViewSpec,
                customerCore,
                entity.customer,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            customerCore
        )
        customerCore.title = heading ? heading : customerCore.title
        customerCore.listTitle = 'Customer'

        if (entity.customer) {
            displayStringFactory.reconfigure(
                entity.customer,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        customerCore.configureForDisplay(parentEntityViewSpec)

        this.customer = new DetailEntry(
          heading ? heading : customerCore.listTitle,
          customerCeProtocol ? customerCeProtocol.entityListView.fullHeading : null,
          'customer',
          BaseDetailType.pushedDetail,
          customerCore,
          EditableType.never
        )
        if (entity.customer) {
          this.customer.ceProtocol = customerCeProtocol;
          this.customer.cachedListEntry = customerCore.makeListEntry(parentEntityViewSpec, entity.customer, 0)
          customerCore.selectedEntity = entity.customer;
        }
        this.customer.singleFieldSelect = true
        this.customer.listHeadingIndex = 0;

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        this.distribution.singleFieldSelect = true

        const transactionsCore = app.makeCore( 'transaction');
        transactionsCore.soGet = SOGetTransaction.construct({
          account: new SOGetAccount(entity.uid),
        });
        transactionsCore.soGet.queryOptions.sortKey = 'dateCreated';
        transactionsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            transactionsCore
        )
        transactionsCore.title = heading ? heading : transactionsCore.title
        transactionsCore.listTitle = 'Transactions'

        transactionsCore.configureForDisplay(parentEntityViewSpec)

        this.transactions = new DetailEntry(
          heading ? heading : transactionsCore.listTitle,
          null,
          'transactions',
          BaseDetailType.pushedList,
          transactionsCore,
          EditableType.never
        )

        const ordersCore = app.makeCore( 'order');
        ordersCore.soGet = SOGetOrder.construct({
          account: new SOGetAccount(entity.uid),
          agreement: app.getScope('agreement') ? new SOGetAgreement(app.getScope('agreement').uid) : null,
          agreemonth: app.getScope('agreemonth') ? new SOGetAgreemonth(app.getScope('agreemonth').uid) : null,
          distributionmonth: app.getScope('distributionmonth') ? new SOGetDistributionmonth(app.getScope('distributionmonth').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            ordersCore
        )
        ordersCore.title = heading ? heading : ordersCore.title
        ordersCore.listTitle = 'Orders'

        ordersCore.configureForDisplay(parentEntityViewSpec)

        this.orders = new DetailEntry(
          heading ? heading : ordersCore.listTitle,
          null,
          'orders',
          BaseDetailType.pushedList,
          ordersCore,
          EditableType.never
        )

        const slotsCore = app.makeCore( 'slot');
        slotsCore.soGet = SOGetSlot.construct({
          account: new SOGetAccount(entity.uid),
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        slotsCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotsCore
        )
        slotsCore.title = heading ? heading : slotsCore.title
        slotsCore.listTitle = 'Slots'

        slotsCore.configureForDisplay(parentEntityViewSpec)

        this.slots = new DetailEntry(
          heading ? heading : slotsCore.listTitle,
          null,
          'slots',
          BaseDetailType.pushedList,
          slotsCore,
          EditableType.never
        )

        const reservationsCore = app.makeCore( 'reservation');
        reservationsCore.soGet = SOGetReservation.construct({
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            reservationsCore
        )
        reservationsCore.title = heading ? heading : reservationsCore.title
        reservationsCore.listTitle = 'Reservations'

        reservationsCore.configureForDisplay(parentEntityViewSpec)

        this.reservations = new DetailEntry(
          heading ? heading : reservationsCore.listTitle,
          null,
          'reservations',
          BaseDetailType.pushedList,
          reservationsCore,
          EditableType.never
        )

        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: new SOGetAccount(entity.uid),
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.pushedList,
          loadsCore,
          EditableType.never
        )

        const agreementsCore = app.makeCore( 'agreement');
        agreementsCore.soGet = SOGetAgreement.construct({
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementsCore
        )
        agreementsCore.title = heading ? heading : agreementsCore.title
        agreementsCore.listTitle = 'Agreements'

        agreementsCore.configureForDisplay(parentEntityViewSpec)

        this.agreements = new DetailEntry(
          heading ? heading : agreementsCore.listTitle,
          null,
          'agreements',
          BaseDetailType.pushedList,
          agreementsCore,
          EditableType.never
        )

        const hyearsCore = app.makeCore( 'hyear');
        hyearsCore.soGet = SOGetHyear.construct({
          account: new SOGetAccount(entity.uid),
        });
        hyearsCore.soGet.queryOptions.sortKey = 'year';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hyearsCore
        )
        hyearsCore.title = heading ? heading : hyearsCore.title
        hyearsCore.listTitle = 'Hyears'

        hyearsCore.configureForDisplay(parentEntityViewSpec)

        this.hyears = new DetailEntry(
          heading ? heading : hyearsCore.listTitle,
          null,
          'hyears',
          BaseDetailType.pushedList,
          hyearsCore,
          EditableType.never
        )

        const hmonthsCore = app.makeCore( 'hmonth');
        hmonthsCore.soGet = SOGetHmonth.construct({
          account: new SOGetAccount(entity.uid),
        });
        hmonthsCore.soGet.queryOptions.sortKey = 'month';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hmonthsCore
        )
        hmonthsCore.title = heading ? heading : hmonthsCore.title
        hmonthsCore.listTitle = 'Hmonths'

        hmonthsCore.configureForDisplay(parentEntityViewSpec)

        this.hmonths = new DetailEntry(
          heading ? heading : hmonthsCore.listTitle,
          null,
          'hmonths',
          BaseDetailType.pushedList,
          hmonthsCore,
          EditableType.never
        )

        const rolesCore = app.makeCore( 'role');
        rolesCore.soGet = SOGetRole.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            rolesCore
        )
        rolesCore.title = heading ? heading : rolesCore.title
        rolesCore.listTitle = 'Roles'

        rolesCore.configureForDisplay(parentEntityViewSpec)

        this.roles = new DetailEntry(
          heading ? heading : rolesCore.listTitle,
          null,
          'roles',
          BaseDetailType.pushedList,
          rolesCore,
          EditableType.never
        )

        const trailerauthsCore = app.makeCore( 'trailerauth');
        trailerauthsCore.soGet = SOGetTrailerauth.construct({
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            trailerauthsCore
        )
        trailerauthsCore.title = heading ? heading : trailerauthsCore.title
        trailerauthsCore.listTitle = 'Trailerauths'

        trailerauthsCore.configureForDisplay(parentEntityViewSpec)

        this.trailerauths = new DetailEntry(
          heading ? heading : trailerauthsCore.listTitle,
          null,
          'trailerauths',
          BaseDetailType.pushedList,
          trailerauthsCore,
          EditableType.never
        )

        const carrierauthsCore = app.makeCore( 'carrierauth');
        carrierauthsCore.soGet = SOGetCarrierauth.construct({
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            carrierauthsCore
        )
        carrierauthsCore.title = heading ? heading : carrierauthsCore.title
        carrierauthsCore.listTitle = 'Carrierauths'

        carrierauthsCore.configureForDisplay(parentEntityViewSpec)

        this.carrierauths = new DetailEntry(
          heading ? heading : carrierauthsCore.listTitle,
          null,
          'carrierauths',
          BaseDetailType.pushedList,
          carrierauthsCore,
          EditableType.never
        )

        const bidsCore = app.makeCore( 'bid');
        bidsCore.soGet = SOGetBid.construct({
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            bidsCore
        )
        bidsCore.title = heading ? heading : bidsCore.title
        bidsCore.listTitle = 'Bids'

        bidsCore.configureForDisplay(parentEntityViewSpec)

        this.bids = new DetailEntry(
          heading ? heading : bidsCore.listTitle,
          null,
          'bids',
          BaseDetailType.pushedList,
          bidsCore,
          EditableType.never
        )
        this.bids.limitViewToScopeMatch = [
            new ScopeMatch(
            'helium',
            false
            ),
        ];

        const containersessionsCore = app.makeCore( 'containersession');
        containersessionsCore.soGet = SOGetContainersession.construct({
          account: new SOGetAccount(entity.uid),
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionsCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionsCore
        )
        containersessionsCore.title = heading ? heading : containersessionsCore.title
        containersessionsCore.listTitle = 'Containersessions'

        containersessionsCore.configureForDisplay(parentEntityViewSpec)

        this.containersessions = new DetailEntry(
          heading ? heading : containersessionsCore.listTitle,
          null,
          'containersessions',
          BaseDetailType.pushedList,
          containersessionsCore,
          EditableType.never
        )

        const saleaccountascsCore = app.makeCore( 'saleaccountasc');
        saleaccountascsCore.soGet = SOGetSaleaccountasc.construct({
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            saleaccountascsCore
        )
        saleaccountascsCore.title = heading ? heading : saleaccountascsCore.title
        saleaccountascsCore.listTitle = 'Saleaccountascs'

        saleaccountascsCore.configureForDisplay(parentEntityViewSpec)

        this.saleaccountascs = new DetailEntry(
          heading ? heading : saleaccountascsCore.listTitle,
          null,
          'saleaccountascs',
          BaseDetailType.pushedList,
          saleaccountascsCore,
          EditableType.never
        )

        const featureascsCore = app.makeCore( 'featureasc');
        featureascsCore.soGet = SOGetFeatureasc.construct({
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            featureascsCore
        )
        featureascsCore.title = heading ? heading : featureascsCore.title
        featureascsCore.listTitle = 'Featureascs'

        featureascsCore.configureForDisplay(parentEntityViewSpec)

        this.featureascs = new DetailEntry(
          heading ? heading : featureascsCore.listTitle,
          null,
          'featureascs',
          BaseDetailType.pushedList,
          featureascsCore,
          EditableType.never
        )

        const invoicesCore = app.makeCore( 'invoice');
        invoicesCore.soGet = SOGetInvoice.construct({
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoicesCore
        )
        invoicesCore.title = heading ? heading : invoicesCore.title
        invoicesCore.listTitle = 'Invoices'

        invoicesCore.configureForDisplay(parentEntityViewSpec)

        this.invoices = new DetailEntry(
          heading ? heading : invoicesCore.listTitle,
          null,
          'invoices',
          BaseDetailType.pushedList,
          invoicesCore,
          EditableType.never
        )
        this.invoices.limitViewToRoles = [
            'scheduler',
        ];

        const publicationsCore = app.makeCore( 'publication');
        publicationsCore.soGet = SOGetPublication.construct({
          account: new SOGetAccount(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            publicationsCore
        )
        publicationsCore.title = heading ? heading : publicationsCore.title
        publicationsCore.listTitle = 'Publications'

        publicationsCore.configureForDisplay(parentEntityViewSpec)

        this.publications = new DetailEntry(
          heading ? heading : publicationsCore.listTitle,
          null,
          'publications',
          BaseDetailType.pushedList,
          publicationsCore,
          EditableType.never
        )
    }
}