import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespFaq implements ObjectiveProtocol {

  entityType: string = 'faq';
  uid: string;
  index: number;
  title: string;
  body: string;
}