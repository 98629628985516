import { SlotCoreBase } from "./slot.core.base"
import {
    ActiveUserService,
    CalendarSpec, EntityViewSpecProtocol, MenuItem, MenuItemType, SelectionOption,
    SvNavigatorComponent
} from "lionheartland";
import {SOGetAccount} from "../../core/account/so-get-account";
import {FormControl, FormGroup} from "@angular/forms";
import {GasDirectApp} from "../../land-app";
import {AccountCore} from "../account/account.core";
import {SOGetDistribution} from "../../core/distribution/so-get-distribution";
import {SlotCore} from "./slot.core";


export class SlotCalendarCore extends SlotCore {
    calendar: boolean = true;

    navTitle = 'Calendar';

    selectedAccountForm: FormGroup;
    startFrom: FormGroup;
    endForm: FormGroup;

    get app(): GasDirectApp {
        return this.activeUserService.app as GasDirectApp;
    }
    //
    // constructor(
    //     public activeUserService: ActiveUserService,
    // ) {
    //     super(
    //         activeUserService
    //     );
    //
    //     this.startFrom = new FormGroup({
    //             selection: new FormControl()
    //         }
    //     );
    //     this.endForm = new FormGroup({
    //             selection: new FormControl()
    //         }
    //     );
    //
    //     const start = new MenuItem('start', 'Start date', this, MenuItemType.date);
    //     start.selectionForm = this.startFrom;
    //     const end = new MenuItem('end', 'End date', this, MenuItemType.date);
    //     end.selectionForm = this.endForm;
    //
    //     this.menuItems = [
    //         // new MenuItem('placed', 'Placed', MenuItemType.multiselect, true),
    //         // new MenuItem('paid', 'Paid', MenuItemType.multiselect, true),
    //         // new MenuItem('excel', 'Export to excel', MenuItemType.excel),
    //     ];
    //
    //
    //     for (const item of this.menuItems) {
    //         if (item.value) {
    //             this.selectedStatusOptions.push(item);
    //         }
    //     }
    //
    //     this.menuItems.push(
    //         start,
    //         end
    //     );
    //
    // }

    startingEntityViewSpec(avRoute: SvNavigatorComponent): CalendarSpec {
        console.log('In slot core generating new core spec');
        return new CalendarSpec(
            this,
            null,
            null,
            avRoute
        );
    }

    menuClick(item: MenuItem, coreSpec: EntityViewSpecProtocol) {
        if (item.identifier === 'account') {
            this.soGet.account = item.selectionForm.value.selection ? new SOGetAccount(item.selectionForm.value.selection) : null;
            this.entities = null;
            coreSpec.listBody.reset();
            this.fetchList(coreSpec);
        } else if (item.identifier === 'start') {
            this.soGet.queryOptions.datetimeStart = item.selectionForm.value.selection ? this.activeUserService.time.appSetTimeZoneUtcDateFromWebBrowserInput(item.selectionForm.value.selection) : null;
            this.entities = null;
            coreSpec.listBody.reset();
            this.fetchList(coreSpec);
        } else if (item.identifier === 'end') {
            this.soGet.queryOptions.datetimeEnd = item.selectionForm.value.selection ? this.activeUserService.time.appSetTimeZoneUtcDateFromWebBrowserInput(item.selectionForm.value.selection) : null;
            this.entities = null;
            coreSpec.listBody.reset();
            this.fetchList(coreSpec);
        } else {
            super.menuClick(item, coreSpec);
        }
    }

}