import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSlotService} from '../../core/slot/eo-slot';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {RAPutSlot} from './ra-put-slot';
import {RAPostSlot} from './ra-post-slot';
import {RADeleteSlot} from './ra-delete-slot';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import {SOGetDistribution} from '../../core/distribution/so-get-distribution'
import { CESlot } from "./ce-slot"


export class SlotCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'slot';
  title = 'Slot';
  ceProtocol: CEConstructor = CESlot

  entityService: EOSlotService;
  soGetClass = SOGetSlot;
  soGet: SOGetSlot;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSlotService
    );
    this.adapters = [
      new RAPostSlot(this),
      new RAPutSlot(this),
      new RADeleteSlot(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSlot();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    if (this.activeUserService.getScope('distribution')) {
        this.soGet.distribution = new SOGetDistribution(
          this.activeUserService.getScope('distribution').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'start';
  }


}