import {SOPostCarrier} from '../../core/carrier/so-post-carrier';
import {CarrierCoreBase} from './carrier.core.base';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';
import {FormField, SelectionOption} from 'lionheartland';
import {postCarrierFormFieldOptions} from './post-carrier-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostCarrier extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postCarrierFormFieldOptions);

  RequestClass = SOPostCarrier

  constructor(
    public entityCore: CarrierCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('companyName'),
      this.getFormValueFromIdentifier('emailDomain'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}