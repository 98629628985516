import {SORespFeature} from '../../core/feature/so-resp-feature';
import {FeatureCore} from './feature.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeatureFormFields} from './put-feature-form-fields';
import {SOGetFeature} from '../../core/feature/so-get-feature';
import {SOGetFeatureasc} from '../../core/featureasc/so-get-featureasc';


export class CEFeatureAttributesBase {

    dateCreated: DetailEntry
    name: DetailEntry
    component: DetailEntry
    description: DetailEntry
    status: DetailEntry
    featureascs: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespFeature,
        app: GasDirectApp,
        formFields: PutFeatureFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.component = new DetailEntry(
            'Component',
            entity.component,
            'component',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.component
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.status
        )


        const featureascsCore = app.makeCore( 'featureasc');
        featureascsCore.soGet = SOGetFeatureasc.construct({
          feature: new SOGetFeature(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            featureascsCore
        )
        featureascsCore.title = heading ? heading : featureascsCore.title
        featureascsCore.listTitle = 'Featureascs'

        featureascsCore.configureForDisplay(parentEntityViewSpec)

        this.featureascs = new DetailEntry(
          heading ? heading : featureascsCore.listTitle,
          null,
          'featureascs',
          BaseDetailType.embeddedList,
          featureascsCore,
          EditableType.never
        )
    }
}