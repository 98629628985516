import { Type } from 'class-transformer';
import { SORespTemptraclin } from '../temptraclin/so-resp-temptraclin';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTemptract implements ObjectiveProtocol {

  entityType: string = 'temptract';
  uid: string;
  name: string;
  durationDays: number = 365;
  takeOrPay: number;
  minLoadSizeToQualify: number;
  quantity: number = 0;
  loadQuantity: number = 0;
  type: string;
  docusignLink: string;
  description: string = "";
  @Type(() => SORespTemptraclin)
  temptraclins: SORespTemptraclin[] = [];
}