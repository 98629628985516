import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostLoadFormFields {

    public order: FormField

    constructor() {

        this.order = new FormField(
          'order',
          'Order',
          'order',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostLoadFormFields()

export const postLoadFormFieldOptions: {[key: string]: FormField} = {
  order: fields.order,
};