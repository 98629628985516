import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutActivity } from './so-put-activity';
import { SOGetActivity } from './so-get-activity';
import { SOPostActivity } from './so-post-activity';
import { SODeleteActivity } from './so-delete-activity';
import {SORespActivity} from './so-resp-activity';
import {SOPluralActivity} from './so-plural-activity';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOActivityService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'activity',
      entityCore
    );
  }

  /** GET Activity by id. Will 404 if id not found */
  get(request: SOGetActivity): Observable<SOPluralActivity> {
    return this.httpClient.get<SOPluralActivity>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralActivity, resp) }),
      tap(_ => this.log(`fetched Activity`)),
      catchError(this.handleError<SOPluralActivity>(`get Activity`))
    );
  }

  /** POST: add a new Activity to the server */
  post(request: SOPostActivity): Observable<SORespActivity> {
    return this.httpClient.post<SORespActivity>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespActivity, resp) }),
      tap((response: SORespActivity) => this.log(`added Activity w/ id=${response.uid}`)),
      catchError(this.handleError<SORespActivity>('Activity post'))
    );
  }

  /** PUT: update Activity  */
  put(request: SOPutActivity): Observable<SORespActivity> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespActivity>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespActivity, resp) }),
      tap((response: SORespActivity) => this.log(`edited Activity w/ uid=${uid}`)),
      catchError(this.handleError<SORespActivity>('Activity put'))
    );
  }

  /** DELETE: delete the Activity from the server */
  delete(request: SODeleteActivity): Observable<SORespActivity> {
    return this.httpClient.delete<SORespActivity>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Activity uid=${request.uid}`)),
      catchError(this.handleError<SORespActivity>('delete Activity'))
    );
  }
}
