import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SORespAccount} from '../../core/account/so-resp-account';
import {AvCalendarComponent, getIndexOfDate} from '../av-calendar/av-calendar.component';
import {CEProtocol, EntityViewSpec, ListEntry, PostSpec} from "lionheartland";
import {ContainersessionCore} from "../../entities/containersession/containersession.core";


@Component({
    selector: 'app-av-board',
    templateUrl: './av-board.component.html',
    styleUrls: ['./av-board.component.css']
})
export class AvBoardComponent extends AvCalendarComponent implements OnInit {

    entities = [];
    daySections = [];
    soakers: ListEntry[] = [];

    soakBackgroundColor = '#FFE9EC';
    soakDueBackgroundColor = '#E3F2FC';
    startDate: Date;
    loadsPastDue: ListEntry[] = [];

    refreshSet = false;

    get accounts(): SORespAccount[] {
        const accounts = [];
        for (const entity of this.entities) {
            const index = accounts.findIndex(obj => obj.uid === entity.account.uid);
            if (index === -1) {
                accounts.push(entity.account);
            }
        }

        accounts.sort((n1, n2) => {
            if (n1.customer.companyName < n2.customer.companyName) {
                return -1;
            }
            if (n1.customer.companyName > n2.customer.companyName) {
                return 1;
            }
            return 0;
        });

        return accounts;
    }


    get showSoak() {
        return this.app.isHelium && this.soak;
    }

    get showCal() {
        return !(this.activeUserService.isHandset && this.showSoak);
    }

    init() {
        super.init();
        // this.containersessionCore.board = true;
        if (!this.activeUserService.isHandset) {
            this.soak = true;
        }
    }


    listFetched(entities, entityViewSpec: EntityViewSpec, hasMore) {
        super.listFetched(entities, entityViewSpec, hasMore);
        if (!this.refreshSet) {
            this.refreshSet = true;
            let isBoard = false;

            // if (this.navigator.entityViewSpecs[this.navigator.entityViewSpecs.length - 1].entityCore.type === 'containersession') {
            //     const core = this.navigator.entityViewSpecs[this.navigator.entityViewSpecs.length - 1].entityCore as ContainersessionCore
            //     isBoard = core.board;
            // }

            // setTimeout(() => {
            //     if (isBoard) {
            //         this.refreshSet = false;
            //         this.pullEvents();
            //     }
            // }, 30000);
        }
    }

    sortLoads() {
        console.log('sorting loads');
        // this.listBody.reset();
        this.soakers = [];
        this.loadsPastDue = [];
        this.daySections = [];

        this.organizeEntitiesLocationOnBoard();
        // this.sortSectionsAsDays();
        this.filterOutSectionsThatAreNotInDateRange();
        this.sortByDueDate(this.soakers);
        this.sortByDueDate(this.loadsPastDue);
    }

    private organizeEntitiesLocationOnBoard() {
        for (const entity of this.entities) {
            // put items on board if requested fill date is in range
            this.addEntityToBoardIfDateIsInRangeOrPastDueOrSoaking(entity.requestedFillDate, entity);

            if (
                this.app.isHelium
                && entity.coolDownDays
                && entity.dueDate
            ) {

                const loadDayIndex = getIndexOfDate(
                    entity.requestedFillDate,
                    this.dayIndexDate,
                    null,
                );
                const soakDayIndex = getIndexOfDate(
                    entity.dueDate,
                    this.dayIndexDate,
                    null,
                );

                if (loadDayIndex !== soakDayIndex) {
                    // put items on board if due date is in range
                    this.addEntityToBoardIfDateIsInRangeOrPastDueOrSoaking(entity.dueDate, entity);
                }
            }
        }
    }

    private filterOutSectionsThatAreNotInDateRange() {
        for (let num = 0; num <= 6; num++) {
            // this.daySections.push(this.listBody.sections[num]);
        }
    }

    private addEntityToBoardIfDateIsInRangeOrPastDueOrSoaking(dateOfInterest: Date, entity) {
        const workingDate = new Date(dateOfInterest);
        workingDate.setHours(12);
        const loadDayIndex = getIndexOfDate(
            workingDate,
            this.dayIndexDate,
            null,
        );
        const ce: CEProtocol = new this.entityViewSpec.entityCore.ceProtocol(
            null,
            this.entityViewSpec.entityCore,
            entity,
            this.app
        );

        const entry = ce.entityListView;
        entry.section = loadDayIndex;

        if (entity.coolDownDays > 0) {
            entry.backgroundColor = this.soakBackgroundColor;
        }
        if (0 <= loadDayIndex && loadDayIndex <= 6) {
            // this.listBody.addEntry(entry);
        }
        this.addEntryToPastDueOrSoakersIfNecessary(entry);
    }

    private addEntryToPastDueOrSoakersIfNecessary(entry: ListEntry) {
        // check if load is soaking
        if (entry.entity.status === 'soaking') {
            const index = this.soakers.findIndex(obj => obj.entity.uid === entry.entity.uid);
            if (index < 0) {
                this.soakers.push(entry);
            }
        }

        // Check if load is past due
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 9999);

        const dueDateEndOfDay = new Date(
            entry.entity.dueDate ? entry.entity.dueDate.getTime() : entry.entity.requestedFillDate.getTime()
        );
        dueDateEndOfDay.setHours(23, 59, 59, 9999);

        if (
            dueDateEndOfDay.getTime() < endOfDay.getTime()
            && !['departed', 'loadingComplete'].includes(entry.entity.status)
        ) {
            const index = this.loadsPastDue.findIndex(obj => obj.entity.uid === entry.entity.uid);
            if (index < 0) {
                this.loadsPastDue.push(entry);
            }
        }
    }

    private sortByDueDate(listOfListEntries) {
        listOfListEntries.sort((n1, n2) => {
            if (n1.entity.dueDate > n2.entity.dueDate) {
                return 1;
            }
            if (n1.entity.dueDate < n2.entity.dueDate) {
                return -1;
            }
            return 0;
        });
    }

    onSelectionChange() {
        this.dayIndexDate = this.date.value;
        this.setDays();
        this.sortLoads();
    }

    toggleSoak() {
        this.soak = !this.soak;
    }

    setStartDay() {
        this.calculateAndSetStartDay(false);
        if (this.startDate == null) {
            console.log('setting start date');
            this.startDate = this.dayIndexDate;
            console.log(this.startDate);
        }
    }

    clickArriveContainer() {
        const containersessionCore = new ContainersessionCore(this.activeUserService);
        const spec = new PostSpec(containersessionCore, this.entityViewSpec, this.entityViewSpec.navigator);
        // spec.showAfterPost = true;
        this.navigator.entityViewSpecs.push(
            spec
        );
    }

    protected readonly print = print;
}
