import {SORespDistoffer} from '../../core/distoffer/so-resp-distoffer';
import {DistofferCore} from './distoffer.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDistofferFormFields} from './put-distoffer-form-fields';
import { CEDistofferAttributes } from './ce-distoffer-attributes'


export class CEDistofferBase extends CEBase {

    public details: CEDistofferAttributes;
    public fromFields: PutDistofferFormFields = new PutDistofferFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: DistofferCore,
        public entity: SORespDistoffer,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEDistofferAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.offering)
        this.attributes.push(this.details.distribution)

    }
}