import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOSitesessionService} from '../../core/sitesession/eo-sitesession';
import {SOGetSitesession} from '../../core/sitesession/so-get-sitesession';
import {RAPutSitesession} from './ra-put-sitesession';
import {RAPostSitesession} from './ra-post-sitesession';
import {RADeleteSitesession} from './ra-delete-sitesession';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CESitesession } from "./ce-sitesession"


export class SitesessionCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'sitesession';
  title = 'Sitesession';
  ceProtocol: CEConstructor = CESitesession

  entityService: EOSitesessionService;
  soGetClass = SOGetSitesession;
  soGet: SOGetSitesession;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOSitesessionService
    );
    this.adapters = [
      new RAPostSitesession(this),
      new RAPutSitesession(this),
      new RADeleteSitesession(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSitesession();
    this.soGet.queryOptions.limit = this.limit;
  }


}