import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutDistributiondayFormFields {

    public state: FormField
    public distribution: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutDistributiondayFormFields()

export const putDistributiondayFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
};