import { SOGetTag } from '../tag/so-get-tag';


export class SOPutLinag {

  constructor(
    public uid: string,
    public name: string = null,
    public identifier: string = null,
    public type: string = null,
    public tag: SOGetTag = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutLinag(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'type' in params ? params.type : null,
      'tag' in params ? params.tag : null,
    );
  }
}
