import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOInquiryService} from '../../core/inquiry/eo-inquiry';
import {SOGetInquiry} from '../../core/inquiry/so-get-inquiry';
import {RAPutInquiry} from './ra-put-inquiry';
import {RAPostInquiry} from './ra-post-inquiry';
import {RADeleteInquiry} from './ra-delete-inquiry';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEInquiry } from "./ce-inquiry"


export class InquiryCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'inquiry';
  title = 'Inquiry';
  ceProtocol: CEConstructor = CEInquiry

  entityService: EOInquiryService;
  soGetClass = SOGetInquiry;
  soGet: SOGetInquiry;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOInquiryService
    );
    this.adapters = [
      new RAPostInquiry(this),
      new RAPutInquiry(this),
      new RADeleteInquiry(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetInquiry();
    this.soGet.queryOptions.limit = this.limit;
  }


}