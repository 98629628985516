<div class="list-container" fxLayout="column">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
  <div *ngIf="!loading" class="hidden-progress-spacer"></div>
  <div fxLayout="row" class="body" fxFlex>
    <div class="board" *ngIf="showCal" fxLayout="column">
      <div fxLayout="row" class="control-bar" fxLayoutAlign="space-between center">
        <div fxLayout="row">
          <div class="cal-buttons" fxLayout="row" fxLayoutAlign="start center">
            <h2>{{month}} {{year}}</h2>
            <button mat-stroked-button class="arrow" (click)="back()">
              <
            </button>
            <button mat-stroked-button color="primary" (click)="pressTodayButton()">
              Today
            </button>
            <button mat-stroked-button class="arrow" (click)="next()">
              >
            </button>
          </div>
          <mat-form-field class="date-box">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="date" (dateChange)="onSelectionChange()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <button mat-icon-button (click)="print()">
            <mat-icon>
              print
            </mat-icon>
          </button>
          <button *ngIf="app.isHelium" class="soak-button" mat-icon-button (click)="toggleSoak()">
            <mat-icon>
              menu_open
            </mat-icon>
          </button>
        </div>
      </div>

      <div class="core-days" fxLayout="column" *ngIf="days">
        <div class="day-names" fxLayout="row">
          <p class="day-col" *ngFor="let day of days">{{day.heading}}</p>
        </div>
        <div class="account-rows">
          <div *ngFor="let account of accounts" fxLayout="column" class="customer">
            <p class="company-name">{{account.customer.companyName}}</p>
            <div fxLayout="row" class="customer-loads">
              <div *ngFor="let day of days" class="days day-col" fxLayout="column">
                <div *ngFor="let event of day.events">
                  <app-dv-load-summary *ngIf="event.listEntry.entity.account.uid == account.uid"
                                       [listEntry]="event.entityView.listEntry"
                                       (click)="selectListEntry(event.entityView.listEntry)"></app-dv-load-summary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="showSoak" class="soak">
        <button (click)="clickArriveContainer()" mat-stroked-button style="width: 100%">Arrive container</button>
      <h2>Soaking</h2>
      <div gdGap="5px" gdColumns="repeat(auto-fill, minmax(120px, 1fr))">
        <div *ngFor="let listEntry of soakers">
          <app-dv-load-summary [listEntry]="listEntry"
                               soaker="{{true}}"
                               (click)="selectListEntry(listEntry)"></app-dv-load-summary>
        </div>
      </div>
      <h2>Past Due</h2>
      <div gdGap="5px" gdColumns="repeat(auto-fill, minmax(120px, 1fr))">
        <div *ngFor="let listEntry of loadsPastDue">
          <app-dv-load-summary [listEntry]="listEntry"
                               (click)="selectListEntry(listEntry)"></app-dv-load-summary>
        </div>
      </div>
      <h2>Shipping Empty</h2>
      <div gdGap="5px" gdColumns="repeat(auto-fill, minmax(120px, 1fr))">
        <div *ngFor="let listEntry of empties">
          <app-dv-load-summary [listEntry]="listEntry"
                               (click)="selectListEntry(listEntry)"></app-dv-load-summary>
        </div>
      </div>
      <h2>Unknowns</h2>
      <div gdGap="5px" gdColumns="repeat(auto-fill, minmax(120px, 1fr))">
        <div *ngFor="let listEntry of unknowns">
          <app-dv-load-summary [listEntry]="listEntry"
                               (click)="selectListEntry(listEntry)"></app-dv-load-summary>
        </div>
      </div>
    </div>
  </div>

</div>
