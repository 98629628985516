import {SORespExternalindexprice} from '../../core/externalindexprice/so-resp-externalindexprice';
import {ExternalindexpriceCore} from './externalindexprice.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutExternalindexpriceFormFields} from './put-externalindexprice-form-fields';
import {SOGetExternalindexprice} from '../../core/externalindexprice/so-get-externalindexprice';
import {SOGetIndexprice} from '../../core/indexprice/so-get-indexprice';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEExternalindexpriceAttributesBase {

    indexprices: DetailEntry
    data: DetailEntry
    timestamp: DetailEntry
    eventProcessedUtcTime: DetailEntry
    partitionId: DetailEntry
    eventEnqueuedUtcTime: DetailEntry
    marketIndexId: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespExternalindexprice,
        app: GasDirectApp,
        formFields: PutExternalindexpriceFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.data = new DetailEntry(
            'Data',
            entity.data,
            'data',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.data
        )

        this.timestamp = new DetailEntry(
            'Timestamp',
            app.activeUserService.time.dateAsString(entity.timestamp),
            'timestamp',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.eventProcessedUtcTime = new DetailEntry(
            'Event processed utc time',
            entity.EventProcessedUtcTime,
            'eventProcessedUtcTime',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.eventProcessedUtcTime
        )

        this.partitionId = new DetailEntry(
            'Partition id',
            entity.PartitionId,
            'partitionId',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.partitionId
        )

        this.eventEnqueuedUtcTime = new DetailEntry(
            'Event enqueued utc time',
            entity.EventEnqueuedUtcTime,
            'eventEnqueuedUtcTime',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.eventEnqueuedUtcTime
        )

        this.marketIndexId = new DetailEntry(
            'Market index id',
            entity.marketIndexId,
            'marketIndexId',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const indexpricesCore = app.makeCore( 'indexprice');
        indexpricesCore.soGet = SOGetIndexprice.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            indexpricesCore
        )
        indexpricesCore.title = heading ? heading : indexpricesCore.title
        indexpricesCore.listTitle = 'Indexprices'

        indexpricesCore.configureForDisplay(parentEntityViewSpec)

        this.indexprices = new DetailEntry(
          heading ? heading : indexpricesCore.listTitle,
          null,
          'indexprices',
          BaseDetailType.embeddedList,
          indexpricesCore,
          EditableType.never
        )
    }
}