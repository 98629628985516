import {SOPostNavtemrola} from '../../core/navtemrola/so-post-navtemrola';
import {NavtemrolaCoreBase} from './navtemrola.core.base';
import {SOGetNavtemrola} from '../../core/navtemrola/so-get-navtemrola';
import {FormField, SelectionOption} from 'lionheartland';
import {postNavtemrolaFormFieldOptions} from './post-navtemrola-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TemproleCore} from '../temprole/temprole.core';
import {SOGetTemprole} from '../../core/temprole/so-get-temprole';
import {NaviCore} from '../navi/navi.core';
import {SOGetNavi} from '../../core/navi/so-get-navi';

export class RAPostNavtemrola extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postNavtemrolaFormFieldOptions);

  RequestClass = SOPostNavtemrola

  constructor(
    public entityCore: NavtemrolaCoreBase
  ) {
    super();
    if (postNavtemrolaFormFieldOptions.temprole) {
      postNavtemrolaFormFieldOptions.temprole.makeCore = true;
    }
    if (postNavtemrolaFormFieldOptions.navi) {
      postNavtemrolaFormFieldOptions.navi.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'temprole',
        SOGetTemprole,
      ),
      this.getFormValueFromIdentifier(
        'navi',
        SOGetNavi,
      ),
      this.getFormValueFromIdentifier('index'),
      this.getFormValueFromIdentifier('mainNav'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}