

export class SOPutMessage {

  constructor(
    public uid: string,
    public body: string = null,
    public read: boolean = null,
    public type: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutMessage(
      'uid' in params ? params.uid : null,
      'body' in params ? params.body : null,
      'read' in params ? params.read : null,
      'type' in params ? params.type : null,
    );
  }
}
