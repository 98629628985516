import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutInvoice } from './so-put-invoice';
import { SOGetInvoice } from './so-get-invoice';
import { SOPostInvoice } from './so-post-invoice';
import { SODeleteInvoice } from './so-delete-invoice';
import {SORespInvoice} from './so-resp-invoice';
import {SOPluralInvoice} from './so-plural-invoice';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOInvoiceService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'invoice',
      entityCore
    );
  }

  /** GET Invoice by id. Will 404 if id not found */
  get(request: SOGetInvoice): Observable<SOPluralInvoice> {
    return this.httpClient.get<SOPluralInvoice>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralInvoice, resp) }),
      tap(_ => this.log(`fetched Invoice`)),
      catchError(this.handleError<SOPluralInvoice>(`get Invoice`))
    );
  }

  /** POST: add a new Invoice to the server */
  post(request: SOPostInvoice): Observable<SORespInvoice> {
    return this.httpClient.post<SORespInvoice>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInvoice, resp) }),
      tap((response: SORespInvoice) => this.log(`added Invoice w/ id=${response.uid}`)),
      catchError(this.handleError<SORespInvoice>('Invoice post'))
    );
  }

  /** PUT: update Invoice  */
  put(request: SOPutInvoice): Observable<SORespInvoice> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespInvoice>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInvoice, resp) }),
      tap((response: SORespInvoice) => this.log(`edited Invoice w/ uid=${uid}`)),
      catchError(this.handleError<SORespInvoice>('Invoice put'))
    );
  }

  /** DELETE: delete the Invoice from the server */
  delete(request: SODeleteInvoice): Observable<SORespInvoice> {
    return this.httpClient.delete<SORespInvoice>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Invoice uid=${request.uid}`)),
      catchError(this.handleError<SORespInvoice>('delete Invoice'))
    );
  }
}
