import {SORespTaskreport} from '../../core/taskreport/so-resp-taskreport';
import {TaskreportCore} from './taskreport.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutTaskreportFormFields} from './put-taskreport-form-fields';
import {SOGetTaskreport} from '../../core/taskreport/so-get-taskreport';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetTask} from '../../core/task/so-get-task';


export class CETaskreportAttributesBase {

    dateCreated: DetailEntry
    type: DetailEntry
    status: DetailEntry
    distribution: DetailEntry
    dateRequested: DetailEntry
    dateStart: DetailEntry
    dateEnd: DetailEntry
    tasks: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespTaskreport,
        app: GasDirectApp,
        formFields: PutTaskreportFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.status = new DetailEntry(
            'Status',
            entity.status,
            'status',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.status
        )

        this.dateRequested = new DetailEntry(
            'Date requested',
            app.activeUserService.time.dateAsString(entity.dateRequested),
            'dateRequested',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateRequested
        )

        this.dateStart = new DetailEntry(
            'Date start',
            app.activeUserService.time.dateAsString(entity.dateStart),
            'dateStart',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateStart
        )

        this.dateEnd = new DetailEntry(
            'Date end',
            app.activeUserService.time.dateAsString(entity.dateEnd),
            'dateEnd',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateEnd
        )


        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const tasksCore = app.makeCore( 'task');
        tasksCore.soGet = SOGetTask.construct({
        });
        tasksCore.soGet.queryOptions.sortKey = 'dateCreated';
        tasksCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tasksCore
        )
        tasksCore.title = heading ? heading : tasksCore.title
        tasksCore.listTitle = 'Tasks'

        tasksCore.configureForDisplay(parentEntityViewSpec)

        this.tasks = new DetailEntry(
          heading ? heading : tasksCore.listTitle,
          null,
          'tasks',
          BaseDetailType.embeddedList,
          tasksCore,
          EditableType.never
        )
    }
}