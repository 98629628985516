import { SOGetHyear } from '../hyear/so-get-hyear';
import { SOGetAgreement } from '../agreement/so-get-agreement';


export class SOPutAgreeyear {

  constructor(
    public uid: string,
    public state: string = null,
    public acq: number = null,
    public acqUsed: number = null,
    public hyear: SOGetHyear = null,
    public agreement: SOGetAgreement = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAgreeyear(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'acq' in params ? params.acq : null,
      'acqUsed' in params ? params.acqUsed : null,
      'hyear' in params ? params.hyear : null,
      'agreement' in params ? params.agreement : null,
    );
  }
}
