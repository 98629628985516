import { SOGetWeight } from '../weight/so-get-weight';


export class SOPutTask {

  constructor(
    public uid: string,
    public trailerGone: boolean = null,
    public completed: boolean = null,
    public weight: SOGetWeight = null,
    public location: string = null,
    public loadStatus: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTask(
      'uid' in params ? params.uid : null,
      'trailerGone' in params ? params.trailerGone : null,
      'completed' in params ? params.completed : null,
      'weight' in params ? params.weight : null,
      'location' in params ? params.location : null,
      'loadStatus' in params ? params.loadStatus : null,
    );
  }
}
