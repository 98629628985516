import { Type } from 'class-transformer';
import { SORespGrant } from '../grant/so-resp-grant';
import { SORespUser } from '../user/so-resp-user';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespToken implements ObjectiveProtocol {

  entityType: string = 'token';
  uid: string;
  @Type(() => SORespGrant)
  roleGrant: SORespGrant;
  @Type(() => SORespUser)
  user: SORespUser = null;
  @Type(() => Date)
  dateCreated: Date = null;
  provider: string = null;
}