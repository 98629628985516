import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutHemon } from './so-put-hemon';
import { SOGetHemon } from './so-get-hemon';
import { SOPostHemon } from './so-post-hemon';
import { SODeleteHemon } from './so-delete-hemon';
import {SORespHemon} from './so-resp-hemon';
import {SOPluralHemon} from './so-plural-hemon';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOHemonService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'hemon',
      entityCore
    );
  }

  /** GET Hemon by id. Will 404 if id not found */
  get(request: SOGetHemon): Observable<SOPluralHemon> {
    return this.httpClient.get<SOPluralHemon>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralHemon, resp) }),
      tap(_ => this.log(`fetched Hemon`)),
      catchError(this.handleError<SOPluralHemon>(`get Hemon`))
    );
  }

  /** POST: add a new Hemon to the server */
  post(request: SOPostHemon): Observable<SORespHemon> {
    return this.httpClient.post<SORespHemon>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHemon, resp) }),
      tap((response: SORespHemon) => this.log(`added Hemon w/ id=${response.uid}`)),
      catchError(this.handleError<SORespHemon>('Hemon post'))
    );
  }

  /** PUT: update Hemon  */
  put(request: SOPutHemon): Observable<SORespHemon> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespHemon>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHemon, resp) }),
      tap((response: SORespHemon) => this.log(`edited Hemon w/ uid=${uid}`)),
      catchError(this.handleError<SORespHemon>('Hemon put'))
    );
  }

  /** DELETE: delete the Hemon from the server */
  delete(request: SODeleteHemon): Observable<SORespHemon> {
    return this.httpClient.delete<SORespHemon>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Hemon uid=${request.uid}`)),
      catchError(this.handleError<SORespHemon>('delete Hemon'))
    );
  }
}
