import { Type } from 'class-transformer';
import { SORespTemptract } from '../temptract/so-resp-temptract';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTempagreetract implements ObjectiveProtocol {

  entityType: string = 'tempagreetract';
  uid: string;
  @Type(() => SORespTemptract)
  temptract: SORespTemptract;
}