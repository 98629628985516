import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutChecklist } from './so-put-checklist';
import { SOGetChecklist } from './so-get-checklist';
import { SOPostChecklist } from './so-post-checklist';
import { SODeleteChecklist } from './so-delete-checklist';
import {SORespChecklist} from './so-resp-checklist';
import {SOPluralChecklist} from './so-plural-checklist';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOChecklistService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'checklist',
      entityCore
    );
  }

  /** GET Checklist by id. Will 404 if id not found */
  get(request: SOGetChecklist): Observable<SOPluralChecklist> {
    return this.httpClient.get<SOPluralChecklist>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralChecklist, resp) }),
      tap(_ => this.log(`fetched Checklist`)),
      catchError(this.handleError<SOPluralChecklist>(`get Checklist`))
    );
  }

  /** POST: add a new Checklist to the server */
  post(request: SOPostChecklist): Observable<SORespChecklist> {
    return this.httpClient.post<SORespChecklist>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespChecklist, resp) }),
      tap((response: SORespChecklist) => this.log(`added Checklist w/ id=${response.uid}`)),
      catchError(this.handleError<SORespChecklist>('Checklist post'))
    );
  }

  /** PUT: update Checklist  */
  put(request: SOPutChecklist): Observable<SORespChecklist> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespChecklist>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespChecklist, resp) }),
      tap((response: SORespChecklist) => this.log(`edited Checklist w/ uid=${uid}`)),
      catchError(this.handleError<SORespChecklist>('Checklist put'))
    );
  }

  /** DELETE: delete the Checklist from the server */
  delete(request: SODeleteChecklist): Observable<SORespChecklist> {
    return this.httpClient.delete<SORespChecklist>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Checklist uid=${request.uid}`)),
      catchError(this.handleError<SORespChecklist>('delete Checklist'))
    );
  }
}
