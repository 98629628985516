import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutLoad } from './so-put-load';
import { SOGetLoad } from './so-get-load';
import { SOPostLoad } from './so-post-load';
import { SODeleteLoad } from './so-delete-load';
import {SORespLoad} from './so-resp-load';
import {SOPluralLoad} from './so-plural-load';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOLoadService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'load',
      entityCore
    );
  }

  /** GET Load by id. Will 404 if id not found */
  get(request: SOGetLoad): Observable<SOPluralLoad> {
    return this.httpClient.get<SOPluralLoad>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralLoad, resp) }),
      tap(_ => this.log(`fetched Load`)),
      catchError(this.handleError<SOPluralLoad>(`get Load`))
    );
  }

  /** POST: add a new Load to the server */
  post(request: SOPostLoad): Observable<SORespLoad> {
    return this.httpClient.post<SORespLoad>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLoad, resp) }),
      tap((response: SORespLoad) => this.log(`added Load w/ id=${response.uid}`)),
      catchError(this.handleError<SORespLoad>('Load post'))
    );
  }

  /** PUT: update Load  */
  put(request: SOPutLoad): Observable<SORespLoad> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespLoad>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLoad, resp) }),
      tap((response: SORespLoad) => this.log(`edited Load w/ uid=${uid}`)),
      catchError(this.handleError<SORespLoad>('Load put'))
    );
  }

  /** DELETE: delete the Load from the server */
  delete(request: SODeleteLoad): Observable<SORespLoad> {
    return this.httpClient.delete<SORespLoad>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Load uid=${request.uid}`)),
      catchError(this.handleError<SORespLoad>('delete Load'))
    );
  }
}
