import {SORespOrganization} from '../../core/organization/so-resp-organization';
import {OrganizationCore} from './organization.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutOrganizationFormFields} from './put-organization-form-fields';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetCustomer} from '../../core/customer/so-get-customer';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetProvider} from '../../core/provider/so-get-provider';
import {SOGetOrgrola} from '../../core/orgrola/so-get-orgrola';
import {SOGetTrailer} from '../../core/trailer/so-get-trailer';
import {SOGetBol} from '../../core/bol/so-get-bol';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetFeatureasc} from '../../core/featureasc/so-get-featureasc';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEOrganizationAttributesBase {

    users: DetailEntry
    handle: DetailEntry
    ownerId: DetailEntry
    customer: DetailEntry
    carrier: DetailEntry
    loads: DetailEntry
    providers: DetailEntry
    orgrolas: DetailEntry
    trailers: DetailEntry
    bols: DetailEntry
    containersessions: DetailEntry
    featureascs: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespOrganization,
        app: GasDirectApp,
        formFields: PutOrganizationFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.handle = new DetailEntry(
            'Handle',
            entity.handle,
            'handle',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.handle
        )

        this.ownerId = new DetailEntry(
            'Owner id',
            entity.ownerId,
            'ownerId',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.ownerId
        )


        const usersCore = app.makeCore( 'user');
        usersCore.soGet = SOGetUser.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        usersCore.soGet.queryOptions.sortKey = 'surname';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            usersCore
        )
        usersCore.title = heading ? heading : usersCore.title
        usersCore.listTitle = 'Users'

        usersCore.configureForDisplay(parentEntityViewSpec)

        this.users = new DetailEntry(
          heading ? heading : usersCore.listTitle,
          null,
          'users',
          BaseDetailType.embeddedList,
          usersCore,
          EditableType.always,
          formFields.users
        )

        const customerCore = app.makeCore( 'customer');
        customerCore.soGet = SOGetCustomer.construct({
        });
        let customerCeProtocol;
        if (entity.customer) {
            customerCeProtocol = createCEProtocol(
                customerCore.ceProtocol,
                parentEntityViewSpec,
                customerCore,
                entity.customer,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            customerCore
        )
        customerCore.title = heading ? heading : customerCore.title
        customerCore.listTitle = 'Customer'

        if (entity.customer) {
            displayStringFactory.reconfigure(
                entity.customer,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        customerCore.configureForDisplay(parentEntityViewSpec)

        this.customer = new DetailEntry(
          heading ? heading : customerCore.listTitle,
          customerCeProtocol ? customerCeProtocol.entityListView.fullHeading : null,
          'customer',
          BaseDetailType.pushedDetail,
          customerCore,
          EditableType.always,
          formFields.customer
        )
        if (entity.customer) {
          this.customer.ceProtocol = customerCeProtocol;
          this.customer.cachedListEntry = customerCore.makeListEntry(parentEntityViewSpec, entity.customer, 0)
          customerCore.selectedEntity = entity.customer;
        }
        this.customer.singleFieldSelect = true

        const carrierCore = app.makeCore( 'carrier');
        carrierCore.soGet = SOGetCarrier.construct({
        });
        let carrierCeProtocol;
        if (entity.carrier) {
            carrierCeProtocol = createCEProtocol(
                carrierCore.ceProtocol,
                parentEntityViewSpec,
                carrierCore,
                entity.carrier,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            carrierCore
        )
        carrierCore.title = heading ? heading : carrierCore.title
        carrierCore.listTitle = 'Carrier'

        if (entity.carrier) {
            displayStringFactory.reconfigure(
                entity.carrier,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        carrierCore.configureForDisplay(parentEntityViewSpec)

        this.carrier = new DetailEntry(
          heading ? heading : carrierCore.listTitle,
          carrierCeProtocol ? carrierCeProtocol.entityListView.fullHeading : null,
          'carrier',
          BaseDetailType.pushedDetail,
          carrierCore,
          EditableType.always,
          formFields.carrier
        )
        if (entity.carrier) {
          this.carrier.ceProtocol = carrierCeProtocol;
          this.carrier.cachedListEntry = carrierCore.makeListEntry(parentEntityViewSpec, entity.carrier, 0)
          carrierCore.selectedEntity = entity.carrier;
        }
        this.carrier.singleFieldSelect = true

        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          carrier: new SOGetOrganization(entity.uid),
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.never
        )

        const providersCore = app.makeCore( 'provider');
        providersCore.soGet = SOGetProvider.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            providersCore
        )
        providersCore.title = heading ? heading : providersCore.title
        providersCore.listTitle = 'Providers'

        providersCore.configureForDisplay(parentEntityViewSpec)

        this.providers = new DetailEntry(
          heading ? heading : providersCore.listTitle,
          null,
          'providers',
          BaseDetailType.embeddedList,
          providersCore,
          EditableType.never
        )

        const orgrolasCore = app.makeCore( 'orgrola');
        orgrolasCore.soGet = SOGetOrgrola.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            orgrolasCore
        )
        orgrolasCore.title = heading ? heading : orgrolasCore.title
        orgrolasCore.listTitle = 'Orgrolas'

        orgrolasCore.configureForDisplay(parentEntityViewSpec)

        this.orgrolas = new DetailEntry(
          heading ? heading : orgrolasCore.listTitle,
          null,
          'orgrolas',
          BaseDetailType.embeddedList,
          orgrolasCore,
          EditableType.always,
          formFields.orgrolas
        )

        const trailersCore = app.makeCore( 'trailer');
        trailersCore.soGet = SOGetTrailer.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            trailersCore
        )
        trailersCore.title = heading ? heading : trailersCore.title
        trailersCore.listTitle = 'Trailers'

        trailersCore.configureForDisplay(parentEntityViewSpec)

        this.trailers = new DetailEntry(
          heading ? heading : trailersCore.listTitle,
          null,
          'trailers',
          BaseDetailType.embeddedList,
          trailersCore,
          EditableType.always,
          formFields.trailers
        )

        const bolsCore = app.makeCore( 'bol');
        bolsCore.soGet = SOGetBol.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            bolsCore
        )
        bolsCore.title = heading ? heading : bolsCore.title
        bolsCore.listTitle = 'Bols'

        bolsCore.configureForDisplay(parentEntityViewSpec)

        this.bols = new DetailEntry(
          heading ? heading : bolsCore.listTitle,
          null,
          'bols',
          BaseDetailType.embeddedList,
          bolsCore,
          EditableType.never
        )

        const containersessionsCore = app.makeCore( 'containersession');
        containersessionsCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionsCore.soGet.queryOptions.sortKey = 'arrived';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionsCore
        )
        containersessionsCore.title = heading ? heading : containersessionsCore.title
        containersessionsCore.listTitle = 'Containersessions'

        containersessionsCore.configureForDisplay(parentEntityViewSpec)

        this.containersessions = new DetailEntry(
          heading ? heading : containersessionsCore.listTitle,
          null,
          'containersessions',
          BaseDetailType.embeddedList,
          containersessionsCore,
          EditableType.never
        )

        const featureascsCore = app.makeCore( 'featureasc');
        featureascsCore.soGet = SOGetFeatureasc.construct({
          organization: new SOGetOrganization(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            featureascsCore
        )
        featureascsCore.title = heading ? heading : featureascsCore.title
        featureascsCore.listTitle = 'Featureascs'

        featureascsCore.configureForDisplay(parentEntityViewSpec)

        this.featureascs = new DetailEntry(
          heading ? heading : featureascsCore.listTitle,
          null,
          'featureascs',
          BaseDetailType.embeddedList,
          featureascsCore,
          EditableType.never
        )
    }
}