

export class SOPostShutelng {

  constructor(
    public data: any,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostShutelng(
      'data' in params ? params.data : null,
    );
  }
}
