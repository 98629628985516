import { Type } from 'class-transformer';
import { SORespChecklistitem } from '../checklistitem/so-resp-checklistitem';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespChecklist implements ObjectiveProtocol {

  entityType: string = 'checklist';
  uid: string;
  body: string;
  name: string;
  identifier: string;
  fileLink: string = null;
  @Type(() => SORespChecklistitem)
  items: SORespChecklistitem[];
}