

export class SOPutFeeschedule {

  constructor(
    public uid: string,
    public displayName: string = null,
    public start: Date = null,
    public end: Date = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutFeeschedule(
      'uid' in params ? params.uid : null,
      'displayName' in params ? params.displayName : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
    );
  }
}
