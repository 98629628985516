import { SOGetHmonth } from '../hmonth/so-get-hmonth';
import { SOGetAgreement } from '../agreement/so-get-agreement';
import { SOGetAgreeyear } from '../agreeyear/so-get-agreeyear';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetAgreemonth implements QueryProtocol {

  constructor(
    public uid: string = null,
    public state: string = null,
    public hmonth: SOGetHmonth = null,
    public agreement: SOGetAgreement = null,
    public year: number = null,
    public month: number = null,
    public agreeyear: SOGetAgreeyear = null,
    public distributionmonth: SOGetDistributionmonth = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetAgreemonth(
      'uid' in params ? params.uid : null,
      'state' in params ? params.state : null,
      'hmonth' in params ? params.hmonth : null,
      'agreement' in params ? params.agreement : null,
      'year' in params ? params.year : null,
      'month' in params ? params.month : null,
      'agreeyear' in params ? params.agreeyear : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
