import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDistoffer } from './so-put-distoffer';
import { SOGetDistoffer } from './so-get-distoffer';
import { SOPostDistoffer } from './so-post-distoffer';
import { SODeleteDistoffer } from './so-delete-distoffer';
import {SORespDistoffer} from './so-resp-distoffer';
import {SOPluralDistoffer} from './so-plural-distoffer';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODistofferService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'distoffer',
      entityCore
    );
  }

  /** GET Distoffer by id. Will 404 if id not found */
  get(request: SOGetDistoffer): Observable<SOPluralDistoffer> {
    return this.httpClient.get<SOPluralDistoffer>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDistoffer, resp) }),
      tap(_ => this.log(`fetched Distoffer`)),
      catchError(this.handleError<SOPluralDistoffer>(`get Distoffer`))
    );
  }

  /** POST: add a new Distoffer to the server */
  post(request: SOPostDistoffer): Observable<SORespDistoffer> {
    return this.httpClient.post<SORespDistoffer>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistoffer, resp) }),
      tap((response: SORespDistoffer) => this.log(`added Distoffer w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDistoffer>('Distoffer post'))
    );
  }

  /** PUT: update Distoffer  */
  put(request: SOPutDistoffer): Observable<SORespDistoffer> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDistoffer>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistoffer, resp) }),
      tap((response: SORespDistoffer) => this.log(`edited Distoffer w/ uid=${uid}`)),
      catchError(this.handleError<SORespDistoffer>('Distoffer put'))
    );
  }

  /** DELETE: delete the Distoffer from the server */
  delete(request: SODeleteDistoffer): Observable<SORespDistoffer> {
    return this.httpClient.delete<SORespDistoffer>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Distoffer uid=${request.uid}`)),
      catchError(this.handleError<SORespDistoffer>('delete Distoffer'))
    );
  }
}
