import {SOPostDistribution} from '../../core/distribution/so-post-distribution';
import {DistributionCoreBase} from './distribution.core.base';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {FormField, SelectionOption} from 'lionheartland';
import {postDistributionFormFieldOptions} from './post-distribution-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {LineCore} from '../line/line.core';
import {SOGetLine} from '../../core/line/so-get-line';

export class RAPostDistribution extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postDistributionFormFieldOptions);

  RequestClass = SOPostDistribution

  constructor(
    public entityCore: DistributionCoreBase
  ) {
    super();
    if (postDistributionFormFieldOptions.line) {
      postDistributionFormFieldOptions.line.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'line',
        SOGetLine,
      ),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('marketIndexId'),
      this.getFormValueFromIdentifier('conversionRate'),
      this.getFormValueFromIdentifier('galToWeight'),
      this.getFormValueFromIdentifier('defaultSlotsPerDay'),
      this.getFormValueFromIdentifier('providerSlotDurationHours'),
      this.getFormValueFromIdentifier('customerSlotDurationHours'),
      this.getFormValueFromIdentifier('showSlotDuration'),
      this.getFormValueFromIdentifier('pickupInstructions'),
      this.getFormValueFromIdentifier('paymentInstructions'),
      this.getFormValueFromIdentifier('checkoutText'),
      this.getFormValueFromIdentifier('purchaseCompleteText'),
      this.getFormValueFromIdentifier('bolHeader'),
      this.getFormValueFromIdentifier('bolWarning'),
      this.getFormValueFromIdentifier('loadingChecklist'),
      this.getFormValueFromIdentifier('departureChecklist'),
      this.getFormValueFromIdentifier('nextLoadNumber'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}