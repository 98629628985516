import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EODistofferService} from '../../core/distoffer/eo-distoffer';
import {SOGetDistoffer} from '../../core/distoffer/so-get-distoffer';
import {RAPutDistoffer} from './ra-put-distoffer';
import {RAPostDistoffer} from './ra-post-distoffer';
import {RADeleteDistoffer} from './ra-delete-distoffer';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution'
import { CEDistoffer } from "./ce-distoffer"


export class DistofferCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'distoffer';
  title = 'Distoffer';
  ceProtocol: CEConstructor = CEDistoffer

  entityService: EODistofferService;
  soGetClass = SOGetDistoffer;
  soGet: SOGetDistoffer;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EODistofferService
    );
    this.adapters = [
      new RAPostDistoffer(this),
      new RAPutDistoffer(this),
      new RADeleteDistoffer(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDistoffer();
    if (this.activeUserService.getScope('distribution')) {
        this.soGet.distribution = new SOGetDistribution(
          this.activeUserService.getScope('distribution').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}