import { SOGetAccount } from '../account/so-get-account';
import { SOGetAgreement } from '../agreement/so-get-agreement';
import { SOGetAgreemonth } from '../agreemonth/so-get-agreemonth';
import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetOrder implements QueryProtocol {

  constructor(
    public uid: string = null,
    public status: string = null,
    public account: SOGetAccount = null,
    public agreement: SOGetAgreement = null,
    public agreemonth: SOGetAgreemonth = null,
    public distributionmonth: SOGetDistributionmonth = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetOrder(
      'uid' in params ? params.uid : null,
      'status' in params ? params.status : null,
      'account' in params ? params.account : null,
      'agreement' in params ? params.agreement : null,
      'agreemonth' in params ? params.agreemonth : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
