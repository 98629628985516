import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOBidService} from '../../core/bid/eo-bid';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {RAPutBid} from './ra-put-bid';
import {RAPostBid} from './ra-post-bid';
import {RADeleteBid} from './ra-delete-bid';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import { CEBid } from "./ce-bid"


export class BidCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'bid';
  title = 'Bid';
  ceProtocol: CEConstructor = CEBid

  entityService: EOBidService;
  soGetClass = SOGetBid;
  soGet: SOGetBid;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOBidService
    );
    this.adapters = [
      new RAPostBid(this),
      new RAPutBid(this),
      new RADeleteBid(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBid();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}