import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostWonderwareFormFields {

    public data: FormField

    constructor() {

        this.data = new FormField(
          'data',
          'Data',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostWonderwareFormFields()

export const postWonderwareFormFieldOptions: {[key: string]: FormField} = {
  data: fields.data,
};