import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespHemon implements ObjectiveProtocol {

  entityType: string = 'hemon';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  state: string;
  n2Level: number;
  n2Pressure: number;
  heTemp: number = null;
  heLevel: number;
  hePressure: number;
  n2LevelDcs: number = null;
  n2PressureDcs: number = null;
  heTempDcs: number = null;
  heLevelDcs: number = null;
  hePressureDcs: number = null;
}