import { SOGetInvoice } from '../invoice/so-get-invoice';


export class SOPostInvoiceitem {

  constructor(
    public description: string,
    public quantity: number,
    public unitCost: number,
    public invoice: SOGetInvoice,
    public units: string,
    public code: string = null,
    public codeDefinition: string = null,
    public baseUnits: string = null,
    public baseQuantity: number = null,
    public conversion: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostInvoiceitem(
      'description' in params ? params.description : null,
      'quantity' in params ? params.quantity : null,
      'unitCost' in params ? params.unitCost : null,
      'invoice' in params ? params.invoice : null,
      'code' in params ? params.code : null,
      'codeDefinition' in params ? params.codeDefinition : null,
      'units' in params ? params.units : null,
      'baseUnits' in params ? params.baseUnits : null,
      'baseQuantity' in params ? params.baseQuantity : null,
      'conversion' in params ? params.conversion : null,
    );
  }
}
