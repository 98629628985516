import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespExternalindexprice implements ObjectiveProtocol {

  entityType: string = 'externalindexprice';
  uid: string;
  data: any;
  @Type(() => Date)
  timestamp: Date;
  EventProcessedUtcTime: string;
  PartitionId: string;
  EventEnqueuedUtcTime: string;
  marketIndexId: string = null;
}