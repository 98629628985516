import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutHestatus } from './so-put-hestatus';
import { SOGetHestatus } from './so-get-hestatus';
import { SOPostHestatus } from './so-post-hestatus';
import { SODeleteHestatus } from './so-delete-hestatus';
import {SORespHestatus} from './so-resp-hestatus';
import {SOPluralHestatus} from './so-plural-hestatus';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOHestatusService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'hestatus',
      entityCore
    );
  }

  /** GET Hestatus by id. Will 404 if id not found */
  get(request: SOGetHestatus): Observable<SOPluralHestatus> {
    return this.httpClient.get<SOPluralHestatus>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralHestatus, resp) }),
      tap(_ => this.log(`fetched Hestatus`)),
      catchError(this.handleError<SOPluralHestatus>(`get Hestatus`))
    );
  }

  /** POST: add a new Hestatus to the server */
  post(request: SOPostHestatus): Observable<SORespHestatus> {
    return this.httpClient.post<SORespHestatus>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHestatus, resp) }),
      tap((response: SORespHestatus) => this.log(`added Hestatus w/ id=${response.uid}`)),
      catchError(this.handleError<SORespHestatus>('Hestatus post'))
    );
  }

  /** PUT: update Hestatus  */
  put(request: SOPutHestatus): Observable<SORespHestatus> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespHestatus>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHestatus, resp) }),
      tap((response: SORespHestatus) => this.log(`edited Hestatus w/ uid=${uid}`)),
      catchError(this.handleError<SORespHestatus>('Hestatus put'))
    );
  }

  /** DELETE: delete the Hestatus from the server */
  delete(request: SODeleteHestatus): Observable<SORespHestatus> {
    return this.httpClient.delete<SORespHestatus>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Hestatus uid=${request.uid}`)),
      catchError(this.handleError<SORespHestatus>('delete Hestatus'))
    );
  }
}
