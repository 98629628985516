import {SORespChecklist} from '../../core/checklist/so-resp-checklist';
import {ChecklistCore} from './checklist.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutChecklistFormFields} from './put-checklist-form-fields';
import { CEChecklistAttributes } from './ce-checklist-attributes'


export class CEChecklistBase extends CEBase {

    public details: CEChecklistAttributes;
    public fromFields: PutChecklistFormFields = new PutChecklistFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ChecklistCore,
        public entity: SORespChecklist,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEChecklistAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.body)
        this.attributes.push(this.details.name)
        this.attributes.push(this.details.identifier)
        this.attributes.push(this.details.fileLink)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.items)

    }
}