import {SOPostLoadfee} from '../../core/loadfee/so-post-loadfee';
import {LoadfeeCoreBase} from './loadfee.core.base';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {FormField, SelectionOption} from 'lionheartland';
import {postLoadfeeFormFieldOptions} from './post-loadfee-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {FeeCore} from '../fee/fee.core';
import {SOGetFee} from '../../core/fee/so-get-fee';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';
import {DistributionmonthCore} from '../distributionmonth/distributionmonth.core';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {PriceCore} from '../price/price.core';
import {SOGetPrice} from '../../core/price/so-get-price';

export class RAPostLoadfee extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postLoadfeeFormFieldOptions);

  RequestClass = SOPostLoadfee

  constructor(
    public entityCore: LoadfeeCoreBase
  ) {
    super();
    if (postLoadfeeFormFieldOptions.fee) {
      postLoadfeeFormFieldOptions.fee.makeCore = true;
    }
    if (postLoadfeeFormFieldOptions.load) {
      postLoadfeeFormFieldOptions.load.makeCore = true;
    }
    if (postLoadfeeFormFieldOptions.distributionmonth) {
      postLoadfeeFormFieldOptions.distributionmonth.makeCore = true;
    }
    if (postLoadfeeFormFieldOptions.price) {
      postLoadfeeFormFieldOptions.price.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
      this.getFormValueFromIdentifier('unitCost'),
      this.getFormValueFromIdentifier('feeDate'),
      this.getFormValueFromIdentifier(
        'distributionmonth',
        SOGetDistributionmonth,
      ),
      this.getFormValueFromIdentifier('notes'),
      this.getFormValueFromIdentifier(
        'fee',
        SOGetFee,
      ),
      this.getFormValueFromIdentifier(
        'price',
        SOGetPrice,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}