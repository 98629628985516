import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOWonderwareService} from '../../core/wonderware/eo-wonderware';
import {SOGetWonderware} from '../../core/wonderware/so-get-wonderware';
import {RAPutWonderware} from './ra-put-wonderware';
import {RAPostWonderware} from './ra-post-wonderware';
import {RADeleteWonderware} from './ra-delete-wonderware';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEWonderware } from "./ce-wonderware"


export class WonderwareCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'wonderware';
  title = 'Wonderware';
  ceProtocol: CEConstructor = CEWonderware

  entityService: EOWonderwareService;
  soGetClass = SOGetWonderware;
  soGet: SOGetWonderware;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOWonderwareService
    );
    this.adapters = [
      new RAPostWonderware(this),
      new RAPutWonderware(this),
      new RADeleteWonderware(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetWonderware();
    this.soGet.queryOptions.limit = this.limit;
  }


}