import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EORateableagreeyearallocationService} from '../../core/rateableagreeyearallocation/eo-rateableagreeyearallocation';
import {SOGetRateableagreeyearallocation} from '../../core/rateableagreeyearallocation/so-get-rateableagreeyearallocation';
import {RAPutRateableagreeyearallocation} from './ra-put-rateableagreeyearallocation';
import {RAPostRateableagreeyearallocation} from './ra-post-rateableagreeyearallocation';
import {RADeleteRateableagreeyearallocation} from './ra-delete-rateableagreeyearallocation';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CERateableagreeyearallocation } from "./ce-rateableagreeyearallocation"


export class RateableagreeyearallocationCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'rateableagreeyearallocation';
  title = 'Rateableagreeyearallocation';
  ceProtocol: CEConstructor = CERateableagreeyearallocation

  entityService: EORateableagreeyearallocationService;
  soGetClass = SOGetRateableagreeyearallocation;
  soGet: SOGetRateableagreeyearallocation;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EORateableagreeyearallocationService
    );
    this.adapters = [
      new RAPostRateableagreeyearallocation(this),
      new RAPutRateableagreeyearallocation(this),
      new RADeleteRateableagreeyearallocation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRateableagreeyearallocation();
    this.soGet.queryOptions.limit = this.limit;
  }


}