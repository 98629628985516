import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostBidFormFields {

    public displayName: FormField
    public description: FormField
    public amount: FormField
    public tempclin: FormField
    public account: FormField
    public amountTwo: FormField
    public amountThree: FormField
    public sale: FormField

    constructor() {

        this.displayName = new FormField(
          'displayName',
          'Display name',
          null,
          FormFieldType.text,
          true,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          true,
        )
        this.tempclin = new FormField(
          'tempclin',
          'Tempclin',
          'tempclin',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.amountTwo = new FormField(
          'amountTwo',
          'Amount two',
          null,
          FormFieldType.text,
          false,
        )
        this.amountThree = new FormField(
          'amountThree',
          'Amount three',
          null,
          FormFieldType.text,
          false,
        )
        this.sale = new FormField(
          'sale',
          'Sale',
          'sale',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostBidFormFields()

export const postBidFormFieldOptions: {[key: string]: FormField} = {
  displayName: fields.displayName,
  description: fields.description,
  amount: fields.amount,
  tempclin: fields.tempclin,
  amountTwo: fields.amountTwo,
  amountThree: fields.amountThree,
  sale: fields.sale,
};