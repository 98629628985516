import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDowntime implements ObjectiveProtocol {

  entityType: string = 'downtime';
  uid: string;
  description: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date = null;
}