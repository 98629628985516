import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTemptract } from './so-put-temptract';
import { SOGetTemptract } from './so-get-temptract';
import { SOPostTemptract } from './so-post-temptract';
import { SODeleteTemptract } from './so-delete-temptract';
import {SORespTemptract} from './so-resp-temptract';
import {SOPluralTemptract} from './so-plural-temptract';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTemptractService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'temptract',
      entityCore
    );
  }

  /** GET Temptract by id. Will 404 if id not found */
  get(request: SOGetTemptract): Observable<SOPluralTemptract> {
    return this.httpClient.get<SOPluralTemptract>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTemptract, resp) }),
      tap(_ => this.log(`fetched Temptract`)),
      catchError(this.handleError<SOPluralTemptract>(`get Temptract`))
    );
  }

  /** POST: add a new Temptract to the server */
  post(request: SOPostTemptract): Observable<SORespTemptract> {
    return this.httpClient.post<SORespTemptract>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemptract, resp) }),
      tap((response: SORespTemptract) => this.log(`added Temptract w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTemptract>('Temptract post'))
    );
  }

  /** PUT: update Temptract  */
  put(request: SOPutTemptract): Observable<SORespTemptract> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTemptract>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemptract, resp) }),
      tap((response: SORespTemptract) => this.log(`edited Temptract w/ uid=${uid}`)),
      catchError(this.handleError<SORespTemptract>('Temptract put'))
    );
  }

  /** DELETE: delete the Temptract from the server */
  delete(request: SODeleteTemptract): Observable<SORespTemptract> {
    return this.httpClient.delete<SORespTemptract>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Temptract uid=${request.uid}`)),
      catchError(this.handleError<SORespTemptract>('delete Temptract'))
    );
  }
}
