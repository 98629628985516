import {SOPutWeight} from '../../core/weight/so-put-weight';
import {WeightCoreBase} from './weight.core.base';
import {SOGetWeight} from '../../core/weight/so-get-weight';
import {FormField, SelectionOption} from 'lionheartland';
import {putWeightFormFieldOptions} from './put-weight-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';

export class RAPutWeight extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putWeightFormFieldOptions);

  RequestClass = SOPutWeight

  constructor(
    public entityCore: WeightCoreBase
  ) {
    super();
    if (putWeightFormFieldOptions.load) {
      putWeightFormFieldOptions.load.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('dateCreated'),
      this.getFormValueFromIdentifier('tare'),
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}