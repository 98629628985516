import {SOPostContract} from '../../core/contract/so-post-contract';
import {ContractCoreBase} from './contract.core.base';
import {SOGetContract} from '../../core/contract/so-get-contract';
import {FormField, SelectionOption} from 'lionheartland';
import {postContractFormFieldOptions} from './post-contract-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AgreementCore} from '../agreement/agreement.core';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {TemptractCore} from '../temptract/temptract.core';
import {SOGetTemptract} from '../../core/temptract/so-get-temptract';

export class RAPostContract extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postContractFormFieldOptions);

  RequestClass = SOPostContract

  constructor(
    public entityCore: ContractCoreBase
  ) {
    super();
    if (postContractFormFieldOptions.agreement) {
      postContractFormFieldOptions.agreement.makeCore = true;
    }
    if (postContractFormFieldOptions.temptract) {
      postContractFormFieldOptions.temptract.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('takeOrPay'),
      this.getFormValueFromIdentifier('minLoadSizeToQualify'),
      this.getFormValueFromIdentifier('docusignLink'),
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
      this.getFormValueFromIdentifier(
        'agreement',
        SOGetAgreement,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'temptract',
        SOGetTemptract,
      ),
      this.getFormValueFromIdentifier('loadType'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}