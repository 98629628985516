import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {LionheartlandModule} from 'lionheartland';
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "./app-routing.module";
import {MatCardModule} from "@angular/material/card";
import {FlexModule, GridModule} from "@angular/flex-layout";
import {AvCalendarComponent} from "./custom-structure/av-calendar/av-calendar.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {DvLoadSummaryComponent} from "./custom-structure/dv-load-summary/dv-load-summary.component";
import {AvBoardComponent} from "./custom-structure/av-board/av-board.component";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {DvEventComponent} from "./custom-structure/dv-event/dv-event.component";
import { GasDirectNavigatorComponent } from './custom-structure/gas-direct-navigator/gas-direct-navigator.component';
import { GasDirectEntityViewComponent } from './custom-structure/gas-direct-entity-view/gas-direct-entity-view.component';
import {AvStatusComponent} from "./custom-structure/av-status/av-status.component";
import {DvLoadRowComponent} from "./custom-structure/dv-load-row/dv-load-row.component";


@NgModule({
    imports: [
        BrowserModule,
        RouterModule,
        AppRoutingModule,
        MatCardModule,
        FlexModule,
        MatProgressBarModule,
        MatIconModule,
        MatListModule,
        MatInputModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatButtonModule,
        GridModule,
        LionheartlandModule,
    ],
    declarations: [
        AppComponent,
        AvCalendarComponent,
        AvBoardComponent,
        DvLoadSummaryComponent,
        DvEventComponent,
        GasDirectNavigatorComponent,
        GasDirectEntityViewComponent,
        AvStatusComponent,
        DvLoadRowComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}