import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespProductionreport implements ObjectiveProtocol {

  entityType: string = 'productionreport';
  uid: string;
  ident: string;
  timeWindow: string;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date;
  average: number = 0;
  averageUnits: string = "";
  midnightWeight: number = 0;
}