import {SOPutInvoiceitem} from '../../core/invoiceitem/so-put-invoiceitem';
import {InvoiceitemCoreBase} from './invoiceitem.core.base';
import {SOGetInvoiceitem} from '../../core/invoiceitem/so-get-invoiceitem';
import {FormField, SelectionOption} from 'lionheartland';
import {putInvoiceitemFormFieldOptions} from './put-invoiceitem-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutInvoiceitem extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putInvoiceitemFormFieldOptions);

  RequestClass = SOPutInvoiceitem

  constructor(
    public entityCore: InvoiceitemCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}