import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOHmonthService} from '../../core/hmonth/eo-hmonth';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {RAPutHmonth} from './ra-put-hmonth';
import {RAPostHmonth} from './ra-post-hmonth';
import {RADeleteHmonth} from './ra-delete-hmonth';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import { CEHmonth } from "./ce-hmonth"


export class HmonthCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'hmonth';
  title = 'Hmonth';
  ceProtocol: CEConstructor = CEHmonth

  entityService: EOHmonthService;
  soGetClass = SOGetHmonth;
  soGet: SOGetHmonth;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOHmonthService
    );
    this.adapters = [
      new RAPostHmonth(this),
      new RAPutHmonth(this),
      new RADeleteHmonth(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetHmonth();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'month';
  }


}