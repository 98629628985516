import { SOGetTemprole } from '../temprole/so-get-temprole';
import { SOGetNavi } from '../navi/so-get-navi';


export class SOPostNavtemrola {

  constructor(
    public temprole: SOGetTemprole,
    public navi: SOGetNavi,
    public index: number = null,
    public mainNav: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostNavtemrola(
      'index' in params ? params.index : null,
      'mainNav' in params ? params.mainNav : null,
      'temprole' in params ? params.temprole : null,
      'navi' in params ? params.navi : null,
    );
  }
}
