import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutMonthcloseFormFields {

    public status: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PutMonthcloseFormFields()

export const putMonthcloseFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
};