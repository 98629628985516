import { SOGetProduction } from '../production/so-get-production';


export class SOPutProductionreport {

  constructor(
    public uid: string,
    public ident: string = null,
    public production: SOGetProduction = null,
    public timeWindow: string = null,
    public start: Date = null,
    public end: Date = null,
    public average: number = null,
    public averageUnits: string = null,
    public midnightWeight: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutProductionreport(
      'uid' in params ? params.uid : null,
      'ident' in params ? params.ident : null,
      'production' in params ? params.production : null,
      'timeWindow' in params ? params.timeWindow : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'average' in params ? params.average : null,
      'averageUnits' in params ? params.averageUnits : null,
      'midnightWeight' in params ? params.midnightWeight : null,
    );
  }
}
