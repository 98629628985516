import {SOPostSlotoption} from '../../core/slotoption/so-post-slotoption';
import {SlotoptionCoreBase} from './slotoption.core.base';
import {SOGetSlotoption} from '../../core/slotoption/so-get-slotoption';
import {FormField, SelectionOption} from 'lionheartland';
import {postSlotoptionFormFieldOptions} from './post-slotoption-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {SlotCore} from '../slot/slot.core';
import {SOGetSlot} from '../../core/slot/so-get-slot';
import {AgreemonthCore} from '../agreemonth/agreemonth.core';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';

export class RAPostSlotoption extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSlotoptionFormFieldOptions);

  RequestClass = SOPostSlotoption

  constructor(
    public entityCore: SlotoptionCoreBase
  ) {
    super();
    if (postSlotoptionFormFieldOptions.slot) {
      postSlotoptionFormFieldOptions.slot.makeCore = true;
    }
    if (postSlotoptionFormFieldOptions.agreemonth) {
      postSlotoptionFormFieldOptions.agreemonth.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'slot',
        SOGetSlot,
      ),
      this.getFormValueFromIdentifier(
        'agreemonth',
        SOGetAgreemonth,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}