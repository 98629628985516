import { SOGetContainersession } from '../containersession/so-get-containersession';
import { SOGetLoad } from '../load/so-get-load';
import { SOGetDistributionday } from '../distributionday/so-get-distributionday';
import { SOGetInvoice } from '../invoice/so-get-invoice';
import { SOGetInvoiceitem } from '../invoiceitem/so-get-invoiceitem';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetDelta implements QueryProtocol {

  constructor(
    public uid: string = null,
    public containersession: SOGetContainersession = null,
    public load: SOGetLoad = null,
    public distributionday: SOGetDistributionday = null,
    public invoice: SOGetInvoice = null,
    public invoiceitem: SOGetInvoiceitem = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetDelta(
      'uid' in params ? params.uid : null,
      'containersession' in params ? params.containersession : null,
      'load' in params ? params.load : null,
      'distributionday' in params ? params.distributionday : null,
      'invoice' in params ? params.invoice : null,
      'invoiceitem' in params ? params.invoiceitem : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
