

export class SOPutSitesession {

  constructor(
    public uid: string,
    public arrived: Date = null,
    public departed: Date = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSitesession(
      'uid' in params ? params.uid : null,
      'arrived' in params ? params.arrived : null,
      'departed' in params ? params.departed : null,
    );
  }
}
