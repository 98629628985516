import { Type } from 'class-transformer';
import { SORespFeature } from '../feature/so-resp-feature';
import { SORespDistribution } from '../distribution/so-resp-distribution';
import { SORespUser } from '../user/so-resp-user';
import { SORespRole } from '../role/so-resp-role';
import { SORespAccount } from '../account/so-resp-account';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespFeatureasc implements ObjectiveProtocol {

  entityType: string = 'featureasc';
  uid: string;
  @Type(() => SORespFeature)
  feature: SORespFeature;
  @Type(() => SORespDistribution)
  distribution: SORespDistribution = null;
  @Type(() => SORespUser)
  user: SORespUser = null;
  @Type(() => SORespRole)
  role: SORespRole = null;
  @Type(() => SORespAccount)
  account: SORespAccount = null;
  @Type(() => SORespOrganization)
  organization: SORespOrganization = null;
}