import {SORespCustomer} from '../../core/customer/so-resp-customer';
import {CustomerCore} from './customer.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutCustomerFormFields} from './put-customer-form-fields';
import {SOGetCustomer} from '../../core/customer/so-get-customer';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetInquiry} from '../../core/inquiry/so-get-inquiry';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CECustomerAttributesBase {

    organizations: DetailEntry
    companyName: DetailEntry
    dateCreated: DetailEntry
    emailDomain: DetailEntry
    signedAgreement: DetailEntry
    handle: DetailEntry
    inquiry: DetailEntry
    fullCompanyName: DetailEntry
    address: DetailEntry
    remittorNumber: DetailEntry
    additionalInvoiceText: DetailEntry
    billingPoc: DetailEntry
    paymentTerms: DetailEntry
    stripeRef: DetailEntry
    stripeDirectDebit: DetailEntry
    stripeVerify1: DetailEntry
    stripeVerify2: DetailEntry
    creditLimit: DetailEntry
    accounts: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespCustomer,
        app: GasDirectApp,
        formFields: PutCustomerFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.companyName = new DetailEntry(
            'Company name',
            entity.companyName,
            'companyName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.companyName
        )
        this.companyName.listHeadingIndex = 0;

        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.emailDomain = new DetailEntry(
            'Email domain',
            entity.emailDomain,
            'emailDomain',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.emailDomain
        )

        this.signedAgreement = new DetailEntry(
            'Signed agreement',
            entity.signedAgreement,
            'signedAgreement',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.signedAgreement
        )

        this.handle = new DetailEntry(
            'Handle',
            entity.handle,
            'handle',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.handle
        )

        this.fullCompanyName = new DetailEntry(
            'Full company name',
            entity.fullCompanyName,
            'fullCompanyName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.fullCompanyName
        )

        this.address = new DetailEntry(
            'Address',
            entity.address,
            'address',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.address
        )

        this.remittorNumber = new DetailEntry(
            'Remittor number',
            entity.remittorNumber,
            'remittorNumber',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.remittorNumber
        )

        this.additionalInvoiceText = new DetailEntry(
            'Additional invoice text',
            entity.additionalInvoiceText,
            'additionalInvoiceText',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.additionalInvoiceText
        )

        this.billingPoc = new DetailEntry(
            'Billing poc',
            entity.billingPoc,
            'billingPoc',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.billingPoc
        )

        this.paymentTerms = new DetailEntry(
            'Payment terms',
            entity.paymentTerms,
            'paymentTerms',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.paymentTerms
        )

        this.stripeRef = new DetailEntry(
            'Stripe ref',
            entity.stripeRef,
            'stripeRef',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.stripeRef
        )

        this.stripeDirectDebit = new DetailEntry(
            'Stripe direct debit',
            entity.stripeDirectDebit,
            'stripeDirectDebit',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.stripeDirectDebit
        )

        this.stripeVerify1 = new DetailEntry(
            'Stripe verify1',
            entity.stripeVerify1,
            'stripeVerify1',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.stripeVerify1
        )

        this.stripeVerify2 = new DetailEntry(
            'Stripe verify2',
            entity.stripeVerify2,
            'stripeVerify2',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.stripeVerify2
        )

        this.creditLimit = new DetailEntry(
            'Credit limit',
            entity.creditLimit,
            'creditLimit',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.creditLimit
        )


        const organizationsCore = app.makeCore( 'organization');
        organizationsCore.soGet = SOGetOrganization.construct({
          customer: new SOGetCustomer(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            organizationsCore
        )
        organizationsCore.title = heading ? heading : organizationsCore.title
        organizationsCore.listTitle = 'Organizations'

        organizationsCore.configureForDisplay(parentEntityViewSpec)

        this.organizations = new DetailEntry(
          heading ? heading : organizationsCore.listTitle,
          null,
          'organizations',
          BaseDetailType.embeddedList,
          organizationsCore,
          EditableType.never
        )

        const inquiryCore = app.makeCore( 'inquiry');
        inquiryCore.soGet = SOGetInquiry.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            inquiryCore
        )
        inquiryCore.title = heading ? heading : inquiryCore.title
        inquiryCore.listTitle = 'Inquiry'

        inquiryCore.configureForDisplay(parentEntityViewSpec)

        this.inquiry = new DetailEntry(
          heading ? heading : inquiryCore.listTitle,
          null,
          'inquiry',
          BaseDetailType.pushedDetail,
          inquiryCore,
          EditableType.never
        )
        this.inquiry.singleFieldSelect = true

        const accountsCore = app.makeCore( 'account');
        accountsCore.soGet = SOGetAccount.construct({
          customer: new SOGetCustomer(entity.uid),
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountsCore
        )
        accountsCore.title = heading ? heading : accountsCore.title
        accountsCore.listTitle = 'Accounts'

        accountsCore.configureForDisplay(parentEntityViewSpec)

        this.accounts = new DetailEntry(
          heading ? heading : accountsCore.listTitle,
          null,
          'accounts',
          BaseDetailType.embeddedList,
          accountsCore,
          EditableType.never
        )
    }
}