import { SOGetDistributionmonth } from '../distributionmonth/so-get-distributionmonth';


export class SOPostDowntime {

  constructor(
    public description: string,
    public start: Date,
    public distributionmonth: SOGetDistributionmonth,
    public cutPercent: number,
    public end: Date = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDowntime(
      'description' in params ? params.description : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'distributionmonth' in params ? params.distributionmonth : null,
      'cutPercent' in params ? params.cutPercent : null,
    );
  }
}
