import {SORespInvoiceitem} from '../../core/invoiceitem/so-resp-invoiceitem';
import {InvoiceitemCore} from './invoiceitem.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutInvoiceitemFormFields} from './put-invoiceitem-form-fields';
import { CEInvoiceitemAttributes } from './ce-invoiceitem-attributes'


export class CEInvoiceitemBase extends CEBase {

    public details: CEInvoiceitemAttributes;
    public fromFields: PutInvoiceitemFormFields = new PutInvoiceitemFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: InvoiceitemCore,
        public entity: SORespInvoiceitem,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEInvoiceitemAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.description)
        this.attributes.push(this.details.quantity)
        this.attributes.push(this.details.unitCost)
        this.attributes.push(this.details.total)
        this.attributes.push(this.details.code)
        this.attributes.push(this.details.codeDefinition)
        this.attributes.push(this.details.units)
        this.attributes.push(this.details.baseUnits)
        this.attributes.push(this.details.baseQuantity)
        this.attributes.push(this.details.conversion)
        this.attributes.push(this.details.loadfees)
        this.attributes.push(this.details.deltas)
        this.attributes.push(this.details.invoice)

    }
}