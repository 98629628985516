import { Type } from 'class-transformer';
import { SORespNavi } from '../navi/so-resp-navi';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespNavtemrola implements ObjectiveProtocol {

  entityType: string = 'navtemrola';
  uid: string;
  index: number = null;
  mainNav: boolean = null;
  @Type(() => SORespNavi)
  navi: SORespNavi;
}