import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EONavroaService} from '../../core/navroa/eo-navroa';
import {SOGetNavroa} from '../../core/navroa/so-get-navroa';
import {RAPutNavroa} from './ra-put-navroa';
import {RAPostNavroa} from './ra-post-navroa';
import {RADeleteNavroa} from './ra-delete-navroa';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CENavroa } from "./ce-navroa"


export class NavroaCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'navroa';
  title = 'Navroa';
  ceProtocol: CEConstructor = CENavroa

  entityService: EONavroaService;
  soGetClass = SOGetNavroa;
  soGet: SOGetNavroa;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EONavroaService
    );
    this.adapters = [
      new RAPostNavroa(this),
      new RAPutNavroa(this),
      new RADeleteNavroa(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetNavroa();
    this.soGet.queryOptions.limit = this.limit;
  }


}