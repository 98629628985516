import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutClin } from './so-put-clin';
import { SOGetClin } from './so-get-clin';
import { SOPostClin } from './so-post-clin';
import { SODeleteClin } from './so-delete-clin';
import {SORespClin} from './so-resp-clin';
import {SOPluralClin} from './so-plural-clin';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOClinService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'clin',
      entityCore
    );
  }

  /** GET Clin by id. Will 404 if id not found */
  get(request: SOGetClin): Observable<SOPluralClin> {
    return this.httpClient.get<SOPluralClin>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralClin, resp) }),
      tap(_ => this.log(`fetched Clin`)),
      catchError(this.handleError<SOPluralClin>(`get Clin`))
    );
  }

  /** POST: add a new Clin to the server */
  post(request: SOPostClin): Observable<SORespClin> {
    return this.httpClient.post<SORespClin>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespClin, resp) }),
      tap((response: SORespClin) => this.log(`added Clin w/ id=${response.uid}`)),
      catchError(this.handleError<SORespClin>('Clin post'))
    );
  }

  /** PUT: update Clin  */
  put(request: SOPutClin): Observable<SORespClin> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespClin>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespClin, resp) }),
      tap((response: SORespClin) => this.log(`edited Clin w/ uid=${uid}`)),
      catchError(this.handleError<SORespClin>('Clin put'))
    );
  }

  /** DELETE: delete the Clin from the server */
  delete(request: SODeleteClin): Observable<SORespClin> {
    return this.httpClient.delete<SORespClin>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Clin uid=${request.uid}`)),
      catchError(this.handleError<SORespClin>('delete Clin'))
    );
  }
}
