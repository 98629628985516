import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutQuotelet } from './so-put-quotelet';
import { SOGetQuotelet } from './so-get-quotelet';
import { SOPostQuotelet } from './so-post-quotelet';
import { SODeleteQuotelet } from './so-delete-quotelet';
import {SORespQuotelet} from './so-resp-quotelet';
import {SOPluralQuotelet} from './so-plural-quotelet';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOQuoteletService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'quotelet',
      entityCore
    );
  }

  /** GET Quotelet by id. Will 404 if id not found */
  get(request: SOGetQuotelet): Observable<SOPluralQuotelet> {
    return this.httpClient.get<SOPluralQuotelet>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralQuotelet, resp) }),
      tap(_ => this.log(`fetched Quotelet`)),
      catchError(this.handleError<SOPluralQuotelet>(`get Quotelet`))
    );
  }

  /** POST: add a new Quotelet to the server */
  post(request: SOPostQuotelet): Observable<SORespQuotelet> {
    return this.httpClient.post<SORespQuotelet>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespQuotelet, resp) }),
      tap((response: SORespQuotelet) => this.log(`added Quotelet w/ id=${response.uid}`)),
      catchError(this.handleError<SORespQuotelet>('Quotelet post'))
    );
  }

  /** PUT: update Quotelet  */
  put(request: SOPutQuotelet): Observable<SORespQuotelet> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespQuotelet>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespQuotelet, resp) }),
      tap((response: SORespQuotelet) => this.log(`edited Quotelet w/ uid=${uid}`)),
      catchError(this.handleError<SORespQuotelet>('Quotelet put'))
    );
  }

  /** DELETE: delete the Quotelet from the server */
  delete(request: SODeleteQuotelet): Observable<SORespQuotelet> {
    return this.httpClient.delete<SORespQuotelet>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Quotelet uid=${request.uid}`)),
      catchError(this.handleError<SORespQuotelet>('delete Quotelet'))
    );
  }
}
