import {SOPostTask} from '../../core/task/so-post-task';
import {TaskCoreBase} from './task.core.base';
import {SOGetTask} from '../../core/task/so-get-task';
import {FormField, SelectionOption} from 'lionheartland';
import {postTaskFormFieldOptions} from './post-task-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {TaskreportCore} from '../taskreport/taskreport.core';
import {SOGetTaskreport} from '../../core/taskreport/so-get-taskreport';
import {LoadCore} from '../load/load.core';
import {SOGetLoad} from '../../core/load/so-get-load';

export class RAPostTask extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTaskFormFieldOptions);

  RequestClass = SOPostTask

  constructor(
    public entityCore: TaskCoreBase
  ) {
    super();
    if (postTaskFormFieldOptions.taskreport) {
      postTaskFormFieldOptions.taskreport.makeCore = true;
    }
    if (postTaskFormFieldOptions.load) {
      postTaskFormFieldOptions.load.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'taskreport',
        SOGetTaskreport,
      ),
      this.getFormValueFromIdentifier('completed'),
      this.getFormValueFromIdentifier(
        'load',
        SOGetLoad,
      ),
      this.getFormValueFromIdentifier('loadStatus'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}