import { SOGetLoad } from '../load/so-get-load';
import { SOGetWeight } from '../weight/so-get-weight';
import { SOGetContainersession } from '../containersession/so-get-containersession';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetTask implements QueryProtocol {

  constructor(
    public uid: string = null,
    public completed: boolean = null,
    public load: SOGetLoad = null,
    public weight: SOGetWeight = null,
    public containersession: SOGetContainersession = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetTask(
      'uid' in params ? params.uid : null,
      'completed' in params ? params.completed : null,
      'load' in params ? params.load : null,
      'weight' in params ? params.weight : null,
      'containersession' in params ? params.containersession : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
