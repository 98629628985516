import {SOPostEvent} from '../../core/event/so-post-event';
import {EventCoreBase} from './event.core.base';
import {SOGetEvent} from '../../core/event/so-get-event';
import {FormField, SelectionOption} from 'lionheartland';
import {postEventFormFieldOptions} from './post-event-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostEvent extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postEventFormFieldOptions);

  RequestClass = SOPostEvent

  constructor(
    public entityCore: EventCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('title'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('eventType'),
      this.getFormValueFromIdentifier('start'),
      this.getFormValueFromIdentifier('end'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}