import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDistributionreport } from './so-put-distributionreport';
import { SOGetDistributionreport } from './so-get-distributionreport';
import { SOPostDistributionreport } from './so-post-distributionreport';
import { SODeleteDistributionreport } from './so-delete-distributionreport';
import {SORespDistributionreport} from './so-resp-distributionreport';
import {SOPluralDistributionreport} from './so-plural-distributionreport';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODistributionreportService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'distributionreport',
      entityCore
    );
  }

  /** GET Distributionreport by id. Will 404 if id not found */
  get(request: SOGetDistributionreport): Observable<SOPluralDistributionreport> {
    return this.httpClient.get<SOPluralDistributionreport>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDistributionreport, resp) }),
      tap(_ => this.log(`fetched Distributionreport`)),
      catchError(this.handleError<SOPluralDistributionreport>(`get Distributionreport`))
    );
  }

  /** POST: add a new Distributionreport to the server */
  post(request: SOPostDistributionreport): Observable<SORespDistributionreport> {
    return this.httpClient.post<SORespDistributionreport>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistributionreport, resp) }),
      tap((response: SORespDistributionreport) => this.log(`added Distributionreport w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDistributionreport>('Distributionreport post'))
    );
  }

  /** PUT: update Distributionreport  */
  put(request: SOPutDistributionreport): Observable<SORespDistributionreport> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDistributionreport>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistributionreport, resp) }),
      tap((response: SORespDistributionreport) => this.log(`edited Distributionreport w/ uid=${uid}`)),
      catchError(this.handleError<SORespDistributionreport>('Distributionreport put'))
    );
  }

  /** DELETE: delete the Distributionreport from the server */
  delete(request: SODeleteDistributionreport): Observable<SORespDistributionreport> {
    return this.httpClient.delete<SORespDistributionreport>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Distributionreport uid=${request.uid}`)),
      catchError(this.handleError<SORespDistributionreport>('delete Distributionreport'))
    );
  }
}
