import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTankreportService} from '../../core/tankreport/eo-tankreport';
import {SOGetTankreport} from '../../core/tankreport/so-get-tankreport';
import {RAPutTankreport} from './ra-put-tankreport';
import {RAPostTankreport} from './ra-post-tankreport';
import {RADeleteTankreport} from './ra-delete-tankreport';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETankreport } from "./ce-tankreport"


export class TankreportCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'tankreport';
  title = 'Tankreport';
  ceProtocol: CEConstructor = CETankreport

  entityService: EOTankreportService;
  soGetClass = SOGetTankreport;
  soGet: SOGetTankreport;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTankreportService
    );
    this.adapters = [
      new RAPostTankreport(this),
      new RAPutTankreport(this),
      new RADeleteTankreport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTankreport();
    this.soGet.queryOptions.limit = this.limit;
  }


}