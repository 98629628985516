import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTrailerauthService} from '../../core/trailerauth/eo-trailerauth';
import {SOGetTrailerauth} from '../../core/trailerauth/so-get-trailerauth';
import {RAPutTrailerauth} from './ra-put-trailerauth';
import {RAPostTrailerauth} from './ra-post-trailerauth';
import {RADeleteTrailerauth} from './ra-delete-trailerauth';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {SOGetAccount} from '../../core/account/so-get-account'
import { CETrailerauth } from "./ce-trailerauth"


export class TrailerauthCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'trailerauth';
  title = 'Trailerauth';
  ceProtocol: CEConstructor = CETrailerauth

  entityService: EOTrailerauthService;
  soGetClass = SOGetTrailerauth;
  soGet: SOGetTrailerauth;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTrailerauthService
    );
    this.adapters = [
      new RAPostTrailerauth(this),
      new RAPutTrailerauth(this),
      new RADeleteTrailerauth(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTrailerauth();
    if (this.activeUserService.getScope('account')) {
        this.soGet.account = new SOGetAccount(
          this.activeUserService.getScope('account').uid
        );
    }
    this.soGet.queryOptions.limit = this.limit;
  }


}