import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostProductFormFields {

    public name: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )

    }
}

const fields = new PostProductFormFields()

export const postProductFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
};