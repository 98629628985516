import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTankService} from '../../core/tank/eo-tank';
import {SOGetTank} from '../../core/tank/so-get-tank';
import {RAPutTank} from './ra-put-tank';
import {RAPostTank} from './ra-post-tank';
import {RADeleteTank} from './ra-delete-tank';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETank } from "./ce-tank"


export class TankCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'tank';
  title = 'Tank';
  ceProtocol: CEConstructor = CETank

  entityService: EOTankService;
  soGetClass = SOGetTank;
  soGet: SOGetTank;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTankService
    );
    this.adapters = [
      new RAPostTank(this),
      new RAPutTank(this),
      new RADeleteTank(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTank();
    this.soGet.queryOptions.limit = this.limit;
  }


}