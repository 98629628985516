import {SORespLinag} from '../../core/linag/so-resp-linag';
import {LinagCore} from './linag.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutLinagFormFields} from './put-linag-form-fields';
import {SOGetLinag} from '../../core/linag/so-get-linag';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetTank} from '../../core/tank/so-get-tank';


export class CELinagAttributesBase {

    name: DetailEntry
    identifier: DetailEntry
    type: DetailEntry
    tag: DetailEntry
    line: DetailEntry
    tank: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespLinag,
        app: GasDirectApp,
        formFields: PutLinagFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.name = new DetailEntry(
            'Name',
            entity.name,
            'name',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.name
        )

        this.identifier = new DetailEntry(
            'Identifier',
            entity.identifier,
            'identifier',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.identifier
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.type
        )


        const tagCore = app.makeCore( 'tag');
        tagCore.soGet = SOGetTag.construct({
        });
        let tagCeProtocol;
        if (entity.tag) {
            tagCeProtocol = createCEProtocol(
                tagCore.ceProtocol,
                parentEntityViewSpec,
                tagCore,
                entity.tag,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tagCore
        )
        tagCore.title = heading ? heading : tagCore.title
        tagCore.listTitle = 'Tag'

        if (entity.tag) {
            displayStringFactory.reconfigure(
                entity.tag,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        tagCore.configureForDisplay(parentEntityViewSpec)

        this.tag = new DetailEntry(
          heading ? heading : tagCore.listTitle,
          tagCeProtocol ? tagCeProtocol.entityListView.fullHeading : null,
          'tag',
          BaseDetailType.pushedDetail,
          tagCore,
          EditableType.always,
          formFields.tag
        )
        if (entity.tag) {
          this.tag.ceProtocol = tagCeProtocol;
          this.tag.cachedListEntry = tagCore.makeListEntry(parentEntityViewSpec, entity.tag, 0)
          tagCore.selectedEntity = entity.tag;
        }
        this.tag.singleFieldSelect = true

        const lineCore = app.makeCore( 'line');
        lineCore.soGet = SOGetLine.construct({
        });
        let lineCeProtocol;
        if (entity.line) {
            lineCeProtocol = createCEProtocol(
                lineCore.ceProtocol,
                parentEntityViewSpec,
                lineCore,
                entity.line,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            lineCore
        )
        lineCore.title = heading ? heading : lineCore.title
        lineCore.listTitle = 'Line'

        if (entity.line) {
            displayStringFactory.reconfigure(
                entity.line,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        lineCore.configureForDisplay(parentEntityViewSpec)

        this.line = new DetailEntry(
          heading ? heading : lineCore.listTitle,
          lineCeProtocol ? lineCeProtocol.entityListView.fullHeading : null,
          'line',
          BaseDetailType.pushedDetail,
          lineCore,
          EditableType.never
        )
        if (entity.line) {
          this.line.ceProtocol = lineCeProtocol;
          this.line.cachedListEntry = lineCore.makeListEntry(parentEntityViewSpec, entity.line, 0)
          lineCore.selectedEntity = entity.line;
        }
        this.line.singleFieldSelect = true

        const tankCore = app.makeCore( 'tank');
        tankCore.soGet = SOGetTank.construct({
        });
        let tankCeProtocol;
        if (entity.tank) {
            tankCeProtocol = createCEProtocol(
                tankCore.ceProtocol,
                parentEntityViewSpec,
                tankCore,
                entity.tank,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tankCore
        )
        tankCore.title = heading ? heading : tankCore.title
        tankCore.listTitle = 'Tank'

        if (entity.tank) {
            displayStringFactory.reconfigure(
                entity.tank,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        tankCore.configureForDisplay(parentEntityViewSpec)

        this.tank = new DetailEntry(
          heading ? heading : tankCore.listTitle,
          tankCeProtocol ? tankCeProtocol.entityListView.fullHeading : null,
          'tank',
          BaseDetailType.pushedDetail,
          tankCore,
          EditableType.never
        )
        if (entity.tank) {
          this.tank.ceProtocol = tankCeProtocol;
          this.tank.cachedListEntry = tankCore.makeListEntry(parentEntityViewSpec, entity.tank, 0)
          tankCore.selectedEntity = entity.tank;
        }
        this.tank.singleFieldSelect = true
    }
}