import {SORespMonthclose} from '../../core/monthclose/so-resp-monthclose';
import {MonthcloseCore} from './monthclose.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutMonthcloseFormFields} from './put-monthclose-form-fields';
import { CEMonthcloseAttributes } from './ce-monthclose-attributes'


export class CEMonthcloseBase extends CEBase {

    public details: CEMonthcloseAttributes;
    public fromFields: PutMonthcloseFormFields = new PutMonthcloseFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: MonthcloseCore,
        public entity: SORespMonthclose,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEMonthcloseAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.month)
        this.attributes.push(this.details.status)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.distribution)

    }
}