import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTrailerauthFormFields {

    public account: FormField
    public trailer: FormField

    constructor() {

        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.trailer = new FormField(
          'trailer',
          'Trailer',
          'trailer',
          FormFieldType.autoCompleteList,
          true,
        )

    }
}

const fields = new PostTrailerauthFormFields()

export const postTrailerauthFormFieldOptions: {[key: string]: FormField} = {
  trailer: fields.trailer,
};