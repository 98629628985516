import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOCarrierService} from '../../core/carrier/eo-carrier';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';
import {RAPutCarrier} from './ra-put-carrier';
import {RAPostCarrier} from './ra-post-carrier';
import {RADeleteCarrier} from './ra-delete-carrier';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CECarrier } from "./ce-carrier"


export class CarrierCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'carrier';
  title = 'Carrier';
  ceProtocol: CEConstructor = CECarrier

  entityService: EOCarrierService;
  soGetClass = SOGetCarrier;
  soGet: SOGetCarrier;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOCarrierService
    );
    this.adapters = [
      new RAPostCarrier(this),
      new RAPutCarrier(this),
      new RADeleteCarrier(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCarrier();
    this.soGet.queryOptions.limit = this.limit;
  }


}