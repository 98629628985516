import {SORespFeeschedule} from '../../core/feeschedule/so-resp-feeschedule';
import {FeescheduleCore} from './feeschedule.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeescheduleFormFields} from './put-feeschedule-form-fields';
import { CEFeescheduleAttributes } from './ce-feeschedule-attributes'


export class CEFeescheduleBase extends CEBase {

    public details: CEFeescheduleAttributes;
    public fromFields: PutFeescheduleFormFields = new PutFeescheduleFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: FeescheduleCore,
        public entity: SORespFeeschedule,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEFeescheduleAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.displayName)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.agreements)
        this.attributes.push(this.details.hyears)
        this.attributes.push(this.details.agreeyears)
        this.attributes.push(this.details.tempagreements)
        this.attributes.push(this.details.fees)
        this.attributes.push(this.details.distribution)
        this.attributes.push(this.details.feescheduleagreementascs)
        this.attributes.push(this.details.awards)

    }
}