import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutFeature } from './so-put-feature';
import { SOGetFeature } from './so-get-feature';
import { SOPostFeature } from './so-post-feature';
import { SODeleteFeature } from './so-delete-feature';
import {SORespFeature} from './so-resp-feature';
import {SOPluralFeature} from './so-plural-feature';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOFeatureService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'feature',
      entityCore
    );
  }

  /** GET Feature by id. Will 404 if id not found */
  get(request: SOGetFeature): Observable<SOPluralFeature> {
    return this.httpClient.get<SOPluralFeature>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralFeature, resp) }),
      tap(_ => this.log(`fetched Feature`)),
      catchError(this.handleError<SOPluralFeature>(`get Feature`))
    );
  }

  /** POST: add a new Feature to the server */
  post(request: SOPostFeature): Observable<SORespFeature> {
    return this.httpClient.post<SORespFeature>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFeature, resp) }),
      tap((response: SORespFeature) => this.log(`added Feature w/ id=${response.uid}`)),
      catchError(this.handleError<SORespFeature>('Feature post'))
    );
  }

  /** PUT: update Feature  */
  put(request: SOPutFeature): Observable<SORespFeature> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespFeature>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespFeature, resp) }),
      tap((response: SORespFeature) => this.log(`edited Feature w/ uid=${uid}`)),
      catchError(this.handleError<SORespFeature>('Feature put'))
    );
  }

  /** DELETE: delete the Feature from the server */
  delete(request: SODeleteFeature): Observable<SORespFeature> {
    return this.httpClient.delete<SORespFeature>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Feature uid=${request.uid}`)),
      catchError(this.handleError<SORespFeature>('delete Feature'))
    );
  }
}
