import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutHyearFormFields {

    public state: FormField
    public feeschedule: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )
        this.feeschedule = new FormField(
          'feeschedule',
          'Feeschedule',
          'feeschedule',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutHyearFormFields()

export const putHyearFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  feeschedule: fields.feeschedule,
};