import {SORespHmonth} from '../../core/hmonth/so-resp-hmonth';
import {HmonthCore} from './hmonth.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHmonthFormFields} from './put-hmonth-form-fields';
import {SOGetHmonth} from '../../core/hmonth/so-get-hmonth';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEHmonthAttributesBase {

    loads: DetailEntry
    state: DetailEntry
    mcq: DetailEntry
    mcqUsed: DetailEntry
    month: DetailEntry
    year: DetailEntry
    account: DetailEntry
    delta: DetailEntry
    hyear: DetailEntry
    mcqEstimated: DetailEntry
    spotQuantity: DetailEntry
    spotQuantityUsed: DetailEntry
    distributionmonth: DetailEntry
    allocatedLoads: DetailEntry
    ordersSubmitted: DetailEntry
    numberOfLoads: DetailEntry
    mcqInitial: DetailEntry
    agreemonths: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespHmonth,
        app: GasDirectApp,
        formFields: PutHmonthFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.state = new DetailEntry(
            'State',
            entity.state,
            'state',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.state
        )

        this.mcq = new DetailEntry(
            'Mcq',
            entity.mcq,
            'mcq',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.mcq
        )

        this.mcqUsed = new DetailEntry(
            'Mcq used',
            entity.mcqUsed,
            'mcqUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.month = new DetailEntry(
            'Month',
            entity.month,
            'month',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.month
        )
        this.month.listHeadingIndex = 2;

        this.year = new DetailEntry(
            'Year',
            entity.year,
            'year',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.year
        )
        this.year.listHeadingIndex = 1;

        this.delta = new DetailEntry(
            'Delta',
            entity.delta,
            'delta',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.delta
        )

        this.mcqEstimated = new DetailEntry(
            'Mcq estimated',
            entity.mcqEstimated,
            'mcqEstimated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.spotQuantity = new DetailEntry(
            'Spot quantity',
            entity.spotQuantity,
            'spotQuantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.spotQuantityUsed = new DetailEntry(
            'Spot quantity used',
            entity.spotQuantityUsed,
            'spotQuantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.allocatedLoads = new DetailEntry(
            'Allocated loads',
            entity.allocatedLoads,
            'allocatedLoads',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.ordersSubmitted = new DetailEntry(
            'Orders submitted',
            entity.ordersSubmitted,
            'ordersSubmitted',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.ordersSubmitted
        )

        this.numberOfLoads = new DetailEntry(
            'Number of loads',
            entity.numberOfLoads,
            'numberOfLoads',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.mcqInitial = new DetailEntry(
            'Mcq initial',
            entity.mcqInitial,
            'mcqInitial',
            BaseDetailType.text,
            null,
            EditableType.never,
        )


        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          hmonth: new SOGetHmonth(entity.uid),
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.never
        )

        const accountCore = app.makeCore( 'account');
        accountCore.soGet = SOGetAccount.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        let accountCeProtocol;
        if (entity.account) {
            accountCeProtocol = createCEProtocol(
                accountCore.ceProtocol,
                parentEntityViewSpec,
                accountCore,
                entity.account,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            accountCore
        )
        accountCore.title = heading ? heading : accountCore.title
        accountCore.listTitle = 'Account'

        if (entity.account) {
            displayStringFactory.reconfigure(
                entity.account,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        accountCore.configureForDisplay(parentEntityViewSpec)

        this.account = new DetailEntry(
          heading ? heading : accountCore.listTitle,
          accountCeProtocol ? accountCeProtocol.entityListView.fullHeading : null,
          'account',
          BaseDetailType.pushedDetail,
          accountCore,
          EditableType.always,
          formFields.account
        )
        if (entity.account) {
          this.account.ceProtocol = accountCeProtocol;
          this.account.cachedListEntry = accountCore.makeListEntry(parentEntityViewSpec, entity.account, 0)
          accountCore.selectedEntity = entity.account;
        }
        this.account.singleFieldSelect = true
        this.account.listHeadingIndex = 0;

        const hyearCore = app.makeCore( 'hyear');
        hyearCore.soGet = SOGetHyear.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        hyearCore.soGet.queryOptions.sortKey = 'year';
        let hyearCeProtocol;
        if (entity.hyear) {
            hyearCeProtocol = createCEProtocol(
                hyearCore.ceProtocol,
                parentEntityViewSpec,
                hyearCore,
                entity.hyear,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hyearCore
        )
        hyearCore.title = heading ? heading : hyearCore.title
        hyearCore.listTitle = 'Hyear'

        if (entity.hyear) {
            displayStringFactory.reconfigure(
                entity.hyear,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        hyearCore.configureForDisplay(parentEntityViewSpec)

        this.hyear = new DetailEntry(
          heading ? heading : hyearCore.listTitle,
          hyearCeProtocol ? hyearCeProtocol.entityListView.fullHeading : null,
          'hyear',
          BaseDetailType.pushedDetail,
          hyearCore,
          EditableType.always,
          formFields.hyear
        )
        if (entity.hyear) {
          this.hyear.ceProtocol = hyearCeProtocol;
          this.hyear.cachedListEntry = hyearCore.makeListEntry(parentEntityViewSpec, entity.hyear, 0)
          hyearCore.selectedEntity = entity.hyear;
        }
        this.hyear.singleFieldSelect = true

        const distributionmonthCore = app.makeCore( 'distributionmonth');
        distributionmonthCore.soGet = SOGetDistributionmonth.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributionmonthCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionmonthCore
        )
        distributionmonthCore.title = heading ? heading : distributionmonthCore.title
        distributionmonthCore.listTitle = 'Distributionmonth'

        distributionmonthCore.configureForDisplay(parentEntityViewSpec)

        this.distributionmonth = new DetailEntry(
          heading ? heading : distributionmonthCore.listTitle,
          null,
          'distributionmonth',
          BaseDetailType.pushedDetail,
          distributionmonthCore,
          EditableType.never
        )
        this.distributionmonth.singleFieldSelect = true

        const agreemonthsCore = app.makeCore( 'agreemonth');
        agreemonthsCore.soGet = SOGetAgreemonth.construct({
          hmonth: new SOGetHmonth(entity.uid),
        });
        agreemonthsCore.soGet.queryOptions.sortKey = 'month';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreemonthsCore
        )
        agreemonthsCore.title = heading ? heading : agreemonthsCore.title
        agreemonthsCore.listTitle = 'Agreemonths'

        agreemonthsCore.configureForDisplay(parentEntityViewSpec)

        this.agreemonths = new DetailEntry(
          heading ? heading : agreemonthsCore.listTitle,
          null,
          'agreemonths',
          BaseDetailType.embeddedList,
          agreemonthsCore,
          EditableType.never
        )
    }
}