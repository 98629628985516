import {SORespItem} from '../../core/item/so-resp-item';
import {ItemCore} from './item.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutItemFormFields} from './put-item-form-fields';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetTempclin} from '../../core/tempclin/so-get-tempclin';
import {SOGetOffering} from '../../core/offering/so-get-offering';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';


export class CEItemAttributesBase {

    quantity: DetailEntry
    order: DetailEntry
    tempclin: DetailEntry
    offering: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespItem,
        app: GasDirectApp,
        formFields: PutItemFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.quantity
        )


        const orderCore = app.makeCore( 'order');
        orderCore.soGet = SOGetOrder.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          agreement: app.getScope('agreement') ? new SOGetAgreement(app.getScope('agreement').uid) : null,
          agreemonth: app.getScope('agreemonth') ? new SOGetAgreemonth(app.getScope('agreemonth').uid) : null,
          distributionmonth: app.getScope('distributionmonth') ? new SOGetDistributionmonth(app.getScope('distributionmonth').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            orderCore
        )
        orderCore.title = heading ? heading : orderCore.title
        orderCore.listTitle = 'Order'

        orderCore.configureForDisplay(parentEntityViewSpec)

        this.order = new DetailEntry(
          heading ? heading : orderCore.listTitle,
          null,
          'order',
          BaseDetailType.pushedDetail,
          orderCore,
          EditableType.always,
          formFields.order
        )
        this.order.singleFieldSelect = true

        const tempclinCore = app.makeCore( 'tempclin');
        tempclinCore.soGet = SOGetTempclin.construct({
        });
        let tempclinCeProtocol;
        if (entity.tempclin) {
            tempclinCeProtocol = createCEProtocol(
                tempclinCore.ceProtocol,
                parentEntityViewSpec,
                tempclinCore,
                entity.tempclin,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempclinCore
        )
        tempclinCore.title = heading ? heading : tempclinCore.title
        tempclinCore.listTitle = 'Tempclin'

        if (entity.tempclin) {
            displayStringFactory.reconfigure(
                entity.tempclin,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        tempclinCore.configureForDisplay(parentEntityViewSpec)

        this.tempclin = new DetailEntry(
          heading ? heading : tempclinCore.listTitle,
          tempclinCeProtocol ? tempclinCeProtocol.entityListView.fullHeading : null,
          'tempclin',
          BaseDetailType.pushedDetail,
          tempclinCore,
          EditableType.always,
          formFields.tempclin
        )
        if (entity.tempclin) {
          this.tempclin.ceProtocol = tempclinCeProtocol;
          this.tempclin.cachedListEntry = tempclinCore.makeListEntry(parentEntityViewSpec, entity.tempclin, 0)
          tempclinCore.selectedEntity = entity.tempclin;
        }
        this.tempclin.singleFieldSelect = true

        const offeringCore = app.makeCore( 'offering');
        offeringCore.soGet = SOGetOffering.construct({
        });
        let offeringCeProtocol;
        if (entity.offering) {
            offeringCeProtocol = createCEProtocol(
                offeringCore.ceProtocol,
                parentEntityViewSpec,
                offeringCore,
                entity.offering,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            offeringCore
        )
        offeringCore.title = heading ? heading : offeringCore.title
        offeringCore.listTitle = 'Offering'

        if (entity.offering) {
            displayStringFactory.reconfigure(
                entity.offering,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        offeringCore.configureForDisplay(parentEntityViewSpec)

        this.offering = new DetailEntry(
          heading ? heading : offeringCore.listTitle,
          offeringCeProtocol ? offeringCeProtocol.entityListView.fullHeading : null,
          'offering',
          BaseDetailType.pushedDetail,
          offeringCore,
          EditableType.always,
          formFields.offering
        )
        if (entity.offering) {
          this.offering.ceProtocol = offeringCeProtocol;
          this.offering.cachedListEntry = offeringCore.makeListEntry(parentEntityViewSpec, entity.offering, 0)
          offeringCore.selectedEntity = entity.offering;
        }
        this.offering.singleFieldSelect = true
    }
}