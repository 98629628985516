import {SORespBaysession} from '../../core/baysession/so-resp-baysession';
import {BaysessionCore} from './baysession.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutBaysessionFormFields} from './put-baysession-form-fields';
import { CEBaysessionAttributes } from './ce-baysession-attributes'


export class CEBaysessionBase extends CEBase {

    public details: CEBaysessionAttributes;
    public fromFields: PutBaysessionFormFields = new PutBaysessionFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: BaysessionCore,
        public entity: SORespBaysession,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEBaysessionAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.start)
        this.attributes.push(this.details.end)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.load)

    }
}