import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutClinyearlngFormFields {


    constructor() {


    }
}

const fields = new PutClinyearlngFormFields()

export const putClinyearlngFormFieldOptions: {[key: string]: FormField} = {
};