import {SORespHestatus} from '../../core/hestatus/so-resp-hestatus';
import {HestatusCore} from './hestatus.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutHestatusFormFields} from './put-hestatus-form-fields';
import { CEHestatusAttributes } from './ce-hestatus-attributes'


export class CEHestatusBase extends CEBase {

    public details: CEHestatusAttributes;
    public fromFields: PutHestatusFormFields = new PutHestatusFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: HestatusCore,
        public entity: SORespHestatus,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEHestatusAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.state)
        this.attributes.push(this.details.distributionday)
        this.attributes.push(this.details.hestatusitems)

    }
}