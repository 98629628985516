import { SOGetLoad } from '../load/so-get-load';
import { SOGetDistribution } from '../distribution/so-get-distribution';
import { SOGetChecklistitem } from '../checklistitem/so-get-checklistitem';


export class SOPutChecklist {

  constructor(
    public uid: string,
    public body: string = null,
    public name: string = null,
    public identifier: string = null,
    public load: SOGetLoad = null,
    public distribution: SOGetDistribution = null,
    public fileLink: string = null,
    public items: SOGetChecklistitem[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutChecklist(
      'uid' in params ? params.uid : null,
      'body' in params ? params.body : null,
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'load' in params ? params.load : null,
      'distribution' in params ? params.distribution : null,
      'fileLink' in params ? params.fileLink : null,
      'items' in params ? params.items : null,
    );
  }
}
