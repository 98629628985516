import { SOGetDistributionyear } from '../distributionyear/so-get-distributionyear';
import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostDistributionmonth {

  constructor(
    public month: number,
    public distributionyear: SOGetDistributionyear,
    public distribution: SOGetDistribution,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDistributionmonth(
      'month' in params ? params.month : null,
      'distributionyear' in params ? params.distributionyear : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
