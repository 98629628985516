import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostDistributionmonthFormFields {

    public month: FormField
    public distributionyear: FormField
    public distribution: FormField

    constructor() {

        this.month = new FormField(
          'month',
          'Month',
          null,
          FormFieldType.text,
          true,
        )
        this.distributionyear = new FormField(
          'distributionyear',
          'Distributionyear',
          'distributionyear',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostDistributionmonthFormFields()

export const postDistributionmonthFormFieldOptions: {[key: string]: FormField} = {
  month: fields.month,
  distributionyear: fields.distributionyear,
};