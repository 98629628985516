import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTransactionFormFields {

    public dateCreated: FormField
    public description: FormField
    public amount: FormField
    public account: FormField
    public order: FormField
    public load: FormField
    public finalLoadCost: FormField
    public reconciliationAdjustment: FormField
    public transactionType: FormField
    public invoice: FormField

    constructor() {

        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.amount = new FormField(
          'amount',
          'Amount',
          null,
          FormFieldType.text,
          true,
        )
        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.order = new FormField(
          'order',
          'Order',
          'order',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.finalLoadCost = new FormField(
          'finalLoadCost',
          'Final load cost',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.reconciliationAdjustment = new FormField(
          'reconciliationAdjustment',
          'Reconciliation adjustment',
          null,
          FormFieldType.boolean,
          false,
          true,
          false
        )
        this.transactionType = new FormField(
          'transactionType',
          'Transaction type',
          null,
          FormFieldType.text,
          false,
        )
        this.invoice = new FormField(
          'invoice',
          'Invoice',
          'invoice',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostTransactionFormFields()

export const postTransactionFormFieldOptions: {[key: string]: FormField} = {
  dateCreated: fields.dateCreated,
  description: fields.description,
  amount: fields.amount,
  order: fields.order,
  load: fields.load,
  finalLoadCost: fields.finalLoadCost,
  reconciliationAdjustment: fields.reconciliationAdjustment,
  transactionType: fields.transactionType,
  invoice: fields.invoice,
};