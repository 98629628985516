import { SOGetOffering } from '../offering/so-get-offering';
import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPutDistoffer {

  constructor(
    public uid: string,
    public offering: SOGetOffering = null,
    public distribution: SOGetDistribution = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDistoffer(
      'uid' in params ? params.uid : null,
      'offering' in params ? params.offering : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
