import {SOPutTankreport} from '../../core/tankreport/so-put-tankreport';
import {TankreportCoreBase} from './tankreport.core.base';
import {SOGetTankreport} from '../../core/tankreport/so-get-tankreport';
import {FormField, SelectionOption} from 'lionheartland';
import {putTankreportFormFieldOptions} from './put-tankreport-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {TankCore} from '../tank/tank.core';
import {SOGetTank} from '../../core/tank/so-get-tank';

export class RAPutTankreport extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTankreportFormFieldOptions);

  RequestClass = SOPutTankreport

  constructor(
    public entityCore: TankreportCoreBase
  ) {
    super();
    if (putTankreportFormFieldOptions.tank) {
      putTankreportFormFieldOptions.tank.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('dateCreated'),
      this.getFormValueFromIdentifier('value'),
      this.getFormValueFromIdentifier(
        'tank',
        SOGetTank,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}