import {SORespFeeschedule} from '../../core/feeschedule/so-resp-feeschedule';
import {FeescheduleCore} from './feeschedule.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutFeescheduleFormFields} from './put-feeschedule-form-fields';
import {SOGetFeeschedule} from '../../core/feeschedule/so-get-feeschedule';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';
import {SOGetTempagreement} from '../../core/tempagreement/so-get-tempagreement';
import {SOGetFee} from '../../core/fee/so-get-fee';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-get-feescheduleagreementasc';
import {SOGetAward} from '../../core/award/so-get-award';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CEFeescheduleAttributesBase {

    agreements: DetailEntry
    hyears: DetailEntry
    agreeyears: DetailEntry
    tempagreements: DetailEntry
    fees: DetailEntry
    displayName: DetailEntry
    distribution: DetailEntry
    start: DetailEntry
    end: DetailEntry
    feescheduleagreementascs: DetailEntry
    awards: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespFeeschedule,
        app: GasDirectApp,
        formFields: PutFeescheduleFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.displayName = new DetailEntry(
            'Display name',
            entity.displayName,
            'displayName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.displayName
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )


        const agreementsCore = app.makeCore( 'agreement');
        agreementsCore.soGet = SOGetAgreement.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreementsCore
        )
        agreementsCore.title = heading ? heading : agreementsCore.title
        agreementsCore.listTitle = 'Agreements'

        agreementsCore.configureForDisplay(parentEntityViewSpec)

        this.agreements = new DetailEntry(
          heading ? heading : agreementsCore.listTitle,
          null,
          'agreements',
          BaseDetailType.embeddedList,
          agreementsCore,
          EditableType.never
        )

        const hyearsCore = app.makeCore( 'hyear');
        hyearsCore.soGet = SOGetHyear.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        hyearsCore.soGet.queryOptions.sortKey = 'year';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            hyearsCore
        )
        hyearsCore.title = heading ? heading : hyearsCore.title
        hyearsCore.listTitle = 'Hyears'

        hyearsCore.configureForDisplay(parentEntityViewSpec)

        this.hyears = new DetailEntry(
          heading ? heading : hyearsCore.listTitle,
          null,
          'hyears',
          BaseDetailType.embeddedList,
          hyearsCore,
          EditableType.never
        )

        const agreeyearsCore = app.makeCore( 'agreeyear');
        agreeyearsCore.soGet = SOGetAgreeyear.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreeyearsCore
        )
        agreeyearsCore.title = heading ? heading : agreeyearsCore.title
        agreeyearsCore.listTitle = 'Agreeyears'

        agreeyearsCore.configureForDisplay(parentEntityViewSpec)

        this.agreeyears = new DetailEntry(
          heading ? heading : agreeyearsCore.listTitle,
          null,
          'agreeyears',
          BaseDetailType.embeddedList,
          agreeyearsCore,
          EditableType.never
        )

        const tempagreementsCore = app.makeCore( 'tempagreement');
        tempagreementsCore.soGet = SOGetTempagreement.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tempagreementsCore
        )
        tempagreementsCore.title = heading ? heading : tempagreementsCore.title
        tempagreementsCore.listTitle = 'Tempagreements'

        tempagreementsCore.configureForDisplay(parentEntityViewSpec)

        this.tempagreements = new DetailEntry(
          heading ? heading : tempagreementsCore.listTitle,
          null,
          'tempagreements',
          BaseDetailType.embeddedList,
          tempagreementsCore,
          EditableType.never
        )

        const feesCore = app.makeCore( 'fee');
        feesCore.soGet = SOGetFee.construct({
          feeschedule: new SOGetFeeschedule(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feesCore
        )
        feesCore.title = heading ? heading : feesCore.title
        feesCore.listTitle = 'Fees'

        feesCore.configureForDisplay(parentEntityViewSpec)

        this.fees = new DetailEntry(
          heading ? heading : feesCore.listTitle,
          null,
          'fees',
          BaseDetailType.embeddedList,
          feesCore,
          EditableType.never
        )

        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.never
        )
        this.distribution.singleFieldSelect = true

        const feescheduleagreementascsCore = app.makeCore( 'feescheduleagreementasc');
        feescheduleagreementascsCore.soGet = SOGetFeescheduleagreementasc.construct({
          feeschedule: new SOGetFeeschedule(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            feescheduleagreementascsCore
        )
        feescheduleagreementascsCore.title = heading ? heading : feescheduleagreementascsCore.title
        feescheduleagreementascsCore.listTitle = 'Feescheduleagreementascs'

        feescheduleagreementascsCore.configureForDisplay(parentEntityViewSpec)

        this.feescheduleagreementascs = new DetailEntry(
          heading ? heading : feescheduleagreementascsCore.listTitle,
          null,
          'feescheduleagreementascs',
          BaseDetailType.embeddedList,
          feescheduleagreementascsCore,
          EditableType.never
        )

        const awardsCore = app.makeCore( 'award');
        awardsCore.soGet = SOGetAward.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            awardsCore
        )
        awardsCore.title = heading ? heading : awardsCore.title
        awardsCore.listTitle = 'Awards'

        awardsCore.configureForDisplay(parentEntityViewSpec)

        this.awards = new DetailEntry(
          heading ? heading : awardsCore.listTitle,
          null,
          'awards',
          BaseDetailType.embeddedList,
          awardsCore,
          EditableType.never
        )
    }
}