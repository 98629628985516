import { SOGetRole } from '../role/so-get-role';
import { SOGetNavi } from '../navi/so-get-navi';


export class SOPostNavroa {

  constructor(
    public role: SOGetRole,
    public navi: SOGetNavi,
    public index: number = null,
    public mainNav: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostNavroa(
      'index' in params ? params.index : null,
      'mainNav' in params ? params.mainNav : null,
      'role' in params ? params.role : null,
      'navi' in params ? params.navi : null,
    );
  }
}
