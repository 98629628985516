import {SOPostOrder} from '../../core/order/so-post-order';
import {OrderCoreBase} from './order.core.base';
import {SOGetOrder} from '../../core/order/so-get-order';
import {FormField, SelectionOption} from 'lionheartland';
import {postOrderFormFieldOptions} from './post-order-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {AgreementCore} from '../agreement/agreement.core';
import {SOGetAgreement} from '../../core/agreement/so-get-agreement';
import {AgreemonthCore} from '../agreemonth/agreemonth.core';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {DistributionmonthCore} from '../distributionmonth/distributionmonth.core';
import {SOGetDistributionmonth} from '../../core/distributionmonth/so-get-distributionmonth';

export class RAPostOrder extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postOrderFormFieldOptions);

  RequestClass = SOPostOrder

  constructor(
    public entityCore: OrderCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier(
        'agreement',
        SOGetAgreement,
        true,
      ),
      this.getFormValueFromIdentifier(
        'agreemonth',
        SOGetAgreemonth,
        true,
      ),
      this.getFormValueFromIdentifier(
        'distributionmonth',
        SOGetDistributionmonth,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}