import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTransaction } from './so-put-transaction';
import { SOGetTransaction } from './so-get-transaction';
import { SOPostTransaction } from './so-post-transaction';
import { SODeleteTransaction } from './so-delete-transaction';
import {SORespTransaction} from './so-resp-transaction';
import {SOPluralTransaction} from './so-plural-transaction';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTransactionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'transaction',
      entityCore
    );
  }

  /** GET Transaction by id. Will 404 if id not found */
  get(request: SOGetTransaction): Observable<SOPluralTransaction> {
    return this.httpClient.get<SOPluralTransaction>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTransaction, resp) }),
      tap(_ => this.log(`fetched Transaction`)),
      catchError(this.handleError<SOPluralTransaction>(`get Transaction`))
    );
  }

  /** POST: add a new Transaction to the server */
  post(request: SOPostTransaction): Observable<SORespTransaction> {
    return this.httpClient.post<SORespTransaction>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTransaction, resp) }),
      tap((response: SORespTransaction) => this.log(`added Transaction w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTransaction>('Transaction post'))
    );
  }

  /** PUT: update Transaction  */
  put(request: SOPutTransaction): Observable<SORespTransaction> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTransaction>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTransaction, resp) }),
      tap((response: SORespTransaction) => this.log(`edited Transaction w/ uid=${uid}`)),
      catchError(this.handleError<SORespTransaction>('Transaction put'))
    );
  }

  /** DELETE: delete the Transaction from the server */
  delete(request: SODeleteTransaction): Observable<SORespTransaction> {
    return this.httpClient.delete<SORespTransaction>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Transaction uid=${request.uid}`)),
      catchError(this.handleError<SORespTransaction>('delete Transaction'))
    );
  }
}
