import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutImpurityFormFields {

    public state: FormField
    public h20: FormField
    public neon: FormField
    public h2: FormField
    public o2: FormField
    public n2: FormField
    public carbonMonoxide: FormField
    public hydrocarbons: FormField
    public carbonDioxide: FormField
    public load: FormField

    constructor() {

        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
        )
        this.h20 = new FormField(
          'h20',
          'H20',
          null,
          FormFieldType.text,
          false,
        )
        this.neon = new FormField(
          'neon',
          'Neon',
          null,
          FormFieldType.text,
          false,
        )
        this.h2 = new FormField(
          'h2',
          'H2',
          null,
          FormFieldType.text,
          false,
        )
        this.o2 = new FormField(
          'o2',
          'O2',
          null,
          FormFieldType.text,
          false,
        )
        this.n2 = new FormField(
          'n2',
          'N2',
          null,
          FormFieldType.text,
          false,
        )
        this.carbonMonoxide = new FormField(
          'carbonMonoxide',
          'Carbon monoxide',
          null,
          FormFieldType.text,
          false,
        )
        this.hydrocarbons = new FormField(
          'hydrocarbons',
          'Hydrocarbons',
          null,
          FormFieldType.text,
          false,
        )
        this.carbonDioxide = new FormField(
          'carbonDioxide',
          'Carbon dioxide',
          null,
          FormFieldType.text,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )

    }
}

const fields = new PutImpurityFormFields()

export const putImpurityFormFieldOptions: {[key: string]: FormField} = {
  state: fields.state,
  h20: fields.h20,
  neon: fields.neon,
  h2: fields.h2,
  o2: fields.o2,
  n2: fields.n2,
  carbonMonoxide: fields.carbonMonoxide,
  hydrocarbons: fields.hydrocarbons,
  carbonDioxide: fields.carbonDioxide,
  load: fields.load,
};