import { SOGetGrant } from '../grant/so-get-grant';
import { SOGetUser } from '../user/so-get-user';


export class SOPostToken {

  constructor(
    public roleGrant: SOGetGrant = null,
    public user: SOGetUser = null,
    public accessToken: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostToken(
      'roleGrant' in params ? params.roleGrant : null,
      'user' in params ? params.user : null,
      'accessToken' in params ? params.accessToken : null,
    );
  }
}
