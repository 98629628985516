import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTrailerFormFields {

    public chassisId: FormField
    public identifier: FormField
    public type: FormField
    public size: FormField
    public dotId: FormField
    public dotInspectionDate: FormField
    public dotE: FormField
    public dotSp: FormField
    public mawp: FormField
    public organization: FormField
    public requestingAccount: FormField
    public fillVolume: FormField
    public mawt: FormField

    constructor() {

        this.chassisId = new FormField(
          'chassisId',
          'Chassis id',
          null,
          FormFieldType.text,
          false,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )
        this.size = new FormField(
          'size',
          'Size',
          null,
          FormFieldType.text,
          true,
        )
        this.dotId = new FormField(
          'dotId',
          'Dot id',
          null,
          FormFieldType.text,
          false,
        )
        this.dotInspectionDate = new FormField(
          'dotInspectionDate',
          'Dot inspection date',
          null,
          FormFieldType.datetime,
          false,
        )
        this.dotE = new FormField(
          'dotE',
          'Dot e',
          null,
          FormFieldType.text,
          false,
        )
        this.dotSp = new FormField(
          'dotSp',
          'Dot sp',
          null,
          FormFieldType.text,
          false,
        )
        this.mawp = new FormField(
          'mawp',
          'Mawp',
          null,
          FormFieldType.text,
          false,
        )
        this.organization = new FormField(
          'organization',
          'Organization',
          'organization',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.requestingAccount = new FormField(
          'requestingAccount',
          'Requesting account',
          null,
          FormFieldType.text,
          false,
        )
        this.fillVolume = new FormField(
          'fillVolume',
          'Fill volume',
          null,
          FormFieldType.text,
          false,
        )
        this.mawt = new FormField(
          'mawt',
          'Mawt',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PostTrailerFormFields()

export const postTrailerFormFieldOptions: {[key: string]: FormField} = {
  chassisId: fields.chassisId,
  identifier: fields.identifier,
  type: fields.type,
  size: fields.size,
  dotId: fields.dotId,
  dotInspectionDate: fields.dotInspectionDate,
  dotE: fields.dotE,
  dotSp: fields.dotSp,
  mawp: fields.mawp,
  organization: fields.organization,
  requestingAccount: fields.requestingAccount,
  fillVolume: fields.fillVolume,
  mawt: fields.mawt,
};