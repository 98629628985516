import {SORespActivity} from '../../core/activity/so-resp-activity';
import {ActivityCore} from './activity.core';
import {CEBase} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutActivityFormFields} from './put-activity-form-fields';
import { CEActivityAttributes } from './ce-activity-attributes'


export class CEActivityBase extends CEBase {

    public details: CEActivityAttributes;
    public fromFields: PutActivityFormFields = new PutActivityFormFields();

    constructor(
        public parentEntityViewSpec: EntityViewSpecProtocol,
        public core: ActivityCore,
        public entity: SORespActivity,
        public app: GasDirectApp,
    ) {
        super(
            parentEntityViewSpec, core, entity, app,
        )
        this.details = new CEActivityAttributes(
            parentEntityViewSpec,
            entity,
            app,
            this.fromFields
        );
        this.setAttributes();
        this.sortAttributes();
    }

    setAttributes() {

        this.attributes = []
        this.attributes.push(this.details.fixmeUser)
        this.attributes.push(this.details.type)
        this.attributes.push(this.details.summary)
        this.attributes.push(this.details.body)
        this.attributes.push(this.details.dateCreated)
        this.attributes.push(this.details.messages)
        this.attributes.push(this.details.order)
        this.attributes.push(this.details.load)
        this.attributes.push(this.details.user)
        this.attributes.push(this.details.containersession)
        this.attributes.push(this.details.slot)

    }
}