import { Component, OnInit } from '@angular/core';
import {SvNavigatorComponent} from "lionheartland";

@Component({
  selector: 'app-gas-direct-navigator',
  templateUrl: './gas-direct-navigator.component.html',
  styleUrls: ['./gas-direct-navigator.component.scss']
})
export class GasDirectNavigatorComponent extends SvNavigatorComponent {


}
