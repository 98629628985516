import {SOPutFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-put-feescheduleagreementasc';
import {FeescheduleagreementascCoreBase} from './feescheduleagreementasc.core.base';
import {SOGetFeescheduleagreementasc} from '../../core/feescheduleagreementasc/so-get-feescheduleagreementasc';
import {FormField, SelectionOption} from 'lionheartland';
import {putFeescheduleagreementascFormFieldOptions} from './put-feescheduleagreementasc-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';

export class RAPutFeescheduleagreementasc extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putFeescheduleagreementascFormFieldOptions);

  RequestClass = SOPutFeescheduleagreementasc

  constructor(
    public entityCore: FeescheduleagreementascCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}