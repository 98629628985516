import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTempriceService} from '../../core/temprice/eo-temprice';
import {SOGetTemprice} from '../../core/temprice/so-get-temprice';
import {RAPutTemprice} from './ra-put-temprice';
import {RAPostTemprice} from './ra-post-temprice';
import {RADeleteTemprice} from './ra-delete-temprice';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETemprice } from "./ce-temprice"


export class TempriceCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'temprice';
  title = 'Temprice';
  ceProtocol: CEConstructor = CETemprice

  entityService: EOTempriceService;
  soGetClass = SOGetTemprice;
  soGet: SOGetTemprice;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTempriceService
    );
    this.adapters = [
      new RAPostTemprice(this),
      new RAPutTemprice(this),
      new RADeleteTemprice(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTemprice();
    this.soGet.queryOptions.limit = this.limit;
  }


}