import { SOGetLine } from '../line/so-get-line';


export class SOPostDistribution {

  constructor(
    public name: string,
    public line: SOGetLine,
    public type: string = "normal",
    public marketIndexId: string = null,
    public conversionRate: number = 0,
    public galToWeight: number = 0,
    public defaultSlotsPerDay: number = 6,
    public providerSlotDurationHours: number = 4,
    public customerSlotDurationHours: number = 2,
    public showSlotDuration: boolean = true,
    public pickupInstructions: string = null,
    public paymentInstructions: string = null,
    public checkoutText: string = null,
    public purchaseCompleteText: string = null,
    public bolHeader: string = "",
    public bolWarning: string = "",
    public loadingChecklist: string = "",
    public departureChecklist: string = "",
    public nextLoadNumber: number = 1,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDistribution(
      'name' in params ? params.name : null,
      'type' in params ? params.type : null,
      'marketIndexId' in params ? params.marketIndexId : null,
      'conversionRate' in params ? params.conversionRate : null,
      'galToWeight' in params ? params.galToWeight : null,
      'defaultSlotsPerDay' in params ? params.defaultSlotsPerDay : null,
      'providerSlotDurationHours' in params ? params.providerSlotDurationHours : null,
      'customerSlotDurationHours' in params ? params.customerSlotDurationHours : null,
      'showSlotDuration' in params ? params.showSlotDuration : null,
      'pickupInstructions' in params ? params.pickupInstructions : null,
      'paymentInstructions' in params ? params.paymentInstructions : null,
      'checkoutText' in params ? params.checkoutText : null,
      'purchaseCompleteText' in params ? params.purchaseCompleteText : null,
      'line' in params ? params.line : null,
      'bolHeader' in params ? params.bolHeader : null,
      'bolWarning' in params ? params.bolWarning : null,
      'loadingChecklist' in params ? params.loadingChecklist : null,
      'departureChecklist' in params ? params.departureChecklist : null,
      'nextLoadNumber' in params ? params.nextLoadNumber : null,
    );
  }
}
