import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespFeeschedule implements ObjectiveProtocol {

  entityType: string = 'feeschedule';
  uid: string;
  displayName: string;
  @Type(() => Date)
  start: Date = null;
  @Type(() => Date)
  end: Date = null;
}