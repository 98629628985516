import {SOPostRole} from '../../core/role/so-post-role';
import {RoleCoreBase} from './role.core.base';
import {SOGetRole} from '../../core/role/so-get-role';
import {FormField, SelectionOption} from 'lionheartland';
import {postRoleFormFieldOptions} from './post-role-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {PlantCore} from '../plant/plant.core';
import {SOGetPlant} from '../../core/plant/so-get-plant';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {CarrierCore} from '../carrier/carrier.core';
import {SOGetCarrier} from '../../core/carrier/so-get-carrier';

export class RAPostRole extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRoleFormFieldOptions);

  RequestClass = SOPostRole

  constructor(
    public entityCore: RoleCoreBase
  ) {
    super();
    if (postRoleFormFieldOptions.plant) {
      postRoleFormFieldOptions.plant.makeCore = true;
    }
    if (postRoleFormFieldOptions.account) {
      postRoleFormFieldOptions.account.makeCore = true;
    }
    if (postRoleFormFieldOptions.carrier) {
      postRoleFormFieldOptions.carrier.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
      this.getFormValueFromIdentifier(
        'plant',
        SOGetPlant,
      ),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
        true,
      ),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
      ),
      this.getFormValueFromIdentifier(
        'carrier',
        SOGetCarrier,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}