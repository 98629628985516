import {SOPostFeature} from '../../core/feature/so-post-feature';
import {FeatureCoreBase} from './feature.core.base';
import {SOGetFeature} from '../../core/feature/so-get-feature';
import {FormField, SelectionOption} from 'lionheartland';
import {postFeatureFormFieldOptions} from './post-feature-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';

export class RAPostFeature extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postFeatureFormFieldOptions);

  RequestClass = SOPostFeature

  constructor(
    public entityCore: FeatureCoreBase
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('component'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('status'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}