import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostDistributiondayFormFields {

    public day: FormField
    public distributionmonth: FormField
    public distribution: FormField

    constructor() {

        this.day = new FormField(
          'day',
          'Day',
          null,
          FormFieldType.text,
          true,
        )
        this.distributionmonth = new FormField(
          'distributionmonth',
          'Distributionmonth',
          'distributionmonth',
          FormFieldType.pushedSelectionList,
          true,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostDistributiondayFormFields()

export const postDistributiondayFormFieldOptions: {[key: string]: FormField} = {
  day: fields.day,
  distributionmonth: fields.distributionmonth,
};