import {SORespDelta} from '../../core/delta/so-resp-delta';
import {DeltaCore} from './delta.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutDeltaFormFields} from './put-delta-form-fields';
import {SOGetDelta} from '../../core/delta/so-get-delta';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetTask} from '../../core/task/so-get-task';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {SOGetInvoiceitem} from '../../core/invoiceitem/so-get-invoiceitem';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {SOGetDeltaweight} from '../../core/deltaweight/so-get-deltaweight';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CEDeltaAttributesBase {

    dateCreated: DetailEntry
    type: DetailEntry
    quantity: DetailEntry
    containersession: DetailEntry
    load: DetailEntry
    billable: DetailEntry
    task: DetailEntry
    distributionday: DetailEntry
    invoice: DetailEntry
    invoiceitem: DetailEntry
    loadfee: DetailEntry
    deltaweights: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespDelta,
        app: GasDirectApp,
        formFields: PutDeltaFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.type = new DetailEntry(
            'Type',
            entity.type,
            'type',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.billable = new DetailEntry(
            'Billable',
            entity.billable,
            'billable',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )


        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        let containersessionCeProtocol;
        if (entity.containersession) {
            containersessionCeProtocol = createCEProtocol(
                containersessionCore.ceProtocol,
                parentEntityViewSpec,
                containersessionCore,
                entity.containersession,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        if (entity.containersession) {
            displayStringFactory.reconfigure(
                entity.containersession,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          containersessionCeProtocol ? containersessionCeProtocol.entityListView.fullHeading : null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        if (entity.containersession) {
          this.containersession.ceProtocol = containersessionCeProtocol;
          this.containersession.cachedListEntry = containersessionCore.makeListEntry(parentEntityViewSpec, entity.containersession, 0)
          containersessionCore.selectedEntity = entity.containersession;
        }
        this.containersession.singleFieldSelect = true

        const loadCore = app.makeCore( 'load');
        loadCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        loadCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadCore
        )
        loadCore.title = heading ? heading : loadCore.title
        loadCore.listTitle = 'Load'

        loadCore.configureForDisplay(parentEntityViewSpec)

        this.load = new DetailEntry(
          heading ? heading : loadCore.listTitle,
          null,
          'load',
          BaseDetailType.pushedDetail,
          loadCore,
          EditableType.never
        )
        this.load.singleFieldSelect = true

        const taskCore = app.makeCore( 'task');
        taskCore.soGet = SOGetTask.construct({
        });
        taskCore.soGet.queryOptions.sortKey = 'dateCreated';
        taskCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            taskCore
        )
        taskCore.title = heading ? heading : taskCore.title
        taskCore.listTitle = 'Task'

        taskCore.configureForDisplay(parentEntityViewSpec)

        this.task = new DetailEntry(
          heading ? heading : taskCore.listTitle,
          null,
          'task',
          BaseDetailType.pushedDetail,
          taskCore,
          EditableType.never
        )
        this.task.singleFieldSelect = true

        const distributiondayCore = app.makeCore( 'distributionday');
        distributiondayCore.soGet = SOGetDistributionday.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributiondayCore.soGet.queryOptions.sortKey = 'start';
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributiondayCore
        )
        distributiondayCore.title = heading ? heading : distributiondayCore.title
        distributiondayCore.listTitle = 'Distributionday'

        distributiondayCore.configureForDisplay(parentEntityViewSpec)

        this.distributionday = new DetailEntry(
          heading ? heading : distributiondayCore.listTitle,
          null,
          'distributionday',
          BaseDetailType.pushedDetail,
          distributiondayCore,
          EditableType.never
        )
        this.distributionday.singleFieldSelect = true

        const invoiceCore = app.makeCore( 'invoice');
        invoiceCore.soGet = SOGetInvoice.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoiceCore
        )
        invoiceCore.title = heading ? heading : invoiceCore.title
        invoiceCore.listTitle = 'Invoice'

        invoiceCore.configureForDisplay(parentEntityViewSpec)

        this.invoice = new DetailEntry(
          heading ? heading : invoiceCore.listTitle,
          null,
          'invoice',
          BaseDetailType.pushedDetail,
          invoiceCore,
          EditableType.never
        )
        this.invoice.singleFieldSelect = true

        const invoiceitemCore = app.makeCore( 'invoiceitem');
        invoiceitemCore.soGet = SOGetInvoiceitem.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            invoiceitemCore
        )
        invoiceitemCore.title = heading ? heading : invoiceitemCore.title
        invoiceitemCore.listTitle = 'Invoiceitem'

        invoiceitemCore.configureForDisplay(parentEntityViewSpec)

        this.invoiceitem = new DetailEntry(
          heading ? heading : invoiceitemCore.listTitle,
          null,
          'invoiceitem',
          BaseDetailType.pushedDetail,
          invoiceitemCore,
          EditableType.never
        )
        this.invoiceitem.singleFieldSelect = true

        const loadfeeCore = app.makeCore( 'loadfee');
        loadfeeCore.soGet = SOGetLoadfee.construct({
        });
        let loadfeeCeProtocol;
        if (entity.loadfee) {
            loadfeeCeProtocol = createCEProtocol(
                loadfeeCore.ceProtocol,
                parentEntityViewSpec,
                loadfeeCore,
                entity.loadfee,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadfeeCore
        )
        loadfeeCore.title = heading ? heading : loadfeeCore.title
        loadfeeCore.listTitle = 'Loadfee'

        if (entity.loadfee) {
            displayStringFactory.reconfigure(
                entity.loadfee,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        loadfeeCore.configureForDisplay(parentEntityViewSpec)

        this.loadfee = new DetailEntry(
          heading ? heading : loadfeeCore.listTitle,
          loadfeeCeProtocol ? loadfeeCeProtocol.entityListView.fullHeading : null,
          'loadfee',
          BaseDetailType.pushedDetail,
          loadfeeCore,
          EditableType.never
        )
        if (entity.loadfee) {
          this.loadfee.ceProtocol = loadfeeCeProtocol;
          this.loadfee.cachedListEntry = loadfeeCore.makeListEntry(parentEntityViewSpec, entity.loadfee, 0)
          loadfeeCore.selectedEntity = entity.loadfee;
        }
        this.loadfee.singleFieldSelect = true

        const deltaweightsCore = app.makeCore( 'deltaweight');
        deltaweightsCore.soGet = SOGetDeltaweight.construct({
          delta: new SOGetDelta(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            deltaweightsCore
        )
        deltaweightsCore.title = heading ? heading : deltaweightsCore.title
        deltaweightsCore.listTitle = 'Deltaweights'

        deltaweightsCore.configureForDisplay(parentEntityViewSpec)

        this.deltaweights = new DetailEntry(
          heading ? heading : deltaweightsCore.listTitle,
          null,
          'deltaweights',
          BaseDetailType.embeddedList,
          deltaweightsCore,
          EditableType.never
        )
    }
}