import {SORespPrice} from '../../core/price/so-resp-price';
import {PriceCore} from './price.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutPriceFormFields} from './put-price-form-fields';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetLoad} from '../../core/load/so-get-load';
import {SOGetClin} from '../../core/clin/so-get-clin';
import {SOGetAgreeyear} from '../../core/agreeyear/so-get-agreeyear';
import {SOGetBid} from '../../core/bid/so-get-bid';
import {SOGetLoadfee} from '../../core/loadfee/so-get-loadfee';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CEPriceAttributesBase {

    loads: DetailEntry
    startDate: DetailEntry
    endDate: DetailEntry
    rate: DetailEntry
    quantity: DetailEntry
    quantityUsed: DetailEntry
    loadQuantity: DetailEntry
    loadQuantityUsed: DetailEntry
    rank: DetailEntry
    clin: DetailEntry
    agreeyear: DetailEntry
    bid: DetailEntry
    loadfees: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespPrice,
        app: GasDirectApp,
        formFields: PutPriceFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.startDate = new DetailEntry(
            'Start date',
            app.activeUserService.time.dateAsString(entity.startDate),
            'startDate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.startDate
        )

        this.endDate = new DetailEntry(
            'End date',
            app.activeUserService.time.dateAsString(entity.endDate),
            'endDate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.endDate
        )

        this.rate = new DetailEntry(
            'Rate',
            entity.rate,
            'rate',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.rate
        )
        this.rate.listHeadingIndex = 0;

        this.quantity = new DetailEntry(
            'Quantity',
            entity.quantity,
            'quantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.quantity
        )
        this.quantity.listSubheadingIndex = 0;

        this.quantityUsed = new DetailEntry(
            'Quantity used',
            entity.quantityUsed,
            'quantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.quantityUsed.listSubheadingIndex = 1;

        this.loadQuantity = new DetailEntry(
            'Load quantity',
            entity.loadQuantity,
            'loadQuantity',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.loadQuantity
        )
        this.loadQuantity.listSubheadingIndex = 2;

        this.loadQuantityUsed = new DetailEntry(
            'Load quantity used',
            entity.loadQuantityUsed,
            'loadQuantityUsed',
            BaseDetailType.text,
            null,
            EditableType.never,
        )
        this.loadQuantityUsed.listSubheadingIndex = 3;

        this.rank = new DetailEntry(
            'Rank',
            entity.rank,
            'rank',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.rank
        )


        const loadsCore = app.makeCore( 'load');
        loadsCore.soGet = SOGetLoad.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          price: new SOGetPrice(entity.uid),
        });
        loadsCore.soGet.queryOptions.sortKey = 'requestedFillDate';
        loadsCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadsCore
        )
        loadsCore.title = heading ? heading : loadsCore.title
        loadsCore.listTitle = 'Loads'

        loadsCore.configureForDisplay(parentEntityViewSpec)

        this.loads = new DetailEntry(
          heading ? heading : loadsCore.listTitle,
          null,
          'loads',
          BaseDetailType.embeddedList,
          loadsCore,
          EditableType.never
        )

        const clinCore = app.makeCore( 'clin');
        clinCore.soGet = SOGetClin.construct({
        });
        let clinCeProtocol;
        if (entity.clin) {
            clinCeProtocol = createCEProtocol(
                clinCore.ceProtocol,
                parentEntityViewSpec,
                clinCore,
                entity.clin,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            clinCore
        )
        clinCore.title = heading ? heading : clinCore.title
        clinCore.listTitle = 'Clin'

        if (entity.clin) {
            displayStringFactory.reconfigure(
                entity.clin,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        clinCore.configureForDisplay(parentEntityViewSpec)

        this.clin = new DetailEntry(
          heading ? heading : clinCore.listTitle,
          clinCeProtocol ? clinCeProtocol.entityListView.fullHeading : null,
          'clin',
          BaseDetailType.pushedDetail,
          clinCore,
          EditableType.never
        )
        if (entity.clin) {
          this.clin.ceProtocol = clinCeProtocol;
          this.clin.cachedListEntry = clinCore.makeListEntry(parentEntityViewSpec, entity.clin, 0)
          clinCore.selectedEntity = entity.clin;
        }
        this.clin.singleFieldSelect = true

        const agreeyearCore = app.makeCore( 'agreeyear');
        agreeyearCore.soGet = SOGetAgreeyear.construct({
        });
        let agreeyearCeProtocol;
        if (entity.agreeyear) {
            agreeyearCeProtocol = createCEProtocol(
                agreeyearCore.ceProtocol,
                parentEntityViewSpec,
                agreeyearCore,
                entity.agreeyear,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            agreeyearCore
        )
        agreeyearCore.title = heading ? heading : agreeyearCore.title
        agreeyearCore.listTitle = 'Agreeyear'

        if (entity.agreeyear) {
            displayStringFactory.reconfigure(
                entity.agreeyear,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        agreeyearCore.configureForDisplay(parentEntityViewSpec)

        this.agreeyear = new DetailEntry(
          heading ? heading : agreeyearCore.listTitle,
          agreeyearCeProtocol ? agreeyearCeProtocol.entityListView.fullHeading : null,
          'agreeyear',
          BaseDetailType.pushedDetail,
          agreeyearCore,
          EditableType.never
        )
        if (entity.agreeyear) {
          this.agreeyear.ceProtocol = agreeyearCeProtocol;
          this.agreeyear.cachedListEntry = agreeyearCore.makeListEntry(parentEntityViewSpec, entity.agreeyear, 0)
          agreeyearCore.selectedEntity = entity.agreeyear;
        }
        this.agreeyear.singleFieldSelect = true

        const bidCore = app.makeCore( 'bid');
        bidCore.soGet = SOGetBid.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            bidCore
        )
        bidCore.title = heading ? heading : bidCore.title
        bidCore.listTitle = 'Bid'

        bidCore.configureForDisplay(parentEntityViewSpec)

        this.bid = new DetailEntry(
          heading ? heading : bidCore.listTitle,
          null,
          'bid',
          BaseDetailType.pushedDetail,
          bidCore,
          EditableType.never
        )
        this.bid.singleFieldSelect = true

        const loadfeesCore = app.makeCore( 'loadfee');
        loadfeesCore.soGet = SOGetLoadfee.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            loadfeesCore
        )
        loadfeesCore.title = heading ? heading : loadfeesCore.title
        loadfeesCore.listTitle = 'Loadfees'

        loadfeesCore.configureForDisplay(parentEntityViewSpec)

        this.loadfees = new DetailEntry(
          heading ? heading : loadfeesCore.listTitle,
          null,
          'loadfees',
          BaseDetailType.embeddedList,
          loadfeesCore,
          EditableType.never
        )
    }
}