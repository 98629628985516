import {SOPostTank} from '../../core/tank/so-post-tank';
import {TankCoreBase} from './tank.core.base';
import {SOGetTank} from '../../core/tank/so-get-tank';
import {FormField, SelectionOption} from 'lionheartland';
import {postTankFormFieldOptions} from './post-tank-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {ProductionCore} from '../production/production.core';
import {SOGetProduction} from '../../core/production/so-get-production';

export class RAPostTank extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTankFormFieldOptions);

  RequestClass = SOPostTank

  constructor(
    public entityCore: TankCoreBase
  ) {
    super();
    if (postTankFormFieldOptions.production) {
      postTankFormFieldOptions.production.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('units'),
      this.getFormValueFromIdentifier(
        'production',
        SOGetProduction,
      ),
      this.getFormValueFromIdentifier('type'),
      this.getFormValueFromIdentifier('capacity'),
      this.getFormValueFromIdentifier('average'),
      this.getFormValueFromIdentifier('value'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}