import {SOPutFeatureasc} from '../../core/featureasc/so-put-featureasc';
import {FeatureascCoreBase} from './featureasc.core.base';
import {SOGetFeatureasc} from '../../core/featureasc/so-get-featureasc';
import {FormField, SelectionOption} from 'lionheartland';
import {putFeatureascFormFieldOptions} from './put-featureasc-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePut} from 'lionheartland';
import {FeatureCore} from '../feature/feature.core';
import {SOGetFeature} from '../../core/feature/so-get-feature';
import {DistributionCore} from '../distribution/distribution.core';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {UserCore} from '../user/user.core';
import {SOGetUser} from '../../core/user/so-get-user';
import {RoleCore} from '../role/role.core';
import {SOGetRole} from '../../core/role/so-get-role';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {OrganizationCore} from '../organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutFeatureasc extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putFeatureascFormFieldOptions);

  RequestClass = SOPutFeatureasc

  constructor(
    public entityCore: FeatureascCoreBase
  ) {
    super();
    if (putFeatureascFormFieldOptions.feature) {
      putFeatureascFormFieldOptions.feature.makeCore = true;
    }
    if (putFeatureascFormFieldOptions.distribution) {
      putFeatureascFormFieldOptions.distribution.makeCore = true;
    }
    if (putFeatureascFormFieldOptions.user) {
      putFeatureascFormFieldOptions.user.makeCore = true;
    }
    if (putFeatureascFormFieldOptions.role) {
      putFeatureascFormFieldOptions.role.makeCore = true;
    }
    if (putFeatureascFormFieldOptions.account) {
      putFeatureascFormFieldOptions.account.makeCore = true;
    }
    if (putFeatureascFormFieldOptions.organization) {
      putFeatureascFormFieldOptions.organization.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'feature',
        SOGetFeature,
      ),
      this.getFormValueFromIdentifier(
        'distribution',
        SOGetDistribution,
      ),
      this.getFormValueFromIdentifier(
        'user',
        SOGetUser,
      ),
      this.getFormValueFromIdentifier(
        'role',
        SOGetRole,
      ),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}