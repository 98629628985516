import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTemptraclin } from './so-put-temptraclin';
import { SOGetTemptraclin } from './so-get-temptraclin';
import { SOPostTemptraclin } from './so-post-temptraclin';
import { SODeleteTemptraclin } from './so-delete-temptraclin';
import {SORespTemptraclin} from './so-resp-temptraclin';
import {SOPluralTemptraclin} from './so-plural-temptraclin';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTemptraclinService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'temptraclin',
      entityCore
    );
  }

  /** GET Temptraclin by id. Will 404 if id not found */
  get(request: SOGetTemptraclin): Observable<SOPluralTemptraclin> {
    return this.httpClient.get<SOPluralTemptraclin>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTemptraclin, resp) }),
      tap(_ => this.log(`fetched Temptraclin`)),
      catchError(this.handleError<SOPluralTemptraclin>(`get Temptraclin`))
    );
  }

  /** POST: add a new Temptraclin to the server */
  post(request: SOPostTemptraclin): Observable<SORespTemptraclin> {
    return this.httpClient.post<SORespTemptraclin>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemptraclin, resp) }),
      tap((response: SORespTemptraclin) => this.log(`added Temptraclin w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTemptraclin>('Temptraclin post'))
    );
  }

  /** PUT: update Temptraclin  */
  put(request: SOPutTemptraclin): Observable<SORespTemptraclin> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTemptraclin>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemptraclin, resp) }),
      tap((response: SORespTemptraclin) => this.log(`edited Temptraclin w/ uid=${uid}`)),
      catchError(this.handleError<SORespTemptraclin>('Temptraclin put'))
    );
  }

  /** DELETE: delete the Temptraclin from the server */
  delete(request: SODeleteTemptraclin): Observable<SORespTemptraclin> {
    return this.httpClient.delete<SORespTemptraclin>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Temptraclin uid=${request.uid}`)),
      catchError(this.handleError<SORespTemptraclin>('delete Temptraclin'))
    );
  }
}
