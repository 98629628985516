<div fxLayout="column" class="list-detail">
    <div fxLayout="row" class="list-detail-content">
        <app-sv-list *ngIf="!isCalendar && !isBoard && !isStatus && isList" class="sv-list" #list [entityViewSpec]="entityListSpec"
                     [parentEntityViewComponent]="this"></app-sv-list>
<!--        <app-av-calendar *ngIf="isCalendar" class="sv-list" #list [entityViewSpec]="entityListSpec"-->
<!--                     [parentEntityViewComponent]="this"></app-av-calendar>-->
        <app-av-board *ngIf="isBoard" class="sv-list" #list [entityViewSpec]="entityCalendarSpec"
                     [parentEntityViewComponent]="this"></app-av-board>
        <app-av-status *ngIf="isStatus" class="sv-list" #list [entityViewSpec]="entityListSpec"
                      [parentEntityViewComponent]="this"></app-av-status>
        <app-sv-entity-attributes-view *ngIf="isDetail" class="list-detail-list" [entityViewSpec]="entityViewSpec"
                                       [parentEntityViewComponent]="this"></app-sv-entity-attributes-view>
        <app-sv-post *ngIf="isPost" class="sv-list" [entityViewSpec]="entityPostSpec"
                     [parentEntityViewComponent]="this"></app-sv-post>
        <app-sv-calendar *ngIf="isCalendar" class="list-detail-detail" [entityViewSpec]="entityCalendarSpec"
                         [parentEntityViewComponent]="this"></app-sv-calendar>
        <app-sv-auto-complete *ngIf="isAutoComplete" class="sv-list" [entityViewSpec]="entityAutoCompleteSpec"
                              [parentEntityViewComponent]="this"></app-sv-auto-complete>
    </div>
</div>

