import {SOPostHyear} from '../../core/hyear/so-post-hyear';
import {HyearCoreBase} from './hyear.core.base';
import {SOGetHyear} from '../../core/hyear/so-get-hyear';
import {FormField, SelectionOption} from 'lionheartland';
import {postHyearFormFieldOptions} from './post-hyear-form-fields';
import {AdapterProtocol} from 'lionheartland';
import {BasePost} from 'lionheartland';
import {AccountCore} from '../account/account.core';
import {SOGetAccount} from '../../core/account/so-get-account';
import {DistributionyearCore} from '../distributionyear/distributionyear.core';
import {SOGetDistributionyear} from '../../core/distributionyear/so-get-distributionyear';

export class RAPostHyear extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postHyearFormFieldOptions);

  RequestClass = SOPostHyear

  constructor(
    public entityCore: HyearCoreBase
  ) {
    super();
    if (postHyearFormFieldOptions.distributionyear) {
      postHyearFormFieldOptions.distributionyear.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('state'),
      this.getFormValueFromIdentifier('year'),
      this.getFormValueFromIdentifier(
        'account',
        SOGetAccount,
        true,
      ),
      this.getFormValueFromIdentifier(
        'distributionyear',
        SOGetDistributionyear,
      ),
      this.getFormValueFromIdentifier('acq'),
      this.getFormValueFromIdentifier('acqUsed'),
      this.getFormValueFromIdentifier('spotQuantity'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}