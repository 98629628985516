import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTagreportFormFields {


    constructor() {


    }
}

const fields = new PutTagreportFormFields()

export const putTagreportFormFieldOptions: {[key: string]: FormField} = {
};