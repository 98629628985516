

export class SOPutBid {

  constructor(
    public uid: string,
    public displayName: string = null,
    public description: string = null,
    public amount: number = null,
    public status: string = null,
    public amountTwo: number = null,
    public amountThree: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutBid(
      'uid' in params ? params.uid : null,
      'displayName' in params ? params.displayName : null,
      'description' in params ? params.description : null,
      'amount' in params ? params.amount : null,
      'status' in params ? params.status : null,
      'amountTwo' in params ? params.amountTwo : null,
      'amountThree' in params ? params.amountThree : null,
    );
  }
}
