import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOBolService} from '../../core/bol/eo-bol';
import {SOGetBol} from '../../core/bol/so-get-bol';
import {RAPutBol} from './ra-put-bol';
import {RAPostBol} from './ra-post-bol';
import {RADeleteBol} from './ra-delete-bol';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEBol } from "./ce-bol"


export class BolCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'bol';
  title = 'Bol';
  ceProtocol: CEConstructor = CEBol

  entityService: EOBolService;
  soGetClass = SOGetBol;
  soGet: SOGetBol;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOBolService
    );
    this.adapters = [
      new RAPostBol(this),
      new RAPutBol(this),
      new RADeleteBol(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBol();
    this.soGet.queryOptions.limit = this.limit;
  }


}