<div fxLayout="column" *ngIf="loadRow">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <h2>{{loadRow.title}}</h2>
      <h2 style="margin-left: 5px">({{loadRow.listEntries.length}})</h2>
    </div>
    <button mat-button (click)="toggleRowVisability()">{{rowVisabilityButtonText}}</button>
  </div>
  <div class="load-row">
    <div *ngIf="showCollection" gdGap="10px" gdColumns="repeat(auto-fill, minmax(200px, 1fr))">
      <app-dv-load-summary
        *ngFor="let entry of loadRow.listEntries" [listEntry]="entry" (click)="parent.selectListEntry(entry)"></app-dv-load-summary>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
