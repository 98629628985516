import {SORespServiceRequest} from '../../core/service-request/so-resp-service-request';
import {ServiceRequestCore} from './service-request.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutServiceRequestFormFields} from './put-service-request-form-fields';
import {SOGetServiceRequest} from '../../core/service-request/so-get-service-request';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';
import {SOGetDistributionday} from '../../core/distributionday/so-get-distributionday';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetAccount} from '../../core/account/so-get-account';


export class CEServiceRequestAttributesBase {

    dateCreated: DetailEntry
    dateRequested: DetailEntry
    serviceType: DetailEntry
    distribution: DetailEntry
    distributionday: DetailEntry
    containersession: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespServiceRequest,
        app: GasDirectApp,
        formFields: PutServiceRequestFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.dateCreated = new DetailEntry(
            'Date created',
            app.activeUserService.time.dateAsString(entity.dateCreated),
            'dateCreated',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateCreated
        )

        this.dateRequested = new DetailEntry(
            'Date requested',
            app.activeUserService.time.dateAsString(entity.dateRequested),
            'dateRequested',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.dateRequested
        )

        this.serviceType = new DetailEntry(
            'Service type',
            entity.serviceType,
            'serviceType',
            BaseDetailType.toggleGroup,
            null,
            EditableType.always,
            formFields.serviceType
        )


        const distributionCore = app.makeCore( 'distribution');
        distributionCore.soGet = SOGetDistribution.construct({
        });
        let distributionCeProtocol;
        if (entity.distribution) {
            distributionCeProtocol = createCEProtocol(
                distributionCore.ceProtocol,
                parentEntityViewSpec,
                distributionCore,
                entity.distribution,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributionCore
        )
        distributionCore.title = heading ? heading : distributionCore.title
        distributionCore.listTitle = 'Distribution'

        if (entity.distribution) {
            displayStringFactory.reconfigure(
                entity.distribution,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributionCore.configureForDisplay(parentEntityViewSpec)

        this.distribution = new DetailEntry(
          heading ? heading : distributionCore.listTitle,
          distributionCeProtocol ? distributionCeProtocol.entityListView.fullHeading : null,
          'distribution',
          BaseDetailType.pushedDetail,
          distributionCore,
          EditableType.always,
          formFields.distribution
        )
        if (entity.distribution) {
          this.distribution.ceProtocol = distributionCeProtocol;
          this.distribution.cachedListEntry = distributionCore.makeListEntry(parentEntityViewSpec, entity.distribution, 0)
          distributionCore.selectedEntity = entity.distribution;
        }
        this.distribution.singleFieldSelect = true

        const distributiondayCore = app.makeCore( 'distributionday');
        distributiondayCore.soGet = SOGetDistributionday.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        distributiondayCore.soGet.queryOptions.sortKey = 'start';
        let distributiondayCeProtocol;
        if (entity.distributionday) {
            distributiondayCeProtocol = createCEProtocol(
                distributiondayCore.ceProtocol,
                parentEntityViewSpec,
                distributiondayCore,
                entity.distributionday,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            distributiondayCore
        )
        distributiondayCore.title = heading ? heading : distributiondayCore.title
        distributiondayCore.listTitle = 'Distributionday'

        if (entity.distributionday) {
            displayStringFactory.reconfigure(
                entity.distributionday,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        distributiondayCore.configureForDisplay(parentEntityViewSpec)

        this.distributionday = new DetailEntry(
          heading ? heading : distributiondayCore.listTitle,
          distributiondayCeProtocol ? distributiondayCeProtocol.entityListView.fullHeading : null,
          'distributionday',
          BaseDetailType.pushedDetail,
          distributiondayCore,
          EditableType.always,
          formFields.distributionday
        )
        if (entity.distributionday) {
          this.distributionday.ceProtocol = distributiondayCeProtocol;
          this.distributionday.cachedListEntry = distributiondayCore.makeListEntry(parentEntityViewSpec, entity.distributionday, 0)
          distributiondayCore.selectedEntity = entity.distributionday;
        }
        this.distributionday.singleFieldSelect = true

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        let containersessionCeProtocol;
        if (entity.containersession) {
            containersessionCeProtocol = createCEProtocol(
                containersessionCore.ceProtocol,
                parentEntityViewSpec,
                containersessionCore,
                entity.containersession,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        if (entity.containersession) {
            displayStringFactory.reconfigure(
                entity.containersession,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          containersessionCeProtocol ? containersessionCeProtocol.entityListView.fullHeading : null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.always,
          formFields.containersession
        )
        if (entity.containersession) {
          this.containersession.ceProtocol = containersessionCeProtocol;
          this.containersession.cachedListEntry = containersessionCore.makeListEntry(parentEntityViewSpec, entity.containersession, 0)
          containersessionCore.selectedEntity = entity.containersession;
        }
        this.containersession.singleFieldSelect = true
    }
}