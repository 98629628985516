import { Type } from 'class-transformer';
import { SORespDelta } from '../delta/so-resp-delta';
import { SORespWeight } from '../weight/so-resp-weight';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespDeltaweight implements ObjectiveProtocol {

  entityType: string = 'deltaweight';
  uid: string;
  initial: boolean;
  @Type(() => SORespDelta)
  delta: SORespDelta;
  @Type(() => SORespWeight)
  weight: SORespWeight;
}