import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTrailermergeFormFields {

    public newTrailer: FormField

    constructor() {

        this.newTrailer = new FormField(
          'newTrailer',
          'New trailer',
          'trailer',
          FormFieldType.autoCompleteList,
          true,
        )

    }
}

const fields = new PostTrailermergeFormFields()

export const postTrailermergeFormFieldOptions: {[key: string]: FormField} = {
  newTrailer: fields.newTrailer,
};