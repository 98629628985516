import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostBaysessionFormFields {

    public containersession: FormField
    public load: FormField

    constructor() {

        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )

    }
}

const fields = new PostBaysessionFormFields()

export const postBaysessionFormFieldOptions: {[key: string]: FormField} = {
  containersession: fields.containersession,
  load: fields.load,
};