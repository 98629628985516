import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespTankreport implements ObjectiveProtocol {

  entityType: string = 'tankreport';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  value: number;
}