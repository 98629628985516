import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutTrailerauthFormFields {

    public account: FormField
    public trailer: FormField

    constructor() {

        this.account = new FormField(
          'account',
          'Account',
          'account',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.trailer = new FormField(
          'trailer',
          'Trailer',
          'trailer',
          FormFieldType.autoCompleteList,
          false,
        )

    }
}

const fields = new PutTrailerauthFormFields()

export const putTrailerauthFormFieldOptions: {[key: string]: FormField} = {
  trailer: fields.trailer,
};