import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOAgreemonthService} from '../../core/agreemonth/eo-agreemonth';
import {SOGetAgreemonth} from '../../core/agreemonth/so-get-agreemonth';
import {RAPutAgreemonth} from './ra-put-agreemonth';
import {RAPostAgreemonth} from './ra-post-agreemonth';
import {RADeleteAgreemonth} from './ra-delete-agreemonth';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEAgreemonth } from "./ce-agreemonth"


export class AgreemonthCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'agreemonth';
  title = 'Agreemonth';
  ceProtocol: CEConstructor = CEAgreemonth

  entityService: EOAgreemonthService;
  soGetClass = SOGetAgreemonth;
  soGet: SOGetAgreemonth;

  isScope = true;

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOAgreemonthService
    );
    this.adapters = [
      new RAPostAgreemonth(this),
      new RAPutAgreemonth(this),
      new RADeleteAgreemonth(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAgreemonth();
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'month';
  }


}