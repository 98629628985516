import { SOGetOrder } from '../order/so-get-order';


export class SOPostQuotelet {

  constructor(
    public type: string,
    public units: string,
    public quantityVolume: number,
    public quantityLoads: number,
    public indexRate: number,
    public variableRate: number,
    public totalRate: number,
    public totalAmount: number,
    public order: SOGetOrder,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostQuotelet(
      'type' in params ? params.type : null,
      'units' in params ? params.units : null,
      'quantityVolume' in params ? params.quantityVolume : null,
      'quantityLoads' in params ? params.quantityLoads : null,
      'indexRate' in params ? params.indexRate : null,
      'variableRate' in params ? params.variableRate : null,
      'totalRate' in params ? params.totalRate : null,
      'totalAmount' in params ? params.totalAmount : null,
      'order' in params ? params.order : null,
    );
  }
}
