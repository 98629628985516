import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutReservationFormFields {

    public status: FormField
    public start: FormField
    public end: FormField
    public description: FormField
    public slot: FormField
    public load: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          false,
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.slot = new FormField(
          'slot',
          'Slot',
          'slot',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )

    }
}

const fields = new PutReservationFormFields()

export const putReservationFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
  start: fields.start,
  end: fields.end,
  description: fields.description,
  slot: fields.slot,
  load: fields.load,
};