import { Type } from 'class-transformer';
import { SORespContainersession } from '../containersession/so-resp-containersession';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespBaysession implements ObjectiveProtocol {

  entityType: string = 'baysession';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end: Date = null;
  @Type(() => SORespContainersession)
  containersession: SORespContainersession;
}