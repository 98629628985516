import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDelta } from './so-put-delta';
import { SOGetDelta } from './so-get-delta';
import { SOPostDelta } from './so-post-delta';
import { SODeleteDelta } from './so-delete-delta';
import {SORespDelta} from './so-resp-delta';
import {SOPluralDelta} from './so-plural-delta';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODeltaService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'delta',
      entityCore
    );
  }

  /** GET Delta by id. Will 404 if id not found */
  get(request: SOGetDelta): Observable<SOPluralDelta> {
    return this.httpClient.get<SOPluralDelta>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDelta, resp) }),
      tap(_ => this.log(`fetched Delta`)),
      catchError(this.handleError<SOPluralDelta>(`get Delta`))
    );
  }

  /** POST: add a new Delta to the server */
  post(request: SOPostDelta): Observable<SORespDelta> {
    return this.httpClient.post<SORespDelta>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDelta, resp) }),
      tap((response: SORespDelta) => this.log(`added Delta w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDelta>('Delta post'))
    );
  }

  /** PUT: update Delta  */
  put(request: SOPutDelta): Observable<SORespDelta> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDelta>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDelta, resp) }),
      tap((response: SORespDelta) => this.log(`edited Delta w/ uid=${uid}`)),
      catchError(this.handleError<SORespDelta>('Delta put'))
    );
  }

  /** DELETE: delete the Delta from the server */
  delete(request: SODeleteDelta): Observable<SORespDelta> {
    return this.httpClient.delete<SORespDelta>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Delta uid=${request.uid}`)),
      catchError(this.handleError<SORespDelta>('delete Delta'))
    );
  }
}
