import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOWeightService} from '../../core/weight/eo-weight';
import {SOGetWeight} from '../../core/weight/so-get-weight';
import {RAPutWeight} from './ra-put-weight';
import {RAPostWeight} from './ra-post-weight';
import {RADeleteWeight} from './ra-delete-weight';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEWeight } from "./ce-weight"


export class WeightCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'weight';
  title = 'Weight';
  ceProtocol: CEConstructor = CEWeight

  entityService: EOWeightService;
  soGetClass = SOGetWeight;
  soGet: SOGetWeight;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOWeightService
    );
    this.adapters = [
      new RAPostWeight(this),
      new RAPutWeight(this),
      new RADeleteWeight(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetWeight();
    this.soGet.queryOptions.limit = this.limit;
  }


}