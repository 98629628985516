import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutServiceRequest } from './so-put-service-request';
import { SOGetServiceRequest } from './so-get-service-request';
import { SOPostServiceRequest } from './so-post-service-request';
import { SODeleteServiceRequest } from './so-delete-service-request';
import {SORespServiceRequest} from './so-resp-service-request';
import {SOPluralServiceRequest} from './so-plural-service-request';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOServiceRequestService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'service_request',
      entityCore
    );
  }

  /** GET ServiceRequest by id. Will 404 if id not found */
  get(request: SOGetServiceRequest): Observable<SOPluralServiceRequest> {
    return this.httpClient.get<SOPluralServiceRequest>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralServiceRequest, resp) }),
      tap(_ => this.log(`fetched ServiceRequest`)),
      catchError(this.handleError<SOPluralServiceRequest>(`get ServiceRequest`))
    );
  }

  /** POST: add a new ServiceRequest to the server */
  post(request: SOPostServiceRequest): Observable<SORespServiceRequest> {
    return this.httpClient.post<SORespServiceRequest>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespServiceRequest, resp) }),
      tap((response: SORespServiceRequest) => this.log(`added ServiceRequest w/ id=${response.uid}`)),
      catchError(this.handleError<SORespServiceRequest>('ServiceRequest post'))
    );
  }

  /** PUT: update ServiceRequest  */
  put(request: SOPutServiceRequest): Observable<SORespServiceRequest> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespServiceRequest>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespServiceRequest, resp) }),
      tap((response: SORespServiceRequest) => this.log(`edited ServiceRequest w/ uid=${uid}`)),
      catchError(this.handleError<SORespServiceRequest>('ServiceRequest put'))
    );
  }

  /** DELETE: delete the ServiceRequest from the server */
  delete(request: SODeleteServiceRequest): Observable<SORespServiceRequest> {
    return this.httpClient.delete<SORespServiceRequest>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted ServiceRequest uid=${request.uid}`)),
      catchError(this.handleError<SORespServiceRequest>('delete ServiceRequest'))
    );
  }
}
