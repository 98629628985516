import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTempagreetractService} from '../../core/tempagreetract/eo-tempagreetract';
import {SOGetTempagreetract} from '../../core/tempagreetract/so-get-tempagreetract';
import {RAPutTempagreetract} from './ra-put-tempagreetract';
import {RAPostTempagreetract} from './ra-post-tempagreetract';
import {RADeleteTempagreetract} from './ra-delete-tempagreetract';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CETempagreetract } from "./ce-tempagreetract"


export class TempagreetractCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'tempagreetract';
  title = 'Tempagreetract';
  ceProtocol: CEConstructor = CETempagreetract

  entityService: EOTempagreetractService;
  soGetClass = SOGetTempagreetract;
  soGet: SOGetTempagreetract;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTempagreetractService
    );
    this.adapters = [
      new RAPostTempagreetract(this),
      new RAPutTempagreetract(this),
      new RADeleteTempagreetract(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTempagreetract();
    this.soGet.queryOptions.limit = this.limit;
  }


}