import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutDcs } from './so-put-dcs';
import { SOGetDcs } from './so-get-dcs';
import { SOPostDcs } from './so-post-dcs';
import { SODeleteDcs } from './so-delete-dcs';
import {SORespDcs} from './so-resp-dcs';
import {SOPluralDcs} from './so-plural-dcs';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EODcsService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'dcs',
      entityCore
    );
  }

  /** GET Dcs by id. Will 404 if id not found */
  get(request: SOGetDcs): Observable<SOPluralDcs> {
    return this.httpClient.get<SOPluralDcs>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDcs, resp) }),
      tap(_ => this.log(`fetched Dcs`)),
      catchError(this.handleError<SOPluralDcs>(`get Dcs`))
    );
  }

  /** POST: add a new Dcs to the server */
  post(request: SOPostDcs): Observable<SORespDcs> {
    return this.httpClient.post<SORespDcs>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDcs, resp) }),
      tap((response: SORespDcs) => this.log(`added Dcs w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDcs>('Dcs post'))
    );
  }

  /** PUT: update Dcs  */
  put(request: SOPutDcs): Observable<SORespDcs> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDcs>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDcs, resp) }),
      tap((response: SORespDcs) => this.log(`edited Dcs w/ uid=${uid}`)),
      catchError(this.handleError<SORespDcs>('Dcs put'))
    );
  }

  /** DELETE: delete the Dcs from the server */
  delete(request: SODeleteDcs): Observable<SORespDcs> {
    return this.httpClient.delete<SORespDcs>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Dcs uid=${request.uid}`)),
      catchError(this.handleError<SORespDcs>('delete Dcs'))
    );
  }
}
