import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutGrantFormFields {

    public isDefault: FormField

    constructor() {

        this.isDefault = new FormField(
          'isDefault',
          'Is default',
          null,
          FormFieldType.boolean,
          false,
        )

    }
}

const fields = new PutGrantFormFields()

export const putGrantFormFieldOptions: {[key: string]: FormField} = {
  isDefault: fields.isDefault,
};