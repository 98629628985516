import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutTagreport } from './so-put-tagreport';
import { SOGetTagreport } from './so-get-tagreport';
import { SOPostTagreport } from './so-post-tagreport';
import { SODeleteTagreport } from './so-delete-tagreport';
import {SORespTagreport} from './so-resp-tagreport';
import {SOPluralTagreport} from './so-plural-tagreport';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOTagreportService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'tagreport',
      entityCore
    );
  }

  /** GET Tagreport by id. Will 404 if id not found */
  get(request: SOGetTagreport): Observable<SOPluralTagreport> {
    return this.httpClient.get<SOPluralTagreport>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTagreport, resp) }),
      tap(_ => this.log(`fetched Tagreport`)),
      catchError(this.handleError<SOPluralTagreport>(`get Tagreport`))
    );
  }

  /** POST: add a new Tagreport to the server */
  post(request: SOPostTagreport): Observable<SORespTagreport> {
    return this.httpClient.post<SORespTagreport>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTagreport, resp) }),
      tap((response: SORespTagreport) => this.log(`added Tagreport w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTagreport>('Tagreport post'))
    );
  }

  /** PUT: update Tagreport  */
  put(request: SOPutTagreport): Observable<SORespTagreport> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTagreport>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTagreport, resp) }),
      tap((response: SORespTagreport) => this.log(`edited Tagreport w/ uid=${uid}`)),
      catchError(this.handleError<SORespTagreport>('Tagreport put'))
    );
  }

  /** DELETE: delete the Tagreport from the server */
  delete(request: SODeleteTagreport): Observable<SORespTagreport> {
    return this.httpClient.delete<SORespTagreport>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Tagreport uid=${request.uid}`)),
      catchError(this.handleError<SORespTagreport>('delete Tagreport'))
    );
  }
}
