import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutSaleFormFields {

    public status: FormField
    public description: FormField

    constructor() {

        this.status = new FormField(
          'status',
          'Status',
          null,
          FormFieldType.text,
          false,
          true,
          "pending"
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )

    }
}

const fields = new PutSaleFormFields()

export const putSaleFormFieldOptions: {[key: string]: FormField} = {
  status: fields.status,
  description: fields.description,
};