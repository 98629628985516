import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EONaviService} from '../../core/navi/eo-navi';
import {SOGetNavi} from '../../core/navi/so-get-navi';
import {RAPutNavi} from './ra-put-navi';
import {RAPostNavi} from './ra-post-navi';
import {RADeleteNavi} from './ra-delete-navi';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CENavi } from "./ce-navi"


export class NaviCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'navi';
  title = 'Navi';
  ceProtocol: CEConstructor = CENavi

  entityService: EONaviService;
  soGetClass = SOGetNavi;
  soGet: SOGetNavi;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EONaviService
    );
    this.adapters = [
      new RAPostNavi(this),
      new RAPutNavi(this),
      new RADeleteNavi(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetNavi();
    this.soGet.queryOptions.limit = this.limit;
  }


}