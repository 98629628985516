import { SOGetProduction } from '../production/so-get-production';


export class SOPutTank {

  constructor(
    public uid: string,
    public name: string = null,
    public units: string = null,
    public type: string = null,
    public capacity: number = null,
    public average: number = null,
    public production: SOGetProduction = null,
    public value: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTank(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'units' in params ? params.units : null,
      'type' in params ? params.type : null,
      'capacity' in params ? params.capacity : null,
      'average' in params ? params.average : null,
      'production' in params ? params.production : null,
      'value' in params ? params.value : null,
    );
  }
}
