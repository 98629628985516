import { Type } from 'class-transformer';
import { SORespLoad } from '../load/so-resp-load';
import {ObjectiveProtocol} from 'lionheartland';


export class SORespInreport implements ObjectiveProtocol {

  entityType: string = 'inreport';
  uid: string;
  @Type(() => SORespLoad)
  load: SORespLoad;
  leftoverPrior: boolean = null;
  prefillPrior: boolean = null;
  leftoverNext: boolean = null;
  prefillNext: boolean = null;
}