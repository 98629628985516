import {SORespEvent} from '../../core/event/so-resp-event';
import {EventCore} from './event.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutEventFormFields} from './put-event-form-fields';
import {SOGetEvent} from '../../core/event/so-get-event';
import {SOGetSlotlet} from '../../core/slotlet/so-get-slotlet';


export class CEEventAttributesBase {

    title: DetailEntry
    description: DetailEntry
    eventType: DetailEntry
    start: DetailEntry
    end: DetailEntry
    slotlet: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespEvent,
        app: GasDirectApp,
        formFields: PutEventFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.title = new DetailEntry(
            'Title',
            entity.title,
            'title',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.title
        )

        this.description = new DetailEntry(
            'Description',
            entity.description,
            'description',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.description
        )

        this.eventType = new DetailEntry(
            'Event type',
            entity.eventType,
            'eventType',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.eventType
        )

        this.start = new DetailEntry(
            'Start',
            app.activeUserService.time.dateAsString(entity.start),
            'start',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.start
        )

        this.end = new DetailEntry(
            'End',
            app.activeUserService.time.dateAsString(entity.end),
            'end',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.end
        )


        const slotletCore = app.makeCore( 'slotlet');
        slotletCore.soGet = SOGetSlotlet.construct({
        });
        let slotletCeProtocol;
        if (entity.slotlet) {
            slotletCeProtocol = createCEProtocol(
                slotletCore.ceProtocol,
                parentEntityViewSpec,
                slotletCore,
                entity.slotlet,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            slotletCore
        )
        slotletCore.title = heading ? heading : slotletCore.title
        slotletCore.listTitle = 'Slotlet'

        if (entity.slotlet) {
            displayStringFactory.reconfigure(
                entity.slotlet,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        slotletCore.configureForDisplay(parentEntityViewSpec)

        this.slotlet = new DetailEntry(
          heading ? heading : slotletCore.listTitle,
          slotletCeProtocol ? slotletCeProtocol.entityListView.fullHeading : null,
          'slotlet',
          BaseDetailType.pushedDetail,
          slotletCore,
          EditableType.always,
          formFields.slotlet
        )
        if (entity.slotlet) {
          this.slotlet.ceProtocol = slotletCeProtocol;
          this.slotlet.cachedListEntry = slotletCore.makeListEntry(parentEntityViewSpec, entity.slotlet, 0)
          slotletCore.selectedEntity = entity.slotlet;
        }
        this.slotlet.singleFieldSelect = true
    }
}