import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutIndexprice } from './so-put-indexprice';
import { SOGetIndexprice } from './so-get-indexprice';
import { SOPostIndexprice } from './so-post-indexprice';
import { SODeleteIndexprice } from './so-delete-indexprice';
import {SORespIndexprice} from './so-resp-indexprice';
import {SOPluralIndexprice} from './so-plural-indexprice';

import {MMEntityService} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import {MMEntityServiceBase} from 'lionheartland';
import {HttpHeaders} from "@angular/common/http";


export class EOIndexpriceService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'indexprice',
      entityCore
    );
  }

  /** GET Indexprice by id. Will 404 if id not found */
  get(request: SOGetIndexprice): Observable<SOPluralIndexprice> {
    return this.httpClient.get<SOPluralIndexprice>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralIndexprice, resp) }),
      tap(_ => this.log(`fetched Indexprice`)),
      catchError(this.handleError<SOPluralIndexprice>(`get Indexprice`))
    );
  }

  /** POST: add a new Indexprice to the server */
  post(request: SOPostIndexprice): Observable<SORespIndexprice> {
    return this.httpClient.post<SORespIndexprice>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespIndexprice, resp) }),
      tap((response: SORespIndexprice) => this.log(`added Indexprice w/ id=${response.uid}`)),
      catchError(this.handleError<SORespIndexprice>('Indexprice post'))
    );
  }

  /** PUT: update Indexprice  */
  put(request: SOPutIndexprice): Observable<SORespIndexprice> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespIndexprice>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespIndexprice, resp) }),
      tap((response: SORespIndexprice) => this.log(`edited Indexprice w/ uid=${uid}`)),
      catchError(this.handleError<SORespIndexprice>('Indexprice put'))
    );
  }

  /** DELETE: delete the Indexprice from the server */
  delete(request: SODeleteIndexprice): Observable<SORespIndexprice> {
    return this.httpClient.delete<SORespIndexprice>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Indexprice uid=${request.uid}`)),
      catchError(this.handleError<SORespIndexprice>('delete Indexprice'))
    );
  }
}
