import { SOGetTrailer } from '../trailer/so-get-trailer';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetTrailermerge implements QueryProtocol {

  constructor(
    public uid: string = null,
    public newTrailer: SOGetTrailer = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetTrailermerge(
      'uid' in params ? params.uid : null,
      'newTrailer' in params ? params.newTrailer : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
