import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostBolFormFields {

    public load: FormField
    public containersession: FormField

    constructor() {

        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostBolFormFields()

export const postBolFormFieldOptions: {[key: string]: FormField} = {
  load: fields.load,
  containersession: fields.containersession,
};