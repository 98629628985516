import {SORespUser} from '../../core/user/so-resp-user';
import {UserCore} from './user.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutUserFormFields} from './put-user-form-fields';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetGrant} from '../../core/grant/so-get-grant';
import {SOGetToken} from '../../core/token/so-get-token';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetActivity} from '../../core/activity/so-get-activity';
import {SOGetFeatureasc} from '../../core/featureasc/so-get-featureasc';
import {SOGetMessage} from '../../core/message/so-get-message';


export class CEUserAttributesBase {

    username: DetailEntry
    givenName: DetailEntry
    surname: DetailEntry
    organization: DetailEntry
    companyName: DetailEntry
    isExxon: DetailEntry
    unread: DetailEntry
    active: DetailEntry
    grants: DetailEntry
    tokens: DetailEntry
    subscriptions: DetailEntry
    activitys: DetailEntry
    featureascs: DetailEntry
    messages: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespUser,
        app: GasDirectApp,
        formFields: PutUserFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.username = new DetailEntry(
            'Username',
            entity.username,
            'username',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.givenName = new DetailEntry(
            'Given name',
            entity.givenName,
            'givenName',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.givenName
        )

        this.surname = new DetailEntry(
            'Surname',
            entity.surname,
            'surname',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.surname
        )

        this.companyName = new DetailEntry(
            'Company name',
            entity.companyName,
            'companyName',
            BaseDetailType.text,
            null,
            EditableType.never,
        )

        this.isExxon = new DetailEntry(
            'Is exxon',
            entity.isExxon,
            'isExxon',
            BaseDetailType.toggle,
            null,
            EditableType.never,
        )

        this.unread = new DetailEntry(
            'Unread',
            entity.unread,
            'unread',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.unread
        )

        this.active = new DetailEntry(
            'Active',
            entity.active,
            'active',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.active
        )


        const organizationCore = app.makeCore( 'organization');
        organizationCore.soGet = SOGetOrganization.construct({
        });
        let organizationCeProtocol;
        if (entity.organization) {
            organizationCeProtocol = createCEProtocol(
                organizationCore.ceProtocol,
                parentEntityViewSpec,
                organizationCore,
                entity.organization,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            organizationCore
        )
        organizationCore.title = heading ? heading : organizationCore.title
        organizationCore.listTitle = 'Organization'

        if (entity.organization) {
            displayStringFactory.reconfigure(
                entity.organization,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        organizationCore.configureForDisplay(parentEntityViewSpec)

        this.organization = new DetailEntry(
          heading ? heading : organizationCore.listTitle,
          organizationCeProtocol ? organizationCeProtocol.entityListView.fullHeading : null,
          'organization',
          BaseDetailType.pushedDetail,
          organizationCore,
          EditableType.always,
          formFields.organization
        )
        if (entity.organization) {
          this.organization.ceProtocol = organizationCeProtocol;
          this.organization.cachedListEntry = organizationCore.makeListEntry(parentEntityViewSpec, entity.organization, 0)
          organizationCore.selectedEntity = entity.organization;
        }
        this.organization.singleFieldSelect = true

        const grantsCore = app.makeCore( 'grant');
        grantsCore.soGet = SOGetGrant.construct({
          user: new SOGetUser(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            grantsCore
        )
        grantsCore.title = heading ? heading : grantsCore.title
        grantsCore.listTitle = 'Grants'

        grantsCore.configureForDisplay(parentEntityViewSpec)

        this.grants = new DetailEntry(
          heading ? heading : grantsCore.listTitle,
          null,
          'grants',
          BaseDetailType.embeddedList,
          grantsCore,
          EditableType.never
        )

        const tokensCore = app.makeCore( 'token');
        tokensCore.soGet = SOGetToken.construct({
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            tokensCore
        )
        tokensCore.title = heading ? heading : tokensCore.title
        tokensCore.listTitle = 'Tokens'

        tokensCore.configureForDisplay(parentEntityViewSpec)

        this.tokens = new DetailEntry(
          heading ? heading : tokensCore.listTitle,
          null,
          'tokens',
          BaseDetailType.pushedList,
          tokensCore,
          EditableType.never
        )

        const subscriptionsCore = app.makeCore( 'subscription');
        subscriptionsCore.soGet = SOGetSubscription.construct({
          user: new SOGetUser(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            subscriptionsCore
        )
        subscriptionsCore.title = heading ? heading : subscriptionsCore.title
        subscriptionsCore.listTitle = 'Subscriptions'

        subscriptionsCore.configureForDisplay(parentEntityViewSpec)

        this.subscriptions = new DetailEntry(
          heading ? heading : subscriptionsCore.listTitle,
          null,
          'subscriptions',
          BaseDetailType.pushedList,
          subscriptionsCore,
          EditableType.never
        )

        const activitysCore = app.makeCore( 'activity');
        activitysCore.soGet = SOGetActivity.construct({
          user: new SOGetUser(entity.uid),
        });
        activitysCore.soGet.queryOptions.sortKey = 'dateCreated';
        activitysCore.soGet.queryOptions.descending = true;
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            activitysCore
        )
        activitysCore.title = heading ? heading : activitysCore.title
        activitysCore.listTitle = 'Activitys'

        activitysCore.configureForDisplay(parentEntityViewSpec)

        this.activitys = new DetailEntry(
          heading ? heading : activitysCore.listTitle,
          null,
          'activitys',
          BaseDetailType.pushedList,
          activitysCore,
          EditableType.never
        )

        const featureascsCore = app.makeCore( 'featureasc');
        featureascsCore.soGet = SOGetFeatureasc.construct({
          user: new SOGetUser(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            featureascsCore
        )
        featureascsCore.title = heading ? heading : featureascsCore.title
        featureascsCore.listTitle = 'Featureascs'

        featureascsCore.configureForDisplay(parentEntityViewSpec)

        this.featureascs = new DetailEntry(
          heading ? heading : featureascsCore.listTitle,
          null,
          'featureascs',
          BaseDetailType.pushedList,
          featureascsCore,
          EditableType.never
        )

        const messagesCore = app.makeCore( 'message');
        messagesCore.soGet = SOGetMessage.construct({
          user: new SOGetUser(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            messagesCore
        )
        messagesCore.title = heading ? heading : messagesCore.title
        messagesCore.listTitle = 'Messages'

        messagesCore.configureForDisplay(parentEntityViewSpec)

        this.messages = new DetailEntry(
          heading ? heading : messagesCore.listTitle,
          null,
          'messages',
          BaseDetailType.pushedList,
          messagesCore,
          EditableType.never
        )
    }
}