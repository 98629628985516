import {SORespNavroa} from '../../core/navroa/so-resp-navroa';
import {NavroaCore} from './navroa.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutNavroaFormFields} from './put-navroa-form-fields';
import {SOGetNavroa} from '../../core/navroa/so-get-navroa';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetNavi} from '../../core/navi/so-get-navi';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CENavroaAttributesBase {

    index: DetailEntry
    mainNav: DetailEntry
    role: DetailEntry
    navi: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespNavroa,
        app: GasDirectApp,
        formFields: PutNavroaFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.index = new DetailEntry(
            'Index',
            entity.index,
            'index',
            BaseDetailType.text,
            null,
            EditableType.always,
            formFields.index
        )

        this.mainNav = new DetailEntry(
            'Main nav',
            entity.mainNav,
            'mainNav',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.mainNav
        )


        const roleCore = app.makeCore( 'role');
        roleCore.soGet = SOGetRole.construct({
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            roleCore
        )
        roleCore.title = heading ? heading : roleCore.title
        roleCore.listTitle = 'Role'

        roleCore.configureForDisplay(parentEntityViewSpec)

        this.role = new DetailEntry(
          heading ? heading : roleCore.listTitle,
          null,
          'role',
          BaseDetailType.pushedDetail,
          roleCore,
          EditableType.never
        )
        this.role.singleFieldSelect = true

        const naviCore = app.makeCore( 'navi');
        naviCore.soGet = SOGetNavi.construct({
        });
        let naviCeProtocol;
        if (entity.navi) {
            naviCeProtocol = createCEProtocol(
                naviCore.ceProtocol,
                parentEntityViewSpec,
                naviCore,
                entity.navi,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            naviCore
        )
        naviCore.title = heading ? heading : naviCore.title
        naviCore.listTitle = 'Navi'

        if (entity.navi) {
            displayStringFactory.reconfigure(
                entity.navi,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        naviCore.configureForDisplay(parentEntityViewSpec)

        this.navi = new DetailEntry(
          heading ? heading : naviCore.listTitle,
          naviCeProtocol ? naviCeProtocol.entityListView.fullHeading : null,
          'navi',
          BaseDetailType.pushedDetail,
          naviCore,
          EditableType.never
        )
        if (entity.navi) {
          this.navi.ceProtocol = naviCeProtocol;
          this.navi.cachedListEntry = naviCore.makeListEntry(parentEntityViewSpec, entity.navi, 0)
          naviCore.selectedEntity = entity.navi;
        }
        this.navi.singleFieldSelect = true
    }
}