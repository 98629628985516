import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostDeltaFormFields {

    public type: FormField
    public containersession: FormField
    public load: FormField
    public billable: FormField

    constructor() {

        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )
        this.containersession = new FormField(
          'containersession',
          'Containersession',
          'containersession',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.billable = new FormField(
          'billable',
          'Billable',
          null,
          FormFieldType.boolean,
          true,
        )

    }
}

const fields = new PostDeltaFormFields()

export const postDeltaFormFieldOptions: {[key: string]: FormField} = {
  type: fields.type,
  containersession: fields.containersession,
  load: fields.load,
  billable: fields.billable,
};