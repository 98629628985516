import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTokenFormFields {

    public roleGrant: FormField
    public user: FormField
    public accessToken: FormField

    constructor() {

        this.roleGrant = new FormField(
          'roleGrant',
          'Role grant',
          'grant',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.user = new FormField(
          'user',
          'User',
          'user',
          FormFieldType.autoCompleteList,
          false,
        )
        this.accessToken = new FormField(
          'accessToken',
          'Access token',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PostTokenFormFields()

export const postTokenFormFieldOptions: {[key: string]: FormField} = {
  roleGrant: fields.roleGrant,
  user: fields.user,
  accessToken: fields.accessToken,
};