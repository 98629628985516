import { SOGetProduction } from '../production/so-get-production';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetProductionreport implements QueryProtocol {

  constructor(
    public uid: string = null,
    public production: SOGetProduction = null,
    public timeWindow: string = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetProductionreport(
      'uid' in params ? params.uid : null,
      'production' in params ? params.production : null,
      'timeWindow' in params ? params.timeWindow : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
