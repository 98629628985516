import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostRateableagreeyearallocationFormFields {

    public jan: FormField
    public feb: FormField
    public mar: FormField
    public apr: FormField
    public may: FormField
    public jun: FormField
    public jul: FormField
    public aug: FormField
    public sep: FormField
    public oct: FormField
    public nov: FormField
    public dec: FormField
    public agreeyear: FormField

    constructor() {

        this.jan = new FormField(
          'jan',
          'Jan',
          null,
          FormFieldType.text,
          false,
        )
        this.feb = new FormField(
          'feb',
          'Feb',
          null,
          FormFieldType.text,
          false,
        )
        this.mar = new FormField(
          'mar',
          'Mar',
          null,
          FormFieldType.text,
          false,
        )
        this.apr = new FormField(
          'apr',
          'Apr',
          null,
          FormFieldType.text,
          false,
        )
        this.may = new FormField(
          'may',
          'May',
          null,
          FormFieldType.text,
          false,
        )
        this.jun = new FormField(
          'jun',
          'Jun',
          null,
          FormFieldType.text,
          false,
        )
        this.jul = new FormField(
          'jul',
          'Jul',
          null,
          FormFieldType.text,
          false,
        )
        this.aug = new FormField(
          'aug',
          'Aug',
          null,
          FormFieldType.text,
          false,
        )
        this.sep = new FormField(
          'sep',
          'Sep',
          null,
          FormFieldType.text,
          false,
        )
        this.oct = new FormField(
          'oct',
          'Oct',
          null,
          FormFieldType.text,
          false,
        )
        this.nov = new FormField(
          'nov',
          'Nov',
          null,
          FormFieldType.text,
          false,
        )
        this.dec = new FormField(
          'dec',
          'Dec',
          null,
          FormFieldType.text,
          false,
        )
        this.agreeyear = new FormField(
          'agreeyear',
          'Agreeyear',
          'agreeyear',
          FormFieldType.pushedSelectionList,
          true,
        )

    }
}

const fields = new PostRateableagreeyearallocationFormFields()

export const postRateableagreeyearallocationFormFieldOptions: {[key: string]: FormField} = {
  jan: fields.jan,
  feb: fields.feb,
  mar: fields.mar,
  apr: fields.apr,
  may: fields.may,
  jun: fields.jun,
  jul: fields.jul,
  aug: fields.aug,
  sep: fields.sep,
  oct: fields.oct,
  nov: fields.nov,
  dec: fields.dec,
  agreeyear: fields.agreeyear,
};