import {ActiveUserService, CEProtocol, CEConstructor, ListSpecType} from 'lionheartland';
import {HttpClient} from 'lionheartland';
import {EOTokenService} from '../../core/token/eo-token';
import {SOGetToken} from '../../core/token/so-get-token';
import {RAPutToken} from './ra-put-token';
import {RAPostToken} from './ra-post-token';
import {RADeleteToken} from './ra-delete-token';
import {AbstractEntityCore} from 'lionheartland';
import {EntityCoreProtocol} from 'lionheartland';
import { CEToken } from "./ce-token"


export class TokenCoreBase extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'token';
  title = 'Token';
  ceProtocol: CEConstructor = CEToken

  entityService: EOTokenService;
  soGetClass = SOGetToken;
  soGet: SOGetToken;


  constructor(
    public activeUserService: ActiveUserService,
  ) {
    super(
      activeUserService,
      EOTokenService
    );
    this.adapters = [
      new RAPostToken(this),
      new RAPutToken(this),
      new RADeleteToken(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetToken();
    this.soGet.queryOptions.limit = this.limit;
  }


}