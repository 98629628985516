import { SOGetTempagreement } from '../tempagreement/so-get-tempagreement';
import { SOGetTemptract } from '../temptract/so-get-temptract';
import {MMQueryOptions} from 'lionheartland';
import {QueryProtocol} from 'lionheartland';


export class SOGetTempagreetract implements QueryProtocol {

  constructor(
    public uid: string = null,
    public tempagreement: SOGetTempagreement = null,
    public temptract: SOGetTemptract = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetTempagreetract(
      'uid' in params ? params.uid : null,
      'tempagreement' in params ? params.tempagreement : null,
      'temptract' in params ? params.temptract : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
