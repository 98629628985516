import { SOGetFeeschedule } from '../feeschedule/so-get-feeschedule';


export class SOPostTempagreement {

  constructor(
    public name: string,
    public docusignLink: string,
    public units: string,
    public description: string = "",
    public autoRenew: boolean = false,
    public durationDays: number = 365,
    public feeschedule: SOGetFeeschedule = null,
    public acq: number = null,
    public top: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTempagreement(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'autoRenew' in params ? params.autoRenew : null,
      'docusignLink' in params ? params.docusignLink : null,
      'units' in params ? params.units : null,
      'durationDays' in params ? params.durationDays : null,
      'feeschedule' in params ? params.feeschedule : null,
      'acq' in params ? params.acq : null,
      'top' in params ? params.top : null,
    );
  }
}
