import { SOGetAccount } from '../account/so-get-account';
import { SOGetTempclin } from '../tempclin/so-get-tempclin';
import { SOGetDistribution } from '../distribution/so-get-distribution';


export class SOPostSlot {

  constructor(
    public status: string,
    public start: Date,
    public end: Date,
    public distribution: SOGetDistribution,
    public slotNumber: number = null,
    public account: SOGetAccount = null,
    public tempclin: SOGetTempclin = null,
    public loadType: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostSlot(
      'status' in params ? params.status : null,
      'slotNumber' in params ? params.slotNumber : null,
      'start' in params ? params.start : null,
      'end' in params ? params.end : null,
      'account' in params ? params.account : null,
      'tempclin' in params ? params.tempclin : null,
      'loadType' in params ? params.loadType : null,
      'distribution' in params ? params.distribution : null,
    );
  }
}
