import {SORespSubscription} from '../../core/subscription/so-resp-subscription';
import {SubscriptionCore} from './subscription.core';
import {ListEntry, CEProtocol, CEBase, createCEProtocol, RowSpec} from 'lionheartland';
import {
  DetailEntry, ScopeMatch,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'lionheartland';
import {BaseDetailType} from 'lionheartland';
import {EntityAttributesForDisplay} from 'lionheartland';
import {getHeading} from 'lionheartland';
import {ListSpecType} from 'lionheartland';
import {DisplayVectorStringFactory} from 'lionheartland';
import {ActiveUserService} from 'lionheartland';
import {EntityViewSpecProtocol} from 'lionheartland';
import { GasDirectApp } from "../../land-app";
import {PutSubscriptionFormFields} from './put-subscription-form-fields';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetMessage} from '../../core/message/so-get-message';
import {SOGetContainersession} from '../../core/containersession/so-get-containersession';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetPublication} from '../../core/publication/so-get-publication';
import {SOGetAccount} from '../../core/account/so-get-account';
import {SOGetDistribution} from '../../core/distribution/so-get-distribution';


export class CESubscriptionAttributesBase {

    messages: DetailEntry
    feed: DetailEntry
    webAlerts: DetailEntry
    mobileAlerts: DetailEntry
    emailAlerts: DetailEntry
    containersession: DetailEntry
    user: DetailEntry
    publication: DetailEntry

    constructor(
        parentEntityViewSpec: EntityViewSpecProtocol,
        entity: SORespSubscription,
        app: GasDirectApp,
        formFields: PutSubscriptionFormFields
    ) {

        const displayStringFactory = new DisplayVectorStringFactory(app.displayOptions);
        let heading;


        this.feed = new DetailEntry(
            'Feed',
            entity.feed,
            'feed',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.feed
        )

        this.webAlerts = new DetailEntry(
            'Web alerts',
            entity.webAlerts,
            'webAlerts',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.webAlerts
        )

        this.mobileAlerts = new DetailEntry(
            'Mobile alerts',
            entity.mobileAlerts,
            'mobileAlerts',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.mobileAlerts
        )

        this.emailAlerts = new DetailEntry(
            'Email alerts',
            entity.emailAlerts,
            'emailAlerts',
            BaseDetailType.toggle,
            null,
            EditableType.always,
            formFields.emailAlerts
        )


        const messagesCore = app.makeCore( 'message');
        messagesCore.soGet = SOGetMessage.construct({
          subscription: new SOGetSubscription(entity.uid),
        });
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            messagesCore
        )
        messagesCore.title = heading ? heading : messagesCore.title
        messagesCore.listTitle = 'Messages'

        messagesCore.configureForDisplay(parentEntityViewSpec)

        this.messages = new DetailEntry(
          heading ? heading : messagesCore.listTitle,
          null,
          'messages',
          BaseDetailType.embeddedList,
          messagesCore,
          EditableType.never
        )

        const containersessionCore = app.makeCore( 'containersession');
        containersessionCore.soGet = SOGetContainersession.construct({
          account: app.getScope('account') ? new SOGetAccount(app.getScope('account').uid) : null,
          distribution: app.getScope('distribution') ? new SOGetDistribution(app.getScope('distribution').uid) : null,
        });
        containersessionCore.soGet.queryOptions.sortKey = 'arrived';
        let containersessionCeProtocol;
        if (entity.containersession) {
            containersessionCeProtocol = createCEProtocol(
                containersessionCore.ceProtocol,
                parentEntityViewSpec,
                containersessionCore,
                entity.containersession,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            containersessionCore
        )
        containersessionCore.title = heading ? heading : containersessionCore.title
        containersessionCore.listTitle = 'Containersession'

        if (entity.containersession) {
            displayStringFactory.reconfigure(
                entity.containersession,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        containersessionCore.configureForDisplay(parentEntityViewSpec)

        this.containersession = new DetailEntry(
          heading ? heading : containersessionCore.listTitle,
          containersessionCeProtocol ? containersessionCeProtocol.entityListView.fullHeading : null,
          'containersession',
          BaseDetailType.pushedDetail,
          containersessionCore,
          EditableType.never
        )
        if (entity.containersession) {
          this.containersession.ceProtocol = containersessionCeProtocol;
          this.containersession.cachedListEntry = containersessionCore.makeListEntry(parentEntityViewSpec, entity.containersession, 0)
          containersessionCore.selectedEntity = entity.containersession;
        }
        this.containersession.singleFieldSelect = true

        const userCore = app.makeCore( 'user');
        userCore.soGet = SOGetUser.construct({
        });
        userCore.soGet.queryOptions.sortKey = 'surname';
        let userCeProtocol;
        if (entity.user) {
            userCeProtocol = createCEProtocol(
                userCore.ceProtocol,
                parentEntityViewSpec,
                userCore,
                entity.user,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            userCore
        )
        userCore.title = heading ? heading : userCore.title
        userCore.listTitle = 'User'

        if (entity.user) {
            displayStringFactory.reconfigure(
                entity.user,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        userCore.configureForDisplay(parentEntityViewSpec)

        this.user = new DetailEntry(
          heading ? heading : userCore.listTitle,
          userCeProtocol ? userCeProtocol.entityListView.fullHeading : null,
          'user',
          BaseDetailType.pushedDetail,
          userCore,
          EditableType.never
        )
        if (entity.user) {
          this.user.ceProtocol = userCeProtocol;
          this.user.cachedListEntry = userCore.makeListEntry(parentEntityViewSpec, entity.user, 0)
          userCore.selectedEntity = entity.user;
        }
        this.user.singleFieldSelect = true

        const publicationCore = app.makeCore( 'publication');
        publicationCore.soGet = SOGetPublication.construct({
        });
        let publicationCeProtocol;
        if (entity.publication) {
            publicationCeProtocol = createCEProtocol(
                publicationCore.ceProtocol,
                parentEntityViewSpec,
                publicationCore,
                entity.publication,
                app
            )
        }
        heading = getHeading(
            parentEntityViewSpec ? parentEntityViewSpec.entityCore : null,
            publicationCore
        )
        publicationCore.title = heading ? heading : publicationCore.title
        publicationCore.listTitle = 'Publication'

        if (entity.publication) {
            displayStringFactory.reconfigure(
                entity.publication,
                parentEntityViewSpec ? parentEntityViewSpec.entityCore.type : null
            )
        }
        publicationCore.configureForDisplay(parentEntityViewSpec)

        this.publication = new DetailEntry(
          heading ? heading : publicationCore.listTitle,
          publicationCeProtocol ? publicationCeProtocol.entityListView.fullHeading : null,
          'publication',
          BaseDetailType.pushedDetail,
          publicationCore,
          EditableType.never
        )
        if (entity.publication) {
          this.publication.ceProtocol = publicationCeProtocol;
          this.publication.cachedListEntry = publicationCore.makeListEntry(parentEntityViewSpec, entity.publication, 0)
          publicationCore.selectedEntity = entity.publication;
        }
        this.publication.singleFieldSelect = true
    }
}