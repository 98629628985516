import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutInquiryFormFields {

    public companyName: FormField
    public givenName: FormField
    public surname: FormField
    public productInquiryStr: FormField
    public estimatedPurchaseQuantity: FormField
    public preferredEmailAddress: FormField
    public phoneNumber: FormField
    public dateCreated: FormField

    constructor() {

        this.companyName = new FormField(
          'companyName',
          'Company name',
          null,
          FormFieldType.text,
          false,
        )
        this.givenName = new FormField(
          'givenName',
          'Given name',
          null,
          FormFieldType.text,
          false,
        )
        this.surname = new FormField(
          'surname',
          'Surname',
          null,
          FormFieldType.text,
          false,
        )
        this.productInquiryStr = new FormField(
          'productInquiryStr',
          'Product inquiry str',
          null,
          FormFieldType.text,
          false,
          true,
          "Shute Creek LNG"
        )
        this.estimatedPurchaseQuantity = new FormField(
          'estimatedPurchaseQuantity',
          'Estimated purchase quantity',
          null,
          FormFieldType.text,
          false,
        )
        this.preferredEmailAddress = new FormField(
          'preferredEmailAddress',
          'Preferred email address',
          null,
          FormFieldType.text,
          false,
        )
        this.phoneNumber = new FormField(
          'phoneNumber',
          'Phone number',
          null,
          FormFieldType.text,
          false,
        )
        this.dateCreated = new FormField(
          'dateCreated',
          'Date created',
          null,
          FormFieldType.datetime,
          false,
        )

    }
}

const fields = new PutInquiryFormFields()

export const putInquiryFormFieldOptions: {[key: string]: FormField} = {
  companyName: fields.companyName,
  givenName: fields.givenName,
  surname: fields.surname,
  productInquiryStr: fields.productInquiryStr,
  estimatedPurchaseQuantity: fields.estimatedPurchaseQuantity,
  preferredEmailAddress: fields.preferredEmailAddress,
  phoneNumber: fields.phoneNumber,
  dateCreated: fields.dateCreated,
};