import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostTempclinFormFields {

    public name: FormField
    public type: FormField
    public state: FormField
    public durationDays: FormField
    public offsetDays: FormField
    public quantity: FormField
    public loadQuantity: FormField
    public description: FormField
    public start: FormField
    public end: FormField
    public distribution: FormField

    constructor() {

        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          true,
        )
        this.type = new FormField(
          'type',
          'Type',
          null,
          FormFieldType.text,
          true,
        )
        this.state = new FormField(
          'state',
          'State',
          null,
          FormFieldType.text,
          false,
          true,
          "active"
        )
        this.durationDays = new FormField(
          'durationDays',
          'Duration days',
          null,
          FormFieldType.text,
          false,
          true,
          365
        )
        this.offsetDays = new FormField(
          'offsetDays',
          'Offset days',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.quantity = new FormField(
          'quantity',
          'Quantity',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.loadQuantity = new FormField(
          'loadQuantity',
          'Load quantity',
          null,
          FormFieldType.text,
          false,
          true,
          0
        )
        this.description = new FormField(
          'description',
          'Description',
          null,
          FormFieldType.text,
          false,
          true,
          ""
        )
        this.start = new FormField(
          'start',
          'Start',
          null,
          FormFieldType.datetime,
          false,
        )
        this.end = new FormField(
          'end',
          'End',
          null,
          FormFieldType.datetime,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PostTempclinFormFields()

export const postTempclinFormFieldOptions: {[key: string]: FormField} = {
  name: fields.name,
  type: fields.type,
  state: fields.state,
  durationDays: fields.durationDays,
  offsetDays: fields.offsetDays,
  quantity: fields.quantity,
  loadQuantity: fields.loadQuantity,
  description: fields.description,
  start: fields.start,
  end: fields.end,
  distribution: fields.distribution,
};