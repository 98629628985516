import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PutChecklistFormFields {

    public body: FormField
    public name: FormField
    public identifier: FormField
    public load: FormField
    public distribution: FormField
    public fileLink: FormField
    public items: FormField

    constructor() {

        this.body = new FormField(
          'body',
          'Body',
          null,
          FormFieldType.text,
          false,
        )
        this.name = new FormField(
          'name',
          'Name',
          null,
          FormFieldType.text,
          false,
        )
        this.identifier = new FormField(
          'identifier',
          'Identifier',
          null,
          FormFieldType.text,
          false,
        )
        this.load = new FormField(
          'load',
          'Load',
          'load',
          FormFieldType.autoCompleteList,
          false,
        )
        this.distribution = new FormField(
          'distribution',
          'Distribution',
          'distribution',
          FormFieldType.pushedSelectionList,
          false,
        )
        this.fileLink = new FormField(
          'fileLink',
          'File link',
          null,
          FormFieldType.text,
          false,
        )
        this.items = new FormField(
          'items',
          'Items',
          'checklistitem',
          FormFieldType.pushedSelectionList,
          false,
        )

    }
}

const fields = new PutChecklistFormFields()

export const putChecklistFormFieldOptions: {[key: string]: FormField} = {
  body: fields.body,
  name: fields.name,
  identifier: fields.identifier,
  load: fields.load,
  distribution: fields.distribution,
  fileLink: fields.fileLink,
  items: fields.items,
};