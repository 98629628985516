import {FormField, FormFieldType, SelectionOption} from 'lionheartland';

export class PostCarrierFormFields {

    public companyName: FormField
    public emailDomain: FormField

    constructor() {

        this.companyName = new FormField(
          'companyName',
          'Company name',
          null,
          FormFieldType.text,
          true,
        )
        this.emailDomain = new FormField(
          'emailDomain',
          'Email domain',
          null,
          FormFieldType.text,
          false,
        )

    }
}

const fields = new PostCarrierFormFields()

export const postCarrierFormFieldOptions: {[key: string]: FormField} = {
  companyName: fields.companyName,
  emailDomain: fields.emailDomain,
};